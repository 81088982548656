import bn from "./bn";
import en from "./en";
import gu from "./gu";
import hi from "./hi";
import kn from "./kn";
import ml from "./ml";
import mr from "./mr";
import pa from "./pa";
import ta from "./ta";
import te from "./te";



const language = (code) => {
  if (code === "bn") return bn;
  if (code === "en") return en;
  if (code === "gu") return gu;
  if (code === "hi") return hi;
  if (code === "kn") return kn;
  if (code === "ml") return ml;
  if (code === "mr") return mr;
  if (code === "pa") return pa;
  if (code === "ta") return ta;
  if (code === "te") return te;
  else return en;
};

export default language;
