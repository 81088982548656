const keys = {
  LANGUAGE_OBJ: "languageObj",
  GET_SETTINGS: "getSettings",
  GET_SETTINGS_VERSION: "getSettingVersion",
  DISTRICT_LIST: "districtList",
  GEOGRAPHY_ID: "geographyId",
  SELECTED_GEOGRAPHY: "selectedGeography",
  PRODUCT_LIST: "productList",
  TAGGING_DATA_LIST: "taggingDataList",
  QUALIFIED_SCHEMES: "qualifiedSchemes",
  PROFILE_QUALIFIED_SCHEMES: "profileQualifiedSchemes",
  PROFILE_QUALIFIED_JOBS: "profileQualifiedJobs",
  QUALIFIED_JOBS: "qualifiedJobs",
  PROACTIVE_QUALIFIED_SCHEMES: "proactiveQualifiedSchemes",
  PROACTIVE_QUALIFIED_JOBS: "proactiveQualifiedJobs",
  IS_SURVEY_COMPLETED: "isSurveyCompleted",
  JWT_DATA: "jwtData",
  PASSED_SCHEME_ID_LIST: "passedSchemeIdList",
  NO_QUALIFIED_SCHEMES: "noQualifiedSchemes",
  NO_QUALIFIED_JOBS: "noQualifiedJobs",
  IS_JWT_UPDATED: "isJwtUpdated",
  AUTHORIZATION_TOKEN: "authorizationToken",
  USERNAME: "username",
  NEXT_URL: "nextUrl",
  SURVEY_TYPE: "surveyType",
  SURVEY_RESPONSE: "surveyResponse",
  PROFILE_SURVEY_RESPONSE: "profileSurveyResponse",
  PRODUCT_DETAILS: "productDetails",
  IS_SCHEME_FIRST_VISIT: "isSchemeFirstVisit",
  CURRENT_PRODUCT: "currentProduct",
  PRODUCT_DETAILS_SAVED: "productDetailsSaved",
  USER_PROFILE_LIST: "userProfileList",
  USER_PROFILE: "userProfile",
  IS_VIEW_ALL: "isViewAll",
  SELECTED_PROFILE_DETAILS: "profileDetails",
  ACTIVE_PROFILE: "activeProfile",
  CURRENT_PRODUCT_URL: "currentProductUrl",
  SOURCE_ID: "sourceId",
  FAMILY_MEMBERS_LIST: "familyMembersList",
  EDIT_PROFILE_TOKEN: "editProfileToken",
  LIST_PROFILE: "listProfile",
  SHOW_UPDATE_GEOGRAPHY: "showupdategeography",
  LOGIN_ATTEMPTS: "loginAttempts",
  SIGNUP_NUMBER: "signUpNumber",
  CURRENT_SOURCE: "currentSource",
  EXTERNAL_USER_PROFILE: "externalUserProfile",
  IS_PROFILE_UPDATED: "isProfileUpdated",
  IS_GUEST_USER: "isGuestUser",
  IS_FIRST_TIME_USER: "isFirstTimeUser",
  SELECTED_SOURCE: "selectedSource",
  NEWSIGNIN: "newSignIn",
  IS_VIEW_ALL_SAVE: "isViewAllSave",
  LOGIN_WITH_OTP: "loginWithOtp",
  EXTERNAL_PROFILE_DATA: "externalProfileData",
  EXTERNAL_PROFILE_DATA_LENGTH: "externalProfileDataLength",
  ADD_PROFILE_FLOW: "addProfileFlow",
  PROFILE_CONFIRMED_FIELDS: "profileConfirmedFields",
  FIND_JOBS: "findJobs",
  FROM_HOME: "fromHome",
  NON_ELIGIBLE_OBJ: "nonEligibleObj",
  PREVIOUS_PROFILE_TOKEN: "previousProfileToken",
  RESPONSE_DATA: "responseData",
  PREVIOUS_PROFILE: "previousProfile",
  EXPLORE: "explore",
  SHOW_MULTIPLE_REASONS: "showMultipleReasons",
  UNMATCHED_KEYS: "unmatchedKeys",
  USE_USER_PROFILE: "useUserProfile",
  NOT_ELIGIBLE_CHECK: "notEligibleCheck",
  APPLICATION_FORM: "applicationForm",
  DOCUMENT_LIST: "documentList",
  SELF_DECLERATION: "selfDecleration",
  USER_KYC: "userKyc",
  USER_SERVICE_ID: "userServiceId",
  TERM_AND_CONDITIONS: "termAndConditions",
  IS_PRINTABLE_APPLICATION_FORM_PRESENT: "isPrintableApplicationFormPresent",
  SAVE_KYC: "saveKyc",
  APPLICATION_FORM_ID: "applicationFormId",
  APPLICATION_FORM_FIELD_VALUES: "applicationFormFieldValues",
  SAVE_API_CALLED: "saveApiCalled",
  SAVED_PRODUCT: "savedProduct",
  ACTIVE_INDEX: "activeIndex",
  SERVICE_ACTIVE_INDEX: "serviceActiveIndex",
  SERVICE_CATEGORY_INDEX: "serviceCategoryIndex",
  APPLICATION_FORM_DOCUMENT: "applicationFormDocument",
  MAPPING_IDS_FOR_DOCUMENT: "mappingIdsForDocument",
  KYC_FORM: "kycForm",
  KYC_SUBMITTED: "kycSubmitted",
  PROFILE_PIC: "profilePic",
  SHOW_FEEDBACK: "showFeedBack",
  SERVICE_CATEGORY_LIST: "serviceCategoryList",
  SET_PASSWORD: "setPassword",
  SUB_SERVICE_NAME: "subServiceName",
  APPLIED_DATA: "appliedData",
  USER_TYPE: "userType",
  THEME_TYPE:"themeColor",
  PREVIOUS_PROFILE_BUSINESS: "previousProfileBusiness",
  SETTINGS_DATA: "settingsData",
  APPLICATION_FILES: "applicationFiles",
  CURRENT_THEME: "currentTheme",
  NOTIFICATIONS: 'notifications',
  LAST_VISIT_NOTIFICATION: 'lastVisitNotification',
  FROM_DRAFT: "fromDraft",
  FORM_APPLIED: "formAppplied",
  PREVIOUS_DOCS_TO_DELETE: "previousDocsToDelete",
  SHOW_UPDATE_GEOGRAPHY_BUSINESS: "showupdategeographyBusiness",
  HASHED_AADHAAR: "hashedAadhaar",
  CALL_PRODUCT_API: "callProductApi",
  FROM_SAVED_PAGE: "fromSavedPage",
  DOCUMENT_LIST_FOR_API: "documentListForApis",
  AADHAAR_EKYC_DATA: "aadhaarEKycData",
  SGM_FOR_STATUS: "sgmForStatus",
  DRAFT_APPLIED_SCHEMES: "draftAppliedSchemes",
  SURVEY_DATA_OF_APPLICATION: 'surveyDataOfApplication',
  SELECTED_STATE_NAME: 'SelectedStateName',
  SELECTED_DISTRICT_NAME: 'SelectedDistrictName',
  Is_ESign_Done: 'isESignDone',
  IsServiceOnlineDone: 'isServiceOnlineDone',
  IsDbtAadhaarDone: "isDbtAadhaarDone",
  FLAG_VERIFIED: "Verified",
  FLAG_UNVERIFIED: "Unverified",
  FLAG_NOT_VERIFIED: "Not Verified",
  KYC_IMAGE : "kycImage",
  FROM_COMPLETE_ACTION:"fromCompleteAction",
  REQUEST_FOR_COMPLETE_ACTION:"requestForCompleteAction",
  FROM_NOTIFICATION:"fromNotification",
  IS_PAYMENT_DONE:"isPaymentDone",
  IS_BACK_FROM_APPLICATION:"isBackFromApplication",
  USER_PROFILE_DOCUMENT:"userProfileDocumnets",
  SOURCE_LIST:"sourceList",
  LOGGEDIN_MOBILE_NUMBER:"LOGGEDIN_MOBILE_NUMBER",
  AUTHORITY_DATA: "authorityData",
  BENEFITS_LIST: "benefitsList",
  SELECTED_SOURCE_FROM_KYC:"selectedSourceFromKyc",
  SURVEY_DATA_FOR_EXTERNAL_SOURCE:"surveyDataForExternalSource",
  LANGUAGE_LIST:"languageList",
  DO_NOT_SET_FIELD_VALUES:"doNotSetFieldValues",
  IS_SWITCH_CHECKED:"switchChecked",
  USER_DATA_USER:"userDataUser",
  VIEW_SERVICE_ID:"viewServiceId",
  CURRENT_KYC_SOURCE: 'currentKycSource',
  LOGIN_FROM:"loginFrom",
  PRODUCT_CODE:"productCode",
  IS_MARQUEE:"isMarquee",
  PAYMENT_STATUS:"paymentStatus",
  PAYMENT_ID:"paymentId",
  ADVERTISEMENT_LIST: "advertisementList",
  DEEP_LINK_URL: "deepLinkUrl",
  TRANSACTION_ID:"transactionId",
  PAYMENT_STATUS_ID:"paymentStatusId",
  EXPIRATION_TIME:"expirationTime",
  IS_PAYMENT_FLOW:"isPaymentFlow",
  DEEP_LINK_SOURCE: "deepLinkSource",
  GOVERNMENT_FEES:"governmentFees",
};

export default keys;
