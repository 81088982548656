const initState = {
    data: undefined,
  };

  const logOutReducer = (state = initState, action) => {
    switch (action.type) {
      case "LOG_OUT":
        return {
          ...state,
          data: action.payload,
        };

        case "RESET_LOG_OUT":
        return {
          ...state,
          data: '',
        };

      default:
        return state;
    }
  };

  export default logOutReducer;
