const initState = {
  data: "",
};

const getLgdCodeFromValueAndLevelReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_LGD_CODE_FROM_VALUE_AND_LEVEL":
      return {
        ...state,
        data: action.payload,
      };

    case "RESET_GET_LGD_CODE_FROM_VALUE_AND_LEVEL":
      return {
        ...state,
        data: undefined,
      };

    default:
      return state;
  }
};

export default getLgdCodeFromValueAndLevelReducer;
