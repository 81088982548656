const initState = {
    data: "",
  };

  const userDocumentByIdReducer = (state = initState, action) => {
    switch (action.type) {
      case "USER_DOCUMENT_BY_ID":
        return {
          ...state,
          data: action.payload,
        };

      case "RESET_USER_DOCUMENT_BY_ID":
        return {
          ...state,
          data: "",
        };

      default:
        return state;
    }
  };

  export default userDocumentByIdReducer;
