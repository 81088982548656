const initState = {
    data: undefined,
  };

  const dbtAadhaarAuthentication = (state = initState, action) => {
    switch (action.type) {
      case "DBT_AADHAAR_AUTHENTICATION":
        return {
          ...state,
          data: action.payload,
        };

      case "RESET_DBT_AADHAAR_AUTHENTICATION":
        return {
          ...state,
          data: undefined,
        };

      default:
        return state;
    }
  };

  export default dbtAadhaarAuthentication;
