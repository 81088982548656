const language = {
    "9e66e6bd4069124578736528a0432752": `మా గురించి`,
    "0f5590b58efe9cfcce35e55a66280e59": `అంగీకరించండి & కొనసాగించండి`,
    "77ab8893d0d282a9f2781d5711883903": `జోడించు`,
    "c40eba17959a63e52412cac8c38d467e": `ప్రొఫైల్ జోడించండి`,
    "ac714b256ab565c91ade9e03e8c410f0": `మీ డేటా అంతా తొలగించబడింది`,
    "b31b443baac1c6da4cb78dfaf2e5be44": `అప్లికేషన్ లింక్`,
    "0ec157e0e15e12c56bfe1c72e0e7515a": `మార్పులను వర్తించండి`,
    "181c1a0ee6db7d7f835eed29791749ab": `మీరు ఖచ్చితంగా ఈ పథకాన్ని తొలగించాలనుకుంటున్నారా?`,
    "d937652cde254e2a9b93b74a4ee2a02b": `మీరు {{name}} ప్రొఫైల్‌ను శాశ్వతంగా తొలగించాలనుకుంటున్నారా?`,
    "3ac1df709254c0e491c642aae5400814": `మీరు {{name}} ఖాతాను శాశ్వతంగా తొలగించాలనుకుంటున్నారా?`,
    "1cbb32715ee74ee3447f48febcc04864": `మీ సేవ్ చేసిన సేవల నుండి మీరు ఈ సేవను తొలగించాలనుకుంటున్నారా?`,
    "ea4788705e6873b424c65e91c2846b19": `రద్దు చేయండి`,
    "f4ec5f57bd4d31b803312d873be40da9": `మార్పు`,
    "6dd495f1c2ae29c8e9a6c4405f18e22e": `స్థానాన్ని మార్చండి`,
    "b1ad1d3aef325b23950a43cb82fd0670": `భాషను ఎంచుకోండి`,
    "714341d9339756588d1dc724991c2b9a": `ఇక్కడ నొక్కండి`,
    "f72b489f19f748cf78a86c78d547ed91": `మీ స్థానాన్ని నిర్ధారించండి`,
    "a0bfb8e59e6c13fc8d990781f77694fe": `కొనసాగించండి`,
    "bee04ef1315b3f9562be34e2e28a7831": `ఖాతాను తొలగించండి`,
    "3e9983cf1885a5ec9f5a5d8127137bd2": `ప్రొఫైల్‌ను తొలగించండి`,
    "2ac7a924af94bf173f38eb31165efce3": `తొలగించు పథకం`,
    "e0bb2f66e5b61be3235bf8969979de19": `జిల్లా`,
    "46a43a7757337712d4f6a3701a5c57dd": `డౌన్‌లోడ్ ఫారం`,
    "4d7be8ac1b9cb2fd5843dbea7ce30344": `మీరు ఇంకా ఏ సేవను సేవ్ చేయలేదు`,
    "edda2208040fe25b06d0d8f3af976651": `మీరు ఏ ఉద్యోగాలను ఆదా చేయలేదు`,
    "f283a3e62b2809c07cf77075103c012f": `మీ కోసం ఉత్తమంగా సరిపోయే ప్రభుత్వ సంక్షేమ పథకాలు, సేవలు మరియు ఉద్యోగాలను కనుగొనడానికి మాకు కొన్ని ప్రాథమిక ప్రొఫైల్ సమాచారం అవసరం.`,
    // "f283a3e62b2809c07cf77075103c012f":`ఉత్తమమైన ప్రభుత్వ సంక్షేమ పథకాలను కనుగొనడానికి లాబ్హార్తికి కొన్ని ప్రాథమిక ప్రొఫైల్ సమాచారం అవసరం`,
    "ce8ae9da5b7cd6c3df2929543a9af92d": `ఇమెయిల్`,
    "01e8b0c4e059b594202e976860b2d481": `మీ స్వంత భాషలో పథకాలు మరియు సేవలను అన్వేషించండి`,
    "483a5da192f46e3cfe8391d3ef234fc4": `తరచుగా అడిగే ప్రశ్నలు`,
    "37aaf54433bbfe989db8871121fe0ad8": `మమ్మల్ని అనుసరించు:`,
    "e0c4332e8c13be976552a059f106354f": `ప్రారంభించడానికి`,
    "48dee7c291ac7f19e499438b072da336": `ప్రభుత్వ రుసుము`,
    "2ceb32552d78024d91a123897636afcb": `EasyGov యొక్క పౌర సాధికారత యాప్`,
    //"357aa540fd5c8ae584ba18359105de3d":`మీకు ఏదైనా పథకాలు లేదా మరేదైనా ప్రశ్న ఉందా?`,
    "357aa540fd5c8ae584ba18359105de3d": `ఒకవేళ మీకు ఏవైనా ప్రశ్న ఉంటే మాకు తెలియజేయండి మరియు మేము 24 వ్యాపార గంటలలోపు మీ వద్దకు వస్తాము.`,
    "b3ba00d60ff5a9e11c15a6efbed13746": `మీ ప్రస్తుత ప్రొఫైల్‌ను నవీకరించడానికి “ప్రస్తుత ప్రొఫైల్‌ను నవీకరించండి” పై క్లిక్ చేయండి మరియు క్రొత్త ప్రొఫైల్‌ను జోడించడానికి “ప్రొఫైల్‌ను జోడించు” ఎంచుకోండి`,
    "12ceff2290bb9039beaa8f36d5dec226": `ఉద్యోగాలు`,
    "5eb57a6678ca337f92884f49b578a908": `...`,
    "4994a8ffeba4ac3140beb89e8d41f174": `భాష`,
    "846eb834b6bfe81dbd412bc010f8cac7": `మాకు తెలియజేయండి మరియు మేము 24 వ్యాపార గంటలలోపు మీ వద్దకు వస్తాము`,
    "a58252e21aaf876bf7b64c05cce1c477": `లింక్ కాపీ చేయబడింది`,
    "ce5bf551379459c1c61d2a204061c455": `స్థానం`,
    "ccffe10fe854018805baf0d3d9327285": `ప్రొఫైల్‌ను నిర్వహించండి`,
    "49ee3087348e8d44e1feda1917443987": `పేరు`,
    "10ac3d04253ef7e1ddc73e6091c0cd55": `తరువాత`,
    "bafd7322c6e97d25b6299b5d6fe8920b": `లేదు`,
    "31916d996c9f53949315692bc897f0c7": `సంబంధించింది కాదు? చూడటం కొనసాగించండి`,
    "e81c4e4f2b7b93b481e13a8553c2ae1b": `లేదా`,
    "0d5e50bf1643118ee026e5d19226542a": `దయచేసి స్పెల్లింగ్‌ను తనిఖీ చేయండి లేదా వేరే దేనికోసం శోధించడానికి ప్రయత్నించండి`,
    "64ed408b000d73143e6ab1b5acb7c26e": `దయచేసి ప్రొఫైల్ ఎంచుకోండి`,
    "fa2ead697d9998cbc65c81384e6533d5": `గోప్యతా విధానం`,
    "7595f48afc2f088bc1ff3313ceeb3605": `దరఖాస్తు చేయడానికి విధానం`,
    "cce99c598cfdb9773ab041d54c3d973a": `ప్రొఫైల్`,
    "665d0095958d696322be90e4eb606977": `ఈ సంబంధంతో ప్రొఫైల్ ఇప్పటికే ఉంది. మీరు ప్రస్తుత ప్రొఫైల్‌ను నవీకరించవచ్చు`,
    "27bcc51421612805a9c006f24a4d6dc6": `పథకాన్ని తొలగించండి`,
    "f8b66e3c488bb1df16cb3c7bb21447f0": `కావలసిన పత్రాలు`,
    "82609dd1953ccbfbb4e0d20623193b56": `ఇంటికి తిరిగి రా`,
    "c9cc8cce247e49bae79f15173ce97354": `సేవ్`,
    "c320cdeffafbb2d60d6d759619c6b5e2": `ప్రొఫైల్ సేవ్`,
    "ab9753c948520fb02c20831980bad53a": `నా పథకాలు`,
    "827452688eed02a12178e96f924ac529": `పథకం`,
    "cb80cf588ba5a0d3dc067b0b0ef219e9": `పథకాలు`,
    "1925ba54f16355af0964df7dfa145761": `మీ కోసం పథకాలు`,
    "13348442cc6a27032d2b4aa28b75a5d3": `వెతకండి`,
    "32df01b9cf0491a879250b58ba2744ba": `శోధన ఫలితాలు`,
    // "988cddb5e417156bd3603983dc0c32e5":`శోధన పథకాలు`,
    "0afcf4c1ba72c441b41fbafaab253fc6": `స్థానాన్ని ఎంచుకోండి`,
    "be2933038f93e25262400220080c111f": `రాష్ట్రాన్ని ఎంచుకోండి`,
    "dfe1d316ec9338e23c892b573667b9bb": `మీ ఎంపికను ఎంచుకోండి`,
    "c2ba7e785c49050f48da9aacc45c2b85": `సేవ`,
    "992a0f0542384f1ee5ef51b7cf4ae6c4": `సేవలు`,
    "48ecd536efbb3622296aac8de326fa38": `క్షమించండి, అందించిన సమాచారం ఆధారంగా, మీరు ఈ పథకానికి అర్హులు కాదు`,
    "4cd9a992dc66802a4b44c366881957d1": `క్షమించండి, ఫలితం కనుగొనబడలేదు`,
    "f86301756ec6964cb96e2b80a38f4162": `క్షమించండి! మేము మీ కోసం సరిపోయే పథకాలను కనుగొనలేకపోయాము`,
    "46a2a41cc6e552044816a2d04634545d": `రాష్ట్రం`,
    "efd9a0ba1e9d553a070028627fdcae18": `సర్వే వివరాలు`,
    "4cd61af85d0ec40f50349cd7c3054ace": `స్విచ్ ప్రొఫైల్`,
    "1d8cd2bfc6943dcf70236f7d351572a0": `నిబంధనలు & షరతులు`,
    "4f7e556abaf92ce8061ad57cc4e7edd1": `ఇది అవసరం`,
    "371999537b8bb6e7f0099b0c40e0e41d": `ఈ ప్రొఫైల్ ఇప్పటికే సృష్టించబడింది. మీరు ప్రొఫైల్‌ను నవీకరించలేరు.`,
    "9eed21a5afd3ba6841c523b0d70599c7": `పథకాలను చూడటానికి 2 కన్నా తక్కువ ఉన్న ఏదైనా వర్గంపై క్లిక్ చేయండి లేదా నిర్దిష్ట వర్గం కోసం ఒక సర్వేను అమలు చేయడానికి 2 కన్నా ఎక్కువ వర్గంపై క్లిక్ చేయండి`,
    "37528894f18a085f9a078496a68eaaa8": `ట్రెండింగ్`,
    "d876a9fe1aecb3ffc05a5639ebe22000": `మళ్ళీ ప్రయత్నించండి`,
    "06933067aafd48425d67bcb01bba5cb6": `నవీకరణ`,
    "fba7f6c863513d97922faab074d3c716": `ప్రొఫైల్‌ను నవీకరించండి`,
    "90b1f6651953fcf8b43ddc0da6760aaf": `వినియోగదారు వివరాలు`,
    "d8d7ad6abfd08c27baa8bedb78c72ddb": `అన్నీ చూడండి`,
    "acac138f2e6f039df2e1fed2f2c0bc44": `EasyGovకి స్వాగతం`,
    "6ebb2e31989c843ea65feefa43237dad": `మీరు మీ ఖాతా నుండి ప్రొఫైల్‌ను తొలగించినప్పుడు, మీరు నిర్దిష్ట ప్రొఫైల్ కోసం నిల్వ చేసిన డేటా లేదా సమాచారాన్ని తిరిగి పొందలేరు. ఎంచుకున్న ప్రొఫైల్ కోసం అన్ని చురుకైన మరియు సేవ్ చేసిన పథకాలు కూడా తొలగించబడతాయి`,
    "6a64fbe7b065fc8579f2521e0054de02": `మీరు మీ లాబ్‌హార్తి ఖాతాను తొలగించినప్పుడు, మీ ప్రొఫైల్ మరియు మీ ఖాతాతో అనుబంధించబడిన ఇతర ప్రొఫైల్‌లలో నిల్వ చేసిన ఏదైనా డేటా లేదా సమాచారాన్ని మీరు తిరిగి పొందలేరు. మీ చురుకైన మరియు సేవ్ చేసిన పథకాలన్నీ కూడా తొలగించబడతాయి`,
    "93cba07454f06a4a960172bbd6e2a435": `అవును`,
    "3aeee34bea95f2b0ced72be632778670": `మీరు ఏ పథకాలను సేవ్ చేయలేదు`,
    "cb32c835ee043e0e2faf4860e46669ae": `ఈ పథకం/సేవ సమీక్షలో ఉంది. దయచేసి కొంతకాలం తర్వాత మళ్ళీ ప్రయత్నించండి.`,
    "c2e0ede88489afe5739c8973aa03a787": `మీ ప్రొఫైల్‌కు సరిపోయే పథకాలు లేవు`,
    "28d2dfa9b435bd81cb2f7f0499b846b3": `పథకాలను కనుగొనండి`,
    "52561ab2d4640b4e299e817b6ba79173": `లాబ్హార్తికి స్వాగతం`,
    "fb0fc4b5fdb2bef7b1c42f2cfd523c2d": `ప్రయత్నాలు మిగిలి ఉన్నాయి`,
    "e13e1cd5f02de37413f9497cc5b65de3": `మీ ఖాతా 30 నిమిషాలు లాక్ చేయబడింది. దయచేసి ఆ సమయం తర్వాత ప్రయత్నించండి`,
    "f2a6c498fb90eebdu72k7f888fce3b18": `స్వాగతం`,
    "1172b2ea87bed4861c8c43a201d53031": `నా సేవలు`,
    "bcc254b55c4a1babdf1dcb82c207506b": `ఫోన్`,
    "8458bad978f4fd6d6c7f8ec2ec0824b4": `పాస్వర్డ్ మర్చిపోయారా?`,
    "d67850bd126f070221dcfd5fa6317043": `చేరడం`,
    "619d59e2ea9f9bb9fe287404dd3ca931": `అన్ని సేవలు`,
    "6b46ae48421828d9973deec5fa9aa0c3": `క్రమబద్ధీకరించండి`,
    "104f0a6879399d6285ab770ed0dbc257": `సేవను తొలగించండి`,
    "0b3937a9dd481d527fcc72adaa735b57": `లాబ్హార్తి ఖాతా లేదా?`,
    "2f453cfe638e57e27bb0c9512436111e": `కొనసాగించండి`,
    "f2fdea440d768b85591e936a803c3631": `సైన్ ఇన్`,
    "82b1fbd43ae27918a37b212a4f60a9ad": `సమర్పించండి`,
    "31693e2e9aa2e5be1bf3ae45ede9c98d": `మీరు అర్హత లేని పథకాలు`,
    "d43cd6a49673f0f486ed7fbea1a5fd8a": `ఆధార్ సంఖ్య`,
    "3a06727e678189b8502408cb9b50b437": `ప్రొఫైల్ మార్చండి`,
    "0323de4f66a1700e2173e9bcdce02715": `లాగ్అవుట్`,
    "644cd55dbe4d01e7e00fc7fc458f9b8f": `మీరు ఖచ్చితంగా లాగ్అవుట్ చేయాలనుకుంటున్నారా?`,
    "c0fb5959a7a8d3ea991f9edb0b1a9e85": `దయచేసి ఐడిని ఎంచుకోండి, మేము మీ కోసం పథకాలను పొందుతాము`,
    "827a37fb93758383027c582a39ec6162": `ఐడి రకం`,
    "cb3441fa3e1291a76dd5ea72e656bca3": `ఏ ఐడి లేకుండా కొనసాగించండి`,
    "99dea78007133396a7b8ed70578ac6ae": `ప్రవేశించండి`,
    "02aeaf2672eb06861c144004ef2248d2": `లాగిన్ ఖాతా`,
    "d4d208eb2d8ccd525a64be73f3521dc5": `చెల్లని ఆధారాలు`,
    "e8055d9577ec8edf34ee15a88a937daa": `ఖాతా లేదా?`,
    "942a3ef743c4e176f75d5223455f7e1a": `మీ 10 అంకెల ఫోన్ నంబర్‌ను నమోదు చేయండి`,
    "21bdc5689c12595ae14298354d5550d5": `ఖాతా కలిగి ఉన్నారా?`,
    "dc647eb65e6711e155375218212b3964": `పాస్వర్డ్`,
    "0b3e8b8021c9b2fafee35ae01ac2cfb9": `${window.projectName} అనువర్తనం మీ కోసం మరియు మీ కుటుంబానికి ఉత్తమమైన ప్రభుత్వ సంక్షేమ పథకాలను కనుగొనడంలో మీకు సహాయపడుతుంది.`,
    "05aca668fa6754247f5094c042fb64ac": `ఫోన్ ధృవీకరణ`,
    "f9bc92a6602b9692a0ba578120c9c441": `మీ OTP కోడ్‌ను ఇక్కడ నమోదు చేయండి`,
    "d9d1e60e50119d9752001d4196ee6b3c": `OTP`,
    "6e95a24fa2a98c2f59c96a24b182ccfd": `మీకు ఏ కోడ్ రాలేదా?`,
    "f922096c878b694ec7a3d9282a55904c": `క్రొత్త కోడ్‌ను తిరిగి పంపండి`,
    "32b830cef750168335d96aff4dd98782": `నా ఉద్యోగాలు`,
    "bbd26bc8ce3623addc9c54c36f692f8d": `మీ కోసం ఉద్యోగాలు`,
    "67cb88ed21d4232c4681a5e648554e09": `దరఖాస్తు రుసుము`,
    "b90455b71eb5e2399be9d20e23c1a1db": `దరఖాస్తు చేయడానికి చివరి తేదీ`,
    "bfe72f18052c940de13501ae9877c3b0": `ఎలా దరఖాస్తు చేయాలి`,
    "4f1f44ef7ce0c8a111f33fe400a6e4d7": `ఎంపిక విధానం`,
    "0f5679acafa674831691cfa01d17501e": `ఉద్యోగ నోటిఫికేషన్`,
    "a45ca50bcc7830af2f84fd046648ec92": `మీ ప్రొఫైల్‌కు సరిపోయే ఉద్యోగాలు లేవు`,
    "c4bcece293eb1ad7f09d4cf9c2037ea6": `ఉద్యోగాలు కనుగొనండి`,
    "5dc52ca9ffdc26147075682c92a0a853": `పోస్టులు`,
    "3f99ba6fbcb46f28fb24f271fc3c02c1": `ఉద్యోగాలను చూడటానికి ఏదైనా వర్గంపై క్లిక్ చేయండి. ఏదైనా ఉద్యోగం కోసం ఒక సర్వేను అమలు చేయడానికి కార్డుపై క్లిక్ చేయండి`,
    "2078989a6c09fdd98efc35d3c1d1de3d": `ఉద్యోగాన్ని తొలగించండి`,
    "08c1517355f6e7313c4d629a193ac69a": `మీ సేవ్ చేసిన ఉద్యోగాల నుండి మీరు ఈ ఉద్యోగాన్ని తొలగించాలనుకుంటున్నారా?`,
    "e614dff8cba6f73ae7cee729fe0091dd": `చెల్లని OTP`,
    "c46c1e69c89811f03dc73feb09273409": `OTP అవసరం`,
    "ae3bb2a1ac61750150b606298091d38a": `కొత్త పాస్వర్డ్`,
    "887f7db126221fe60d18c895d41dc8f6": `పాస్వర్డ్ను నిర్ధారించండి`,
    "70d9be9b139893aa6c69b5e77e614311": `నిర్ధారించండి`,
    "20aedd1e6de4dcf8d115b5a7424c58d7": `పాస్వర్డ్ అవసరం.`,
    "841a389ec69697c28df7b9739419d21d": `పాస్వర్డ్ 6 అక్షరాల పొడవు ఉండాలి.`,
    "bcb9407d90fd17ccff50587f5edfe987": `పాస్వర్డ్ సరిపోలలేదు.`,
    "318721657b639c8ca02293211db68837": `ఫోన్ నంబర్ అవసరం`,
    "4ea3456b0e1b463f50b81c79230cf070": `చెల్లని ఫోన్ నంబర్`,
    "bd454fc3d32f4425d146d3e861409871": `వినియోగదారు ఉన్నారు`,
    "ab48b978d197e315e92e904e36d26175": `ఫోన్ నంబర్ చెల్లదు`,
    "70729403b9560d3adb142977608b81d2": `OTP పరిమితి మించిపోయింది. 4 గంటల తర్వాత ప్రయత్నించండి.`,
    "1e4dbc7eaa78468a3bc1448a3d68d906": `ఫోను నంబరు`,
    "4f2df4c330b8df125a3d75f2d69a9b5e": `OTP పంపండి`,
    "9ec54ec336b65da6179cf750de33ecd7": `పాస్వర్డ్ మర్చిపోయారు`,
    "98adc700c19f071f07a714a703a6fc1e": `ఐడి వివరాలు`,
    "f262fae0a2f17e8c1eb4e9eddb0160e4": `కొనసాగండి`,
    "49fb619c4872c491fae649c28b339ed8": `మీ ఆధారాలు మా రికార్డులతో సరిపోలడం లేదు`,
    "58557a71f9811899313234decda72d5a": `మీ ఖాతా 30 నిమిషాలు లాక్ చేయబడింది. దయచేసి ఆ సమయం తర్వాత ప్రయత్నించండి.`,
    "e7665f483813a56e9410787ce2d174ef": `ఫోన్ నంబర్ ఇప్పటికే వాడుకలో ఉంది. దయచేసి సైన్ అప్ చేయడానికి సైన్ ఇన్ చేయండి లేదా మరొక సంఖ్యను ఉపయోగించండి.`,
    "0a46fa5fb9e86e80fc94e229d1967699": `ఫోన్ నంబర్ నమోదు కాలేదు. దయచేసి తిరిగి ప్రయత్నించండి`,
    "bdff8b1cbfb4d5623382d7b0678e707c": `పాస్‌వర్డ్ తప్పనిసరిగా కనీసం 8 అక్షరాలను కలిగి ఉండాలి, వీటిలో కనీసం ఒక అప్పర్ కేసు, ఒక లోయర్ కేసు, ఒక సంఖ్య మరియు ఒక ప్రత్యేక అక్షరాలు ఉన్నాయి`,
    "d55085482b3e8da780755832c17d7d60": `చివరి తేదీ`,
    "988cddb5e417156bd3603983dc0c32e5": `శోధన పథకం మరియు సేవలు`,
    "261b39077b81875e115baa22bc6ce04c": `ఉద్యోగాలను చూడటానికి ఒక నిర్దిష్ట వర్గానికి చెందినది, దయచేసి వర్గంపై క్లిక్ చేయండి`,
    "bdb53070a3fc01196ae38b059a4b42ff": `ఉద్యోగం తొలగించండి`,
    "ede1fcad3d6bf808fc948df7d22e1536": `క్షమించండి! మేము మీ కోసం సరిపోయే ఉద్యోగాలు కనుగొనలేకపోయాము`,
    "0c4fa7a0201f434b0ef84494d0aac07a": `క్షమించండి, అందించిన సమాచారం ఆధారంగా, మీరు ఉద్యోగానికి అర్హులు కాదు`,
    "e4defbb7cdf529fa3606749a82c06932": `పోస్టులు`,
    "25d2401d71f4ba1ba7685559c1b77927": `మీ ప్రొఫైల్ ఆధారంగా, మీరు మీకు ఏ ఉద్యోగానికి అర్హత సాధించలేదు. <br> మేము నిరంతరం ఎక్కువ ఉద్యోగాలను జోడిస్తున్నాము, దయచేసి తరువాత తనిఖీ చేయండి.`,
    "ea7c2400c0ae5f58608cb5051ee852f3": `ప్రస్తుతానికి మీ రాష్ట్రంలో ఉద్యోగాలు లేవు, మేము నిరంతరం అప్‌డేట్ చేస్తున్నాము, దయచేసి మళ్ళీ మాతో తనిఖీ చేయండి`,
    "24fe48030f7d3097d5882535b04c3fa8": `గడువు ముగిసింది`,
    "9dc0186e2d5d3398ffee6ec964b15dc0": `గడువు ముగిసిన అన్ని ఉద్యోగాలు గడువు తేదీ నుండి 30 రోజుల వ్యవధి తర్వాత మీ “నా ఉద్యోగాలు” నుండి తొలగించబడతాయి.`,
    "34a934c0c86d866df9d14f8a6e90c637": `అర్హత లేని కారణం`,
    "a97ea56b0e00b2379736ae60869ff66a": `ప్రశ్న`,
    "8e9e3212461f14c8dbea6456c920bcbc": `అర్హత విలువలు`,
    "c4dce677291fa5bc05276ffe93c5d8f2": `మీరు అందించిన విలువ`,
    "9a917c4a4f546c6072bd61ae617763bf": `పథకం మీ కోసం కాదు`,
    "e57c13893a290ce882569b5638ba7b82": `మీరు నమోదు చేసిన ఐడి కనుగొనబడలేదు. దయచేసి మళ్ళీ ప్రయత్నించండి.`,
    "e26859a59ede4ab8d8ea0213b68fa732": `సోర్స్ సిస్టమ్ ప్రస్తుతం స్పందించడం లేదు, దయచేసి కొంతకాలం తర్వాత ప్రయత్నించండి.`,
    // "e26859a59ede4ab8d8ea0213b68fa732":`సోర్స్ సిస్టమ్ ప్రస్తుతం స్పందించడం లేదు, దయచేసి ఐడి లేకుండా కొనసాగండి లేదా కొంతకాలం తర్వాత ప్రయత్నించండి.`,
    "d2235c7ecbbbae1bf62833dade7486f5": `ప్రొఫైల్ వివరాలు గోల్డెన్ రికార్డ్ నుండి తీసుకోబడ్డాయి.`,
    "346df6b73ae19a6619f26648cf415522": `అనర్హమైన పథకాల జాబితా ప్రొఫైల్ డేటాపై ఆధారపడి ఉంటుంది.`,
    "6e0fe1553e84e34da97a6c4c9040b31b": `లాబ్హార్తి`,
    "27559de2125101ccb786807bceb46aa1": `భారతదేశం`,
    "936b6a0effe4990e8f5420a0099fa537": `EasyGov`,
    "8cf04a9734132302f96da8e113e80ce5": `హోమ్`,
    "923b39c56654bb6264b6638ba8386b3c": `మీ ప్రొఫైల్‌ను సృష్టించడానికి సైన్ ఇన్/సైన్ అప్ చేయండి`,
    "52f2ba99491a1bae953303a4eaf50196": `OTP తో లాగిన్ అవ్వండి`,
    "18e0c8084d60f55016d67d4e2ca507ce": `SSO తో లాగిన్ అవ్వండి`,
    "d3d77957f18f1e208cb5b0caa07bec5c": `మరిన్ని పథకాలను కనుగొనండి`,
    "6a26f548831e6a8c26bfbbd9f6ec61e0": `సహాయం`,
    "b2119dd0c37bb396e24199d5ffbd0c72": `స్క్రీన్ రీడర్ యాక్సెస్`,
    "34b6cd75171affba6957e308dcbd92be": `సంస్కరణ`,
    "4221d3e17c6eca2ca6337251a3cf9c4e": `చివరిగా నవీకరించబడింది`,
    "ece2c7eaaac9f27b055e6e482ab776b9": `సమాచార శోధన`,
    "ad8f678ccf4a4a34b46f01c74f25b91b": `వెబ్‌సైట్‌లో సమాచారాన్ని యాక్సెస్ చేయడంలో వినియోగదారులకు సహాయపడటానికి, “ఇక్కడ శోధించండి” ఎంపిక అందించబడుతుంది. ఇక్కడ, కన్నడలో టైప్ చేసే ఎంపిక అందుబాటులో ఉంది. శోధిస్తున్నప్పుడు, యునికోడ్ ఫాంట్ వాడకం తప్పనిసరి. ఆంగ్లంలో శోధించే ఎంపిక కూడా అందించబడుతుంది.`,
    "698aa21b21c2252839e5e54a0d2f12ef": `ఇంటర్నెట్ ప్రాప్యత`,
    "4ac2856693f5c8b4fc6cdaf669ed2d74": `ఇంటర్నెట్ నెమ్మదిగా లేదా అందుబాటులో లేనట్లయితే, వెబ్‌సైట్ రూపకల్పనలో మార్పు ఉండవచ్చు లేదా కొన్ని ఉప పేజీలు తెరవకపోవచ్చు.`,
    "0da22543a45316a826571412b3d5953c": `వెబ్‌సైట్ వరల్డ్ వైడ్ వెబ్ కన్సార్టియం (డబ్ల్యు3సి) వెబ్ కంటెంట్ ప్రాప్యత మార్గదర్శకాలు (డబ్ల్యుసిఎజి) 2.0 స్థాయి AA కి అనుగుణంగా ఉంటుంది. ఇది స్క్రీన్ రీడర్స్ వంటి సహాయక సాంకేతిక పరిజ్ఞానాన్ని ఉపయోగించి దృశ్య బలహీనత ఉన్నవారికి వెబ్‌సైట్‌ను యాక్సెస్ చేస్తుంది. వెబ్‌సైట్ యొక్క సమాచారం జావ్స్, ఎన్విడిఎ, సఫా, సూపర్నోవా మరియు విండో-ఐస్ వంటి విభిన్న స్క్రీన్ రీడర్లతో అందుబాటులో ఉంటుంది.`,
    "cb976b928eacd65e6342cfa78bf196da": `కింది పట్టిక వేర్వేరు స్క్రీన్ రీడర్ల గురించి సమాచారాన్ని జాబితా చేస్తుంది`,
    "786f669131d80fbed4572a520c28c14d": `కంటెంట్ సృష్టించబడింది మరియు EasyGov యాజమాన్యంలో ఉంది`,
    "fc290a7672f96fd8b94a9659c8c02322": `| అన్ని హక్కులూ ప్రత్యేకించుకోవడమైనది`,
    "6016a2b341113bf496b719905398ecd2": `కాపీరైట్`,
    "0e8a85115f2c3ef0058c70f30a9b6deb": `దయచేసి మీ రిజిస్టర్డ్ మొబైల్ నంబర్‌కు పంపిన OTP ని నమోదు చేయండి`,
    "34f8ccda5172e4eac9a8a014559b38c1": `దయచేసి మీ పాస్‌వర్డ్‌ను సెట్ చేయండి`,
    "548899be111ad732b37786783dfbd6d8": `ఇంటర్నెట్ కనెక్షన్ లేదు`,
    "5c3a1593ca1d34226359df44f3d45f2f": `దయచేసి కొంతకాలం తర్వాత మళ్ళీ తనిఖీ చేయండి.`,
    '35043eeac5651298d4bb6c100e071745': `మీరు మూడవ పార్టీ వెబ్‌సైట్‌కు మళ్ళించబడతారు. మీరు కొనసాగించాలనుకుంటున్నారా?`,
    "6182e08f3974a70ac4883b5fceaa14c0": `దయచేసి మీ రిజిస్టర్డ్ మొబైల్ నంబర్‌కు పంపిన OTP ని నమోదు చేయండి`,
    "306fb6389c3b3fd2835af15cfc7c66e9": `కుటుంబ ఐడిని మార్చండి`,
    "63fc7651ee1dbf6868a43a5b5fa8b581": `అక్షరాలు మాత్రమే అనుమతించబడతాయి`,
    "6903fbe8375d52217c15618170f4078a": `30 అక్షరాలు మాత్రమే అనుమతించబడతాయి`,
    "9ab37820a722ce13e80a3d0aa468d6dc": `*అవసరం`,
    "0df1f18a2fa23cd60990e47692f99e07": `అప్లికేషన్ లింక్`,
    "a60852f204ed8028c1c58808b746d115": `అలాగే`,
    "edbd5921e9c7168e04cbe6f75ee76174": `మీ ప్రొఫైల్ ఆధారంగా, మీరు ఏ పథకానికి అర్హత సాధించలేదు. మేము నిరంతరం మరిన్ని పథకాలను జోడిస్తున్నాము, దయచేసి తరువాత తనిఖీ చేయండి.`,
    "a203c39a340e1d45069b1ba64e1d003a": `శ్రద్ధ!`,
    "037673ab5a84897e0ee43ca37f7680f6": `మీరు మీ డేటాను మార్చినట్లయితే అదే మీ ప్రొఫైల్ డేటాలో కూడా నవీకరించబడుతుంది. మీరు కొనసాగించాలనుకుంటున్నారా?`,
    "d1d16cede248b826a431ea11132567cb": `మీరు మీ ధృవీకరించబడిన డేటాను మార్చినట్లయితే, అది తిరిగి ధృవీకరించబడుతుంది మరియు మీ స్థితి మార్చబడుతుంది. ఇది మీ అప్లికేషన్ యొక్క తిరస్కరణ లేదా పెరిగిన ప్రాసెసింగ్ సమయం యొక్క అధిక అవకాశాలకు దారితీయవచ్చు.`,
    "23a0ca84a7fd64eee8248bd1dfc77185": `పేరు మార్పు`,
    "3b383caaaa6113dd83b381b43a97d7ee": `ఈ వినియోగదారు కోసం ఇప్పటికే ఒక ప్రొఫైల్ ఉంది, క్రొత్త కుటుంబ ఐడిని జోడిస్తే మునుపటి వివరాలను తొలగిస్తుంది. నిర్ధారించండి`,
    "e0efbdcea0abe735dbf887292a5670fa": `సోదరుడు`,
    "ad6e7652b1bdfb38783486c2c3d5e806": `స్వీయ`,
    "19cda37369e2d2015e66b9d478ffbe15": `సోదరి`,
    "81e053d78f01540e9cd6fb68a1c03976": `ఇంకెవరో`,
    "b8e18040dc07eead8e6741733653a740": `తల్లి`,
    "a30ca91be97a9cedc0d2426e54b0e782": `తండ్రి`,
    "784c01f6b735be57175e2f438297c741": `జీవిత భాగస్వామి`,
    "1968ab9fdfedf558714bf6caec9a1234": `కొడుకు`,
    "7bc1cb93f51f61bde0e90b20c81ebf88": `కుమార్తె`,
    "25f34d5943da3f09a2aa6358193a41bd": `దరఖాస్తు చేసుకోవడానికి కొనసాగండి`,
    "79dff508ab2e4d272f0630d001271eb1": `మరిన్ని ఉద్యోగాలను అన్వేషించండి`,
    "b6ebe1e750185946ed41be9191410612": `మీరు లాబ్హార్తి ద్వారా దరఖాస్తు చేసుకోగలిగే వివరాలను అందించాలి.`,
    "af1899084d8361b9afb9187da988ff9c": `“క్షమించండి, మేము మీ డేటాను పొందలేకపోయాము”.`,
    "1b56218ddf0a290241d661d26253d51f": `{{attempts}} ప్రయత్నం (లు) మాత్రమే`,
    "f299cff2484fdb361f4fc3189490d909": `మీరు అందించిన కింది సమాచారం మీ ప్రభుత్వ పునరుద్ధరణతో సరిపోలలేదు:`,
    "1edf5362e5f4af6f370a7f34c3136a7a": `ధృవీకరణ విఫలమైంది`,
    "dcf9ac5128c95d88e068e223dfe01d27": `ధృవీకరణ స్థితి`,
    "bc910f8bdf70f29374f496f05be0330c": `మొదటి పేరు`,
    "77587239bf4c54ea493c7033e1dbf636": `చివరి పేరు`,
    "14fcbe1dd0f7a39f0195aba46cf89d17": `వివరాలను ధృవీకరించండి`,
    "b6121db5ee47deeb0ca42d5dea0b2225": `ప్రయత్నాలు విఫలమయ్యాయి`,
    "839301cec5574d7c3256afce4fb85467": `మీ కుటుంబ వివరాలను ధృవీకరించడానికి మీరు చెల్లుబాటు అయ్యే ప్రయత్నాలను మించిపోయారు. మీ ధృవీకరణ నిరోధించబడింది`,
    "6733aa56fd001b9bf479650f3950688a": `మీరు స్కీమ్ అప్లికేషన్ పేజీకి దర్శకత్వం వహిస్తున్నారు`,
    "9303c9bd4f8178680dc382adbfcd62af": `ఈ ఖాళీని తప్పనిసరిగా పూరించవలెను.`,
    "53a04cfc3d09c9b2dfb8349791c62bc1": `మీ పేరు ఈ కుటుంబంలోని ఏ సభ్యుడితో సరిపోలలేదు`,
    "1ca35256fcb85c1ba9ea7ac1095462ad": `మీ కుటుంబ వివరాలను ధృవీకరించడానికి మీరు చెల్లుబాటు అయ్యే ప్రయత్నాలను మించిపోయారు. మీ ధృవీకరణ 3 గంటలు నిరోధించబడింది`,
    "0e3a917198575c463bb4fdcb7332b03a": `చెల్లని ఆధార్ కార్డ్ నంబర్`,
    "630058029627ce58e7571e7a5b6ec591": `పూర్తి పేరు`,
    "072355e896ac1a999d2fa0aa208f94ee": `ఈ పథకం మీ “నా పథకాల” లో సేవ్ చేయబడింది.`,
    "789189638f8a301304dfadeb61036dd9": `ఈ సేవ మీ “నా సేవల్లో” సేవ్ చేయబడింది.`,
    "ab8023b4fc2395738b8fd2df440641a9": `ఈ ఉద్యోగం మీ “నా ఉద్యోగాలలో” సేవ్ చేయబడింది.`,
    "671dec8fc4e3dc847c1b0d7b67b4e6e1": `సంబంధం`,
    "f252b4e2f3b2963d330c8cde8e9cdbd9": `అందుబాటులో ఉంటే`,
    "ef51899b57258db72f15b4703a8a86ba": `పాన్ ఇండియా ప్రభుత్వ సంక్షేమ పథకాలు, సేవలు & ఉద్యోగాలు కోసం పౌరులకు కార్యాచరణ సమాచారాన్ని యాక్సెస్ చేయడానికి ఒకే వేదిక.`,
    //New Translations for Phase 1:`#VALUE!`,
    "7510e00267e89c78470e79b42ecd6463": `అనువర్తనాన్ని మీ స్వంత భాషలో అన్వేషించండి`,
    "83218ac34c1834c26781fe4bde918ee4": `స్వాగతం`,
    "1d00e7dce692e8dc3f6877f035e3a616": `లేదా`,
    "e0cf58fa3413df99ae83109ebb9a41a3": `OTP ను మళ్ళీ పంపు`,
    "6ed83b24865902c6fd90bbd15247e042": `పాస్‌వర్డ్ సెటప్`,
    "9157806ea93e82c6843e29353af0339f": `OTP ని అభ్యర్థించండి`,
    "69f18e98d2dd54118ade84732dc3a5e0": `పిన్ ధృవీకరించండి`,
    "2dd4472245a696bc0b4b944db2a8b519": `వ్యక్తిగత`,
    "a274f4d4670213a9045ce258c6c56b80": `నోటిఫికేషన్‌లు`,
    "407456e575dee5a4b84a19a7111a4a4d": `మీరు 2 సంఖ్యలతో తక్కువ ఉన్న ఏదైనా స్కీమ్ వర్గంపై క్లిక్ చేసినప్పుడు, మీరు అర్హత సాధించే పథకాలను మీరు చూడగలుగుతారు, మీరు 2 కంటే ఎక్కువ సంఖ్యలతో ఏదైనా స్కీమ్ వర్గంపై క్లిక్ చేసినప్పుడు, వర్గం నిర్దిష్ట సర్వే మీకు మరింత ఇవ్వడానికి నడుస్తుంది ఎంచుకున్న వర్గానికి పథకాల శుద్ధి చేసిన జాబితా.`,
    "9139eb8e78808acbd15244d4eb9f1bc3": `మరిన్ని పథకాలను అన్వేషించండి`,
    "c61dd5f0e5b5389306ad5221ec2cd795": `ఈ పథకం మీ “నా పథకాల” లో సేవ్ చేయబడింది`,
    "f2a6c498fb90ee345d997f888fce3b18": `తొలగించు`,
    "469bad4859f3c33fcb8ce09314c3e895": `సర్వే వివరాలను సమీక్షించండి`,
    "7dce122004969d56ae2e0245cb754d35": `సవరణ`,
    "c5984b3fe02f7b06d77fb538d842b0fc": `సర్వే వివరాలను నవీకరించండి`,
    "6cf33ce1a7a11a7178131567c777b637": `అదే పేరు మరియు సంబంధంతో ప్రొఫైల్ ఇప్పటికే ఉంది`,
    "fffcb4d1e12c96b2f571f77ad25878b8": `కారణం`,
    "e8d89b6875d217a990d47701138e687a": `మీరు ఖచ్చితంగా ఈ డేటాను తొలగించాలనుకుంటున్నారా?`,
    "aa98db08fed41a99a00aac36f521540f": `చివరిగా ధృవీకరించబడింది`,
    "5da618e8e4b89c66fe86e32cdafde142": `నుండి`,
    "253ec560db0637f6a0e53aefcbd4be0c": `మీరు లాబ్‌హార్తి ఖాతాను తొలగించినప్పుడు, మీరు మీ ప్రొఫైల్‌లో సేవ్ చేసిన ఏ డేటా లేదా సమాచారాన్ని తిరిగి పొందలేరు. సేవ్ చేసిన అన్ని పథకాలు మరియు {{name}} for కోసం నిల్వ చేయబడిన సమాచారం కూడా తొలగించబడుతుంది.`,
    "c0e70d5bfc28ae3aadfb8f85697c54d5": `మీ డేటా విజయవంతంగా నవీకరించబడింది.`,
    "09b73aba9204a05ba5eaf0ea471fdc8c": `EasyGov అనేది సురాజ్య సర్వీస్ ప్రైవేట్ లిమిటెడ్ యొక్క రిజిస్టర్డ్ ట్రేడ్‌మార్క్. EasyGov యొక్క ప్లాట్‌ఫారమ్-యాజ్-ఎ-సర్వీస్ మోడల్ ప్రభుత్వ సంక్షేమాన్ని సంప్రదాయవాద, రియాక్టివ్ మరియు ప్రోగ్రామ్ సెంట్రిక్ నుండి దశలవారీగా ప్రగతిశీల, క్రియాశీల మరియు కుటుంబ కేంద్రంగా మారుస్తుంది. పౌరుల ప్రయోజనాల కోసం సంక్షేమ వ్యవస్థ యొక్క ఈ సంక్లిష్ట ప్రయాణాన్ని పరిష్కరించడానికి EasyGov వివిధ ప్రభుత్వాలకు సహాయం చేస్తోంది. దయచేసి మరింత సమాచారం కోసం <a href='https://easygov.co.in/' target='_blank'> https://easygov.co.in/</a>ని సందర్శించండి`,
    "8f241c62a9523b05bf0b6b16a09d856d": `భాష మార్చు`,
    "1ccbe6084301f9b1e18e3e7caf723ae1": `మీరు ఖచ్చితంగా లాగ్అవుట్ చేయాలనుకుంటున్నారా?`,
    "32ca0a0f69103bad1e6f134d5a8e36d7": `ఒక నిర్దిష్ట వర్గం కోసం ఉద్యోగాలను చూడటానికి, దయచేసి ఏదైనా ఉద్యోగ వర్గంపై క్లిక్ చేయండి`,
    "d6e6cb19e3b9c02f89d6cd54cfa7c613": `వ్యాపారం`,
    "b1c94ca2fbc3e78fc30069c8d0f01680": `అన్నీ`,
    "1dd1c5fb7f25cd41b291d43a89e3aefd": `ఈ రోజు`,
    "ebfe9ce86e6e9fb953aa7a25b59c1956": `నిన్న`,
    "4b2ef3655b5d6d48f1fa41892d0043b9": `నోటిఫికేషన్‌లు లేవు`,
    "094f537cfb139894f1412200c78e87b1": `మీరు ఎంచుకున్న నోటిఫికేషన్‌లను తొలగించాలనుకుంటున్నారా?`,
    "4201c11dc26b42deb90cb119c0b12fdb": `నోటిఫికేషన్‌ను డౌన్‌లోడ్ చేయండి`,
    "ae45c43efe0011f67ec52003c0d8f916": `<b> సూచన </b>: 'పాస్‌వర్డ్' కనీసం 8 <br> అక్షరాలను కలిగి ఉండాలి, వీటిలో కనీసం ఒక ఉన్నత కేసు, ఒక లోయర్ కేసు, ఒక సంఖ్య మరియు ఒక ప్రత్యేక అక్షరాలు ఉన్నాయి.`,
    "248336101b461380a4b2391a7625493d": `సేవ్ చేయబడింది`,
    "f03ab16cd58372c77ba45a3d9a5a1cb9": `ముసాయిదా`,
    "a4d158b65f20725f9fe9c1763e9805c8": `ప్రైవేట్ ప్రాయోజిత`,
    "7fe12d923db43febcc5d8ea18df632ca": `మీ కెవైసి పత్రాన్ని ఎంచుకోండి`,
    "d8ce714c8a76133a8d5746e3bfdd7ef7": `కెవైసి`,
    "4f67fe16b274bf31a67539fbedb8f8d3": `దస్తావేజు పద్దతి`,
    "5127fa87ed375848481bfe9c786d8022": `మీ కెవైసి ని పూరించండి`,
    "8f75e3041034138aba310ba3f097ed24": `పత్ర సంఖ్య`,
    "9abfb506bebd1e72069f0be0014986dc": `చెల్లని పత్ర సంఖ్య`,
    "ecfffd1bad9944f6a50e369f63268cff": `QR స్కాన్`,
    "c541f95d69a4a507b87b32d05dd6d73c": `ఫ్రేమ్ లోపల కోడ్‌ను ఉంచండి`,
    "3876661cb83fa41284f828bd8d7fbb7b": `కెవైసి ప్రక్రియను ధృవీకరణ మరియు పూర్తి చేయడానికి కెవైసి పత్రాలు అప్‌లోడ్ చేయబడతాయి.`,
    "cc1d6f3625c4eb671d76b6ebf3f6f544": `మీ కస్టమర్ తెలుసుకోండి`,
    "08ac502bbb7652c683c1da97c7fdeb94": `{{xxxx}} మీ మొబైల్ నంబర్‌కి OTPని పంపారు`,
    "d19a88ee579190614084c2ea84379ac7": `OTP (1:00) ను తిరిగి పొందండి`,
    "7397e92003e187474e5eab39b27fcb6b": `క్షమించండి, మేము మీ డేటాను సోర్స్ సిస్టమ్స్ నుండి పొందలేకపోయాము. దయచేసి తర్వాత మళ్లీ ప్రయత్నించండి.`,
    "a943d4ac1dd2e0048bcbf85225bf2811": `మీరు OTP ని ఉత్పత్తి చేయడానికి గరిష్ట పరిమితిని మించిపోయారు, దయచేసి 3 గంటల తర్వాత మళ్లీ ప్రయత్నించండి`,
    "8dac090b39cf9b3bff9a9503531d04cf": `మీ ఖాతా ప్రస్తుతం నిరోధించబడింది, దయచేసి కొంత సమయం తర్వాత ప్రయత్నించండి.`,
    "08798caaee5cc8ea1282883901c8810b": `మిగిలి ఉన్న ప్రయత్నాలు - {{Number}} హెచ్చరిక - వరుసగా 4 విజయవంతం కాని ప్రయత్నాల తరువాత, మీ ఖాతా లాక్ చేయబడుతుంది.`,
    "33018956b3ffe20d413e3c6780f19aff": `కెవైసి వివరాలను నిర్ధారించండి`,
    "90f35a35415ca3b4c623d7c515fce1c0": `శ్రద్ధ! మీరు మీ వివరాలను అప్‌డేట్ చేస్తే లేదా మార్చినట్లయితే మీ డేటా ధృవీకరించబడదు మరియు నవీకరించబడిన డేటా కోసం డాక్యుమెంట్ ప్రూఫ్‌ను అప్‌లోడ్ చేయమని మీరు అడుగుతారు. కొనసాగడానికి "కొనసాగించండి" పై క్లిక్ చేయండి`,
    "aee68a98fd803f091082faf867c0f62b": `కెవైసి ఫారమ్ నింపండి`,
    "2e853d869dd0c3cb0a64e4938ee90647": `చెల్లని పిన్ కోడ్`,
    "8f3124e142a4000fe04a581f4f41c8da": `చెల్లని {{labour card}} సంఖ్య`,
    "dbb362212f1b8118f17633521d1e72f7": `మీ అప్లికేషన్ మీ 'డ్రాఫ్ట్ స్కీమ్'లో విజయవంతంగా సేవ్ చేయబడింది.`,
    "5c93310dd0291e121181e830cdda892e": `గ్యాలరీ`,
    "967d35e40f3f95b1f538bd248640bf3b": `కెమెరా`,
    "37bed5f24fa3752c7830c021501539a0": `మీరు ఖచ్చితంగా ఫోటోను తొలగించాలనుకుంటున్నారా?`,
    "1a263d755f169c2499c1a55f7a465b93": `మీ డాక్యుమెంట్ ఐడి ప్రస్తుతం నిరోధించబడింది, దయచేసి కొంత సమయం తర్వాత ప్రయత్నించండి.`,
    "689707127ddb398dc4b447e1dffa3a47": `మీ వివరాలను ధృవీకరించడానికి మీరు చెల్లుబాటు అయ్యే ప్రయత్నాలను మించిపోయారు. మీ ధృవీకరణ 3 గంటలు నిరోధించబడింది.`,
    "04c850a81052df23680d00d2aa1d891e": `కెవైసి స్థితి`,
    "a441e519087df0d7c8572b9fafa14ae2": `మీరు అందించిన కింది సమాచారం మీ ప్రభుత్వ రికార్డుతో సరిపోలడం లేదు.1. మీ పేరు రికార్డుతో సరిపోలలేదు.2. మీ వయస్సు రికార్డుతో సరిపోలలేదు.`,
    "75514ce9dac260a1f7abd0d85ce6af46": `మీరు అందించిన వివరాలు రికార్డులతో సరిపోలడం లేదు`,
    "5955e277da6fb75982b0f651f4ae8b92": `మేము మీ డేటాను సోర్స్ సిస్టమ్స్ నుండి పొందలేము. దయచేసి తర్వాత మళ్లీ ప్రయత్నించండి.`,
    "be60ac326400cc5ef0d173a833c4a40b": `మీ వివరాలు ధృవీకరించబడుతున్నాయి`,
    "d1083e84a9a7283ffb081263e8533509": `మీ ‘డ్రాఫ్ట్ స్కీమ్’ లో మీ అప్లికేషన్ విజయవంతంగా సేవ్ చేయబడింది`,
    "0bad551abed71c0a41ca8df804afec86": `పత్రాన్ని ఉపయోగించి కెవైసి`,
    "e6387f3e684958515af2947d8cdf01d7": `కెవైసి ఫారమ్‌ను నవీకరించండి`,
    "9c7ed3d8ac60281e90519b4ebf07a9a2": `చిరునామాను ఎంచుకోండి`,
    "31e41095bfaa14799239e8d9ba7ad438": `దరఖాస్తు వివరాలు`,
    "e1e4c8c9ccd9fc39c391da4bcd093fb2": `బ్లాక్`,
    "b9eca8f59016e8ff69a2c76fa869d23f": `లేబర్ కార్డును అప్‌లోడ్ చేయండి`,
    "6cf24d7a07132fe88b610e2b3d3def28": `స్వీయ ప్రకటన`,
    "827b05360cd22ccbaf3d085aded71570": `చిరునామా నిరూపణ`,
    "f734cd7792862bebbbb87ddc54285376": `గుర్తింపు ధృవీకరణము`,
    "5a8c686dfdba564a2801806f3827c124": `చిత్రం తప్పనిసరిగా JPG లేదా PNG ఆకృతిలో ఉండాలి. PDF ఫైల్ తప్పనిసరిగా 1MB కన్నా తక్కు.`,
    "d495cdb8315e12e3dc5fd6121ed69cbc": `చెల్లని IFSC కోడ్`,
    "cd9f177eac6411501839f7868e38767b": `మీరు ఖచ్చితంగా ఈ పత్రాన్ని తొలగించాలనుకుంటున్నారా?`,
    "565d2daf0ccaf00d354669e7551b81dd": `ప్రొఫైల్ వివరాలను సమీక్షించండి`,
    "9895dd168483d1d34ab9ebfb2b6760be": `బ్యాంకును ఎంచుకోండి`,
    "deb5c3f30f3a8381367392d6608fbe68": `దరఖాస్తు స్థితి`,
    "970324882e5bbfd2deef9e5b7e2d1160": `అప్లికేషన్‌ను డౌన్‌లోడ్ చేయండి`,
    "03b55d8ed61b625c209cc42ea0a35b8c": `ధన్యవాదాలు!`,
    "8276f0b86b5ee98d03f2eafba3590bb0": `మీకు సేవ చేయడానికి అవకాశం ఇచ్చినందుకు. మీ దరఖాస్తు విజయవంతంగా సమర్పించబడింది.`,
    "ac3122aeddff176ae67d3df2b2198d19": `ధన్యవాదాలు! మీ అప్లికేషన్ ధృవీకరణ కోసం సమర్పించబడింది, "అప్లైడ్ అప్లికేషన్స్" క్రింద అనువర్తనం నుండి మీ అప్లికేషన్ స్థితిని తనిఖీ చేయండి`,
    "73da163444abaee6a5c88532b8eda629": `మీ అనుభవం ఎలా ఉంది?`,
    "74eb855e4de6fe58228f03006c02fd8a": `సరే`,
    "d36ed38206da38c85af59039d18f1108": `<b> మీ అభిప్రాయాన్ని వివరించండి </b> (ఐచ్ఛికం)`,
    "446e92f15cd245125ccd983ef968e692": `ధన్యవాదాలు!`,
    "634a1a1ed07518476cb7a4beab87185a": `భయంకరమైన`,
    "f1b68d66337a81cfa0d2076171cba2a8": `చెడ్డది`,
    "45802158e78dd9584161629098018fe8": `మంచిది`,
    "5709082e0d4e7a2cb92c2348acf297be": `ప్రేమించాను`,
    "c629e8ac2592361ec6474f07fb75f6fc": `మీ అభిప్రాయం మెరుగుపరచడానికి కొత్త అవకాశాలను కనుగొనటానికి మాకు అనుమతిస్తుంది మరియు మీకు ఉత్తమ అనువర్తన అనుభవం ఉందని నిర్ధారించుకోండి.`,
    "871abe85eba547582d9e8f0f9c61efbd": `మాకు అభిప్రాయాన్ని పంపినందుకు ధన్యవాదాలు.`,
    "733d43480c8589b1368e5def6b480415": `అనువర్తిత`,
    "a5b739036b4d0b2bc966291747198d9b": `నమోదు చేయబడింది`,
    "0a4c051fb821b8e96d2bd564b893639b": `మీ కోసం అనుకూలీకరించిన పథకాలు లేవు`,
    "9639e32cab248434a17ab32237cb3b71": `వర్తించండి`,
    "3f68e67dc6c397aaa9d1c24c356f754f": `ధృవీకరించబడింది`,
    "d63b911814d17bb4d541141b8531a458": `ఈ పథకాలు మీ "నా పథకాలలో" సేవ్ చేయబడ్డాయి`,
    "454fd3b5167e532f28f4e3d47cd901fa": `మీకు ఇంకా సేవ్ చేసిన పథకం లేదు`,
    "5e22b63835aef7976f3ca019841b1b28": `మీరు ఖచ్చితంగా ఈ పథకాన్ని తొలగించాలనుకుంటున్నారా?`,
    "8454c7c12286671b47684cef824ffba6": `"శ్రద్ధ! మీరు మీ డేటాను మార్చినట్లయితే అదే మీ ప్రొఫైల్ డేటాలో కూడా నవీకరించబడుతుంది. మీరు కొనసాగించాలనుకుంటున్నారా?"`,
    "24a23d787190f2c4812ff9ab11847a72": `స్థితి:`,
    "85896a744f6fd84cbcd7bddb31fae8d7": `చర్య:`,
    "ccb168b5a6a86eb100dc2dee754a316b": `కారణం:`,
    "85b7d770b94264f51f31d2d7a98f69f0": `ఉపసంహరించుకోండి దరఖాస్తు`,
    "0ddc5e1c93fec761445a53ee9fd70285": `అధికారానికి పంపండి`,
    "7a4813490daea6bbe84ed02f9534570b": `అప్లికేషన్ & అటాచ్మెంట్ ధృవీకరించబడింది`,
    "f9e39c8a1b3348b654b73fcdebeb3265": `దరఖాస్తు ఇంకా ఆమోదించబడలేదు`,
    "6b76127f99b6f29c2a725da125d98760": `ప్రయోజనం పంపిణీ`,
    "4296fd320beaf61dcf979ff634cf2f2d": `చర్య అవసరం:`,
    "fc341b619c2671a430365edefc100ff1": `పూర్తి చర్యలు`,
    "4f4b8b7d27a33c83089f30dba4989be5": `అనువర్తనాలను చూడండి`,
    "2f70605f8a2f6c01ac06fe0f6d32b44c": `మీరు ఖచ్చితంగా మీ దరఖాస్తును ఉపసంహరించుకోవాలనుకుంటున్నారా?`,
    "6eaaa83f6b9b14dbed5e0779d91fffb5": `అప్లికేషన్ తిరస్కరించబడింది`,
    "6187927c14aae6cab0f87b55ef75edb1": `తిరిగి ఆప్లీ`,
    "864cacf59d640b51390faf29867c310e": `తదుపరి ప్రయోజనం కోసం కొనసాగండి`,
    "16d2b386b2034b9488996466aaae0b57": `చరిత్ర`,
    "c5b2992c0919083c2d8907f8fe1441f6": `సామాజిక-ఆర్థిక వివరాలు`,
    "d79c1042de4ec2d7e6d84d3a8f436765": `వృత్తి ప్రొఫైల్`,
    "3c8d3371dfe92bb990493d3dda09717c": `విద్య ప్రొఫైల్`,
    "181bfe2f225b876c687104878a6b43cc": `హౌసింగ్ వివరాలు`,
    "f081b3a821bf13173ac4ec3468122a8c": `ఆరోగ్య వివరాలు`,
    "fa284c99eeef0e1c874643e05a449214": `"శ్రద్ధ! మీరు అన్ని విలువలను ఎంపిక చేయలేరు. ఈ విలువను ఎంపిక చేయడానికి ముందు మరొక విలువను ఎంచుకోండి."`,
    "311d38f551d45cb61e297748f08bb658": `మీ డేటా విజయవంతంగా సేవ్ చేయబడింది`,
    "4500d073ef5972cc935a59ff0b752d8b": `చెల్లుబాటు అయ్యే వరకు`,
    "8de420ed8c345d37cf65a3494bff92ef": `ఓటరు కార్డు`,
    "f6fb95a7bfd158a53680691f250e2273": `ప్రభుత్వ రుసుము:`,
    "c51bae43a860107d2ddaddcc44c72355": `చిరునామా రుజువు (ఏదైనా ఒకటి)`,
    "a1cba2ed3414b72aa4d7651653d08900": `వయస్సు యొక్క రుజువు (ఏదైనా ఒకటి)`,
    "330dd937743341b8c97a75ddbcfacf1b": `డౌన్‌లోడ్ ఫారమ్‌కు లింక్:`,
    "de259fed6ef60355d0dd031c4a97e945": `అప్‌లోడ్ ఫారమ్‌కు లింక్:`,
    "6204374d06a6ca36a20e887451db78af": `మరిన్ని సేవలను అన్వేషించండి`,
    "98d047289f68350b76f20cd10f1db3d4": `గుర్తింపు ధృవీకరణము`,
    "1a01812e1888f4eb6dca36e8b1d39c04": `డాబ్ ప్రూఫ్`,
    "0dcd6f339c0f72875972d60324db52ff": `దరఖాస్తు రుసుము వివరాలు`,
    "133e72d94045f209d146dd6fc01de185": `ఫీజు వివరాలు`,
    "b6d11bb63dc2071d6cba2aaeba8c8a1d": `సౌలభ్యం రుసుము`,
    "719fec04166d6fa75f89cd29ad61fa8c": `పన్నులు`,
    "a876fbe73a6d039cb8c8566f6aa716d5": `మొత్తం మొత్తం`,
    "9d463ea3feb5afec51c20b4f62927ca3": `కూపన్ కోడ్`,
    "620a92a00838e523161dab899b76c764": `చెల్లించడానికి కొనసాగండి`,
    "6f861b42317b2333c01faa9b555b7e14": `ఈ కూపన్‌తో పొదుపులు.`,
    "c6088ccfc87a825ceb53a15ce4d99510": `మీరు <Amount> యొక్క మొత్తం తగ్గింపును పొందారు`,
    "0e54fc6a9dba79c2bc03ac08f3bf4a27": `అవును!`,
    "c1d9a46634bb89273f7d95a5577f15ad": `కూపన్ డిస్కౌంట్`,
    "1063e38cb53d94d386f21227fcd84717": `తొలగించండి`,
    "ba8550da63869236a92fed41fba6e84f": `కూపన్ కోడ్ విజయవంతంగా వర్తించబడింది`,
    "f7a7e0e3897bac9ea4c83d53f6ad5a23": `చెల్లని కూపన్ కోడ్.`,
    "90fe9a78f3ea30beeb6b7ef447d2b6dd": `కూపన్ కోడ్ విజయవంతంగా తొలగించబడింది`,
    "af1b98adf7f686b84cd0b443e022b7a0": `కేటగిరీలు`,
    "11f18dd73f0c3d374d9d5c230cd43160": `యువత నైపుణ్యం`,
    "5a6c0d2579b31142130f20104fa58ea6": `ధృవపత్రాలు`,
    "c482980d384a9d0e7bc39e1140270870": `ఫైనాన్స్`,
    "605669cab962bf944d99ce89cf9e58d9": `ఆరోగ్యం`,
    "5dac73aa49b9ccaca4b9b0d0ef615d10": `పోలీసులు`,
    "b2ece3493eed05158a9cde4a0d2da2a5": `ప్రజా ఫిర్యాదు`,
    "94df2a6972ca1fa79411645fe9b42339": `వినియోగ`,
    "f5f0c488ed56cf789d19a13ffba8abe3": `రవాణా`,
    "0db377921f4ce762c62526131097968f": `జనరల్`,
    "bc34e4c4f36b0d66910a906449910f64": `మీరు ఖచ్చితంగా ఈ సేవను తొలగించాలనుకుంటున్నారా?`,
    "1e22827dff05f5a1c044993aff539403": `మీకు సేవ్ చేసిన సేవలు లేవు.`,
    "b08f17b81d3b9b4f6287b87c53cfc97a": `ముసాయిదాలో మీకు ఎటువంటి సేవలు లేవు.`,
    "6f96ffbc03ad1b9ebb5c6e2ecfcbdc71": `మీరు ఇంకా ఏ సేవలను వర్తింపజేయలేదు.`,
    "daf87fe41645c86672e96857222a0909": `దరఖాస్తు సమర్పించబడింది`,
    "54aeeea175c1509b7c56e0c984f9b4fa": `మీకు సేవ చేయడానికి అవకాశం ఇచ్చినందుకు ధన్యవాదాలు. మీ దరఖాస్తు విజయవంతంగా సమర్పించబడింది.`,
    "f18c4dab2674eae1e8c287c683b66729": `దరఖాస్తు తేదీ`,
    "fb0728ffa9b40d444db91c818eb21a2b": `దరఖాస్తుదారుడి పేరు`,
    "e500e94f457ddd88824c88fda548bed2": `అప్లికేషన్ ఐడి`,
    "ad0206b8eec486df629ce37169376f65": `పాత్ర & బాధ్యతలు:`,
    "6f2ad1cfe3c5b08cc53c62470f4cb1f7": `అవసరమైన పత్రాలు:`,
    "1d83797411218ecca1e5ff4d4705dfb0": `ఎంపిక విధానం:`,
    "ce9e1e989c012211d8bc83c5353b4495": `ఉద్యోగ దరఖాస్తు వివరాలు`,
    "18dcaa84c2ee447028f8450cd0380ba6": `విద్యా వివరాలు:`,
    "93e9a018926429f6d0b0c032910a8eb6": `ఉద్యోగ దరఖాస్తు వివరాలను సమీక్షించండి`,
    "6f906132d3c6b88b57225fdcf76f6e71": `తరచుగా అడిగే ప్రశ్నలు`,
    "2d8d66b9c9b540fcb8384167f1565b64": `దయచేసి స్పెల్లింగ్‌ను తనిఖీ చేయండి లేదా వేరే దేనికోసం శోధించడానికి ప్రయత్నించండి.`,
    "f73a506c52c2dba159fc0fbee823aace": `మీరు ఇప్పటికే ఈ పథకం కోసం దరఖాస్తు చేసుకున్నారు. దయచేసి మీ అనువర్తిత పథకాల నుండి మీ అప్లికేషన్ స్థితిని తనిఖీ చేయండి.`,
    "b73856e1c7ab3e647e28663d5e30fabe": `మీరు ఇప్పటికే ఈ పథకం కోసం దరఖాస్తు చేసుకున్నారు. దయచేసి మీ అనువర్తిత సేవల నుండి మీ అప్లికేషన్ స్థితిని తనిఖీ చేయండి.`,
    "cd850a99c0895272f2cdf23586867b16": `మీరు ఇప్పటికే ఈ పథకం కోసం దరఖాస్తు చేసుకున్నారు. దయచేసి మీ ఉద్యోగాల నుండి మీ అప్లికేషన్ స్థితిని తనిఖీ చేయండి.`,
    "4e0b8852fc3f3d1a2b4c24c4602766dd": `చిత్రం తప్పనిసరిగా JPG, PNG, PDF ఫైల్‌లో ఉండాలి మరియు 1MB కంటే తక్కువ ఉండాలి.`,
    "5a9e7167eb5b7cb7bb46cf5c05eed59a": `డిప్లోప్`,
    "91412465ea9169dfd901dd5e7c96dd99": `అప్‌లోడ్`,
    "3835b71ace5673385a19d56bc938fda8": `ఫీల్డ్ ఛాయాచిత్రం`,
    "4386624773a0ef6de5a5de0dd6f8facd": `డాక్యుమెంట్ రకాన్ని నమోదు చేయండి`,
    "fdba3d2a9c707e50f4eec34cd6bdd0d6": `"మీ కస్టమర్‌ను తెలుసుకోండి" అనేది ప్రభుత్వ పథకం కోసం దరఖాస్తు చేస్తున్న వినియోగదారు యొక్క గుర్తింపును గుర్తించడం మరియు ధృవీకరించడం తప్పనిసరి ప్రక్రియ.`,
    "244773cb8f460196045c5b90659d2c9d": `మీ "డ్రాఫ్ట్ సర్వీసెస్" లో మీ అప్లికేషన్ విజయవంతంగా సేవ్ చేయబడింది`,
    "bbcb2990551820fc4678835c14a84503": `ఫోటోను తొలగించండి`,
    "78e3e2ba60b81cf6cc24e6baf89eaa81": `వ్యక్తిగత ధృవపత్రాలు`,
    "88bcab139c5d6142b04bf77d7dd1c023": `మీరు ఇప్పటికే ఈ సేవ కోసం దరఖాస్తు చేసుకున్నారు. దయచేసి మీ అనువర్తిత సేవల నుండి మీ అప్లికేషన్ స్థితిని తనిఖీ చేయండి.`,
    "bf8cd5900a65efaa89931067cb1d3775": `మీరు ముసాయిదాలో ఎటువంటి పథకాలను సేవ్ చేయలేదు.`,
    "beab92ac3c8a798a35aea2ad0809313f": `అతిథిగా కొనసాగండి`,
    "95884374c7752d972101b70854caffe6": `సైన్ ఇన్/సైన్ అప్`,
    "e44cb1c4a784a247f7296cc02f7f301c": `జిల్లాను ఎంచుకోండి`,
    "9aa060dc3e3bb3ffff9908c5e713a80c": `మీరు ఇంకా ఏ సేవలకు దరఖాస్తు చేయలేదు.`,
    "383f419ea8c0b8142cbdc71e2e4b144a": `మునుపటి ప్రశ్న`,
    "fb188e808b65b9ee48a5c9d88fb92af2": `పునఃప్రారంభించండి`,
    "db5eb84117d06047c97c9a0191b5fffe": `మద్దతు`,
    "bc80ae98530c6ba4e76ed34c58d81875": `మొబైల్ యాప్ కోసం Easygovని డౌన్‌లోడ్ చేయండి`,
    "85ce0cae173233410acbc380a7e531cd": `దయచేసి ఈ పేజీ ప్రభుత్వం యొక్క వెబ్‌సైట్‌లు / వెబ్ పేజీలకు లింక్‌లను కూడా అందిస్తుంది. మంత్రిత్వ శాఖలు/విభాగాలు/సంస్థలు. ఈ వెబ్‌సైట్ల యొక్క కంటెంట్ సంబంధిత సంస్థల యాజమాన్యంలో ఉంది మరియు వాటిని మరింత సమాచారం లేదా సలహా కోసం సంప్రదించవచ్చు`,
    "ad0a802ec8134e9210a5625933db50a7": `నిరాకరణ`,
    "26dbd93cbe6f1e56b1577d8e3cb7fda3": `సేవలను ఎంచుకోండి`,
    "c6582b36b0f516684a873e8782517822": `పథకాలు మరియు సేవలను ఎంచుకోండి`,
    "b98287be44e1230cf144aa3af08ac82f": `మీరు ఇంకా ఏ పథకాలకు నమోదు కాలేదు`,
    "449d3cf7dd09172024ebe93872410f0f": `అనువర్తనాన్ని చూడండి`,
    "025d9b3a3c2a6a27d04c57c1d7cabc9f": `చర్య అవసరం లేదు`,
    "06df33001c1d7187fdd81ea1f5b277aa": `చర్యలు`,
    "adff901e227bf2b2200c4767fe773836": `అవసరమైన చర్యలు`,
    "4ff84d2ee02fe6ef0ca2edc3cdecc0ed": `చర్యలు అవసరం`,
    "c08c272bc5648735d560f0ba5114a256": `సంప్రదింపు మద్దతు`,
    "fd2f820569d83784238e1027897b463e": `మీ పేరు రాయుము, మీ పేరు రాయండి`,
    "cda83d00666a7bfa8cde6808de4ef994": `సంప్రదింపు సంఖ్య`,
    "a4d3b161ce1309df1c4e25df28694b7b": `సమర్పించండి`,
    "4c2a8fe7eaf24721cc7a9f0175115bd4": `సందేశం`,
    "68d390535e8a7120e3f6942d8a2214a0": `మీ సందేశాన్ని నమోదు చేయండి`,
    "2f644b4a27e68f505c4f4ac2ffe3a8ac": `దయచేసి వర్ణమాలలు, సంఖ్యలు, కామా, -, _, /`,
    "58f7a43f2c3bd7854d99fbbbc3fa4ae9": `వర్ణమాలలు మాత్రమే అనుమతించబడతాయి.`,
    "4906d981bb592b32f6982fb4187c7b60": `చెల్లుబాటు అయ్యే ఐఎఫ్ఎస్సి కోడ్‌ను నమోదు చేయండి.`,
    "562d3d08919ae105c3ebc42d9607d266": `దయచేసి వర్ణమాల లేదా సంఖ్యలను మాత్రమే నమోదు చేయండి`,
    "fab2bb673b21bef95ee70b99dbd7f9fc": `11 అక్షరాలు మాత్రమే అనుమతించబడతాయి.`,
    "a7eb433676475fee54727c14ad880b8c": `150 అక్షరాలు మాత్రమే అనుమతించబడతాయి.`,
    "7c58cde3b827bc7d57cbf77865046169": `దయచేసి సంఖ్యలను మాత్రమే నమోదు చేయండి`,
    "544b0264d55a1e4cf476b279240d9be4": `పది అంకెలు మాత్రమే అనుమతించబడతాయి. దయచేసి 0 లేదా +91 ఉపసర్గ చేయవద్దు`,
    "73f0d980e48b8141e0776e02d96a872f": `పన్నెండు అంకెలు మాత్రమే అనుమతించబడతాయి.`,
    "0ce98bd973419ff33921a2275e7903b9": `దయచేసి చెల్లుబాటు అయ్యే పాన్ నంబర్‌ను నమోదు చేయండి.`,
    "c6f91b305e91a0053748310de170a73c": `ఆరు అంకెలు మాత్రమే అనుమతించబడతాయి`,
    "9f44bd93c8988c682d5ef5369fd11f47": `దయచేసి చెల్లుబాటు అయ్యే ఇమెయిల్‌ను నమోదు చేయండి`,
    "10803b83a68db8f7e7a33e3b41e184d0": `పుట్టిన తేది`,
    "ac598199ea0dd028ade4677a435caf4b": `చెల్లుబాటు అయ్యే ఆధార్ సంఖ్యను నమోదు చేయండి`,
    "d0de720ccdffea8490dfd2893c50ebc5": `ఇది {{displayName}} కంటే ఎక్కువగా ఉండాలి`,
    "ced6e171e13ebd5c37415c822706b9d4": `ఇది {{displayName}} కంటే తక్కువగా ఉండాలి`,
    "d1a1cb2bd14fdd9eec972f162cfd1ee0": `ఇది {{displayName}} కు సమానంగా ఉండాలి`,
    "9483135517d156229d0439dff74b1ca0": `ఇది {{displayName} కంటే ఎక్కువ లేదా సమానంగా ఉండాలి`,
    "01e9cd748e852b1ae389b980bff195b0": `ఇది {{displayName} కంటే తక్కువగా ఉండాలి లేదా సమానంగా ఉండాలి`,
    "dc236871b26ba387e1284445caa99df4": `మీకు సేవ చేయడానికి అవకాశం ఇచ్చినందుకు.`,
    "2102fff7bb4de45c9e616e3f01b6810f": `మీ దరఖాస్తు విజయవంతంగా సమర్పించబడింది.`,
    "dc9466f3d424b4acba2fc5c9f58d4f8a": `క్షమించండి, మీకు అర్హత లేదు`,
    "a9198ed7d89e246a80078db84d2722e8": `మీ ఫోన్ నంబర్ లేదా పాస్‌వర్డ్ సరిపోలడం లేదు`,
    "8ac0a98c8c2f2c86396e34b6b5d63e38": `అదే పేరుతో ప్రొఫైల్ ఇప్పటికే ఉంది`,
    "4ae112e33b3189c9fa04369b51259d76": `అదే పేరు మరియు సంబంధం ఉన్న ప్రొఫైల్ ఇప్పటికే ఉంది`,
    "b0fb09a28a4a52a1b3ca2656dec0435e": `మీరు ఖచ్చితంగా ఈ పత్రాన్ని తొలగించాలనుకుంటున్నారా?`,
    "e384b6a4760d80be609d897414cfe680": `మీరు రిజిస్టర్డ్ యూజర్ కాదు, మీరు మరింత ముందుకు సాగడానికి "సైన్ అప్" లేదా "OTP తో లాగిన్ అవ్వవచ్చు"`,
    "c2cd85ffa55b2ec9b049b7eb131d3a9f": `నమోదు చేసిన పాస్‌వర్డ్‌లు సరిపోలలేదు. మళ్ళీ ప్రయత్నించండి`,
    "3a816ee692e09b3cac6ef18e14071bd4": `మీ పాస్‌వర్డ్ విజయవంతంగా సెట్ చేయబడింది`,
    "764d4847b734121ceb58e5c8195fa5b6": `మీ అభిప్రాయం మెరుగుపరచడానికి మరియు మీకు ఉత్తమ అనువర్తన అనుభవం ఉందని నిర్ధారించుకోవడానికి క్రొత్త అవకాశాలను కనుగొనటానికి మాకు అనుమతిస్తుంది.`,
    "701d895fbc64569aa143ca6c44274139": `మీరు OTP ని రూపొందించడానికి గరిష్ట పరిమితిని మించిపోయారు, దయచేసి కొంతకాలం తర్వాత మళ్లీ ప్రయత్నించండి.`,
    "0bdfc4d562c9b0ccfaa4c0f6d4cac98f": `మీరు ఇంకా ఏ పథకాలకు దరఖాస్తు చేయలేదు.`,
    "4391bf5189e3e13c4aa4cb314c605ac8": `మీరు ఇంకా ఏ పథకాలను సేవ్ చేయలేదు.`,
    "92202504591d549b89340d26ffc0753a": `ఈ ఉద్యోగం మీ "నా ఉద్యోగాలలో" సేవ్ చేయబడింది`,
    "af6ebe0c0e89201cb2294f32955f7b15": `ఒకేసారి 20 పత్రాలు మాత్రమే అనుమతించబడతాయి.`,
    "b1481f771aee527fd910fa8d8672cd19": `మీరు ఖచ్చితంగా ఈ నోటిఫికేషన్‌ను తొలగించాలనుకుంటున్నారా?`,
    "06a6b009c19d5816574cdc7800d10eb5": `మీ అభ్యర్థన విజయవంతంగా పెంచబడింది.`,
    "b941bc7a9d4fe7a8c7c31ed440754285": `అనువర్తనాన్ని సమీక్షించండి`,
    "e56b98da835e8f4a018502b121520ac7": `మిగిలి ఉన్న ప్రయత్నాలు`,
    "416828262df288445e72ca1d3661f993": `హెచ్చరిక - వరుసగా 4 విజయవంతం కాని ప్రయత్నాల తరువాత, మీ ఖాతా లాక్ చేయబడుతుంది`,
    "9f978addf10226b03278e4932348ba98": `40 అక్షరాలు మాత్రమే అనుమతించబడతాయి.`,
    "69f076c339373e00e9e841da965f4208": `ఆధార్ నవీకరణ`,
    "48a82e0b2bd5818f110e55bb6f14aedc": `మీరు అందించిన కింది సమాచారం ప్రభుత్వ రికార్డుతో సరిపోలలేదు`,
    "5c37ad5c0dd6e318af899b3c8fcbe886": `క్షమించండి, మేము మీ డేటాను సోర్స్ సిస్టమ్ నుండి పొందలేము. మీరు ధృవీకరణ లేకుండా ముందుకు సాగవచ్చు లేదా తరువాత ప్రయత్నించవచ్చు.`,
    "cdb2033fc9a30d6d6c1abe401cd1e37b": `తర్వాత ప్రయత్నించండి`,
    "f8d855b26faffa0f8779fb44632d6bc1": `చెల్లని క్యాప్చా కోడ్`,
    "d53b4d564b9054db9b4b4a50e713197f": `దయచేసి ఇక్కడ క్యాప్చాను నమోదు చేయండి`,
    "ed001fb3ff7eaca9bbfbaacddc9d5a72": `చరిత్ర కనుగొనబడలేదు`,
    "4934d9bc9ebd024d4106d5e1f093ae79": `సందర్శకులు లెక్కించారు`,
    "f7ce7cb4b450acfe0b4cef36c3bdec2d": `మీ బ్యాంక్ ఖాతా ప్రస్తుతం క్రియారహితంగా ఉంది. మీరు మరింత ముందుకు సాగవచ్చు, కానీ మీ అప్లికేషన్ ఆమోదించబడితే, మీ బ్యాంక్ ఖాతాను ప్రయోజనం పొందటానికి త్వరగా సక్రియం చేయండి.`,
    "df9e5c88861b08c33b2d46de8d7037e9": `మీ బ్యాంక్ ఖాతా ప్రస్తుతం రద్దు చేయబడింది. మీరు మరింత ముందుకు సాగవచ్చు, కానీ మీ అప్లికేషన్ ఆమోదించబడితే, మీ బ్యాంక్ ఖాతాను ప్రయోజనం పొందటానికి త్వరగా సక్రియం చేయండి.`,
    "3fc95e40b475f7269e64f25a1c4aef3b": `మీ బ్యాంక్ ఖాతా మీ ఆధార్ తో అనుసంధానించబడలేదు. మీరు మరింత ముందుకు సాగవచ్చు, కానీ మీ దరఖాస్తు ఆమోదం పొందినట్లయితే, మీ బ్యాంక్ ఖాతాను మీ ఆధార్ తో అనుసంధానించవచ్చు.`,
    "1c35d003a65da48bf9f6d6fc69271d5a": `క్షమించండి, సిస్టమ్ లోపం కారణంగా మీ దరఖాస్తు సమర్పించబడలేదు. దయచేసి తర్వాత మళ్లీ ప్రయత్నించండి.`,
    "8e437cbd840bce5c5edc0ef8164de6b7": `మీరు మీ వివరాలను అప్‌డేట్ చేస్తే లేదా మార్చినట్లయితే మీ డేటా ధృవీకరించబడదు మరియు నవీకరించబడిన డేటా కోసం డాక్యుమెంట్ ప్రూఫ్‌ను అప్‌లోడ్ చేయమని మిమ్మల్ని అడుగుతారు. కొనసాగించడానికి “కొనసాగండి” పై క్లిక్ చేయండి.`,
    "b010e3be9f81072a9808ed4a5dd0e45b": `ఈ క్రింది సమాచారం సర్వేలో మీరు అందించిన వివరాలతో సరిపోలలేదు:`,
    "01acb0344944f11d68aeecc9f5b574ea": `మీరు ప్రతిస్పందన (ల) తో కొనసాగాలనుకుంటున్నారా?`,
    "4ff70f7b2e5a74415de360150a6e59d3": `మీ`,
    "bd7aaae9a27a2a4d71214c002bcf50ce": `రికార్డుతో సరిపోలలేదు.`,
    "ebfd339b0ac14aa79b325b53f06bd375": `మీరు అందించిన సమాచారం మీ ఆధార్ వివరాలతో సరిపోలలేదు. మీరు మరింత ముందుకు సాగలేరు.`,
    "1e410e1b9903e7a99b93857f536b7e1d": `తనిఖీ చెయ్యబడలేదు`,
    "28b266f2bf0a4ecc2dec3b6ca24fcb9b": `అప్‌లోడ్`,
    "68982db8041ee52dd9e5a6e527c8a1fa": `తిరిగి పంట`,
    "e7bf7d83a37666fd8749e433189b1c27": `దయచేసి వేచి ఉండండి`,
    "c389f9e7d5ea93af9deb2e8f1f9c6d89": `మరింత తెలుసుకోవడానికి {{"0x0001F6C8"}} on పై క్లిక్ చేయండి.`,
    "f17816bcbe79e18bee49388fe84d2876": `OTP గడువు ముగిసింది.`,
    "1531d9dc798857eb85345d349bffc99b": `తిరిగి`,
    "7939de8a9958e01fb1f172e21c5a7a85": `దయచేసి సరైన సమాచారాన్ని అందించండి, మీరు అర్హత ఉన్న పథకాలను చూపించడానికి ఇది మాకు సహాయపడుతుంది.`,
    "bf58c4a9eac8e0ec82c158c3adfcd609": `పథకాలు వివిధ వర్గాల క్రింద వర్గీకరించబడ్డాయి. మీరు ఇష్టపడే వర్గం ప్రకారం మీరు పథకాలను తనిఖీ చేయవచ్చు`,
    "09349e778786f7ed758021ddbed12ca6": `{Intent} వర్గం క్రింద అర్హతగల పథకాలను కనుగొనడానికి ఈ సమాచారం మాకు సహాయపడుతుంది.`,
    "0d45c019e7e9237a316f0f16824015d2": `మీ ప్రొఫైల్ డేటా వేర్వేరు ట్యాబ్‌ల క్రింద నిల్వ చేయబడుతుంది. మీరు మీ ప్రొఫైల్ సమాచారాన్ని సవరించవచ్చు.`,
    "3f52884ec08606e6e73426eaec8de195": `ఈ పథకం కింద మీరు పొందే ఖచ్చితమైన ప్రయోజనం గురించి మరియు ప్రయోజనాన్ని పొందటానికి దరఖాస్తు ప్రక్రియ గురించి వివరాలను మీకు అందించడానికి ఈ సమాచారం మాకు సహాయపడుతుంది.`,
    "c389f9e7d5ea93af9deb2e8f1f9c6d89": `మరింత తెలుసుకోవడానికి {{"0x0001F6C8"}} పై క్లిక్ చేయండి.`,
    "5cc2bdab3db5aa21633656a0d55f7477": `మీరు దరఖాస్తుతో మరింత ముందుకు సాగలేరు`,
    "d5de543c0e83073f05a846d62cc83c81": `పత్రం ఇప్పటికే అదే ఫీల్డ్ కోసం అప్‌లోడ్ చేయబడింది.`,
    "fca8da28cbcbe094dc646872e7cdbc7b": `చెల్లుబాటు అయ్యే ఎంటర్`,
    "6fa2260c737f40fa1a3f63274c8fca48": `మీరు మరింత ముందుకు సాగలేరు. మీరు మీ వివరాలను ప్రొఫైల్‌లో లేదా మీ ఆధార్ కార్డులో సరిదిద్దవచ్చు`,
    "3272e327ba9c82197c5c982487224925": `క్షమించండి, మేము మీ డేటాను సోర్స్ సిస్టమ్ నుండి పొందలేము. దయచేసి తర్వాత మళ్లీ ప్రయత్నించండి.`,
    "586637318049e6dbb03e991bc45cba06": `మీ ఖాతాను తొలగించడానికి మీరు మొదట మీ ఖాతాతో అనుబంధించబడిన అన్ని ప్రొఫైల్‌లను తొలగించాలి.`,
    "ce6d6092569f3f247659ceb51d57be95": `మీ అనువర్తిత లేదా నమోదు చేసిన పథకాలలో కొనసాగుతున్న అప్లికేషన్ ఉన్నందున మీరు {{profileName}} ప్రొఫైల్‌ను తొలగించలేరు`,
    "8d258e398e2dbc8099c984258fadce2a": `మీ రిజిస్టర్డ్ మొబైల్ నంబర్‌కు OTP పంపబడింది (xxx xxx {{phoneNumber}})`,
    "abb2e3d13ded4cea9275beafacd9f4c8": `ఈ పథకంపై మీ ఆసక్తిని పెంచడానికి, "మీ ఆసక్తిని సృష్టించండి" పై క్లిక్ చేయండి మరియు ఈ పథకం యొక్క ప్రయోజనాన్ని ఇవ్వడానికి ప్రభుత్వం మీకు చేరుకుంటుంది`,
    "493de8e0a6b5d4a10af30cf3105b9e1f": `మీ ఆసక్తిని సృష్టించండి`,
    "c9b0246a8f3989724ec4958f91e380a7": `పథకం వివరాలు`,
    "804ca01b219522a88d26c3002632a00e": `వివరాలను పంచుకున్నందుకు ధన్యవాదాలు.`,
    "04b6d9b7eb73749476d958b2133f4bbe": `స్కీమ్ అవేర్నెస్ సర్వే`,
    "a5661f68fc78a5d4f15afa057fec7a9f": `మరింత ముందుకు సాగడానికి మీరు మీ వివరాలను సర్వేలో నవీకరించాలి.`,
    "1e4531d1dc128abbc00b48e5c7b6ef48": `సర్వేను నవీకరించండి`,
    "362e532b6ad1fda489c5909d437f02a3": `మేము మీ వివరాలను పొందుతున్నాము, దయచేసి వేచి ఉండండి ..`,
    "8a1b3c7d62e2d60d5be34d8e36a9768f": `వ్యక్తిగత లాగిన్ తిరిగి`,
    "6737d50489b1cf1d4408e66b8591ca38": `మీ స్కీమ్ అప్లికేషన్‌పై మీకు పెండింగ్‌లో ఉంది. మీ దరఖాస్తుతో మరింత ముందుకు సాగడానికి పెండింగ్‌లో ఉన్న చర్యలను పూర్తి చేయండి.`,
    "e37c99137b7396277137c1f73f8a86d5": `సిఇజి యొక్క నిష్క్రమణ`,
    "706c7a936b977ec3c4e6603f888e3fce": `EasyGov ద్వారా ఆధారితం`,
    "598d42810b342196884f255912974d69": `క్ర.సం. లేటు`,
    "60e4e81c6f9c1938d77e8ded6d64643a": `స్క్రీన్ రీడర్`,
    "15bbb9d0bbf25e8d2978de1168c749dc": `వెబ్‌సైట్`,
    "21d587949a2a2e89c958b622e244b405": `ఉచిత/ వాణిజ్య`,
    "d5c03f7b855d442a2a7f24024cf89960": `దృశ్యేతర డెస్క్‌టాప్ యాక్సెస్ (ఎన్‌విడిఎ)`,
    "f956e16f65a3a937e5ecfe4195d17d0d": `(క్రొత్త విండోలో తెరిచే బాహ్య వెబ్‌సైట్)`,
    "b24ce0cd392a5b0b8dedc66c25213594": `ఉచితం`,
    "c8ed8ec3dc8b21290b5d28ca56344553": `జాస్`,
    "b2788e1ab6be65f1c38bf7d23cd081f1": `వాణిజ్య`,
    "ec18aa8148f59475130ff217d693b465": `విండో-ఐస్`,
    "d96a273ceb2b5f83d710081d9c38440a": `వెళ్ళడానికి సిస్టమ్ యాక్సెస్`,
    "c932fd98147348de9974252a0efcc7cb": `WebAnywhere`,
    "540431e2b3234a8698b4044f15ef1fff": `PDF కంటెంట్`,
    "801ab24683a4a8c433c6eb40c48bcd9d": `డౌన్‌లోడ్`,
    "83f8a38f024082405d2fff921c2bb1d2": `అడోబ్ అక్రోబాట్ రీడర్`,
    "9d8c84ff52cfc2c2e0075e669edacffd": `వర్డ్ ఫైల్స్`,
    "4db1a171aaf96723d4002cc41559ddf7": `క్రొత్త విండోలో తెరిచే బాహ్య వెబ్‌సైట్`,
    "86c61b344697039b8a54bbef9e4959c3": `ఎక్సెల్ వ్యూయర్ 2003 (2003 వరకు ఏదైనా సంస్కరణలో)`,
    "04efb134468330da1430e32701160107": `ఎక్సెల్ కోసం మైక్రోసాఫ్ట్ ఆఫీస్ అనుకూలత ప్యాక్ (2007 వెర్షన్ కోసం)`,
    "a385a9bb61bb694694eef5d2f54c563a": `పవర్ పాయింట్ ప్రెజెంటేషన్లు`,
    "da67d56a74c01537a71a3ab0eda00faa": `పవర్ పాయింట్ కోసం మైక్రోసాఫ్ట్ ఆఫీస్ అనుకూలత ప్యాక్ (2007 వెర్షన్ కోసం)`,
    "bb625547a85b98c2c9f9f4121c72cd78": `ఫ్లాష్ కంటెంట్`,
    "ab69edd2798068928605717c6591462f": `ఎడోబ్ ఫ్లాష్ ప్లేయర్`,
    "2c4fa0a307152ef814bbab8d4075d938": `ఆడియో ఫైల్స్`,
    "da7be99fd6fac5c8a4065eb2c07cad88": `విండోస్ మీడియా ప్లేయర్`,
    "5a5652ca26ee42fe73fdd79497cf9e21": `వర్డ్ వ్యూయర్ (2003 వరకు ఏదైనా సంస్కరణలో)`,
    "2e856241d3d995fefdb1b4b32f84a79b": `వర్డ్ కోసం మైక్రోసాఫ్ట్ ఆఫీస్ అనుకూలత ప్యాక్ (2007 వెర్షన్ కోసం)`,
    "64a443242750513eb56695e07aedcff2": `ఎక్సెల్ ఫైల్స్`,
    "382b0f5185773fa0f67a8ed8056c7759": `ఎన్/ఎ`,
    "462c18d5b89050fb1b7f8fca1e535af8": `ప్రాసెస్ చేయబడింది`,
    "ccb26ac32c3cc7cc2f0fd1bbac3b8e2d": `మీకు ప్రాసెస్ చేసిన సేవలు లేవు`,
    "fc44a0efc672761745c2e76aac6851d2": `మీ అప్లికేషన్ తిరస్కరించబడింది ఎందుకంటే`,
    "7d0db380a5b95a8ba1da0bca241abda1": `వద్ద`,
    "1252044838fd74c4e8b506ef1302a8b5": `ఉపద్రవము`,
    "06627e6919d21853d887d00334ee5337": `ఫిర్యాదుల నిర్వహణ`,
    "f940fbe4aec76e9784e7f15c4b92ac6d": `ప్రాసెస్ చేయడంలో మీకు ఏమాత్రం మనోవేదన లేదు`,
    "2290dc1500a2b4548eb3b931f2e3609a": `మీకు సేవ్ చేసిన ఫిర్యాదు లేదు.`,
    "b25b60dc1a5f7c17e09bc73174668d9a": `ముసాయిదాలో మీకు ఫిర్యాదు లేదు.`,
    "a02c58d421087ec9519620a29d68a6ec": `మీరు ఇంకా ఏ ఫిర్యాదులకు సమర్పించలేదు.`,
    "b65f636dcbc018b1901e23a7c2adde90": `మరింత అన్వేషించండి`,
    "5c835c22429f25992b4371691ecde1a4": `సమర్పించబడింది`,
    "2535f5e4e0892c73a5fad023bf3e15e2": `నా మనోవేదనలు`,
    "4351cfebe4b61d8aa5efa1d020710005": `చూడండి`,
    "e9516387730903ffd7cfe3317e05e385": `ఈ ఫిర్యాదు మీ "నా మనోవేదన" లో సేవ్ చేయబడింది.`,
    "b70db24f2cb70fb266eb03da8f289ba5": `ఫిర్యాదును చూడండి`,
    "646558064e10a12b1e8914e86eca3ca6": `మీ సేవా అనువర్తనంపై మీకు పెండింగ్‌లో ఉంది. మీ దరఖాస్తుతో మరింత ముందుకు సాగడానికి పెండింగ్‌లో ఉన్న చర్యలను పూర్తి చేయండి.`,
    "d2841171ea05a48fa5463b3f7c7c92d7": `మీ ఫిర్యాదుల దరఖాస్తుపై మీకు పెండింగ్‌లో ఉంది. మీ దరఖాస్తుతో మరింత ముందుకు సాగడానికి పెండింగ్‌లో ఉన్న చర్యలను పూర్తి చేయండి.`,
    "9279f823b01f13b7a8c04688289543cc": `మీ అప్లికేషన్ మీ 'డ్రాఫ్ట్ గ్రీవెన్స్'లో విజయవంతంగా సేవ్ చేయబడింది.`,
    "af481c93dcae73d73afe9927076c8a09": `ఈ మనోవేదనల కోసం మీరు ఇప్పటికే దరఖాస్తు చేసుకున్నారు. దయచేసి మీ అనువర్తిత మనోవేదనల నుండి మీ అప్లికేషన్ స్థితిని తనిఖీ చేయండి.`,
    "ce972231a0faf31791c7ac0bf0d1da84": `మీరు ఏదైనా చెల్లించినట్లయితే మీ మొత్తం తిరిగి చెల్లించబడదు.`,
    "2ffef02ca513f8bbadbaf130a23097de": `మీ "డ్రాఫ్ట్ ఫిర్యాదులలో" మీ అప్లికేషన్ విజయవంతంగా సేవ్ చేయబడింది`,
    "e55e425dbaf6b6449940eac8ea749092": `దయచేసి వర్ణమాలలను మాత్రమే నమోదు చేయండి.`,
    "64ff9e3809686d4ea3253a631cae4761": `దయచేసి 2 దశాంశ స్థానాలకు సరైన విలువను నమోదు చేయండి.`,
    "7ed0ef23d527c2876015161e422f49ce": `దయచేసి మీ పేరును కన్నడ భాషలో నమోదు చేయండి.`,
    "60ae728544ab5cb25dba9ff4b8f6aa95": `సేవా సింధు కోసం మీ అప్లికేషన్ రిఫరెన్స్ నంబర్`,
    "2c0ed1f2f9239dbca46beca549190e00": `ఆధార్ చిరునామా రుజువుగా తీసుకోబడుతుంది, దయచేసి జిల్లాను ఆధార్ లో ఎంచుకోండి.`,
    "8355a53d777c5ac84a37970b5158aba5": `లోపం 205: ఆధార్ లోని మీ పేరు రెవెన్యూ విభాగం జారీ చేసిన కుల లేదా ఆదాయ ధృవీకరణ పత్రం వివరాలతో సరిపోలడం లేదు.`,
    "601f77894e71ae7d4922eda713dda66c": `లోపం 207: మీ కులం మరియు ఆదాయ ధృవీకరణ పత్రం గడువు ముగిసింది.`,
    "9182b3e8dc7965f2775a204095f4c992": `మీరు నమోదు చేసిన OTP తప్పు. దయచేసి సరైన OTP తో మళ్ళీ ప్రయత్నించండి.`,
    "6e0fe1003e84e34da97a6c4c9040b31b": `వెనుకబడిన తరగతుల సంక్షేమ విభాగం యొక్క అరివు ఎడ్యుకేషనల్ లోన్ స్కీమ్ (పునరుద్ధరణ) కోసం దరఖాస్తును అంగీకరించిన చివరి తేదీ 31 మార్చి 2022 వరకు పొడిగించబడింది.`,
    "69d3a9de2fc1407f59e8936ca0c525a0": `క్షమించండి, లోపం సంభవించింది. దయచేసి తర్వాత మళ్లీ ప్రయత్నించండి.`,
    "7953c16d691da03c899391a39412fc9b": `మీ స్కీమ్ అప్లికేషన్‌పై మీకు పెండింగ్‌లో ఉంది. మీ దరఖాస్తుతో మరింత ముందుకు సాగడానికి <i> {{startDate}} </i> నుండి పెండింగ్‌లో ఉన్న చర్యలను పూర్తి చేయండి.`,
    "f4f4366332ac6949882de59c81b08de9": `మీ స్కీమ్ అప్లికేషన్‌పై మీకు పెండింగ్‌లో ఉంది. <i> {{startDate}} </i> నుండి పెండింగ్‌లో ఉన్న చర్యలను పూర్తి చేయండి <i> {{enddate}} </i> మీ అప్లికేషన్‌తో మరింత ముందుకు సాగండి.`,
    "77b43c24220ee608380031b72f8017ce":`సేవా వర్గాలు`,
    "e0e54c374f8b39a1520766e5924af100": `మీ కుటుంబం మరియు మీ కోసం పథకాలు, సేవలు మరియు జీవనోపాధిని తెలుసుకోవడానికి ఒక ప్లాట్‌ఫారమ్`,
    "d56f6b073f573237a6c4aed91589e982": `జీవనోపాధి`,
    "e5930fadbc620e368c92561f381ead58": `మీ కోసం జీవనోపాధి అవకాశాలు`,
    "192adf39ebd850e3e2e30971e885dc6d": `ప్రస్తుతం మీ రాష్ట్రంలో కాన్ఫిగర్ చేయబడిన జీవనోపాధి అవకాశాలు ఏవీ లేవు, మేము నిరంతరం అప్‌డేట్ చేస్తున్నాము, దయచేసి మాతో మళ్లీ తనిఖీ చేయండి`,
    "TM_Alt51": "జోడింపు(లు)",
    "TM_Hea1": "మొత్తం మొత్తం",
    "TM_Hea2": "సారాంశం",
    "TM_Sub56": "మొత్తం",
    "TM_But42": "చెల్లించు",
    "TM_Mod41":"చెల్లింపు పెండింగ్‌లో ఉంది",
    "TM_Mod40":"చెల్లింపు విజయవంతమైంది",
    "TM_Alt72":"చెల్లింపు విఫలమైంది",
    "TM_Pag48":"సహాయం మరియు మద్దతు",
    "TM_Con33":"ధన్యవాదాలు, మీ దరఖాస్తు విజయవంతంగా సమర్పించబడింది.",
    "TM_Err34": "చెల్లింపు వైఫల్యం కారణంగా మీ దరఖాస్తును సమర్పించడం సాధ్యం కాలేదు.",
    "TM_Rec55": "మీ చెల్లింపు స్థితిని నిర్ధారించడానికి సమయం తీసుకుంటోంది. మేము మీ బ్యాంక్‌తో నిరంతరం తనిఖీ చేస్తున్నాము. దయచేసి తదుపరి 48 గంటల పాటు ఎటువంటి అదనపు చెల్లింపులు చేయకుండా ఉండండి.",
    "TM_But13": "వెనక్కి వెళ్ళు",
    "TM_Alt124":"దయచేసి వేచి ఉండండి",
    "TM_Rec58": "ఆగండి.మేము మీ చెల్లింపు స్థితిని ధృవీకరిస్తున్నాము",
    "TM_Rec59": "ఈ సేవ కోసం మీ చివరి చెల్లింపు పెండింగ్‌లో ఉంది. ఇది పూర్తయిన తర్వాత మేము మీకు తెలియజేస్తాము. ఈలోగా, మా ఇతర సేవలను అన్వేషించడానికి సంకోచించకండి.",
    "TM_Rec60":"మీ ఓర్పుకు నా ధన్యవాదములు.",
    "TM_Rec61":"పేజీ స్వయంచాలకంగా గడువు ముగిసింది",
    "TM_Alt165":"చెల్లింపు చేయడానికి మీ మొబైల్ పరికరం యొక్క యుపిఐ యాప్‌లోని క్యుఆర్ కోడ్‌ని స్కాన్ చేయండి",
    "TM_Pag39": "లభర్తి",
    "TM_Alt166":"చెల్లింపు తిరస్కరించబడింది",
    "TM_Err39":"చెల్లింపు తిరస్కరించబడినందున మీ దరఖాస్తు సమర్పించబడలేదు.",
    "cc4cbfe72c596683881033f36eac3e2f":"మేము మీ బ్యాంక్‌తో మీ చెల్లింపును నిర్ధారించే వరకు దయచేసి వేచి ఉండండి. ఈ ప్రక్రియకు కొంత సమయం పట్టవచ్చు.",
    "7d54c690a3a707e692cceb6978e24bc1": "ఫీజు విభజన",
}
export default language;