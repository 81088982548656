const initState = {
    data: undefined,
  };
  
  const saveAwarenessSurveyAndKycReducer = (state = initState, action) => {
    switch (action.type) {
      case "SAVE_AWARENESS_SURVEY_AND_KYC":
        return {
          ...state,
          data: action.payload,
        };
  
      case "RESET_SAVE_AWARENESS_SURVEY_AND_KYC":
        return {
          ...state,
          data: undefined,
        };
  
      default:
        return state;
    }
  };
  
  export default saveAwarenessSurveyAndKycReducer;
  