const initState = {
    data: undefined,
  };

  const digiLockerAadhaarKycReducer = (state = initState, action) => {
    switch (action.type) {
      case "DIGILOCKER_AADHAAR_KYC":
        return {
          ...state,
          data: action.payload,
        };

      case "RESET_DIGILOCKER_AADHAAR_KYC":
        return {
          ...state,
          data: undefined,
        };

      default:
        return state;
    }
  };

  export default digiLockerAadhaarKycReducer;
