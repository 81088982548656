const initState = {
    data: "",
  };
  
  const getAllApplicationsCountForEndUserReducer = (state = initState, action) => {
    switch (action.type) {
      case "GET_APPLICATION_COUNT_SUCCESS":
        return {
          ...state,
          data: action.payload,
        };
  
      case "GET_APPLICATION_COUNT_FAILURE":
        return {
          ...state,
          data: undefined,
        };
  
      default:
        return state;
    }
  };
  
  export default getAllApplicationsCountForEndUserReducer;
  