import { combineReducers } from "redux";
import getSettingsReducer from "./getSettingsReducer";
import languageReducer from "./languageReducer";
import getSiteContentReducer from "./getSiteContentReducer";
import districtReducer from "./districtReducer";
import getProductReducer from "./getProductReducer";
import getSurveyDataReducer from "./getSurveyDataReducer";
import loaderReducer from "./loaderReducer";
import dataLoaderReducer from "./dataLoaderReducer";
import saveAndMatchReducer from "./saveAndMatchReducer";
import deleteUserReducer from "./deleteUserReducer";
import validateDbtResponseReducer from "./validateDbtResponseReducer";
import saveProductReducer from "./saveProductReducer";
import getSavedProductReducer from "./getSaveProductReducer";
import saveUserProfileReducer from "./saveUserProfileReducer";
import getUserProfileReducer from "./getUserProfileReducer";
import getUserFamilyProfileReducer from "./getUserFamilyProfileReducer";
import deleteUserFamilyMemberReducer from "./deleteUserFamilyMemberReducer";
import removeSavedProductReducer from "./removeSavedproductReducer";
import updateLangAndGeoReducer from "./updateLangAndGeoReducer";
import signInReducer from "./signInReducer";
import signUpReducer from "./signUpReducer";
import otpVerifyReducer from "./otpVerifyReducer";
import resetPasswordReducer from "./resetPasswordReducer";
import setPasswordReducer from "./setPasswordReducer";
import getFieldBySourceReducer from "./getFieldBySourceReducer";
import getSourceReducer from "./getSourceReducer";
import getExternalProfileReducer from "./getExternalProfileReducer";
import guestSignInReducer from "./guestSignInReducer";
import logOutReducer from './logOutReducer';
import loginWithOtpReducer from './loginWithOtpReducer';
import saveUserFamilyProfileReducer from './saveUserFamilyProfileReducer'
import updateFamilyProfileReducer from './updateFamilyProfileReducer'
import clearProfileReducer from './clearProfileReducer'
import getApplicationFormsReducer from './getApplicationFormsReducer';
import getSaltReducer from './getSaltReducer';
import getExternalProfileVerify from './verifyDetailReducer';
import saveKycFormReducer from './saveKycReducer';
import saveApplicationFormReducer from './saveApplicationFormReducer';
import getSurveyDetailsReducer from './getSurveyDetailsReducer';
import saveDocsForUserReducer from './saveDocsForUserReducer';
import userServiceFeedbackReducer from './userServiceFeedbackReducer';
import listAllApplicationsReducer from './listAllApplicationsReducer';
import getSavedApplicationReducer from './getSavedApplicationReducer';
import withdrawApplicationReducer from './withdrawApplicationReducer';
import printApplicationReducer from './printApplicationReducer';
import getSingleDocumentForEndUserReducer from './getSingleDocumentForEndUserReducer';
import getUserNotificationsReducer from "./getUserNotificationReducer";
import deleteDocumentsListReducer from "./deleteDocumentsListReducer";
import digiLockerSigninReducer from './digiLockerSigninReducer';
import digiLockerAadhaarKycReducer from './digiLockerAadhaarKycReducer';
import dbtAadhaarAuthenticationReducer from './dbtAadhaarAuthenticationReducer';
import getServiceOnlineURLReducer from './getServiceOnlineURLReducer';
import eSignDataAndUrlReducer from './eSignDataAndUrlReducer';
import getSignedPdfReducer from './getSignedPdfReducer';
import printAndFinalSubmit from "./printAndFinalSubmitReducer"
import userDocumentByIdReducer from "./userDocumentByIdReducer";
import userProfileDocumentReducer from "./userProfileDocumentReducer";
import completeActionsReducer from './completeActionsReducer'
import updateExternalStatusReducer from './updateExternalStatusReducer'
import getAppOpenCountReducer from "./getAppOpenCountReducer";
import setAppOpenCountReducer from "./setAppOpenCountReducer";
import deleteApplicationFromDraftReducer from "./deleteApplicationFromDraftReducer";
import getProductKycReducer from "./getProductKycReducer";
import saveAwarenessSurveyAndKycReducer from "./saveAwarenessSurveyAndKycReducer";
import getParentLgdFromChildReducer from "./getParentLgdFromChildReducer";
import getUserCertificateByApplicationIdReducer from "./getUserCertificateByApplicationIdReducer";
import eKycAadhaarAuthenticationReducer from "./eKycAadhaarAuthenticationReducer";
import getEKycDataReducer from "./getEKycDataReducer";
import getChildOptionFromParentReducer from "./getChildOptionFromParentReducer";
import getLgdCodeFromValueAndLevelReducer from "./getLgdCodeFromValueAndLevelReducer"
import saveSingleDocForUserReducer from "./saveSingleDocForUserReducer"
import getVendorListReducer from "./getVendorListReducer";
import getAllDocumentsForEndUserReducer from "./getAllDocumentsForEndUserReducer";
import fetchPaymentGatewayReducer from "./fetchPaymentGatewayReducer";
import generatePaymentLinkReducer from "./generatePaymentLinkReducer";
import getPaymentStatusReducer from "./getPaymentStatusReducer";
import getAdvertisementReducer from './getAdvertisementReducer';
import deepLinkVisitReducer from "./deepLinkVisitReducer";
import getAllApplicationsCountForEndUserReducer from "./getAllApplicationsCountForEndUserReducer";

const rootReducer = combineReducers({
  settings: getSettingsReducer,
  language: languageReducer,
  siteContent: getSiteContentReducer,
  districtList: districtReducer,
  productList: getProductReducer,
  surveyData: getSurveyDataReducer,
  showLoader: loaderReducer,
  showDataLoader: dataLoaderReducer,
  saveAndMatchData: saveAndMatchReducer,
  deleteUser: deleteUserReducer,
  saveProduct: saveProductReducer,
  getSavedProduct: getSavedProductReducer,
  saveUserProfile: saveUserProfileReducer,
  getUserProfile: getUserProfileReducer,
  getUserFamilyProfile: getUserFamilyProfileReducer,
  deleteUserFamily: deleteUserFamilyMemberReducer,
  removedProduct: removeSavedProductReducer,
  updateLangAndGeo: updateLangAndGeoReducer,
  signIn: signInReducer,
  signUp: signUpReducer,
  otpVerify: otpVerifyReducer,
  resetPassword: resetPasswordReducer,
  setPassword: setPasswordReducer,
  getFieldBySource: getFieldBySourceReducer,
  getSource: getSourceReducer,
  getExternalProfile: getExternalProfileReducer,
  guestSignIn: guestSignInReducer,
  logOut: logOutReducer,
  loginWithOtp: loginWithOtpReducer,
  saveFamilyUser: saveUserFamilyProfileReducer,
  updateFamilyProfile: updateFamilyProfileReducer,
  clearProfile: clearProfileReducer,
  applicationForm: getApplicationFormsReducer,
  salt: getSaltReducer,
  getExternalProfileVerify: getExternalProfileVerify,
  saveKyc: saveKycFormReducer,
  saveApplicationForm: saveApplicationFormReducer,
  getSurveyDetails: getSurveyDetailsReducer,
  saveDocs: saveDocsForUserReducer,
  userServiceFeedback: userServiceFeedbackReducer,
  listAllApplications: listAllApplicationsReducer,
  getSavedApplication: getSavedApplicationReducer,
  withdrawApplication: withdrawApplicationReducer,
  printApplication: printApplicationReducer,
  getSingleDocumentForEndUser: getSingleDocumentForEndUserReducer,
  getUserNotifications: getUserNotificationsReducer,
  deleteDocumentsList: deleteDocumentsListReducer,
  digiLockerSignin: digiLockerSigninReducer,
  digiLockerAadhaarKyc: digiLockerAadhaarKycReducer,
  dbtAadhaarAuthentication: dbtAadhaarAuthenticationReducer,
  getServiceOnlineURL: getServiceOnlineURLReducer,
  eSignDataAndUrl: eSignDataAndUrlReducer,
  getSignedPdf: getSignedPdfReducer,
  printAndFinalSubmit: printAndFinalSubmit,
  validateDbtResponse: validateDbtResponseReducer,
  userDocumentById: userDocumentByIdReducer,
  userProfileDocument: userProfileDocumentReducer,
  completeActions: completeActionsReducer,
  updateExternalStatus: updateExternalStatusReducer,
  getAppOpenCountData: getAppOpenCountReducer,
  setAppOpenCountData: setAppOpenCountReducer,
  deleteApplicationFromDraft: deleteApplicationFromDraftReducer,
  getProductKyc: getProductKycReducer,
  saveAwarenessSurveyAndKyc: saveAwarenessSurveyAndKycReducer,
  getParentLgdFromChild: getParentLgdFromChildReducer,
  getUserCertificateByApplicationId: getUserCertificateByApplicationIdReducer,
  eKycAadhaarAuthentication: eKycAadhaarAuthenticationReducer,
  getEKycData: getEKycDataReducer,
  getChildOptionFromParent: getChildOptionFromParentReducer,
  getLgdCodeFromValueAndLevel: getLgdCodeFromValueAndLevelReducer,
  saveSingleDocForUser: saveSingleDocForUserReducer,
  getVendorList : getVendorListReducer,
  allDocumentsData : getAllDocumentsForEndUserReducer,
  fetchPaymentGateways: fetchPaymentGatewayReducer,
  generatePaymentLink: generatePaymentLinkReducer,
  getPaymentStatus: getPaymentStatusReducer,
  advertisementList:getAdvertisementReducer,
  deepLinkVisit: deepLinkVisitReducer,
  getAllApplicationsCount:getAllApplicationsCountForEndUserReducer,
})

export default rootReducer;
