import storage from "../../utility/encryptData";
import keys from '../../common/keys';

const initState = {
  data: "",
};

const getAdvertisementReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_ADVERTISEMENT":
      return {
        ...state,
        data: action.payload,
      };

    case "RESET_ADVERTISEMENT_LIST":
      return {
        ...state,
        data: "",
      };

    default:
      return state;
  }
};

export default getAdvertisementReducer;