const initState = {
  data: undefined,
};

const getAllDocumentsForEndUser = (state = initState, action) => {
  switch (action.type) {

    case "GET_ALL_DOCUMENTS":
      return {
        ...state,
        data: action.payload,
      };

    case "RESET_GET_ALL_DOCUMENTS":
      return {
        ...state,
        data: undefined,
      };

    default:
      return state;
  }
};

export default getAllDocumentsForEndUser;
