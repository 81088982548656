const initState = {
  data: "",
};

const districtReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_DISTRICT":
      return {
        ...state,
        data: action.payload,
      };

    case "RESET_DISTRICT_LIST":
      return {
        ...state,
        data: "",
      };

    default:
      return state;
  }
};

export default districtReducer;
