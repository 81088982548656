import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import storage from "../utility/encryptData";
import keys from "../common/keys";
import history from "../history";
// IMPORT COMPONENT AFTER THIS LINE

const Home = React.lazy(() => import("./WEB/Home"));
const JobsHome = React.lazy(() => import("./WEB/JobsHome/"));
const Language = React.lazy(() => import("./WEB/Language/"));
const Location = React.lazy(() => import("./WEB/Location/"));
const Survey = React.lazy(() => import("./WEB/Survey"));
const JobSurvey = React.lazy(() => import("./WEB/JobSurvey"));
const IntentScreen = React.lazy(() => import("./WEB/Survey/IntentScreen"));
const JobsIntentScreen = React.lazy(() => import("./WEB/JobSurvey/JobsIntentScreen"));
const SchemeSpecificSurvey = React.lazy(() => import("./WEB/Survey/SchemeSpecificSurvey"));
const JobSpecificSurvey = React.lazy(() => import("./WEB/JobSurvey/jobSpecificSurvey"));
const IntentSurvey = React.lazy(() => import("./WEB/Survey/IntentSurvey"));
const ApplyDetails = React.lazy(() => import("./WEB/Applydetails"));
const JobDetails = React.lazy(() => import("./WEB/Applydetails/jobDetails"));
const About = React.lazy(() => import("./WEB/Aboutus"));
const FAQ = React.lazy(() => import("./WEB/FAQ"));
const PrivacyPolicy = React.lazy(() => import("./WEB/PrivacyPolicy"));
const TermsNConditions = React.lazy(() => import("./WEB/TermsNConditions"));
const Services = React.lazy(() => import("./WEB/Services"));
const Grievance = React.lazy(() => import("./WEB/Grievance"));
const Search = React.lazy(() => import("./WEB/Search"));
const NonEligibleScheme = React.lazy(() => import("./WEB/Non-Eligible-Scheme"));
const NoInternet = React.lazy(() => import("./WEB/NoInternet"));
const Menu = React.lazy(() => import("./WEB/Menu"));
const SavedScheme = React.lazy(() => import("./WEB/Home/savedScheme"));
const SavedJob = React.lazy(() => import("./WEB/JobsHome/savedJobs"));
const JobDetailsSave = React.lazy(() => import("./WEB/JobsHome/jobDetailsSave"));
const SavedServices = React.lazy(() => import("./WEB/Home/savedServices"));
const SavedGrievance = React.lazy(() => import("./WEB/Grievance/savedGrievance"));

const ProactiveScheme = React.lazy(() => import("./WEB/Home/proactiveScheme"));
const SurveyDetails = React.lazy(() => import("./WEB/Survey/SurveyDetails"));
const JobSurveyDetails = React.lazy(() => import("./WEB/JobSurvey/JobSurveyDetails"));
const ApplyDetailsSave = React.lazy(() => import("./WEB/Home/applyDetailsSave"));
const CreateProfile = React.lazy(() => import("./WEB/createProfile"));
const ManageProfile = React.lazy(() => import("./WEB/Menu/manageProfile"));
const ProfileDetails = React.lazy(() => import("./WEB/Menu/details"));
const Main = React.lazy(() => import("./WEB/Main"));
const SignUp = React.lazy(() => import("./WEB/SignUp"));
const SignIn = React.lazy(() => import("./WEB/SignIn"));
const Verification = React.lazy(() => import("./WEB/OTPVerification"));
const SetPassword = React.lazy(() => import("./WEB/SetPassword"));
const ForgotPassword = React.lazy(() => import("./WEB/ForgotPassword"));
const SchemeNotForYou = React.lazy(() => import("./WEB/Survey/SchemeNotForYou"));
const Notification = React.lazy(() => import("./WEB/Notifications"));
const ProfileNotification = React.lazy(() => import("./WEB/Notification"));
const ProfileManage = React.lazy(() => import("./WEB/ManageProfile/index"));
// const HomeScreen = React.lazy(() => import("./WEB/HomeScreen"));
const VerifyDetails = React.lazy(() => import("./WEB/VerifyDetails"));
const VerificationDetails = React.lazy(() => import("./WEB/Verification"));
const FamilyDetails = React.lazy(() => import("./WEB/ManageProfile/details"));
const LoginWithOTP = React.lazy(() => import("./WEB/SignIn/SignOtp"));
const KycManual = React.lazy(() => import("./WEB/KYC/kycManual"));
const KycManualRevamp = React.lazy(() => import("./WEB/KYC/kycManualRevamp"));
const KycHome = React.lazy(() => import("./WEB/KYC"));
const ApplicationDetailsOneData = React.lazy(() => import("./WEB/Application/applicationOneData"));
const ApplicationRevamp = React.lazy(() => import("./WEB/Application/applicationRevamp"));
const ApplicationProcessing = React.lazy(() => import("./WEB/Application/applicationRevampProcessing"));
const MySavedSchemes = React.lazy(() => import("./WEB/Schemes/savedSchemes"));
const ReviewDetailScreen = React.lazy(() => import("./WEB/Application/reviewScreen"));
const SchemeSurveyDetails = React.lazy(() => import("./WEB/SchemeSurvey"));
const ApplicationStatus = React.lazy(() => import("./WEB/Schemes/applicationStatus"));
const ConfirmSurveyDetails = React.lazy(() => import('./WEB/Survey/ConfirmSurveyDetails'));
const NotEligibleJobs = React.lazy(() => import("./WEB/Non-Eligible-Scheme/noneligibleForJobs"));
const History = React.lazy(() => import("./WEB/History"));
const ServicesCategories = React.lazy(() => import("./WEB/Services/Servicescategories"));
const GrievanceCategories = React.lazy(() => import("./WEB/Grievance/GrievanceCategories"));
const HomeFirstTimeUser = React.lazy(() => import("./WEB/HomeFirstTimeUser"));
const ManageProfileDetails = React.lazy(() => import("./WEB/ManageProfile/ManageProfileDetails"));
const ApplicationFailure = React.lazy(() => import("./WEB/Application/applicationFailure"));
const SchemeAwareness = React.lazy(() => import("./WEB/SchemeAwareness"));

const AwarenessSurvey = React.lazy(() => import("./WEB/SchemeAwareness/awarenessSurvey"));
const SurveyTest = React.lazy(() => import("./Survey"));
const ApplicationRevampLGDOpt = React.lazy(() => import("./WEB/Application/applicationRevampLGDOpt"))
const ApplicationForm = React.lazy(() => import("./WEB/Application/applicationForm"))
const ReviewPaymentPage = React.lazy(() => import("./WEB/reviewPaymentPage"));
const PaymentQR = React.lazy(() => import("./WEB/paymentQR"));
const PaymentStatus = React.lazy(() => import("./WEB/PaymentStatus"));



const RoutesWeb = withRouter(({ location }) => {
  // When user clicks on back after deleting account.
  // if(storage.getItemValue(keys.LANGUAGE_OBJ) === null && window.location.pathname !== "/"){
  //   history.replace("/")
  // }
  // if(storage.getItemValue(keys.LANGUAGE_OBJ) === null && window.location.pathname !== "/"){
  //   history.replace("/")
  // } else

  // if(storage.getItemValue(keys.LANGUAGE_OBJ) === null && window.location.pathname !== "/"){
  //   history.replace("/")
  // }
  // else if(storage.getItemValue(keys.GEOGRAPHY_ID) === null && window.location.pathname !== "/selectLocation"  && storage.getItemValue(keys.LANGUAGE_OBJ)){
  //   history.replace("/selectLocation")



  // }

  if (!storage.getItemValue(keys.LANGUAGE_OBJ) && !storage.getItemValue(keys.GEOGRAPHY_ID) && window.location.pathname !== "/homeUser" && window.location.pathname !== "/privacyPolicy") {
    history.replace("/homeUser");
  }

  if (storage.getItemValue(keys.USER_TYPE) && storage.getItemValue(keys.USER_TYPE) === "business") {
    let settingsData = storage.getItemValue(keys.SETTINGS_DATA)
    if (settingsData) {
      settingsData = JSON.parse(settingsData)
      console.log("settingsData", settingsData);
      if (window.location.pathname === "/" && settingsData.isGtoBSchemeEnabled === "false") {
        history.replace('/services')
      }
    }
  }


  const routes = [

    {
      component:
        (window.outerWidth >= 600 && !storage.getItemValue(keys.USERNAME)) ?
          HomeFirstTimeUser
          : Home,
      path: "/",
    },

    {
      component: (storage.getItemValue(keys.IS_SCHEME_FIRST_VISIT) === null)
        ? JobSurvey
        : JobsHome,
      path: "/job-home"
    },

    {
      component: Language,
      path: "/selectLanguage",
    },

    {
      component: JobSurvey,
      path: "/job-survey"
    },

    {
      component: NotEligibleJobs,
      path: "/noneligibleForJobs"
    },

    {
      component: Location,
      path: "/selectLocation",
    },

    {
      component: Survey,
      path: "/survey",
    },

    {
      component: IntentScreen,
      path: "/qualifiedSchemes",
    },
    {
      component: JobsIntentScreen,
      path: "/qualifiedJobs"
    },

    {
      component: SchemeSpecificSurvey,
      path: "/specificSurvey",
    },
    {
      component: JobSpecificSurvey,
      path: "/jobSpecificSurvey"
    },

    {
      component: Home,
      path: "/home",
    },

    {
      component: IntentSurvey,
      path: "/intentSurvey",
    },

    {
      component: ApplyDetails,
      path: "/applydetails",
    },

    {
      component: JobDetails,
      path: "/jobDetails"
    },

    {
      component: ApplyDetailsSave,
      path: "/applydetailsSave",
    },

    {
      component: LoginWithOTP,
      path: "/loginOtp",
    },

    {
      component: JobDetailsSave,
      path: "/jobdetailsSave"
    },
    {
      component: HomeFirstTimeUser,
      path: "/homeUser",
    },

    {
      component: About,
      path: "/about",
    },

    {
      component: FAQ,
      path: "/faq",
    },
    {
      component: TermsNConditions,
      path: "/tnC",
    },
    {
      component: PrivacyPolicy,
      path: "/privacyPolicy",
    },

    {
      component: Services,
      path: "/services",
    },
    {
      component: Grievance,
      path: "/grievance",
    },
    {
      component: Search,
      path: "/search",
    },
    {
      component: NonEligibleScheme,
      path: "/noneligiblescheme",
    },
    {
      component: Menu,
      path: "/menu",
    },
    {
      component: SavedScheme,
      path: "/savedscheme",
    },
    {
      component: ProactiveScheme,
      path: "/proactiveScheme",
    },
    {
      component: SurveyDetails,
      path: "/surveydetails",
    },
    {
      component: JobSurveyDetails,
      path: "/jobSurveydetails"
    },
    {
      component: SavedJob,
      path: "/savedJobs"
    },
    {
      component: SavedServices,
      path: "/savedservices"
    },
    {
      component: SavedGrievance,
      path: "/savedGrievance"
    },
    {
      component: Main,
      path: "/main",
    },
    {
      component: SignUp,
      path: "/signUp",
    },
    {
      component: SignIn,
      path: "/signIn",
    },
    {
      component: Verification,
      path: "/otpVerification",
    },
    {
      component: SetPassword,
      path: "/setPassword",
    },
    {
      component: ForgotPassword,
      path: "/forgotPassword",
    },
    {
      component: CreateProfile,
      path: "/createProfile",
    },
    {
      component: ManageProfile,
      path: "/manageProfile",
    },
    {
      component: ProfileDetails,
      path: "/profileDetails",
    },
    {
      component: SchemeNotForYou,
      path: "/nonEligibleSchemes",
    },
    {
      component: Notification,
      path: "/notification",
    },

    {
      component: ProfileNotification,
      path: "/profile-notification",
    },


    {
      component: ProfileManage,
      path: "/profileManage",
    },
    {
      component: FamilyDetails,
      path: "/familyDetails",
    },
    {
      component: NoInternet,
      path: "/noInternet",
    },
    {
      component: KycManual,
      path: "/kycManual",
    },
    {
      component: KycManualRevamp,
      path: "/kycManualRevamp",
    },
    {
      component: KycHome,
      path: "/kyc",
    },
    {
      component: ApplicationDetailsOneData,
      path: "/applicationDetailsOneData",
    },
    {
      component: ApplicationRevamp,
      path: "/applicationRevamp",
    },

    {
      component: ApplicationProcessing,
      path: "/applicationProcessing",
    },

    {
      component: ApplicationFailure,
      path: "/applicationFailed",
    },
    {
      component: ReviewDetailScreen,
      path: "/reviewScreen",
    },


    {
      component: MySavedSchemes,
      path: "/mysavedSchemes",
    },
    {
      component: SchemeSurveyDetails,
      path: "/schemeSurveyDetails",
    },
    {
      component: VerifyDetails,
      path: "/verifyDetails",
    },
    {
      component: VerificationDetails,
      path: "/verificationDetails",
    },
    {
      component: ApplicationStatus,
      path: "/applicationStatus",
    },
    {
      component: ConfirmSurveyDetails,
      path: "/confirmSurveyDetails",
    },
    {
      component: History,
      path: "/history",
    },
    {
      component: ServicesCategories,
      path: "/servicesCategories",
    },
    {
      component: GrievanceCategories,
      path: "/grievanceCategories",
    },
    {
      component: ManageProfileDetails,
      path: "/manageProfileDetails",
    },
    {
      component: SchemeAwareness,
      path: "/schemeAwareness",
    },
    {
      component: PaymentStatus,
      path: "/paymentStatus",
    },
    {
      component: PaymentQR,
      path: "/paymentQR",  
    },
    {
      component: AwarenessSurvey,
      path: "/awarenessSurvey",
    },
    {
      component: SurveyTest,
      path: "/test",
    },
    {
      component: ApplicationRevampLGDOpt,
      path: "/applicationRevampLGDOpt"
    },
    {
        component: ApplicationForm,
        path: "/applicationForm"
    }, 
    {
      component: ReviewPaymentPage,
      path: "/reviewPayment",
    },
  ];


  return (
    <Switch location={location}>
      {routes.map((route) => {
        return (
          <Route
            exact
            key={route.path}
            path={route.path}
            component={route.component}
          />
        );
      })}
    </Switch>
  );
});

export default RoutesWeb;
