const initState = {
    data: undefined,
  };

  const getSignedPdf = (state = initState, action) => {
    switch (action.type) {
      case "ESIGN_GET_SIGNED_PDF":
        return {
          ...state,
          data: action.payload,
        };

      case "RESET_ESIGN_GET_SIGNED_PDF":
        return {
          ...state,
          data: undefined,
        };

      default:
        return state;
    }
  };

  export default getSignedPdf;
