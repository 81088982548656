var language = {
  "9e66e6bd4069124578736528a0432752": "About Us",
  "0f5590b58efe9cfcce35e55a66280e59": "Accept & Continue",
  "77ab8893d0d282a9f2781d5711883903": "Add",
  "c40eba17959a63e52412cac8c38d467e": "Add Profile",
  "ac714b256ab565c91ade9e03e8c410f0": "All your data has been deleted",
  "b31b443baac1c6da4cb78dfaf2e5be44": "Application Link",
  "0ec157e0e15e12c56bfe1c72e0e7515a": "Apply Changes",
  "181c1a0ee6db7d7f835eed29791749ab": "Are you sure you want to delete this Scheme?",
  "d937652cde254e2a9b93b74a4ee2a02b": "Are you sure you want to permanently delete {{name}} profile ?",
  "3ac1df709254c0e491c642aae5400814": "Are you sure you want to permanently delete {{name}} account?",
  "1cbb32715ee74ee3447f48febcc04864": "Are you sure you want to Delete this service from your Saved Services?",
  "ea4788705e6873b424c65e91c2846b19": "Cancel",
  "f4ec5f57bd4d31b803312d873be40da9": "Change",
  "6dd495f1c2ae29c8e9a6c4405f18e22e": "Change Location",
  "b1ad1d3aef325b23950a43cb82fd0670": "Choose Language",
  "714341d9339756588d1dc724991c2b9a": "Click Here",
  "f72b489f19f748cf78a86c78d547ed91": "Confirm Your Location",
  "a0bfb8e59e6c13fc8d990781f77694fe": "Continue",
  "bee04ef1315b3f9562be34e2e28a7831": "Delete Account",
  "3e9983cf1885a5ec9f5a5d8127137bd2": "Delete Profile",
  "2ac7a924af94bf173f38eb31165efce3": "Delete Scheme",
  "e0bb2f66e5b61be3235bf8969979de19": "District",
  "46a43a7757337712d4f6a3701a5c57dd": "Download Form",
  "4d7be8ac1b9cb2fd5843dbea7ce30344": "You have not saved any service yet",
  "edda2208040fe25b06d0d8f3af976651": "You have not saved any jobs",
  "f283a3e62b2809c07cf77075103c012f": "We need some basic profile information for finding best suited Government Welfare Schemes, Services and Jobs for you.	",
  // "f283a3e62b2809c07cf77075103c012f": "Labharthi needs some basic profile information for finding best suited Government Welfare Schemes",
  "ce8ae9da5b7cd6c3df2929543a9af92d": "Email",
  "01e8b0c4e059b594202e976860b2d481": "Explore Schemes and Services in your own language",
  "483a5da192f46e3cfe8391d3ef234fc4": "FAQs",
  "37aaf54433bbfe989db8871121fe0ad8": "Follow Us:",
  "e0c4332e8c13be976552a059f106354f": "Get Started",
  "48dee7c291ac7f19e499438b072da336": "Government Fee",
  "2ceb32552d78024d91a123897636afcb": "EasyGov's Citizen Empowerment App",
  //"357aa540fd5c8ae584ba18359105de3d": "Do you have any question about any Schemes or anything else?",
  "357aa540fd5c8ae584ba18359105de3d": "In case you have any query let us know and we will get back to you within 24 business hours.",
  "b3ba00d60ff5a9e11c15a6efbed13746": "To update your current profile click on “Update Current Profile” and to add a new profile select “Add Profile”",
  "12ceff2290bb9039beaa8f36d5dec226": "Jobs",
  "5eb57a6678ca337f92884f49b578a908": "...",
  "4994a8ffeba4ac3140beb89e8d41f174": "Language",
  "846eb834b6bfe81dbd412bc010f8cac7": "Let us know and we will get back to you within 24 business hours",
  "a58252e21aaf876bf7b64c05cce1c477": "Link copied",
  "ce5bf551379459c1c61d2a204061c455": "Location",
  "ccffe10fe854018805baf0d3d9327285": "Manage Profile",
  "49ee3087348e8d44e1feda1917443987": "Name",
  "10ac3d04253ef7e1ddc73e6091c0cd55": "Next",
  "bafd7322c6e97d25b6299b5d6fe8920b": "No",
  "31916d996c9f53949315692bc897f0c7": "Not relevant? Continue looking",
  "e81c4e4f2b7b93b481e13a8553c2ae1b": "or",
  "0d5e50bf1643118ee026e5d19226542a": "Please check the spelling or try searching for something else",
  "64ed408b000d73143e6ab1b5acb7c26e": "Please Select Profile",
  "fa2ead697d9998cbc65c81384e6533d5": "Privacy Policy",
  "7595f48afc2f088bc1ff3313ceeb3605": "Procedure to Apply",
  "cce99c598cfdb9773ab041d54c3d973a": "Profile",
  "665d0095958d696322be90e4eb606977": "Profile with this relationship already exists. You can update the current profile",
  "27bcc51421612805a9c006f24a4d6dc6": "Remove Scheme",
  "f8b66e3c488bb1df16cb3c7bb21447f0": "Required Documents",
  "82609dd1953ccbfbb4e0d20623193b56": "Return Home",
  "c9cc8cce247e49bae79f15173ce97354": "Save",
  "c320cdeffafbb2d60d6d759619c6b5e2": "Save Profile",
  "ab9753c948520fb02c20831980bad53a": "My Schemes",
  "827452688eed02a12178e96f924ac529": "Scheme",
  "cb80cf588ba5a0d3dc067b0b0ef219e9": "Schemes",
  "1925ba54f16355af0964df7dfa145761": "Schemes For You",
  "13348442cc6a27032d2b4aa28b75a5d3": "Search",
  "32df01b9cf0491a879250b58ba2744ba": "Search Results",
  // "988cddb5e417156bd3603983dc0c32e5": "Search Schemes",
  "0afcf4c1ba72c441b41fbafaab253fc6": "Select Location",
  "be2933038f93e25262400220080c111f": "Select State",
  "dfe1d316ec9338e23c892b573667b9bb": "Select your option",
  "c2ba7e785c49050f48da9aacc45c2b85": "Service",
  "992a0f0542384f1ee5ef51b7cf4ae6c4": "Services",
  "48ecd536efbb3622296aac8de326fa38": "Sorry, based on the information provided, you are not eligible for the scheme",
  "4cd9a992dc66802a4b44c366881957d1": "Sorry, No Result Found",
  "f86301756ec6964cb96e2b80a38f4162": "Sorry! We couldn't find any matching schemes for you",
  "46a2a41cc6e552044816a2d04634545d": "State",
  "efd9a0ba1e9d553a070028627fdcae18": "Survey Details",
  "4cd61af85d0ec40f50349cd7c3054ace": "Switch Profile",
  "1d8cd2bfc6943dcf70236f7d351572a0": "Terms & Conditions",
  "4f7e556abaf92ce8061ad57cc4e7edd1": "This is required",
  "371999537b8bb6e7f0099b0c40e0e41d": "This profile is already created. You cannot update the profile.",
  "9eed21a5afd3ba6841c523b0d70599c7": "To view Schemes click on any category with less than 2 or To run a survey for specific category click on category with more than 2",
  "37528894f18a085f9a078496a68eaaa8": "Trending",
  "d876a9fe1aecb3ffc05a5639ebe22000": "Try Again",
  "06933067aafd48425d67bcb01bba5cb6": "Update",
  "fba7f6c863513d97922faab074d3c716": "Update Profile",
  "90b1f6651953fcf8b43ddc0da6760aaf": "User Profile",
  "d8d7ad6abfd08c27baa8bedb78c72ddb": "View All",
  "acac138f2e6f039df2e1fed2f2c0bc44": "Welcome to EasyGov",
  "6ebb2e31989c843ea65feefa43237dad": "When you delete a profile from your account, you won't be able to retrieve any data or information stored for that particular profile. All proactive and saved schemes for the chosen profile will also be deleted",
  "6a64fbe7b065fc8579f2521e0054de02": "When you delete your Labharthi account, you won't be able to retrieve any data or information stored in your profile and other profiles associated with your account. All your proactive and saved schemes will also be deleted",
  "93cba07454f06a4a960172bbd6e2a435": "Yes",
  "3aeee34bea95f2b0ced72be632778670": "You have not saved any schemes",
  "cb32c835ee043e0e2faf4860e46669ae": "This Scheme/Service is Under Review. Please try again after sometime.",
  "c2e0ede88489afe5739c8973aa03a787": "There are no schemes matching your profile",
  "28d2dfa9b435bd81cb2f7f0499b846b3": "Find Schemes",
  "52561ab2d4640b4e299e817b6ba79173": "Welcome to Labharthi",
  "fb0fc4b5fdb2bef7b1c42f2cfd523c2d": "Attempts Left",
  "e13e1cd5f02de37413f9497cc5b65de3": "Your account has been locked for 30 minutes. Please try after that time",
  "f2a6c498fb90eebdu72k7f888fce3b18": "Welcome",
  "1172b2ea87bed4861c8c43a201d53031": "My Services",
  "bcc254b55c4a1babdf1dcb82c207506b": "Phone",
  "8458bad978f4fd6d6c7f8ec2ec0824b4": "Forgot password?",
  "d67850bd126f070221dcfd5fa6317043": "Sign Up",
  "619d59e2ea9f9bb9fe287404dd3ca931": "All Services",
  "6b46ae48421828d9973deec5fa9aa0c3": "Sort",
  "104f0a6879399d6285ab770ed0dbc257": "Remove Service",
  "0b3937a9dd481d527fcc72adaa735b57": `Don't have Labharthi account?`,
  "2f453cfe638e57e27bb0c9512436111e": "Continue",
  "f2fdea440d768b85591e936a803c3631": "Sign In",
  "82b1fbd43ae27918a37b212a4f60a9ad": "Submit",
  "31693e2e9aa2e5be1bf3ae45ede9c98d": "Schemes you are not eligible for",
  "d43cd6a49673f0f486ed7fbea1a5fd8a": "Aadhaar number",
  "3a06727e678189b8502408cb9b50b437": "Change Profile",
  "0323de4f66a1700e2173e9bcdce02715": "Logout",
  "644cd55dbe4d01e7e00fc7fc458f9b8f": "Are you sure you want to logout?",
  "c0fb5959a7a8d3ea991f9edb0b1a9e85": "Please select ID, we will get schemes for you",
  "827a37fb93758383027c582a39ec6162": "Type of ID",
  "cb3441fa3e1291a76dd5ea72e656bca3": "Continue Without Any ID",
  "99dea78007133396a7b8ed70578ac6ae": "Login",
  "02aeaf2672eb06861c144004ef2248d2": "Login Account",
  "d4d208eb2d8ccd525a64be73f3521dc5": "Invalid Credentials",
  "e8055d9577ec8edf34ee15a88a937daa": "Don’t have an account?",
  "942a3ef743c4e176f75d5223455f7e1a": "Enter your 10 digit phone number",
  "21bdc5689c12595ae14298354d5550d5": "Already have an account?",
  "dc647eb65e6711e155375218212b3964": "Password",
  "0b3e8b8021c9b2fafee35ae01ac2cfb9": `${window.projectName} App will help you in finding best suited Government Welfare Schemes for you and your family.`,
  "05aca668fa6754247f5094c042fb64ac": "Phone Verification",
  "f9bc92a6602b9692a0ba578120c9c441": "Enter your OTP code here",
  "d9d1e60e50119d9752001d4196ee6b3c": "OTP",
  "6e95a24fa2a98c2f59c96a24b182ccfd": "Didn't you receive any Code?",
  "f922096c878b694ec7a3d9282a55904c": "Resend New Code",
  "32b830cef750168335d96aff4dd98782": "My Jobs",
  "bbd26bc8ce3623addc9c54c36f692f8d": "Jobs For You",
  "67cb88ed21d4232c4681a5e648554e09": "Application Fee",
  "b90455b71eb5e2399be9d20e23c1a1db": "Last Date to Apply",
  "bfe72f18052c940de13501ae9877c3b0": "How to Apply",
  "4f1f44ef7ce0c8a111f33fe400a6e4d7": "Selection Procedure",
  "0f5679acafa674831691cfa01d17501e": "Job Notification",
  "a45ca50bcc7830af2f84fd046648ec92": "There are no jobs matching your profile",
  "c4bcece293eb1ad7f09d4cf9c2037ea6": "Find Jobs",
  "5dc52ca9ffdc26147075682c92a0a853": "Posts",
  "3f99ba6fbcb46f28fb24f271fc3c02c1": "To view Jobs click on any category. To run a survey for any job click on the card",
  "2078989a6c09fdd98efc35d3c1d1de3d": "Delete Job",
  "08c1517355f6e7313c4d629a193ac69a": "Are you sure you want to Delete this job from your Saved Jobs?",
  "e614dff8cba6f73ae7cee729fe0091dd": "Invalid OTP",
  "c46c1e69c89811f03dc73feb09273409": "OTP is required",
  "ae3bb2a1ac61750150b606298091d38a": "New Password",
  "887f7db126221fe60d18c895d41dc8f6": "Confirm Password",
  "70d9be9b139893aa6c69b5e77e614311": "Confirm",
  "20aedd1e6de4dcf8d115b5a7424c58d7": "Password is required.",
  "841a389ec69697c28df7b9739419d21d": "Password should be 6 character long.",
  "bcb9407d90fd17ccff50587f5edfe987": "Password doesn't match.",
  "318721657b639c8ca02293211db68837": "Phone Number is required",
  "4ea3456b0e1b463f50b81c79230cf070": "Invalid Phone Number",
  "bd454fc3d32f4425d146d3e861409871": "User already exists",
  "ab48b978d197e315e92e904e36d26175": "Phone Number is invalid",
  "70729403b9560d3adb142977608b81d2": "OTP Limit Exceeded. Try after 4 hours.",
  "1e4dbc7eaa78468a3bc1448a3d68d906": "Phone Number",
  "4f2df4c330b8df125a3d75f2d69a9b5e": "Send OTP",
  "9ec54ec336b65da6179cf750de33ecd7": "Forgot Password",
  "98adc700c19f071f07a714a703a6fc1e": "ID Details",
  "f262fae0a2f17e8c1eb4e9eddb0160e4": "Proceed",
  "49fb619c4872c491fae649c28b339ed8": "Your credentials do not match our records",
  "58557a71f9811899313234decda72d5a": "Your account has been locked for 30 minutes. Please try after that time.",
  "e7665f483813a56e9410787ce2d174ef": "The phone number is already in use. Please sign in or use another number to Sign up.",
  "0a46fa5fb9e86e80fc94e229d1967699": "The phone number is not registered. Please retry",
  "bdff8b1cbfb4d5623382d7b0678e707c": "Password must contain atleast 8 characters, including atleast one upper case, one lower case, one number and one special character",
  "d55085482b3e8da780755832c17d7d60": "Last Date",
  "988cddb5e417156bd3603983dc0c32e5": "Search Scheme and Services",
  "261b39077b81875e115baa22bc6ce04c": "To view Jobs belongs to a particular category, please click on the category",
  "bdb53070a3fc01196ae38b059a4b42ff": "Remove Job",
  "ede1fcad3d6bf808fc948df7d22e1536": "Sorry! We couldn't find any matching jobs for you",
  "0c4fa7a0201f434b0ef84494d0aac07a": "Sorry, based on the information provided, you are not eligible for the job",
  "e4defbb7cdf529fa3606749a82c06932": "Posts",
  "25d2401d71f4ba1ba7685559c1b77927": "Based on your profile, we did not find you eligible for any job.<br> We are continuously adding more jobs, please check later.",
  "ea7c2400c0ae5f58608cb5051ee852f3": "There are no Jobs configured in your state at the moment, we are continuously updating, please check with us again",
  "24fe48030f7d3097d5882535b04c3fa8": "Expired",
  "9dc0186e2d5d3398ffee6ec964b15dc0": "All expired jobs will be removed from your  “My Jobs“ after a period of 30 days from the date of expiry.",
  "34a934c0c86d866df9d14f8a6e90c637": "Reason for Non Eligible",
  "a97ea56b0e00b2379736ae60869ff66a": "Question",
  "8e9e3212461f14c8dbea6456c920bcbc": "Eligible Values",
  "c4dce677291fa5bc05276ffe93c5d8f2": "Value provided by you",
  "9a917c4a4f546c6072bd61ae617763bf": "Scheme Not For You",
  "e57c13893a290ce882569b5638ba7b82": "The ID entered by you has not been found. Please try again.",
  "e26859a59ede4ab8d8ea0213b68fa732": "The source system is currently not responding, please try after sometime.",
  // "e26859a59ede4ab8d8ea0213b68fa732": "The source system is currently not responding, please proceed without ID or try after sometime.",
  "d2235c7ecbbbae1bf62833dade7486f5": "Profile details have been taken from the Golden Record.",
  "346df6b73ae19a6619f26648cf415522": "The list of ineligible schemes is based on the profile data.",
  "6e0fe1553e84e34da97a6c4c9040b31b": "Labharthi",
  "27559de2125101ccb786807bceb46aa1": "India",
  "936b6a0effe4990e8f5420a0099fa537": "EasyGov",
  "8cf04a9734132302f96da8e113e80ce5": "Home",
  "923b39c56654bb6264b6638ba8386b3c": "Sign In/Sign Up to create your profile",
  "52f2ba99491a1bae953303a4eaf50196": "Login with OTP",
  "18e0c8084d60f55016d67d4e2ca507ce": "Login with SSO",
  "d3d77957f18f1e208cb5b0caa07bec5c": "Find more Schemes",
  "6a26f548831e6a8c26bfbbd9f6ec61e0": "Help",
  "b2119dd0c37bb396e24199d5ffbd0c72": "Screen Reader Access",
  "34b6cd75171affba6957e308dcbd92be": "Version",
  "4221d3e17c6eca2ca6337251a3cf9c4e": "Last Updated",
  "ece2c7eaaac9f27b055e6e482ab776b9": "INFORMATION SEARCH",
  "ad8f678ccf4a4a34b46f01c74f25b91b": "To help users access information within the website, a “search here” option is been provided. here, the option to type in Kannada is made available. while searching, usage of Unicode font is compulsory. option to search in English is also provided.",
  "698aa21b21c2252839e5e54a0d2f12ef": "INTERNET ACCESSIBILITY",
  "4ac2856693f5c8b4fc6cdaf669ed2d74": "If the internet is slow or unavailable, there may be a modification in website design or some subpages may not open.",
  "0da22543a45316a826571412b3d5953c": "The website complies with World Wide Web Consortium (W3C) Web Content Accessibility Guidelines (WCAG) 2.0 level AA. This will enable people with visual impairments access the website using assistive technologies, such as screen readers. The information of the website is accessible with different screen readers, such as JAWS, NVDA, SAFA, Supernova and Window-Eyes.",
  "cb976b928eacd65e6342cfa78bf196da": "Following table lists the information about different screen readers",
  "786f669131d80fbed4572a520c28c14d": "Content created and owned by EasyGov",
  "fc290a7672f96fd8b94a9659c8c02322": "| All rights reserved",
  "6016a2b341113bf496b719905398ecd2": "Copyright",
  "0e8a85115f2c3ef0058c70f30a9b6deb": "Please enter OTP sent to your registered mobile number",
  "34f8ccda5172e4eac9a8a014559b38c1": "Please set your Password",
  "548899be111ad732b37786783dfbd6d8": "No Internet Connection",
  "5c3a1593ca1d34226359df44f3d45f2f": "Please check again after sometime.",
  '35043eeac5651298d4bb6c100e071745': 'You will be redirected to the third-party Website. Do you want to continue?',
  "6182e08f3974a70ac4883b5fceaa14c0": "Please enter OTP sent to your registered mobile number",
  "306fb6389c3b3fd2835af15cfc7c66e9": "Change Family ID",
  "63fc7651ee1dbf6868a43a5b5fa8b581": "Only characters are allowed",
  "6903fbe8375d52217c15618170f4078a": "Only 30 characters are allowed",
  "9ab37820a722ce13e80a3d0aa468d6dc": "*Required",
  "0df1f18a2fa23cd60990e47692f99e07": "Application Link",
  //  PHASE3
  "a60852f204ed8028c1c58808b746d115": "Ok",
  "edbd5921e9c7168e04cbe6f75ee76174": "Based on your profile, we did not find you eligible for any scheme. We are continuously adding more schemes, please check later.",
  "a203c39a340e1d45069b1ba64e1d003a": "Attention!",
  "037673ab5a84897e0ee43ca37f7680f6": "If you change your data the same will also be updated in your profile data. Do you want to continue?",
  "d1d16cede248b826a431ea11132567cb": "If you change your verified data, it will be re-verified and your status will be changed. This may lead to higher chances of rejection or increased processing time of your application.",
  "23a0ca84a7fd64eee8248bd1dfc77185": "Change Name",
  "3b383caaaa6113dd83b381b43a97d7ee": "A profile already exists for this User, Adding a new Family Id will remove the previous details. Please Confirm",
  //Improvement
  "e0efbdcea0abe735dbf887292a5670fa": "Brother",
  "ad6e7652b1bdfb38783486c2c3d5e806": "Self",
  "19cda37369e2d2015e66b9d478ffbe15": "Sister",
  "81e053d78f01540e9cd6fb68a1c03976": "Someone Else",
  "b8e18040dc07eead8e6741733653a740": "Mother",
  "a30ca91be97a9cedc0d2426e54b0e782": "Father",
  "784c01f6b735be57175e2f438297c741": "Spouse",
  "1968ab9fdfedf558714bf6caec9a1234": "Son",
  "7bc1cb93f51f61bde0e90b20c81ebf88": "Daughter",
  "25f34d5943da3f09a2aa6358193a41bd": "Proceed to apply",
  "79dff508ab2e4d272f0630d001271eb1": "Explore More Jobs",
  "b6ebe1e750185946ed41be9191410612": "You need to provide the details to be able to apply through Labharthi.",
  "af1899084d8361b9afb9187da988ff9c": "“Sorry, we're unable to fetch your data”.",
  "1b56218ddf0a290241d661d26253d51f": "Only {{attempts}} Attempt(s) Left",
  "f299cff2484fdb361f4fc3189490d909": "The following information provided by you did not match with your government recor:",
  "1edf5362e5f4af6f370a7f34c3136a7a": "Verification Failed",
  "dcf9ac5128c95d88e068e223dfe01d27": "Verification Status",
  "bc910f8bdf70f29374f496f05be0330c": "First Name",
  "77587239bf4c54ea493c7033e1dbf636": "Last Name",
  "14fcbe1dd0f7a39f0195aba46cf89d17": "Verify Details",
  "b6121db5ee47deeb0ca42d5dea0b2225": "Attempts Failed",
  "839301cec5574d7c3256afce4fb85467": "You have exceeded the valid number of attempts to verify your family details. Your verification has been blocked",
  "6733aa56fd001b9bf479650f3950688a": "You are being directed to Scheme application Page",
  "9303c9bd4f8178680dc382adbfcd62af": "This field is required.",
  "53a04cfc3d09c9b2dfb8349791c62bc1": "Your name did not match with any member of this family",
  "1ca35256fcb85c1ba9ea7ac1095462ad": "You have exceeded the valid number of attempts to verify your family details. Your verification has been blocked for 3 hours",
  "0e3a917198575c463bb4fdcb7332b03a": "Invalid Aadhaar Card Number",
  "630058029627ce58e7571e7a5b6ec591": "Full Name",
  "072355e896ac1a999d2fa0aa208f94ee": 'This scheme has been saved in your “My Schemes”. ',
  "789189638f8a301304dfadeb61036dd9": 'This Service has been saved in your “My Services". ',
  "ab8023b4fc2395738b8fd2df440641a9": 'This Job has been saved in your “My Jobs”. ',
  "671dec8fc4e3dc847c1b0d7b67b4e6e1": "Relation",
  "f252b4e2f3b2963d330c8cde8e9cdbd9": "if available",
  "ef51899b57258db72f15b4703a8a86ba": "A single platform for Citizens to access actionable information for pan India Government Welfare Schemes, Services & Jobs.",
  //New Translations for Phase 1
  "7510e00267e89c78470e79b42ecd6463": "Explore the App in your own language",
  "83218ac34c1834c26781fe4bde918ee4": "Welcome",
  "1d00e7dce692e8dc3f6877f035e3a616": "OR",
  "e0cf58fa3413df99ae83109ebb9a41a3": "Resend OTP",
  "6ed83b24865902c6fd90bbd15247e042": "Setup Password",
  "9157806ea93e82c6843e29353af0339f": "Request OTP",
  "69f18e98d2dd54118ade84732dc3a5e0": "Verify Pin",
  "2dd4472245a696bc0b4b944db2a8b519": "Individual",
  "a274f4d4670213a9045ce258c6c56b80": "Notifications",
  "407456e575dee5a4b84a19a7111a4a4d": "When you click on any scheme category with less that 2 numbers, you are able to view the schemes that you might be eligible for, when you click on any scheme category with more than 2 numbers then category specific survey will run to give you a more refined list of schemes for the selected category.",
  "9139eb8e78808acbd15244d4eb9f1bc3": "Explore More Schemes",
  "c61dd5f0e5b5389306ad5221ec2cd795": "This Scheme has been saved in your “My Schemes”",
  "f2a6c498fb90ee345d997f888fce3b18": "Delete",
  "469bad4859f3c33fcb8ce09314c3e895": "Review Survey Details",
  "7dce122004969d56ae2e0245cb754d35": "Edit",
  "c5984b3fe02f7b06d77fb538d842b0fc": "Update Survey Details",
  "6cf33ce1a7a11a7178131567c777b637": "Profile with the same name and relationship already exists",
  "fffcb4d1e12c96b2f571f77ad25878b8": "Reason",
  "e8d89b6875d217a990d47701138e687a": "Are you sure you want to delete this data?",
  "aa98db08fed41a99a00aac36f521540f": "Last Verified",
  "5da618e8e4b89c66fe86e32cdafde142": "From",
  "253ec560db0637f6a0e53aefcbd4be0c": "When you delete Labharthi account , you won't be able to retrieve any data or information saved in your profile. All Saved Schemes and information stored for {{name}} will also be deleted.",
  "c0e70d5bfc28ae3aadfb8f85697c54d5": "Your data has been successfully updated.",
  "09b73aba9204a05ba5eaf0ea471fdc8c": "EasyGov is a registered trademark of Surajya Service Private Limited. EasyGov's platform-as-a-service model transforms Government welfare from being conservative, reactive and program centric to progressive, proactive and family centric in a phased manner. EasyGov is helping various Governments to solve this complex journey of the Welfare System for the benefits of the citizens. Please visit <a href='https://easygov.co.in/' target='_blank'> https://easygov.co.in/</a> for more information",
  "8f241c62a9523b05bf0b6b16a09d856d": "Change Language",
  "1ccbe6084301f9b1e18e3e7caf723ae1": "Are you sure do you want to Logout?",
  "32ca0a0f69103bad1e6f134d5a8e36d7": "To view Jobs for a particular category, please click on the any job category",
  "d6e6cb19e3b9c02f89d6cd54cfa7c613": "Business",
  "b1c94ca2fbc3e78fc30069c8d0f01680": "All",
  "1dd1c5fb7f25cd41b291d43a89e3aefd": "Today",
  "ebfe9ce86e6e9fb953aa7a25b59c1956": "Yesterday",
  "4b2ef3655b5d6d48f1fa41892d0043b9": "No Notifications",
  "094f537cfb139894f1412200c78e87b1": "Are you sure you want to delete the selected notifications?",
  "4201c11dc26b42deb90cb119c0b12fdb": "Download Notification",
  "ae45c43efe0011f67ec52003c0d8f916": "<b>Hint</b>: The 'Password' must contain at least 8 <br> characters,Including at least one upper case, one lower case, one number and one special character.",
  "248336101b461380a4b2391a7625493d": "Saved",
  "f03ab16cd58372c77ba45a3d9a5a1cb9": "Draft",
  "a4d158b65f20725f9fe9c1763e9805c8": "Private Sponsored",
  //New Translations for Phase -2
  "7fe12d923db43febcc5d8ea18df632ca": "Select your KYC Document",
  "d8ce714c8a76133a8d5746e3bfdd7ef7": "KYC",
  "4f67fe16b274bf31a67539fbedb8f8d3": "Document Type",
  "5127fa87ed375848481bfe9c786d8022": "Fill Your KYC",
  "8f75e3041034138aba310ba3f097ed24": "Document Number",
  "9abfb506bebd1e72069f0be0014986dc": "Invalid document number",
  "ecfffd1bad9944f6a50e369f63268cff": "QR Scan",
  "c541f95d69a4a507b87b32d05dd6d73c": "Place the code inside the frame",
  "3876661cb83fa41284f828bd8d7fbb7b": "KYC Documents to be uploaded for verification and completion of KYC process.",
  "cc1d6f3625c4eb671d76b6ebf3f6f544": "Know Your Customer",
  "08ac502bbb7652c683c1da97c7fdeb94": "{{xxxx}} has sent an OTP to your mobile number",
  "d19a88ee579190614084c2ea84379ac7": "Resend OTP (1:00)",
  "7397e92003e187474e5eab39b27fcb6b": "Sorry, We're unable to fetch your data from the source systems. Please try again later.",
  "a943d4ac1dd2e0048bcbf85225bf2811": "You have exceeded the maximum limit to generate the OTP, Please try again after 3 Hours",
  "8dac090b39cf9b3bff9a9503531d04cf": "Your account is currently blocked, please try after some time.",
  "08798caaee5cc8ea1282883901c8810b": "Attempts Remaining - {{Number}} Warning - After 4 Consecutive unsuccessful attempts, your account will be locked.",
  "33018956b3ffe20d413e3c6780f19aff": "Confirm KYC Details",
  "90f35a35415ca3b4c623d7c515fce1c0": `Attention! If you update or change your details your data will be unverified and you will asked to upload document proof for the updated data. Click on "Continue" to proceed`,
  "aee68a98fd803f091082faf867c0f62b": "Fill KYC Form",
  "2e853d869dd0c3cb0a64e4938ee90647": "Invalid pin code",
  "8f3124e142a4000fe04a581f4f41c8da": "Invalid {{labour card}} number",
  "dbb362212f1b8118f17633521d1e72f7": `Your application successfully saved in your 'Draft Scheme'.`,
  "5c93310dd0291e121181e830cdda892e": "Gallery",
  "967d35e40f3f95b1f538bd248640bf3b": "Camera",
  "37bed5f24fa3752c7830c021501539a0": "Are you sure you want to remove the photo?",
  "1a263d755f169c2499c1a55f7a465b93": "Your Document ID is currently blocked, please try after some time.",
  "689707127ddb398dc4b447e1dffa3a47": "You have exceeded the valid number of Attempts to verify your details. Your verification has blocked for 3 hours.",
  "04c850a81052df23680d00d2aa1d891e": "KYC Status",
  "a441e519087df0d7c8572b9fafa14ae2": `The following information provided by you did not match with your government record.1. Your Name did not match with the record.2. Your Age did not match with the record.`,
  "75514ce9dac260a1f7abd0d85ce6af46": "The details provided by you don't match with the records",
  "5955e277da6fb75982b0f651f4ae8b92": "We are unable to fetch your data from the source systems. Please try again later.",
  "be60ac326400cc5ef0d173a833c4a40b": "Your details are being verified",
  "d1083e84a9a7283ffb081263e8533509": "Your Application has been successfully saved in your ‘Draft Scheme’",
  "0bad551abed71c0a41ca8df804afec86": "KYC using document",
  "e6387f3e684958515af2947d8cdf01d7": "Update KYC Form",
  "9c7ed3d8ac60281e90519b4ebf07a9a2": "Select address",
  "31e41095bfaa14799239e8d9ba7ad438": "Application Details",
  "e1e4c8c9ccd9fc39c391da4bcd093fb2": "Block",
  "b9eca8f59016e8ff69a2c76fa869d23f": "Upload Labour Card",
  "6cf24d7a07132fe88b610e2b3d3def28": "Self Declaration",
  "827b05360cd22ccbaf3d085aded71570": "Proof of Address",
  "f734cd7792862bebbbb87ddc54285376": "Proof of Identity",
  "5a8c686dfdba564a2801806f3827c124": "Image must be in JPG or PNG format. PDF file must be less than 1MB.",
  "d495cdb8315e12e3dc5fd6121ed69cbc": "Invalid IFSC Code",
  "cd9f177eac6411501839f7868e38767b": "Are you sure you want to delete this document?",
  "565d2daf0ccaf00d354669e7551b81dd": "Review Profile details",
  "9895dd168483d1d34ab9ebfb2b6760be": "Select Bank",
  "deb5c3f30f3a8381367392d6608fbe68": "Application Status",
  "970324882e5bbfd2deef9e5b7e2d1160": "Download Application",
  "03b55d8ed61b625c209cc42ea0a35b8c": "Thank You!",
  "8276f0b86b5ee98d03f2eafba3590bb0": "For giving  the opportunity to serve you. Your application has been successfully submitted.",
  "ac3122aeddff176ae67d3df2b2198d19": `Thank you! Your application has been submitted for verification, Check your application Status from the app under "Applied Applications"`,
  "73da163444abaee6a5c88532b8eda629": "How was your experience?",
  "74eb855e4de6fe58228f03006c02fd8a": "OKAY",
  "d36ed38206da38c85af59039d18f1108": "<b>Describe your Feedback</b> (Optional)",
  "446e92f15cd245125ccd983ef968e692": "No Thanks!",
  "634a1a1ed07518476cb7a4beab87185a": "TERRIBLE",
  "f1b68d66337a81cfa0d2076171cba2a8": "BAD",
  "45802158e78dd9584161629098018fe8": "GOOD",
  "5709082e0d4e7a2cb92c2348acf297be": "LOVED IT",
  "c629e8ac2592361ec6474f07fb75f6fc": "Your feedback allow us to discover new opportunities to improve & make sure you have the best app experience.",
  "871abe85eba547582d9e8f0f9c61efbd": "Thank you for sending us feedback.",
  "733d43480c8589b1368e5def6b480415": "Applied",
  "a5b739036b4d0b2bc966291747198d9b": "Enrolled",
  "0a4c051fb821b8e96d2bd564b893639b": "No customized schemes for you",
  "9639e32cab248434a17ab32237cb3b71": "Apply",
  "3f68e67dc6c397aaa9d1c24c356f754f": "Verified",
  "d63b911814d17bb4d541141b8531a458": `This schemes has been saved in your "My Schemes"`,
  "454fd3b5167e532f28f4e3d47cd901fa": "You have no saved scheme yet",
  "5e22b63835aef7976f3ca019841b1b28": "Are you sure you want to remove this scheme?",
  "8454c7c12286671b47684cef824ffba6": `"Attention! If you change your data the same will also be updated in your profile data. Do you want to continue?"`,
  "24a23d787190f2c4812ff9ab11847a72": "Status:",
  "85896a744f6fd84cbcd7bddb31fae8d7": "Action:",
  "ccb168b5a6a86eb100dc2dee754a316b": "Reason:",
  "85b7d770b94264f51f31d2d7a98f69f0": "Withdraw Application",
  "0ddc5e1c93fec761445a53ee9fd70285": "Send to Authority",
  "7a4813490daea6bbe84ed02f9534570b": "Application & attachment verified",
  "f9e39c8a1b3348b654b73fcdebeb3265": "Application not approved yet",
  "6b76127f99b6f29c2a725da125d98760": "Benefit Disbursement",
  "4296fd320beaf61dcf979ff634cf2f2d": "Action required:",
  "fc341b619c2671a430365edefc100ff1": "Complete Actions",
  "4f4b8b7d27a33c83089f30dba4989be5": "View Applications",
  "2f70605f8a2f6c01ac06fe0f6d32b44c": "Are you sure you want to withdraw your application?",
  "6eaaa83f6b9b14dbed5e0779d91fffb5": "Application Rejected",
  "6187927c14aae6cab0f87b55ef75edb1": "Re-Apply",
  "864cacf59d640b51390faf29867c310e": "Proceed For Next Benefit",
  "16d2b386b2034b9488996466aaae0b57": "History",
  "c5b2992c0919083c2d8907f8fe1441f6": "Socio-Economic Details",
  "d79c1042de4ec2d7e6d84d3a8f436765": "Occupation Profile",
  "3c8d3371dfe92bb990493d3dda09717c": "Education Profile",
  "181bfe2f225b876c687104878a6b43cc": "Housing Details",
  "f081b3a821bf13173ac4ec3468122a8c": "Health Details",
  "fa284c99eeef0e1c874643e05a449214": `"Attention! You cannot unselect all values. Choose another value before unselecting this value."`,
  "311d38f551d45cb61e297748f08bb658": "Your data has been saved successfully",
  "4500d073ef5972cc935a59ff0b752d8b": "Valid Till",
  "8de420ed8c345d37cf65a3494bff92ef": "Voter Card",
  "f6fb95a7bfd158a53680691f250e2273": "Government Fee:",
  "c51bae43a860107d2ddaddcc44c72355": "Proof of Address (any one)",
  "a1cba2ed3414b72aa4d7651653d08900": "Proof of Age (any one)",
  "330dd937743341b8c97a75ddbcfacf1b": "Link to download form:",
  "de259fed6ef60355d0dd031c4a97e945": "Link to upload form:",
  "6204374d06a6ca36a20e887451db78af": "Explore More Services",
  "98d047289f68350b76f20cd10f1db3d4": "Proof of ID",
  "1a01812e1888f4eb6dca36e8b1d39c04": "DOB Proof",
  "0dcd6f339c0f72875972d60324db52ff": "Application Fee Details",
  "133e72d94045f209d146dd6fc01de185": "Fee Details",
  "b6d11bb63dc2071d6cba2aaeba8c8a1d": "Convenience Fee",
  "719fec04166d6fa75f89cd29ad61fa8c": "Taxes",
  "a876fbe73a6d039cb8c8566f6aa716d5": "Total Amount",
  "9d463ea3feb5afec51c20b4f62927ca3": "Coupon Code",
  "620a92a00838e523161dab899b76c764": "Proceed to Pay",
  "6f861b42317b2333c01faa9b555b7e14": "Savings with this coupon.",
  "c6088ccfc87a825ceb53a15ce4d99510": "You have availed a total discount of <Amount>",
  "0e54fc6a9dba79c2bc03ac08f3bf4a27": "Yay!",
  "c1d9a46634bb89273f7d95a5577f15ad": "Coupon Discount",
  "1063e38cb53d94d386f21227fcd84717": "Remove",
  "ba8550da63869236a92fed41fba6e84f": "Coupon code applied Successfully",
  "f7a7e0e3897bac9ea4c83d53f6ad5a23": "Invalid Coupon Code.",
  "90fe9a78f3ea30beeb6b7ef447d2b6dd": "Coupon code removed successfully",
  "af1b98adf7f686b84cd0b443e022b7a0": "Categories",
  "11f18dd73f0c3d374d9d5c230cd43160": "Youth Skill",
  "5a6c0d2579b31142130f20104fa58ea6": "Certificates",
  "c482980d384a9d0e7bc39e1140270870": "Finance",
  "605669cab962bf944d99ce89cf9e58d9": "Health",
  "5dac73aa49b9ccaca4b9b0d0ef615d10": "Police",
  "b2ece3493eed05158a9cde4a0d2da2a5": "Public Grievance",
  "94df2a6972ca1fa79411645fe9b42339": "Utility",
  "f5f0c488ed56cf789d19a13ffba8abe3": "Transport",
  "0db377921f4ce762c62526131097968f": "General",
  "bc34e4c4f36b0d66910a906449910f64": "Are you sure you want to remove this Service?",
  "1e22827dff05f5a1c044993aff539403": "You do not have any saved services.",
  "b08f17b81d3b9b4f6287b87c53cfc97a": "You do not have any Services in Draft.",
  "6f96ffbc03ad1b9ebb5c6e2ecfcbdc71": "You have not applied any services yet.",
  "daf87fe41645c86672e96857222a0909": "Application Submitted",
  "54aeeea175c1509b7c56e0c984f9b4fa": "Thank You, for giving the opportunity to serve you. Your application has been successfully submitted.",
  "f18c4dab2674eae1e8c287c683b66729": "Date of Application",
  "fb0728ffa9b40d444db91c818eb21a2b": "Name of Applicant",
  "e500e94f457ddd88824c88fda548bed2": "Application ID",
  "ad0206b8eec486df629ce37169376f65": "Role & Responsibilities:",
  "6f2ad1cfe3c5b08cc53c62470f4cb1f7": "Documents required:",
  "1d83797411218ecca1e5ff4d4705dfb0": "Selection Procedure:",
  "ce9e1e989c012211d8bc83c5353b4495": "Job Application Details",
  "18dcaa84c2ee447028f8450cd0380ba6": "Academic Details:",
  "93e9a018926429f6d0b0c032910a8eb6": "Review Job Application Details",
  "6f906132d3c6b88b57225fdcf76f6e71": "FAQ's",
  "2d8d66b9c9b540fcb8384167f1565b64": "Please check the spelling or try searching for something else.",
  "f73a506c52c2dba159fc0fbee823aace": "You have already applied for this Scheme. Please check your application status for the same from your Applied Schemes.",
  "b73856e1c7ab3e647e28663d5e30fabe": "You have already applied for this Scheme. Please check your application status for the same from your Applied Services.",
  "cd850a99c0895272f2cdf23586867b16": "You have already applied for this Scheme. Please check your application status for the same from your Jobs.",
  "4e0b8852fc3f3d1a2b4c24c4602766dd": "Image must be in JPG, PNG, PDF file and less than 1MB.",
  "5a9e7167eb5b7cb7bb46cf5c05eed59a": "Recrop",
  "91412465ea9169dfd901dd5e7c96dd99": "Upload ",
  "3835b71ace5673385a19d56bc938fda8": "Field Photograph",
  "4386624773a0ef6de5a5de0dd6f8facd": "Enter Document Type",
  "fdba3d2a9c707e50f4eec34cd6bdd0d6": `"Know Your Customer" is the mandatory process of identifying and verifying the identity of the user who is applying for a government scheme.`,
  "244773cb8f460196045c5b90659d2c9d": `Your application has been successfully saved in your "Draft Services"`,
  "bbcb2990551820fc4678835c14a84503": "Remove Photo",
  "78e3e2ba60b81cf6cc24e6baf89eaa81": "Personal Certificates",
  "88bcab139c5d6142b04bf77d7dd1c023": "You have already applied for this Service. Please check your application status for the same from your Applied Services.",
  "bf8cd5900a65efaa89931067cb1d3775": "You have not saved any Schemes in Draft.",
  "beab92ac3c8a798a35aea2ad0809313f": "Continue as a Guest",
  "95884374c7752d972101b70854caffe6": "Sign In/Sign Up",
  "e44cb1c4a784a247f7296cc02f7f301c": "Select District",
  "9aa060dc3e3bb3ffff9908c5e713a80c": "You have not applied for any services yet.",
  "383f419ea8c0b8142cbdc71e2e4b144a": "Previous Question",
  "fb188e808b65b9ee48a5c9d88fb92af2": "Start Again",
  "db5eb84117d06047c97c9a0191b5fffe": "Support",
  "bc80ae98530c6ba4e76ed34c58d81875": "Download Easygov for Mobile App",
  "85ce0cae173233410acbc380a7e531cd": "Please note that this page also provides links to the websites / web pages of Govt. Ministries/Departments/Organisations.The content of these websites are owned by the respective organisations and they may be contacted for any further information or suggestion",
  "ad0a802ec8134e9210a5625933db50a7": "Disclaimer",
  "26dbd93cbe6f1e56b1577d8e3cb7fda3": "Select Services",
  "c6582b36b0f516684a873e8782517822": "Select Schemes and Services",
  "b98287be44e1230cf144aa3af08ac82f": "You are not Enrolled for any schemes yet",
  "449d3cf7dd09172024ebe93872410f0f": "View Application",
  "025d9b3a3c2a6a27d04c57c1d7cabc9f": "No Action needed",
  "06df33001c1d7187fdd81ea1f5b277aa": "Actions",
  "adff901e227bf2b2200c4767fe773836": "actions needed",
  "4ff84d2ee02fe6ef0ca2edc3cdecc0ed": "Actions Required",
  "c08c272bc5648735d560f0ba5114a256": "Contact Support",
  "fd2f820569d83784238e1027897b463e": "Enter your name",
  "cda83d00666a7bfa8cde6808de4ef994": "Contact Number",
  "a4d3b161ce1309df1c4e25df28694b7b": "Submit",
  "4c2a8fe7eaf24721cc7a9f0175115bd4": "Message",
  "68d390535e8a7120e3f6942d8a2214a0": "Enter your message",
  "2f644b4a27e68f505c4f4ac2ffe3a8ac": "Please enter only alphabets, numbers, comma, -, _, /",
  "58f7a43f2c3bd7854d99fbbbc3fa4ae9": "Only alphabets are allowed.",
  "4906d981bb592b32f6982fb4187c7b60": "Enter a valid IFSC Code.",
  "562d3d08919ae105c3ebc42d9607d266": "Please enter only alphabets or numbers",
  "fab2bb673b21bef95ee70b99dbd7f9fc": "Only 11 characters are allowed.",
  "a7eb433676475fee54727c14ad880b8c": "Only 150 characters are allowed.",
  "7c58cde3b827bc7d57cbf77865046169": "Please enter only numbers",
  "544b0264d55a1e4cf476b279240d9be4": "Only ten digits are allowed. Please don't prefix 0 or +91",
  "73f0d980e48b8141e0776e02d96a872f": "Only twelve digits are allowed.",
  "0ce98bd973419ff33921a2275e7903b9": "Please enter a valid PAN Number.",
  "c6f91b305e91a0053748310de170a73c": "Only six digits are allowed",
  "9f44bd93c8988c682d5ef5369fd11f47": "Please enter valid email",
  "10803b83a68db8f7e7a33e3b41e184d0": "Date of Birth",
  "ac598199ea0dd028ade4677a435caf4b": "Enter a valid Aadhaar Number",
  "d0de720ccdffea8490dfd2893c50ebc5": "It should be greater than {{displayName}}",
  "ced6e171e13ebd5c37415c822706b9d4": "It should be lesser than {{displayName}}",
  "d1a1cb2bd14fdd9eec972f162cfd1ee0": "It should be equal to {{displayName}}",
  "9483135517d156229d0439dff74b1ca0": "It should be greater than or equal to {{displayName}}",
  "01e9cd748e852b1ae389b980bff195b0": "It should be lesser than or equal to {{displayName}}",
  "dc236871b26ba387e1284445caa99df4": "For giving the opportunity to serve you.",
  "2102fff7bb4de45c9e616e3f01b6810f": "Your Application has been successfully submitted.",
  "dc9466f3d424b4acba2fc5c9f58d4f8a": "Sorry, You are not eligible",
  "a9198ed7d89e246a80078db84d2722e8": "Your Phone number or Password doesn't match",
  "8ac0a98c8c2f2c86396e34b6b5d63e38": "Profile with same name already exists",
  "4ae112e33b3189c9fa04369b51259d76": "Profile with same name and relationship already exists",
  "b0fb09a28a4a52a1b3ca2656dec0435e": "Are you sure you want to remove this document?",
  "e384b6a4760d80be609d897414cfe680": 'You are not a registered user, you can either "Sign Up" or "Login with OTP" to proceed further',
  "c2cd85ffa55b2ec9b049b7eb131d3a9f": "Passwords entered didn't match. Try Again",
  "3a816ee692e09b3cac6ef18e14071bd4": "Your Password has been successfullly set",
  "764d4847b734121ceb58e5c8195fa5b6": "Your feedback allows us to discover new opportunities to improve and make sure you have the best app experience.",
  "701d895fbc64569aa143ca6c44274139": "You exceeded the maximum limit to generate the OTP, Please try again after Sometime.",
  "0bdfc4d562c9b0ccfaa4c0f6d4cac98f": "You have not applied for any schemes yet.",
  "4391bf5189e3e13c4aa4cb314c605ac8": "You have not saved any schemes yet.",
  "92202504591d549b89340d26ffc0753a": `This Job has been saved in your "My Jobs"`,
  "af6ebe0c0e89201cb2294f32955f7b15": "Only 20 documents are allowed at a time .",
  "b1481f771aee527fd910fa8d8672cd19": "Are you sure you want to remove this Notification?",
  "06a6b009c19d5816574cdc7800d10eb5": "Your request has been raised successfully.",
  "b941bc7a9d4fe7a8c7c31ed440754285": "Review application",
  "e56b98da835e8f4a018502b121520ac7": "Attempts Remaining",
  "416828262df288445e72ca1d3661f993": "Warning - After 3 consecutive unsuccessful attempts, your account will be locked",
  "9f978addf10226b03278e4932348ba98": "Only 40 characters are allowed.",
  "69f076c339373e00e9e841da965f4208": "Update Aadhaar",
  "48a82e0b2bd5818f110e55bb6f14aedc": "The following information provided by you did not match the government record",
  "5c37ad5c0dd6e318af899b3c8fcbe886": "Sorry, we’re unable to fetch your data from the source system. You may proceed without verification or try later.",
  "cdb2033fc9a30d6d6c1abe401cd1e37b": "Try Later",
  "f8d855b26faffa0f8779fb44632d6bc1": "Invalid Captcha Code",
  "d53b4d564b9054db9b4b4a50e713197f": "Please enter the Captcha here",
  "ed001fb3ff7eaca9bbfbaacddc9d5a72": "No History Found",
  "4934d9bc9ebd024d4106d5e1f093ae79": "Visitors Count",
  "f7ce7cb4b450acfe0b4cef36c3bdec2d": "Your Bank Account is currently inactive. You can proceed further but get your bank account activated at the earliest to receive benefit, if your application gets approved.",
  "df9e5c88861b08c33b2d46de8d7037e9": "Your Bank Account is currently cancelled. You can proceed further but get your bank account activated at the earliest to receive benefit, if your application gets approved.",
  "3fc95e40b475f7269e64f25a1c4aef3b": "Your Bank Account is not linked with your Aadhaar. You can proceed further but get your bank account linked with your Aadhaar at the earliest to receive benefit, if your application gets approved.",
  "1c35d003a65da48bf9f6d6fc69271d5a": "Sorry, your application could not be submitted due to a system error. Please try again later.",
  "8e437cbd840bce5c5edc0ef8164de6b7": "If you update or change your details your data will be unverified and you will be asked to upload document proof for the updated data. Click on “Proceed” to continue.",
  "b010e3be9f81072a9808ed4a5dd0e45b": "The following information does not match with the details provided by you in the survey:",
  "01acb0344944f11d68aeecc9f5b574ea": "Do you want to proceed with response(s)?",
  "4ff70f7b2e5a74415de360150a6e59d3": "Your",
  "bd7aaae9a27a2a4d71214c002bcf50ce": "did not match with record.",
  "ebfd339b0ac14aa79b325b53f06bd375": "The information provided by you did not match with your Aadhaar details. You cannot proceed further.",
  "1e410e1b9903e7a99b93857f536b7e1d": "Not Verified",

  "28b266f2bf0a4ecc2dec3b6ca24fcb9b": "Upload ",
  "68982db8041ee52dd9e5a6e527c8a1fa": "Re-Crop",
  "e7bf7d83a37666fd8749e433189b1c27": "Please Wait",
  "c389f9e7d5ea93af9deb2e8f1f9c6d89": 'Click on {{"0x0001F6C8"}} to know more.',
  "f17816bcbe79e18bee49388fe84d2876": 'OTP Expired.',
  "1531d9dc798857eb85345d349bffc99b": 'Back',
  "7939de8a9958e01fb1f172e21c5a7a85": "Please provide the correct information, it will help us to show you schemes you're eligible for.",
  "bf58c4a9eac8e0ec82c158c3adfcd609": 'Schemes have been categorised under different categories. You can check the schemes as per your preferred category',
  "09349e778786f7ed758021ddbed12ca6": 'This information will help us to find the eligible schemes under the {Intent} Category. ',
  "0d45c019e7e9237a316f0f16824015d2": 'Your profile data is stored under different tabs. You can edit any of your profile information.',
  "3f52884ec08606e6e73426eaec8de195": 'This information will help us to provide you details about the exact benefit that you might receive under this scheme and the application process to avail the benefit.',
  "c389f9e7d5ea93af9deb2e8f1f9c6d89": 'Click on {{"0x0001F6C8"}} to know more.',
  "5cc2bdab3db5aa21633656a0d55f7477": "You cannot proceed further with the Application",
  "d5de543c0e83073f05a846d62cc83c81": "Document already uploaded for the same field.",
  "fca8da28cbcbe094dc646872e7cdbc7b": "Enter valid",
  "6fa2260c737f40fa1a3f63274c8fca48": "You cannot proceed further. You can either correct your details in the profile or in your Aadhaar Card",
  "3272e327ba9c82197c5c982487224925": "Sorry, we’re unable to fetch your data from the source system. Please try again later.",
  "586637318049e6dbb03e991bc45cba06": "To delete your account you must first delete all profiles associated with your account.",
  "ce6d6092569f3f247659ceb51d57be95": "You cannot delete the {{profileName}} profile as you have an ongoing application in your Applied or Enrolled Schemes",
  "8d258e398e2dbc8099c984258fadce2a": 'An OTP has been sent to your registered mobile number (xxx xxx {{phoneNumber}})',
  "abb2e3d13ded4cea9275beafacd9f4c8": `To raise your interest in this scheme, click on "Generate your Interest" and Government will reach to you to give this scheme's benefit`,
  "493de8e0a6b5d4a10af30cf3105b9e1f": "Generate your Interest",
  "c9b0246a8f3989724ec4958f91e380a7": "Scheme Details",
  "804ca01b219522a88d26c3002632a00e": "Thank You for sharing the details.",
  "04b6d9b7eb73749476d958b2133f4bbe": "Scheme Awarness Survey",
  "a5661f68fc78a5d4f15afa057fec7a9f": "To proceed further you need to update your details in the survey.",
  "1e4531d1dc128abbc00b48e5c7b6ef48": "Update Survey",
  "362e532b6ad1fda489c5909d437f02a3": "We are fetching your details, Please wait ..",
  "8a1b3c7d62e2d60d5be34d8e36a9768f": "Back to Individual Login",
  "6737d50489b1cf1d4408e66b8591ca38": "You have pending actions on your scheme application. Complete the pending actions to proceed further with your application.",
  "e37c99137b7396277137c1f73f8a86d5": "Intiative of CEG",
  "706c7a936b977ec3c4e6603f888e3fce": "Powered by EasyGov",
  "598d42810b342196884f255912974d69": "Sl. No.",
  "60e4e81c6f9c1938d77e8ded6d64643a": "Screen Reader",
  "15bbb9d0bbf25e8d2978de1168c749dc": "Website",
  "21d587949a2a2e89c958b622e244b405": "Free/ Commercial",
  "d5c03f7b855d442a2a7f24024cf89960": "Non Visual Desktop Access (NVDA)",
  "f956e16f65a3a937e5ecfe4195d17d0d": "(External website that opens in a new window)",
  "b24ce0cd392a5b0b8dedc66c25213594": "Free",
  "c8ed8ec3dc8b21290b5d28ca56344553": "JAWS",
  "b2788e1ab6be65f1c38bf7d23cd081f1": "Commercial",
  "ec18aa8148f59475130ff217d693b465": "Window-Eyes",
  "d96a273ceb2b5f83d710081d9c38440a": "System Access To Go",
  "c932fd98147348de9974252a0efcc7cb": "WebAnywhere",

  "540431e2b3234a8698b4044f15ef1fff": "PDF content",
  "801ab24683a4a8c433c6eb40c48bcd9d": "Download",
  "83f8a38f024082405d2fff921c2bb1d2": "Adobe Acrobat Reader",
  "9d8c84ff52cfc2c2e0075e669edacffd": "Word files",
  "4db1a171aaf96723d4002cc41559ddf7": "External website that opens in a new window",
  "86c61b344697039b8a54bbef9e4959c3": "Excel Viewer 2003 (in any version till 2003)",
  "04efb134468330da1430e32701160107": "Microsoft Office Compatibility Pack for Excel (for 2007 version)",
  "a385a9bb61bb694694eef5d2f54c563a": "PowerPoint presentations",
  "da67d56a74c01537a71a3ab0eda00faa": "Microsoft Office Compatibility Pack for PowerPoint (for 2007 version)",
  "bb625547a85b98c2c9f9f4121c72cd78": "Flash content",
  "ab69edd2798068928605717c6591462f": "Adobe Flash Player",
  "2c4fa0a307152ef814bbab8d4075d938": "Audio Files",
  "da7be99fd6fac5c8a4065eb2c07cad88": "Windows Media Player",
  "5a5652ca26ee42fe73fdd79497cf9e21": "Word Viewer (in any version till 2003)",
  "2e856241d3d995fefdb1b4b32f84a79b": "Microsoft Office Compatibility Pack for Word (for 2007 version)",
  "64a443242750513eb56695e07aedcff2": "Excel files",
  "382b0f5185773fa0f67a8ed8056c7759": "N/A",
  "462c18d5b89050fb1b7f8fca1e535af8": "Processed",
  "ccb26ac32c3cc7cc2f0fd1bbac3b8e2d": "You do not have any services in processed",
  "fc44a0efc672761745c2e76aac6851d2": "Your Application is rejected because",
  "7d0db380a5b95a8ba1da0bca241abda1": "at",
  "1252044838fd74c4e8b506ef1302a8b5": "grievance",
  "06627e6919d21853d887d00334ee5337": "Grievance Management",
  "f940fbe4aec76e9784e7f15c4b92ac6d": "You do not have any grievance in processed",
  "2290dc1500a2b4548eb3b931f2e3609a": "You do not have any saved grievance.",
  "b25b60dc1a5f7c17e09bc73174668d9a": "You do not have any grievance in draft.",
  "a02c58d421087ec9519620a29d68a6ec": "You have not submitted for any grievance yet.",
  "b65f636dcbc018b1901e23a7c2adde90": "Explore More",
  "5c835c22429f25992b4371691ecde1a4": "Submitted",
  "2535f5e4e0892c73a5fad023bf3e15e2": "My Grievances",
  "4351cfebe4b61d8aa5efa1d020710005": "View",
  "e9516387730903ffd7cfe3317e05e385": 'This Grievance has been saved in your "My Grievance". ',
  "b70db24f2cb70fb266eb03da8f289ba5": "View Grievance",
  "646558064e10a12b1e8914e86eca3ca6": "You have pending actions on your service application. Complete the pending actions to proceed further with your application.",
  "d2841171ea05a48fa5463b3f7c7c92d7": "You have pending actions on your grievance application. Complete the pending actions to proceed further with your application.",
  "9279f823b01f13b7a8c04688289543cc": `Your application successfully saved in your 'Draft Grievance'.`,
  "af481c93dcae73d73afe9927076c8a09": "You have already applied for this Grievances. Please check your application status for the same from your Applied Grievances.",
  "ce972231a0faf31791c7ac0bf0d1da84": "Your amount will not be refunded if you have paid any.",
  "2ffef02ca513f8bbadbaf130a23097de": 'Your application has been successfully saved in your "Draft Grievances"',
  "e55e425dbaf6b6449940eac8ea749092": "Please enter only alphabets.",
  "64ff9e3809686d4ea3253a631cae4761": "Please enter a value correct to 2 decimal places.",
  "7ed0ef23d527c2876015161e422f49ce": "Please enter your name in Kannada language.",
  "60ae728544ab5cb25dba9ff4b8f6aa95": "Your Application Reference Number for Seva Sindhu is",
  "2c0ed1f2f9239dbca46beca549190e00": "Aadhaar will be taken as proof of address, please select the district as in Aadhaar.",
  "8355a53d777c5ac84a37970b5158aba5": "Error 205: Your name in Aadhaar does not match with the details in caste or income certificate issued by Revenue department.",
  "601f77894e71ae7d4922eda713dda66c": "Error 207: Your caste and income certificate has expired.",
  "9182b3e8dc7965f2775a204095f4c992": "The OTP entered by you is incorrect. Please try again with the correct OTP.",
  "6e0fe1003e84e34da97a6c4c9040b31b": "The last date of accepting application for Arivu Educational loan scheme (Renewal) of Backward Classes Welfare Department has been extended to 31 March 2022.",
  "69d3a9de2fc1407f59e8936ca0c525a0": "Sorry, an error has occurred. Please try again later.",
  "7953c16d691da03c899391a39412fc9b": "You have pending actions on your scheme application. Complete the pending actions from <i>{{startDate}}</i> onwards to proceed further with your application.",
  "f4f4366332ac6949882de59c81b08de9": "You have pending actions on your scheme application. Complete the pending actions from <i>{{startDate}}</i> onwards till <i>{{endDate}}</i> to proceed further with your application.",
  "77b43c24220ee608380031b72f8017ce": "Service Categories",
  "e0e54c374f8b39a1520766e5924af100": "A Platform to know schemes,services and livelihood for you and your family",
  "d56f6b073f573237a6c4aed91589e982": "Livelihood",
  "e5930fadbc620e368c92561f381ead58": "Livelihood Opportunities for you",
  "192adf39ebd850e3e2e30971e885dc6d": "There are no livelihood opportunities configured in your state at the moment, we are continuously updating, please check with us again",
  "TM_Alt51": "Attachment(s)",
  "TM_Hea1": "Total Amount",
  "TM_Hea2": "Summary",
  "TM_Sub56": "Total",
  "TM_But42": "Pay",
  "TM_Err34": "Your application could not be submitted due to payment failure.",
  "TM_Err39": "Your application could not be submitted due to payment rejected.",
  "TM_Rec55": "It is taking time to confirm the status of your payment.We are continuously checking with your bank. Please avoid making any additional payments for the next 48 hours.",
  "TM_But13": "Go Back",
  "TM_Alt124":"Please wait",
  "TM_Mod41":"Payment Pending",
  "TM_Mod40":"Payment Successful",
  "TM_Alt72":"Payment Failed",
  "TM_Alt166":"Payment Rejected",
  "TM_Pag48":"Help and Support",
  "TM_Con33":"Thank you, your application has been submitted successfully.",
  "TM_Rec59": "Your last payment for this service is in pending status. We'll notify you when it's complete. In the meantime, feel free to explore our other services.",
  "TM_Rec60":"Thank you for your patience.",
  "TM_Rec61":"The page will automatically timeout in",
  "TM_Alt165":"Scan the QR code in the UPI app of your mobile device to make the payment ",
  "TM_Pag39": "Labharthi",
  "cc4cbfe72c596683881033f36eac3e2f":"Please wait while we confirm your payment with your bank. This process may take a moment",
  "7d54c690a3a707e692cceb6978e24bc1": "Fee Breakup",
};

export default language;
