const language = {
    "9e66e6bd4069124578736528a0432752": `আমাদের সম্পর্কে`,
    "0f5590b58efe9cfcce35e55a66280e59": `গ্রহণ করুন এবং চালিয়ে যান`,
    "77ab8893d0d282a9f2781d5711883903": `যোগ করুন`,
    "c40eba17959a63e52412cac8c38d467e": `প্রোফাইল যুক্ত করুন`,
    "ac714b256ab565c91ade9e03e8c410f0": `আপনার সমস্ত ডেটা মুছে ফেলা হয়েছে`,
    "b31b443baac1c6da4cb78dfaf2e5be44": `অ্যাপ্লিকেশন লিঙ্ক`,
    "0ec157e0e15e12c56bfe1c72e0e7515a": `পরিবর্তনগুলি প্রয়োগ`,
    "181c1a0ee6db7d7f835eed29791749ab": `আপনি কি নিশ্চিত যে আপনি এই স্কিমটি মুছতে চান?`,
    "d937652cde254e2a9b93b74a4ee2a02b": `আপনি কি নিশ্চিত যে আপনি স্থায়ীভাবে {{name}} প্রোফাইল মুছতে চান?`,
    "3ac1df709254c0e491c642aae5400814": `আপনি কি নিশ্চিত যে আপনি স্থায়ীভাবে {{name}} অ্যাকাউন্ট মুছতে চান?`,
    "1cbb32715ee74ee3447f48febcc04864": `আপনি কি নিশ্চিত যে আপনি আপনার সংরক্ষিত পরিষেবাগুলি থেকে এই পরিষেবাটি মুছতে চান?`,
    "ea4788705e6873b424c65e91c2846b19": `বাতিল`,
    "f4ec5f57bd4d31b803312d873be40da9": `পরিবর্তন`,
    "6dd495f1c2ae29c8e9a6c4405f18e22e": `অবস্থান পরিবর্তন করুন`,
    "b1ad1d3aef325b23950a43cb82fd0670": `ভাষা নির্বাচন করুন`,
    "714341d9339756588d1dc724991c2b9a": `এখানে ক্লিক করুন`,
    "f72b489f19f748cf78a86c78d547ed91": `আপনার অবস্থান নিশ্চিত করুন`,
    "a0bfb8e59e6c13fc8d990781f77694fe": `চালিয়ে যান`,
    "bee04ef1315b3f9562be34e2e28a7831": `হিসাব মুছে ফেলা`,
    "3e9983cf1885a5ec9f5a5d8127137bd2": `প্রোফাইল মুছুন`,
    "2ac7a924af94bf173f38eb31165efce3": `স্কিম মুছুন`,
    "e0bb2f66e5b61be3235bf8969979de19": `জেলা`,
    "46a43a7757337712d4f6a3701a5c57dd": `ফর্ম ডাউনলোড করুন`,
    "4d7be8ac1b9cb2fd5843dbea7ce30344": `আপনি এখনও কোনও পরিষেবা সংরক্ষণ করেন নি`,
    "edda2208040fe25b06d0d8f3af976651": `আপনি কোনও কাজ বাঁচান নি`,
    "f283a3e62b2809c07cf77075103c012f": `আপনার জন্য সেরা উপযুক্ত সরকারী কল্যাণমূলক প্রকল্প, পরিষেবা এবং চাকরি সন্ধানের জন্য আমাদের কিছু প্রাথমিক প্রোফাইল তথ্য প্রয়োজন।`,
    // "f283a3e62b2809c07cf77075103c012f":`সেরা উপযুক্ত সরকারী কল্যাণমূলক প্রকল্পগুলি সন্ধানের জন্য ল্যাবহার্তির কিছু প্রাথমিক প্রোফাইল তথ্য প্রয়োজন`,
    "ce8ae9da5b7cd6c3df2929543a9af92d": `ইমেল`,
    "01e8b0c4e059b594202e976860b2d481": `আপনার নিজের ভাষায় স্কিম এবং পরিষেবাগুলি অন্বেষণ করুন`,
    "483a5da192f46e3cfe8391d3ef234fc4": `সচরাচর জিজ্ঞাস্য`,
    "37aaf54433bbfe989db8871121fe0ad8": `আমাদের অনুসরণ করো:`,
    "e0c4332e8c13be976552a059f106354f": `এবার শুরু করা যাক`,
    "48dee7c291ac7f19e499438b072da336": `সরকারী ফি`,
    "2ceb32552d78024d91a123897636afcb": `EasyGov এর নাগরিক ক্ষমতায়ন অ্যাপ`,
    //"357aa540fd5c8ae584ba18359105de3d":`কোনও স্কিম বা অন্য কিছু সম্পর্কে আপনার কোনও প্রশ্ন আছে?`,
    "357aa540fd5c8ae584ba18359105de3d": `আপনার যদি কোনও ক্যোয়ারী থাকে তবে আমাদের জানান এবং আমরা 24 ব্যবসায়িক সময়ের মধ্যে আপনার কাছে ফিরে আসব।`,
    "b3ba00d60ff5a9e11c15a6efbed13746": `আপনার বর্তমান প্রোফাইল আপডেট করতে "বর্তমান প্রোফাইল আপডেট করুন" এ ক্লিক করুন এবং একটি নতুন প্রোফাইল যুক্ত করতে "প্রোফাইল যুক্ত করুন" নির্বাচন করুন`,
    "12ceff2290bb9039beaa8f36d5dec226": `চাকরি`,
    "5eb57a6678ca337f92884f49b578a908": `...`,
    "4994a8ffeba4ac3140beb89e8d41f174": `ভাষা`,
    "846eb834b6bfe81dbd412bc010f8cac7": `আমাদের জানান এবং আমরা 24 ব্যবসায়িক সময়ের মধ্যে আপনার কাছে ফিরে আসব`,
    "a58252e21aaf876bf7b64c05cce1c477": `লিঙ্ক অনুলিপি`,
    "ce5bf551379459c1c61d2a204061c455": `অবস্থান`,
    "ccffe10fe854018805baf0d3d9327285": `প্রোফাইল পরিচালনা করুন`,
    "49ee3087348e8d44e1feda1917443987": `নাম`,
    "10ac3d04253ef7e1ddc73e6091c0cd55": `পরবর্তী`,
    "bafd7322c6e97d25b6299b5d6fe8920b": `না`,
    "31916d996c9f53949315692bc897f0c7": `প্রাসঙ্গিক না? সন্ধান চালিয়ে যান`,
    "e81c4e4f2b7b93b481e13a8553c2ae1b": `বা`,
    "0d5e50bf1643118ee026e5d19226542a": `দয়া করে বানানটি পরীক্ষা করুন বা অন্য কোনও কিছুর জন্য অনুসন্ধান করার চেষ্টা করুন`,
    "64ed408b000d73143e6ab1b5acb7c26e": `প্রোফাইল নির্বাচন করুন`,
    "fa2ead697d9998cbc65c81384e6533d5": `গোপনীয়তা নীতি`,
    "7595f48afc2f088bc1ff3313ceeb3605": `আবেদন করার পদ্ধতি`,
    "cce99c598cfdb9773ab041d54c3d973a": `প্রোফাইল`,
    "665d0095958d696322be90e4eb606977": `এই সম্পর্কের সাথে প্রোফাইল ইতিমধ্যে বিদ্যমান। আপনি বর্তমান প্রোফাইল আপডেট করতে পারেন`,
    "27bcc51421612805a9c006f24a4d6dc6": `স্কিম সরান`,
    "f8b66e3c488bb1df16cb3c7bb21447f0": `প্রয়োজনীয় কাগজপত্র`,
    "82609dd1953ccbfbb4e0d20623193b56": `বাড়িতে ফিরে`,
    "c9cc8cce247e49bae79f15173ce97354": `সংরক্ষণ`,
    "c320cdeffafbb2d60d6d759619c6b5e2": `প্রোফাইল সংরক্ষণ`,
    "ab9753c948520fb02c20831980bad53a": `আমার স্কিম`,
    "827452688eed02a12178e96f924ac529": `পরিকল্পনা`,
    "cb80cf588ba5a0d3dc067b0b0ef219e9": `স্কিমস`,
    "1925ba54f16355af0964df7dfa145761": `আপনার জন্য পরিকল্পনা`,
    "13348442cc6a27032d2b4aa28b75a5d3": `অনুসন্ধান করুন`,
    "32df01b9cf0491a879250b58ba2744ba": `অনুসন্ধান ফলাফল`,
    // "988cddb5e417156bd3603983dc0c32e5":`অনুসন্ধান স্কিম`,
    "0afcf4c1ba72c441b41fbafaab253fc6": `স্থান নির্বাচন করুন`,
    "be2933038f93e25262400220080c111f": `রাজ্য নির্বাচন কর`,
    "dfe1d316ec9338e23c892b573667b9bb": `আপনার বিকল্প নির্বাচন করুন`,
    "c2ba7e785c49050f48da9aacc45c2b85": `পরিষেবা`,
    "992a0f0542384f1ee5ef51b7cf4ae6c4": `সেবা`,
    "48ecd536efbb3622296aac8de326fa38": `দুঃখিত, প্রদত্ত তথ্যের ভিত্তিতে, আপনি এই প্রকল্পের জন্য যোগ্য নন`,
    "4cd9a992dc66802a4b44c366881957d1": `দুঃখিত, কোনও ফলাফল পাওয়া যায় নি`,
    "f86301756ec6964cb96e2b80a38f4162": `দুঃখিত! আমরা আপনার জন্য কোনও মিলে যাওয়া স্কিম খুঁজে পাইনি`,
    "46a2a41cc6e552044816a2d04634545d": `রাষ্ট্র`,
    "efd9a0ba1e9d553a070028627fdcae18": `জরিপের বিশদ`,
    "4cd61af85d0ec40f50349cd7c3054ace": `প্রোফাইল স্যুইচ করুন`,
    "1d8cd2bfc6943dcf70236f7d351572a0": `শর্তাবলী`,
    "4f7e556abaf92ce8061ad57cc4e7edd1": `এটি প্রয়োজন`,
    "371999537b8bb6e7f0099b0c40e0e41d": `এই প্রোফাইলটি ইতিমধ্যে তৈরি করা হয়েছে। আপনি প্রোফাইল আপডেট করতে পারবেন না।`,
    "9eed21a5afd3ba6841c523b0d70599c7": `স্কিমগুলি দেখতে 2 টিরও কম সহ যে কোনও বিভাগে ক্লিক করুন বা নির্দিষ্ট বিভাগের জন্য একটি সমীক্ষা চালাতে 2 টিরও বেশি বিভাগে ক্লিক করুন`,
    "37528894f18a085f9a078496a68eaaa8": `চলমান`,
    "d876a9fe1aecb3ffc05a5639ebe22000": `আবার চেষ্টা করুন`,
    "06933067aafd48425d67bcb01bba5cb6": `হালনাগাদ`,
    "fba7f6c863513d97922faab074d3c716": `প্রফাইল হালনাগাদ`,
    "90b1f6651953fcf8b43ddc0da6760aaf": `ব্যবহারকারীর প্রোফাইল`,
    "d8d7ad6abfd08c27baa8bedb78c72ddb": `সব দেখ`,
    "acac138f2e6f039df2e1fed2f2c0bc44": `EasyGov এ স্বাগতম`,
    "6ebb2e31989c843ea65feefa43237dad": `আপনি যখন আপনার অ্যাকাউন্ট থেকে কোনও প্রোফাইল মুছবেন, আপনি সেই নির্দিষ্ট প্রোফাইলের জন্য সঞ্চিত কোনও ডেটা বা তথ্য পুনরুদ্ধার করতে সক্ষম হবেন না। নির্বাচিত প্রোফাইলের জন্য সমস্ত প্র্যাকটিভ এবং সংরক্ষিত স্কিমগুলিও মুছে ফেলা হবে`,
    "6a64fbe7b065fc8579f2521e0054de02": `আপনি যখন আপনার ল্যাবহার্থি অ্যাকাউন্টটি মুছবেন, আপনি আপনার প্রোফাইল এবং আপনার অ্যাকাউন্টের সাথে সম্পর্কিত অন্যান্য প্রোফাইলগুলিতে সঞ্চিত কোনও ডেটা বা তথ্য পুনরুদ্ধার করতে সক্ষম হবেন না। আপনার সমস্ত প্র্যাকটিভ এবং সংরক্ষিত স্কিমগুলিও মুছে ফেলা হবে`,
    "93cba07454f06a4a960172bbd6e2a435": `হ্যাঁ`,
    "3aeee34bea95f2b0ced72be632778670": `আপনি কোনও স্কিম সংরক্ষণ করেন নি`,
    "cb32c835ee043e0e2faf4860e46669ae": `এই স্কিম/পরিষেবা পর্যালোচনাধীন রয়েছে। কিছুক্ষণ পরে আবার চেষ্টা করুন।`,
    "c2e0ede88489afe5739c8973aa03a787": `আপনার প্রোফাইলের সাথে মেলে এমন কোনও স্কিম নেই`,
    "28d2dfa9b435bd81cb2f7f0499b846b3": `স্কিমগুলি সন্ধান করুন`,
    "52561ab2d4640b4e299e817b6ba79173": `ল্যাবহার্তিতে স্বাগতম`,
    "fb0fc4b5fdb2bef7b1c42f2cfd523c2d": `চেষ্টা বাকি`,
    "e13e1cd5f02de37413f9497cc5b65de3": `আপনার অ্যাকাউন্টটি 30 মিনিটের জন্য লক করা হয়েছে। দয়া করে সেই সময়ের পরে চেষ্টা করুন`,
    "f2a6c498fb90eebdu72k7f888fce3b18": `স্বাগত`,
    "1172b2ea87bed4861c8c43a201d53031": `আমার পরিষেবা`,
    "bcc254b55c4a1babdf1dcb82c207506b": `ফোন`,
    "8458bad978f4fd6d6c7f8ec2ec0824b4": `পাসওয়ার্ড ভুলে গেছেন?`,
    "d67850bd126f070221dcfd5fa6317043": `নিবন্ধন করুন`,
    "619d59e2ea9f9bb9fe287404dd3ca931": `সমস্ত পরিষেবা`,
    "6b46ae48421828d9973deec5fa9aa0c3": `সাজান`,
    "104f0a6879399d6285ab770ed0dbc257": `পরিষেবা সরান`,
    "0b3937a9dd481d527fcc72adaa735b57": `ল্যাবহার্তি অ্যাকাউন্ট নেই?`,
    "2f453cfe638e57e27bb0c9512436111e": `চালিয়ে যান`,
    "f2fdea440d768b85591e936a803c3631": `সাইন ইন করুন`,
    "82b1fbd43ae27918a37b212a4f60a9ad": `জমা দিন`,
    "31693e2e9aa2e5be1bf3ae45ede9c98d": `স্কিমগুলির জন্য আপনি যোগ্য নন`,
    "d43cd6a49673f0f486ed7fbea1a5fd8a": `আধার নম্বর`,
    "3a06727e678189b8502408cb9b50b437": `প্রোফাইল পরিবর্তন করুন`,
    "0323de4f66a1700e2173e9bcdce02715": `প্রস্থান`,
    "644cd55dbe4d01e7e00fc7fc458f9b8f": `আপনি লগ আউট করতে চান?`,
    "c0fb5959a7a8d3ea991f9edb0b1a9e85": `দয়া করে আইডি নির্বাচন করুন, আমরা আপনার জন্য স্কিমগুলি পাব`,
    "827a37fb93758383027c582a39ec6162": `আইডি প্রকার`,
    "cb3441fa3e1291a76dd5ea72e656bca3": `কোনও আইডি ছাড়াই চালিয়ে যান`,
    "99dea78007133396a7b8ed70578ac6ae": `প্রবেশ করুন`,
    "02aeaf2672eb06861c144004ef2248d2": `লগইন অ্যাকাউন্ট`,
    "d4d208eb2d8ccd525a64be73f3521dc5": `অবৈধ প্রশংসাপত্র`,
    "e8055d9577ec8edf34ee15a88a937daa": `অ্যাকাউন্ট নেই?`,
    "942a3ef743c4e176f75d5223455f7e1a": `আপনার 10 ডিজিটের ফোন নম্বর লিখুন`,
    "21bdc5689c12595ae14298354d5550d5": `ইতিমধ্যে একটি সদস্যপদ আছে?`,
    "dc647eb65e6711e155375218212b3964": `পাসওয়ার্ড`,
    "0b3e8b8021c9b2fafee35ae01ac2cfb9": `${window.projectName} অ্যাপ্লিকেশন আপনাকে এবং আপনার পরিবারের জন্য সেরা উপযুক্ত সরকারী কল্যাণমূলক প্রকল্পগুলি সন্ধান করতে আপনাকে সহায়তা করবে।`,
    "05aca668fa6754247f5094c042fb64ac": `ফোন যাচাইকরণ`,
    "f9bc92a6602b9692a0ba578120c9c441": `আপনার OTP কোডটি এখানে লিখুন`,
    "d9d1e60e50119d9752001d4196ee6b3c": `OTP`,
    "6e95a24fa2a98c2f59c96a24b182ccfd": `আপনি কোন কোড পান নি?`,
    "f922096c878b694ec7a3d9282a55904c": `নতুন কোড পুনরায় পাঠান`,
    "32b830cef750168335d96aff4dd98782": `আমার কাজ`,
    "bbd26bc8ce3623addc9c54c36f692f8d": `আপনার জন্য কাজ`,
    "67cb88ed21d4232c4681a5e648554e09": `আবেদন ফী`,
    "b90455b71eb5e2399be9d20e23c1a1db": `আবেদন করার শেষ তারিখ`,
    "bfe72f18052c940de13501ae9877c3b0": `কিভাবে আবেদন করতে হবে`,
    "4f1f44ef7ce0c8a111f33fe400a6e4d7": `নির্বাচন পদ্ধতি`,
    "0f5679acafa674831691cfa01d17501e": `কাজের বিজ্ঞপ্তি`,
    "a45ca50bcc7830af2f84fd046648ec92": `আপনার প্রোফাইলের সাথে মিলে কোনও কাজ নেই`,
    "c4bcece293eb1ad7f09d4cf9c2037ea6": `চাকরি খোজ`,
    "5dc52ca9ffdc26147075682c92a0a853": `পোস্ট`,
    "3f99ba6fbcb46f28fb24f271fc3c02c1": `চাকরি দেখতে যে কোনও বিভাগে ক্লিক করুন। যে কোনও কাজের জন্য জরিপ চালাতে কার্ডে ক্লিক করুন`,
    "2078989a6c09fdd98efc35d3c1d1de3d": `কাজ মুছুন`,
    "08c1517355f6e7313c4d629a193ac69a": `আপনি কি নিশ্চিত যে আপনি আপনার সংরক্ষিত কাজগুলি থেকে এই কাজটি মুছতে চান?`,
    "e614dff8cba6f73ae7cee729fe0091dd": `অবৈধ OTP`,
    "c46c1e69c89811f03dc73feb09273409": `OTP প্রয়োজন`,
    "ae3bb2a1ac61750150b606298091d38a": `নতুন পাসওয়ার্ড`,
    "887f7db126221fe60d18c895d41dc8f6": `পাসওয়ার্ড নিশ্চিত করুন`,
    "70d9be9b139893aa6c69b5e77e614311": `নিশ্চিত করুন`,
    "20aedd1e6de4dcf8d115b5a7424c58d7": `পাসওয়ার্ড প্রয়োজন।`,
    "841a389ec69697c28df7b9739419d21d": `পাসওয়ার্ড 6 টি অক্ষর দীর্ঘ হওয়া উচিত।`,
    "bcb9407d90fd17ccff50587f5edfe987": `পাসওয়ার্ড মেলে না।`,
    "318721657b639c8ca02293211db68837": `ফোন নম্বর প্রয়োজন`,
    "4ea3456b0e1b463f50b81c79230cf070": `অবৈধ ফোন নম্বর`,
    "bd454fc3d32f4425d146d3e861409871": `ব্যবহারকারী আগে থেকেই আছে`,
    "ab48b978d197e315e92e904e36d26175": `ফোন নম্বরটি অবৈধ`,
    "70729403b9560d3adb142977608b81d2": `OTP সীমা ছাড়িয়ে গেছে। 4 ঘন্টা পরে চেষ্টা করুন।`,
    "1e4dbc7eaa78468a3bc1448a3d68d906": `ফোন নম্বর`,
    "4f2df4c330b8df125a3d75f2d69a9b5e": `OTP প্রেরণ করুন`,
    "9ec54ec336b65da6179cf750de33ecd7": `পাসওয়ার্ড ভুলে গেছেন`,
    "98adc700c19f071f07a714a703a6fc1e": `আইডি বিশদ`,
    "f262fae0a2f17e8c1eb4e9eddb0160e4": `অগ্রসর`,
    "49fb619c4872c491fae649c28b339ed8": `আপনার শংসাপত্রগুলি আমাদের রেকর্ডগুলির সাথে মেলে না`,
    "58557a71f9811899313234decda72d5a": `আপনার অ্যাকাউন্টটি 30 মিনিটের জন্য লক করা হয়েছে। দয়া করে সেই সময়ের পরে চেষ্টা করুন।`,
    "e7665f483813a56e9410787ce2d174ef": `ফোন নম্বরটি ইতিমধ্যে ব্যবহৃত হচ্ছে। সাইন আপ করতে সাইন ইন করুন বা অন্য নম্বর ব্যবহার করুন।`,
    "0a46fa5fb9e86e80fc94e229d1967699": `ফোন নম্বরটি নিবন্ধিত নয়। পুনরায় চেষ্টা করুন`,
    "bdff8b1cbfb4d5623382d7b0678e707c": `পাসওয়ার্ডে অবশ্যই কমপক্ষে একটি উচ্চতর কেস, একটি লোয়ার কেস, একটি নম্বর এবং একটি বিশেষ অক্ষর সহ কমপক্ষে 8 টি অক্ষর থাকতে হবে`,
    "d55085482b3e8da780755832c17d7d60": `শেষ তারিখ`,
    "988cddb5e417156bd3603983dc0c32e5": `অনুসন্ধান স্কিম এবং পরিষেবাদি`,
    "261b39077b81875e115baa22bc6ce04c": `চাকরিগুলি একটি নির্দিষ্ট বিভাগের অন্তর্ভুক্ত, বিভাগে ক্লিক করুন`,
    "bdb53070a3fc01196ae38b059a4b42ff": `কাজ সরান`,
    "ede1fcad3d6bf808fc948df7d22e1536": `দুঃখিত! আমরা আপনার জন্য কোনও মিলে যাওয়া কাজ খুঁজে পাইনি`,
    "0c4fa7a0201f434b0ef84494d0aac07a": `দুঃখিত, প্রদত্ত তথ্যের ভিত্তিতে, আপনি কাজের জন্য যোগ্য নন`,
    "e4defbb7cdf529fa3606749a82c06932": `পোস্ট`,
    "25d2401d71f4ba1ba7685559c1b77927": `আপনার প্রোফাইলের উপর ভিত্তি করে, আমরা আপনাকে কোনও কাজের জন্য যোগ্য বলে মনে করি নি <br> <br> আমরা অবিচ্ছিন্নভাবে আরও চাকরি যুক্ত করছি, দয়া করে পরে পরীক্ষা করুন।`,
    "ea7c2400c0ae5f58608cb5051ee852f3": `এই মুহুর্তে আপনার রাজ্যে কোনও কাজ কনফিগার করা নেই, আমরা অবিচ্ছিন্নভাবে আপডেট করছি, দয়া করে আবার আমাদের সাথে চেক করুন`,
    "24fe48030f7d3097d5882535b04c3fa8": `মেয়াদোত্তীর্ণ`,
    "9dc0186e2d5d3398ffee6ec964b15dc0": `সমস্ত মেয়াদোত্তীর্ণ চাকরি শেষ হওয়ার তারিখ থেকে 30 দিনের পরে আপনার "আমার কাজ" থেকে সরানো হবে।`,
    "34a934c0c86d866df9d14f8a6e90c637": `অযোগ্য যোগ্যতার কারণ`,
    "a97ea56b0e00b2379736ae60869ff66a": `প্রশ্ন`,
    "8e9e3212461f14c8dbea6456c920bcbc": `যোগ্য মান`,
    "c4dce677291fa5bc05276ffe93c5d8f2": `আপনার দ্বারা সরবরাহ করা মান`,
    "9a917c4a4f546c6072bd61ae617763bf": `স্কিম আপনার জন্য নয়`,
    "e57c13893a290ce882569b5638ba7b82": `আপনার দ্বারা প্রবেশ করা আইডিটি পাওয়া যায় নি। অনুগ্রহপূর্বক আবার চেষ্টা করুন.`,
    "e26859a59ede4ab8d8ea0213b68fa732": `উত্স সিস্টেমটি বর্তমানে সাড়া দিচ্ছে না, দয়া করে কিছুক্ষণ পরে চেষ্টা করুন।`,
    // "e26859a59ede4ab8d8ea0213b68fa732":`উত্স সিস্টেমটি বর্তমানে সাড়া দিচ্ছে না, দয়া করে আইডি ছাড়াই এগিয়ে যান বা কিছুক্ষণ পরে চেষ্টা করুন।`,
    "d2235c7ecbbbae1bf62833dade7486f5": `সোনার রেকর্ড থেকে প্রোফাইল বিশদ নেওয়া হয়েছে।`,
    "346df6b73ae19a6619f26648cf415522": `অযোগ্য স্কিমগুলির তালিকা প্রোফাইল ডেটার উপর ভিত্তি করে।`,
    "6e0fe1553e84e34da97a6c4c9040b31b": `ল্যাবহার্থি`,
    "27559de2125101ccb786807bceb46aa1": `ভারত`,
    "936b6a0effe4990e8f5420a0099fa537": `EasyGov`,
    "8cf04a9734132302f96da8e113e80ce5": `বাড়ি`,
    "923b39c56654bb6264b6638ba8386b3c": `আপনার প্রোফাইল তৈরি করতে সাইন ইন/সাইন আপ করুন`,
    "52f2ba99491a1bae953303a4eaf50196": `OTP দিয়ে লগইন করুন`,
    "18e0c8084d60f55016d67d4e2ca507ce": `SSO দিয়ে লগইন করুন`,
    "d3d77957f18f1e208cb5b0caa07bec5c": `আরও স্কিমগুলি সন্ধান করুন`,
    "6a26f548831e6a8c26bfbbd9f6ec61e0": `সাহায্য`,
    "b2119dd0c37bb396e24199d5ffbd0c72": `স্ক্রিন রিডার অ্যাক্সেস`,
    "34b6cd75171affba6957e308dcbd92be": `সংস্করণ`,
    "4221d3e17c6eca2ca6337251a3cf9c4e": `সর্বশেষ সংষ্করণ`,
    "ece2c7eaaac9f27b055e6e482ab776b9": `তথ্য খোঁজা`,
    "ad8f678ccf4a4a34b46f01c74f25b91b": `ব্যবহারকারীদের ওয়েবসাইটের মধ্যে তথ্য অ্যাক্সেস করতে সহায়তা করতে, একটি "এখানে অনুসন্ধান এখানে" বিকল্প সরবরাহ করা হয়েছে। এখানে, কন্নড় টাইপ করার বিকল্পটি উপলব্ধ করা হয়েছে। অনুসন্ধান করার সময়, ইউনিকোড ফন্টের ব্যবহার বাধ্যতামূলক। ইংরেজিতে অনুসন্ধানের বিকল্পও সরবরাহ করা হয়।`,
    "698aa21b21c2252839e5e54a0d2f12ef": `ইন্টারনেট অ্যাক্সেসযোগ্যতা`,
    "4ac2856693f5c8b4fc6cdaf669ed2d74": `যদি ইন্টারনেট ধীর বা অনুপলব্ধ থাকে তবে ওয়েবসাইট ডিজাইনে কোনও পরিবর্তন হতে পারে বা কিছু সাবপেজগুলি খুলতে পারে না।`,
    "0da22543a45316a826571412b3d5953c": `ওয়েবসাইটটি ওয়ার্ল্ড ওয়াইড ওয়েব কনসোর্টিয়াম (ডাব্লু 3 সি) ওয়েব কন্টেন্ট অ্যাক্সেসিবিলিটি গাইডলাইনস (ডাব্লুসিএজি) ২.০ স্তর এএর সাথে সম্মতি জানায়। এটি স্ক্রিন পাঠকদের মতো সহায়ক প্রযুক্তি ব্যবহার করে ভিজ্যুয়াল প্রতিবন্ধকতাযুক্ত ব্যক্তিদের ওয়েবসাইটে অ্যাক্সেস করতে সক্ষম করবে। ওয়েবসাইটের তথ্য বিভিন্ন স্ক্রিন পাঠকদের সাথে অ্যাক্সেসযোগ্য, যেমন জাডাব্লুএস, এনভিডিএ, সাফা, সুপারনোভা এবং উইন্ডো-চোখের।`,
    "cb976b928eacd65e6342cfa78bf196da": `নিম্নলিখিত টেবিল বিভিন্ন স্ক্রিন পাঠকদের সম্পর্কে তথ্য তালিকাভুক্ত করে`,
    "786f669131d80fbed4572a520c28c14d": `EasyGov দ্বারা তৈরি এবং মালিকানাধীন সামগ্রী`,
    "fc290a7672f96fd8b94a9659c8c02322": `| সমস্ত অধিকার সংরক্ষিত`,
    "6016a2b341113bf496b719905398ecd2": `কপিরাইট`,
    "0e8a85115f2c3ef0058c70f30a9b6deb": `আপনার নিবন্ধিত মোবাইল নম্বরে পাঠানো OTP লিখুন`,
    "34f8ccda5172e4eac9a8a014559b38c1": `আপনার পাসওয়ার্ড সেট করুন`,
    "548899be111ad732b37786783dfbd6d8": `কোনও ইন্টারনেট সংযোগ নেই`,
    "5c3a1593ca1d34226359df44f3d45f2f": `কিছুক্ষণ পরে আবার চেক করুন।`,
    '35043eeac5651298d4bb6c100e071745': `আপনাকে তৃতীয় পক্ষের ওয়েবসাইটে পুনঃনির্দেশিত করা হবে। আপনি কি অবিরত করতে চান?`,
    "6182e08f3974a70ac4883b5fceaa14c0": `আপনার নিবন্ধিত মোবাইল নম্বরে পাঠানো OTP লিখুন`,
    "306fb6389c3b3fd2835af15cfc7c66e9": `পরিবারের আইডি পরিবর্তন করুন`,
    "63fc7651ee1dbf6868a43a5b5fa8b581": `শুধুমাত্র অক্ষর অনুমোদিত`,
    "6903fbe8375d52217c15618170f4078a": `শুধুমাত্র 30 টি অক্ষর অনুমোদিত`,
    "9ab37820a722ce13e80a3d0aa468d6dc": `*প্রয়োজনীয়`,
    "0df1f18a2fa23cd60990e47692f99e07": `অ্যাপ্লিকেশন লিঙ্ক`,
    "a60852f204ed8028c1c58808b746d115": `ঠিক আছে`,
    "edbd5921e9c7168e04cbe6f75ee76174": `আপনার প্রোফাইলের ভিত্তিতে, আমরা আপনাকে কোনও স্কিমের জন্য যোগ্য বলে মনে করি নি। আমরা ক্রমাগত আরও স্কিম যুক্ত করছি, দয়া করে পরে চেক করুন।`,
    "a203c39a340e1d45069b1ba64e1d003a": `মনোযোগ!`,
    "037673ab5a84897e0ee43ca37f7680f6": `আপনি যদি আপনার ডেটা পরিবর্তন করেন তবে আপনার প্রোফাইল ডেটাতেও আপডেট হবে। আপনি কি অবিরত করতে চান?`,
    "d1d16cede248b826a431ea11132567cb": `আপনি যদি আপনার যাচাই করা ডেটা পরিবর্তন করেন তবে এটি পুনরায় যাচাই করা হবে এবং আপনার স্থিতি পরিবর্তন করা হবে। এটি আপনার আবেদনের প্রত্যাখ্যান বা বর্ধিত প্রক্রিয়াজাতকরণের সময় উচ্চতর সম্ভাবনা হতে পারে।`,
    "23a0ca84a7fd64eee8248bd1dfc77185": `নাম পরিবর্তন কর`,
    "3b383caaaa6113dd83b381b43a97d7ee": `এই ব্যবহারকারীর জন্য ইতিমধ্যে একটি প্রোফাইল বিদ্যমান, একটি নতুন পরিবার আইডি যুক্ত করা পূর্ববর্তী বিশদগুলি সরিয়ে ফেলবে। দয়া করে নিশ্চিত করুন`,
    "e0efbdcea0abe735dbf887292a5670fa": `ভাই`,
    "ad6e7652b1bdfb38783486c2c3d5e806": `স্ব`,
    "19cda37369e2d2015e66b9d478ffbe15": `বোন`,
    "81e053d78f01540e9cd6fb68a1c03976": `অন্য কেউ`,
    "b8e18040dc07eead8e6741733653a740": `মা`,
    "a30ca91be97a9cedc0d2426e54b0e782": `পিতা`,
    "784c01f6b735be57175e2f438297c741": `স্বামী / স্ত্রী`,
    "1968ab9fdfedf558714bf6caec9a1234": `পুত্র`,
    "7bc1cb93f51f61bde0e90b20c81ebf88": `কন্যা`,
    "25f34d5943da3f09a2aa6358193a41bd": `আবেদন করতে এগিয়ে যান`,
    "79dff508ab2e4d272f0630d001271eb1": `আরও কাজ অন্বেষণ`,
    "b6ebe1e750185946ed41be9191410612": `ল্যাবহার্তির মাধ্যমে প্রয়োগ করতে সক্ষম হতে আপনাকে বিশদ সরবরাহ করতে হবে।`,
    "af1899084d8361b9afb9187da988ff9c": `"দুঃখিত, আমরা আপনার ডেটা আনতে অক্ষম"।`,
    "1b56218ddf0a290241d661d26253d51f": `কেবল {{attempts}} প্রচেষ্টা (গুলি) বামে`,
    "f299cff2484fdb361f4fc3189490d909": `আপনার প্রদত্ত নিম্নলিখিত তথ্যগুলি আপনার সরকারী পুনর্বিবেচনার সাথে মেলে না:`,
    "1edf5362e5f4af6f370a7f34c3136a7a": `যাচাই ব্যর্থ`,
    "dcf9ac5128c95d88e068e223dfe01d27": `যাচাইয়ের স্থিতি`,
    "bc910f8bdf70f29374f496f05be0330c": `নামের প্রথম অংশ`,
    "77587239bf4c54ea493c7033e1dbf636": `নামের শেষাংশ`,
    "14fcbe1dd0f7a39f0195aba46cf89d17": `বিশদ যাচাই করুন`,
    "b6121db5ee47deeb0ca42d5dea0b2225": `প্রচেষ্টা ব্যর্থ হয়েছে`,
    "839301cec5574d7c3256afce4fb85467": `আপনি আপনার পরিবারের বিশদ যাচাই করার জন্য বৈধ সংখ্যা ছাড়িয়ে গেছেন। আপনার যাচাইকরণ অবরুদ্ধ করা হয়েছে`,
    "6733aa56fd001b9bf479650f3950688a": `আপনাকে স্কিম অ্যাপ্লিকেশন পৃষ্ঠায় পরিচালিত করা হচ্ছে`,
    "9303c9bd4f8178680dc382adbfcd62af": `ঘরটি অবশ্যই পূরণ করতে হবে.`,
    "53a04cfc3d09c9b2dfb8349791c62bc1": `আপনার নাম এই পরিবারের কোনও সদস্যের সাথে মেলে না`,
    "1ca35256fcb85c1ba9ea7ac1095462ad": `আপনি আপনার পরিবারের বিশদ যাচাই করার জন্য বৈধ সংখ্যা ছাড়িয়ে গেছেন। আপনার যাচাইকরণ 3 ঘন্টা অবরুদ্ধ করা হয়েছে`,
    "0e3a917198575c463bb4fdcb7332b03a": `অবৈধ আধার কার্ড নম্বর`,
    "630058029627ce58e7571e7a5b6ec591": `পুরো নাম`,
    "072355e896ac1a999d2fa0aa208f94ee": `এই স্কিমটি আপনার "আমার স্কিমগুলিতে" সংরক্ষণ করা হয়েছে।`,
    "789189638f8a301304dfadeb61036dd9": `এই পরিষেবাটি আপনার "আমার পরিষেবাগুলিতে" সংরক্ষণ করা হয়েছে।`,
    "ab8023b4fc2395738b8fd2df440641a9": `এই কাজটি আপনার "আমার কাজগুলিতে" সংরক্ষণ করা হয়েছে।`,
    "671dec8fc4e3dc847c1b0d7b67b4e6e1": `সম্পর্ক`,
    "f252b4e2f3b2963d330c8cde8e9cdbd9": `যদি পাওয়া যায়`,
    "ef51899b57258db72f15b4703a8a86ba": `প্যান ইন্ডিয়া সরকারী কল্যাণমূলক প্রকল্প, পরিষেবা এবং চাকরির জন্য কার্যকর তথ্য অ্যাক্সেস করার জন্য নাগরিকদের জন্য একটি একক প্ল্যাটফর্ম।`,
    //New Translations for Phase 1:`#VALUE!`,
    "7510e00267e89c78470e79b42ecd6463": `আপনার নিজের ভাষায় অ্যাপটি অন্বেষণ করুন`,
    "83218ac34c1834c26781fe4bde918ee4": `স্বাগত`,
    "1d00e7dce692e8dc3f6877f035e3a616": `বা`,
    "e0cf58fa3413df99ae83109ebb9a41a3": `OTP আবার পাঠান`,
    "6ed83b24865902c6fd90bbd15247e042": `পাসওয়ার্ড সেটআপ করুন`,
    "9157806ea93e82c6843e29353af0339f": `OTP অনুরোধ করুন`,
    "69f18e98d2dd54118ade84732dc3a5e0": `পিন যাচাই করুন`,
    "2dd4472245a696bc0b4b944db2a8b519": `স্বতন্ত্র`,
    "a274f4d4670213a9045ce258c6c56b80": `বিজ্ঞপ্তি`,
    "407456e575dee5a4b84a19a7111a4a4d": `আপনি যখন 2 টি কম সংখ্যার সাথে কোনও স্কিম বিভাগে ক্লিক করেন, আপনি যে স্কিমগুলি আপনি যোগ্য হতে পারেন তা দেখতে সক্ষম হন, যখন আপনি 2 টিরও বেশি সংখ্যার সাথে কোনও স্কিম বিভাগে ক্লিক করেন তখন বিভাগ নির্দিষ্ট জরিপটি আপনাকে আরও দেওয়ার জন্য চলবে নির্বাচিত বিভাগের জন্য স্কিমগুলির পরিশোধিত তালিকা।`,
    "9139eb8e78808acbd15244d4eb9f1bc3": `আরও স্কিমগুলি অন্বেষণ করুন`,
    "c61dd5f0e5b5389306ad5221ec2cd795": `এই স্কিমটি আপনার "আমার স্কিমগুলিতে" সংরক্ষণ করা হয়েছে`,
    "f2a6c498fb90ee345d997f888fce3b18": `মুছে ফেলা`,
    "469bad4859f3c33fcb8ce09314c3e895": `জরিপের বিশদ পর্যালোচনা`,
    "7dce122004969d56ae2e0245cb754d35": `সম্পাদনা`,
    "c5984b3fe02f7b06d77fb538d842b0fc": `সমীক্ষার বিশদ আপডেট করুন`,
    "6cf33ce1a7a11a7178131567c777b637": `একই নাম এবং সম্পর্কের সাথে প্রোফাইল ইতিমধ্যে বিদ্যমান`,
    "fffcb4d1e12c96b2f571f77ad25878b8": `কারণ`,
    "e8d89b6875d217a990d47701138e687a": `আপনি কি নিশ্চিত যে আপনি এই ডেটা মুছতে চান?`,
    "aa98db08fed41a99a00aac36f521540f": `সর্বশেষ যাচাই করা হয়েছে`,
    "5da618e8e4b89c66fe86e32cdafde142": `থেকে`,
    "253ec560db0637f6a0e53aefcbd4be0c": `আপনি যখন ল্যাবহার্থি অ্যাকাউন্ট মুছবেন, আপনি আপনার প্রোফাইলে সংরক্ষিত কোনও ডেটা বা তথ্য পুনরুদ্ধার করতে সক্ষম হবেন না। {{name}} এর জন্য সঞ্চিত সমস্ত সংরক্ষিত স্কিম এবং তথ্যও মুছে ফেলা হবে।`,
    "c0e70d5bfc28ae3aadfb8f85697c54d5": `আপনার ডেটা সফলভাবে আপডেট করা হয়েছে।`,
    "09b73aba9204a05ba5eaf0ea471fdc8c": `EasyGov হল সুরাজ্য সার্ভিস প্রাইভেট লিমিটেডের একটি নিবন্ধিত ট্রেডমার্ক। EasyGov-এর প্ল্যাটফর্ম-এ-এ-সার্ভিস মডেলটি পর্যায়ক্রমে সরকারী কল্যাণকে রক্ষণশীল, প্রতিক্রিয়াশীল এবং প্রোগ্রাম কেন্দ্রিক থেকে প্রগতিশীল, সক্রিয় এবং পরিবারকেন্দ্রিক হতে রূপান্তরিত করে। EasyGov নাগরিকদের সুবিধার জন্য কল্যাণ ব্যবস্থার এই জটিল যাত্রা সমাধানে বিভিন্ন সরকারকে সাহায্য করছে। আরও তথ্যের জন্য অনুগ্রহ করে <a href='https://easygov.co.in/' target='_blank'> https://easygov.co.in/</a> দেখুন`,
    "8f241c62a9523b05bf0b6b16a09d856d": `ভাষা পরিবর্তন করুন`,
    "1ccbe6084301f9b1e18e3e7caf723ae1": `আপনি কি নিশ্চিত যে আপনি লগআউট করতে চান?`,
    "32ca0a0f69103bad1e6f134d5a8e36d7": `একটি নির্দিষ্ট বিভাগের জন্য চাকরি দেখতে, দয়া করে যে কোনও কাজের বিভাগে ক্লিক করুন`,
    "d6e6cb19e3b9c02f89d6cd54cfa7c613": `ব্যবসা`,
    "b1c94ca2fbc3e78fc30069c8d0f01680": `সব`,
    "1dd1c5fb7f25cd41b291d43a89e3aefd": `আজ`,
    "ebfe9ce86e6e9fb953aa7a25b59c1956": `গতকাল`,
    "4b2ef3655b5d6d48f1fa41892d0043b9": `বিজ্ঞপ্তি নেই`,
    "094f537cfb139894f1412200c78e87b1": `আপনি কি নিশ্চিত যে আপনি নির্বাচিত বিজ্ঞপ্তিগুলি মুছতে চান?`,
    "4201c11dc26b42deb90cb119c0b12fdb": `বিজ্ঞপ্তি ডাউনলোড করুন`,
    "ae45c43efe0011f67ec52003c0d8f916": `<b> ইঙ্গিত </b>: 'পাসওয়ার্ড' এর মধ্যে কমপক্ষে একটি উচ্চতর কেস, একটি লোয়ার কেস, একটি সংখ্যা এবং একটি বিশেষ চরিত্র সহ কমপক্ষে 8 <br> অক্ষর থাকতে হবে।`,
    "248336101b461380a4b2391a7625493d": `সংরক্ষিত`,
    "f03ab16cd58372c77ba45a3d9a5a1cb9": `খসড়া`,
    "a4d158b65f20725f9fe9c1763e9805c8": `ব্যক্তিগত স্পনসর করা`,
    "7fe12d923db43febcc5d8ea18df632ca": `আপনার কেওয়াইসি ডকুমেন্ট নির্বাচন করুন`,
    "d8ce714c8a76133a8d5746e3bfdd7ef7": `কেওয়াইসি`,
    "4f67fe16b274bf31a67539fbedb8f8d3": `নথিপত্র ধরণ`,
    "5127fa87ed375848481bfe9c786d8022": `আপনার কেওয়াইসি পূরণ করুন`,
    "8f75e3041034138aba310ba3f097ed24": `ডকুমেন্ট সংখ্যা`,
    "9abfb506bebd1e72069f0be0014986dc": `অবৈধ নথি নম্বর`,
    "ecfffd1bad9944f6a50e369f63268cff": `কিউআর স্ক্যান`,
    "c541f95d69a4a507b87b32d05dd6d73c": `ফ্রেমের ভিতরে কোডটি রাখুন`,
    "3876661cb83fa41284f828bd8d7fbb7b": `কেওয়াইসি প্রক্রিয়া যাচাইকরণ এবং সমাপ্তির জন্য কেওয়াইসি নথি আপলোড করতে হবে।`,
    "cc1d6f3625c4eb671d76b6ebf3f6f544": `আপনার ক্রেতাকে জানুন`,
    "08ac502bbb7652c683c1da97c7fdeb94": `{{xxxx}} আপনার মোবাইল নম্বরে একটি OTP পাঠিয়েছে৷`,
    "d19a88ee579190614084c2ea84379ac7": `OTP আবার পাঠান (1:00)`,
    "7397e92003e187474e5eab39b27fcb6b": `দুঃখিত, আমরা উত্স সিস্টেমগুলি থেকে আপনার ডেটা আনতে অক্ষম। অনুগ্রহ করে একটু পরে আবার চেষ্টা করুন.`,
    "a943d4ac1dd2e0048bcbf85225bf2811": `আপনি OTP জেনারেট করার সর্বোচ্চ সীমা অতিক্রম করেছেন, অনুগ্রহ করে 3 ঘন্টা পরে আবার চেষ্টা করুন`,
    "8dac090b39cf9b3bff9a9503531d04cf": `আপনার অ্যাকাউন্টটি বর্তমানে অবরুদ্ধ, দয়া করে কিছু সময়ের পরে চেষ্টা করুন।`,
    "08798caaee5cc8ea1282883901c8810b": `অবশিষ্ট চেষ্টা - {{Number}} সতর্কতা - টানা 4 টি ব্যর্থ চেষ্টা করার পরে, আপনার অ্যাকাউন্টটি লক হয়ে যাবে।`,
    "33018956b3ffe20d413e3c6780f19aff": `কেওয়াইসি বিশদ নিশ্চিত করুন`,
    "90f35a35415ca3b4c623d7c515fce1c0": `মনোযোগ! আপনি যদি আপনার বিশদ আপডেট বা পরিবর্তন করেন তবে আপনার ডেটা যাচাই করা হবে এবং আপনাকে আপডেট হওয়া ডেটার জন্য ডকুমেন্ট প্রুফ আপলোড করতে বলা হবে। এগিয়ে যেতে "চালিয়ে যান" এ ক্লিক করুন`,
    "aee68a98fd803f091082faf867c0f62b": `কেওয়াইসি ফর্ম পূরণ করুন`,
    "2e853d869dd0c3cb0a64e4938ee90647": `অবৈধ পিন কোড`,
    "8f3124e142a4000fe04a581f4f41c8da": `অবৈধ {{labour card}} সংখ্যা`,
    "dbb362212f1b8118f17633521d1e72f7": `আপনার অ্যাপ্লিকেশনটি আপনার 'খসড়া স্কিম' এ সফলভাবে সংরক্ষণ করেছে।`,
    "5c93310dd0291e121181e830cdda892e": `গ্যালারী`,
    "967d35e40f3f95b1f538bd248640bf3b": `ক্যামেরা`,
    "37bed5f24fa3752c7830c021501539a0": `আপনি কি নিশ্চিত যে আপনি ছবিটি সরাতে চান?`,
    "1a263d755f169c2499c1a55f7a465b93": `আপনার ডকুমেন্ট আইডি বর্তমানে অবরুদ্ধ, দয়া করে কিছু সময়ের পরে চেষ্টা করুন।`,
    "689707127ddb398dc4b447e1dffa3a47": `আপনি আপনার বিশদ যাচাই করার জন্য বৈধ সংখ্যা ছাড়িয়ে গেছেন। আপনার যাচাইকরণ 3 ঘন্টা অবরুদ্ধ রয়েছে।`,
    "04c850a81052df23680d00d2aa1d891e": `কেওয়াইসি স্ট্যাটাস`,
    "a441e519087df0d7c8572b9fafa14ae2": `আপনার দেওয়া নিম্নলিখিত তথ্যগুলি আপনার সরকারি রেকর্ডের সাথে মেলেনি৷1৷ রেকর্ডের সাথে আপনার নাম মেলেনি।2. রেকর্ডের সাথে আপনার বয়স মেলেনি।`,
    "75514ce9dac260a1f7abd0d85ce6af46": `আপনার প্রদত্ত বিবরণগুলি রেকর্ডগুলির সাথে মেলে না`,
    "5955e277da6fb75982b0f651f4ae8b92": `আমরা উত্স সিস্টেমগুলি থেকে আপনার ডেটা আনতে অক্ষম। অনুগ্রহ করে একটু পরে আবার চেষ্টা করুন.`,
    "be60ac326400cc5ef0d173a833c4a40b": `আপনার বিবরণ যাচাই করা হচ্ছে`,
    "d1083e84a9a7283ffb081263e8533509": `আপনার আবেদনটি আপনার ‘খসড়া স্কিম’ এ সফলভাবে সংরক্ষণ করা হয়েছে`,
    "0bad551abed71c0a41ca8df804afec86": `ডকুমেন্ট ব্যবহার করে কেওয়াইসি`,
    "e6387f3e684958515af2947d8cdf01d7": `কেওয়াইসি ফর্ম আপডেট করুন`,
    "9c7ed3d8ac60281e90519b4ebf07a9a2": `ঠিকানা নির্বাচন করুন`,
    "31e41095bfaa14799239e8d9ba7ad438": `অ্যাপ্লিকেশন বিশদ`,
    "e1e4c8c9ccd9fc39c391da4bcd093fb2": `ব্লক`,
    "b9eca8f59016e8ff69a2c76fa869d23f": `শ্রম কার্ড আপলোড করুন`,
    "6cf24d7a07132fe88b610e2b3d3def28": `স্ব ঘোষণা`,
    "827b05360cd22ccbaf3d085aded71570": `ঠিকানা প্রমাণ`,
    "f734cd7792862bebbbb87ddc54285376": `পরিচয় প্রমাণ`,
    "5a8c686dfdba564a2801806f3827c124": `ছবিগুলি অবশ্যই JPG বা PNG ফর্ম্যাটে হতে হবে৷ PDF ফাইল 1MB এর কম হতে হবে।`,
    "d495cdb8315e12e3dc5fd6121ed69cbc": `অবৈধ IFSC কোড`,
    "cd9f177eac6411501839f7868e38767b": `আপনি কি নিশ্চিত যে আপনি এই দস্তাবেজটি মুছতে চান?`,
    "565d2daf0ccaf00d354669e7551b81dd": `প্রোফাইল বিশদ পর্যালোচনা`,
    "9895dd168483d1d34ab9ebfb2b6760be": `ব্যাংক নির্বাচন করুন`,
    "deb5c3f30f3a8381367392d6608fbe68": `আবেদনপত্রের অবস্থা`,
    "970324882e5bbfd2deef9e5b7e2d1160": `অ্যাপ্লিকেশন ডাউনলোড করুন`,
    "03b55d8ed61b625c209cc42ea0a35b8c": `ধন্যবাদ!`,
    "8276f0b86b5ee98d03f2eafba3590bb0": `আপনাকে পরিবেশন করার সুযোগ দেওয়ার জন্য। আপনার আবেদন সফলভাবে জমা দেওয়া হয়েছে।`,
    "ac3122aeddff176ae67d3df2b2198d19": `ধন্যবাদ! আপনার আবেদন যাচাইয়ের জন্য জমা দেওয়া হয়েছে, "প্রয়োগকৃত অ্যাপ্লিকেশন" এর অধীনে অ্যাপ্লিকেশন থেকে আপনার আবেদনের স্থিতি পরীক্ষা করুন`,
    "73da163444abaee6a5c88532b8eda629": `আপনার অভিজ্ঞতা কেমন ছিল?`,
    "74eb855e4de6fe58228f03006c02fd8a": `ঠিক আছে`,
    "d36ed38206da38c85af59039d18f1108": `<b>আপনার প্রতিক্রিয়া বর্ণনা করুন</b> (ঐচ্ছিক)`,
    "446e92f15cd245125ccd983ef968e692": `না ধন্যবাদ!`,
    "634a1a1ed07518476cb7a4beab87185a": `ভয়ানক`,
    "f1b68d66337a81cfa0d2076171cba2a8": `খারাপ`,
    "45802158e78dd9584161629098018fe8": `ভাল`,
    "5709082e0d4e7a2cb92c2348acf297be": `এটা পছন্দ`,
    "c629e8ac2592361ec6474f07fb75f6fc": `আপনার প্রতিক্রিয়া আমাদের উন্নত করার জন্য নতুন সুযোগগুলি আবিষ্কার করতে এবং আপনার কাছে সেরা অ্যাপের অভিজ্ঞতা রয়েছে তা নিশ্চিত করার অনুমতি দেয়।`,
    "871abe85eba547582d9e8f0f9c61efbd": `আমাদের প্রতিক্রিয়া প্রেরণের জন্য আপনাকে ধন্যবাদ।`,
    "733d43480c8589b1368e5def6b480415": `প্রয়োগ`,
    "a5b739036b4d0b2bc966291747198d9b": `তালিকাভুক্ত`,
    "0a4c051fb821b8e96d2bd564b893639b": `আপনার জন্য কোনও কাস্টমাইজড স্কিম নেই`,
    "9639e32cab248434a17ab32237cb3b71": `প্রয়োগ করুন`,
    "3f68e67dc6c397aaa9d1c24c356f754f": `যাচাই`,
    "d63b911814d17bb4d541141b8531a458": `এই স্কিমগুলি আপনার "আমার স্কিমগুলিতে" সংরক্ষণ করা হয়েছে`,
    "454fd3b5167e532f28f4e3d47cd901fa": `আপনার এখনও কোনও সংরক্ষিত স্কিম নেই`,
    "5e22b63835aef7976f3ca019841b1b28": `আপনি কি নিশ্চিত যে আপনি এই স্কিমটি সরিয়ে ফেলতে চান?`,
    "8454c7c12286671b47684cef824ffba6": `"মনোযোগ! আপনি যদি আপনার ডেটা পরিবর্তন করেন তবে আপনার প্রোফাইল ডেটাতেও এটি আপডেট করা হবে। আপনি কি চালিয়ে যেতে চান?"`,
    "24a23d787190f2c4812ff9ab11847a72": `স্থিতি:`,
    "85896a744f6fd84cbcd7bddb31fae8d7": `কর্ম:`,
    "ccb168b5a6a86eb100dc2dee754a316b": `কারণ:`,
    "85b7d770b94264f51f31d2d7a98f69f0": `আবেদন প্রত্যাহার`,
    "0ddc5e1c93fec761445a53ee9fd70285": `কর্তৃপক্ষ প্রেরণ`,
    "7a4813490daea6bbe84ed02f9534570b": `অ্যাপ্লিকেশন এবং সংযুক্তি যাচাই করা হয়েছে`,
    "f9e39c8a1b3348b654b73fcdebeb3265": `আবেদন এখনও অনুমোদিত হয়নি`,
    "6b76127f99b6f29c2a725da125d98760": `বেনিফিট বিতরণ`,
    "4296fd320beaf61dcf979ff634cf2f2d": `ক্রিয়া প্রয়োজনীয়:`,
    "fc341b619c2671a430365edefc100ff1": `সম্পূর্ণ ক্রিয়া`,
    "4f4b8b7d27a33c83089f30dba4989be5": `অ্যাপ্লিকেশন দেখুন`,
    "2f70605f8a2f6c01ac06fe0f6d32b44c": `আপনি কি নিশ্চিত যে আপনি আপনার আবেদন প্রত্যাহার করতে চান?`,
    "6eaaa83f6b9b14dbed5e0779d91fffb5": `আবেদন প্রত্যাখ্যান`,
    "6187927c14aae6cab0f87b55ef75edb1": `পুনরায় প্রয়োগ করুন`,
    "864cacf59d640b51390faf29867c310e": `পরবর্তী বেনিফিটের জন্য এগিয়ে যান`,
    "16d2b386b2034b9488996466aaae0b57": `ইতিহাস`,
    "c5b2992c0919083c2d8907f8fe1441f6": `আর্থ-সামাজিক বিবরণ`,
    "d79c1042de4ec2d7e6d84d3a8f436765": `পেশা প্রোফাইল`,
    "3c8d3371dfe92bb990493d3dda09717c": `শিক্ষা প্রোফাইল`,
    "181bfe2f225b876c687104878a6b43cc": `আবাসন বিশদ`,
    "f081b3a821bf13173ac4ec3468122a8c": `স্বাস্থ্য বিবরণ`,
    "fa284c99eeef0e1c874643e05a449214": `"মনোযোগ! আপনি সমস্ত মানগুলি অনিচ্ছাকৃত করতে পারবেন না। এই মানটি অনির্বাচিত করার আগে অন্য মান চয়ন করুন" "`,
    "311d38f551d45cb61e297748f08bb658": `আপনার ডেটা সফলভাবে সংরক্ষণ করা হয়েছে`,
    "4500d073ef5972cc935a59ff0b752d8b": `বৈধ পর্যন্ত`,
    "8de420ed8c345d37cf65a3494bff92ef": `ভোটার কার্ড`,
    "f6fb95a7bfd158a53680691f250e2273": `সরকারী ফি:`,
    "c51bae43a860107d2ddaddcc44c72355": `ঠিকানার প্রমাণ (যে কোনও একটি)`,
    "a1cba2ed3414b72aa4d7651653d08900": `বয়সের প্রমাণ (যে কোনও একটি)`,
    "330dd937743341b8c97a75ddbcfacf1b": `ডাউনলোড ফর্ম লিঙ্ক:`,
    "de259fed6ef60355d0dd031c4a97e945": `ফর্ম আপলোড করার লিঙ্ক:`,
    "6204374d06a6ca36a20e887451db78af": `আরও পরিষেবা অন্বেষণ`,
    "98d047289f68350b76f20cd10f1db3d4": `আইডির প্রমাণ`,
    "1a01812e1888f4eb6dca36e8b1d39c04": `ডিওবি প্রুফ`,
    "0dcd6f339c0f72875972d60324db52ff": `আবেদন ফি বিশদ`,
    "133e72d94045f209d146dd6fc01de185": `ফি বিশদ`,
    "b6d11bb63dc2071d6cba2aaeba8c8a1d": `সুবিধার ফি`,
    "719fec04166d6fa75f89cd29ad61fa8c": `করের`,
    "a876fbe73a6d039cb8c8566f6aa716d5": `সর্বমোট পরিমাণ`,
    "9d463ea3feb5afec51c20b4f62927ca3": `কুপন কোড`,
    "620a92a00838e523161dab899b76c764": `অর্থ প্রদান`,
    "6f861b42317b2333c01faa9b555b7e14": `এই কুপন দিয়ে সঞ্চয়।`,
    "c6088ccfc87a825ceb53a15ce4d99510": `আপনি <Amount> মোট ছাড় পেয়েছেন`,
    "0e54fc6a9dba79c2bc03ac08f3bf4a27": `হ্যাঁ!`,
    "c1d9a46634bb89273f7d95a5577f15ad": `কুপন ছাড়`,
    "1063e38cb53d94d386f21227fcd84717": `অপসারণ`,
    "ba8550da63869236a92fed41fba6e84f": `কুপন কোড সফলভাবে প্রয়োগ`,
    "f7a7e0e3897bac9ea4c83d53f6ad5a23": `অকার্যকর কুপন কোড.`,
    "90fe9a78f3ea30beeb6b7ef447d2b6dd": `কুপন কোড সফলভাবে সরানো হয়েছে`,
    "af1b98adf7f686b84cd0b443e022b7a0": `বিভাগ`,
    "11f18dd73f0c3d374d9d5c230cd43160": `যুব দক্ষতা`,
    "5a6c0d2579b31142130f20104fa58ea6": `শংসাপত্র`,
    "c482980d384a9d0e7bc39e1140270870": `অর্থায়ন`,
    "605669cab962bf944d99ce89cf9e58d9": `স্বাস্থ্য`,
    "5dac73aa49b9ccaca4b9b0d0ef615d10": `পুলিশ`,
    "b2ece3493eed05158a9cde4a0d2da2a5": `জনসাধারণের অভিযোগ`,
    "94df2a6972ca1fa79411645fe9b42339": `ইউটিলিটি`,
    "f5f0c488ed56cf789d19a13ffba8abe3": `পরিবহন`,
    "0db377921f4ce762c62526131097968f": `সাধারণ`,
    "bc34e4c4f36b0d66910a906449910f64": `আপনি কি নিশ্চিত যে আপনি এই পরিষেবাটি সরিয়ে ফেলতে চান?`,
    "1e22827dff05f5a1c044993aff539403": `আপনার কোনও সংরক্ষিত পরিষেবা নেই।`,
    "b08f17b81d3b9b4f6287b87c53cfc97a": `খসড়াতে আপনার কোনও পরিষেবা নেই।`,
    "6f96ffbc03ad1b9ebb5c6e2ecfcbdc71": `আপনি এখনও কোনও পরিষেবা প্রয়োগ করেন নি।`,
    "daf87fe41645c86672e96857222a0909": `আবেদন জমা দেওয়া`,
    "54aeeea175c1509b7c56e0c984f9b4fa": `আপনাকে ধন্যবাদ, আপনাকে পরিবেশন করার সুযোগ দেওয়ার জন্য। আপনার আবেদন সফলভাবে জমা দেওয়া হয়েছে।`,
    "f18c4dab2674eae1e8c287c683b66729": `আবেদনের তারিখ`,
    "fb0728ffa9b40d444db91c818eb21a2b": `আবেদনকারীর নাম`,
    "e500e94f457ddd88824c88fda548bed2": `আবেদন আইডি`,
    "ad0206b8eec486df629ce37169376f65": `ভূমিকা ও দায়িত্ব:`,
    "6f2ad1cfe3c5b08cc53c62470f4cb1f7": `নথি প্রয়োজন:`,
    "1d83797411218ecca1e5ff4d4705dfb0": `নির্বাচন পদ্ধতি:`,
    "ce9e1e989c012211d8bc83c5353b4495": `কাজের অ্যাপ্লিকেশন বিশদ`,
    "18dcaa84c2ee447028f8450cd0380ba6": `একাডেমিক বিশদ:`,
    "93e9a018926429f6d0b0c032910a8eb6": `কাজের অ্যাপ্লিকেশন বিশদ পর্যালোচনা`,
    "6f906132d3c6b88b57225fdcf76f6e71": `সচরাচর জিজ্ঞাস্য`,
    "2d8d66b9c9b540fcb8384167f1565b64": `দয়া করে বানানটি পরীক্ষা করুন বা অন্য কিছু অনুসন্ধান করার চেষ্টা করুন।`,
    "f73a506c52c2dba159fc0fbee823aace": `আপনি ইতিমধ্যে এই স্কিমের জন্য আবেদন করেছেন। আপনার প্রয়োগিত স্কিমগুলি থেকে দয়া করে আপনার অ্যাপ্লিকেশনটির স্থিতি পরীক্ষা করুন।`,
    "b73856e1c7ab3e647e28663d5e30fabe": `আপনি ইতিমধ্যে এই স্কিমের জন্য আবেদন করেছেন। আপনার প্রয়োগকৃত পরিষেবাগুলি থেকে দয়া করে আপনার অ্যাপ্লিকেশনটির স্থিতি পরীক্ষা করুন।`,
    "cd850a99c0895272f2cdf23586867b16": `আপনি ইতিমধ্যে এই স্কিমের জন্য আবেদন করেছেন। আপনার কাজ থেকে একই জন্য আপনার আবেদনের স্থিতি পরীক্ষা করুন।`,
    "4e0b8852fc3f3d1a2b4c24c4602766dd": `ছবি অবশ্যই JPG, PNG, PDF ফাইলে এবং 1MB এর কম হতে হবে।`,
    "5a9e7167eb5b7cb7bb46cf5c05eed59a": `নিয়োগ`,
    "91412465ea9169dfd901dd5e7c96dd99": `আপলোড`,
    "3835b71ace5673385a19d56bc938fda8": `মাঠের ছবি`,
    "4386624773a0ef6de5a5de0dd6f8facd": `নথির ধরণ প্রবেশ করান`,
    "fdba3d2a9c707e50f4eec34cd6bdd0d6": `"আপনার গ্রাহককে জানুন" হ'ল সরকারী প্রকল্পের জন্য আবেদনকারী ব্যবহারকারীর পরিচয় সনাক্ত এবং যাচাই করার বাধ্যতামূলক প্রক্রিয়া।`,
    "244773cb8f460196045c5b90659d2c9d": `আপনার অ্যাপ্লিকেশনটি আপনার "খসড়া পরিষেবাদি" সফলভাবে সংরক্ষণ করা হয়েছে`,
    "bbcb2990551820fc4678835c14a84503": `ছবি সরান`,
    "78e3e2ba60b81cf6cc24e6baf89eaa81": `ব্যক্তিগত শংসাপত্র`,
    "88bcab139c5d6142b04bf77d7dd1c023": `আপনি ইতিমধ্যে এই পরিষেবার জন্য আবেদন করেছেন। আপনার প্রয়োগকৃত পরিষেবাগুলি থেকে দয়া করে আপনার অ্যাপ্লিকেশনটির স্থিতি পরীক্ষা করুন।`,
    "bf8cd5900a65efaa89931067cb1d3775": `আপনি খসড়াতে কোনও স্কিম সংরক্ষণ করেন নি।`,
    "beab92ac3c8a798a35aea2ad0809313f": `অতিথি হিসাবে চালিয়ে যান`,
    "95884374c7752d972101b70854caffe6": `সাইন ইন সাইন আপ`,
    "e44cb1c4a784a247f7296cc02f7f301c": `জেলা নির্বাচন করুন`,
    "9aa060dc3e3bb3ffff9908c5e713a80c": `আপনি এখনও কোনও পরিষেবার জন্য আবেদন করেন নি।`,
    "383f419ea8c0b8142cbdc71e2e4b144a": `পূর্ববর্তী প্রশ্ন`,
    "fb188e808b65b9ee48a5c9d88fb92af2": `আবার শুরু`,
    "db5eb84117d06047c97c9a0191b5fffe": `সমর্থন`,
    "bc80ae98530c6ba4e76ed34c58d81875": `মোবাইল অ্যাপের জন্য Easygov ডাউনলোড করুন`,
    "85ce0cae173233410acbc380a7e531cd": `দয়া করে নোট করুন যে এই পৃষ্ঠাটি সরকারের ওয়েবসাইট / ওয়েব পৃষ্ঠাগুলিতে লিঙ্ক সরবরাহ করে। মন্ত্রনালয়/বিভাগ/সংগঠনগুলি এই ওয়েবসাইটগুলির সামগ্রী সম্পর্কিত সংস্থাগুলির মালিকানাধীন এবং তাদের আরও কোনও তথ্য বা পরামর্শের জন্য যোগাযোগ করা যেতে পারে`,
    "ad0a802ec8134e9210a5625933db50a7": `দাবি অস্বীকার`,
    "26dbd93cbe6f1e56b1577d8e3cb7fda3": `পরিষেবা নির্বাচন করুন`,
    "c6582b36b0f516684a873e8782517822": `স্কিম এবং পরিষেবা নির্বাচন করুন`,
    "b98287be44e1230cf144aa3af08ac82f": `আপনি এখনও কোনও স্কিমের জন্য তালিকাভুক্ত নন`,
    "449d3cf7dd09172024ebe93872410f0f": `অ্যাপ্লিকেশন দেখুন`,
    "025d9b3a3c2a6a27d04c57c1d7cabc9f": `কোন পদক্ষেপ নেওয়ার প্রয়োজন নেই`,
    "06df33001c1d7187fdd81ea1f5b277aa": `ক্রিয়া`,
    "adff901e227bf2b2200c4767fe773836": `ক্রিয়া প্রয়োজন`,
    "4ff84d2ee02fe6ef0ca2edc3cdecc0ed": `পদক্ষেপ প্রয়োজন`,
    "c08c272bc5648735d560f0ba5114a256": `যোগাযোগ সমর্থন`,
    "fd2f820569d83784238e1027897b463e": `আপনার নাম প্রবেশ করুন`,
    "cda83d00666a7bfa8cde6808de4ef994": `যোগাযোগের নম্বর`,
    "a4d3b161ce1309df1c4e25df28694b7b": `জমা দিন`,
    "4c2a8fe7eaf24721cc7a9f0175115bd4": `বার্তা`,
    "68d390535e8a7120e3f6942d8a2214a0": `আপনার মেসেজ লিখুন`,
    "2f644b4a27e68f505c4f4ac2ffe3a8ac": `দয়া করে কেবল বর্ণমালা, সংখ্যা, কমা, -, _, / লিখুন`,
    "58f7a43f2c3bd7854d99fbbbc3fa4ae9": `কেবল বর্ণমালা অনুমোদিত।`,
    "4906d981bb592b32f6982fb4187c7b60": `একটি বৈধ আইএফএসসি কোড লিখুন।`,
    "562d3d08919ae105c3ebc42d9607d266": `দয়া করে কেবল বর্ণমালা বা সংখ্যা লিখুন`,
    "fab2bb673b21bef95ee70b99dbd7f9fc": `কেবল 11 টি অক্ষর অনুমোদিত।`,
    "a7eb433676475fee54727c14ad880b8c": `শুধুমাত্র 150 টি অক্ষর অনুমোদিত।`,
    "7c58cde3b827bc7d57cbf77865046169": `দয়া করে কেবল নম্বর লিখুন`,
    "544b0264d55a1e4cf476b279240d9be4": `মাত্র দশ অঙ্কের অনুমতি রয়েছে। দয়া করে 0 বা +91 উপসর্গ করবেন না`,
    "73f0d980e48b8141e0776e02d96a872f": `শুধুমাত্র বারো অঙ্কের অনুমোদিত।`,
    "0ce98bd973419ff33921a2275e7903b9": `একটি বৈধ প্যান নম্বর লিখুন দয়া করে।`,
    "c6f91b305e91a0053748310de170a73c": `মাত্র ছয়টি অঙ্কের অনুমতি রয়েছে`,
    "9f44bd93c8988c682d5ef5369fd11f47": `অনুগ্রহ করে আপনার সঠিক মেইল ​​আইডি প্রবেশ করান`,
    "10803b83a68db8f7e7a33e3b41e184d0": `জন্ম তারিখ`,
    "ac598199ea0dd028ade4677a435caf4b": `একটি বৈধ আধার নম্বর লিখুন`,
    "d0de720ccdffea8490dfd2893c50ebc5": `এটি {{displayName}} এর চেয়ে বড় হওয়া উচিত`,
    "ced6e171e13ebd5c37415c822706b9d4": `এটি {{displayName}} এর চেয়ে কম হওয়া উচিত`,
    "d1a1cb2bd14fdd9eec972f162cfd1ee0": `এটি {{displayName}} এর সমান হওয়া উচিত`,
    "9483135517d156229d0439dff74b1ca0": `এটি {{displayName}} এর চেয়ে বড় বা সমান হওয়া উচিত`,
    "01e9cd748e852b1ae389b980bff195b0": `এটি {{displayName}} এর চেয়ে কম বা সমান হওয়া উচিত`,
    "dc236871b26ba387e1284445caa99df4": `আপনাকে পরিবেশন করার সুযোগ দেওয়ার জন্য।`,
    "2102fff7bb4de45c9e616e3f01b6810f": `আপনার আবেদন সফলভাবে জমা দেওয়া হয়েছে।`,
    "dc9466f3d424b4acba2fc5c9f58d4f8a": `দুঃখিত, আপনি যোগ্য নন`,
    "a9198ed7d89e246a80078db84d2722e8": `আপনার ফোন নম্বর বা পাসওয়ার্ড মেলে না`,
    "8ac0a98c8c2f2c86396e34b6b5d63e38": `একই নাম সহ প্রোফাইল ইতিমধ্যে বিদ্যমান`,
    "4ae112e33b3189c9fa04369b51259d76": `একই নাম এবং সম্পর্কের সাথে প্রোফাইল ইতিমধ্যে বিদ্যমান`,
    "b0fb09a28a4a52a1b3ca2656dec0435e": `আপনি কি নিশ্চিত যে আপনি এই দস্তাবেজটি সরিয়ে ফেলতে চান?`,
    "e384b6a4760d80be609d897414cfe680": `আপনি একজন নিবন্ধিত ব্যবহারকারী নন, আপনি হয় "সাইন আপ" বা "OTP দিয়ে লগইন" করতে পারেন।`,
    "c2cd85ffa55b2ec9b049b7eb131d3a9f": `পাসওয়ার্ড প্রবেশ করা মেলে না। আবার চেষ্টা করুন`,
    "3a816ee692e09b3cac6ef18e14071bd4": `আপনার পাসওয়ার্ড সফলভাবে সেট করা হয়েছে`,
    "764d4847b734121ceb58e5c8195fa5b6": `আপনার প্রতিক্রিয়া আমাদের উন্নত করার জন্য নতুন সুযোগগুলি আবিষ্কার করতে এবং আপনার কাছে সেরা অ্যাপের অভিজ্ঞতা রয়েছে তা নিশ্চিত করার অনুমতি দেয়।`,
    "701d895fbc64569aa143ca6c44274139": `আপনি OTP জেনারেট করার সর্বোচ্চ সীমা অতিক্রম করেছেন, অনুগ্রহ করে কিছুক্ষণ পরে আবার চেষ্টা করুন।`,
    "0bdfc4d562c9b0ccfaa4c0f6d4cac98f": `আপনি এখনও কোনও স্কিমের জন্য আবেদন করেন নি।`,
    "4391bf5189e3e13c4aa4cb314c605ac8": `আপনি এখনও কোনও স্কিম সংরক্ষণ করেন নি।`,
    "92202504591d549b89340d26ffc0753a": `এই কাজটি আপনার "আমার কাজগুলিতে" সংরক্ষণ করা হয়েছে`,
    "af6ebe0c0e89201cb2294f32955f7b15": `একবারে কেবল 20 টি নথি অনুমোদিত।`,
    "b1481f771aee527fd910fa8d8672cd19": `আপনি কি নিশ্চিত যে আপনি এই বিজ্ঞপ্তিটি সরাতে চান?`,
    "06a6b009c19d5816574cdc7800d10eb5": `আপনার অনুরোধ সফলভাবে উত্থাপিত হয়েছে।`,
    "b941bc7a9d4fe7a8c7c31ed440754285": `পর্যালোচনা অ্যাপ্লিকেশন`,
    "e56b98da835e8f4a018502b121520ac7": `অবশিষ্ট প্রচেষ্টা`,
    "416828262df288445e72ca1d3661f993": `সতর্কতা - টানা 4 টি ব্যর্থ চেষ্টা করার পরে, আপনার অ্যাকাউন্টটি লক হয়ে যাবে`,
    "9f978addf10226b03278e4932348ba98": `শুধুমাত্র 40 টি অক্ষর অনুমোদিত।`,
    "69f076c339373e00e9e841da965f4208": `আধার আপডেট করুন`,
    "48a82e0b2bd5818f110e55bb6f14aedc": `আপনার প্রদত্ত নিম্নলিখিত তথ্যগুলি সরকারী রেকর্ডের সাথে মেলে না`,
    "5c37ad5c0dd6e318af899b3c8fcbe886": `দুঃখিত, আমরা উত্স সিস্টেম থেকে আপনার ডেটা আনতে অক্ষম। আপনি যাচাইকরণ ছাড়াই এগিয়ে যেতে পারেন বা পরে চেষ্টা করতে পারেন।`,
    "cdb2033fc9a30d6d6c1abe401cd1e37b": `পরে চেষ্টা করুন`,
    "f8d855b26faffa0f8779fb44632d6bc1": `অবৈধ ক্যাপচা কোড`,
    "d53b4d564b9054db9b4b4a50e713197f": `দয়া করে এখানে ক্যাপচা প্রবেশ করুন`,
    "ed001fb3ff7eaca9bbfbaacddc9d5a72": `কোনও ইতিহাস পাওয়া যায় নি`,
    "4934d9bc9ebd024d4106d5e1f093ae79": `দর্শনার্থীদের গণনা`,
    "f7ce7cb4b450acfe0b4cef36c3bdec2d": `আপনার ব্যাংক অ্যাকাউন্ট বর্তমানে নিষ্ক্রিয়। আপনি আরও এগিয়ে যেতে পারেন তবে আপনার অ্যাপ্লিকেশন অনুমোদিত হলে আপনার ব্যাংক অ্যাকাউন্টটি বেনিফিট পাওয়ার জন্য তাড়াতাড়ি সক্রিয় করতে পারেন।`,
    "df9e5c88861b08c33b2d46de8d7037e9": `আপনার ব্যাংক অ্যাকাউন্ট বর্তমানে বাতিল করা হয়েছে। আপনি আরও এগিয়ে যেতে পারেন তবে আপনার অ্যাপ্লিকেশন অনুমোদিত হলে আপনার ব্যাংক অ্যাকাউন্টটি বেনিফিট পাওয়ার জন্য তাড়াতাড়ি সক্রিয় করতে পারেন।`,
    "3fc95e40b475f7269e64f25a1c4aef3b": `আপনার ব্যাংক অ্যাকাউন্টটি আপনার আধারের সাথে যুক্ত নয়। আপনি আরও এগিয়ে যেতে পারেন তবে আপনার আবেদন অনুমোদিত হলে আপনার ব্যাংক অ্যাকাউন্টটি আপনার আধারের সাথে প্রাথমিকভাবে সুবিধা পেতে সংযুক্ত করতে পারেন।`,
    "1c35d003a65da48bf9f6d6fc69271d5a": `দুঃখিত, সিস্টেম ত্রুটির কারণে আপনার আবেদন জমা দেওয়া যায়নি। অনুগ্রহ করে একটু পরে আবার চেষ্টা করুন.`,
    "8e437cbd840bce5c5edc0ef8164de6b7": `আপনি যদি আপনার বিশদ আপডেট বা পরিবর্তন করেন তবে আপনার ডেটা যাচাই করা হবে এবং আপনাকে আপডেট হওয়া ডেটার জন্য ডকুমেন্ট প্রুফ আপলোড করতে বলা হবে। চালিয়ে যেতে "এগিয়ে যান" এ ক্লিক করুন।`,
    "b010e3be9f81072a9808ed4a5dd0e45b": `সমীক্ষায় আপনার প্রদত্ত বিশদগুলির সাথে নিম্নলিখিত তথ্য মেলে না:`,
    "01acb0344944f11d68aeecc9f5b574ea": `আপনি কি প্রতিক্রিয়া (গুলি) নিয়ে এগিয়ে যেতে চান?`,
    "4ff70f7b2e5a74415de360150a6e59d3": `তোমার`,
    "bd7aaae9a27a2a4d71214c002bcf50ce": `রেকর্ডের সাথে মেলে না।`,
    "ebfd339b0ac14aa79b325b53f06bd375": `আপনার প্রদত্ত তথ্যগুলি আপনার আধারের বিশদগুলির সাথে মেলে না। আপনি আরও এগিয়ে যেতে পারবেন না।`,
    "1e410e1b9903e7a99b93857f536b7e1d": `যাচাই করা হয়নি`,
    "28b266f2bf0a4ecc2dec3b6ca24fcb9b": `আপলোড`,
    "68982db8041ee52dd9e5a6e527c8a1fa": `পুনরায় ক্রপ`,
    "e7bf7d83a37666fd8749e433189b1c27": `অনুগ্রহপূর্বক অপেক্ষা করুন`,
    "c389f9e7d5ea93af9deb2e8f1f9c6d89": `আরও জানতে {{"0x0001F6C8"}} on এ ক্লিক করুন।`,
    "f17816bcbe79e18bee49388fe84d2876": `OTP মেয়াদ শেষ।`,
    "1531d9dc798857eb85345d349bffc99b": `পেছনে`,
    "7939de8a9958e01fb1f172e21c5a7a85": `দয়া করে সঠিক তথ্য সরবরাহ করুন, এটি আপনাকে আপনার যোগ্য স্কিমগুলি দেখাতে সহায়তা করবে।`,
    "bf58c4a9eac8e0ec82c158c3adfcd609": `স্কিমগুলি বিভিন্ন বিভাগের অধীনে শ্রেণিবদ্ধ করা হয়েছে। আপনি আপনার পছন্দসই বিভাগ অনুযায়ী স্কিমগুলি পরীক্ষা করতে পারেন`,
    "09349e778786f7ed758021ddbed12ca6": `এই তথ্য আমাদের {Intent} বিভাগের অধীনে যোগ্য স্কিমগুলি সন্ধান করতে সহায়তা করবে।`,
    "0d45c019e7e9237a316f0f16824015d2": `আপনার প্রোফাইল ডেটা বিভিন্ন ট্যাবের নীচে সংরক্ষণ করা হয়। আপনি আপনার যে কোনও প্রোফাইল তথ্য সম্পাদনা করতে পারেন।`,
    "3f52884ec08606e6e73426eaec8de195": `এই তথ্যটি আপনাকে এই স্কিমের অধীনে যে সঠিক সুবিধাটি পেতে পারে এবং সুবিধাটি গ্রহণের জন্য আবেদন প্রক্রিয়া সম্পর্কে বিশদ সরবরাহ করতে আমাদের সহায়তা করবে।`,
    "c389f9e7d5ea93af9deb2e8f1f9c6d89": `আরও জানতে {{"0x0001F6C8"}} on এ ক্লিক করুন।`,
    "5cc2bdab3db5aa21633656a0d55f7477": `আপনি আবেদন দিয়ে আরও এগিয়ে যেতে পারবেন না`,
    "d5de543c0e83073f05a846d62cc83c81": `ডকুমেন্ট ইতিমধ্যে একই ক্ষেত্রের জন্য আপলোড করা হয়েছে।`,
    "fca8da28cbcbe094dc646872e7cdbc7b": `বৈধ প্রবেশ করুন`,
    "6fa2260c737f40fa1a3f63274c8fca48": `আপনি আরও এগিয়ে যেতে পারবেন না। আপনি হয় প্রোফাইলে বা আপনার আধার কার্ডে আপনার বিশদটি সংশোধন করতে পারেন`,
    "3272e327ba9c82197c5c982487224925": `দুঃখিত, আমরা উত্স সিস্টেম থেকে আপনার ডেটা আনতে অক্ষম। অনুগ্রহ করে একটু পরে আবার চেষ্টা করুন.`,
    "586637318049e6dbb03e991bc45cba06": `আপনার অ্যাকাউন্ট মুছতে আপনাকে প্রথমে আপনার অ্যাকাউন্টের সাথে সম্পর্কিত সমস্ত প্রোফাইল মুছতে হবে।`,
    "ce6d6092569f3f247659ceb51d57be95": `আপনার প্রয়োগিত বা তালিকাভুক্ত স্কিমগুলিতে আপনার চলমান অ্যাপ্লিকেশন রয়েছে বলে আপনি {{profileName}} প্রোফাইলটি মুছতে পারবেন না`,
    "8d258e398e2dbc8099c984258fadce2a": `আপনার নিবন্ধিত মোবাইল নম্বরে একটি OTP পাঠানো হয়েছে (xxx xxx {{phoneNumber}})`,
    "abb2e3d13ded4cea9275beafacd9f4c8": `এই স্কিমটিতে আপনার আগ্রহ বাড়াতে, "আপনার আগ্রহ তৈরি করুন" এ ক্লিক করুন এবং এই প্রকল্পের সুবিধা দেওয়ার জন্য সরকার আপনার কাছে পৌঁছে যাবে`,
    "493de8e0a6b5d4a10af30cf3105b9e1f": `আপনার আগ্রহ উত্পন্ন করুন`,
    "c9b0246a8f3989724ec4958f91e380a7": `স্কিম বিশদ`,
    "804ca01b219522a88d26c3002632a00e": `বিশদ ভাগ করে নেওয়ার জন্য আপনাকে ধন্যবাদ।`,
    "04b6d9b7eb73749476d958b2133f4bbe": `স্কিম আওয়ারনেস জরিপ`,
    "a5661f68fc78a5d4f15afa057fec7a9f": `আরও এগিয়ে যাওয়ার জন্য আপনাকে জরিপে আপনার বিশদ আপডেট করতে হবে।`,
    "1e4531d1dc128abbc00b48e5c7b6ef48": `আপডেট জরিপ`,
    "362e532b6ad1fda489c5909d437f02a3": `আমরা আপনার বিশদ আনছি, দয়া করে অপেক্ষা করুন ..`,
    "8a1b3c7d62e2d60d5be34d8e36a9768f": `পৃথক লগইন ফিরে`,
    "6737d50489b1cf1d4408e66b8591ca38": `আপনার স্কিম অ্যাপ্লিকেশনটিতে আপনার মুলতুবি ক্রিয়া রয়েছে। আপনার অ্যাপ্লিকেশনটি আরও এগিয়ে যেতে মুলতুবি ক্রিয়াগুলি সম্পূর্ণ করুন।`,
    "e37c99137b7396277137c1f73f8a86d5": `সিইজি ইনটিটিভ`,
    "706c7a936b977ec3c4e6603f888e3fce": `EasyGov দ্বারা চালিত`,
    "598d42810b342196884f255912974d69": `এসএল। নং নং`,
    "60e4e81c6f9c1938d77e8ded6d64643a": `স্ক্রিন রিডার`,
    "15bbb9d0bbf25e8d2978de1168c749dc": `ওয়েবসাইট`,
    "21d587949a2a2e89c958b622e244b405": `বিনামূল্যে/ বাণিজ্যিক`,
    "d5c03f7b855d442a2a7f24024cf89960": `নন ভিজ্যুয়াল ডেস্কটপ অ্যাক্সেস (এনভিডিএ)`,
    "f956e16f65a3a937e5ecfe4195d17d0d": `(বাহ্যিক ওয়েবসাইট যা একটি নতুন উইন্ডোতে খোলে)`,
    "b24ce0cd392a5b0b8dedc66c25213594": `বিনামূল্যে`,
    "c8ed8ec3dc8b21290b5d28ca56344553": `চোয়াল`,
    "b2788e1ab6be65f1c38bf7d23cd081f1": `ব্যবসায়িক`,
    "ec18aa8148f59475130ff217d693b465": `উইন্ডো-আই`,
    "d96a273ceb2b5f83d710081d9c38440a": `যেতে যেতে সিস্টেম অ্যাক্সেস`,
    "c932fd98147348de9974252a0efcc7cb": `WebAnywhere`,
    "540431e2b3234a8698b4044f15ef1fff": `পিডিএফ সামগ্রী`,
    "801ab24683a4a8c433c6eb40c48bcd9d": `ডাউনলোড`,
    "83f8a38f024082405d2fff921c2bb1d2": `অ্যাডোব অ্যাক্রোব্যাট রিডার`,
    "9d8c84ff52cfc2c2e0075e669edacffd": `শব্দ ফাইল`,
    "4db1a171aaf96723d4002cc41559ddf7": `বাহ্যিক ওয়েবসাইট যা একটি নতুন উইন্ডোতে খোলে`,
    "86c61b344697039b8a54bbef9e4959c3": `এক্সেল ভিউয়ার 2003 (2003 অবধি কোনও সংস্করণে)`,
    "04efb134468330da1430e32701160107": `এক্সেলের জন্য মাইক্রোসফ্ট অফিসের সামঞ্জস্যতা প্যাক (2007 সংস্করণের জন্য)`,
    "a385a9bb61bb694694eef5d2f54c563a": `পাওয়ারপয়েন্ট উপস্থাপনা`,
    "da67d56a74c01537a71a3ab0eda00faa": `পাওয়ারপয়েন্টের জন্য মাইক্রোসফ্ট অফিসের সামঞ্জস্যতা প্যাক (2007 সংস্করণের জন্য)`,
    "bb625547a85b98c2c9f9f4121c72cd78": `ফ্ল্যাশ সামগ্রী`,
    "ab69edd2798068928605717c6591462f": `অ্যাডোবি ফ্ল্যাশ প্লেয়ার`,
    "2c4fa0a307152ef814bbab8d4075d938": `অডিও ফাইল`,
    "da7be99fd6fac5c8a4065eb2c07cad88": `উইন্ডোজ মিডিয়া প্লেয়ার`,
    "5a5652ca26ee42fe73fdd79497cf9e21": `ওয়ার্ড ভিউয়ার (2003 অবধি কোনও সংস্করণে)`,
    "2e856241d3d995fefdb1b4b32f84a79b": `ওয়ার্ডের জন্য মাইক্রোসফ্ট অফিসের সামঞ্জস্যতা প্যাক (2007 সংস্করণের জন্য)`,
    "64a443242750513eb56695e07aedcff2": `এক্সেল ফাইল`,
    "382b0f5185773fa0f67a8ed8056c7759": `এন/এ`,
    "462c18d5b89050fb1b7f8fca1e535af8": `প্রক্রিয়াজাত`,
    "ccb26ac32c3cc7cc2f0fd1bbac3b8e2d": `আপনার প্রক্রিয়াজাত কোনও পরিষেবা নেই`,
    "fc44a0efc672761745c2e76aac6851d2": `আপনার আবেদন প্রত্যাখ্যান করা হয়েছে কারণ`,
    "7d0db380a5b95a8ba1da0bca241abda1": `এ`,
    "1252044838fd74c4e8b506ef1302a8b5": `অভিযোগ`,
    "06627e6919d21853d887d00334ee5337": `অভিযোগ ব্যবস্থাপনা`,
    "f940fbe4aec76e9784e7f15c4b92ac6d": `প্রক্রিয়াজাতকরণে আপনার কোনও অভিযোগ নেই`,
    "2290dc1500a2b4548eb3b931f2e3609a": `আপনার কোনও সংরক্ষিত অভিযোগ নেই।`,
    "b25b60dc1a5f7c17e09bc73174668d9a": `খসড়াটিতে আপনার কোনও অভিযোগ নেই।`,
    "a02c58d421087ec9519620a29d68a6ec": `আপনি এখনও কোনও অভিযোগের জন্য জমা দেননি।`,
    "b65f636dcbc018b1901e23a7c2adde90": `আরও অন্বেষণ`,
    "5c835c22429f25992b4371691ecde1a4": `জমা দেওয়া`,
    "2535f5e4e0892c73a5fad023bf3e15e2": `আমার অভিযোগ`,
    "4351cfebe4b61d8aa5efa1d020710005": `দেখুন`,
    "e9516387730903ffd7cfe3317e05e385": `এই অভিযোগটি আপনার "আমার অভিযোগ" এ সংরক্ষণ করা হয়েছে।`,
    "b70db24f2cb70fb266eb03da8f289ba5": `অভিযোগ দেখুন`,
    "646558064e10a12b1e8914e86eca3ca6": `আপনার পরিষেবা অ্যাপ্লিকেশনটিতে আপনার মুলতুবি ক্রিয়া রয়েছে। আপনার অ্যাপ্লিকেশনটি আরও এগিয়ে যেতে মুলতুবি ক্রিয়াগুলি সম্পূর্ণ করুন।`,
    "d2841171ea05a48fa5463b3f7c7c92d7": `আপনার অভিযোগ অ্যাপ্লিকেশনটিতে আপনার মুলতুবি ক্রিয়া রয়েছে। আপনার অ্যাপ্লিকেশনটি আরও এগিয়ে যেতে মুলতুবি ক্রিয়াগুলি সম্পূর্ণ করুন।`,
    "9279f823b01f13b7a8c04688289543cc": `আপনার অ্যাপ্লিকেশনটি আপনার 'খসড়া অভিযোগ' সফলভাবে সংরক্ষণ করেছে।`,
    "af481c93dcae73d73afe9927076c8a09": `আপনি ইতিমধ্যে এই অভিযোগগুলির জন্য আবেদন করেছেন। আপনার প্রয়োগ করা অভিযোগগুলি থেকে দয়া করে আপনার আবেদনের স্থিতি একই জন্য পরীক্ষা করুন।`,
    "ce972231a0faf31791c7ac0bf0d1da84": `আপনি যদি কোনও অর্থ প্রদান করেন তবে আপনার পরিমাণ ফেরত দেওয়া হবে না।`,
    "2ffef02ca513f8bbadbaf130a23097de": `আপনার অ্যাপ্লিকেশনটি আপনার "খসড়া অভিযোগগুলি" সফলভাবে সংরক্ষণ করা হয়েছে`,
    "e55e425dbaf6b6449940eac8ea749092": `দয়া করে কেবল বর্ণমালা লিখুন।`,
    "64ff9e3809686d4ea3253a631cae4761": `দয়া করে 2 দশমিক স্থানে সঠিক একটি মান লিখুন।`,
    "7ed0ef23d527c2876015161e422f49ce": `কন্নড় ভাষায় আপনার নাম লিখুন দয়া করে।`,
    "60ae728544ab5cb25dba9ff4b8f6aa95": `সেবা সিন্ধুর জন্য আপনার আবেদন রেফারেন্স নম্বরটি হ'ল`,
    "2c0ed1f2f9239dbca46beca549190e00": `আধারকে ঠিকানার প্রমাণ হিসাবে নেওয়া হবে, দয়া করে আধারের মতো জেলাটি নির্বাচন করুন।`,
    "8355a53d777c5ac84a37970b5158aba5": `ত্রুটি 205: আধারে আপনার নাম রাজস্ব বিভাগ দ্বারা জারি করা বর্ণ বা আয়ের শংসাপত্রের বিশদগুলির সাথে মেলে না।`,
    "601f77894e71ae7d4922eda713dda66c": `ত্রুটি 207: আপনার বর্ণ ও আয়ের শংসাপত্রের মেয়াদ শেষ হয়ে গেছে।`,
    "9182b3e8dc7965f2775a204095f4c992": `আপনার দ্বারা প্রবেশ করা OTP ভুল। সঠিক OTP দিয়ে আবার চেষ্টা করুন।`,
    "6e0fe1003e84e34da97a6c4c9040b31b": `অনগ্রসর শ্রেণীর কল্যাণ দপ্তরের আরিভু শিক্ষাগত ঋণ প্রকল্পের (নবায়ন) আবেদন গ্রহণের শেষ তারিখ 31 মার্চ 2022 পর্যন্ত বাড়ানো হয়েছে।`,
    "69d3a9de2fc1407f59e8936ca0c525a0": `দুঃখিত, একটি ত্রুটি ঘটেছে. অনুগ্রহ করে একটু পরে আবার চেষ্টা করুন.`,
    "7953c16d691da03c899391a39412fc9b": `আপনার স্কিম অ্যাপ্লিকেশনটিতে আপনার মুলতুবি ক্রিয়া রয়েছে। আপনার অ্যাপ্লিকেশনটি আরও এগিয়ে যাওয়ার জন্য <i> {{startDate}} </i> এর পরে মুলতুবি ক্রিয়াগুলি সম্পূর্ণ করুন।`,
    "f4f4366332ac6949882de59c81b08de9": `আপনার স্কিম অ্যাপ্লিকেশনটিতে আপনার মুলতুবি ক্রিয়া রয়েছে। আপনার অ্যাপ্লিকেশনটি আরও এগিয়ে যেতে <i> {{startDate}} </i> থেকে <i>{{endDate}} </i> অবধি মুলতুবি ক্রিয়াগুলি সম্পূর্ণ করুন।`,
    "77b43c24220ee608380031b72f8017ce": `সেবা বিভাগ`,
    "e0e54c374f8b39a1520766e5924af100": `আপনার পরিবার এবং আপনার জন্য স্কিম, পরিষেবা এবং জীবিকা জানার একটি প্ল্যাটফর্ম`,
    "d56f6b073f573237a6c4aed91589e982": `জীবিকা`,
    "e5930fadbc620e368c92561f381ead58": "আপনার জন্য জীবিকার সুযোগ",
    "192adf39ebd850e3e2e30971e885dc6d": `এই মুহুর্তে আপনার রাজ্যে কোনও জীবিকার সুযোগ কনফিগার করা নেই, আমরা ক্রমাগত আপডেট করছি, অনুগ্রহ করে আমাদের সাথে আবার চেক করুন`,
    "TM_Alt51": "সংযুক্তি(গুলি)",
    "TM_Alt51": "अनुलग्नक(ओं)",
    "TM_Hea1": "মোট পরিমাণ",
    "TM_Hea2": "সারসংক্ষেপ",
    "TM_Sub56": "মোট",
    "TM_But42": "বেতন",
    "TM_Err34": "পেমেন্ট ব্যর্থতার কারণে আপনার আবেদন জমা দেওয়া যায়নি।",  
    "TM_Rec55": "আপনার পেমেন্টের স্থিতি নিশ্চিত করতে সময় নিচ্ছে৷ আমরা ক্রমাগত আপনার ব্যাঙ্কের সাথে চেক করছি৷ অনুগ্রহ করে পরবর্তী 48 ঘন্টার জন্য কোনো অতিরিক্ত পেমেন্ট করা এড়িয়ে চলুন।", 
    "TM_But13": "ফিরে যান",
    "TM_Alt124":"অনুগ্রহ করে অপেক্ষা করুন",
    "TM_Mod41":"পেমেন্ট পেন্ডিং",
    "TM_Mod40":"পেমেন্ট সফল",
    "TM_Alt72":"পেমেন্ট ব্যর্থ হয়েছে",
    "TM_Pag48":"সাহায্য এবং সহযোগিতা",
    "TM_Con33":"ধন্যবাদ, আপনার আবেদন সফলভাবে জমা দেওয়া হয়েছে।",
    "TM_Rec59": "এই পরিষেবার জন্য আপনার শেষ পেমেন্ট মুলতুবি অবস্থায় আছে। এটি সম্পূর্ণ হলে আমরা আপনাকে জানিয়ে দেব। ইতিমধ্যে, আমাদের অন্যান্য পরিষেবাগুলি নির্দ্বিধায় এক্সপ্লোর করুন।",
    "TM_Rec60":"আপনার ধৈর্য্যের জন্য আপনাকে ধন্যবাদ।",
    "TM_Rec61":"পেজটি স্বয়ংক্রিয়ভাবে টাইম আউট হয়ে যাবে",
    "TM_Alt165":"পেমেন্ট করতে আপনার মোবাইল ডিভাইসের ইউপিআই অ্যাপে কিউআর কোড স্ক্যান করুন ",
    "TM_Pag39": "লাভার্থী",
    "TM_Alt166":"পেমেন্ট প্রত্যাখ্যাত",
    "TM_Err39":"পেমেন্ট প্রত্যাখ্যান করার কারণে আপনার আবেদন জমা দেওয়া যায়নি।",
    "cc4cbfe72c596683881033f36eac3e2f":"আমরা আপনার ব্যাঙ্কে আপনার পেমেন্ট নিশ্চিত করা পর্যন্ত অপেক্ষা করুন। এই প্রক্রিয়া একটি মুহূর্ত নিতে পারে।",
    "7d54c690a3a707e692cceb6978e24bc1": "ফি ব্রেকআপ",
}
export default language;