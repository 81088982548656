import storage from "./encryptData";
import keys from "../common/keys";
import evaluateRule from './evaluateRule';

const handleDependentFields = (field, profileList) => {
  
  let isRulePassed = false
  let ruleList = field.parentFieldValue.split("|/|")
  for(let i=0; i<ruleList.length; i++){
    let rule = ruleList[i]
    if(ruleList.length > 1){
      if(i===0){
        rule = rule + ")"
      } else if(i === ruleList.length - 1){
        rule =  "(" + rule
      }
    }
    let isParentRulePassed = evaluateRule(rule, profileList, field).isRulePassed
    isRulePassed = isRulePassed || isParentRulePassed
  }
  return isRulePassed;
}
export default handleDependentFields;
