// import keys from '../../../common/keys'
// import storage from '../../../utility/encryptData'

const initState = {
    data: "",
  };

const saveUserFamilyProfileReducer = (state = initState, action) => {
  switch (action.type) {
    case "SAVE_USER_FAMILY_PROFILE":
      return {
        ...state,
        data: action.payload,
      };

    case "RESET_SAVE_USER_FAMILY_PROFILE":
      return {
        ...state,
        data: "",
      };

    default:
      return state;
  }
};

export default saveUserFamilyProfileReducer;
