import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../Non-Eligible-Scheme/noneligible.css";
import keys from "../../../common/keys";
import storage from "../../../utility/encryptData";
import getStaticText from "../../../config";
import {noInternet} from './noInternet'

class NoInternet extends React.Component {
  constructor(props)
  {
    super(props);
    this.staticText = storage.getItemValue(keys.LANGUAGE_OBJ)
      ? getStaticText(JSON.parse(storage.getItemValue(keys.LANGUAGE_OBJ)).code)
      : getStaticText("en");
  }
  render() {
    return (
      <Container fluid className="padding-0">

        <Row className="padding-8 padding-16 mx-0">

          <Col className="px-0 text-center py-lg-4">
            <img
              src={`${noInternet.base64}`} alt="jpg"
              alt="NoInternet"
              className="noInternet text-center mt-lg-4 "
            />
              <p className="text-heading-confirmation text-danger mt-lg-4" style={{fontSize:"25px"}}>
                {this.staticText["548899be111ad732b37786783dfbd6d8"]}
              </p>
              <p className="non-eligible-text text-danger">
                {this.staticText["5c3a1593ca1d34226359df44f3d45f2f"]}
              </p>
          </Col>
        </Row>
        {/* <Row className="padding-8 padding-16 mx-0 mt-lg-3">
          <Col xs={12} lg={3} md={4} sm={6} className="px-0 margin-center text-center margin-lg-2-bottom">
            <div className="btn---lg non-shadow-button">
              <Button
                flat
                label= "Reload Again"
              />
            </div>
          </Col>
        </Row> */}
      </Container>
    );
  }
}

export default NoInternet;
