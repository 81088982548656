import storage from '../../utility/encryptData'
import keys from '../../common/keys'
import handlePossibleFieldValue from '../../utility/handlePossibleFieldValue'
import handleDependentFields from '../../utility/handleDependentFields'

const initState = {
    data: "",
  };

const saveUserProfileReducer = (state = initState, action) => {
  switch (action.type) {
    case "SAVE_USER_PROFILE":
      if(action.payload && action.payload.sources){
        let sourceObj = action.payload.sources.find( source => source.shortName === "easygov")
        let groups = sourceObj.groups
        let currentActiveProfile = JSON.parse(storage.getItemValue(keys.ACTIVE_PROFILE))
        let profileLength = 0, unansweredFields = 0
        let profileList = []
        let userProfile={}
        groups.map( profile => {
          profileLength = profileLength + profile.userProfiles.length
          unansweredFields = unansweredFields + profile.userProfiles.filter( item => !item.hasOwnProperty('fieldValue')).length
          let profilePercentage = Math.ceil(((profileLength - unansweredFields) / profileLength)*100)
          if(currentActiveProfile){
            currentActiveProfile['profilePercentage'] = profilePercentage
            storage.setItemValue(keys.ACTIVE_PROFILE, JSON.stringify(currentActiveProfile))
          }
          if(profilePercentage > 0){
            storage.setItemValue(keys.IS_SCHEME_FIRST_VISIT, true)
          } else {
            storage.removeItemValue(keys.IS_SCHEME_FIRST_VISIT)
          }

          let childFields = profile.userProfiles.filter(profile => (
            (profile.hasOwnProperty('parentFieldValue') && profile.parentFieldValue.trim() !== "") ||
            (profile.hasOwnProperty('derivedValue') && profile.derivedValue.trim() !== "") ||
            (profile.hasOwnProperty('possibleValues') && profile.possibleValues.trim() !== "")) && profile.hasOwnProperty('fieldValue'))
          childFields.map( field => {
            let isRulePassed = false
            if(field.hasOwnProperty("parentFieldValue") && field.parentFieldValue.trim() !== ""){
              isRulePassed = handleDependentFields(field, groups)
              if(!isRulePassed){
                field.isDisplayedToUser = false
                profile.userProfiles.splice(profile.userProfiles.indexOf(field), 1)
                let subChildFields = childFields.filter(item => item.hasOwnProperty('parentFieldValue') && item.parentFieldValue.trim() !=="" && item.parentFieldValue.includes(field.fieldShortName))
                subChildFields.map(subChild => {
                  subChild.isDisplayedToUser = false
                  profile.userProfiles.splice(profile.userProfiles.indexOf(subChild), 1)
                })
              }
            }
            if(field.hasOwnProperty("possibleValues") && field.possibleValues.trim() !== "" &&
              ((isRulePassed && field.hasOwnProperty("parentFieldValue")) || (field.hasOwnProperty("parentFieldValue") && field.parentFieldValue.trim() === "") ||
              !field.hasOwnProperty("parentFieldValue"))) {
              let isPossibleRulePassed = false
              let optionsList = handlePossibleFieldValue(field, groups)
              if(optionsList){
                optionsList = optionsList.split('|')
                let fieldOptions = field.fieldValueOptions.filter(option => optionsList.includes(option.hasOwnProperty("untranslated_value") ? option.untranslated_value : option.value))
                if(fieldOptions.length > 0){
                  if(fieldOptions.find(item => field.fieldValue.includes(item.value))){
                    field['fieldOptions'] = [...field.fieldValueOptions]
                    field.fieldValueOptions = [...fieldOptions]
                  }else {
                    field.isDisplayedToUser = false
                    profile.userProfiles.splice(profile.userProfiles.indexOf(field), 1)
                    let subChildFields = childFields.filter(item => item.hasOwnProperty('parentFieldValue') && item.parentFieldValue.trim() !=="" && item.parentFieldValue.includes(field.fieldShortName))
                    subChildFields.map(subChild => {
                      subChild.isDisplayedToUser = false
                      profile.userProfiles.splice(profile.userProfiles.indexOf(subChild), 1)
                    })
                  }
                } else {
                  field.isDisplayedToUser = false
                  profile.userProfiles.splice(profile.userProfiles.indexOf(field), 1)
                  let subChildFields = childFields.filter(item => item.hasOwnProperty('parentFieldValue') && item.parentFieldValue.trim() !=="" && item.parentFieldValue.includes(field.fieldShortName))
                  subChildFields.map(subChild => {
                    subChild.isDisplayedToUser = false
                    profile.userProfiles.splice(profile.userProfiles.indexOf(subChild), 1)
                  })
                }
              } else {
                field.isDisplayedToUser = false
                profile.userProfiles.splice(profile.userProfiles.indexOf(field), 1)
                let subChildFields = childFields.filter(item => item.hasOwnProperty('parentFieldValue') && item.parentFieldValue.trim() !=="" && item.parentFieldValue.includes(field.fieldShortName))
                subChildFields.map(subChild => {
                  subChild.isDisplayedToUser = false
                  profile.userProfiles.splice(profile.userProfiles.indexOf(subChild), 1)
                })
              }
            }
          })
          let answeredFields = []
          answeredFields = profile.userProfiles.filter( item => item.hasOwnProperty('fieldValue'))
          profileList = [...profileList, ...answeredFields]
          if(profileList.length>0){
              userProfile['userProfile'] = profileList
              storage.setItemValue(keys.USER_PROFILE, JSON.stringify(userProfile));
          }
        })
      }
      return {
        ...state,
        data: action.payload,
      };

    case "RESET_USER_PROFILE":
      return {
        ...state,
        data: "",
      };

    default:
      return state;
  }
};

export default saveUserProfileReducer;
