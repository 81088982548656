const initState = {
    data: "",
  };

  const updateLangAndGeoReducer = (state = initState, action) => {
    switch (action.type) {
      case "UPDATE_LANG_GEO":
        return {
          ...state,
          data: action.payload,
        };

      default:
        return state;
    }
  };

  export default updateLangAndGeoReducer;
