const initState = {
    data: "",
  };

  const getSavedProductReducer = (state = initState, action) => {
    switch (action.type) {
      case "GET_SAVE_PRODUCT":
        return {
          ...state,
          data: action.payload,
        };

      case "RESET_SAVED_PRODUCT":
        return {
          ...state,
          data: "",
        };

      default:
        return state;
    }
  };

  export default getSavedProductReducer;
