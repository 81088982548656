const initState = {
    data: "",
  };
  
  const loginWithOtpReducer = (state = initState, action) => {
    switch (action.type) {
      case "LOGIN_WITH_OTP":
        return {
          ...state,
          data: action.payload,
        };
      case "RESET_LOGIN_WITH_OTP":
        return {
          ...state,
          data: "",
        };
      default:
        return state;
    }
  };
  
  export default loginWithOtpReducer;
  