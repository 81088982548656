const initState = {
  data: "",
};

const getSiteContentReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_SITE_CONTENT":
      return {
        ...state,
        data: action.payload,
      };

    case "RESET_SITE_CONTENT":
      return {
        ...state,
        data: undefined,
      };

    default:
      return state;
  }
};

export default getSiteContentReducer;
