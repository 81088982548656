import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import storage from "../utility/encryptData";
import keys from "../common/keys";
import history from "../history";
// IMPORT COMPONENT AFTER THIS LINE

const Home = React.lazy(() => import("./MOBILE/Home"));
const JobsHome = React.lazy(() => import("./MOBILE/JobsHome/"));
const Language = React.lazy(() => import("./MOBILE/Language/"));
const Location = React.lazy(() => import("./MOBILE/Location/"));
const Survey = React.lazy(() => import("./MOBILE/Survey"));
const JobSurvey = React.lazy(() => import("./MOBILE/JobSurvey"));
const IntentScreen = React.lazy(() => import("./MOBILE/Survey/IntentScreen"));
const JobsIntentScreen = React.lazy(() => import("./MOBILE/JobSurvey/JobsIntentScreen"));
const SchemeSpecificSurvey = React.lazy(() =>import("./MOBILE/Survey/SchemeSpecificSurvey"));
const JobSpecificSurvey = React.lazy(() =>import("./MOBILE/JobSurvey/jobSpecificSurvey"));
const IntentSurvey = React.lazy(() =>import("./MOBILE/Survey/IntentSurvey"));
const ApplyDetails = React.lazy(() => import("./MOBILE/Applydetails"));
const JobDetails = React.lazy(() => import("./MOBILE/Applydetails/jobDetails"));
const About = React.lazy(() => import("./AboutUs/index"));
// const About = React.lazy(() => import("./MOBILE/Aboutus"));
const TypeOfKyc = React.lazy(() => import("./MOBILE/VerifyDetails/typeOfKyc"));
const FAQ = React.lazy(() => import("./MOBILE/FAQ"));
const PrivacyPolicy = React.lazy(() => import("./MOBILE/PrivacyPolicy"));
const TermsNConditions = React.lazy(() => import("./MOBILE/TermsNConditions"));
const Services = React.lazy(() => import("./MOBILE/Services"));
const Grievance =React.lazy(() => import("./MOBILE/Grievance"));
const HomeScreen = React.lazy(() => import("./MOBILE/HomeScreen"));
const Search = React.lazy(() => import("./MOBILE/Search"));
const NonEligibleScheme = React.lazy(() => import("./MOBILE/Non-Eligible-Scheme"));
const NoInternet = React.lazy(() => import("./MOBILE/NoInternet"));
const Menu = React.lazy(() => import("./MOBILE/Menu"));
const SavedScheme = React.lazy(() => import("./MOBILE/Home/savedScheme"));
const SavedJob=React.lazy(() => import("./MOBILE/JobsHome/savedJobs"));
const JobDetailsSave=React.lazy(() => import("./MOBILE/JobsHome/jobDetailsSave"));
const SavedServices = React.lazy(() => import("./MOBILE/Home/savedServices"));
const SavedGrievance = React.lazy(() => import("./MOBILE/Grievance/savedGrievance"));
const ProactiveScheme = React.lazy(() => import("./MOBILE/Home/proactiveScheme"));
const SurveyDetails = React.lazy(() => import("./MOBILE/Survey/SurveyDetails"));
const JobSurveyDetails=React.lazy(() => import("./MOBILE/JobSurvey/JobSurveyDetails"));
const ApplyDetailsSave = React.lazy(() => import("./MOBILE/Home/applyDetailsSave"));
const CreateProfile = React.lazy(() => import("./MOBILE/createProfile"));
const ManageProfile = React.lazy(() => import("./MOBILE/Menu/manageProfile"));
const ProfileDetails = React.lazy(() => import("./MOBILE/Menu/details"));
const Main = React.lazy(() => import("./MOBILE/Main"));
const SignUp = React.lazy(() => import("./MOBILE/SignUp"));
const SignIn = React.lazy(() => import("./MOBILE/SignIn"));
const Verification = React.lazy(() => import("./MOBILE/OTPVerification"));
const SetPassword = React.lazy(() => import("./MOBILE/SetPassword"));
const ForgotPassword = React.lazy(() => import("./MOBILE/ForgotPassword"));
const SchemeNotForYou = React.lazy(() => import("./MOBILE/Survey/SchemeNotForYou"));
const Notification = React.lazy(() => import("./MOBILE/Notifications"));
const ProfileNotification = React.lazy(() => import("./MOBILE/Notification"));
const ProfileManage = React.lazy(() => import("./MOBILE/ManageProfile/index"));
const VerifyDetails = React.lazy(() => import("./MOBILE/VerifyDetails"));
const VerificationDetails = React.lazy(() => import("./MOBILE/Verification"));
const FamilyDetails = React.lazy(() => import("./MOBILE/ManageProfile/details"));
const LoginWithOTP= React.lazy(() => import("./MOBILE/SignIn/SignOtp"));
const KycManual= React.lazy(() => import("./MOBILE/KYC/kycManual"));
const KycManualRevamp = React.lazy(() => import("./MOBILE/KYC/kycManualRevamp"));
const KycHome= React.lazy(() => import("./MOBILE/KYC"));
const ApplicationDetailsAction= React.lazy(() => import("./MOBILE/Application/applicationAction"));
const ApplicationDetailsOneData = React.lazy(() => import("./MOBILE/Application/applicationOneData"));
const ApplicationRevamp = React.lazy(() => import("./MOBILE/Application/applicationRevamp"));
const MySavedSchemes = React.lazy(() => import("./MOBILE/Schemes/savedSchemes"));
const ReviewDetailScreen= React.lazy(() => import("./MOBILE/Application/reviewScreen"));
const SchemeSurveyDetails= React.lazy(() => import("./MOBILE/SchemeSurvey"));
const ApplicationStatus= React.lazy(() => import("./MOBILE/Schemes/applicationStatus"));
const ConfirmSurveyDetails= React.lazy(() => import('./MOBILE/Survey/ConfirmSurveyDetails'));
const NotEligibleJobs = React.lazy(() => import("./MOBILE/Non-Eligible-Scheme/noneligibleForJobs"));
const History = React.lazy(() => import("./MOBILE/History"));
const ServicesCategories = React.lazy(() => import("./MOBILE/Services/Servicescategories"));
const GrievanceCategories = React.lazy(() => import("./MOBILE/Grievance/GrievanceCategories"));
const ApplicationFailure = React.lazy(() => import("./MOBILE/Application/applicationFailure"));
const ManageProfileDetails = React.lazy(() => import("./MOBILE/ManageProfile/ManageProfileDetails"));
const ApplicationStatusNew=  React.lazy(() => import("./MOBILE/Application/applicationStatus"));

const SchemeAwareness=  React.lazy(() => import("./MOBILE/SchemeAwareness"));

const AwarenessSurvey=  React.lazy(() => import("./MOBILE/SchemeAwareness/awarenessSurvey"));



const RoutesWeb = withRouter(({ location }) => {
  // When user clicks on back after deleting account.
  // if(storage.getItemValue(keys.LANGUAGE_OBJ) === null && window.location.pathname !== "/"){
  //   history.replace("/")
  // }
  // if(storage.getItemValue(keys.LANGUAGE_OBJ) === null && window.location.pathname !== "/"){
  //   history.replace("/")
  // } else

  if(storage.getItemValue(keys.LANGUAGE_OBJ) === null && window.location.pathname !== "/" && window.outerWidth<600 && window.location.pathname!=="/privacyPolicy"){
    history.replace("/")
  } else if(storage.getItemValue(keys.GEOGRAPHY_ID) === null && window.location.pathname !== "/selectLocation"  && storage.getItemValue(keys.LANGUAGE_OBJ) && window.outerWidth<600 && window.location.pathname!=="/privacyPolicy"){
    history.replace("/selectLocation")
  }

    if(storage.getItemValue(keys.USER_TYPE) && storage.getItemValue(keys.USER_TYPE) === "business"){
        let settingsData = storage.getItemValue(keys.SETTINGS_DATA)
        if(settingsData){
            settingsData = JSON.parse(settingsData)
            console.log("settingsData", settingsData);
            if(window.location.pathname === "/" && settingsData.isGtoBSchemeEnabled === "false"){
                history.replace('/services')
            }
        }
    }

  const routes = [

      {
        component:
        (storage.getItemValue(keys.IS_FIRST_TIME_USER) === null && window.outerWidth < 600)
        ? HomeScreen
        :(storage.getItemValue(keys.LANGUAGE_OBJ) === null)
          ? Language
          : (storage.getItemValue(keys.IS_SCHEME_FIRST_VISIT) === null)
          ? Survey
          : Home,
        path: "/"
      },

      {
        component: (storage.getItemValue(keys.IS_SCHEME_FIRST_VISIT) === null)
        ? JobSurvey
        : JobsHome,
        path: "/job-home"
      },

      {
        component:TypeOfKyc,
        path: "/typeOfKyc",
      },

    {
      component: Language,
      path: "/selectLanguage",
    },

    {
      component: JobSurvey,
      path: "/job-survey"
    },

     {
      component: NotEligibleJobs,
      path: "/noneligibleForJobs"
    },

    {
      component: Location,
      path: "/selectLocation",
    },

    {
      component: Survey,
      path: "/survey",
    },

    {
      component: IntentScreen,
      path: "/qualifiedSchemes",
    },
    {
      component: JobsIntentScreen,
      path: "/qualifiedJobs"
    },

    {
      component: SchemeSpecificSurvey,
      path: "/specificSurvey",
    },
    {
      component: JobSpecificSurvey,
      path: "/jobSpecificSurvey"
    },

    {
      component: Home,
      path: "/home",
    },

    {
      component: IntentSurvey,
      path: "/intentSurvey",
    },

    {
      component: ApplyDetails,
      path: "/applydetails",
    },

    {
      component: JobDetails,
      path: "/jobDetails"
    },

    {
      component: ApplyDetailsSave,
      path: "/applydetailsSave",
    },

    {
      component: LoginWithOTP,
      path: "/loginOtp",
    },

     {
      component: JobDetailsSave,
      path: "/jobdetailsSave"
    },

    {
      component: About,
      path: "/about",
    },

    {
      component: FAQ,
      path: "/faq",
    },
    {
      component: TermsNConditions,
      path: "/tnC",
    },
    {
      component: PrivacyPolicy,
      path: "/privacyPolicy",
    },

    {
      component: Services,
      path: "/services",
    },
     {
      component: Grievance,
      path: "/grievance",
    },
    {
      component: Search,
      path: "/search",
    },
    {
      component: NonEligibleScheme,
      path: "/noneligiblescheme",
    },
    {
      component: Menu,
      path: "/menu",
    },
    {
      component: SavedScheme,
      path: "/savedscheme",
    },
    {
      component: ProactiveScheme,
      path: "/proactiveScheme",
    },
    {
      component: SurveyDetails,
      path: "/surveydetails",
    },
     {
      component: JobSurveyDetails,
      path: "/jobSurveydetails"
    },
     {
      component: SavedJob,
      path: "/savedJobs"
    },
    {
      component: SavedServices,
      path: "/savedservices"
    },
    {
      component: SavedGrievance,
      path: "/savedGrievance"
    },
    {
      component: Main,
      path: "/main",
    },
    {
      component: SignUp,
      path: "/signUp",
    },
    {
      component: SignIn,
      path: "/signIn",
    },
    {
      component: Verification,
      path: "/otpVerification",
    },
    {
      component: SetPassword,
      path: "/setPassword",
    },
    {
      component: ForgotPassword,
      path: "/forgotPassword",
    },
    {
      component: CreateProfile,
      path: "/createProfile",
    },
    {
      component: ManageProfile,
      path: "/manageProfile",
    },
    {
      component: ProfileDetails,
      path: "/profileDetails",
    },
    {
      component: SchemeNotForYou,
      path: "/nonEligibleSchemes",
    },
    {
      component: Notification,
      path: "/notification",
    },

    {
      component: ProfileNotification,
      path: "/profile-notification",
    },


    {
      component: ProfileManage,
      path: "/profileManage",
    },
    {
      component: FamilyDetails,
      path: "/familyDetails",
    },
    {
      component: NoInternet,
      path: "/noInternet",
    },
    {
      component: KycManual,
      path: "/kycManual",
    },
    {
      component: KycHome,
      path: "/kyc",
    },
    {
      component: KycManualRevamp,
      path: "/kycManualRevamp",
    },
    {
      component: ApplicationDetailsOneData,
      path: "/applicationDetailsOneData",
    },
    {
      component: ApplicationRevamp,
      path: "/applicationRevamp",
    },
    {
      component: ApplicationFailure,
      path: "/applicationFailed",
    },
    {
      component: ReviewDetailScreen,
      path: "/reviewScreen",
    },
    {
      component: MySavedSchemes,
      path: "/mysavedSchemes",
    },
    {
      component: SchemeSurveyDetails,
      path: "/schemeSurveyDetails",
    },
    {
    component:VerifyDetails,
      path: "/verifyDetails",
    },
    {
      component:VerificationDetails,
      path: "/verificationDetails",
    },
    {
      component:ApplicationStatus,
      path: "/applicationStatus",
    },
    {
      component: ApplicationStatusNew,
      path: "/applicationFailed",
    },
    {
      component:ConfirmSurveyDetails,
      path: "/confirmSurveyDetails",
    },
    {
      component:History,
      path: "/history",
    },
    {
      component:ServicesCategories,
      path: "/servicesCategories",
    },
    {
      component:GrievanceCategories,
      path: "/grievanceCategories",
    },
    {
      component:ManageProfileDetails,
      path: "/manageProfileDetails",
    },
    {
      component: ApplicationDetailsAction,
      path: "/applicationDetailsAction",
    },
    {
      component: SchemeAwareness,
      path: "/schemeAwareness",
    },
    {
      component: AwarenessSurvey,
      path: "/awarenessSurvey",
    },
  ];


  return (
    <Switch location={location}>
      {routes.map((route) => {
        return (
          <Route
            exact
            key={route.path}
            path={route.path}
            component={route.component}
          />
        );
      })}
    </Switch>
  );
});

export default RoutesWeb;
