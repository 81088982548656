const initState = {
    data: "",
  };

  const getFieldBySourceReducer = (state = initState, action) => {
    switch (action.type) {
      case "GET_FIELD_BY_SOURCE":
        return {
          ...state,
          data: action.payload,
        };

      case "RESET_FIELDS_BY_SOURCE":
        return {
          ...state,
          data: undefined,
        };

      default:
        return state;
    }
  };

  export default getFieldBySourceReducer;
