const initState = {
    data: "",
  };
  
  const removeSavedProductReducer = (state = initState, action) => {
    switch (action.type) {
      case "REMOVE_SAVED_PRODUCT":
        return {
          ...state,
          data: action.payload,
        };

      case "RESET_REMOVE_SAVED_PRODUCT":
        return {
          ...state,
          data: "",
        };    
  
      default:
        return state;
    }
  };
  
  export default removeSavedProductReducer;
  