const initState = {
    data: "",
  };

  const deleteUserFamilyMemberReducer = (state = initState, action) => {
    switch (action.type) {
      case "DELETE_USER_FAMILY_MEMBER":
        return {
          ...state,
          data: action.payload,
        };

      case "RESET_DELETE_USER_FAMILY_MEMBER":
        return {
          ...state,
          data: undefined,
        };

      default:
        return state;
    }
  };

  export default deleteUserFamilyMemberReducer;
