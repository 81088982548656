const initState = {
  data: "",
};

const getSaltReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_SALT":
      return {
        ...state,
        data: action.payload,
      };

      case "REST_GET_SALT":
        return {
          ...state,
          data: "",
        };

    default:
      return state;
  }
};

export default getSaltReducer;
