const initState = {
    data: "",
  };

  const printApplicationReducer = (state = initState, action) => {
    switch (action.type) {
      case "PRINT_APPLICATION":
        return {
          ...state,
          data: action.payload,
        };

        case "PRINT_APPLICATION_RESET":
          return {
            ...state,
            data: "",
          };

      default:
        return state;
    }
  };

  export default printApplicationReducer;
