import storage from '../../utility/encryptData'
import keys from '../../common/keys'
const initState = {
  data: [],
};
 const evaluateRule = (rule, profileList) => {
  let isRulePassed = true
  let percentageMatched = 0
  let fieldsMatched = 0
    try {
  profileList.map((question) => {
    let shortNameString = "{{" + question.fieldShortName + "}}";
    var newNameString = shortNameString.replace(/[(]/g,"\\(").replace(/[)]/g,"\\)")
    var regExp = new RegExp(newNameString, "g");
    if(question.hasOwnProperty('selectedValue')){
      rule = rule.replace(regExp, question.selectedValue);
    } else if(question.hasOwnProperty('fieldValue')){
      if(question.hasOwnProperty('untranslated_fieldValue')){
        rule = rule.replace(regExp, question.untranslated_fieldValue);
      } else {
        rule = rule.replace(regExp, question.fieldValue);
      }
    }
  });

  var splittedRule = rule.split("&&");
  for (let i = 0; i < splittedRule.length; i++) {
    if(splittedRule[i].includes("((")){
      while(splittedRule[i].includes("((")){
        splittedRule[i] = clearInitialBrackets(splittedRule[i])
      }
    }
    if(splittedRule[i].includes("))")){
      while(splittedRule[i].includes("))")){
        splittedRule[i] = clearEndingBrackets(splittedRule[i])
      }
    }
    try {
      if(splittedRule[i].includes("'{{")) {
        splittedRule[i] = false
      } else {
        splittedRule[i] = eval(splittedRule[i])
        if(splittedRule[i]){
          fieldsMatched++;
        }
      }
          // splittedRule[i] = splittedRule[i].includes("'{{")
          // ? true
          // : eval(splittedRule[i]);
        } catch(err){
        }
        isRulePassed = isRulePassed && splittedRule[i];
      }
      if(fieldsMatched > 0){
        percentageMatched = Math.ceil((fieldsMatched / profileList.length)*100)
      }
      let obj = {
        "value" : isRulePassed,
        "percentageMatched" : percentageMatched
      }
      // return isRulePassed;
      return obj;
    } catch (e) {
    let obj = {
        "value" : false,
        "percentageMatched" : 0
      }
       return obj;
    }

}

const clearInitialBrackets = (string) => {
  return string.replace("(", "");
}

const clearEndingBrackets = (string) => {
  let a = reverseString(string);
  a = a.replace(")", "");
  return reverseString(a);
}

const reverseString = (str) => {
  const revArray = [];
  const length = str.length - 1;
  for (let i = length; i >= 0; i--) {
    revArray.push(str[i]);
  }
  return revArray.join("");
}
const getUserNotificationsReducer = (state = initState, action) => {
  let filterData = [];
  if (action.type === 'GET_USER_NOTIFICATIONS') {
    let userProfile = JSON.parse(storage.getItemValue(keys.USER_PROFILE)) || [];
     filterData = action.payload.filter(word => {
        let obj = evaluateRule(word.broadcastRule, userProfile.userProfile);
      if (word.notificationType !== "broadcast") {
         return true
       } else {
         return (obj.value==true && word.notificationType === "broadcast")?true:false;
       }
     }).map(el => {
           el.isSelected = false;
        return el;
     });

  }
  switch (action.type) {
    case "GET_USER_NOTIFICATIONS":
      return {
        ...state,
        data: filterData,
      };

      case "REST_USER_NOTIFICATIONS":
        return {
          ...state,
          data: [],
        };

    default:
      return state;
  }
};

export default getUserNotificationsReducer;
