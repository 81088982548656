const initState = {
    data: "",
  };

  const userProfileDocumentReducer = (state = initState, action) => {
    switch (action.type) {
      case "USER_PROFILE_DOCUMENT":
        return {
          ...state,
          data: action.payload,
        };

      case "RESET_USER_PROFILE_DOCUMENT":
        return {
          ...state,
          data: "",
        };

      default:
        return state;
    }
  };

  export default userProfileDocumentReducer;
