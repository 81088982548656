const initState = {
    data: "",
  };
  
  const getApplicationFormsReducer = (state = initState, action) => {
    switch (action.type) {
      case "GET_APPLICATION_FORMS":
        return {
          ...state,
          data: action.payload,
        };
  
      case "RESET_GET_APPLICATION_FORMS":
        return {
          ...state,
          data: "",
        };
  
      default:
        return state;
    }
  };
  
  export default getApplicationFormsReducer;
  