const initState = {
  data: "",
};

const languageReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_LANGUAGE":
      return {
        ...state,
        data: action.payload,
      };

    case "GET_LANGUAGE_RESET":
    return {
      ...state,
      data: undefined,
    };   

    default:
      return state;
  }
};

export default languageReducer;
