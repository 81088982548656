const initState = {
  data: "",
};

const getExternalProfileReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_EXTERNAL_PROFILE":
      return {
        ...state,
        data: action.payload,
      };

    case "RESET_GET_EXTERNAL_PROFILE":
      return {
        ...state,
        data: "",
      };
    
    case "HANDLE_ERROR_EXTERNAL_PROFILE":
        return {
          ...state,
          data: action.payload
        };

    default:
      return state;
  }
};

export default getExternalProfileReducer;
