const initState = {
  data: "",
};

const signInReducer = (state = initState, action) => {
  switch (action.type) {
    case "SIGN_IN":
      return {
        ...state,
        data: action.payload,
      };
    case "RESET_SIGN_IN":
      return {
        ...state,
        data: "",
      };
    default:
      return state;
  }
};

export default signInReducer;
