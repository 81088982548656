const language = {
  "9e66e6bd4069124578736528a0432752": `हमारे बारे में`,
  "0f5590b58efe9cfcce35e55a66280e59": `स्वीकार करें और जारी रखें`,
  "77ab8893d0d282a9f2781d5711883903": `जोड़ें`,
  "c40eba17959a63e52412cac8c38d467e": `प्रोफ़ाइल जोड़ें`,
  "ac714b256ab565c91ade9e03e8c410f0": `आपके सभी डेटा हटा दिए गए हैं`,
  "b31b443baac1c6da4cb78dfaf2e5be44": `अनुप्रयोग लिंक`,
  "0ec157e0e15e12c56bfe1c72e0e7515a": `परिवर्तन लागू करें`,
  "181c1a0ee6db7d7f835eed29791749ab": `क्या आप सुनिश्चित हैं कि आप इस योजना को हटाना चाहते हैं?`,
  "d937652cde254e2a9b93b74a4ee2a02b": `क्या आप सुनिश्चित हैं कि आप स्थायी रूप से {{name}} प्रोफ़ाइल को हटाना चाहते हैं?`,
  "3ac1df709254c0e491c642aae5400814": `क्या आप सुनिश्चित हैं कि आप स्थायी रूप से {{name}} खाते को हटाना चाहते हैं?`,
  "1cbb32715ee74ee3447f48febcc04864": `क्या आप सुनिश्चित हैं कि आप इस सेवा को अपनी सहेजे गए सेवाओं से हटाना चाहते हैं?`,
  "ea4788705e6873b424c65e91c2846b19": `रद्द करना`,
  "f4ec5f57bd4d31b803312d873be40da9": `परिवर्तन`,
  "6dd495f1c2ae29c8e9a6c4405f18e22e": `स्थान बदलें`,
  "b1ad1d3aef325b23950a43cb82fd0670": `भाषा चुनें`,
  "714341d9339756588d1dc724991c2b9a": `यहां क्लिक करें`,
  "f72b489f19f748cf78a86c78d547ed91": `अपने स्थान की पुष्टि करें`,
  "a0bfb8e59e6c13fc8d990781f77694fe": `जारी रखना`,
  "bee04ef1315b3f9562be34e2e28a7831": `खाता हटा दो`,
  "3e9983cf1885a5ec9f5a5d8127137bd2": `प्रोफ़ाइल हटाएं`,
  "2ac7a924af94bf173f38eb31165efce3": `योजना को हटा दें`,
  "e0bb2f66e5b61be3235bf8969979de19": `ज़िला`,
  "46a43a7757337712d4f6a3701a5c57dd": `डाउनलोड फॉर्म`,
  "4d7be8ac1b9cb2fd5843dbea7ce30344": `आपने अभी तक कोई सेवा नहीं बचाई है`,
  "edda2208040fe25b06d0d8f3af976651": `आपने कोई नौकरी नहीं बचाई है`,
  "f283a3e62b2809c07cf77075103c012f": `हमें आपके लिए सर्वोत्तम अनुकूल सरकारी कल्याण योजनाओं, सेवाओं और नौकरियों को खोजने के लिए कुछ बुनियादी प्रोफ़ाइल जानकारी की आवश्यकता है।`,
  // "f283a3e62b2809c07cf77075103c012f":`सबसे उपयुक्त सरकारी कल्याण योजनाओं को खोजने के लिए लाभार्थी को कुछ बुनियादी प्रोफ़ाइल जानकारी की आवश्यकता है`,
  "ce8ae9da5b7cd6c3df2929543a9af92d": `ईमेल`,
  "01e8b0c4e059b594202e976860b2d481": `अपनी भाषा में योजनाओं और सेवाओं का अन्वेषण करें`,
  "483a5da192f46e3cfe8391d3ef234fc4": `पूछे जाने वाले प्रश्न`,
  "37aaf54433bbfe989db8871121fe0ad8": `हमें फॉलो करें:`,
  "e0c4332e8c13be976552a059f106354f": `शुरू करें`,
  "48dee7c291ac7f19e499438b072da336": `सरकारी शुल्क`,
  "2ceb32552d78024d91a123897636afcb": `EasyGov का नागरिक सशक्तिकरण ऐप`,
  //"357aa540fd5c8ae584ba18359105de3d":`क्या आपके पास किसी भी योजनाओं या किसी और चीज़ के बारे में कोई सवाल है?`,
  "357aa540fd5c8ae584ba18359105de3d": `यदि आपके पास कोई क्वेरी है तो हमें बताएं और हम 24 व्यावसायिक घंटों के भीतर आपके पास वापस आ जाएंगे।`,
  "b3ba00d60ff5a9e11c15a6efbed13746": `अपनी वर्तमान प्रोफ़ाइल अपडेट करने के लिए "वर्तमान प्रोफ़ाइल अपडेट करें" पर क्लिक करें और एक नई प्रोफ़ाइल जोड़ने के लिए "प्रोफ़ाइल जोड़ें" चुनें`,
  "12ceff2290bb9039beaa8f36d5dec226": `नौकरियां`,
  "5eb57a6678ca337f92884f49b578a908": `...`,
  "4994a8ffeba4ac3140beb89e8d41f174": `भाषा`,
  "846eb834b6bfe81dbd412bc010f8cac7": `हमें बताएं और हम 24 व्यावसायिक घंटों के भीतर आपके पास वापस आ जाएंगे`,
  "a58252e21aaf876bf7b64c05cce1c477": `लिंक कॉपी किया गया`,
  "ce5bf551379459c1c61d2a204061c455": `स्थान`,
  "ccffe10fe854018805baf0d3d9327285": `प्रोफ़ाइल प्रबंधित करें`,
  "49ee3087348e8d44e1feda1917443987": `नाम`,
  "10ac3d04253ef7e1ddc73e6091c0cd55": `अगला`,
  "bafd7322c6e97d25b6299b5d6fe8920b": `नहीं`,
  "31916d996c9f53949315692bc897f0c7": `संबद्ध नहीं? देखना जारी रखें`,
  "e81c4e4f2b7b93b481e13a8553c2ae1b": `या`,
  "0d5e50bf1643118ee026e5d19226542a": `कृपया वर्तनी की जाँच करें या कुछ और खोजने का प्रयास करें`,
  "64ed408b000d73143e6ab1b5acb7c26e": `कृपया प्रोफ़ाइल चुनें`,
  "fa2ead697d9998cbc65c81384e6533d5": `गोपनीयता नीति`,
  "7595f48afc2f088bc1ff3313ceeb3605": `लागू करने की प्रक्रिया`,
  "cce99c598cfdb9773ab041d54c3d973a": `प्रोफ़ाइल`,
  "665d0095958d696322be90e4eb606977": `इस संबंध के साथ प्रोफ़ाइल पहले से मौजूद है। आप वर्तमान प्रोफ़ाइल को अपडेट कर सकते हैं`,
  "27bcc51421612805a9c006f24a4d6dc6": `निकालें योजना`,
  "f8b66e3c488bb1df16cb3c7bb21447f0": `आवश्यक दस्तावेज़`,
  "82609dd1953ccbfbb4e0d20623193b56": `होमपेज पर लौटें`,
  "c9cc8cce247e49bae79f15173ce97354": `बचाना`,
  "c320cdeffafbb2d60d6d759619c6b5e2": `प्रोफाइल सेव करें`,
  "ab9753c948520fb02c20831980bad53a": `मेरी योजनाएं`,
  "827452688eed02a12178e96f924ac529": `योजना`,
  "cb80cf588ba5a0d3dc067b0b0ef219e9": `योजनाओं`,
  "1925ba54f16355af0964df7dfa145761": `आपके लिए योजनाएं`,
  "13348442cc6a27032d2b4aa28b75a5d3": `खोजें`,
  "32df01b9cf0491a879250b58ba2744ba": `खोज के परिणाम`,
  // "988cddb5e417156bd3603983dc0c32e5":`योजनाएँ खोजें`,
  "0afcf4c1ba72c441b41fbafaab253fc6": `स्थान चुनें`,
  "be2933038f93e25262400220080c111f": `राज्य चुनें`,
  "dfe1d316ec9338e23c892b573667b9bb": `अपना विकल्प चुनें`,
  "c2ba7e785c49050f48da9aacc45c2b85": `सेवा`,
  "992a0f0542384f1ee5ef51b7cf4ae6c4": `सेवाएं`,
  "48ecd536efbb3622296aac8de326fa38": `क्षमा करें, प्रदान की गई जानकारी के आधार पर, आप योजना के लिए पात्र नहीं हैं`,
  "4cd9a992dc66802a4b44c366881957d1": `क्षमा करें, कोई परिणाम नहीं मिला`,
  "f86301756ec6964cb96e2b80a38f4162": `माफ़ करना! हम आपके लिए कोई मैचिंग स्कीम नहीं पा सके`,
  "46a2a41cc6e552044816a2d04634545d": `राज्य`,
  "efd9a0ba1e9d553a070028627fdcae18": `सर्वेक्षण विवरण`,
  "4cd61af85d0ec40f50349cd7c3054ace": `स्विच प्रोफाइल`,
  "1d8cd2bfc6943dcf70236f7d351572a0": `नियम एवं शर्तें`,
  "4f7e556abaf92ce8061ad57cc4e7edd1": `यह आवश्यक है`,
  "371999537b8bb6e7f0099b0c40e0e41d": `यह प्रोफ़ाइल पहले से ही बनाई गई है। आप प्रोफ़ाइल को अपडेट नहीं कर सकते।`,
  "9eed21a5afd3ba6841c523b0d70599c7": `योजनाओं को देखने के लिए 2 से कम के साथ किसी भी श्रेणी पर क्लिक करें या विशिष्ट श्रेणी के लिए एक सर्वेक्षण चलाने के लिए 2 से अधिक के साथ श्रेणी पर क्लिक करें`,
  "37528894f18a085f9a078496a68eaaa8": `रुझान`,
  "d876a9fe1aecb3ffc05a5639ebe22000": `पुनः प्रयास करें`,
  "06933067aafd48425d67bcb01bba5cb6": `अद्यतन`,
  "fba7f6c863513d97922faab074d3c716": `प्रोफ़ाइल को नवीनतम बनाओ`,
  "90b1f6651953fcf8b43ddc0da6760aaf": `उपयोगकर्ता रूपरेखा`,
  "d8d7ad6abfd08c27baa8bedb78c72ddb": `सभी को देखें`,
  "acac138f2e6f039df2e1fed2f2c0bc44": `EasyGov में आपका स्वागत है`,
  "6ebb2e31989c843ea65feefa43237dad": `जब आप अपने खाते से एक प्रोफ़ाइल हटा देते हैं, तो आप उस विशेष प्रोफ़ाइल के लिए संग्रहीत किसी भी डेटा या जानकारी को पुनः प्राप्त करने में सक्षम नहीं होंगे। चुने हुए प्रोफ़ाइल के लिए सभी सक्रिय और सहेजे गए योजनाओं को भी हटा दिया जाएगा`,
  "6a64fbe7b065fc8579f2521e0054de02": `जब आप अपने लाभार्थी खाते को हटा देते हैं, तो आप अपने प्रोफ़ाइल और अपने खाते से जुड़े अन्य प्रोफाइल में संग्रहीत किसी भी डेटा या जानकारी को पुनः प्राप्त नहीं कर पाएंगे। आपके सभी सक्रिय और सहेजे गए योजनाओं को भी हटा दिया जाएगा`,
  "93cba07454f06a4a960172bbd6e2a435": `हाँ`,
  "3aeee34bea95f2b0ced72be632778670": `आपने कोई योजना सेव नहीं की है`,
  "cb32c835ee043e0e2faf4860e46669ae": `इस योजना/सेवा की समीक्षा की जा रही है। कृपया कुछ समय बाद फिर से प्रयास करें।`,
  "c2e0ede88489afe5739c8973aa03a787": `आपकी प्रोफ़ाइल से मेल खाने वाली कोई योजनाएं नहीं हैं`,
  "28d2dfa9b435bd81cb2f7f0499b846b3": `योजनाओं का पता लगाएं`,
  "52561ab2d4640b4e299e817b6ba79173": `लाभार्थी में आपका स्वागत है`,
  "fb0fc4b5fdb2bef7b1c42f2cfd523c2d": `प्रयास शेष`,
  "e13e1cd5f02de37413f9497cc5b65de3": `आपका खाता 30 मिनट के लिए लॉक कर दिया गया है। कृपया उस समय के बाद प्रयास करें`,
  "f2a6c498fb90eebdu72k7f888fce3b18": `स्वागत`,
  "1172b2ea87bed4861c8c43a201d53031": `मेरी सेवाएं`,
  "bcc254b55c4a1babdf1dcb82c207506b": `फ़ोन`,
  "8458bad978f4fd6d6c7f8ec2ec0824b4": `पासवर्ड भूल गए?`,
  "d67850bd126f070221dcfd5fa6317043": `साइन अप करें`,
  "619d59e2ea9f9bb9fe287404dd3ca931": `सभी सेवाएँ`,
  "6b46ae48421828d9973deec5fa9aa0c3": `क्रम से लगाना`,
  "104f0a6879399d6285ab770ed0dbc257": `सेवा हटाये`,
  "0b3937a9dd481d527fcc72adaa735b57": `लाभार्थी खाता नहीं है?`,
  "2f453cfe638e57e27bb0c9512436111e": `जारी रखना`,
  "f2fdea440d768b85591e936a803c3631": `साइन इन करें`,
  "82b1fbd43ae27918a37b212a4f60a9ad": `प्रस्तुत करना`,
  "31693e2e9aa2e5be1bf3ae45ede9c98d": `जिन योजनाओं के लिए आप पात्र नहीं हैं`,
  "d43cd6a49673f0f486ed7fbea1a5fd8a": `आधार संख्या`,
  "3a06727e678189b8502408cb9b50b437": `विवरणिका बदले`,
  "0323de4f66a1700e2173e9bcdce02715": `लॉग आउट`,
  "644cd55dbe4d01e7e00fc7fc458f9b8f": `क्या आप लॉग आउट करना चाहते हैं?`,
  "c0fb5959a7a8d3ea991f9edb0b1a9e85": `कृपया आईडी का चयन करें, हमें आपके लिए योजनाएं मिलेंगी`,
  "827a37fb93758383027c582a39ec6162": `आईडी का प्रकार`,
  "cb3441fa3e1291a76dd5ea72e656bca3": `बिना किसी आईडी के जारी रखें`,
  "99dea78007133396a7b8ed70578ac6ae": `लॉग इन करें`,
  "02aeaf2672eb06861c144004ef2248d2": `लॉगिन खाता`,
  "d4d208eb2d8ccd525a64be73f3521dc5": `अवैध प्रत्यय पत्र`,
  "e8055d9577ec8edf34ee15a88a937daa": `कोई खाता नहीं है?`,
  "942a3ef743c4e176f75d5223455f7e1a": `अपना 10 अंकों का फोन नंबर दर्ज करें`,
  "21bdc5689c12595ae14298354d5550d5": `क्या आपके पास पहले से एक खाता मौजूद है?`,
  "dc647eb65e6711e155375218212b3964": `पासवर्ड`,
  "0b3e8b8021c9b2fafee35ae01ac2cfb9": `${window.projectName} ऐप आपको और आपके परिवार के लिए सर्वोत्तम अनुकूल सरकारी कल्याण योजनाओं को खोजने में मदद करेगा।`,
  "05aca668fa6754247f5094c042fb64ac": `फ़ोन सत्यापन`,
  "f9bc92a6602b9692a0ba578120c9c441": `अपना OTP कोड यहां दर्ज करें`,
  "d9d1e60e50119d9752001d4196ee6b3c": `OTP`,
  "6e95a24fa2a98c2f59c96a24b182ccfd": `क्या आपको कोई कोड नहीं मिला?`,
  "f922096c878b694ec7a3d9282a55904c": `नए कोड को फिर से लिखें`,
  "32b830cef750168335d96aff4dd98782": `मेरे काम`,
  "bbd26bc8ce3623addc9c54c36f692f8d": `आपके लिए नौकरी`,
  "67cb88ed21d4232c4681a5e648554e09": `आवेदन शुल्क`,
  "b90455b71eb5e2399be9d20e23c1a1db": `आवेदन करने के लिए अंतिम तिथि`,
  "bfe72f18052c940de13501ae9877c3b0": `आवेदन कैसे करें`,
  "4f1f44ef7ce0c8a111f33fe400a6e4d7": `चयन प्रक्रिया`,
  "0f5679acafa674831691cfa01d17501e": `नौकरी अधिसूचना`,
  "a45ca50bcc7830af2f84fd046648ec92": `आपकी प्रोफ़ाइल से मेल खाने वाली कोई नौकरियां नहीं हैं`,
  "c4bcece293eb1ad7f09d4cf9c2037ea6": `नौकरी ढुंढो`,
  "5dc52ca9ffdc26147075682c92a0a853": `पदों`,
  "3f99ba6fbcb46f28fb24f271fc3c02c1": `नौकरियों को देखने के लिए किसी भी श्रेणी पर क्लिक करें। किसी भी नौकरी के लिए एक सर्वेक्षण चलाने के लिए कार्ड पर क्लिक करें`,
  "2078989a6c09fdd98efc35d3c1d1de3d": `नौकरी हटाना`,
  "08c1517355f6e7313c4d629a193ac69a": `क्या आप सुनिश्चित हैं कि आप इस नौकरी को अपनी सहेजे गए नौकरियों से हटाना चाहते हैं?`,
  "e614dff8cba6f73ae7cee729fe0091dd": `अमान्य OTP`,
  "c46c1e69c89811f03dc73feb09273409": `OTP की आवश्यकता है`,
  "ae3bb2a1ac61750150b606298091d38a": `नया पासवर्ड`,
  "887f7db126221fe60d18c895d41dc8f6": `पासवर्ड की पुष्टि कीजिये`,
  "70d9be9b139893aa6c69b5e77e614311": `पुष्टि करें`,
  "20aedd1e6de4dcf8d115b5a7424c58d7": `पासवर्ड की आवश्यकता है।`,
  "841a389ec69697c28df7b9739419d21d": `पासवर्ड 6 चरित्र लंबा होना चाहिए।`,
  "bcb9407d90fd17ccff50587f5edfe987": `पासवर्ड मेल नहीं खाता है।`,
  "318721657b639c8ca02293211db68837": `फोन नंबर की आवश्यकता है`,
  "4ea3456b0e1b463f50b81c79230cf070": `अवैध फोन नंबर`,
  "bd454fc3d32f4425d146d3e861409871": `उपयोगकर्ता पहले से मौजूद है`,
  "ab48b978d197e315e92e904e36d26175": `फोन नंबर अमान्य है`,
  "70729403b9560d3adb142977608b81d2": `OTP सीमा पार हो गई। 4 घंटे के बाद प्रयास करें।`,
  "1e4dbc7eaa78468a3bc1448a3d68d906": `फ़ोन नंबर`,
  "4f2df4c330b8df125a3d75f2d69a9b5e": `OTP भेजें`,
  "9ec54ec336b65da6179cf750de33ecd7": `पासवर्ड भूल गए`,
  "98adc700c19f071f07a714a703a6fc1e": `आईडी विवरण`,
  "f262fae0a2f17e8c1eb4e9eddb0160e4": `आगे बढ़ना`,
  "49fb619c4872c491fae649c28b339ed8": `आपके क्रेडेंशियल्स हमारे रिकॉर्ड से मेल नहीं खाते हैं`,
  "58557a71f9811899313234decda72d5a": `आपका खाता 30 मिनट के लिए लॉक कर दिया गया है। कृपया उस समय के बाद प्रयास करें।`,
  "e7665f483813a56e9410787ce2d174ef": `फोन नंबर पहले से ही उपयोग में है। कृपया साइन अप करने के लिए साइन इन करें या किसी अन्य नंबर का उपयोग करें।`,
  "0a46fa5fb9e86e80fc94e229d1967699": `फोन नंबर पंजीकृत नहीं है। कृपया पुन: प्रयास करें`,
  "bdff8b1cbfb4d5623382d7b0678e707c": `पासवर्ड में कम से कम 8 वर्ण होना चाहिए, जिसमें कम से कम एक ऊपरी मामला, एक निचला मामला, एक संख्या और एक विशेष चरित्र शामिल है`,
  "d55085482b3e8da780755832c17d7d60": `अंतिम तिथी`,
  "988cddb5e417156bd3603983dc0c32e5": `खोज योजना और सेवाएँ`,
  "261b39077b81875e115baa22bc6ce04c": `नौकरियों को देखने के लिए एक विशेष श्रेणी से संबंधित है, कृपया श्रेणी पर क्लिक करें`,
  "bdb53070a3fc01196ae38b059a4b42ff": `नौकरी हटाएं`,
  "ede1fcad3d6bf808fc948df7d22e1536": `माफ़ करना! हम आपके लिए कोई मैचिंग जॉब नहीं पा सके`,
  "0c4fa7a0201f434b0ef84494d0aac07a": `क्षमा करें, प्रदान की गई जानकारी के आधार पर, आप नौकरी के लिए पात्र नहीं हैं`,
  "e4defbb7cdf529fa3606749a82c06932": `पदों`,
  "25d2401d71f4ba1ba7685559c1b77927": `आपकी प्रोफ़ाइल के आधार पर, हमें आपको किसी भी नौकरी के लिए पात्र नहीं मिला। <br> हम लगातार अधिक नौकरियों को जोड़ रहे हैं, कृपया बाद में देखें।`,
  "ea7c2400c0ae5f58608cb5051ee852f3": `इस समय आपके राज्य में कोई नौकरियां कॉन्फ़िगर नहीं की गई हैं, हम लगातार अपडेट कर रहे हैं, कृपया हमारे साथ फिर से जांचें`,
  "24fe48030f7d3097d5882535b04c3fa8": `खत्म हो चुका`,
  "9dc0186e2d5d3398ffee6ec964b15dc0": `सभी समाप्त हो चुकी नौकरियों को समाप्त होने की तारीख से 30 दिनों की अवधि के बाद आपकी "मेरी नौकरियों" से हटा दिया जाएगा।`,
  "34a934c0c86d866df9d14f8a6e90c637": `गैर -योग्य का कारण`,
  "a97ea56b0e00b2379736ae60869ff66a": `प्रश्न`,
  "8e9e3212461f14c8dbea6456c920bcbc": `योग्य मूल्य`,
  "c4dce677291fa5bc05276ffe93c5d8f2": `आपके द्वारा प्रदान किया गया मूल्य`,
  "9a917c4a4f546c6072bd61ae617763bf": `योजना आपके लिए नहीं`,
  "e57c13893a290ce882569b5638ba7b82": `आपके द्वारा दर्ज की गई आईडी नहीं मिली है। कृपया पुन: प्रयास करें।`,
  "e26859a59ede4ab8d8ea0213b68fa732": `स्रोत प्रणाली वर्तमान में जवाब नहीं दे रही है, कृपया कुछ समय बाद प्रयास करें।`,
  // "e26859a59ede4ab8d8ea0213b68fa732":`स्रोत प्रणाली वर्तमान में जवाब नहीं दे रही है, कृपया आईडी के बिना आगे बढ़ें या कुछ समय बाद प्रयास करें।`,
  "d2235c7ecbbbae1bf62833dade7486f5": `प्रोफ़ाइल विवरण गोल्डन रिकॉर्ड से लिया गया है।`,
  "346df6b73ae19a6619f26648cf415522": `अयोग्य योजनाओं की सूची प्रोफ़ाइल डेटा पर आधारित है।`,
  "6e0fe1553e84e34da97a6c4c9040b31b": `लाभार्थी`,
  "27559de2125101ccb786807bceb46aa1": `भारत`,
  "936b6a0effe4990e8f5420a0099fa537": `EasyGov`,
  "8cf04a9734132302f96da8e113e80ce5": `घर`,
  "923b39c56654bb6264b6638ba8386b3c": `अपनी प्रोफ़ाइल बनाने के लिए साइन इन करें/साइन अप करें`,
  "52f2ba99491a1bae953303a4eaf50196": `OTP के साथ लॉगिन करें`,
  "18e0c8084d60f55016d67d4e2ca507ce": `SSO के साथ लॉगिन करें`,
  "d3d77957f18f1e208cb5b0caa07bec5c": `अधिक योजनाएं खोजें`,
  "6a26f548831e6a8c26bfbbd9f6ec61e0": `मदद करना`,
  "b2119dd0c37bb396e24199d5ffbd0c72": `स्क्रीन रीडर एक्सेस`,
  "34b6cd75171affba6957e308dcbd92be": `संस्करण`,
  "4221d3e17c6eca2ca6337251a3cf9c4e": `आखरी अपडेट`,
  "ece2c7eaaac9f27b055e6e482ab776b9": `जानकारी की खोज`,
  "ad8f678ccf4a4a34b46f01c74f25b91b": `उपयोगकर्ताओं को वेबसाइट के भीतर जानकारी तक पहुंचने में मदद करने के लिए, एक "यहां खोज" विकल्प प्रदान किया गया है। यहां, कन्नड़ में टाइप करने का विकल्प उपलब्ध कराया गया है। खोज करते समय, यूनिकोड फ़ॉन्ट का उपयोग अनिवार्य है। अंग्रेजी में खोज करने का विकल्प भी प्रदान किया गया है।`,
  "698aa21b21c2252839e5e54a0d2f12ef": `इंटरनेट एक्सेसिबिलिटी`,
  "4ac2856693f5c8b4fc6cdaf669ed2d74": `यदि इंटरनेट धीमा या अनुपलब्ध है, तो वेबसाइट डिजाइन में एक संशोधन हो सकता है या कुछ उपपृष्ठ नहीं खुल सकते हैं।`,
  "0da22543a45316a826571412b3d5953c": `वेबसाइट वर्ल्ड वाइड वेब कंसोर्टियम (W3C) वेब कंटेंट एक्सेसिबिलिटी गाइडलाइन्स (WCAG) 2.0 लेवल AA का अनुपालन करती है। यह दृश्य हानि वाले लोगों को सहायक प्रौद्योगिकियों, जैसे स्क्रीन पाठकों का उपयोग करके वेबसाइट तक पहुंचने में सक्षम करेगा। वेबसाइट की जानकारी विभिन्न स्क्रीन पाठकों, जैसे कि जबड़े, एनवीडीए, एसएएफए, सुपरनोवा और विंडो-आईज़ के साथ सुलभ है।`,
  "cb976b928eacd65e6342cfa78bf196da": `निम्नलिखित तालिका विभिन्न स्क्रीन पाठकों के बारे में जानकारी को सूचीबद्ध करती है`,
  "786f669131d80fbed4572a520c28c14d": `Easygov द्वारा बनाई गई और स्वामित्व वाली सामग्री`,
  "fc290a7672f96fd8b94a9659c8c02322": `| सर्वाधिकार सुरक्षित`,
  "6016a2b341113bf496b719905398ecd2": `कॉपीराइट`,
  "0e8a85115f2c3ef0058c70f30a9b6deb": `कृपया अपने पंजीकृत मोबाइल नंबर पर भेजे गए OTP दर्ज करें`,
  "34f8ccda5172e4eac9a8a014559b38c1": `कृपया अपना पासवर्ड सेट करें`,
  "548899be111ad732b37786783dfbd6d8": `कोई इंटरनेट कनेक्शन नहीं`,
  "5c3a1593ca1d34226359df44f3d45f2f": `कृपया कुछ समय बाद फिर से जाँच करें।`,
  '35043eeac5651298d4bb6c100e071745': `आपको तृतीय-पक्ष वेबसाइट पर पुनर्निर्देशित किया जाएगा। क्या आप जारी रखना चाहते हैं?`,
  "6182e08f3974a70ac4883b5fceaa14c0": `कृपया अपने पंजीकृत मोबाइल नंबर पर भेजे गए OTP दर्ज करें`,
  "306fb6389c3b3fd2835af15cfc7c66e9": `परिवार आईडी बदलें`,
  "63fc7651ee1dbf6868a43a5b5fa8b581": `केवल पात्रों की अनुमति है`,
  "6903fbe8375d52217c15618170f4078a": `केवल 30 वर्णों की अनुमति है`,
  "9ab37820a722ce13e80a3d0aa468d6dc": `*आवश्यक`,
  "0df1f18a2fa23cd60990e47692f99e07": `अनुप्रयोग लिंक`,
  "a60852f204ed8028c1c58808b746d115": `ठीक`,
  "edbd5921e9c7168e04cbe6f75ee76174": `आपकी प्रोफ़ाइल के आधार पर, हमने आपको किसी भी योजना के लिए पात्र नहीं पाया। हम लगातार अधिक योजनाएं जोड़ रहे हैं, कृपया बाद में देखें।`,
  "a203c39a340e1d45069b1ba64e1d003a": `ध्यान!`,
  "037673ab5a84897e0ee43ca37f7680f6": `यदि आप अपना डेटा बदलते हैं तो वही आपके प्रोफ़ाइल डेटा में भी अपडेट किया जाएगा। क्या आप जारी रखना चाहते हैं?`,
  "d1d16cede248b826a431ea11132567cb": `यदि आप अपना सत्यापित डेटा बदलते हैं, तो इसे फिर से सत्यापित किया जाएगा और आपकी स्थिति बदल दी जाएगी। इससे आपके आवेदन के अस्वीकृति या बढ़े हुए प्रसंस्करण समय की अधिक संभावना हो सकती है।`,
  "23a0ca84a7fd64eee8248bd1dfc77185": `नाम परिवर्तन करें`,
  "3b383caaaa6113dd83b381b43a97d7ee": `इस उपयोगकर्ता के लिए पहले से ही एक प्रोफ़ाइल मौजूद है, एक नया परिवार आईडी जोड़ने से पिछले विवरण को हटा दिया जाएगा। कृपया पुष्टि करें`,
  "e0efbdcea0abe735dbf887292a5670fa": `भइया`,
  "ad6e7652b1bdfb38783486c2c3d5e806": `खुद`,
  "19cda37369e2d2015e66b9d478ffbe15": `बहन`,
  "81e053d78f01540e9cd6fb68a1c03976": `कोई और`,
  "b8e18040dc07eead8e6741733653a740": `माता`,
  "a30ca91be97a9cedc0d2426e54b0e782": `पिता`,
  "784c01f6b735be57175e2f438297c741": `पति या पत्नी`,
  "1968ab9fdfedf558714bf6caec9a1234": `बेटा`,
  "7bc1cb93f51f61bde0e90b20c81ebf88": `बेटी`,
  "25f34d5943da3f09a2aa6358193a41bd": `आवेदन करने के लिए आगे बढ़ें`,
  "79dff508ab2e4d272f0630d001271eb1": `अधिक नौकरियों का अन्वेषण करें`,
  "b6ebe1e750185946ed41be9191410612": `आपको लाभार्थी के माध्यम से आवेदन करने में सक्षम होने के लिए विवरण प्रदान करने की आवश्यकता है।`,
  "af1899084d8361b9afb9187da988ff9c": `"क्षमा करें, हम आपका डेटा लाने में असमर्थ हैं"।`,
  "1b56218ddf0a290241d661d26253d51f": `केवल {{attempts}} प्रयास (ओं) को छोड़ दिया`,
  "f299cff2484fdb361f4fc3189490d909": `आपके द्वारा प्रदान की गई निम्नलिखित जानकारी आपकी सरकार के पुनरावृत्ति से मेल नहीं खाती:`,
  "1edf5362e5f4af6f370a7f34c3136a7a": `सत्यापन असफल`,
  "dcf9ac5128c95d88e068e223dfe01d27": `सत्यापन की स्थिति`,
  "bc910f8bdf70f29374f496f05be0330c": `पहला नाम`,
  "77587239bf4c54ea493c7033e1dbf636": `उपनाम`,
  "14fcbe1dd0f7a39f0195aba46cf89d17": `विवरण सत्यापित करें`,
  "b6121db5ee47deeb0ca42d5dea0b2225": `प्रयास विफल रहे`,
  "839301cec5574d7c3256afce4fb85467": `आपने अपने परिवार के विवरण को सत्यापित करने के लिए वैध प्रयासों को पार कर लिया है। आपका सत्यापन अवरुद्ध हो गया है`,
  "6733aa56fd001b9bf479650f3950688a": `आपको स्कीम एप्लिकेशन पेज पर निर्देशित किया जा रहा है`,
  "9303c9bd4f8178680dc382adbfcd62af": `यह फ़ील्ड आवश्यक है.`,
  "53a04cfc3d09c9b2dfb8349791c62bc1": `आपका नाम इस परिवार के किसी भी सदस्य के साथ मेल नहीं खाता था`,
  "1ca35256fcb85c1ba9ea7ac1095462ad": `आपने अपने परिवार के विवरण को सत्यापित करने के लिए वैध प्रयासों को पार कर लिया है। आपका सत्यापन 3 घंटे के लिए अवरुद्ध कर दिया गया है`,
  "0e3a917198575c463bb4fdcb7332b03a": `अमान्य आधार कार्ड नंबर`,
  "630058029627ce58e7571e7a5b6ec591": `पूरा नाम`,
  "072355e896ac1a999d2fa0aa208f94ee": `इस योजना को आपकी "मेरी योजनाओं" में सहेजा गया है।`,
  "789189638f8a301304dfadeb61036dd9": `यह सेवा आपकी "मेरी सेवाओं" में सहेजा गया है।`,
  "ab8023b4fc2395738b8fd2df440641a9": `इस नौकरी को आपकी "मेरी नौकरियों" में बचाया गया है।`,
  "671dec8fc4e3dc847c1b0d7b67b4e6e1": `रिश्ता`,
  "f252b4e2f3b2963d330c8cde8e9cdbd9": `अगर उपलब्ध हो`,
  "ef51899b57258db72f15b4703a8a86ba": `नागरिकों के लिए एक एकल मंच पैन इंडिया सरकार के कल्याण योजनाओं, सेवाओं और नौकरियों के लिए कार्रवाई योग्य जानकारी तक पहुंचने के लिए।`,
  //New Translations for Phase 1:`#VALUE!`,
  "7510e00267e89c78470e79b42ecd6463": `अपनी भाषा में ऐप का अन्वेषण करें`,
  "83218ac34c1834c26781fe4bde918ee4": `स्वागत`,
  "1d00e7dce692e8dc3f6877f035e3a616": `या`,
  "e0cf58fa3413df99ae83109ebb9a41a3": `OTP पुनः भेजें`,
  "6ed83b24865902c6fd90bbd15247e042": `सेटअप पासवर्ड`,
  "9157806ea93e82c6843e29353af0339f": `OTP अनुरोध करें`,
  "69f18e98d2dd54118ade84732dc3a5e0": `पिन सत्यापित करें`,
  "2dd4472245a696bc0b4b944db2a8b519": `व्यक्तिगत`,
  "a274f4d4670213a9045ce258c6c56b80": `अधिसूचना`,
  "407456e575dee5a4b84a19a7111a4a4d": `जब आप किसी भी स्कीम श्रेणी पर कम उस 2 नंबर के साथ क्लिक करते हैं, तो आप उन योजनाओं को देखने में सक्षम होते हैं, जिनके लिए आप पात्र हो सकते हैं, जब आप 2 से अधिक संख्याओं के साथ किसी भी स्कीम श्रेणी पर क्लिक करते हैं तो श्रेणी विशिष्ट सर्वेक्षण आपको और अधिक देने के लिए चलेगा। चयनित श्रेणी के लिए योजनाओं की परिष्कृत सूची।`,
  "9139eb8e78808acbd15244d4eb9f1bc3": `अधिक योजनाओं का अन्वेषण करें`,
  "c61dd5f0e5b5389306ad5221ec2cd795": `इस योजना को आपकी "मेरी योजनाओं" में सहेजा गया है`,
  "f2a6c498fb90ee345d997f888fce3b18": `मिटाना`,
  "469bad4859f3c33fcb8ce09314c3e895": `सर्वेक्षण विवरण की समीक्षा करें`,
  "7dce122004969d56ae2e0245cb754d35": `संपादन करना`,
  "c5984b3fe02f7b06d77fb538d842b0fc": `सर्वेक्षण विवरण अद्यतन करें`,
  "6cf33ce1a7a11a7178131567c777b637": `एक ही नाम और संबंध के साथ प्रोफ़ाइल पहले से मौजूद है`,
  "fffcb4d1e12c96b2f571f77ad25878b8": `कारण`,
  "e8d89b6875d217a990d47701138e687a": `क्या आप सुनिश्चित हैं कि आप इस डेटा को हटाना चाहते हैं?`,
  "aa98db08fed41a99a00aac36f521540f": `अंतिम सत्यापित`,
  "5da618e8e4b89c66fe86e32cdafde142": `से`,
  "253ec560db0637f6a0e53aefcbd4be0c": `जब आप लाभार्थी खाते को हटा देते हैं, तो आप अपनी प्रोफ़ाइल में सहेजे गए किसी भी डेटा या जानकारी को पुनः प्राप्त करने में सक्षम नहीं होंगे। {{name}} के लिए संग्रहीत सभी सहेजे गए योजनाएं और जानकारी भी हटा दी जाएगी।`,
  "c0e70d5bfc28ae3aadfb8f85697c54d5": `आपका डेटा सफलतापूर्वक अपडेट किया गया है।`,
  "09b73aba9204a05ba5eaf0ea471fdc8c": `EasyGov Surajya Service Private Limited का एक पंजीकृत ट्रेडमार्क है। EasyGov के प्लेटफ़ॉर्म-ए-ए-सर्विस मॉडल सरकारी कल्याण को रूढ़िवादी, प्रतिक्रियाशील और कार्यक्रम केंद्रित होने से प्रगतिशील, सक्रिय और परिवार केंद्रित होने से चरणबद्ध तरीके से बदल देते हैं। EasyGov विभिन्न सरकारों को नागरिकों के लाभों के लिए कल्याण प्रणाली की इस जटिल यात्रा को हल करने में मदद कर रहा है। कृपया अधिक जानकारी के लिए <a href='https://easygov.co.in/' target='_blank'> https://easygov.co.in/</a> पर जाएँ`,
  "8f241c62a9523b05bf0b6b16a09d856d": `भाषा बदलो`,
  "1ccbe6084301f9b1e18e3e7caf723ae1": `क्या आप सुनिश्चित हैं कि आप लॉगआउट करना चाहते हैं?`,
  "32ca0a0f69103bad1e6f134d5a8e36d7": `किसी विशेष श्रेणी के लिए नौकरियां देखने के लिए, कृपया किसी भी नौकरी श्रेणी पर क्लिक करें`,
  "d6e6cb19e3b9c02f89d6cd54cfa7c613": `व्यवसाय`,
  "b1c94ca2fbc3e78fc30069c8d0f01680": `सभी`,
  "1dd1c5fb7f25cd41b291d43a89e3aefd": `आज`,
  "ebfe9ce86e6e9fb953aa7a25b59c1956": `कल`,
  "4b2ef3655b5d6d48f1fa41892d0043b9": `कोई सूचनाएं नहीं`,
  "094f537cfb139894f1412200c78e87b1": `क्या आप सुनिश्चित हैं कि आप चयनित सूचनाओं को हटाना चाहते हैं?`,
  "4201c11dc26b42deb90cb119c0b12fdb": `डाउनलोड अधिसूचना`,
  "ae45c43efe0011f67ec52003c0d8f916": `<b> hint </b>: 'पासवर्ड' में कम से कम 8 <br> वर्ण होना चाहिए, जिसमें कम से कम एक ऊपरी मामला, एक निचला मामला, एक संख्या और एक विशेष वर्ण शामिल है।`,
  "248336101b461380a4b2391a7625493d": `बचाया`,
  "f03ab16cd58372c77ba45a3d9a5a1cb9": `प्रारूप`,
  "a4d158b65f20725f9fe9c1763e9805c8": `निजी प्रायोजित`,
  "7fe12d923db43febcc5d8ea18df632ca": `अपने केवाईसी दस्तावेज़ का चयन करें`,
  "d8ce714c8a76133a8d5746e3bfdd7ef7": `केवाईसी`,
  "4f67fe16b274bf31a67539fbedb8f8d3": `दस्तावेज़ का प्रकार`,
  "5127fa87ed375848481bfe9c786d8022": `अपने केवाईसी भरें`,
  "8f75e3041034138aba310ba3f097ed24": `दस्तावेज़ संख्या`,
  "9abfb506bebd1e72069f0be0014986dc": `अमान्य दस्तावेज़ संख्या`,
  "ecfffd1bad9944f6a50e369f63268cff": `क्यूआर स्कैन`,
  "c541f95d69a4a507b87b32d05dd6d73c": `कोड को फ्रेम के अंदर रखें`,
  "3876661cb83fa41284f828bd8d7fbb7b": `केवाईसी दस्तावेजों को सत्यापन और केवाईसी प्रक्रिया के पूरा होने के लिए अपलोड किया जाना है।`,
  "cc1d6f3625c4eb671d76b6ebf3f6f544": `अपने ग्राहक को जानो`,
  "08ac502bbb7652c683c1da97c7fdeb94": `{{xxxx}} ने आपके मोबाइल नंबर पर एक OTP भेजा है`,
  "d19a88ee579190614084c2ea84379ac7": `OTP (1:00) का पुनरुत्थान करें`,
  "7397e92003e187474e5eab39b27fcb6b": `क्षमा करें, हम स्रोत सिस्टम से आपका डेटा लाने में असमर्थ हैं। बाद में पुन: प्रयास करें।`,
  "a943d4ac1dd2e0048bcbf85225bf2811": `आपने OTP उत्पन्न करने के लिए अधिकतम सीमा को पार कर लिया है, कृपया 3 घंटे के बाद फिर से प्रयास करें`,
  "8dac090b39cf9b3bff9a9503531d04cf": `आपका खाता वर्तमान में अवरुद्ध है, कृपया कुछ समय के बाद प्रयास करें।`,
  "08798caaee5cc8ea1282883901c8810b": `शेष प्रयास - {{Number}} चेतावनी - लगातार 4 असफल प्रयासों के बाद, आपका खाता बंद हो जाएगा।`,
  "33018956b3ffe20d413e3c6780f19aff": `केवाईसी विवरण की पुष्टि करें`,
  "90f35a35415ca3b4c623d7c515fce1c0": `ध्यान! यदि आप अपने विवरण को अपडेट या बदलते हैं, तो आपका डेटा अस्वीकृत हो जाएगा और आपने अपडेट किए गए डेटा के लिए दस्तावेज़ प्रूफ अपलोड करने के लिए कहा होगा। आगे बढ़ने के लिए "जारी रखें" पर क्लिक करें`,
  "aee68a98fd803f091082faf867c0f62b": `केवाईसी फॉर्म भरें`,
  "2e853d869dd0c3cb0a64e4938ee90647": `अमान्य पिन कोड`,
  "8f3124e142a4000fe04a581f4f41c8da": `अमान्य {{labour card}} संख्या`,
  "dbb362212f1b8118f17633521d1e72f7": `आपका आवेदन सफलतापूर्वक आपकी 'ड्राफ्ट स्कीम' में सहेजा गया।`,
  "5c93310dd0291e121181e830cdda892e": `गेलरी`,
  "967d35e40f3f95b1f538bd248640bf3b": `कैमरा`,
  "37bed5f24fa3752c7830c021501539a0": `क्या आप सुनिश्चित हैं कि आप फोटो निकालना चाहते हैं?`,
  "1a263d755f169c2499c1a55f7a465b93": `आपकी दस्तावेज़ आईडी वर्तमान में अवरुद्ध है, कृपया कुछ समय के बाद प्रयास करें।`,
  "689707127ddb398dc4b447e1dffa3a47": `आपने अपने विवरण को सत्यापित करने के लिए मान्य संख्या से अधिक प्रयासों को पार कर लिया है। आपका सत्यापन 3 घंटे के लिए अवरुद्ध है।`,
  "04c850a81052df23680d00d2aa1d891e": `केवाईसी स्थिति`,
  "a441e519087df0d7c8572b9fafa14ae2": `आपके द्वारा प्रदान की गई निम्नलिखित जानकारी आपके सरकार के रिकॉर्ड से मेल नहीं खाती (1) आपका नाम रिकॉर्ड के साथ मेल नहीं खाता था (2) आपकी उम्र रिकॉर्ड के साथ मेल नहीं खाती।`,
  "75514ce9dac260a1f7abd0d85ce6af46": `आपके द्वारा प्रदान किए गए विवरण रिकॉर्ड के साथ मेल नहीं खाते`,
  "5955e277da6fb75982b0f651f4ae8b92": `हम स्रोत सिस्टम से आपका डेटा लाने में असमर्थ हैं। बाद में पुन: प्रयास करें।`,
  "be60ac326400cc5ef0d173a833c4a40b": `आपके विवरण सत्यापित किए जा रहे हैं`,
  "d1083e84a9a7283ffb081263e8533509": `आपके आवेदन को सफलतापूर्वक आपकी 'ड्राफ्ट स्कीम' में सहेजा गया है`,
  "0bad551abed71c0a41ca8df804afec86": `केवाईसी दस्तावेज़ का उपयोग करना`,
  "e6387f3e684958515af2947d8cdf01d7": `केवाईसी फॉर्म अपडेट करें`,
  "9c7ed3d8ac60281e90519b4ebf07a9a2": `पता चुनें`,
  "31e41095bfaa14799239e8d9ba7ad438": `दरख्वास्त विस्तार`,
  "e1e4c8c9ccd9fc39c391da4bcd093fb2": `अवरोध पैदा करना`,
  "b9eca8f59016e8ff69a2c76fa869d23f": `श्रम कार्ड अपलोड करें`,
  "6cf24d7a07132fe88b610e2b3d3def28": `स्व घोषणा`,
  "827b05360cd22ccbaf3d085aded71570": `पते का प्रमाण`,
  "f734cd7792862bebbbb87ddc54285376": `पहचान का सबूत`,
  "5a8c686dfdba564a2801806f3827c124": `छवि JPG या PNG प्रारूप में होनी चाहिए। PDF फाइल 1 MB से कम होनी चाहिए।`,
  "d495cdb8315e12e3dc5fd6121ed69cbc": `अमान्य IFSC कोड`,
  "cd9f177eac6411501839f7868e38767b": `क्या आप सुनिश्चित हैं कि आप इस दस्तावेज़ को हटाना चाहते हैं?`,
  "565d2daf0ccaf00d354669e7551b81dd": `प्रोफ़ाइल विवरण की समीक्षा करें`,
  "9895dd168483d1d34ab9ebfb2b6760be": `बैंक का चयन करें`,
  "deb5c3f30f3a8381367392d6608fbe68": `आवेदन की स्थिति`,
  "970324882e5bbfd2deef9e5b7e2d1160": `डाउनलोड आवेदन`,
  "03b55d8ed61b625c209cc42ea0a35b8c": `धन्यवाद!`,
  "8276f0b86b5ee98d03f2eafba3590bb0": `आपकी सेवा करने का अवसर देने के लिए। आपका आवेदन सफलतापूर्वक प्रस्तुत किया गया है।`,
  "ac3122aeddff176ae67d3df2b2198d19": `आपको धन्यवाद! आपका आवेदन सत्यापन के लिए प्रस्तुत किया गया है, "एप्लाइड एप्लिकेशन" के तहत ऐप से अपनी आवेदन की स्थिति देखें`,
  "73da163444abaee6a5c88532b8eda629": `आपका अनुभव कैसा रहा?`,
  "74eb855e4de6fe58228f03006c02fd8a": `ठीक है`,
  "d36ed38206da38c85af59039d18f1108": `<b> अपनी प्रतिक्रिया का वर्णन करें </b> (वैकल्पिक)`,
  "446e92f15cd245125ccd983ef968e692": `जी नहीं, धन्यवाद!`,
  "634a1a1ed07518476cb7a4beab87185a": `भयानक`,
  "f1b68d66337a81cfa0d2076171cba2a8": `बुरा`,
  "45802158e78dd9584161629098018fe8": `अच्छा`,
  "5709082e0d4e7a2cb92c2348acf297be": `पसन्द आया`,
  "c629e8ac2592361ec6474f07fb75f6fc": `आपकी प्रतिक्रिया हमें बेहतर बनाने और सुनिश्चित करने के लिए नए अवसरों की खोज करने की अनुमति देती है कि आपके पास सबसे अच्छा ऐप अनुभव है।`,
  "871abe85eba547582d9e8f0f9c61efbd": `हमें प्रतिक्रिया भेजने के लिए धन्यवाद।`,
  "733d43480c8589b1368e5def6b480415": `लागू`,
  "a5b739036b4d0b2bc966291747198d9b": `दाखिला लिया`,
  "0a4c051fb821b8e96d2bd564b893639b": `आपके लिए कोई अनुकूलित योजनाएं नहीं`,
  "9639e32cab248434a17ab32237cb3b71": `आवेदन करना`,
  "3f68e67dc6c397aaa9d1c24c356f754f": `सत्यापित`,
  "d63b911814d17bb4d541141b8531a458": `इस योजना को आपकी "मेरी योजनाओं" में बचाया गया है`,
  "454fd3b5167e532f28f4e3d47cd901fa": `आपके पास अभी तक कोई सहेजा हुआ योजना नहीं है`,
  "5e22b63835aef7976f3ca019841b1b28": `क्या आप सुनिश्चित हैं कि आप इस योजना को हटाना चाहते हैं?`,
  "8454c7c12286671b47684cef824ffba6": `"ध्यान दें! यदि आप अपना डेटा बदलते हैं तो वही आपके प्रोफ़ाइल डेटा में भी अपडेट किया जाएगा। क्या आप जारी रखना चाहते हैं?"`,
  "24a23d787190f2c4812ff9ab11847a72": `दर्जा:`,
  "85896a744f6fd84cbcd7bddb31fae8d7": `गतिविधि:`,
  "ccb168b5a6a86eb100dc2dee754a316b": `कारण:`,
  "85b7d770b94264f51f31d2d7a98f69f0": `आवेदन को वापस लेना`,
  "0ddc5e1c93fec761445a53ee9fd70285": `प्राधिकरण को भेजें`,
  "7a4813490daea6bbe84ed02f9534570b": `अनुप्रयोग और अनुलग्नक सत्यापित`,
  "f9e39c8a1b3348b654b73fcdebeb3265": `आवेदन अभी तक स्वीकृत नहीं है`,
  "6b76127f99b6f29c2a725da125d98760": `लाभ संवितरण`,
  "4296fd320beaf61dcf979ff634cf2f2d": `कार्यवाई की आवश्यकता:`,
  "fc341b619c2671a430365edefc100ff1": `पूर्ण कार्य`,
  "4f4b8b7d27a33c83089f30dba4989be5": `अनुप्रयोग देखें`,
  "2f70605f8a2f6c01ac06fe0f6d32b44c": `क्या आप सुनिश्चित हैं कि आप अपना आवेदन वापस लेना चाहते हैं?`,
  "6eaaa83f6b9b14dbed5e0779d91fffb5": `अनुप्रयोग ने अस्वीकार कर दिया`,
  "6187927c14aae6cab0f87b55ef75edb1": `फिर से लागू`,
  "864cacf59d640b51390faf29867c310e": `अगले लाभ के लिए आगे बढ़ें`,
  "16d2b386b2034b9488996466aaae0b57": `इतिहास`,
  "c5b2992c0919083c2d8907f8fe1441f6": `सामाजिक-आर्थिक विवरण`,
  "d79c1042de4ec2d7e6d84d3a8f436765": `व्यवसाय प्रोफ़ाइल`,
  "3c8d3371dfe92bb990493d3dda09717c": `शिक्षा प्रोफ़ाइल`,
  "181bfe2f225b876c687104878a6b43cc": `आवास विवरण`,
  "f081b3a821bf13173ac4ec3468122a8c": `स्वास्थ्य विवरण`,
  "fa284c99eeef0e1c874643e05a449214": `"ध्यान दें! आप सभी मूल्यों को अचयनित नहीं कर सकते। इस मान को अचयनित करने से पहले एक और मूल्य चुनें।"`,
  "311d38f551d45cb61e297748f08bb658": `आपका डेटा सफलतापूर्वक सहेजा गया है`,
  "4500d073ef5972cc935a59ff0b752d8b": `तक मान्य है`,
  "8de420ed8c345d37cf65a3494bff92ef": `मतदाता कार्ड`,
  "f6fb95a7bfd158a53680691f250e2273": `सरकारी शुल्क:`,
  "c51bae43a860107d2ddaddcc44c72355": `पते का प्रमाण (कोई भी)`,
  "a1cba2ed3414b72aa4d7651653d08900": `उम्र का प्रमाण (कोई भी)`,
  "330dd937743341b8c97a75ddbcfacf1b": `डाउनलोड फॉर्म के लिए लिंक:`,
  "de259fed6ef60355d0dd031c4a97e945": `अपलोड फॉर्म के लिए लिंक:`,
  "6204374d06a6ca36a20e887451db78af": `अधिक सेवाओं का अन्वेषण करें`,
  "98d047289f68350b76f20cd10f1db3d4": `आईडी का प्रमाण`,
  "1a01812e1888f4eb6dca36e8b1d39c04": `जन्मतिथि प्रमाण`,
  "0dcd6f339c0f72875972d60324db52ff": `आवेदन शुल्क विवरण`,
  "133e72d94045f209d146dd6fc01de185": `शुल्क विवरण`,
  "b6d11bb63dc2071d6cba2aaeba8c8a1d": `सुविधा शुल्क`,
  "719fec04166d6fa75f89cd29ad61fa8c": `करों`,
  "a876fbe73a6d039cb8c8566f6aa716d5": `कुल राशि`,
  "9d463ea3feb5afec51c20b4f62927ca3": `कूपन कोड`,
  "620a92a00838e523161dab899b76c764": `चुकाने के लिए कार्रवाई शुरू करो`,
  "6f861b42317b2333c01faa9b555b7e14": `इस कूपन के साथ बचत।`,
  "c6088ccfc87a825ceb53a15ce4d99510": `आपने <Amount> की कुल छूट का लाभ उठाया है`,
  "0e54fc6a9dba79c2bc03ac08f3bf4a27": `वाह!`,
  "c1d9a46634bb89273f7d95a5577f15ad": `कूपन छूट`,
  "1063e38cb53d94d386f21227fcd84717": `हटाना`,
  "ba8550da63869236a92fed41fba6e84f": `कूपन कोड सफलतापूर्वक लागू किया गया`,
  "f7a7e0e3897bac9ea4c83d53f6ad5a23": `अमान्य कूपन कोड।`,
  "90fe9a78f3ea30beeb6b7ef447d2b6dd": `कूपन कोड सफलतापूर्वक हटा दिया गया`,
  "af1b98adf7f686b84cd0b443e022b7a0": `श्रेणियाँ`,
  "11f18dd73f0c3d374d9d5c230cd43160": `युवा कौशल`,
  "5a6c0d2579b31142130f20104fa58ea6": `प्रमाण पत्र`,
  "c482980d384a9d0e7bc39e1140270870": `वित्त`,
  "605669cab962bf944d99ce89cf9e58d9": `स्वास्थ्य`,
  "5dac73aa49b9ccaca4b9b0d0ef615d10": `पुलिस`,
  "b2ece3493eed05158a9cde4a0d2da2a5": `सार्वजनिक शिकायत`,
  "94df2a6972ca1fa79411645fe9b42339": `उपयोगिता`,
  "f5f0c488ed56cf789d19a13ffba8abe3": `यातायात`,
  "0db377921f4ce762c62526131097968f": `सामान्य`,
  "bc34e4c4f36b0d66910a906449910f64": `क्या आप सुनिश्चित हैं कि आप इस सेवा को हटाना चाहते हैं?`,
  "1e22827dff05f5a1c044993aff539403": `आपके पास कोई सहेजा गया सेवा नहीं है।`,
  "b08f17b81d3b9b4f6287b87c53cfc97a": `आपके पास ड्राफ्ट में कोई सेवा नहीं है।`,
  "6f96ffbc03ad1b9ebb5c6e2ecfcbdc71": `आपने अभी तक कोई सेवा लागू नहीं की है।`,
  "daf87fe41645c86672e96857222a0909": `आवेदन जमा किया गया`,
  "54aeeea175c1509b7c56e0c984f9b4fa": `धन्यवाद, आपकी सेवा करने का अवसर देने के लिए। आपका आवेदन सफलतापूर्वक प्रस्तुत किया गया है।`,
  "f18c4dab2674eae1e8c287c683b66729": `आवेदन की तारीख`,
  "fb0728ffa9b40d444db91c818eb21a2b": `आवेदक का नाम`,
  "e500e94f457ddd88824c88fda548bed2": `आवेदन पहचान पत्र`,
  "ad0206b8eec486df629ce37169376f65": `भूमिका और जिम्मेदारियां:`,
  "6f2ad1cfe3c5b08cc53c62470f4cb1f7": `आवश्यक दस्तावेज़:`,
  "1d83797411218ecca1e5ff4d4705dfb0": `चयन प्रक्रिया:`,
  "ce9e1e989c012211d8bc83c5353b4495": `नौकरी आवेदन विवरण`,
  "18dcaa84c2ee447028f8450cd0380ba6": `शैक्षणिक विवरण:`,
  "93e9a018926429f6d0b0c032910a8eb6": `नौकरी आवेदन विवरण की समीक्षा करें`,
  "6f906132d3c6b88b57225fdcf76f6e71": `पूछे जाने वाले प्रश्न`,
  "2d8d66b9c9b540fcb8384167f1565b64": `कृपया वर्तनी की जाँच करें या कुछ और खोजने का प्रयास करें।`,
  "f73a506c52c2dba159fc0fbee823aace": `आप इस योजना के लिए पहले ही आवेदन कर चुके हैं। कृपया अपनी लागू योजनाओं से उसी के लिए अपने आवेदन की स्थिति देखें।`,
  "b73856e1c7ab3e647e28663d5e30fabe": `आप इस योजना के लिए पहले ही आवेदन कर चुके हैं। कृपया अपनी लागू सेवाओं से उसी के लिए अपने आवेदन की स्थिति देखें।`,
  "cd850a99c0895272f2cdf23586867b16": `आप इस योजना के लिए पहले ही आवेदन कर चुके हैं। कृपया अपनी नौकरियों से उसी के लिए अपने आवेदन की स्थिति देखें।`,
  "4e0b8852fc3f3d1a2b4c24c4602766dd": `इमेज JPG, PNG, PDF फाइल में होनी चाहिए और 1MB से कम होनी चाहिए।`,
  "5a9e7167eb5b7cb7bb46cf5c05eed59a": `भर्ती करना`,
  "91412465ea9169dfd901dd5e7c96dd99": `डालना`,
  "3835b71ace5673385a19d56bc938fda8": `फ़ील्ड फोटोग्राफ`,
  "4386624773a0ef6de5a5de0dd6f8facd": `दस्तावेज़ प्रकार दर्ज करें`,
  "fdba3d2a9c707e50f4eec34cd6bdd0d6": `"अपने ग्राहक को जानें" एक सरकारी योजना के लिए आवेदन करने वाले उपयोगकर्ता की पहचान की पहचान और सत्यापन करने की अनिवार्य प्रक्रिया है।`,
  "244773cb8f460196045c5b90659d2c9d": `आपके आवेदन को सफलतापूर्वक आपकी "ड्राफ्ट सेवाओं" में सहेजा गया है`,
  "bbcb2990551820fc4678835c14a84503": `फोटो निकालें`,
  "78e3e2ba60b81cf6cc24e6baf89eaa81": `व्यक्तिगत प्रमाण पत्र`,
  "88bcab139c5d6142b04bf77d7dd1c023": `आप पहले ही इस सेवा के लिए आवेदन कर चुके हैं। कृपया अपनी लागू सेवाओं से उसी के लिए अपने आवेदन की स्थिति देखें।`,
  "bf8cd5900a65efaa89931067cb1d3775": `आपने ड्राफ्ट में कोई योजना नहीं दी है।`,
  "beab92ac3c8a798a35aea2ad0809313f": `एक अतिथि के रूप में जारी रखें`,
  "95884374c7752d972101b70854caffe6": `प्रवेश या साइन अप`,
  "e44cb1c4a784a247f7296cc02f7f301c": `जिला का चयन करें`,
  "9aa060dc3e3bb3ffff9908c5e713a80c": `आपने अभी तक किसी भी सेवा के लिए आवेदन नहीं किया है।`,
  "383f419ea8c0b8142cbdc71e2e4b144a": `पिछला प्रश्न`,
  "fb188e808b65b9ee48a5c9d88fb92af2": `फिर से शुरू करें`,
  "db5eb84117d06047c97c9a0191b5fffe": `सहायता`,
  "bc80ae98530c6ba4e76ed34c58d81875": `मोबाइल ऐप के लिए Easygov डाउनलोड करें`,
  "85ce0cae173233410acbc380a7e531cd": `कृपया ध्यान दें कि यह पृष्ठ सरकार की वेबसाइटों / वेब पेजों के लिंक भी प्रदान करता है। मंत्रालयों/विभाग/संगठन। इन वेबसाइटों की सामग्री संबंधित संगठनों के स्वामित्व में हैं और उन्हें किसी भी आगे की जानकारी या सुझाव के लिए संपर्क किया जा सकता है`,
  "ad0a802ec8134e9210a5625933db50a7": `अस्वीकरण`,
  "26dbd93cbe6f1e56b1577d8e3cb7fda3": `सेवाओं का चयन करें`,
  "c6582b36b0f516684a873e8782517822": `योजनाओं और सेवाओं का चयन करें`,
  "b98287be44e1230cf144aa3af08ac82f": `आप अभी तक किसी भी योजना के लिए नामांकित नहीं हैं`,
  "449d3cf7dd09172024ebe93872410f0f": `अनुप्रयोग देखें`,
  "025d9b3a3c2a6a27d04c57c1d7cabc9f": `किसी कार्यवाही की आवश्यकता नहीं`,
  "06df33001c1d7187fdd81ea1f5b277aa": `कार्रवाई`,
  "adff901e227bf2b2200c4767fe773836": `कार्यों की जरूरत है`,
  "4ff84d2ee02fe6ef0ca2edc3cdecc0ed": `आवश्यक कार्य`,
  "c08c272bc5648735d560f0ba5114a256": `सहयोग टीम से संपर्क करें`,
  "fd2f820569d83784238e1027897b463e": `अपना नाम दर्ज करें`,
  "cda83d00666a7bfa8cde6808de4ef994": `संपर्क संख्या`,
  "a4d3b161ce1309df1c4e25df28694b7b": `प्रस्तुत करना`,
  "4c2a8fe7eaf24721cc7a9f0175115bd4": `संदेश`,
  "68d390535e8a7120e3f6942d8a2214a0": `अपना संदेश दर्ज करें`,
  "2f644b4a27e68f505c4f4ac2ffe3a8ac": `कृपया केवल अक्षर, संख्या, अल्पविराम, -, _, / दर्ज करें`,
  "58f7a43f2c3bd7854d99fbbbc3fa4ae9": `केवल अक्षर की अनुमति है।`,
  "4906d981bb592b32f6982fb4187c7b60": `एक मान्य आईएफएससी कोड दर्ज करें।`,
  "562d3d08919ae105c3ebc42d9607d266": `कृपया केवल अक्षर या संख्याएँ दर्ज करें`,
  "fab2bb673b21bef95ee70b99dbd7f9fc": `केवल 11 वर्णों की अनुमति है।`,
  "a7eb433676475fee54727c14ad880b8c": `केवल 150 वर्णों की अनुमति है।`,
  "7c58cde3b827bc7d57cbf77865046169": `कृपया केवल नंबर दर्ज करें`,
  "544b0264d55a1e4cf476b279240d9be4": `केवल दस अंकों की अनुमति है। कृपया 0 या +91 उपसर्ग न करें`,
  "73f0d980e48b8141e0776e02d96a872f": `केवल बारह अंकों की अनुमति है।`,
  "0ce98bd973419ff33921a2275e7903b9": `कृपया एक वैध पैन नंबर दर्ज करें।`,
  "c6f91b305e91a0053748310de170a73c": `केवल छह अंकों की अनुमति है`,
  "9f44bd93c8988c682d5ef5369fd11f47": `कृपया वैध ईमेल दर्ज़ करें`,
  "10803b83a68db8f7e7a33e3b41e184d0": `जन्म की तारीख`,
  "ac598199ea0dd028ade4677a435caf4b": `एक वैध आधार संख्या दर्ज करें`,
  "d0de720ccdffea8490dfd2893c50ebc5": `यह {{displayName}} से अधिक होना चाहिए`,
  "ced6e171e13ebd5c37415c822706b9d4": `यह {{displayName}} से कम होना चाहिए`,
  "d1a1cb2bd14fdd9eec972f162cfd1ee0": `यह {{displayName}} के बराबर होना चाहिए`,
  "9483135517d156229d0439dff74b1ca0": `यह {{displayName}} से अधिक या बराबर होना चाहिए`,
  "01e9cd748e852b1ae389b980bff195b0": `यह {{displayName}} से कम या बराबर होना चाहिए`,
  "dc236871b26ba387e1284445caa99df4": `आपकी सेवा करने का अवसर देने के लिए।`,
  "2102fff7bb4de45c9e616e3f01b6810f": `आपका आवेदन सफलतापूर्वक प्रस्तुत किया गया है।`,
  "dc9466f3d424b4acba2fc5c9f58d4f8a": `क्षमा करें, आप पात्र नहीं हैं`,
  "a9198ed7d89e246a80078db84d2722e8": `आपका फ़ोन नंबर या पासवर्ड मेल नहीं खाता है`,
  "8ac0a98c8c2f2c86396e34b6b5d63e38": `एक ही नाम के साथ प्रोफ़ाइल पहले से मौजूद है`,
  "4ae112e33b3189c9fa04369b51259d76": `एक ही नाम और संबंध के साथ प्रोफ़ाइल पहले से मौजूद है`,
  "b0fb09a28a4a52a1b3ca2656dec0435e": `क्या आप सुनिश्चित हैं कि आप इस दस्तावेज़ को हटाना चाहते हैं?`,
  "e384b6a4760d80be609d897414cfe680": `आप एक पंजीकृत उपयोगकर्ता नहीं हैं, आप आगे बढ़ने के लिए या तो "साइन अप" या "OTP के साथ लॉगिन" कर सकते हैं`,
  "c2cd85ffa55b2ec9b049b7eb131d3a9f": `दर्ज किए गए पासवर्ड मेल नहीं खाते। पुनः प्रयास करें`,
  "3a816ee692e09b3cac6ef18e14071bd4": `आपका पासवर्ड सफल रूप से सेट किया गया है`,
  "764d4847b734121ceb58e5c8195fa5b6": `आपकी प्रतिक्रिया हमें सुधार करने और यह सुनिश्चित करने के लिए नए अवसरों की खोज करने की अनुमति देती है कि आपके पास सबसे अच्छा ऐप अनुभव है।`,
  "701d895fbc64569aa143ca6c44274139": `आप OTP उत्पन्न करने के लिए अधिकतम सीमा से अधिक हो गए, कृपया कुछ समय बाद फिर से प्रयास करें।`,
  "0bdfc4d562c9b0ccfaa4c0f6d4cac98f": `आपने अभी तक किसी भी योजना के लिए आवेदन नहीं किया है।`,
  "4391bf5189e3e13c4aa4cb314c605ac8": `आपने अभी तक कोई योजना नहीं बची है।`,
  "92202504591d549b89340d26ffc0753a": `यह नौकरी आपके "मेरी नौकरियों" में बच गई है`,
  "af6ebe0c0e89201cb2294f32955f7b15": `एक समय में केवल 20 दस्तावेजों की अनुमति है।`,
  "b1481f771aee527fd910fa8d8672cd19": `क्या आप सुनिश्चित हैं कि आप इस अधिसूचना को हटाना चाहते हैं?`,
  "06a6b009c19d5816574cdc7800d10eb5": `आपका अनुरोध सफलतापूर्वक उठाया गया है।`,
  "b941bc7a9d4fe7a8c7c31ed440754285": `समीक्षा आवेदन`,
  "e56b98da835e8f4a018502b121520ac7": `प्रयास बाकी है`,
  "416828262df288445e72ca1d3661f993": `चेतावनी - लगातार 4 असफल प्रयासों के बाद, आपका खाता बंद हो जाएगा`,
  "9f978addf10226b03278e4932348ba98": `केवल 40 वर्णों की अनुमति है।`,
  "69f076c339373e00e9e841da965f4208": `अद्यतन को अपडेट करें`,
  "48a82e0b2bd5818f110e55bb6f14aedc": `आपके द्वारा प्रदान की गई निम्नलिखित जानकारी सरकारी रिकॉर्ड से मेल नहीं खाती`,
  "5c37ad5c0dd6e318af899b3c8fcbe886": `क्षमा करें, हम स्रोत सिस्टम से आपका डेटा लाने में असमर्थ हैं। आप सत्यापन के बिना आगे बढ़ सकते हैं या बाद में कोशिश कर सकते हैं।`,
  "cdb2033fc9a30d6d6c1abe401cd1e37b": `बाद में प्रयास करें`,
  "f8d855b26faffa0f8779fb44632d6bc1": `अमान्य कैप्टचा कोड`,
  "d53b4d564b9054db9b4b4a50e713197f": `कृपया यहां कैप्चा दर्ज करें`,
  "ed001fb3ff7eaca9bbfbaacddc9d5a72": `कोई इतिहास नहीं मिला`,
  "4934d9bc9ebd024d4106d5e1f093ae79": `आगंतुकों की गणना`,
  "f7ce7cb4b450acfe0b4cef36c3bdec2d": `आपका बैंक खाता वर्तमान में निष्क्रिय है। आप आगे बढ़ सकते हैं, लेकिन लाभ प्राप्त करने के लिए जल्द से जल्द अपना बैंक खाता सक्रिय कर सकते हैं, यदि आपका आवेदन अनुमोदित हो जाता है।`,
  "df9e5c88861b08c33b2d46de8d7037e9": `आपका बैंक खाता वर्तमान में रद्द कर दिया गया है। आप आगे बढ़ सकते हैं, लेकिन लाभ प्राप्त करने के लिए जल्द से जल्द अपना बैंक खाता सक्रिय कर सकते हैं, यदि आपका आवेदन अनुमोदित हो जाता है।`,
  "3fc95e40b475f7269e64f25a1c4aef3b": `आपका बैंक खाता आपके आधार से जुड़ा नहीं है। आप आगे आगे बढ़ सकते हैं, लेकिन अपने बैंक खाते को अपने आधार के साथ जुड़ा हुआ है, जो कि लाभ प्राप्त करने के लिए जल्द से जल्द, यदि आपका आवेदन अनुमोदित हो जाता है।`,
  "1c35d003a65da48bf9f6d6fc69271d5a": `क्षमा करें, सिस्टम त्रुटि के कारण आपका आवेदन जमा नहीं किया जा सकता है। बाद में पुन: प्रयास करें।`,
  "8e437cbd840bce5c5edc0ef8164de6b7": `यदि आप अपने विवरण को अपडेट या बदलते हैं, तो आपका डेटा अस्वीकृत हो जाएगा और आपको अद्यतन किए गए डेटा के लिए दस्तावेज़ प्रूफ अपलोड करने के लिए कहा जाएगा। जारी रखने के लिए "आगे बढ़ें" पर क्लिक करें।`,
  "b010e3be9f81072a9808ed4a5dd0e45b": `निम्नलिखित जानकारी सर्वेक्षण में आपके द्वारा प्रदान किए गए विवरण के साथ मेल नहीं खाती है:`,
  "01acb0344944f11d68aeecc9f5b574ea": `क्या आप प्रतिक्रिया (ओं) के साथ आगे बढ़ना चाहते हैं?`,
  "4ff70f7b2e5a74415de360150a6e59d3": `तुम्हारी`,
  "bd7aaae9a27a2a4d71214c002bcf50ce": `रिकॉर्ड के साथ मेल नहीं खाती।`,
  "ebfd339b0ac14aa79b325b53f06bd375": `आपके द्वारा दी गई जानकारी आपके आधार विवरण के साथ मेल नहीं खाती। आप आगे नहीं बढ़ सकते।`,
  "1e410e1b9903e7a99b93857f536b7e1d": `सत्यापित नहीं है`,
  "28b266f2bf0a4ecc2dec3b6ca24fcb9b": `डालना`,
  "68982db8041ee52dd9e5a6e527c8a1fa": `फिर से फसल`,
  "e7bf7d83a37666fd8749e433189b1c27": `कृपया प्रतीक्षा करें`,
  "c389f9e7d5ea93af9deb2e8f1f9c6d89": `अधिक जानने के लिए {{"0x0001F6C8"}} पर क्लिक करें।`,
  "f17816bcbe79e18bee49388fe84d2876": `OTP समाप्त हो गया।`,
  "1531d9dc798857eb85345d349bffc99b": `पीछे`,
  "7939de8a9958e01fb1f172e21c5a7a85": `कृपया सही जानकारी प्रदान करें, यह हमें उन योजनाओं को दिखाने में मदद करेगा जिनके लिए आप पात्र हैं।`,
  "bf58c4a9eac8e0ec82c158c3adfcd609": `विभिन्न श्रेणियों के तहत योजनाओं को वर्गीकृत किया गया है। आप अपनी पसंदीदा श्रेणी के अनुसार योजनाओं की जांच कर सकते हैं`,
  "09349e778786f7ed758021ddbed12ca6": `यह जानकारी हमें {Intent} श्रेणी के तहत योग्य योजनाओं को खोजने में मदद करेगी।`,
  "0d45c019e7e9237a316f0f16824015d2": `आपका प्रोफ़ाइल डेटा विभिन्न टैब के तहत संग्रहीत किया जाता है। आप अपनी किसी भी प्रोफ़ाइल जानकारी को संपादित कर सकते हैं।`,
  "3f52884ec08606e6e73426eaec8de195": `यह जानकारी हमें इस योजना के तहत प्राप्त होने वाले सटीक लाभ के बारे में विवरण प्रदान करने में मदद करेगी और लाभ का लाभ उठाने के लिए आवेदन प्रक्रिया।`,
  "c389f9e7d5ea93af9deb2e8f1f9c6d89": `अधिक जानने के लिए {{"0x0001F6C8"}} पर क्लिक करें।`,
  "5cc2bdab3db5aa21633656a0d55f7477": `आप आवेदन के साथ आगे नहीं बढ़ सकते`,
  "d5de543c0e83073f05a846d62cc83c81": `दस्तावेज़ पहले से ही उसी क्षेत्र के लिए अपलोड किया गया है।`,
  "fca8da28cbcbe094dc646872e7cdbc7b": `मान्य दर्ज करें`,
  "6fa2260c737f40fa1a3f63274c8fca48": `आप आगे नहीं बढ़ सकते। आप या तो प्रोफ़ाइल में या अपने आधार कार्ड में अपने विवरण को सही कर सकते हैं`,
  "3272e327ba9c82197c5c982487224925": `क्षमा करें, हम स्रोत सिस्टम से आपका डेटा लाने में असमर्थ हैं। बाद में पुन: प्रयास करें।`,
  "586637318049e6dbb03e991bc45cba06": `अपने खाते को हटाने के लिए आपको पहले अपने खाते से जुड़े सभी प्रोफाइल को हटाना होगा।`,
  "ce6d6092569f3f247659ceb51d57be95": `आप {{profilename}} प्रोफ़ाइल को नहीं हटा सकते हैं क्योंकि आपके पास अपनी लागू या नामांकित योजनाओं में एक चल रहा है`,
  "8d258e398e2dbc8099c984258fadce2a": `एक OTP आपके पंजीकृत मोबाइल नंबर पर भेजा गया है (xxx xxx {{phoneNumber}})`,
  "abb2e3d13ded4cea9275beafacd9f4c8": `इस योजना में अपनी रुचि बढ़ाने के लिए, "अपनी रुचि उत्पन्न करें" पर क्लिक करें और सरकार इस योजना का लाभ देने के लिए आप तक पहुंच जाएगी`,
  "493de8e0a6b5d4a10af30cf3105b9e1f": `अपनी रुचि उत्पन्न करें`,
  "c9b0246a8f3989724ec4958f91e380a7": `योजना विवरण`,
  "804ca01b219522a88d26c3002632a00e": `विवरण साझा करने के लिए धन्यवाद।`,
  "04b6d9b7eb73749476d958b2133f4bbe": `योजना अरेपन सर्वेक्षण`,
  "a5661f68fc78a5d4f15afa057fec7a9f": `आगे बढ़ने के लिए आपको सर्वेक्षण में अपना विवरण अपडेट करना होगा।`,
  "1e4531d1dc128abbc00b48e5c7b6ef48": `अद्यतन सर्वेक्षण`,
  "362e532b6ad1fda489c5909d437f02a3": `हम आपका विवरण प्राप्त कर रहे हैं, कृपया प्रतीक्षा करें ..`,
  "8a1b3c7d62e2d60d5be34d8e36a9768f": `व्यक्तिगत लॉगिन पर वापस`,
  "6737d50489b1cf1d4408e66b8591ca38": `आपके पास अपने स्कीम एप्लिकेशन पर लंबित कार्य हैं। अपने आवेदन के साथ आगे बढ़ने के लिए लंबित कार्यों को पूरा करें।`,
  "e37c99137b7396277137c1f73f8a86d5": `सीईजी का संयोग`,
  "706c7a936b977ec3c4e6603f888e3fce": `EasyGov द्वारा संचालित`,
  "598d42810b342196884f255912974d69": `क्रमांक संख्या`,
  "60e4e81c6f9c1938d77e8ded6d64643a": `स्क्रीन रीडर`,
  "15bbb9d0bbf25e8d2978de1168c749dc": `वेबसाइट`,
  "21d587949a2a2e89c958b622e244b405": `मुक्त/ वाणिज्यिक`,
  "d5c03f7b855d442a2a7f24024cf89960": `गैर -दृश्य डेस्कटॉप अभिगम (एनवीडीए)`,
  "f956e16f65a3a937e5ecfe4195d17d0d": `(बाहरी वेबसाइट जो एक नई विंडो में खुलती है)`,
  "b24ce0cd392a5b0b8dedc66c25213594": `मुक्त`,
  "c8ed8ec3dc8b21290b5d28ca56344553": `जबड़े`,
  "b2788e1ab6be65f1c38bf7d23cd081f1": `व्यावसायिक`,
  "ec18aa8148f59475130ff217d693b465": `खिड़की-आंखों`,
  "d96a273ceb2b5f83d710081d9c38440a": `जाने के लिए सिस्टम एक्सेस`,
  "c932fd98147348de9974252a0efcc7cb": `WebAnywhere`,
  "540431e2b3234a8698b4044f15ef1fff": `पीडीएफ सामग्री`,
  "801ab24683a4a8c433c6eb40c48bcd9d": `डाउनलोड`,
  "83f8a38f024082405d2fff921c2bb1d2": `एडोब एक्रोबेट रीडर`,
  "9d8c84ff52cfc2c2e0075e669edacffd": `शब्द फ़ाइलें`,
  "4db1a171aaf96723d4002cc41559ddf7": `बाहरी वेबसाइट जो एक नई विंडो में खुलती है`,
  "86c61b344697039b8a54bbef9e4959c3": `एक्सेल व्यूअर 2003 (2003 तक किसी भी संस्करण में)`,
  "04efb134468330da1430e32701160107": `एक्सेल के लिए माइक्रोसॉफ्ट ऑफिस संगतता पैक (2007 संस्करण के लिए)`,
  "a385a9bb61bb694694eef5d2f54c563a": `पावरपॉइंट प्रस्तुतियाँ`,
  "da67d56a74c01537a71a3ab0eda00faa": `पावर प्वाइंट के लिए माइक्रोसॉफ्ट कार्यालय संगतता पैक (2007 संस्करण के लिए)`,
  "bb625547a85b98c2c9f9f4121c72cd78": `फ्लैश सामग्री`,
  "ab69edd2798068928605717c6591462f": `एडोब फ्लैश प्लेयर`,
  "2c4fa0a307152ef814bbab8d4075d938": `ऑडियो फाइलें`,
  "da7be99fd6fac5c8a4065eb2c07cad88": `विंडोज़ मीडिया प्लेयर`,
  "5a5652ca26ee42fe73fdd79497cf9e21": `वर्ड व्यूअर (2003 तक किसी भी संस्करण में)`,
  "2e856241d3d995fefdb1b4b32f84a79b": `माइक्रोसॉफ्ट ऑफिस संगतता पैक वर्ड के लिए (2007 संस्करण के लिए)`,
  "64a443242750513eb56695e07aedcff2": `एक्सेल फाइलें`,
  "382b0f5185773fa0f67a8ed8056c7759": `एन/ए`,
  "462c18d5b89050fb1b7f8fca1e535af8": `प्रसंस्कृत`,
  "ccb26ac32c3cc7cc2f0fd1bbac3b8e2d": `आपके पास संसाधित में कोई सेवा नहीं है`,
  "fc44a0efc672761745c2e76aac6851d2": `आपके आवेदन को अस्वीकार कर दिया गया है क्योंकि`,
  "7d0db380a5b95a8ba1da0bca241abda1": `पर`,
  "1252044838fd74c4e8b506ef1302a8b5": `शिकायत`,
  "06627e6919d21853d887d00334ee5337": `शिकायत प्रबंधन`,
  "f940fbe4aec76e9784e7f15c4b92ac6d": `आपको संसाधित में कोई शिकायत नहीं है`,
  "2290dc1500a2b4548eb3b931f2e3609a": `आपके पास कोई भी शिकायत नहीं है।`,
  "b25b60dc1a5f7c17e09bc73174668d9a": `आपके पास ड्राफ्ट में कोई शिकायत नहीं है।`,
  "a02c58d421087ec9519620a29d68a6ec": `आपने अभी तक किसी भी शिकायत के लिए प्रस्तुत नहीं किया है।`,
  "b65f636dcbc018b1901e23a7c2adde90": `और ज्यादा खोजें`,
  "5c835c22429f25992b4371691ecde1a4": `प्रस्तुत`,
  "2535f5e4e0892c73a5fad023bf3e15e2": `मेरी शिकायतें`,
  "4351cfebe4b61d8aa5efa1d020710005": `राय`,
  "e9516387730903ffd7cfe3317e05e385": `इस शिकायत को आपकी "मेरी शिकायत" में बचाया गया है।`,
  "b70db24f2cb70fb266eb03da8f289ba5": `शिकायत देखें`,
  "646558064e10a12b1e8914e86eca3ca6": `आपके पास अपने सेवा एप्लिकेशन पर लंबित क्रियाएं हैं। अपने आवेदन के साथ आगे बढ़ने के लिए लंबित कार्यों को पूरा करें।`,
  "d2841171ea05a48fa5463b3f7c7c92d7": `आपके पास अपने शिकायत आवेदन पर लंबित कार्य हैं। अपने आवेदन के साथ आगे बढ़ने के लिए लंबित कार्यों को पूरा करें।`,
  "9279f823b01f13b7a8c04688289543cc": `आपका आवेदन सफलतापूर्वक आपके 'ड्राफ्ट शिकायत' में सहेजा गया।`,
  "af481c93dcae73d73afe9927076c8a09": `आप इस शिकायतों के लिए पहले ही आवेदन कर चुके हैं। कृपया अपनी लागू शिकायतों से उसी के लिए अपने आवेदन की स्थिति देखें।`,
  "ce972231a0faf31791c7ac0bf0d1da84": `यदि आपने कोई भुगतान किया है तो आपकी राशि वापस नहीं की जाएगी।`,
  "2ffef02ca513f8bbadbaf130a23097de": `आपके आवेदन को सफलतापूर्वक आपके "ड्राफ्ट शिकायतों" में सहेजा गया है`,
  "e55e425dbaf6b6449940eac8ea749092": `कृपया केवल अक्षर दर्ज करें।`,
  "64ff9e3809686d4ea3253a631cae4761": `कृपया 2 दशमलव स्थानों पर एक मान सही दर्ज करें।`,
  "7ed0ef23d527c2876015161e422f49ce": `कृपया अपना नाम कन्नड़ भाषा में दर्ज करें।`,
  "60ae728544ab5cb25dba9ff4b8f6aa95": `सेवा सिंधु के लिए आपका आवेदन संदर्भ संख्या है`,
  "2c0ed1f2f9239dbca46beca549190e00": `आधार को पते के प्रमाण के रूप में लिया जाएगा, कृपया आधार के रूप में जिले का चयन करें।`,
  "8355a53d777c5ac84a37970b5158aba5": `त्रुटि 205: आधार में आपका नाम राजस्व विभाग द्वारा जारी जाति या आय प्रमाण पत्र में विवरण के साथ मेल नहीं खाता है।`,
  "601f77894e71ae7d4922eda713dda66c": `त्रुटि 207: आपकी जाति और आय प्रमाण पत्र समाप्त हो गया है।`,
  "9182b3e8dc7965f2775a204095f4c992": `आपके द्वारा दर्ज किया गया OTP गलत है। कृपया सही OTP के साथ फिर से प्रयास करें।`,
  "6e0fe1003e84e34da97a6c4c9040b31b": `पिछड़े वर्गों के कल्याण विभाग के अरिवु एजुकेशनल लोन स्कीम (नवीकरण) के लिए आवेदन स्वीकार करने की अंतिम तिथि को 31 मार्च 2022 तक बढ़ाया गया है।`,
  "69d3a9de2fc1407f59e8936ca0c525a0": `माफ कीजिए, एक गलती हुई है। बाद में पुन: प्रयास करें।`,
  "7953c16d691da03c899391a39412fc9b": `आपके पास अपने स्कीम एप्लिकेशन पर लंबित कार्य हैं। अपने आवेदन के साथ आगे बढ़ने के लिए <i> {{startDate}} </i> से लंबित कार्यों को पूरा करें।`,
  "f4f4366332ac6949882de59c81b08de9": `आपके पास अपने स्कीम एप्लिकेशन पर लंबित कार्य हैं। अपने आवेदन के साथ आगे बढ़ने के लिए <i> {{startDate}} </i> के बाद से <i> {{enddate}} </i> से लंबित क्रियाओं को पूरा करें।`,
  "77b43c24220ee608380031b72f8017ce":`सेवा की श्रेणियां`,
  "e0e54c374f8b39a1520766e5924af100": `आपके परिवार और आपके लिए योजनाओं, सेवाओं और आजीविका को जानने का एक मंच`,
  "d56f6b073f573237a6c4aed91589e982": `आजीविका`,
  "e5930fadbc620e368c92561f381ead58": `आपके लिए आजीविका के विकल्प `,
  "192adf39ebd850e3e2e30971e885dc6d": `इस समय आपके राज्य में आजीविका के विकल्प नहीं है, हम लगातार अपडेट कर रहे हैं, कृपया पुनः जाँच करें।`,
  "TM_Alt51": "अनुलग्नक(ओं)",
  "TM_Hea1": "कुल राशि",
  "TM_Hea2": "संक्षिप्त विवरण",
  "TM_Sub56": "योग",
  "TM_Mod41":"भुगतान लंबित है",
  "TM_Mod40":"भुगतान सफल रहा",
  "TM_Alt72":"भुगतान विफल रहा",
  "TM_Pag48":"मदद और सहायता",
  "TM_Con33":"धन्यवाद, आपका आवेदन सफलतापूर्वक सबमिट कर दिया गया है।",
  "TM_Err34": "भुगतान विफल होने के कारण आपका आवेदन जमा नहीं किया जा सका।",
  "TM_Rec55": "आपके भुगतान की स्थिति की पुष्टि करने में समय लग रहा है। हम आपके बैंक से लगातार संपर्क कर रहे हैं। कृपया अगले 48 घंटों में कोई अतिरिक्त भुगतान न करें।",
  "TM_But13": "वापस जाएं",
  "TM_Alt124":"कृपया प्रतीक्षा करें",
  "TM_Rec59": "आपका पिछला भुगतान इस सेवा के लिए रुका हुआ है। भुगतान पूरा हो जाने पर हम आपको सूचित करेंगे। इस बीच आप बेझिझक हमारी अन्य सेवाओं के बारें में जानें।",
  "TM_Rec60":"आपके धैर्य के लिए धन्यवाद।",
  "TM_Rec61":"पेज अपने आप टाइम आउट हो जाएगा",
  "TM_Alt165":"भुगतान करने के लिए अपने मोबाइल डिवाइस के यूपीआई ऐप में क्यूआर कोड को स्कैन करें।",
  "TM_Pag39":"लभार्थी",
  "TM_Alt166":"भुगतान अस्वीकृत",
  "TM_Err39":"भुगतान अस्वीकृत होने के कारण आपका आवेदन जमा नहीं किया जा सका।",
  "cc4cbfe72c596683881033f36eac3e2f":"कृपया तब तक प्रतीक्षा करें जब तक हम आपके बैंक से आपके भुगतान की पुष्टि नहीं कर देते। इस प्रक्रिया में कुछ समय लग सकता है।",
  "7d54c690a3a707e692cceb6978e24bc1": "शुल्कविच्छेद",
}
export default language;