const language = {
    "9e66e6bd4069124578736528a0432752": `आमच्याबद्दल`,
    "0f5590b58efe9cfcce35e55a66280e59": `स्वीकारा आणि सुरू ठेवा`,
    "77ab8893d0d282a9f2781d5711883903": `जोडा`,
    "c40eba17959a63e52412cac8c38d467e": `प्रोफाइल जोडा`,
    "ac714b256ab565c91ade9e03e8c410f0": `आपला सर्व डेटा हटविला गेला आहे`,
    "b31b443baac1c6da4cb78dfaf2e5be44": `अनुप्रयोग दुवा`,
    "0ec157e0e15e12c56bfe1c72e0e7515a": `बदल लागू करा`,
    "181c1a0ee6db7d7f835eed29791749ab": `आपणास खात्री आहे की आपण ही योजना हटवू इच्छिता?`,
    "d937652cde254e2a9b93b74a4ee2a02b": `आपणास खात्री आहे की आपण कायमस्वरुपी {{name}} प्रोफाइल हटवू इच्छित आहात?`,
    "3ac1df709254c0e491c642aae5400814": `आपणास खात्री आहे की आपण कायमस्वरुपी {{name}} खाते हटवू इच्छिता?`,
    "1cbb32715ee74ee3447f48febcc04864": `आपणास खात्री आहे की आपण आपल्या सेव्ह केलेल्या सेवांमधून ही सेवा हटवू इच्छिता?`,
    "ea4788705e6873b424c65e91c2846b19": `रद्द करा`,
    "f4ec5f57bd4d31b803312d873be40da9": `बदला`,
    "6dd495f1c2ae29c8e9a6c4405f18e22e": `स्थान बदला`,
    "b1ad1d3aef325b23950a43cb82fd0670": `भाषा निवडा`,
    "714341d9339756588d1dc724991c2b9a": `इथे क्लिक करा`,
    "f72b489f19f748cf78a86c78d547ed91": `आपल्या स्थानाची पुष्टी करा`,
    "a0bfb8e59e6c13fc8d990781f77694fe": `सुरू`,
    "bee04ef1315b3f9562be34e2e28a7831": `खाते हटवा`,
    "3e9983cf1885a5ec9f5a5d8127137bd2": `प्रोफाइल हटवा`,
    "2ac7a924af94bf173f38eb31165efce3": `योजना हटवा`,
    "e0bb2f66e5b61be3235bf8969979de19": `जिल्हा`,
    "46a43a7757337712d4f6a3701a5c57dd": `फॉर्म डाउनलोड करा`,
    "4d7be8ac1b9cb2fd5843dbea7ce30344": `आपण अद्याप कोणतीही सेवा जतन केली नाही`,
    "edda2208040fe25b06d0d8f3af976651": `आपण कोणत्याही नोकर्‍या वाचवल्या नाहीत`,
    "f283a3e62b2809c07cf77075103c012f": `आपल्यासाठी सर्वोत्तम योग्य सरकारी कल्याण योजना, सेवा आणि नोकरी शोधण्यासाठी आम्हाला काही मूलभूत प्रोफाइल माहिती आवश्यक आहे.`,
    // "f283a3e62b2809c07cf77075103c012f":`सर्वोत्तम अनुकूल सरकारी कल्याण योजना शोधण्यासाठी लबाथीला काही मूलभूत प्रोफाइल माहितीची आवश्यकता आहे`,
    "ce8ae9da5b7cd6c3df2929543a9af92d": `ईमेल`,
    "01e8b0c4e059b594202e976860b2d481": `आपल्या स्वतःच्या भाषेत योजना आणि सेवा एक्सप्लोर करा`,
    "483a5da192f46e3cfe8391d3ef234fc4": `वारंवार विचारले जाणारे प्रश्न`,
    "37aaf54433bbfe989db8871121fe0ad8": `आमच्या मागे या:`,
    "e0c4332e8c13be976552a059f106354f": `सुरु करूया`,
    "48dee7c291ac7f19e499438b072da336": `शासकीय फी`,
    "2ceb32552d78024d91a123897636afcb": `EasyGov नागरिक सशक्तीकरण अॅप`,
    //"357aa540fd5c8ae584ba18359105de3d":`आपल्याकडे कोणत्याही योजनांबद्दल किंवा इतर कशाबद्दल काही प्रश्न आहे?`,
    "357aa540fd5c8ae584ba18359105de3d": `आपल्याकडे कोणतीही क्वेरी असल्यास आम्हाला कळवा आणि आम्ही 24 व्यवसाय तासात आपल्याकडे परत येऊ.`,
    "b3ba00d60ff5a9e11c15a6efbed13746": `आपले वर्तमान प्रोफाइल अद्यतनित करण्यासाठी “वर्तमान प्रोफाइल अद्यतनित करा” वर क्लिक करा आणि नवीन प्रोफाइल जोडण्यासाठी “प्रोफाइल जोडा” निवडा`,
    "12ceff2290bb9039beaa8f36d5dec226": `नोकर्‍या`,
    "5eb57a6678ca337f92884f49b578a908": `...`,
    "4994a8ffeba4ac3140beb89e8d41f174": `इंग्रजी`,
    "846eb834b6bfe81dbd412bc010f8cac7": `आम्हाला कळवा आणि आम्ही 24 व्यवसाय तासात आपल्याकडे परत येऊ`,
    "a58252e21aaf876bf7b64c05cce1c477": `दुवा कॉपी केला`,
    "ce5bf551379459c1c61d2a204061c455": `स्थान`,
    "ccffe10fe854018805baf0d3d9327285": `प्रोफाइल व्यवस्थापित करा`,
    "49ee3087348e8d44e1feda1917443987": `नाव`,
    "10ac3d04253ef7e1ddc73e6091c0cd55": `पुढे`,
    "bafd7322c6e97d25b6299b5d6fe8920b": `नाही`,
    "31916d996c9f53949315692bc897f0c7": `संबंधित नाही? पहात रहा`,
    "e81c4e4f2b7b93b481e13a8553c2ae1b": `किंवा`,
    "0d5e50bf1643118ee026e5d19226542a": `कृपया शब्दलेखन तपासा किंवा दुसरे काहीतरी शोधण्याचा प्रयत्न करा`,
    "64ed408b000d73143e6ab1b5acb7c26e": `कृपया प्रोफाइल निवडा`,
    "fa2ead697d9998cbc65c81384e6533d5": `गोपनीयता धोरण`,
    "7595f48afc2f088bc1ff3313ceeb3605": `अर्ज करण्याची प्रक्रिया`,
    "cce99c598cfdb9773ab041d54c3d973a": `प्रोफाइल`,
    "665d0095958d696322be90e4eb606977": `या नात्यासह प्रोफाइल आधीपासून अस्तित्वात आहे. आपण वर्तमान प्रोफाइल अद्यतनित करू शकता`,
    "27bcc51421612805a9c006f24a4d6dc6": `योजना काढा`,
    "f8b66e3c488bb1df16cb3c7bb21447f0": `आवश्यक कागदपत्रे`,
    "82609dd1953ccbfbb4e0d20623193b56": `घरी परतणे`,
    "c9cc8cce247e49bae79f15173ce97354": `जतन करा`,
    "c320cdeffafbb2d60d6d759619c6b5e2": `प्रोफाइल जतन करा`,
    "ab9753c948520fb02c20831980bad53a": `माझ्या योजना`,
    "827452688eed02a12178e96f924ac529": `योजना`,
    "cb80cf588ba5a0d3dc067b0b0ef219e9": `योजना`,
    "1925ba54f16355af0964df7dfa145761": `आपल्यासाठी योजना`,
    "13348442cc6a27032d2b4aa28b75a5d3": `शोधा`,
    "32df01b9cf0491a879250b58ba2744ba": `शोध परिणाम`,
    // "988cddb5e417156bd3603983dc0c32e5":`शोध योजना`,
    "0afcf4c1ba72c441b41fbafaab253fc6": `स्थान निवडा`,
    "be2933038f93e25262400220080c111f": `राज्य निवडा`,
    "dfe1d316ec9338e23c892b573667b9bb": `आपला पर्याय निवडा`,
    "c2ba7e785c49050f48da9aacc45c2b85": `सेवा`,
    "992a0f0542384f1ee5ef51b7cf4ae6c4": `सेवा`,
    "48ecd536efbb3622296aac8de326fa38": `क्षमस्व, प्रदान केलेल्या माहितीच्या आधारे, आपण योजनेसाठी पात्र नाही`,
    "4cd9a992dc66802a4b44c366881957d1": `क्षमस्व, कोणताही परिणाम आढळला नाही`,
    "f86301756ec6964cb96e2b80a38f4162": `क्षमस्व! आम्हाला आपल्यासाठी कोणत्याही जुळणार्‍या योजना सापडल्या नाहीत`,
    "46a2a41cc6e552044816a2d04634545d": `राज्य`,
    "efd9a0ba1e9d553a070028627fdcae18": `सर्वेक्षण तपशील`,
    "4cd61af85d0ec40f50349cd7c3054ace": `स्विच प्रोफाइल`,
    "1d8cd2bfc6943dcf70236f7d351572a0": `अटी व शर्ती`,
    "4f7e556abaf92ce8061ad57cc4e7edd1": `हे आवश्यक आहे`,
    "371999537b8bb6e7f0099b0c40e0e41d": `हे प्रोफाइल आधीच तयार केले आहे. आपण प्रोफाइल अद्यतनित करू शकत नाही.`,
    "9eed21a5afd3ba6841c523b0d70599c7": `योजना पाहण्यासाठी 2 पेक्षा कमी असलेल्या कोणत्याही श्रेणीवर क्लिक करा किंवा विशिष्ट श्रेणीसाठी सर्वेक्षण चालविण्यासाठी 2 पेक्षा जास्त श्रेणीवर क्लिक करा`,
    "37528894f18a085f9a078496a68eaaa8": `चर्चेत असलेला विषय`,
    "d876a9fe1aecb3ffc05a5639ebe22000": `पुन्हा प्रयत्न करा`,
    "06933067aafd48425d67bcb01bba5cb6": `अद्यतन`,
    "fba7f6c863513d97922faab074d3c716": `प्रोफाइल अद्यतनित करा`,
    "90b1f6651953fcf8b43ddc0da6760aaf": `वापरकर्ता प्रोफाइल`,
    "d8d7ad6abfd08c27baa8bedb78c72ddb": `सर्व पहा`,
    "acac138f2e6f039df2e1fed2f2c0bc44": `EasyGov मध्ये आपले स्वागत आहे`,
    "6ebb2e31989c843ea65feefa43237dad": `जेव्हा आपण आपल्या खात्यातून एखादे प्रोफाइल हटविता तेव्हा आपण त्या विशिष्ट प्रोफाइलसाठी संग्रहित कोणताही डेटा किंवा माहिती पुनर्प्राप्त करण्यात सक्षम होणार नाही. निवडलेल्या प्रोफाइलसाठी सर्व सक्रिय आणि जतन केलेल्या योजना देखील हटविल्या जातील`,
    "6a64fbe7b065fc8579f2521e0054de02": `जेव्हा आपण आपले लॅबार्थी खाते हटवता तेव्हा आपण आपल्या प्रोफाइलमध्ये संग्रहित केलेला कोणताही डेटा किंवा माहिती आपल्या खात्याशी संबंधित इतर प्रोफाइल पुनर्प्राप्त करण्यास सक्षम राहणार नाही. आपल्या सर्व सक्रिय आणि जतन केलेल्या योजना देखील हटविल्या जातील`,
    "93cba07454f06a4a960172bbd6e2a435": `होय`,
    "3aeee34bea95f2b0ced72be632778670": `आपण कोणत्याही योजना जतन केल्या नाहीत`,
    "cb32c835ee043e0e2faf4860e46669ae": `ही योजना/सेवा पुनरावलोकन अंतर्गत आहे. कृपया कधीतरी नंतर पुन्हा प्रयत्न करा.`,
    "c2e0ede88489afe5739c8973aa03a787": `आपल्या प्रोफाइलशी जुळणार्‍या कोणत्याही योजना नाहीत`,
    "28d2dfa9b435bd81cb2f7f0499b846b3": `योजना शोधा`,
    "52561ab2d4640b4e299e817b6ba79173": `लांबार्थी मध्ये आपले स्वागत आहे`,
    "fb0fc4b5fdb2bef7b1c42f2cfd523c2d": `प्रयत्न बाकी आहेत`,
    "e13e1cd5f02de37413f9497cc5b65de3": `आपले खाते 30 मिनिटांसाठी लॉक केले गेले आहे. कृपया त्या वेळेनंतर प्रयत्न करा`,
    "f2a6c498fb90eebdu72k7f888fce3b18": `आपले स्वागत आहे`,
    "1172b2ea87bed4861c8c43a201d53031": `माझ्या सेवा`,
    "bcc254b55c4a1babdf1dcb82c207506b": `फोन`,
    "8458bad978f4fd6d6c7f8ec2ec0824b4": `संकेतशब्द विसरलात?`,
    "d67850bd126f070221dcfd5fa6317043": `साइन अप करा`,
    "619d59e2ea9f9bb9fe287404dd3ca931": `सर्व सेवा`,
    "6b46ae48421828d9973deec5fa9aa0c3": `क्रमवारी`,
    "104f0a6879399d6285ab770ed0dbc257": `सेवा काढा`,
    "0b3937a9dd481d527fcc72adaa735b57": `लभार्थी खाते नाही?`,
    "2f453cfe638e57e27bb0c9512436111e": `सुरू`,
    "f2fdea440d768b85591e936a803c3631": `साइन इन करा`,
    "82b1fbd43ae27918a37b212a4f60a9ad": `प्रस्तुत करणे`,
    "31693e2e9aa2e5be1bf3ae45ede9c98d": `आपण पात्र नसलेल्या योजना`,
    "d43cd6a49673f0f486ed7fbea1a5fd8a": `आधार क्रमांक`,
    "3a06727e678189b8502408cb9b50b437": `प्रोफाइल बदला`,
    "0323de4f66a1700e2173e9bcdce02715": `बाहेर पडणे`,
    "644cd55dbe4d01e7e00fc7fc458f9b8f": `आपणास खात्री आहे की आपण लॉगआउट करू इच्छिता?`,
    "c0fb5959a7a8d3ea991f9edb0b1a9e85": `कृपया आयडी निवडा, आम्हाला आपल्यासाठी योजना मिळतील`,
    "827a37fb93758383027c582a39ec6162": `आयडीचा प्रकार`,
    "cb3441fa3e1291a76dd5ea72e656bca3": `कोणत्याही आयडीशिवाय सुरू ठेवा`,
    "99dea78007133396a7b8ed70578ac6ae": `लॉगिन`,
    "02aeaf2672eb06861c144004ef2248d2": `लॉगिन खाते`,
    "d4d208eb2d8ccd525a64be73f3521dc5": `अवैध प्रमाणपत्रे`,
    "e8055d9577ec8edf34ee15a88a937daa": `खाते नाही?`,
    "942a3ef743c4e176f75d5223455f7e1a": `आपला 10 अंकी फोन नंबर प्रविष्ट करा`,
    "21bdc5689c12595ae14298354d5550d5": `आधीपासूनच एक खाते आहे?`,
    "dc647eb65e6711e155375218212b3964": `संकेतशब्द`,
    "0b3e8b8021c9b2fafee35ae01ac2cfb9": `${window.projectName} अॅप आपल्यासाठी आणि आपल्या कुटुंबासाठी सर्वोत्तम योग्य सरकारी कल्याण योजना शोधण्यात मदत करेल.`,
    "05aca668fa6754247f5094c042fb64ac": `फोन सत्यापन`,
    "f9bc92a6602b9692a0ba578120c9c441": `आपला OTP कोड येथे प्रविष्ट करा`,
    "d9d1e60e50119d9752001d4196ee6b3c": `OTP`,
    "6e95a24fa2a98c2f59c96a24b182ccfd": `आपल्याला कोणताही कोड मिळाला नाही?`,
    "f922096c878b694ec7a3d9282a55904c": `नवीन कोड पुन्हा पाठवा`,
    "32b830cef750168335d96aff4dd98782": `माझ्या नोकर्‍या`,
    "bbd26bc8ce3623addc9c54c36f692f8d": `आपल्यासाठी नोकर्‍या`,
    "67cb88ed21d4232c4681a5e648554e09": `अर्ज फी`,
    "b90455b71eb5e2399be9d20e23c1a1db": `अर्ज करण्यासाठी शेवटची तारीख`,
    "bfe72f18052c940de13501ae9877c3b0": `अर्ज कसा करावा`,
    "4f1f44ef7ce0c8a111f33fe400a6e4d7": `निवड प्रक्रिया`,
    "0f5679acafa674831691cfa01d17501e": `नोकरीची सूचना`,
    "a45ca50bcc7830af2f84fd046648ec92": `आपल्या प्रोफाइलशी जुळणारी कोणतीही नोकरी नाहीत`,
    "c4bcece293eb1ad7f09d4cf9c2037ea6": `नोकर्‍या शोधा`,
    "5dc52ca9ffdc26147075682c92a0a853": `पदे`,
    "3f99ba6fbcb46f28fb24f271fc3c02c1": `नोकरी पाहण्यासाठी कोणत्याही श्रेणीवर क्लिक करा. कोणत्याही नोकरीसाठी सर्वेक्षण चालविण्यासाठी कार्डवर क्लिक करा`,
    "2078989a6c09fdd98efc35d3c1d1de3d": `नोकरी हटवा`,
    "08c1517355f6e7313c4d629a193ac69a": `आपणास खात्री आहे की आपण आपल्या जतन केलेल्या नोकरीतून हे काम हटवू इच्छिता?`,
    "e614dff8cba6f73ae7cee729fe0091dd": `अवैध OTP`,
    "c46c1e69c89811f03dc73feb09273409": `OTP आवश्यक आहे`,
    "ae3bb2a1ac61750150b606298091d38a": `नवीन संकेतशब्द`,
    "887f7db126221fe60d18c895d41dc8f6": `पासवर्डची पुष्टी करा`,
    "70d9be9b139893aa6c69b5e77e614311": `पुष्टी`,
    "20aedd1e6de4dcf8d115b5a7424c58d7": `संकेतशब्द आवश्यक आहे.`,
    "841a389ec69697c28df7b9739419d21d": `संकेतशब्द 6 वर्ण लांब असावा.`,
    "bcb9407d90fd17ccff50587f5edfe987": `संकेतशब्द जुळत नाही.`,
    "318721657b639c8ca02293211db68837": `फोन नंबर आवश्यक आहे`,
    "4ea3456b0e1b463f50b81c79230cf070": `अवैध दूरध्वनी क्रमांक`,
    "bd454fc3d32f4425d146d3e861409871": `वापरकर्ता आधीपासून अस्तित्वात आहे`,
    "ab48b978d197e315e92e904e36d26175": `फोन नंबर अवैध आहे`,
    "70729403b9560d3adb142977608b81d2": `OTP मर्यादा ओलांडली. 4 तासांनंतर प्रयत्न करा.`,
    "1e4dbc7eaa78468a3bc1448a3d68d906": `फोन नंबर`,
    "4f2df4c330b8df125a3d75f2d69a9b5e": `OTP पाठवा`,
    "9ec54ec336b65da6179cf750de33ecd7": `संकेतशब्द विसरलात`,
    "98adc700c19f071f07a714a703a6fc1e": `आयडी तपशील`,
    "f262fae0a2f17e8c1eb4e9eddb0160e4": `पुढे जा`,
    "49fb619c4872c491fae649c28b339ed8": `आपली क्रेडेन्शियल्स आमच्या रेकॉर्डशी जुळत नाहीत`,
    "58557a71f9811899313234decda72d5a": `आपले खाते 30 मिनिटांसाठी लॉक केले गेले आहे. कृपया त्या वेळेनंतर प्रयत्न करा.`,
    "e7665f483813a56e9410787ce2d174ef": `फोन नंबर आधीपासून वापरात आहे. कृपया साइन इन करण्यासाठी साइन इन करा किंवा दुसरा नंबर वापरा.`,
    "0a46fa5fb9e86e80fc94e229d1967699": `फोन नंबर नोंदणीकृत नाही. कृपया पुन्हा प्रयत्न करा`,
    "bdff8b1cbfb4d5623382d7b0678e707c": `संकेतशब्दामध्ये किमान एक अप्पर केस, एक लोअर केस, एक नंबर आणि एक विशेष वर्ण यासह किमान 8 वर्ण असणे आवश्यक आहे`,
    "d55085482b3e8da780755832c17d7d60": `शेवटची तारीख`,
    "988cddb5e417156bd3603983dc0c32e5": `शोध योजना आणि सेवा`,
    "261b39077b81875e115baa22bc6ce04c": `नोकरी पाहण्यासाठी विशिष्ट श्रेणीशी संबंधित आहे, कृपया श्रेणीवर क्लिक करा`,
    "bdb53070a3fc01196ae38b059a4b42ff": `नोकरी काढा`,
    "ede1fcad3d6bf808fc948df7d22e1536": `क्षमस्व! आम्हाला आपल्यासाठी कोणत्याही जुळणार्‍या नोकर्‍या सापडल्या नाहीत`,
    "0c4fa7a0201f434b0ef84494d0aac07a": `क्षमस्व, प्रदान केलेल्या माहितीच्या आधारे, आपण नोकरीसाठी पात्र नाही`,
    "e4defbb7cdf529fa3606749a82c06932": `पदे`,
    "25d2401d71f4ba1ba7685559c1b77927": `आपल्या प्रोफाइलच्या आधारे, आम्ही आपल्याला कोणत्याही नोकरीसाठी पात्र ठरलो नाही. <br> आम्ही सतत अधिक नोकर्‍या जोडत आहोत, कृपया नंतर तपासा.`,
    "ea7c2400c0ae5f58608cb5051ee852f3": `याक्षणी आपल्या राज्यात कोणतीही नोकरी कॉन्फिगर केलेली नाही, आम्ही सतत अद्यतनित करीत आहोत, कृपया पुन्हा आमच्याशी तपासा`,
    "24fe48030f7d3097d5882535b04c3fa8": `कालबाह्य झाले`,
    "9dc0186e2d5d3398ffee6ec964b15dc0": `कालबाह्य झालेल्या सर्व नोकर्‍या कालबाह्य तारखेपासून ३० दिवसांच्या कालावधीनंतर तुमच्या “माय जॉब्स” मधून काढून टाकल्या जातील.`,
    "34a934c0c86d866df9d14f8a6e90c637": `पात्र नसलेले कारण`,
    "a97ea56b0e00b2379736ae60869ff66a": `प्रश्न`,
    "8e9e3212461f14c8dbea6456c920bcbc": `पात्र मूल्ये`,
    "c4dce677291fa5bc05276ffe93c5d8f2": `आपण प्रदान केलेले मूल्य`,
    "9a917c4a4f546c6072bd61ae617763bf": `आपल्यासाठी योजना नाही`,
    "e57c13893a290ce882569b5638ba7b82": `आपण प्रविष्ट केलेला आयडी सापडला नाही. कृपया पुन्हा प्रयत्न करा.`,
    "e26859a59ede4ab8d8ea0213b68fa732": `स्त्रोत प्रणाली सध्या प्रतिसाद देत नाही, कृपया काही वेळा नंतर प्रयत्न करा.`,
    // "e26859a59ede4ab8d8ea0213b68fa732":`स्त्रोत प्रणाली सध्या प्रतिसाद देत नाही, कृपया आयडीशिवाय पुढे जा किंवा कधीतरी नंतर प्रयत्न करा.`,
    "d2235c7ecbbbae1bf62833dade7486f5": `प्रोफाइल तपशील गोल्डन रेकॉर्डमधून घेण्यात आला आहे.`,
    "346df6b73ae19a6619f26648cf415522": `अपात्र योजनांची यादी प्रोफाइल डेटावर आधारित आहे.`,
    "6e0fe1553e84e34da97a6c4c9040b31b": `लबाार्थी`,
    "27559de2125101ccb786807bceb46aa1": `भारत`,
    "936b6a0effe4990e8f5420a0099fa537": `EasyGov`,
    "8cf04a9734132302f96da8e113e80ce5": `मुख्यपृष्ठ`,
    "923b39c56654bb6264b6638ba8386b3c": `आपले प्रोफाइल तयार करण्यासाठी साइन इन करा/साइन अप करा`,
    "52f2ba99491a1bae953303a4eaf50196": `OTP सह लॉग इन करा`,
    "18e0c8084d60f55016d67d4e2ca507ce": `SSO सह लॉग इन करा`,
    "d3d77957f18f1e208cb5b0caa07bec5c": `अधिक योजना शोधा`,
    "6a26f548831e6a8c26bfbbd9f6ec61e0": `मदत`,
    "b2119dd0c37bb396e24199d5ffbd0c72": `स्क्रीन रीडर प्रवेश`,
    "34b6cd75171affba6957e308dcbd92be": `आवृत्ती`,
    "4221d3e17c6eca2ca6337251a3cf9c4e": `शेवटचे अद्यावत`,
    "ece2c7eaaac9f27b055e6e482ab776b9": `माहिती शोध`,
    "ad8f678ccf4a4a34b46f01c74f25b91b": `वापरकर्त्यांना वेबसाइटमध्ये माहितीमध्ये प्रवेश करण्यात मदत करण्यासाठी, “येथे शोध” पर्याय प्रदान केला गेला आहे. येथे, कन्नडामध्ये टाइप करण्याचा पर्याय उपलब्ध करुन दिला आहे. शोधत असताना, युनिकोड फॉन्टचा वापर अनिवार्य आहे. इंग्रजीमध्ये शोधण्याचा पर्याय देखील प्रदान केला आहे.`,
    "698aa21b21c2252839e5e54a0d2f12ef": `इंटरनेट प्रवेशयोग्यता`,
    "4ac2856693f5c8b4fc6cdaf669ed2d74": `जर इंटरनेट हळू किंवा अनुपलब्ध असेल तर वेबसाइट डिझाइनमध्ये बदल होऊ शकतात किंवा काही उपपृष्ठ उघडू शकत नाहीत.`,
    "0da22543a45316a826571412b3d5953c": `वेबसाइट वर्ल्ड वाईड वेब कन्सोर्टियम (डब्ल्यू 3 सी) वेब सामग्री प्रवेशयोग्यता मार्गदर्शक तत्त्वे (डब्ल्यूसीएजी) 2.0 लेव्हल एए चे पालन करते. हे व्हिज्युअल कमजोरी असलेल्या लोकांना स्क्रीन वाचकांसारख्या सहाय्यक तंत्रज्ञानाचा वापर करून वेबसाइटवर प्रवेश करण्यास सक्षम करेल. वेबसाइटची माहिती वेगवेगळ्या स्क्रीन वाचकांसह प्रवेशयोग्य आहे, जसे की जब्स, एनव्हीडीए, सफा, सुपरनोवा आणि विंडो-आयज.`,
    "cb976b928eacd65e6342cfa78bf196da": `खालील सारणीमध्ये भिन्न स्क्रीन वाचकांविषयी माहिती सूचीबद्ध केली आहे`,
    "786f669131d80fbed4572a520c28c14d": `Easygov मालकीची सामग्री तयार केली आणि मालकीची सामग्री`,
    "fc290a7672f96fd8b94a9659c8c02322": `| सर्व हक्क राखीव`,
    "6016a2b341113bf496b719905398ecd2": `कॉपीराइट`,
    "0e8a85115f2c3ef0058c70f30a9b6deb": `कृपया आपल्या नोंदणीकृत मोबाइल नंबरवर पाठविलेले OTP प्रविष्ट करा`,
    "34f8ccda5172e4eac9a8a014559b38c1": `कृपया आपला संकेतशब्द सेट करा`,
    "548899be111ad732b37786783dfbd6d8": `इंटरनेट कनेक्शन नाही`,
    "5c3a1593ca1d34226359df44f3d45f2f": `कृपया कधीतरी नंतर पुन्हा तपासा.`,
    '35043eeac5651298d4bb6c100e071745': `आपल्याला तृतीय-पक्षाच्या वेबसाइटवर पुनर्निर्देशित केले जाईल. आपण सुरू ठेवू इच्छिता?`,
    "6182e08f3974a70ac4883b5fceaa14c0": `कृपया आपल्या नोंदणीकृत मोबाइल नंबरवर पाठविलेले OTP प्रविष्ट करा`,
    "306fb6389c3b3fd2835af15cfc7c66e9": `कौटुंबिक आयडी बदला`,
    "63fc7651ee1dbf6868a43a5b5fa8b581": `केवळ वर्णांना परवानगी आहे`,
    "6903fbe8375d52217c15618170f4078a": `केवळ 30 वर्णांना परवानगी आहे`,
    "9ab37820a722ce13e80a3d0aa468d6dc": `*आवश्यक`,
    "0df1f18a2fa23cd60990e47692f99e07": `अनुप्रयोग दुवा`,
    "a60852f204ed8028c1c58808b746d115": `ठीक आहे`,
    "edbd5921e9c7168e04cbe6f75ee76174": `आपल्या प्रोफाइलच्या आधारे, आम्ही आपल्याला कोणत्याही योजनेसाठी पात्र ठरलो नाही. आम्ही सतत अधिक योजना जोडत आहोत, कृपया नंतर तपासा.`,
    "a203c39a340e1d45069b1ba64e1d003a": `लक्ष!`,
    "037673ab5a84897e0ee43ca37f7680f6": `आपण आपला डेटा बदलल्यास आपल्या प्रोफाइल डेटामध्ये देखील तो अद्यतनित केला जाईल. आपण सुरू ठेवू इच्छिता?`,
    "d1d16cede248b826a431ea11132567cb": `आपण आपला सत्यापित डेटा बदलल्यास, तो पुन्हा सत्यापित केला जाईल आणि आपली स्थिती बदलली जाईल. यामुळे आपल्या अनुप्रयोगाची नकार किंवा प्रक्रिया वाढण्याची शक्यता जास्त असू शकते.`,
    "23a0ca84a7fd64eee8248bd1dfc77185": `नाव बदल`,
    "3b383caaaa6113dd83b381b43a97d7ee": `या वापरकर्त्यासाठी आधीपासूनच एक प्रोफाइल अस्तित्त्वात आहे, नवीन फॅमिली आयडी जोडल्यास मागील तपशील काढेल. कृपया याची पुष्टी करा`,
    "e0efbdcea0abe735dbf887292a5670fa": `भाऊ`,
    "ad6e7652b1bdfb38783486c2c3d5e806": `स्व`,
    "19cda37369e2d2015e66b9d478ffbe15": `बहीण`,
    "81e053d78f01540e9cd6fb68a1c03976": `कोणीतरी`,
    "b8e18040dc07eead8e6741733653a740": `आई`,
    "a30ca91be97a9cedc0d2426e54b0e782": `वडील`,
    "784c01f6b735be57175e2f438297c741": `जोडीदार`,
    "1968ab9fdfedf558714bf6caec9a1234": `मुलगा`,
    "7bc1cb93f51f61bde0e90b20c81ebf88": `मुलगी`,
    "25f34d5943da3f09a2aa6358193a41bd": `अर्ज करण्यासाठी पुढे जा`,
    "79dff508ab2e4d272f0630d001271eb1": `अधिक नोकर्‍या एक्सप्लोर करा`,
    "b6ebe1e750185946ed41be9191410612": `लाबाथीद्वारे अर्ज करण्यास सक्षम होण्यासाठी आपल्याला तपशील प्रदान करणे आवश्यक आहे.`,
    "af1899084d8361b9afb9187da988ff9c": `“क्षमस्व, आम्ही आपला डेटा आणण्यात अक्षम आहोत”.`,
    "1b56218ddf0a290241d661d26253d51f": `केवळ {{attempts}} प्रयत्न बाकी`,
    "f299cff2484fdb361f4fc3189490d909": `आपण प्रदान केलेली खालील माहिती आपल्या सरकारच्या पुनरावृत्तीशी जुळली नाही:`,
    "1edf5362e5f4af6f370a7f34c3136a7a": `सत्यापन अयशस्वी`,
    "dcf9ac5128c95d88e068e223dfe01d27": `सत्यापन स्थिती`,
    "bc910f8bdf70f29374f496f05be0330c": `पहिले नाव`,
    "77587239bf4c54ea493c7033e1dbf636": `आडनाव`,
    "14fcbe1dd0f7a39f0195aba46cf89d17": `तपशील सत्यापित करा`,
    "b6121db5ee47deeb0ca42d5dea0b2225": `प्रयत्न अयशस्वी झाले`,
    "839301cec5574d7c3256afce4fb85467": `आपण आपल्या कौटुंबिक तपशीलांची पडताळणी करण्याच्या प्रयत्नांची वैध संख्या ओलांडली आहे. आपले सत्यापन अवरोधित केले गेले आहे`,
    "6733aa56fd001b9bf479650f3950688a": `आपल्याला स्कीम अनुप्रयोग पृष्ठाकडे निर्देशित केले जात आहे`,
    "9303c9bd4f8178680dc382adbfcd62af": `हे क्षेत्र आवश्यक आहे.`,
    "53a04cfc3d09c9b2dfb8349791c62bc1": `आपले नाव या कुटुंबातील कोणत्याही सदस्याशी जुळले नाही`,
    "1ca35256fcb85c1ba9ea7ac1095462ad": `आपण आपल्या कौटुंबिक तपशीलांची पडताळणी करण्याच्या प्रयत्नांची वैध संख्या ओलांडली आहे. आपले सत्यापन 3 तास अवरोधित केले गेले आहे`,
    "0e3a917198575c463bb4fdcb7332b03a": `अवैध आधार कार्ड नंबर`,
    "630058029627ce58e7571e7a5b6ec591": `पूर्ण नाव`,
    "072355e896ac1a999d2fa0aa208f94ee": `ही योजना आपल्या “माझ्या योजनांमध्ये” जतन केली गेली आहे.`,
    "789189638f8a301304dfadeb61036dd9": `ही सेवा आपल्या “माझ्या सेवा” मध्ये जतन केली गेली आहे.`,
    "ab8023b4fc2395738b8fd2df440641a9": `ही नोकरी आपल्या “माझ्या नोकरी” मध्ये वाचली आहे.`,
    "671dec8fc4e3dc847c1b0d7b67b4e6e1": `संबंध`,
    "f252b4e2f3b2963d330c8cde8e9cdbd9": `उपलब्ध असल्यास`,
    "ef51899b57258db72f15b4703a8a86ba": `नागरिकांना पॅन इंडिया सरकार कल्याण योजना, सेवा आणि नोकरीसाठी कारवाई करण्यायोग्य माहितीवर प्रवेश करण्यासाठी एकच व्यासपीठ.`,
    //New Translations for Phase 1:`#VALUE!`,
    "7510e00267e89c78470e79b42ecd6463": `आपल्या स्वत: च्या भाषेत अ‍ॅप एक्सप्लोर करा`,
    "83218ac34c1834c26781fe4bde918ee4": `आपले स्वागत आहे`,
    "1d00e7dce692e8dc3f6877f035e3a616": `किंवा`,
    "e0cf58fa3413df99ae83109ebb9a41a3": `OTP रीसंड करा`,
    "6ed83b24865902c6fd90bbd15247e042": `सेटअप संकेतशब्द`,
    "9157806ea93e82c6843e29353af0339f": `OTP विनंती करा`,
    "69f18e98d2dd54118ade84732dc3a5e0": `पिन सत्यापित करा`,
    "2dd4472245a696bc0b4b944db2a8b519": `वैयक्तिक`,
    "a274f4d4670213a9045ce258c6c56b80": `अधिसूचना`,
    "407456e575dee5a4b84a19a7111a4a4d": `जेव्हा आपण 2 नंबर कमी असलेल्या कोणत्याही योजनेच्या श्रेणीवर क्लिक करता तेव्हा आपण पात्र ठरलेल्या योजना पाहण्यास सक्षम आहात, जेव्हा आपण 2 पेक्षा जास्त संख्येसह कोणत्याही योजनेच्या श्रेणीवर क्लिक करता तेव्हा श्रेणी विशिष्ट सर्वेक्षण आपल्याला अधिक देईल. निवडलेल्या श्रेणीसाठी योजनांची परिष्कृत यादी.`,
    "9139eb8e78808acbd15244d4eb9f1bc3": `अधिक योजना एक्सप्लोर करा`,
    "c61dd5f0e5b5389306ad5221ec2cd795": `ही योजना आपल्या “माझ्या योजनांमध्ये” जतन केली गेली आहे`,
    "f2a6c498fb90ee345d997f888fce3b18": `हटवा`,
    "469bad4859f3c33fcb8ce09314c3e895": `पुनरावलोकन सर्वेक्षण तपशील`,
    "7dce122004969d56ae2e0245cb754d35": `सुधारणे`,
    "c5984b3fe02f7b06d77fb538d842b0fc": `सर्वेक्षण तपशील अद्यतनित करा`,
    "6cf33ce1a7a11a7178131567c777b637": `समान नाव आणि नातेसंबंध असलेले प्रोफाइल आधीपासून अस्तित्वात आहे`,
    "fffcb4d1e12c96b2f571f77ad25878b8": `कारण`,
    "e8d89b6875d217a990d47701138e687a": `आपणास खात्री आहे की आपण हा डेटा हटवू इच्छिता?`,
    "aa98db08fed41a99a00aac36f521540f": `शेवटचे सत्यापित`,
    "5da618e8e4b89c66fe86e32cdafde142": `पासून`,
    "253ec560db0637f6a0e53aefcbd4be0c": `जेव्हा आपण लॅबार्थी खाते हटवता तेव्हा आपण आपल्या प्रोफाइलमध्ये सेव्ह केलेला कोणताही डेटा किंवा माहिती पुनर्प्राप्त करण्यात सक्षम होणार नाही. सर्व जतन केलेल्या योजना आणि {{name}} साठी संग्रहित माहिती देखील हटविली जाईल.`,
    "c0e70d5bfc28ae3aadfb8f85697c54d5": `आपला डेटा यशस्वीरित्या अद्यतनित केला गेला आहे.`,
    "09b73aba9204a05ba5eaf0ea471fdc8c": `EasyGov हा सुराज्या सर्व्हिस प्रायव्हेट लिमिटेडचा नोंदणीकृत ट्रेडमार्क आहे. EasyGov प्लॅटफॉर्म-ए-ए-सर्व्हिस मॉडेल सरकारी कल्याणला पुराणमतवादी, प्रतिक्रियाशील आणि प्रोग्राम केंद्रीत होण्यापासून प्रगतीशील, सक्रिय आणि कौटुंबिक केंद्रित होण्यापासून टप्प्याटप्प्याने रूपांतरित करते. EasyGov विविध सरकारांना कल्याणकारी व्यवस्थेचा हा जटिल प्रवास नागरिकांच्या फायद्यांसाठी सोडविण्यास मदत करीत आहे. कृपया अधिक माहितीसाठी <a href='https://easygov.co.in/' target='_blank'> https://easygov.co.in/</a> वर भेट द्या`,
    "8f241c62a9523b05bf0b6b16a09d856d": `भाषा बदला`,
    "1ccbe6084301f9b1e18e3e7caf723ae1": `आपणास खात्री आहे की आपण लॉगआउट करू इच्छिता?`,
    "32ca0a0f69103bad1e6f134d5a8e36d7": `विशिष्ट श्रेणीसाठी नोकर्‍या पाहण्यासाठी, कृपया कोणत्याही नोकरी श्रेणीवर क्लिक करा`,
    "d6e6cb19e3b9c02f89d6cd54cfa7c613": `व्यवसाय`,
    "b1c94ca2fbc3e78fc30069c8d0f01680": `सर्व`,
    "1dd1c5fb7f25cd41b291d43a89e3aefd": `आज`,
    "ebfe9ce86e6e9fb953aa7a25b59c1956": `काल`,
    "4b2ef3655b5d6d48f1fa41892d0043b9": `सूचना नाही`,
    "094f537cfb139894f1412200c78e87b1": `आपणास खात्री आहे की आपण निवडलेल्या सूचना हटवू इच्छिता?`,
    "4201c11dc26b42deb90cb119c0b12fdb": `सूचना डाउनलोड करा`,
    "ae45c43efe0011f67ec52003c0d8f916": `<b> इशारा </b>: 'संकेतशब्द' मध्ये कमीतकमी एक अप्पर केस, एक लोअर केस, एक नंबर आणि एक विशेष वर्ण यासह किमान 8 <br> वर्ण असणे आवश्यक आहे.`,
    "248336101b461380a4b2391a7625493d": `जतन केले`,
    "f03ab16cd58372c77ba45a3d9a5a1cb9": `मसुदा`,
    "a4d158b65f20725f9fe9c1763e9805c8": `खाजगी प्रायोजित`,
    "7fe12d923db43febcc5d8ea18df632ca": `आपला केवायसी दस्तऐवज निवडा`,
    "d8ce714c8a76133a8d5746e3bfdd7ef7": `केवायसी`,
    "4f67fe16b274bf31a67539fbedb8f8d3": `दस्तऐवज प्रकार`,
    "5127fa87ed375848481bfe9c786d8022": `आपले केवायसी भरा`,
    "8f75e3041034138aba310ba3f097ed24": `दस्तऐवज क्रमांक`,
    "9abfb506bebd1e72069f0be0014986dc": `अवैध दस्तऐवज क्रमांक`,
    "ecfffd1bad9944f6a50e369f63268cff": `क्यूआर स्कॅन`,
    "c541f95d69a4a507b87b32d05dd6d73c": `कोड फ्रेमच्या आत ठेवा`,
    "3876661cb83fa41284f828bd8d7fbb7b": `केवायसी कागदपत्रे सत्यापित करण्यासाठी आणि केवायसी प्रक्रियेच्या पूर्णतेसाठी अपलोड केल्या पाहिजेत.`,
    "cc1d6f3625c4eb671d76b6ebf3f6f544": `आपला ग्राहक जाणून घ्या`,
    "08ac502bbb7652c683c1da97c7fdeb94": `{{xxxx}} आपल्या मोबाइल नंबरवर एक OTP पाठविला आहे`,
    "d19a88ee579190614084c2ea84379ac7": `OTP रीसंड करा (1:00)`,
    "7397e92003e187474e5eab39b27fcb6b": `क्षमस्व, आम्ही स्त्रोत प्रणालींमधून आपला डेटा आणण्यात अक्षम आहोत. कृपया पुन्हा प्रयत्न करा.`,
    "a943d4ac1dd2e0048bcbf85225bf2811": `आपण OTP व्युत्पन्न करण्यासाठी कमाल मर्यादा ओलांडली आहे, कृपया 3 तासांनंतर पुन्हा प्रयत्न करा`,
    "8dac090b39cf9b3bff9a9503531d04cf": `आपले खाते सध्या अवरोधित केले आहे, कृपया काही काळानंतर प्रयत्न करा.`,
    "08798caaee5cc8ea1282883901c8810b": `उर्वरित प्रयत्न - {{Number}} चेतावणी - सलग 4 अयशस्वी प्रयत्नांनंतर आपले खाते लॉक केले जाईल.`,
    "33018956b3ffe20d413e3c6780f19aff": `केवायसी तपशीलांची पुष्टी करा`,
    "90f35a35415ca3b4c623d7c515fce1c0": `लक्ष! आपण आपला तपशील अद्यतनित केल्यास किंवा बदलल्यास आपला डेटा असत्यापित होईल आणि आपण अद्यतनित केलेल्या डेटासाठी दस्तऐवज पुरावा अपलोड करण्यास सांगितले जाईल. पुढे जाण्यासाठी "सुरू ठेवा" वर क्लिक करा`,
    "aee68a98fd803f091082faf867c0f62b": `केवायसी फॉर्म भरा`,
    "2e853d869dd0c3cb0a64e4938ee90647": `अवैध पिन कोड`,
    "8f3124e142a4000fe04a581f4f41c8da": `अवैध {{labour card}} क्रमांक`,
    "dbb362212f1b8118f17633521d1e72f7": `आपला अर्ज आपल्या 'मसुद्याच्या योजनेत' यशस्वीरित्या जतन केला.`,
    "5c93310dd0291e121181e830cdda892e": `गॅलरी`,
    "967d35e40f3f95b1f538bd248640bf3b": `कॅमेरा`,
    "37bed5f24fa3752c7830c021501539a0": `आपणास खात्री आहे की आपण फोटो काढू इच्छिता?`,
    "1a263d755f169c2499c1a55f7a465b93": `आपला दस्तऐवज आयडी सध्या अवरोधित केला आहे, कृपया काही काळानंतर प्रयत्न करा.`,
    "689707127ddb398dc4b447e1dffa3a47": `आपण आपले तपशील सत्यापित करण्याच्या प्रयत्नांची वैध संख्या ओलांडली आहे. आपले सत्यापन 3 तास अवरोधित केले आहे.`,
    "04c850a81052df23680d00d2aa1d891e": `केवायसी स्थिती`,
    "a441e519087df0d7c8572b9fafa14ae2": `तुम्ही दिलेली खालील माहिती तुमच्या सरकारी रेकॉर्डशी जुळत नाही.1. तुमचे नाव रेकॉर्डशी जुळले नाही.2. तुमचे वय रेकॉर्डशी जुळत नाही.`,
    "75514ce9dac260a1f7abd0d85ce6af46": `आपण प्रदान केलेला तपशील रेकॉर्डशी जुळत नाही`,
    "5955e277da6fb75982b0f651f4ae8b92": `आम्ही स्त्रोत प्रणालींमधून आपला डेटा आणण्यात अक्षम आहोत. कृपया पुन्हा प्रयत्न करा.`,
    "be60ac326400cc5ef0d173a833c4a40b": `आपले तपशील सत्यापित केले जात आहेत`,
    "d1083e84a9a7283ffb081263e8533509": `आपला अर्ज आपल्या ‘मसुद्याच्या योजनेमध्ये’ यशस्वीरित्या जतन केला गेला आहे`,
    "0bad551abed71c0a41ca8df804afec86": `दस्तऐवज वापरुन केवायसी`,
    "e6387f3e684958515af2947d8cdf01d7": `केवायसी फॉर्म अद्यतनित करा`,
    "9c7ed3d8ac60281e90519b4ebf07a9a2": `पत्ता निवडा`,
    "31e41095bfaa14799239e8d9ba7ad438": `अर्ज तपशील`,
    "e1e4c8c9ccd9fc39c391da4bcd093fb2": `ब्लॉक`,
    "b9eca8f59016e8ff69a2c76fa869d23f": `कामगार कार्ड अपलोड करा`,
    "6cf24d7a07132fe88b610e2b3d3def28": `स्वत: ची घोषणा`,
    "827b05360cd22ccbaf3d085aded71570": `पत्त्याचा पुरावा`,
    "f734cd7792862bebbbb87ddc54285376": `ओळखीचा पुरावा`,
    "5a8c686dfdba564a2801806f3827c124": `प्रतिमा JPG किंवा PNG स्वरूपात असणे आवश्यक आहे. PDF फाइल 1 एमबीपेक्षा कमी असणे आवश्यक आहे.`,
    "d495cdb8315e12e3dc5fd6121ed69cbc": `अवैध IFSC कोड`,
    "cd9f177eac6411501839f7868e38767b": `आपणास खात्री आहे की आपण हा दस्तऐवज हटवू इच्छिता?`,
    "565d2daf0ccaf00d354669e7551b81dd": `प्रोफाइल तपशीलांचे पुनरावलोकन करा`,
    "9895dd168483d1d34ab9ebfb2b6760be": `बँक निवडा`,
    "deb5c3f30f3a8381367392d6608fbe68": `अर्ज स्थिती`,
    "970324882e5bbfd2deef9e5b7e2d1160": `अनुप्रयोग डाउनलोड करा`,
    "03b55d8ed61b625c209cc42ea0a35b8c": `धन्यवाद!`,
    "8276f0b86b5ee98d03f2eafba3590bb0": `आपली सेवा करण्याची संधी देण्यासाठी. आपला अर्ज यशस्वीरित्या सबमिट केला गेला आहे.`,
    "ac3122aeddff176ae67d3df2b2198d19": `धन्यवाद! तुमचा अर्ज पडताळणीसाठी सबमिट केला गेला आहे, "लागू केलेले अर्ज" अंतर्गत अॅपवरून तुमची अर्जाची स्थिती तपासा.`,
    "73da163444abaee6a5c88532b8eda629": `तुमचा अनुभव कसा होता?`,
    "74eb855e4de6fe58228f03006c02fd8a": `ठीक आहे`,
    "d36ed38206da38c85af59039d18f1108": `<b> आपल्या अभिप्रायाचे वर्णन करा </b> (पर्यायी)`,
    "446e92f15cd245125ccd983ef968e692": `नको धन्यवाद!`,
    "634a1a1ed07518476cb7a4beab87185a": `भयानक`,
    "f1b68d66337a81cfa0d2076171cba2a8": `वाईट`,
    "45802158e78dd9584161629098018fe8": `चांगले`,
    "5709082e0d4e7a2cb92c2348acf297be": `आवडलं`,
    "c629e8ac2592361ec6474f07fb75f6fc": `आपला अभिप्राय आम्हाला सुधारित करण्यासाठी नवीन संधी शोधण्याची परवानगी देतो आणि आपल्याकडे सर्वोत्कृष्ट अ‍ॅपचा अनुभव आहे याची खात्री करुन घ्या.`,
    "871abe85eba547582d9e8f0f9c61efbd": `आम्हाला अभिप्राय पाठविल्याबद्दल धन्यवाद.`,
    "733d43480c8589b1368e5def6b480415": `लागू`,
    "a5b739036b4d0b2bc966291747198d9b": `नोंदणीकृत`,
    "0a4c051fb821b8e96d2bd564b893639b": `आपल्यासाठी सानुकूलित योजना नाहीत`,
    "9639e32cab248434a17ab32237cb3b71": `अर्ज करा`,
    "3f68e67dc6c397aaa9d1c24c356f754f": `सत्यापित`,
    "d63b911814d17bb4d541141b8531a458": `या योजना आपल्या "माझ्या योजना" मध्ये जतन केल्या गेल्या आहेत`,
    "454fd3b5167e532f28f4e3d47cd901fa": `आपल्याकडे अद्याप कोणतीही जतन केलेली योजना नाही`,
    "5e22b63835aef7976f3ca019841b1b28": `आपणास खात्री आहे की आपण ही योजना काढू इच्छिता?`,
    "8454c7c12286671b47684cef824ffba6": `"लक्ष! आपण आपला डेटा बदलल्यास आपल्या प्रोफाइल डेटामध्ये देखील तो अद्यतनित केला जाईल. आपण सुरू ठेवू इच्छिता?"`,
    "24a23d787190f2c4812ff9ab11847a72": `स्थिती:`,
    "85896a744f6fd84cbcd7bddb31fae8d7": `कृती:`,
    "ccb168b5a6a86eb100dc2dee754a316b": `कारणः`,
    "85b7d770b94264f51f31d2d7a98f69f0": `अर्ज मागे घ्या`,
    "0ddc5e1c93fec761445a53ee9fd70285": `प्राधिकरणास पाठवा`,
    "7a4813490daea6bbe84ed02f9534570b": `अनुप्रयोग आणि संलग्नक सत्यापित`,
    "f9e39c8a1b3348b654b73fcdebeb3265": `अर्ज अद्याप मंजूर झाला नाही`,
    "6b76127f99b6f29c2a725da125d98760": `लाभ वितरण`,
    "4296fd320beaf61dcf979ff634cf2f2d": `कृती आवश्यक:`,
    "fc341b619c2671a430365edefc100ff1": `पूर्ण क्रिया`,
    "4f4b8b7d27a33c83089f30dba4989be5": `अनुप्रयोग पहा`,
    "2f70605f8a2f6c01ac06fe0f6d32b44c": `आपणास खात्री आहे की आपण आपला अनुप्रयोग मागे घेऊ इच्छिता?`,
    "6eaaa83f6b9b14dbed5e0779d91fffb5": `अर्ज नाकारला`,
    "6187927c14aae6cab0f87b55ef75edb1": `पुन्हा अर्ज करा`,
    "864cacf59d640b51390faf29867c310e": `पुढील फायद्यासाठी पुढे जा`,
    "16d2b386b2034b9488996466aaae0b57": `इतिहास`,
    "c5b2992c0919083c2d8907f8fe1441f6": `सामाजिक-आर्थिक तपशील`,
    "d79c1042de4ec2d7e6d84d3a8f436765": `व्यवसाय प्रोफाइल`,
    "3c8d3371dfe92bb990493d3dda09717c": `शिक्षण प्रोफाइल`,
    "181bfe2f225b876c687104878a6b43cc": `गृहनिर्माण तपशील`,
    "f081b3a821bf13173ac4ec3468122a8c": `आरोग्य तपशील`,
    "fa284c99eeef0e1c874643e05a449214": `"लक्ष! आपण सर्व मूल्ये निवडू शकत नाही. हे मूल्य निवडण्यापूर्वी दुसरे मूल्य निवडा."`,
    "311d38f551d45cb61e297748f08bb658": `आपला डेटा यशस्वीरित्या जतन केला गेला आहे`,
    "4500d073ef5972cc935a59ff0b752d8b": `पर्यंत वैध`,
    "8de420ed8c345d37cf65a3494bff92ef": `मतदार कार्ड`,
    "f6fb95a7bfd158a53680691f250e2273": `शासकीय फी:`,
    "c51bae43a860107d2ddaddcc44c72355": `पत्त्याचा पुरावा (कोणीही)`,
    "a1cba2ed3414b72aa4d7651653d08900": `वयाचा पुरावा (कोणीही)`,
    "330dd937743341b8c97a75ddbcfacf1b": `फॉर्म डाउनलोड करण्यासाठी दुवा:`,
    "de259fed6ef60355d0dd031c4a97e945": `अपलोड फॉर्मचा दुवा:`,
    "6204374d06a6ca36a20e887451db78af": `अधिक सेवा एक्सप्लोर करा`,
    "98d047289f68350b76f20cd10f1db3d4": `आयडीचा पुरावा`,
    "1a01812e1888f4eb6dca36e8b1d39c04": `डीओबी पुरावा`,
    "0dcd6f339c0f72875972d60324db52ff": `अर्ज फी तपशील`,
    "133e72d94045f209d146dd6fc01de185": `फी तपशील`,
    "b6d11bb63dc2071d6cba2aaeba8c8a1d": `सोयीची फी`,
    "719fec04166d6fa75f89cd29ad61fa8c": `कर`,
    "a876fbe73a6d039cb8c8566f6aa716d5": `एकूण रक्कम`,
    "9d463ea3feb5afec51c20b4f62927ca3": `कूपन कोड`,
    "620a92a00838e523161dab899b76c764": `देय द्या`,
    "6f861b42317b2333c01faa9b555b7e14": `या कूपनसह बचत.`,
    "c6088ccfc87a825ceb53a15ce4d99510": `आपण <Amount> एकूण सवलत दिली आहे`,
    "0e54fc6a9dba79c2bc03ac08f3bf4a27": `होय!`,
    "c1d9a46634bb89273f7d95a5577f15ad": `कूपन सूट`,
    "1063e38cb53d94d386f21227fcd84717": `काढा`,
    "ba8550da63869236a92fed41fba6e84f": `कूपन कोड यशस्वीरित्या लागू`,
    "f7a7e0e3897bac9ea4c83d53f6ad5a23": `अवैध कूपन कोड.`,
    "90fe9a78f3ea30beeb6b7ef447d2b6dd": `कूपन कोड यशस्वीरित्या काढला`,
    "af1b98adf7f686b84cd0b443e022b7a0": `श्रेणी`,
    "11f18dd73f0c3d374d9d5c230cd43160": `युवा कौशल्य`,
    "5a6c0d2579b31142130f20104fa58ea6": `प्रमाणपत्रे`,
    "c482980d384a9d0e7bc39e1140270870": `वित्त`,
    "605669cab962bf944d99ce89cf9e58d9": `आरोग्य`,
    "5dac73aa49b9ccaca4b9b0d0ef615d10": `पोलिस`,
    "b2ece3493eed05158a9cde4a0d2da2a5": `सार्वजनिक तक्रार`,
    "94df2a6972ca1fa79411645fe9b42339": `उपयुक्तता`,
    "f5f0c488ed56cf789d19a13ffba8abe3": `वाहतूक`,
    "0db377921f4ce762c62526131097968f": `सामान्य`,
    "bc34e4c4f36b0d66910a906449910f64": `आपणास खात्री आहे की आपण ही सेवा काढू इच्छिता?`,
    "1e22827dff05f5a1c044993aff539403": `आपल्याकडे कोणत्याही जतन केलेल्या सेवा नाहीत.`,
    "b08f17b81d3b9b4f6287b87c53cfc97a": `आपल्याकडे मसुद्यात कोणतीही सेवा नाही.`,
    "6f96ffbc03ad1b9ebb5c6e2ecfcbdc71": `आपण अद्याप कोणत्याही सेवा लागू केल्या नाहीत.`,
    "daf87fe41645c86672e96857222a0909": `अर्ज सबमिट केला`,
    "54aeeea175c1509b7c56e0c984f9b4fa": `आपली सेवा करण्याची संधी दिल्याबद्दल धन्यवाद. आपला अर्ज यशस्वीरित्या सबमिट केला गेला आहे.`,
    "f18c4dab2674eae1e8c287c683b66729": `अर्जाची तारीख`,
    "fb0728ffa9b40d444db91c818eb21a2b": `अर्जदाराचे नाव`,
    "e500e94f457ddd88824c88fda548bed2": `अनुप्रयोग आयडी`,
    "ad0206b8eec486df629ce37169376f65": `भूमिका आणि जबाबदा: ्या:`,
    "6f2ad1cfe3c5b08cc53c62470f4cb1f7": `कागदपत्रे आवश्यक:`,
    "1d83797411218ecca1e5ff4d4705dfb0": `निवड प्रक्रिया:`,
    "ce9e1e989c012211d8bc83c5353b4495": `नोकरी अर्जाचा तपशील`,
    "18dcaa84c2ee447028f8450cd0380ba6": `शैक्षणिक तपशील:`,
    "93e9a018926429f6d0b0c032910a8eb6": `नोकरी अर्जाच्या तपशीलांचे पुनरावलोकन करा`,
    "6f906132d3c6b88b57225fdcf76f6e71": `वारंवार विचारले जाणारे प्रश्न`,
    "2d8d66b9c9b540fcb8384167f1565b64": `कृपया शब्दलेखन तपासा किंवा दुसरे काहीतरी शोधण्याचा प्रयत्न करा.`,
    "f73a506c52c2dba159fc0fbee823aace": `आपण या योजनेसाठी आधीच अर्ज केला आहे. कृपया आपल्या लागू केलेल्या योजनांमधून आपली अनुप्रयोग स्थिती तपासा.`,
    "b73856e1c7ab3e647e28663d5e30fabe": `आपण या योजनेसाठी आधीच अर्ज केला आहे. कृपया आपल्या लागू केलेल्या सेवांमधून आपली अनुप्रयोग स्थिती तपासा.`,
    "cd850a99c0895272f2cdf23586867b16": `आपण या योजनेसाठी आधीच अर्ज केला आहे. कृपया आपल्या नोकरीसाठी आपली अर्जाची स्थिती तपासा.`,
    "4e0b8852fc3f3d1a2b4c24c4602766dd": `प्रतिमा JPG, PNG, PDF फाइलमध्ये आणि 1MB पेक्षा कमी असणे आवश्यक आहे.`,
    "5a9e7167eb5b7cb7bb46cf5c05eed59a": `रिकॉप`,
    "91412465ea9169dfd901dd5e7c96dd99": `अपलोड`,
    "3835b71ace5673385a19d56bc938fda8": `फील्ड छायाचित्र`,
    "4386624773a0ef6de5a5de0dd6f8facd": `दस्तऐवज प्रकार प्रविष्ट करा`,
    "fdba3d2a9c707e50f4eec34cd6bdd0d6": `"आपल्या ग्राहकांना जाणून घ्या" ही सरकारी योजनेसाठी अर्ज करीत असलेल्या वापरकर्त्याची ओळख ओळखणे आणि सत्यापित करणे ही अनिवार्य प्रक्रिया आहे.`,
    "244773cb8f460196045c5b90659d2c9d": `आपला अनुप्रयोग आपल्या "मसुद्याच्या सेवा" मध्ये यशस्वीरित्या जतन केला गेला आहे`,
    "bbcb2990551820fc4678835c14a84503": `फोटो काढा`,
    "78e3e2ba60b81cf6cc24e6baf89eaa81": `वैयक्तिक प्रमाणपत्रे`,
    "88bcab139c5d6142b04bf77d7dd1c023": `आपण या सेवेसाठी आधीच अर्ज केला आहे. कृपया आपल्या लागू केलेल्या सेवांमधून आपली अनुप्रयोग स्थिती तपासा.`,
    "bf8cd5900a65efaa89931067cb1d3775": `आपण मसुद्यात कोणत्याही योजना जतन केल्या नाहीत.`,
    "beab92ac3c8a798a35aea2ad0809313f": `अतिथी म्हणून सुरू ठेवा`,
    "95884374c7752d972101b70854caffe6": `साइन इन/साइन अप`,
    "e44cb1c4a784a247f7296cc02f7f301c": `जिल्हा निवडा`,
    "9aa060dc3e3bb3ffff9908c5e713a80c": `आपण अद्याप कोणत्याही सेवांसाठी अर्ज केलेला नाही.`,
    "383f419ea8c0b8142cbdc71e2e4b144a": `मागील प्रश्न`,
    "fb188e808b65b9ee48a5c9d88fb92af2": `पुन्हा प्रारंभ करा`,
    "db5eb84117d06047c97c9a0191b5fffe": `समर्थन`,
    "bc80ae98530c6ba4e76ed34c58d81875": `मोबाइल अॅपसाठी Easygov डाउनलोड करा`,
    "85ce0cae173233410acbc380a7e531cd": `कृपया लक्षात घ्या की हे पृष्ठ सरकारच्या वेबसाइट्स / वेब पृष्ठांचे दुवे देखील प्रदान करते. मंत्रालये/विभाग/संस्था. या वेबसाइट्सची सामग्री संबंधित संस्थांच्या मालकीची आहे आणि पुढील माहिती किंवा सूचनेसाठी त्यांच्याशी संपर्क साधला जाऊ शकतो`,
    "ad0a802ec8134e9210a5625933db50a7": `अस्वीकरण`,
    "26dbd93cbe6f1e56b1577d8e3cb7fda3": `सेवा निवडा`,
    "c6582b36b0f516684a873e8782517822": `योजना आणि सेवा निवडा`,
    "b98287be44e1230cf144aa3af08ac82f": `आपण अद्याप कोणत्याही योजनांसाठी नावनोंदणी केलेले नाही`,
    "449d3cf7dd09172024ebe93872410f0f": `अर्ज पहा`,
    "025d9b3a3c2a6a27d04c57c1d7cabc9f": `कोणत्याही कारवाईची आवश्यकता नाही`,
    "06df33001c1d7187fdd81ea1f5b277aa": `क्रिया`,
    "adff901e227bf2b2200c4767fe773836": `कृती आवश्यक`,
    "4ff84d2ee02fe6ef0ca2edc3cdecc0ed": `कृती आवश्यक`,
    "c08c272bc5648735d560f0ba5114a256": `संपर्क समर्थन`,
    "fd2f820569d83784238e1027897b463e": `आपले नांव लिहा`,
    "cda83d00666a7bfa8cde6808de4ef994": `संपर्क क्रमांक`,
    "a4d3b161ce1309df1c4e25df28694b7b": `प्रस्तुत करणे`,
    "4c2a8fe7eaf24721cc7a9f0175115bd4": `संदेश`,
    "68d390535e8a7120e3f6942d8a2214a0": `आपला संदेश प्रविष्ट करा`,
    "2f644b4a27e68f505c4f4ac2ffe3a8ac": `कृपया केवळ वर्णमाला, संख्या, स्वल्पविराम, -, _, / प्रविष्ट करा`,
    "58f7a43f2c3bd7854d99fbbbc3fa4ae9": `केवळ वर्णमाला परवानगी आहे.`,
    "4906d981bb592b32f6982fb4187c7b60": `वैध आयएफएससी कोड प्रविष्ट करा.`,
    "562d3d08919ae105c3ebc42d9607d266": `कृपया केवळ वर्णमाला किंवा संख्या प्रविष्ट करा`,
    "fab2bb673b21bef95ee70b99dbd7f9fc": `केवळ 11 वर्णांना परवानगी आहे.`,
    "a7eb433676475fee54727c14ad880b8c": `केवळ 150 वर्णांना परवानगी आहे.`,
    "7c58cde3b827bc7d57cbf77865046169": `कृपया फक्त नंबर प्रविष्ट करा`,
    "544b0264d55a1e4cf476b279240d9be4": `केवळ दहा अंकांना परवानगी आहे. कृपया 0 किंवा +91 उपसर्ग करू नका`,
    "73f0d980e48b8141e0776e02d96a872f": `केवळ बारा अंकांना परवानगी आहे.`,
    "0ce98bd973419ff33921a2275e7903b9": `कृपया एक वैध पॅन नंबर प्रविष्ट करा.`,
    "c6f91b305e91a0053748310de170a73c": `केवळ सहा अंकांना परवानगी आहे`,
    "9f44bd93c8988c682d5ef5369fd11f47": `कृपया वैध ईमेल प्रविष्ट करा`,
    "10803b83a68db8f7e7a33e3b41e184d0": `जन्म तारीख`,
    "ac598199ea0dd028ade4677a435caf4b": `वैध आधार क्रमांक प्रविष्ट करा`,
    "d0de720ccdffea8490dfd2893c50ebc5": `हे {{displayName}} पेक्षा मोठे असावे`,
    "ced6e171e13ebd5c37415c822706b9d4": `हे {{displayName}} पेक्षा कमी असावे`,
    "d1a1cb2bd14fdd9eec972f162cfd1ee0": `हे {{displayName}} च्या समान असावे`,
    "9483135517d156229d0439dff74b1ca0": `हे {{displayName}} पेक्षा मोठे किंवा समान असावे`,
    "01e9cd748e852b1ae389b980bff195b0": `हे {{displayName}} च्या तुलनेत कमी किंवा समान असावे`,
    "dc236871b26ba387e1284445caa99df4": `आपली सेवा करण्याची संधी देण्यासाठी.`,
    "2102fff7bb4de45c9e616e3f01b6810f": `आपला अर्ज यशस्वीरित्या सबमिट केला गेला आहे.`,
    "dc9466f3d424b4acba2fc5c9f58d4f8a": `क्षमस्व, आपण पात्र नाही`,
    "a9198ed7d89e246a80078db84d2722e8": `आपला फोन नंबर किंवा संकेतशब्द जुळत नाही`,
    "8ac0a98c8c2f2c86396e34b6b5d63e38": `त्याच नावाचे प्रोफाइल आधीपासूनच अस्तित्वात आहे`,
    "4ae112e33b3189c9fa04369b51259d76": `समान नाव आणि नातेसंबंध असलेले प्रोफाइल आधीपासून अस्तित्वात आहे`,
    "b0fb09a28a4a52a1b3ca2656dec0435e": `आपणास खात्री आहे की आपण हा दस्तऐवज काढू इच्छिता?`,
    "e384b6a4760d80be609d897414cfe680": `आपण नोंदणीकृत वापरकर्ता नाही, पुढे जाण्यासाठी आपण एकतर "साइन अप" किंवा "OTP सह लॉगिन" करू शकता`,
    "c2cd85ffa55b2ec9b049b7eb131d3a9f": `प्रविष्ट केलेले संकेतशब्द जुळले नाहीत. पुन्हा प्रयत्न करा`,
    "3a816ee692e09b3cac6ef18e14071bd4": `आपला संकेतशब्द यशस्वीपणे सेट केला गेला आहे`,
    "764d4847b734121ceb58e5c8195fa5b6": `आपला अभिप्राय आम्हाला सुधारित करण्यासाठी नवीन संधी शोधण्याची आणि आपल्याकडे सर्वोत्कृष्ट अ‍ॅपचा अनुभव असल्याचे सुनिश्चित करण्यास अनुमती देते.`,
    "701d895fbc64569aa143ca6c44274139": `OTP व्युत्पन्न करण्यासाठी आपण जास्तीत जास्त मर्यादा ओलांडली आहे, कृपया कधीतरी नंतर पुन्हा प्रयत्न करा.`,
    "0bdfc4d562c9b0ccfaa4c0f6d4cac98f": `आपण अद्याप कोणत्याही योजनांसाठी अर्ज केलेला नाही.`,
    "4391bf5189e3e13c4aa4cb314c605ac8": `आपण अद्याप कोणत्याही योजना जतन केल्या नाहीत.`,
    "92202504591d549b89340d26ffc0753a": `ही नोकरी आपल्या "माझ्या नोकरी" मध्ये वाचली आहे`,
    "af6ebe0c0e89201cb2294f32955f7b15": `एका वेळी केवळ 20 कागदपत्रांना परवानगी आहे.`,
    "b1481f771aee527fd910fa8d8672cd19": `आपणास खात्री आहे की आपण ही सूचना काढू इच्छिता?`,
    "06a6b009c19d5816574cdc7800d10eb5": `आपली विनंती यशस्वीरित्या वाढविली गेली आहे.`,
    "b941bc7a9d4fe7a8c7c31ed440754285": `अनुप्रयोग पुनरावलोकन`,
    "e56b98da835e8f4a018502b121520ac7": `उर्वरित प्रयत्न`,
    "416828262df288445e72ca1d3661f993": `चेतावणी - सलग 4 अयशस्वी प्रयत्नांनंतर आपले खाते लॉक केले जाईल`,
    "9f978addf10226b03278e4932348ba98": `केवळ 40 वर्णांना परवानगी आहे.`,
    "69f076c339373e00e9e841da965f4208": `आधार अद्यतनित करा`,
    "48a82e0b2bd5818f110e55bb6f14aedc": `आपण प्रदान केलेली खालील माहिती सरकारच्या रेकॉर्डशी जुळली नाही`,
    "5c37ad5c0dd6e318af899b3c8fcbe886": `क्षमस्व, आम्ही स्त्रोत सिस्टममधून आपला डेटा आणण्यात अक्षम आहोत. आपण सत्यापन केल्याशिवाय पुढे जाऊ शकता किंवा नंतर प्रयत्न करू शकता.`,
    "cdb2033fc9a30d6d6c1abe401cd1e37b": `नंतर प्रयत्न करा`,
    "f8d855b26faffa0f8779fb44632d6bc1": `अवैध कॅप्चा कोड`,
    "d53b4d564b9054db9b4b4a50e713197f": `कृपया येथे कॅप्चा प्रविष्ट करा`,
    "ed001fb3ff7eaca9bbfbaacddc9d5a72": `कोणताही इतिहास सापडला नाही`,
    "4934d9bc9ebd024d4106d5e1f093ae79": `अभ्यागत मोजतात`,
    "f7ce7cb4b450acfe0b4cef36c3bdec2d": `आपले बँक खाते सध्या निष्क्रिय आहे. आपण पुढे जाऊ शकता परंतु आपला अर्ज मंजूर झाल्यास आपला बँक खाते लवकरात लवकर सक्रिय करू शकता.`,
    "df9e5c88861b08c33b2d46de8d7037e9": `आपले बँक खाते सध्या रद्द झाले आहे. आपण पुढे जाऊ शकता परंतु आपला अर्ज मंजूर झाल्यास आपला बँक खाते लवकरात लवकर सक्रिय करू शकता.`,
    "3fc95e40b475f7269e64f25a1c4aef3b": `आपले बँक खाते आपल्या आधारशी जोडलेले नाही. आपण पुढे जाऊ शकता परंतु आपला अनुप्रयोग मंजूर झाल्यास, आपल्या बँकेचे खाते आपल्या आधाराशी लवकरात लवकर लाभ मिळवू शकता.`,
    "1c35d003a65da48bf9f6d6fc69271d5a": `क्षमस्व, सिस्टमच्या त्रुटीमुळे आपला अर्ज सबमिट केला जाऊ शकत नाही. कृपया पुन्हा प्रयत्न करा.`,
    "8e437cbd840bce5c5edc0ef8164de6b7": `आपण आपला तपशील अद्यतनित केल्यास किंवा बदलल्यास आपला डेटा असत्यापित होईल आणि आपल्याला अद्यतनित केलेल्या डेटासाठी दस्तऐवज पुरावा अपलोड करण्यास सांगितले जाईल. सुरू ठेवण्यासाठी “पुढे जा” वर क्लिक करा.`,
    "b010e3be9f81072a9808ed4a5dd0e45b": `खालील माहिती आपल्या सर्वेक्षणात प्रदान केलेल्या तपशीलांशी जुळत नाही:`,
    "01acb0344944f11d68aeecc9f5b574ea": `आपण प्रतिसादासह पुढे जाऊ इच्छिता?`,
    "4ff70f7b2e5a74415de360150a6e59d3": `तुझे`,
    "bd7aaae9a27a2a4d71214c002bcf50ce": `रेकॉर्डशी जुळला नाही.`,
    "ebfd339b0ac14aa79b325b53f06bd375": `आपल्याद्वारे प्रदान केलेली माहिती आपल्या आधार तपशीलांशी जुळली नाही. आपण पुढे जाऊ शकत नाही.`,
    "1e410e1b9903e7a99b93857f536b7e1d": `सत्यापित नाही`,
    "28b266f2bf0a4ecc2dec3b6ca24fcb9b": `अपलोड`,
    "68982db8041ee52dd9e5a6e527c8a1fa": `पुन्हा पीक`,
    "e7bf7d83a37666fd8749e433189b1c27": `कृपया थांबा`,
    "c389f9e7d5ea93af9deb2e8f1f9c6d89": `अधिक जाणून घेण्यासाठी {{"0x0001F6C8"}} वर क्लिक करा.`,
    "f17816bcbe79e18bee49388fe84d2876": `OTP कालबाह्य झाले.`,
    "1531d9dc798857eb85345d349bffc99b": `मागे`,
    "7939de8a9958e01fb1f172e21c5a7a85": `कृपया योग्य माहिती प्रदान करा, आपण पात्र असलेल्या योजना दर्शविण्यास आम्हाला मदत करेल.`,
    "bf58c4a9eac8e0ec82c158c3adfcd609": `योजनांचे वर्गीकरण वेगवेगळ्या श्रेणींमध्ये केले गेले आहे. आपण आपल्या पसंतीच्या श्रेणीनुसार योजना तपासू शकता`,
    "09349e778786f7ed758021ddbed12ca6": `ही माहिती {Intent} श्रेणी अंतर्गत पात्र योजना शोधण्यात आम्हाला मदत करेल.`,
    "0d45c019e7e9237a316f0f16824015d2": `आपला प्रोफाइल डेटा वेगवेगळ्या टॅब अंतर्गत संग्रहित केला आहे. आपण आपली कोणतीही प्रोफाइल माहिती संपादित करू शकता.`,
    "3f52884ec08606e6e73426eaec8de195": `ही माहिती आपल्याला या योजनेअंतर्गत प्राप्त झालेल्या अचूक फायद्याबद्दल आणि फायद्याचा लाभ घेण्यासाठी अर्जाच्या प्रक्रियेबद्दल तपशील प्रदान करण्यात मदत करेल.`,
    "c389f9e7d5ea93af9deb2e8f1f9c6d89": `अधिक जाणून घेण्यासाठी {{"0x0001F6C8"}} वर क्लिक करा.`,
    "5cc2bdab3db5aa21633656a0d55f7477": `आपण अर्जासह पुढे जाऊ शकत नाही`,
    "d5de543c0e83073f05a846d62cc83c81": `दस्तऐवज आधीच त्याच फील्डसाठी अपलोड केले आहे.`,
    "fca8da28cbcbe094dc646872e7cdbc7b": `वैध प्रविष्ट करा`,
    "6fa2260c737f40fa1a3f63274c8fca48": `आपण पुढे जाऊ शकत नाही. आपण एकतर आपले तपशील प्रोफाइलमध्ये किंवा आपल्या आधार कार्डमध्ये दुरुस्त करू शकता`,
    "3272e327ba9c82197c5c982487224925": `क्षमस्व, आम्ही स्त्रोत सिस्टममधून आपला डेटा आणण्यात अक्षम आहोत. कृपया पुन्हा प्रयत्न करा.`,
    "586637318049e6dbb03e991bc45cba06": `आपले खाते हटविण्यासाठी आपण प्रथम आपल्या खात्याशी संबंधित सर्व प्रोफाइल हटविणे आवश्यक आहे.`,
    "ce6d6092569f3f247659ceb51d57be95": `आपल्या लागू केलेल्या किंवा नोंदणीकृत योजनांमध्ये आपल्याकडे चालू असलेला अनुप्रयोग असल्याने आपण {{profileName}} प्रोफाइल हटवू शकत नाही`,
    "8d258e398e2dbc8099c984258fadce2a": `तुमच्या नोंदणीकृत मोबाईल नंबरवर एक OTP पाठवला गेला आहे (xxx xxx {{phoneNumber}})`,
    "abb2e3d13ded4cea9275beafacd9f4c8": `या योजनेत आपली आवड निर्माण करण्यासाठी, "आपले हित व्युत्पन्न करा" वर क्लिक करा आणि या योजनेचा फायदा देण्यासाठी सरकार आपल्यापर्यंत पोहोचेल`,
    "493de8e0a6b5d4a10af30cf3105b9e1f": `आपली आवड निर्माण करा`,
    "c9b0246a8f3989724ec4958f91e380a7": `योजना तपशील`,
    "804ca01b219522a88d26c3002632a00e": `तपशील सामायिक केल्याबद्दल धन्यवाद.`,
    "04b6d9b7eb73749476d958b2133f4bbe": `योजना जागरूक सर्वेक्षण`,
    "a5661f68fc78a5d4f15afa057fec7a9f": `पुढे जाण्यासाठी आपल्याला सर्वेक्षणात आपले तपशील अद्यतनित करणे आवश्यक आहे.`,
    "1e4531d1dc128abbc00b48e5c7b6ef48": `अद्यतनित सर्वेक्षण`,
    "362e532b6ad1fda489c5909d437f02a3": `आम्ही आपले तपशील आणत आहोत, कृपया थांबा ..`,
    "8a1b3c7d62e2d60d5be34d8e36a9768f": `वैयक्तिक लॉगिनकडे परत`,
    "6737d50489b1cf1d4408e66b8591ca38": `आपल्याकडे आपल्या योजनेच्या अर्जावर प्रलंबित क्रिया आहेत. आपल्या अनुप्रयोगासह पुढे जाण्यासाठी प्रलंबित क्रिया पूर्ण करा.`,
    "e37c99137b7396277137c1f73f8a86d5": `सीईजीचा हेतू`,
    "706c7a936b977ec3c4e6603f888e3fce": `EasyGov द्वारा समर्थित`,
    "598d42810b342196884f255912974d69": `एसएल. नाव`,
    "60e4e81c6f9c1938d77e8ded6d64643a": `स्क्रीन रीडर`,
    "15bbb9d0bbf25e8d2978de1168c749dc": `संकेतस्थळ`,
    "21d587949a2a2e89c958b622e244b405": `विनामूल्य/ व्यावसायिक`,
    "d5c03f7b855d442a2a7f24024cf89960": `व्हिज्युअल डेस्कटॉप प्रवेश (एनव्हीडीए)`,
    "f956e16f65a3a937e5ecfe4195d17d0d": `(नवीन विंडोमध्ये उघडणारी बाह्य वेबसाइट)`,
    "b24ce0cd392a5b0b8dedc66c25213594": `फुकट`,
    "c8ed8ec3dc8b21290b5d28ca56344553": `जबडे`,
    "b2788e1ab6be65f1c38bf7d23cd081f1": `व्यावसायिक`,
    "ec18aa8148f59475130ff217d693b465": `विंडो-डोळे`,
    "d96a273ceb2b5f83d710081d9c38440a": `जाण्यासाठी सिस्टम प्रवेश`,
    "c932fd98147348de9974252a0efcc7cb": `WebAnywhere`,
    "540431e2b3234a8698b4044f15ef1fff": `पीडीएफ सामग्री`,
    "801ab24683a4a8c433c6eb40c48bcd9d": `डाउनलोड करा`,
    "83f8a38f024082405d2fff921c2bb1d2": `अ‍ॅडोब अ‍ॅक्रोबॅट रीडर`,
    "9d8c84ff52cfc2c2e0075e669edacffd": `शब्द फायली`,
    "4db1a171aaf96723d4002cc41559ddf7": `नवीन विंडोमध्ये उघडणारी बाह्य वेबसाइट`,
    "86c61b344697039b8a54bbef9e4959c3": `एक्सेल व्ह्यूअर 2003 (2003 पर्यंत कोणत्याही आवृत्तीमध्ये)`,
    "04efb134468330da1430e32701160107": `एक्सेलसाठी मायक्रोसॉफ्ट ऑफिस कॉम्पॅबिलिटी पॅक (2007 आवृत्तीसाठी)`,
    "a385a9bb61bb694694eef5d2f54c563a": `पॉवरपॉईंट सादरीकरणे`,
    "da67d56a74c01537a71a3ab0eda00faa": `पॉवरपॉईंटसाठी मायक्रोसॉफ्ट ऑफिस कॉम्पॅबिलिटी पॅक (2007 आवृत्तीसाठी)`,
    "bb625547a85b98c2c9f9f4121c72cd78": `फ्लॅश सामग्री`,
    "ab69edd2798068928605717c6591462f": `अ‍ॅडोब फ्लॅश प्लेयर`,
    "2c4fa0a307152ef814bbab8d4075d938": `ऑडिओ फायली`,
    "da7be99fd6fac5c8a4065eb2c07cad88": `विंडोज मीडिया प्लेयर`,
    "5a5652ca26ee42fe73fdd79497cf9e21": `शब्द दर्शक (2003 पर्यंत कोणत्याही आवृत्तीमध्ये)`,
    "2e856241d3d995fefdb1b4b32f84a79b": `वर्डसाठी मायक्रोसॉफ्ट ऑफिस कॉम्पॅबिलिटी पॅक (2007 आवृत्तीसाठी)`,
    "64a443242750513eb56695e07aedcff2": `एक्सेल फायली`,
    "382b0f5185773fa0f67a8ed8056c7759": `एन/ए`,
    "462c18d5b89050fb1b7f8fca1e535af8": `प्रक्रिया केली`,
    "ccb26ac32c3cc7cc2f0fd1bbac3b8e2d": `आपल्याकडे प्रक्रिया केलेल्या कोणत्याही सेवा नाहीत`,
    "fc44a0efc672761745c2e76aac6851d2": `आपला अर्ज नाकारला आहे कारण`,
    "7d0db380a5b95a8ba1da0bca241abda1": `वर`,
    "1252044838fd74c4e8b506ef1302a8b5": `तक्रार`,
    "06627e6919d21853d887d00334ee5337": `तक्रार व्यवस्थापन`,
    "f940fbe4aec76e9784e7f15c4b92ac6d": `आपल्याकडे प्रक्रियेत कोणतीही तक्रार नाही`,
    "2290dc1500a2b4548eb3b931f2e3609a": `आपल्याकडे कोणतीही तक्रार नाही.`,
    "b25b60dc1a5f7c17e09bc73174668d9a": `आपल्याकडे मसुद्यात कोणतीही तक्रार नाही.`,
    "a02c58d421087ec9519620a29d68a6ec": `आपण अद्याप कोणत्याही तक्रारीसाठी सबमिट केलेले नाही.`,
    "b65f636dcbc018b1901e23a7c2adde90": `अधिक एक्सप्लोर करा`,
    "5c835c22429f25992b4371691ecde1a4": `सबमिट केले`,
    "2535f5e4e0892c73a5fad023bf3e15e2": `माझ्या तक्रारी`,
    "4351cfebe4b61d8aa5efa1d020710005": `दृश्य`,
    "e9516387730903ffd7cfe3317e05e385": `ही तक्रार आपल्या "माझ्या तक्रारी" मध्ये वाचली आहे.`,
    "b70db24f2cb70fb266eb03da8f289ba5": `तक्रार पहा`,
    "646558064e10a12b1e8914e86eca3ca6": `आपल्याकडे आपल्या सेवा अनुप्रयोगावर प्रलंबित क्रिया आहेत. आपल्या अनुप्रयोगासह पुढे जाण्यासाठी प्रलंबित क्रिया पूर्ण करा.`,
    "d2841171ea05a48fa5463b3f7c7c92d7": `आपल्याकडे आपल्या तक्रार अनुप्रयोगावर प्रलंबित क्रिया आहेत. आपल्या अनुप्रयोगासह पुढे जाण्यासाठी प्रलंबित क्रिया पूर्ण करा.`,
    "9279f823b01f13b7a8c04688289543cc": `आपला अनुप्रयोग आपल्या 'मसुद्याच्या तक्रारी' मध्ये यशस्वीरित्या जतन झाला.`,
    "af481c93dcae73d73afe9927076c8a09": `आपण या तक्रारींसाठी आधीच अर्ज केला आहे. कृपया आपल्या लागू केलेल्या तक्रारींमधून आपली अनुप्रयोग स्थिती तपासा.`,
    "ce972231a0faf31791c7ac0bf0d1da84": `आपण काही पैसे दिले असल्यास आपली रक्कम परत केली जाणार नाही.`,
    "2ffef02ca513f8bbadbaf130a23097de": `आपला अनुप्रयोग आपल्या "मसुद्याच्या तक्रारी" मध्ये यशस्वीरित्या जतन केला गेला आहे`,
    "e55e425dbaf6b6449940eac8ea749092": `कृपया केवळ वर्णमाला प्रविष्ट करा.`,
    "64ff9e3809686d4ea3253a631cae4761": `कृपया 2 दशांश ठिकाणी मूल्य योग्य प्रविष्ट करा.`,
    "7ed0ef23d527c2876015161e422f49ce": `कृपया कन्नड भाषेत आपले नाव प्रविष्ट करा.`,
    "60ae728544ab5cb25dba9ff4b8f6aa95": `सेवा सिंधूसाठी आपला अर्ज संदर्भ क्रमांक आहे`,
    "2c0ed1f2f9239dbca46beca549190e00": `आधार पत्त्याचा पुरावा म्हणून घेतला जाईल, कृपया आधार प्रमाणे जिल्हा निवडा.`,
    "8355a53d777c5ac84a37970b5158aba5": `त्रुटी 205: आधारमधील आपले नाव महसूल विभागाने जारी केलेल्या जाती किंवा उत्पन्न प्रमाणपत्रातील तपशीलांशी जुळत नाही.`,
    "601f77894e71ae7d4922eda713dda66c": `त्रुटी 207: आपले जात आणि उत्पन्न प्रमाणपत्र कालबाह्य झाले आहे.`,
    "9182b3e8dc7965f2775a204095f4c992": `आपल्याद्वारे प्रविष्ट केलेला OTP चुकीचा आहे. कृपया योग्य OTP सह पुन्हा प्रयत्न करा.`,
    "6e0fe1003e84e34da97a6c4c9040b31b": `मागासवर्गीय कल्याण विभागाच्या अरिव्हू शैक्षणिक कर्ज योजनेसाठी (नूतनीकरण) अर्ज स्वीकारण्याची शेवटची तारीख 31 मार्च 2022 पर्यंत वाढविण्यात आली आहे.`,
    "69d3a9de2fc1407f59e8936ca0c525a0": `क्षमस्व, एक त्रुटी आली आहे. कृपया पुन्हा प्रयत्न करा.`,
    "7953c16d691da03c899391a39412fc9b": `आपल्याकडे आपल्या योजनेच्या अर्जावर प्रलंबित क्रिया आहेत. आपल्या अनुप्रयोगासह पुढे जाण्यासाठी <i> {{startDate}} </i> वरून प्रलंबित क्रिया पूर्ण करा.`,
    "f4f4366332ac6949882de59c81b08de9": `आपल्याकडे आपल्या योजनेच्या अर्जावर प्रलंबित क्रिया आहेत. आपल्या अनुप्रयोगासह पुढे जाण्यासाठी <i> {{startDate}} </i> वरून <i> {{endDate}} </i> वरून प्रलंबित क्रिया पूर्ण करा.`,
    "77b43c24220ee608380031b72f8017ce":`सेवा श्रेणी`,
    "e0e54c374f8b39a1520766e5924af100": `तुमच्या कुटुंबासाठी आणि तुमच्यासाठी योजना, सेवा आणि उपजीविका जाणून घेण्यासाठी एक प्लॅटफॉर्म`,
    "d56f6b073f573237a6c4aed91589e982": `उपजीविका`,
    "e5930fadbc620e368c92561f381ead58": `तुमच्यासाठी उपजीविकेच्या संधी`,
    "192adf39ebd850e3e2e30971e885dc6d": `याक्षणी तुमच्या राज्यात उपजीविकेच्या कोणत्याही संधी कॉन्फिगर केलेल्या नाहीत, आम्ही सतत अपडेट करत आहोत, कृपया आमच्याशी पुन्हा तपासा`,
    "TM_Alt51": "संलग्नक(चे)",
    "TM_Hea1": "एकूण रक्कम",
    "TM_Hea2": "सारांश",
    "TM_Sub56": "एकूण",
    "TM_But42": "भुगतान करा",
    "TM_Mod41":"देयक प्रलंबित",
    "TM_Mod40":"पेमेंट यशस्वी",
    "TM_Alt72":"पेमेंट अयशस्वी",
    "TM_Pag48":"मदत आणि आधार",
    "TM_Con33":"धन्यवाद, तुमचा अर्ज यशस्वीरित्या सबमिट केला गेला आहे.",
    "TM_Err34": "पेमेंट अयशस्वी झाल्यामुळे तुमचा अर्ज सबमिट करता आला नाही.",
    "TM_Rec55": "तुमच्या पेमेंटच्या स्थितीची पुष्टी करण्यासाठी वेळ लागत आहे. आम्ही तुमच्या बँकेकडे सतत तपासत आहोत. कृपया पुढील 48 तासांसाठी कोणतेही अतिरिक्त पेमेंट करणे टाळा.",
    "TM_But13": "परत जा",
    "TM_Alt124":"कृपया थांबा",
    "TM_Rec58": "थांबा. आम्ही तुमची पेमेंट स्थिती सत्यापित करत आहोत",
    "TM_Rec59": "या सेवेसाठी तुमचे शेवटचे पेमेंट प्रलंबित स्थितीत आहे ते पूर्ण झाल्यावर आम्ही तुम्हाला सूचित करू या दरम्यान आमच्या इतर सेवा एक्सप्लोर करा.",
    "TM_Rec60":"आपल्या संयमाबद्दल आभार मानतो.",
    "TM_Rec61":"पृष्ठ आपोआप कालबाह्य होईल",
    "TM_Alt165":"देयक भरण्यासाठी तुमच्या मोबाइल डिव्हाइसवर यूपीआय ऐप क्यूआर कोड स्कॅन करा.",
    "TM_Pag39": "लभार्थी",
    "TM_Alt166":"पेमेंट नाकारले",
    "TM_Err39":"पेमेंट नाकारल्यामुळे तुमचा अर्ज सबमिट करता आला नाही.",
    "cc4cbfe72c596683881033f36eac3e2f":"आम्ही तुमच्या बँकेत तुमच्या पेमेंटची पुष्टी करेपर्यंत कृपया प्रतीक्षा करा. या प्रक्रियेस थोडा वेळ लागू शकतो.",
    "7d54c690a3a707e692cceb6978e24bc1": "फी ब्रेकअप",
}
export default language;