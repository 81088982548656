import storage from '../../utility/encryptData'
import keys from '../../common/keys'
import { stubFalse } from 'lodash-es';
// import history from "../../../history"
import handlePossibleFieldValue from '../../utility/handlePossibleFieldValue'
import handleDependentFields from '../../utility/handleDependentFields'

const initState = {
  data: "",
};

const getValue = (string, startTag, endTag) => {
  if (string.indexOf(startTag) === -1 || string.indexOf(endTag) === -1) {
     return ""
  }
  else if(startTag !== "" && endTag !== "")
    return string.substring(
      string.indexOf(startTag) + startTag.length,
      string.indexOf(endTag)
     );

  else if(startTag !== "" && endTag === "")
    return string.substring(
      string.indexOf(startTag) + startTag.length,
      string.length
     );
}

const replaceValue = (string, shortName, value) => {
  let shortNameString = "{{" + shortName + "}}";
  var newNameString = shortNameString
    .replace(/[(]/g, "\\(")
    .replace(/[)]/g, "\\)");
  var regExp = new RegExp(newNameString, "g");
  return string.replace(regExp, value);
}

const getTimestamp = (timestamp1,timestamp2) => {
      var dateField1= new Date(timestamp1)
      var dateField2= new Date(timestamp2)
      if(dateField1 > dateField2){
        return false;
      } else {
        return true;
      }
}



const getUserProfileReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_USER_PROFILE":
      if(action.payload && action.payload.length > 0){
        let sourceObj = action.payload.find( source => source.shortName === "easygov")
        let sourceObjTemp = action.payload.filter( source => source.shortName !== "easygov")
        let groups = sourceObj.groups
        let currentActiveProfile = JSON.parse(storage.getItemValue(keys.ACTIVE_PROFILE))
        let profileLength = 0, unansweredFields = 0
        let profilePercentage = 0,  profileList = []
        groups.map( profile => {
          profile.userProfiles.map(field =>{
            sourceObjTemp.map(sourceField =>{
              sourceField.groups.length>1 && sourceField.groups[1].hasOwnProperty('userProfiles') &&  sourceField.groups[1].userProfiles.map(fields =>{
                      if(fields.hasOwnProperty("fieldValue") && field.hasOwnProperty("fieldValue")){
                        if((field.fieldShortName === fields.fieldShortName) && (field.fieldValue === fields.fieldValue)){
                              if(!(field.hasOwnProperty("timestamp") && getTimestamp(sourceField.timestamp,field.timestamp))){
                                field["verified"] = true;
                                field["timestamp"] = sourceField.timestamp;
                                field["sourceObj"] = sourceField.name;
                                field["documentName"] = "";
                                field["verifiedShownToUser"] = true;
                              }
                        }
                      }
                  })
            })
          })
          profileLength = profileLength + profile.userProfiles.length
          unansweredFields = unansweredFields + profile.userProfiles.filter( item => !item.hasOwnProperty('fieldValue')).length
          profilePercentage = Math.ceil(((profileLength - unansweredFields) / profileLength)*100)
          if(currentActiveProfile && Object.keys(currentActiveProfile).length!==0){
            currentActiveProfile['profilePercentage'] = profilePercentage
            storage.setItemValue(keys.ACTIVE_PROFILE, JSON.stringify(currentActiveProfile))
          }
          if(profilePercentage > 0){
              storage.setItemValue(keys.IS_SCHEME_FIRST_VISIT, true)
          } else {
            storage.removeItemValue(keys.IS_SCHEME_FIRST_VISIT, true)
          }

          let childFields = profile.userProfiles.filter(profile => (
            (profile.hasOwnProperty('parentFieldValue') && profile.parentFieldValue.trim() !== "") ||
            (profile.hasOwnProperty('derivedValue') && profile.derivedValue.trim() !== "") ||
            (profile.hasOwnProperty('possibleValues') && profile.possibleValues.trim() !== "")) && profile.hasOwnProperty('fieldValue'))
          childFields.map( field => {
            let isRulePassed = false
            if(field.hasOwnProperty("parentFieldValue") && field.parentFieldValue.trim() !== ""){
              isRulePassed = handleDependentFields(field, groups)
              if(!isRulePassed){
                field.isDisplayedToUser = false
                profile.userProfiles.splice(profile.userProfiles.indexOf(field), 1)
                let subChildFields = childFields.filter(item => item.hasOwnProperty('parentFieldValue') && item.parentFieldValue.trim() !=="" && item.parentFieldValue.includes(field.fieldShortName))
                subChildFields.map(subChild => {
                  subChild.isDisplayedToUser = false
                  profile.userProfiles.splice(profile.userProfiles.indexOf(subChild), 1)
                })
              }
            }
            if(field.hasOwnProperty("possibleValues") && field.possibleValues.trim() !== "" &&
              ((isRulePassed && field.hasOwnProperty("parentFieldValue")) || (field.hasOwnProperty("parentFieldValue") && field.parentFieldValue.trim() === "") ||
              !field.hasOwnProperty("parentFieldValue"))) {
              let isPossibleRulePassed = false
              let optionsList = handlePossibleFieldValue(field, groups)
              if(optionsList){
                optionsList = optionsList.split('|')
                let fieldOptions = field.fieldValueOptions.filter(option => optionsList.includes(option.hasOwnProperty("untranslated_value") ? option.untranslated_value : option.value))
                if(fieldOptions.length > 0){
                  if(fieldOptions.find(item => field.fieldValue.includes(item.value))){
                    field['fieldOptions'] = [...field.fieldValueOptions]
                    field.fieldValueOptions = [...fieldOptions]
                  }else {
                    field.isDisplayedToUser = false
                    profile.userProfiles.splice(profile.userProfiles.indexOf(field), 1)
                    let subChildFields = childFields.filter(item => item.hasOwnProperty('parentFieldValue') && item.parentFieldValue.trim() !=="" && item.parentFieldValue.includes(field.fieldShortName))
                    subChildFields.map(subChild => {
                      subChild.isDisplayedToUser = false
                      profile.userProfiles.splice(profile.userProfiles.indexOf(subChild), 1)
                    })
                  }
                } else {
                  field.isDisplayedToUser = false
                  profile.userProfiles.splice(profile.userProfiles.indexOf(field), 1)
                  let subChildFields = childFields.filter(item => item.hasOwnProperty('parentFieldValue') && item.parentFieldValue.trim() !=="" && item.parentFieldValue.includes(field.fieldShortName))
                  subChildFields.map(subChild => {
                    subChild.isDisplayedToUser = false
                    profile.userProfiles.splice(profile.userProfiles.indexOf(subChild), 1)
                  })
                }
              } else {
                field.isDisplayedToUser = false
                profile.userProfiles.splice(profile.userProfiles.indexOf(field), 1)
                let subChildFields = childFields.filter(item => item.hasOwnProperty('parentFieldValue') && item.parentFieldValue.trim() !=="" && item.parentFieldValue.includes(field.fieldShortName))
                subChildFields.map(subChild => {
                  subChild.isDisplayedToUser = false
                  profile.userProfiles.splice(profile.userProfiles.indexOf(subChild), 1)
                })
              }
            }
          })
          let answeredFields = []
          answeredFields = profile.userProfiles.filter( item => item.hasOwnProperty('fieldValue'))
          profileList = [...profileList, ...answeredFields]
        })


        // ---- TO CHECK IF PROFILE IS UPDATED BY ANY EXTERNAL SOURCE ----
        let isProfileUpdated = false
        if(profilePercentage > 0){
          for(let i=0; i<action.payload.length; i++){
            let source = action.payload[i]
            if(source.shortName !== "easygov" && source.groups.length>1){
              let outputFields = source.groups.find( (item => (item.name.toLowerCase() === "output") || (item.hasOwnProperty("untranslated_name") && item.untranslated_name.toLowerCase() === "output")))
              let answeredFields = outputFields.userProfiles.filter( field => field.hasOwnProperty('fieldValue') && field.fieldValue !== "")
              if(answeredFields.length > 0){
                isProfileUpdated = true
              }
            }
          }
        }
        let profileList_temp = []
        let userProfile_temp = {}
        sourceObj.groups.map( profile => {
        let answeredFields_temp = []
        answeredFields_temp = profile.userProfiles.filter( item => item.hasOwnProperty('fieldValue'))
        profileList_temp = [...profileList_temp, ...answeredFields_temp]
      })
        userProfile_temp['userProfile'] = profileList_temp
        storage.setItemValue(keys.USER_PROFILE, JSON.stringify(userProfile_temp));
        if(sourceObj){
          return {
            ...state,
            data: sourceObj.groups,
            isProfileUpdated: isProfileUpdated
          };
        }
      } else {
        return {
          ...state,
          data: [],
        };
      }

    case "RESET_USER_PROFILE":
      return {
        ...state,
        data: undefined,
        isProfileUpdated: false
      };

    default:
      return state;
  }
};

export default getUserProfileReducer;
