import storage from '../../utility/encryptData'
import keys from '../../common/keys'

const initState = {
  data: "",
};

const getProductReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_PRODUCT":
      storage.setIndexedValue(keys.PRODUCT_LIST, action.payload.objects)
      storage.setIndexedValue(keys.TAGGING_DATA_LIST, action.payload.taggingData)
      return {
        ...state,
        data: action.payload,
      };

    case "RESET_PRODUCT_LIST":
      return {
        ...state,
        data: "",
      };

    default:
      return state;
  }
};

export default getProductReducer;
