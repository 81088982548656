const initState = {
  data: undefined,
};

const saveAndMatchReducer = (state = initState, action) => {
  switch (action.type) {
    case "SAVE_AND_MATCH":
      return {
        ...state,
        data: action.payload,
      };

    case "RESET_SAVE_AND_MATCH":
      return {
        ...state,
        data: undefined,
      };

    default:
      return state;
  }
};

export default saveAndMatchReducer;
