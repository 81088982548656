const language = {
    "9e66e6bd4069124578736528a0432752": `અમારા વિશે`,
    "0f5590b58efe9cfcce35e55a66280e59": `સ્વીકારો અને ચાલુ રાખો`,
    "77ab8893d0d282a9f2781d5711883903": `ઉમેરો`,
    "c40eba17959a63e52412cac8c38d467e": `પ્રોફાઇલ ઉમેરો`,
    "ac714b256ab565c91ade9e03e8c410f0": `તમારો બધો ડેટા કાઢી નાખવામાં આવ્યો છે`,
    "b31b443baac1c6da4cb78dfaf2e5be44": `અરજી`,
    "0ec157e0e15e12c56bfe1c72e0e7515a": `ફેરફારો લાગુ કરો`,
    "181c1a0ee6db7d7f835eed29791749ab": `શું તમે ખરેખર આ સ્કીમ કાઢી નાખવા માંગો છો?`,
    "d937652cde254e2a9b93b74a4ee2a02b": `શું તમે ખરેખર {{name}} પ્રોફાઇલને કાયમ માટે કાઢી નાખવા માંગો છો?`,
    "3ac1df709254c0e491c642aae5400814": `શું તમે ખરેખર {{name}} એકાઉન્ટને કાયમ માટે કાઢી નાખવા માંગો છો?`,
    "1cbb32715ee74ee3447f48febcc04864": `શું તમે ખરેખર તમારી સાચવેલી સેવાઓમાંથી આ સેવાને કાઢી નાખવા માંગો છો?`,
    "ea4788705e6873b424c65e91c2846b19": `રદ કરવું`,
    "f4ec5f57bd4d31b803312d873be40da9": `પરિવર્તન`,
    "6dd495f1c2ae29c8e9a6c4405f18e22e": `સ્થાન બદલો`,
    "b1ad1d3aef325b23950a43cb82fd0670": `ભાષા પસંદ કરો`,
    "714341d9339756588d1dc724991c2b9a": `અહીં ક્લિક કરો`,
    "f72b489f19f748cf78a86c78d547ed91": `તમારા સ્થાનની પુષ્ટિ કરો`,
    "a0bfb8e59e6c13fc8d990781f77694fe": `ચાલુ રાખવું`,
    "bee04ef1315b3f9562be34e2e28a7831": `એકાઉન્ટ કાઢી નાખો`,
    "3e9983cf1885a5ec9f5a5d8127137bd2": `પ્રોફાઇલ કાઢી નાખો`,
    "2ac7a924af94bf173f38eb31165efce3": `સ્કીમ કાઢી નાખો`,
    "e0bb2f66e5b61be3235bf8969979de19": `જિલ્લો`,
    "46a43a7757337712d4f6a3701a5c57dd": `ડાઉનલોડ ફોર્મ`,
    "4d7be8ac1b9cb2fd5843dbea7ce30344": `તમે હજી સુધી કોઈ સેવા સાચવી નથી`,
    "edda2208040fe25b06d0d8f3af976651": `તમે કોઈ નોકરી બચાવી નથી`,
    "f283a3e62b2809c07cf77075103c012f": `તમારા માટે શ્રેષ્ઠ યોગ્ય સરકારી કલ્યાણ યોજનાઓ, સેવાઓ અને નોકરીઓ શોધવા માટે અમને કેટલીક મૂળભૂત પ્રોફાઇલ માહિતીની જરૂર છે.`,
    // "f283a3e62b2809c07cf77075103c012f":`શ્રેષ્ઠ યોગ્ય સરકારી કલ્યાણ યોજનાઓ શોધવા માટે લબર્થીને કેટલીક મૂળભૂત પ્રોફાઇલ માહિતીની જરૂર છે`,
    "ce8ae9da5b7cd6c3df2929543a9af92d": `ઇમેઇલ`,
    "01e8b0c4e059b594202e976860b2d481": `તમારી પોતાની ભાષામાં યોજનાઓ અને સેવાઓનું અન્વેષણ કરો`,
    "483a5da192f46e3cfe8391d3ef234fc4": `વારંવાર પૂછાતા પ્રશ્નો`,
    "37aaf54433bbfe989db8871121fe0ad8": `અમને અનુસરો:`,
    "e0c4332e8c13be976552a059f106354f": `શરૂ કરો`,
    "48dee7c291ac7f19e499438b072da336": `સરકારી ફી`,
    "2ceb32552d78024d91a123897636afcb": `EasyGov ની નાગરિક સશક્તિકરણ એપ્લિકેશન`,
    //"357aa540fd5c8ae584ba18359105de3d":`શું તમને કોઈ યોજનાઓ અથવા અન્ય કંઈપણ વિશે કોઈ પ્રશ્ન છે?`,
    "357aa540fd5c8ae584ba18359105de3d": `જો તમારી પાસે કોઈ ક્વેરી હોય તો અમને જણાવો અને અમે 24 વ્યવસાયિક કલાકની અંદર તમારી પાસે પાછા આવીશું.`,
    "b3ba00d60ff5a9e11c15a6efbed13746": `તમારી વર્તમાન પ્રોફાઇલને અપડેટ કરવા માટે "વર્તમાન પ્રોફાઇલ અપડેટ કરો" પર ક્લિક કરો અને નવી પ્રોફાઇલ ઉમેરવા માટે "પ્રોફાઇલ ઉમેરો" પસંદ કરો`,
    "12ceff2290bb9039beaa8f36d5dec226": `નોકરી`,
    "5eb57a6678ca337f92884f49b578a908": `...`,
    "4994a8ffeba4ac3140beb89e8d41f174": `ભાષા`,
    "846eb834b6bfe81dbd412bc010f8cac7": `અમને જણાવો અને અમે 24 વ્યવસાયિક કલાકની અંદર તમારી પાસે પાછા આવીશું`,
    "a58252e21aaf876bf7b64c05cce1c477": `કડી ક ied પિ કરી`,
    "ce5bf551379459c1c61d2a204061c455": `સ્થાન`,
    "ccffe10fe854018805baf0d3d9327285": `પ્રોફાઇલનું સંચાલન કરો`,
    "49ee3087348e8d44e1feda1917443987": `નામ`,
    "10ac3d04253ef7e1ddc73e6091c0cd55": `આગલું`,
    "bafd7322c6e97d25b6299b5d6fe8920b": `કોઈ`,
    "31916d996c9f53949315692bc897f0c7": `સંબંધિત નથી? ધ્યાન આપવું`,
    "e81c4e4f2b7b93b481e13a8553c2ae1b": `નમાના`,
    "0d5e50bf1643118ee026e5d19226542a": `કૃપા કરીને જોડણી તપાસો અથવા કંઈક બીજું શોધવાનો પ્રયાસ કરો`,
    "64ed408b000d73143e6ab1b5acb7c26e": `કૃપા કરીને પ્રોફાઇલ પસંદ કરો`,
    "fa2ead697d9998cbc65c81384e6533d5": `ગોપનીયતા નીતિ`,
    "7595f48afc2f088bc1ff3313ceeb3605": `લાગુ પાડવાની કાર્યપદ્ધતિ`,
    "cce99c598cfdb9773ab041d54c3d973a": `રૂપરેખા`,
    "665d0095958d696322be90e4eb606977": `આ સંબંધ સાથેની પ્રોફાઇલ પહેલેથી જ અસ્તિત્વમાં છે. તમે વર્તમાન પ્રોફાઇલને અપડેટ કરી શકો છો`,
    "27bcc51421612805a9c006f24a4d6dc6": `દૂર યોજના`,
    "f8b66e3c488bb1df16cb3c7bb21447f0": `આવશ્યક દસ્તાવેજો`,
    "82609dd1953ccbfbb4e0d20623193b56": `ઘરે પરત`,
    "c9cc8cce247e49bae79f15173ce97354": `બચત કરવી`,
    "c320cdeffafbb2d60d6d759619c6b5e2": `પ્રોફાઇલ સાચવો`,
    "ab9753c948520fb02c20831980bad53a": `મારી યોજનાઓ`,
    "827452688eed02a12178e96f924ac529": `યોજના`,
    "cb80cf588ba5a0d3dc067b0b0ef219e9": `યોજના`,
    "1925ba54f16355af0964df7dfa145761": `તમારા માટે યોજનાઓ`,
    "13348442cc6a27032d2b4aa28b75a5d3": `શોધો`,
    "32df01b9cf0491a879250b58ba2744ba": `શોધ પરિણામો`,
    // "988cddb5e417156bd3603983dc0c32e5":`શોધ યોજના`,
    "0afcf4c1ba72c441b41fbafaab253fc6": `સ્થાન પસંદ કરો`,
    "be2933038f93e25262400220080c111f": `રાજ્ય પસંદ કરો`,
    "dfe1d316ec9338e23c892b573667b9bb": `તમારો વિકલ્પ પસંદ કરો`,
    "c2ba7e785c49050f48da9aacc45c2b85": `સેવા`,
    "992a0f0542384f1ee5ef51b7cf4ae6c4": `સેવા`,
    "48ecd536efbb3622296aac8de326fa38": `માફ કરશો, પ્રદાન કરેલી માહિતીના આધારે, તમે યોજના માટે પાત્ર નથી`,
    "4cd9a992dc66802a4b44c366881957d1": `માફ કરશો, કોઈ પરિણામ મળ્યું નથી`,
    "f86301756ec6964cb96e2b80a38f4162": `માફ કરશો! અમે તમારા માટે કોઈ મેચિંગ યોજનાઓ શોધી શક્યા નહીં`,
    "46a2a41cc6e552044816a2d04634545d": `રાજ્ય`,
    "efd9a0ba1e9d553a070028627fdcae18": `મોજણીની વિગતો`,
    "4cd61af85d0ec40f50349cd7c3054ace": `સ્વિચ પ્રોફાઇલ`,
    "1d8cd2bfc6943dcf70236f7d351572a0": `શરતો અને નિયમો`,
    "4f7e556abaf92ce8061ad57cc4e7edd1": `આ જરૂરી છે`,
    "371999537b8bb6e7f0099b0c40e0e41d": `આ પ્રોફાઇલ પહેલેથી જ બનાવવામાં આવી છે. તમે પ્રોફાઇલને અપડેટ કરી શકતા નથી.`,
    "9eed21a5afd3ba6841c523b0d70599c7": `યોજનાઓ જોવા માટે 2 કરતા ઓછી સાથેની કોઈપણ કેટેગરી પર ક્લિક કરો અથવા વિશિષ્ટ કેટેગરી માટે કોઈ સર્વે ચલાવવા માટે 2 થી વધુ સાથે કેટેગરી પર ક્લિક કરો`,
    "37528894f18a085f9a078496a68eaaa8": `ટ્રેન્ડિંગ`,
    "d876a9fe1aecb3ffc05a5639ebe22000": `ફરીથી પ્રયત્ન કરો`,
    "06933067aafd48425d67bcb01bba5cb6": `અપડેટ કરવું`,
    "fba7f6c863513d97922faab074d3c716": `અપડેટ પ્રોફાઇલ`,
    "90b1f6651953fcf8b43ddc0da6760aaf": `વપરાશકર્તા પોશાકો`,
    "d8d7ad6abfd08c27baa8bedb78c72ddb": `બધુજ જુઓ`,
    "acac138f2e6f039df2e1fed2f2c0bc44": `EasyGov માં આપનું સ્વાગત છે`,
    "6ebb2e31989c843ea65feefa43237dad": `જ્યારે તમે તમારા એકાઉન્ટમાંથી પ્રોફાઇલ કાઢી નાખો છો, ત્યારે તમે તે ચોક્કસ પ્રોફાઇલ માટે સંગ્રહિત કોઈપણ ડેટા અથવા માહિતી પુનઃપ્રાપ્ત કરવામાં સમર્થ હશો નહીં. પસંદ કરેલ પ્રોફાઇલ માટેની તમામ સક્રિય અને સાચવેલી યોજનાઓ પણ કાઢી નાખવામાં આવશે`,
    "6a64fbe7b065fc8579f2521e0054de02": `જ્યારે તમે તમારું Labharthi એકાઉન્ટ કાઢી નાખો છો, ત્યારે તમે તમારી પ્રોફાઇલ અને તમારા એકાઉન્ટ સાથે સંકળાયેલ અન્ય પ્રોફાઇલ્સમાં સંગ્રહિત કોઈપણ ડેટા અથવા માહિતી પુનઃપ્રાપ્ત કરી શકશો નહીં. તમારી બધી સક્રિય અને સાચવેલી યોજનાઓ પણ કાઢી નાખવામાં આવશે`,
    "93cba07454f06a4a960172bbd6e2a435": `હા`,
    "3aeee34bea95f2b0ced72be632778670": `તમે કોઈ યોજનાઓ બચાવી નથી`,
    "cb32c835ee043e0e2faf4860e46669ae": `આ યોજના/સેવા સમીક્ષા હેઠળ છે. કૃપા કરીને થોડા સમય પછી ફરી પ્રયાસ કરો.`,
    "c2e0ede88489afe5739c8973aa03a787": `તમારી પ્રોફાઇલ સાથે મેળ ખાતી કોઈ યોજનાઓ નથી`,
    "28d2dfa9b435bd81cb2f7f0499b846b3": `યોજનાઓ શોધો`,
    "52561ab2d4640b4e299e817b6ba79173": `લબર્થિમાં આપનું સ્વાગત છે`,
    "fb0fc4b5fdb2bef7b1c42f2cfd523c2d": `પ્રયાસો બાકી`,
    "e13e1cd5f02de37413f9497cc5b65de3": `તમારું એકાઉન્ટ 30 મિનિટ માટે લ locked ક કરવામાં આવ્યું છે. કૃપા કરીને તે સમય પછી પ્રયાસ કરો`,
    "f2a6c498fb90eebdu72k7f888fce3b18": `આવકાર્ય`,
    "1172b2ea87bed4861c8c43a201d53031": `મારી સેવાઓ`,
    "bcc254b55c4a1babdf1dcb82c207506b": `કણ`,
    "8458bad978f4fd6d6c7f8ec2ec0824b4": `પાસવર્ડ ભૂલી ગયા છો?`,
    "d67850bd126f070221dcfd5fa6317043": `સાઇન અપ કરવું`,
    "619d59e2ea9f9bb9fe287404dd3ca931": `તમામ સેવાઓ`,
    "6b46ae48421828d9973deec5fa9aa0c3": `પ્રકાર`,
    "104f0a6879399d6285ab770ed0dbc257": `સેવા દૂર કરો`,
    "0b3937a9dd481d527fcc72adaa735b57": `લબર્થિ એકાઉન્ટ નથી?`,
    "2f453cfe638e57e27bb0c9512436111e": `ચાલુ રાખવું`,
    "f2fdea440d768b85591e936a803c3631": `સાઇન -ઇન કરવું`,
    "82b1fbd43ae27918a37b212a4f60a9ad": `રજૂ કરવું`,
    "31693e2e9aa2e5be1bf3ae45ede9c98d": `યોજનાઓ કે જેના માટે તમે પાત્ર નથી`,
    "d43cd6a49673f0f486ed7fbea1a5fd8a": `આધાર નંબર`,
    "3a06727e678189b8502408cb9b50b437": `પ્રોફાઇલ બદલો`,
    "0323de4f66a1700e2173e9bcdce02715": `લૉગ આઉટ`,
    "644cd55dbe4d01e7e00fc7fc458f9b8f": `શું તમે ખરેખર લોગઆઉટ કરવા માંગો છો?`,
    "c0fb5959a7a8d3ea991f9edb0b1a9e85": `કૃપા કરીને આઈડી પસંદ કરો, અમે તમારા માટે યોજનાઓ મેળવીશું`,
    "827a37fb93758383027c582a39ec6162": `આઈડીનો પ્રકાર`,
    "cb3441fa3e1291a76dd5ea72e656bca3": `કોઈપણ આઈડી વિના ચાલુ રાખો`,
    "99dea78007133396a7b8ed70578ac6ae": `લૉગિન`,
    "02aeaf2672eb06861c144004ef2248d2": `લૉગિન એકાઉન્ટ`,
    "d4d208eb2d8ccd525a64be73f3521dc5": `અમાન્ય ઓળખાણપત્ર`,
    "e8055d9577ec8edf34ee15a88a937daa": `એકાઉન્ટ નથી?`,
    "942a3ef743c4e176f75d5223455f7e1a": `તમારો 10 અંકનો ફોન નંબર દાખલ કરો`,
    "21bdc5689c12595ae14298354d5550d5": `પહેલેથી જ એક એકાઉન્ટ છે?`,
    "dc647eb65e6711e155375218212b3964": `પાસશ`,
    "0b3e8b8021c9b2fafee35ae01ac2cfb9": `${window.projectName} એપ્લિકેશન તમને તમારા અને તમારા પરિવાર માટે શ્રેષ્ઠ અનુકુળ સરકારી કલ્યાણ યોજનાઓ શોધવામાં મદદ કરશે.`,
    "05aca668fa6754247f5094c042fb64ac": `ફોન -ખરાઈ`,
    "f9bc92a6602b9692a0ba578120c9c441": `તમારો OTP કોડ અહીં દાખલ કરો`,
    "d9d1e60e50119d9752001d4196ee6b3c": `OTP`,
    "6e95a24fa2a98c2f59c96a24b182ccfd": `તમને કોઈ કોડ મળ્યો નથી?`,
    "f922096c878b694ec7a3d9282a55904c": `નવો કોડ બદલો`,
    "32b830cef750168335d96aff4dd98782": `મારી નોકરી`,
    "bbd26bc8ce3623addc9c54c36f692f8d": `તમારા માટે નોકરીઓ`,
    "67cb88ed21d4232c4681a5e648554e09": `અરજી ફી`,
    "b90455b71eb5e2399be9d20e23c1a1db": `અરજી કરવાની છેલ્લી તારીખ`,
    "bfe72f18052c940de13501ae9877c3b0": `કેવી રીતે અરજી કરવી`,
    "4f1f44ef7ce0c8a111f33fe400a6e4d7": `પસંદગી કાર્યપદ્ધતિ`,
    "0f5679acafa674831691cfa01d17501e": `નોકરીની સૂચના`,
    "a45ca50bcc7830af2f84fd046648ec92": `તમારી પ્રોફાઇલ સાથે મેળ ખાતી કોઈ નોકરી નથી`,
    "c4bcece293eb1ad7f09d4cf9c2037ea6": `નોકરી શોધો`,
    "5dc52ca9ffdc26147075682c92a0a853": `પોસ્ટ`,
    "3f99ba6fbcb46f28fb24f271fc3c02c1": `નોકરી જોવા માટે કોઈપણ કેટેગરી પર ક્લિક કરો. કોઈપણ નોકરી માટે સર્વે ચલાવવા માટે કાર્ડ પર ક્લિક કરો`,
    "2078989a6c09fdd98efc35d3c1d1de3d": `જોબ કાઢી નાખો`,
    "08c1517355f6e7313c4d629a193ac69a": `શું તમે ખરેખર તમારી સાચવેલી નોકરીઓમાંથી આ નોકરીને કાઢી નાખવા માંગો છો?`,
    "e614dff8cba6f73ae7cee729fe0091dd": `અમાન્ય OTP`,
    "c46c1e69c89811f03dc73feb09273409": `OTP આવશ્યક છે`,
    "ae3bb2a1ac61750150b606298091d38a": `નવો પાસવર્ડ`,
    "887f7db126221fe60d18c895d41dc8f6": `પાસવર્ડ પુષ્ટિ કરો`,
    "70d9be9b139893aa6c69b5e77e614311": `પુષ્ટિ આપવી`,
    "20aedd1e6de4dcf8d115b5a7424c58d7": `પાસવર્ડ અનિવાર્ય છે.`,
    "841a389ec69697c28df7b9739419d21d": `પાસવર્ડ 6 પાત્ર લાંબો હોવો જોઈએ.`,
    "bcb9407d90fd17ccff50587f5edfe987": `પાસવર્ડ મેળ ખાતો નથી.`,
    "318721657b639c8ca02293211db68837": `ફોન નંબર આવશ્યક છે`,
    "4ea3456b0e1b463f50b81c79230cf070": `અમાન્ય ફોન નંબર`,
    "bd454fc3d32f4425d146d3e861409871": `વપરાશકર્તા પહેલાથી જ અસ્તિત્વમાં છે`,
    "ab48b978d197e315e92e904e36d26175": `ફોન નંબર અમાન્ય છે`,
    "70729403b9560d3adb142977608b81d2": `OTP મર્યાદા ઓળંગી ગઈ. 4 કલાક પછી પ્રયાસ કરો.`,
    "1e4dbc7eaa78468a3bc1448a3d68d906": `ફોન નંબર`,
    "4f2df4c330b8df125a3d75f2d69a9b5e": `OTP મોકલો`,
    "9ec54ec336b65da6179cf750de33ecd7": `પાસવર્ડ ભૂલી ગયા છો`,
    "98adc700c19f071f07a714a703a6fc1e": `આઈડી વિગતો`,
    "f262fae0a2f17e8c1eb4e9eddb0160e4": `આગળ વધવું`,
    "49fb619c4872c491fae649c28b339ed8": `તમારા ઓળખપત્રો અમારા રેકોર્ડ સાથે મેળ ખાતા નથી`,
    "58557a71f9811899313234decda72d5a": `તમારું એકાઉન્ટ 30 મિનિટ માટે લૉક કરવામાં આવ્યું છે. કૃપા કરીને તે સમય પછી પ્રયાસ કરો.`,
    "e7665f483813a56e9410787ce2d174ef": `ફોન નંબર પહેલાથી ઉપયોગમાં છે. કૃપા કરીને સાઇન ઇન કરો અથવા સાઇન અપ કરવા માટે બીજો નંબર વાપરો.`,
    "0a46fa5fb9e86e80fc94e229d1967699": `ફોન નંબર નોંધાયેલ નથી. કૃપા કરીને ફરી પ્રયાસ કરો`,
    "bdff8b1cbfb4d5623382d7b0678e707c": `પાસવર્ડમાં ઓછામાં ઓછા 8 અક્ષરો હોવા આવશ્યક છે, જેમાં ઓછામાં ઓછા એક ઉપલા કેસ, એક નીચલા કેસ, એક નંબર અને એક વિશેષ પાત્રનો સમાવેશ થાય છે`,
    "d55085482b3e8da780755832c17d7d60": `છેલ્લી તારીખ`,
    "988cddb5e417156bd3603983dc0c32e5": `શોધ યોજના અને સેવાઓ`,
    "261b39077b81875e115baa22bc6ce04c": `જોબ્સ જોવા માટે કોઈ ચોક્કસ કેટેગરીની છે, કૃપા કરીને કેટેગરી પર ક્લિક કરો`,
    "bdb53070a3fc01196ae38b059a4b42ff": `નોકરી દૂર કરો`,
    "ede1fcad3d6bf808fc948df7d22e1536": `માફ કરશો! અમને તમારા માટે કોઈ મેચિંગ નોકરી મળી શકી નથી`,
    "0c4fa7a0201f434b0ef84494d0aac07a": `માફ કરશો, પ્રદાન કરેલી માહિતીના આધારે, તમે નોકરી માટે પાત્ર નથી`,
    "e4defbb7cdf529fa3606749a82c06932": `પોસ્ટ`,
    "25d2401d71f4ba1ba7685559c1b77927": `તમારી પ્રોફાઇલના આધારે, અમે તમને કોઈપણ નોકરી માટે પાત્ર નથી મળ્યાં. <br> અમે સતત વધુ નોકરીઓ ઉમેરી રહ્યા છીએ, કૃપા કરીને પછીથી તપાસો.`,
    "ea7c2400c0ae5f58608cb5051ee852f3": `આ ક્ષણે તમારા રાજ્યમાં કોઈ નોકરીઓ ગોઠવેલ નથી, અમે સતત અપડેટ કરી રહ્યા છીએ, કૃપા કરીને અમારી સાથે ફરીથી તપાસો`,
    "24fe48030f7d3097d5882535b04c3fa8": `સમાપ્ત`,
    "9dc0186e2d5d3398ffee6ec964b15dc0": `સમાપ્તિની તારીખથી 30 દિવસની અવધિ પછી બધી સમાપ્ત થયેલ નોકરીઓ તમારી "મારી નોકરીઓ" માંથી દૂર કરવામાં આવશે.`,
    "34a934c0c86d866df9d14f8a6e90c637": `લાયક ન હોવાના કારણ`,
    "a97ea56b0e00b2379736ae60869ff66a": `પ્રશ્ન`,
    "8e9e3212461f14c8dbea6456c920bcbc": `પાત્ર મૂલ્યો`,
    "c4dce677291fa5bc05276ffe93c5d8f2": `તમારા દ્વારા પ્રદાન કરેલ મૂલ્ય`,
    "9a917c4a4f546c6072bd61ae617763bf": `તમારા માટે યોજના નથી`,
    "e57c13893a290ce882569b5638ba7b82": `તમારા દ્વારા દાખલ કરેલી આઈડી મળી નથી. મહેરબાની કરીને ફરીથી પ્રયતન કરો.`,
    "e26859a59ede4ab8d8ea0213b68fa732": `સ્રોત સિસ્ટમ હાલમાં જવાબ આપી રહી નથી, કૃપા કરીને થોડા સમય પછી પ્રયાસ કરો.`,
    // "e26859a59ede4ab8d8ea0213b68fa732":`સ્રોત સિસ્ટમ હાલમાં જવાબ આપી રહી નથી, કૃપા કરીને આઈડી વિના આગળ વધો અથવા થોડા સમય પછી પ્રયાસ કરો.`,
    "d2235c7ecbbbae1bf62833dade7486f5": `પ્રોફાઇલ વિગતો ગોલ્ડન રેકોર્ડમાંથી લેવામાં આવી છે.`,
    "346df6b73ae19a6619f26648cf415522": `અયોગ્ય યોજનાઓની સૂચિ પ્રોફાઇલ ડેટા પર આધારિત છે.`,
    "6e0fe1553e84e34da97a6c4c9040b31b": `લબર્થિ`,
    "27559de2125101ccb786807bceb46aa1": `ભારત`,
    "936b6a0effe4990e8f5420a0099fa537": `EasyGov`,
    "8cf04a9734132302f96da8e113e80ce5": `ઘર`,
    "923b39c56654bb6264b6638ba8386b3c": `તમારી પ્રોફાઇલ બનાવવા માટે સાઇન ઇન/સાઇન અપ કરો`,
    "52f2ba99491a1bae953303a4eaf50196": `OTP વડે લોગિન કરો`,
    "18e0c8084d60f55016d67d4e2ca507ce": `SSO સાથે લોગિન કરો`,
    "d3d77957f18f1e208cb5b0caa07bec5c": `વધુ યોજનાઓ શોધો`,
    "6a26f548831e6a8c26bfbbd9f6ec61e0": `મદદ`,
    "b2119dd0c37bb396e24199d5ffbd0c72": `સ્ક્રીન રીડર ઍક્સેસ`,
    "34b6cd75171affba6957e308dcbd92be": `ભાષાંતર`,
    "4221d3e17c6eca2ca6337251a3cf9c4e": `છેલ્લે અપડેટ કરેલું`,
    "ece2c7eaaac9f27b055e6e482ab776b9": `માહિતી શોધ`,
    "ad8f678ccf4a4a34b46f01c74f25b91b": `વપરાશકર્તાઓને વેબસાઇટની અંદરની માહિતીને access ક્સેસ કરવામાં સહાય માટે, "અહીં શોધ" વિકલ્પ પ્રદાન કરવામાં આવ્યો છે. અહીં, કન્નડમાં ટાઇપ કરવાનો વિકલ્પ ઉપલબ્ધ કરાયો છે. શોધ કરતી વખતે, યુનિકોડ ફોન્ટનો ઉપયોગ ફરજિયાત છે. અંગ્રેજીમાં શોધવાનો વિકલ્પ પણ પ્રદાન કરવામાં આવે છે.`,
    "698aa21b21c2252839e5e54a0d2f12ef": `ઇન્ટરનેટ સુલભતા`,
    "4ac2856693f5c8b4fc6cdaf669ed2d74": `જો ઇન્ટરનેટ ધીમું અથવા અનુપલબ્ધ હોય, તો વેબસાઇટ ડિઝાઇનમાં ફેરફાર થઈ શકે છે અથવા કેટલાક પેટા પૃષ્ઠો ખોલી શકતા નથી.`,
    "0da22543a45316a826571412b3d5953c": `વેબસાઇટ વર્લ્ડ વાઇડ વેબ કન્સોર્ટિયમ (ડબ્લ્યુ 3 સી) વેબ કન્ટેન્ટ ibility ક્સેસિબિલીટી ગાઇડલાઇન્સ (ડબ્લ્યુસીએજી) 2.0 લેવલ એએનું પાલન કરે છે. આ સ્ક્રીન રીડર્સ જેવી સહાયક તકનીકોનો ઉપયોગ કરીને વિઝ્યુઅલ ક્ષતિઓવાળા લોકોને વેબસાઇટને access ક્સેસ કરશે. વેબસાઇટની માહિતી વિવિધ સ્ક્રીન વાચકો, જેમ કે જડબાં, એનવીડીએ, સફા, સુપરનોવા અને વિંડો-આઇઝ સાથે ible ક્સેસ કરી શકાય છે.`,
    "cb976b928eacd65e6342cfa78bf196da": `નીચેના કોષ્ટક વિવિધ સ્ક્રીન વાચકો વિશેની માહિતીની સૂચિ આપે છે`,
    "786f669131d80fbed4572a520c28c14d": `Easygov દ્વારા બનાવેલી અને માલિકીની સામગ્રી`,
    "fc290a7672f96fd8b94a9659c8c02322": `| બધા હકો અમારી પાસે રાખેલા છે`,
    "6016a2b341113bf496b719905398ecd2": `કૉપિરાઇટ`,
    "0e8a85115f2c3ef0058c70f30a9b6deb": `કૃપા કરીને તમારા રજીસ્ટર્ડ મોબાઈલ નંબર પર મોકલેલ OTP દાખલ કરો`,
    "34f8ccda5172e4eac9a8a014559b38c1": `કૃપા કરીને તમારો પાસવર્ડ સેટ કરો`,
    "548899be111ad732b37786783dfbd6d8": `કોઈ ઇન્ટરનેટ કનેક્શન નથી`,
    "5c3a1593ca1d34226359df44f3d45f2f": `કૃપા કરીને થોડા સમય પછી ફરીથી તપાસો.`,
    '35043eeac5651298d4bb6c100e071745': `તમને તૃતીય-પક્ષ વેબસાઇટ પર રીડાયરેક્ટ કરવામાં આવશે. શું તમે ચાલુ રાખવા માંગો છો?`,
    "6182e08f3974a70ac4883b5fceaa14c0": `કૃપા કરીને તમારા રજીસ્ટર્ડ મોબાઈલ નંબર પર મોકલેલ OTP દાખલ કરો`,
    "306fb6389c3b3fd2835af15cfc7c66e9": `ફેમિલી આઈડી બદલો`,
    "63fc7651ee1dbf6868a43a5b5fa8b581": `ફક્ત અક્ષરોની મંજૂરી છે`,
    "6903fbe8375d52217c15618170f4078a": `ફક્ત 30 અક્ષરોની મંજૂરી છે`,
    "9ab37820a722ce13e80a3d0aa468d6dc": `*જરૂરી`,
    "0df1f18a2fa23cd60990e47692f99e07": `અરજી`,
    "a60852f204ed8028c1c58808b746d115": `બરાબર`,
    "edbd5921e9c7168e04cbe6f75ee76174": `તમારી પ્રોફાઇલના આધારે, અમે તમને કોઈપણ યોજના માટે પાત્ર નથી મળ્યાં. અમે સતત વધુ યોજનાઓ ઉમેરી રહ્યા છીએ, કૃપા કરીને પછીથી તપાસો.`,
    "a203c39a340e1d45069b1ba64e1d003a": `ધ્યાન!`,
    "037673ab5a84897e0ee43ca37f7680f6": `જો તમે તમારો ડેટા તે જ બદલો છો તો પણ તમારા પ્રોફાઇલ ડેટામાં અપડેટ કરવામાં આવશે. શું તમે ચાલુ રાખવા માંગો છો?`,
    "d1d16cede248b826a431ea11132567cb": `જો તમે તમારો ચકાસાયેલ ડેટા બદલો છો, તો તે ફરીથી ચકાસવામાં આવશે અને તમારી સ્થિતિ બદલાશે. આ તમારી એપ્લિકેશનના અસ્વીકાર અથવા પ્રોસેસિંગનો સમય વધારવાની સંભાવના તરફ દોરી શકે છે.`,
    "23a0ca84a7fd64eee8248bd1dfc77185": `નામ બદલો`,
    "3b383caaaa6113dd83b381b43a97d7ee": `આ વપરાશકર્તા માટે પહેલેથી જ એક પ્રોફાઇલ અસ્તિત્વમાં છે, નવી ફેમિલી આઈડી ઉમેરવાથી પાછલી વિગતો દૂર થશે. ખાતરી કરો`,
    "e0efbdcea0abe735dbf887292a5670fa": `ભાઈ`,
    "ad6e7652b1bdfb38783486c2c3d5e806": `સ્વયં`,
    "19cda37369e2d2015e66b9d478ffbe15": `બહેન`,
    "81e053d78f01540e9cd6fb68a1c03976": `અન્ય કોઈ વ્યક્તિ`,
    "b8e18040dc07eead8e6741733653a740": `માતા`,
    "a30ca91be97a9cedc0d2426e54b0e782": `પિતા`,
    "784c01f6b735be57175e2f438297c741": `જીવનસાથી`,
    "1968ab9fdfedf558714bf6caec9a1234": `પુત્ર`,
    "7bc1cb93f51f61bde0e90b20c81ebf88": `દીકરી`,
    "25f34d5943da3f09a2aa6358193a41bd": `અરજી કરવા આગળ વધવું`,
    "79dff508ab2e4d272f0630d001271eb1": `વધુ નોકરીઓનું અન્વેષણ કરો`,
    "b6ebe1e750185946ed41be9191410612": `તમારે લાબર્થી દ્વારા અરજી કરવા માટે વિગતો પ્રદાન કરવાની જરૂર છે.`,
    "af1899084d8361b9afb9187da988ff9c": `"માફ કરશો, અમે તમારો ડેટા લાવવામાં અસમર્થ છીએ".`,
    "1b56218ddf0a290241d661d26253d51f": `ફક્ત {{attempts}} પ્રયાસ (ઓ) બાકી`,
    "f299cff2484fdb361f4fc3189490d909": `તમારા દ્વારા પૂરી પાડવામાં આવેલી નીચેની માહિતી તમારા સરકારના પુનરાવર્તન સાથે મેળ ખાતી નથી:`,
    "1edf5362e5f4af6f370a7f34c3136a7a": `ચકાસણી નિષ્ફળ`,
    "dcf9ac5128c95d88e068e223dfe01d27": `ખરાઈ દરજ્જો`,
    "bc910f8bdf70f29374f496f05be0330c": `પ્રથમ નામ`,
    "77587239bf4c54ea493c7033e1dbf636": `છેલ્લું નામ`,
    "14fcbe1dd0f7a39f0195aba46cf89d17": `વિગતો ચકાસો`,
    "b6121db5ee47deeb0ca42d5dea0b2225": `પ્રયત્નો નિષ્ફળ`,
    "839301cec5574d7c3256afce4fb85467": `તમે તમારા કુટુંબની વિગતોને ચકાસવાના પ્રયત્નોની માન્ય સંખ્યાને વટાવી દીધી છે. તમારી ચકાસણી અવરોધિત કરવામાં આવી છે`,
    "6733aa56fd001b9bf479650f3950688a": `તમને યોજના એપ્લિકેશન પૃષ્ઠ પર નિર્દેશિત કરવામાં આવી રહ્યા છે`,
    "9303c9bd4f8178680dc382adbfcd62af": `આ ક્ષેત્ર જરૂરી છે.`,
    "53a04cfc3d09c9b2dfb8349791c62bc1": `તમારું નામ આ પરિવારના કોઈપણ સભ્ય સાથે મેળ ખાતું નથી`,
    "1ca35256fcb85c1ba9ea7ac1095462ad": `તમે તમારા કુટુંબની વિગતોને ચકાસવાના પ્રયત્નોની માન્ય સંખ્યાને વટાવી દીધી છે. તમારી ચકાસણી 3 કલાકથી અવરોધિત છે`,
    "0e3a917198575c463bb4fdcb7332b03a": `અમાન્ય આધાર કાર્ડ નંબર`,
    "630058029627ce58e7571e7a5b6ec591": `પૂરું નામ`,
    "072355e896ac1a999d2fa0aa208f94ee": `આ યોજના તમારી "મારી યોજનાઓ" માં સાચવવામાં આવી છે.`,
    "789189638f8a301304dfadeb61036dd9": `આ સેવા તમારી "મારી સેવાઓ" માં સાચવવામાં આવી છે.`,
    "ab8023b4fc2395738b8fd2df440641a9": `આ નોકરી તમારી "મારી નોકરીઓ" માં સાચવવામાં આવી છે.`,
    "671dec8fc4e3dc847c1b0d7b67b4e6e1": `સંબંધ`,
    "f252b4e2f3b2963d330c8cde8e9cdbd9": `જો હોય તો`,
    "ef51899b57258db72f15b4703a8a86ba": `પાન ઇન્ડિયા ગવર્નમેન્ટ વેલ્ફેર સ્કીમ્સ, સેવાઓ અને નોકરીઓ માટે ક્રિયાશીલ માહિતીને to ક્સેસ કરવા માટે નાગરિકો માટે એક જ પ્લેટફોર્મ.`,
    //New Translations for Phase 1:`#VALUE!`,
    "7510e00267e89c78470e79b42ecd6463": `તમારી પોતાની ભાષામાં એપ્લિકેશનનું અન્વેષણ કરો`,
    "83218ac34c1834c26781fe4bde918ee4": `આવકાર્ય`,
    "1d00e7dce692e8dc3f6877f035e3a616": `નમાના`,
    "e0cf58fa3413df99ae83109ebb9a41a3": `OTP ફરીથી મોકલો`,
    "6ed83b24865902c6fd90bbd15247e042": `પાસવર્ડ સેટ કરો`,
    "9157806ea93e82c6843e29353af0339f": `OTP માટે વિનંતી કરો`,
    "69f18e98d2dd54118ade84732dc3a5e0": `પિન ચકાસી લેવું`,
    "2dd4472245a696bc0b4b944db2a8b519": `વ્યક્તિગત`,
    "a274f4d4670213a9045ce258c6c56b80": `સૂચના`,
    "407456e575dee5a4b84a19a7111a4a4d": `જ્યારે તમે 2 નંબરો ઓછી સાથે કોઈપણ યોજના કેટેગરી પર ક્લિક કરો છો, ત્યારે તમે જે યોજનાઓ માટે પાત્ર છો તે યોજનાઓ જોવા માટે સક્ષમ છો, જ્યારે તમે 2 થી વધુ નંબરો સાથે કોઈપણ યોજના કેટેગરી પર ક્લિક કરો છો, તો કેટેગરી વિશિષ્ટ સર્વે તમને વધુ આપવા માટે ચાલશે પસંદ કરેલી કેટેગરી માટે યોજનાઓની શુદ્ધ સૂચિ.`,
    "9139eb8e78808acbd15244d4eb9f1bc3": `વધુ યોજનાઓનું અન્વેષણ કરો`,
    "c61dd5f0e5b5389306ad5221ec2cd795": `આ યોજના તમારી "મારી યોજનાઓ" માં સાચવવામાં આવી છે`,
    "f2a6c498fb90ee345d997f888fce3b18": `કા delી નાખવું`,
    "469bad4859f3c33fcb8ce09314c3e895": `સમીક્ષા મોજણી વિગતો`,
    "7dce122004969d56ae2e0245cb754d35": `સંપાદન કરવું`,
    "c5984b3fe02f7b06d77fb538d842b0fc": `અપડેટ મોજણી વિગતો`,
    "6cf33ce1a7a11a7178131567c777b637": `સમાન નામ અને સંબંધ સાથેની પ્રોફાઇલ પહેલેથી જ અસ્તિત્વમાં છે`,
    "fffcb4d1e12c96b2f571f77ad25878b8": `કારણ`,
    "e8d89b6875d217a990d47701138e687a": `શું તમે ખરેખર આ ડેટા કા delete ી નાખવા માંગો છો?`,
    "aa98db08fed41a99a00aac36f521540f": `છેલ્લી ચકાસણી`,
    "5da618e8e4b89c66fe86e32cdafde142": `થી`,
    "253ec560db0637f6a0e53aefcbd4be0c": `જ્યારે તમે લભારથી એકાઉન્ટને કાઢી નાખો છો, ત્યારે તમે તમારી પ્રોફાઇલમાં સાચવેલ કોઈપણ ડેટા અથવા માહિતી પુનઃપ્રાપ્ત કરી શકશો નહીં. બધી સાચવેલી યોજનાઓ અને {{name}} માટે સંગ્રહિત માહિતી પણ કાઢી નાખવામાં આવશે.`,
    "c0e70d5bfc28ae3aadfb8f85697c54d5": `તમારો ડેટા સફળતાપૂર્વક અપડેટ કરવામાં આવ્યો છે.`,
    "09b73aba9204a05ba5eaf0ea471fdc8c": `EasyGov એ સુરાજ્યા સર્વિસ પ્રાઈવેટ લિમિટેડનો રજિસ્ટર્ડ ટ્રેડમાર્ક છે. EasyGovનું પ્લેટફોર્મ-એ-એ-સર્વિસ મોડેલ સરકારી કલ્યાણને રૂ serv િચુસ્ત, પ્રતિક્રિયાશીલ અને પ્રોગ્રામ કેન્દ્રિત હોવાથી પ્રગતિશીલ, સક્રિય અને કુટુંબિક કેન્દ્રમાં પરિવર્તિત કરે છે. EasyGov નાગરિકોના ફાયદા માટે કલ્યાણ પ્રણાલીની આ જટિલ યાત્રાને હલ કરવામાં વિવિધ સરકારોને મદદ કરી રહી છે. કૃપા કરીને વધુ માહિતી માટે <a href='https://easygov.co.in/' target='_blank'> https://easygov.co.in/</a> ની મુલાકાત લો.`,
    "8f241c62a9523b05bf0b6b16a09d856d": `ભાષા બદલો`,
    "1ccbe6084301f9b1e18e3e7caf723ae1": `શું તમે ખરેખર લોગઆઉટ કરવા માંગો છો?`,
    "32ca0a0f69103bad1e6f134d5a8e36d7": `કોઈ ચોક્કસ કેટેગરી માટે નોકરીઓ જોવા માટે, કૃપા કરીને કોઈપણ જોબ કેટેગરી પર ક્લિક કરો`,
    "d6e6cb19e3b9c02f89d6cd54cfa7c613": `બિઝનેસ`,
    "b1c94ca2fbc3e78fc30069c8d0f01680": `બધા`,
    "1dd1c5fb7f25cd41b291d43a89e3aefd": `આજ`,
    "ebfe9ce86e6e9fb953aa7a25b59c1956": `ગઇકાલે`,
    "4b2ef3655b5d6d48f1fa41892d0043b9": `કોઈ સૂચનાઓ`,
    "094f537cfb139894f1412200c78e87b1": `શું તમે ખરેખર પસંદ કરેલી સૂચનાઓ કાઢી નાખવા માંગો છો?`,
    "4201c11dc26b42deb90cb119c0b12fdb": `સૂચના ડાઉનલોડ કરો`,
    "ae45c43efe0011f67ec52003c0d8f916": `<b> સંકેત </b>: 'પાસવર્ડ' માં ઓછામાં ઓછા 8 <br> અક્ષરો હોવા આવશ્યક છે, જેમાં ઓછામાં ઓછા એક ઉપલા કેસ, એક નીચલા કેસ, એક નંબર અને એક વિશેષ પાત્રનો સમાવેશ થાય છે.`,
    "248336101b461380a4b2391a7625493d": `બચાવેલો`,
    "f03ab16cd58372c77ba45a3d9a5a1cb9": `મુસદ્દો`,
    "a4d158b65f20725f9fe9c1763e9805c8": `ખાનગી`,
    "7fe12d923db43febcc5d8ea18df632ca": `તમારો કેવાયસી દસ્તાવેજ પસંદ કરો`,
    "d8ce714c8a76133a8d5746e3bfdd7ef7": `કેવાયસી`,
    "4f67fe16b274bf31a67539fbedb8f8d3": `દસ્તાવેજ પ્રકાર`,
    "5127fa87ed375848481bfe9c786d8022": `તમારી કેવાયસી ભરો`,
    "8f75e3041034138aba310ba3f097ed24": `દસ્તાવેજ ક્રમાંક`,
    "9abfb506bebd1e72069f0be0014986dc": `અમાન્ય દસ્તાવેજ નંબર`,
    "ecfffd1bad9944f6a50e369f63268cff": `ક્યૂઆર સ્કેન`,
    "c541f95d69a4a507b87b32d05dd6d73c": `કોડને ફ્રેમની અંદર મૂકો`,
    "3876661cb83fa41284f828bd8d7fbb7b": `કેવાયસી પ્રક્રિયાની ચકાસણી અને પૂર્ણ કરવા માટે અપલોડ કરવા માટે કેવાયસી દસ્તાવેજો.`,
    "cc1d6f3625c4eb671d76b6ebf3f6f544": `તમારા ગ્રાહકને જાણો`,
    "08ac502bbb7652c683c1da97c7fdeb94": `{{xxxx}} એ તમારા મોબાઇલ નંબર પર OTP મોકલ્યો છે`,
    "d19a88ee579190614084c2ea84379ac7": `OTP ફરીથી મોકલો (1:00)`,
    "7397e92003e187474e5eab39b27fcb6b": `માફ કરશો, અમે સ્રોત સિસ્ટમ્સમાંથી તમારો ડેટા લાવવામાં અસમર્થ છીએ. પછીથી ફરી પ્રયત્ન કરો.`,
    "a943d4ac1dd2e0048bcbf85225bf2811": `તમે OTP જનરેટ કરવાની મહત્તમ મર્યાદાને વટાવી દીધી છે, કૃપા કરીને 3 કલાક પછી ફરી પ્રયાસ કરો`,
    "8dac090b39cf9b3bff9a9503531d04cf": `તમારું એકાઉન્ટ હાલમાં અવરોધિત છે, કૃપા કરીને થોડા સમય પછી પ્રયાસ કરો.`,
    "08798caaee5cc8ea1282883901c8810b": `બાકીના પ્રયત્નો - {{Number}} ચેતવણી - સતત 4 અસફળ પ્રયત્નો પછી, તમારું એકાઉન્ટ લ locked ક થઈ જશે.`,
    "33018956b3ffe20d413e3c6780f19aff": `કેવાયસી વિગતોની પુષ્ટિ કરો`,
    "90f35a35415ca3b4c623d7c515fce1c0": `ધ્યાન! જો તમે તમારી વિગતોને અપડેટ કરો છો અથવા બદલો છો તો તમારો ડેટા અનરિફાઇડ થશે અને તમે અપડેટ કરેલા ડેટા માટે દસ્તાવેજ પ્રૂફ અપલોડ કરવાનું પૂછશો. આગળ વધવા માટે "ચાલુ રાખો" પર ક્લિક કરો`,
    "aee68a98fd803f091082faf867c0f62b": `કેવાયસી ફોર્મ ભરો`,
    "2e853d869dd0c3cb0a64e4938ee90647": `અમાન્ય પિન કોડ`,
    "8f3124e142a4000fe04a581f4f41c8da": `અમાન્ય {{labour card}} નંબર`,
    "dbb362212f1b8118f17633521d1e72f7": `તમારી એપ્લિકેશન સફળતાપૂર્વક તમારી 'ડ્રાફ્ટ સ્કીમ' માં સાચવવામાં આવી છે.`,
    "5c93310dd0291e121181e830cdda892e": `શબપરીમા`,
    "967d35e40f3f95b1f538bd248640bf3b": `કેમેરા`,
    "37bed5f24fa3752c7830c021501539a0": `શું તમને ખાતરી છે કે તમે ફોટો દૂર કરવા માંગો છો?`,
    "1a263d755f169c2499c1a55f7a465b93": `તમારો દસ્તાવેજ આઈડી હાલમાં અવરોધિત છે, કૃપા કરીને થોડા સમય પછી પ્રયાસ કરો.`,
    "689707127ddb398dc4b447e1dffa3a47": `તમે તમારી વિગતોને ચકાસવાના પ્રયત્નોની માન્ય સંખ્યાને વટાવી દીધી છે. તમારી ચકાસણી 3 કલાક માટે અવરોધિત છે.`,
    "04c850a81052df23680d00d2aa1d891e": `કેવાયસી સ્થિતિ`,
    "a441e519087df0d7c8572b9fafa14ae2": `તમારા દ્વારા પૂરી પાડવામાં આવેલી નીચેની માહિતી તમારા સરકારના રેકોર્ડ સાથે મેળ ખાતી નથી. તમારું નામ રેકોર્ડ .2 સાથે મેળ ખાતું નથી. તમારી ઉંમર રેકોર્ડ સાથે મેળ ખાતી નથી.`,
    "75514ce9dac260a1f7abd0d85ce6af46": `તમારા દ્વારા પ્રદાન કરવામાં આવેલી વિગતો રેકોર્ડ્સ સાથે મેળ ખાતી નથી`,
    "5955e277da6fb75982b0f651f4ae8b92": `અમે સ્રોત સિસ્ટમ્સમાંથી તમારો ડેટા લાવવામાં અસમર્થ છીએ. પછીથી ફરી પ્રયત્ન કરો.`,
    "be60ac326400cc5ef0d173a833c4a40b": `તમારી વિગતો ચકાસી રહી છે`,
    "d1083e84a9a7283ffb081263e8533509": `તમારી એપ્લિકેશન તમારી ‘ડ્રાફ્ટ સ્કીમ’ માં સફળતાપૂર્વક સાચવવામાં આવી છે`,
    "0bad551abed71c0a41ca8df804afec86": `દસ્તાવેજનો ઉપયોગ કરીને કેવાયસી`,
    "e6387f3e684958515af2947d8cdf01d7": `કેવાયસી ફોર્મ અપડેટ કરો`,
    "9c7ed3d8ac60281e90519b4ebf07a9a2": `સરનામું પસંદ કરો`,
    "31e41095bfaa14799239e8d9ba7ad438": `અરજી -વિગતો`,
    "e1e4c8c9ccd9fc39c391da4bcd093fb2": `અવરોધ`,
    "b9eca8f59016e8ff69a2c76fa869d23f": `મજૂર કાર્ડ અપલોડ કરો`,
    "6cf24d7a07132fe88b610e2b3d3def28": `સ્વયં ઘોષણા`,
    "827b05360cd22ccbaf3d085aded71570": `સરનામાના પ્રૂફ`,
    "f734cd7792862bebbbb87ddc54285376": `ઓળખાણનો પુરાવો`,
    "5a8c686dfdba564a2801806f3827c124": `છબીઓ JPG અથવા PNG ફોર્મેટમાં હોવી આવશ્યક છે. PDF ફાઇલો 1 MB કરતા ઓછી હોવી જોઈએ.`,
    "d495cdb8315e12e3dc5fd6121ed69cbc": `અમાન્ય IFSC કોડ`,
    "cd9f177eac6411501839f7868e38767b": `શું તમે ખરેખર આ દસ્તાવેજને કા delete ી નાખવા માંગો છો?`,
    "565d2daf0ccaf00d354669e7551b81dd": `સમીક્ષા પ્રોફાઇલ વિગતો`,
    "9895dd168483d1d34ab9ebfb2b6760be": `બેન્ક પસંદ કરો`,
    "deb5c3f30f3a8381367392d6608fbe68": `અરજીનો દરજ્જો`,
    "970324882e5bbfd2deef9e5b7e2d1160": `એપ્લિકેશન ડાઉનલોડ કરો`,
    "03b55d8ed61b625c209cc42ea0a35b8c": `આભાર!`,
    "8276f0b86b5ee98d03f2eafba3590bb0": `તમારી સેવા કરવાની તક આપવા માટે. તમારી અરજી સફળતાપૂર્વક સબમિટ કરવામાં આવી છે.`,
    "ac3122aeddff176ae67d3df2b2198d19": `આભાર! તમારી એપ્લિકેશન ચકાસણી માટે સબમિટ કરવામાં આવી છે, "એપ્લાઇડ એપ્લિકેશન" હેઠળ એપ્લિકેશનમાંથી તમારી એપ્લિકેશનની સ્થિતિ તપાસો`,
    "73da163444abaee6a5c88532b8eda629": `તમારો અનુભવ કેવો હતો?`,
    "74eb855e4de6fe58228f03006c02fd8a": `બરાબર`,
    "d36ed38206da38c85af59039d18f1108": `<b> તમારા પ્રતિસાદનું વર્ણન કરો </b> (વૈકલ્પિક)`,
    "446e92f15cd245125ccd983ef968e692": `ના આભાર!`,
    "634a1a1ed07518476cb7a4beab87185a": `ભયંકર`,
    "f1b68d66337a81cfa0d2076171cba2a8": `ખરાબ`,
    "45802158e78dd9584161629098018fe8": `સારું`,
    "5709082e0d4e7a2cb92c2348acf297be": `ગમ્યું`,
    "c629e8ac2592361ec6474f07fb75f6fc": `તમારો પ્રતિસાદ અમને સુધારવા અને તમારી પાસે શ્રેષ્ઠ એપ્લિકેશન અનુભવ છે તેની ખાતરી કરવા માટે નવી તકો શોધવાની મંજૂરી આપે છે.`,
    "871abe85eba547582d9e8f0f9c61efbd": `અમને પ્રતિસાદ મોકલવા બદલ આભાર.`,
    "733d43480c8589b1368e5def6b480415": `લાગુ કરવું`,
    "a5b739036b4d0b2bc966291747198d9b": `નોંધાયેલું`,
    "0a4c051fb821b8e96d2bd564b893639b": `તમારા માટે કોઈ કસ્ટમાઇઝ્ડ યોજનાઓ નથી`,
    "9639e32cab248434a17ab32237cb3b71": `અરજી કરવી`,
    "3f68e67dc6c397aaa9d1c24c356f754f": `ચકાસણી`,
    "d63b911814d17bb4d541141b8531a458": `આ યોજનાઓ તમારી "મારી યોજનાઓ" માં સાચવવામાં આવી છે`,
    "454fd3b5167e532f28f4e3d47cd901fa": `તમારી પાસે હજી સુધી કોઈ સાચવેલ યોજના નથી`,
    "5e22b63835aef7976f3ca019841b1b28": `શું તમે ખરેખર આ યોજનાને દૂર કરવા માંગો છો?`,
    "8454c7c12286671b47684cef824ffba6": `"ધ્યાન! જો તમે તમારો ડેટા બદલો છો તો તમારા પ્રોફાઇલ ડેટામાં પણ અપડેટ કરવામાં આવશે. શું તમે ચાલુ રાખવા માંગો છો?"`,
    "24a23d787190f2c4812ff9ab11847a72": `સ્થિતિ:`,
    "85896a744f6fd84cbcd7bddb31fae8d7": `ક્રિયા:`,
    "ccb168b5a6a86eb100dc2dee754a316b": `કારણ:`,
    "85b7d770b94264f51f31d2d7a98f69f0": `અરજી પાછી ખેંચી લેવી`,
    "0ddc5e1c93fec761445a53ee9fd70285": `અધિકાર સાથે મોકલો`,
    "7a4813490daea6bbe84ed02f9534570b": `અરજી અને જોડાણની ચકાસણી`,
    "f9e39c8a1b3348b654b73fcdebeb3265": `અરજી હજી સુધી માન્ય નથી`,
    "6b76127f99b6f29c2a725da125d98760": `લાભ વહેંચવું`,
    "4296fd320beaf61dcf979ff634cf2f2d": `ક્રિયા જરૂરી:`,
    "fc341b619c2671a430365edefc100ff1": `સંપૂર્ણ ક્રિયાઓ`,
    "4f4b8b7d27a33c83089f30dba4989be5": `એપ્લિકેશનો જુઓ`,
    "2f70605f8a2f6c01ac06fe0f6d32b44c": `શું તમને ખાતરી છે કે તમે તમારી એપ્લિકેશન પાછી ખેંચી લેવા માંગો છો?`,
    "6eaaa83f6b9b14dbed5e0779d91fffb5": `અરજી`,
    "6187927c14aae6cab0f87b55ef75edb1": `ફરી અરજી કરવી`,
    "864cacf59d640b51390faf29867c310e": `આગળના લાભ માટે આગળ વધો`,
    "16d2b386b2034b9488996466aaae0b57": `ઇતિહાસ`,
    "c5b2992c0919083c2d8907f8fe1441f6": `સામાજિક-આર્થિક વિગતો`,
    "d79c1042de4ec2d7e6d84d3a8f436765": `વ્યવસાય રૂપરેખા`,
    "3c8d3371dfe92bb990493d3dda09717c": `શિક્ષણ -કિત્તન`,
    "181bfe2f225b876c687104878a6b43cc": `આવાસન વિગતો`,
    "f081b3a821bf13173ac4ec3468122a8c": `આરોગ્ય`,
    "fa284c99eeef0e1c874643e05a449214": `"ધ્યાન! તમે બધા મૂલ્યોને પસંદ કરી શકતા નથી. આ મૂલ્યને પસંદ ન કરતા પહેલા બીજું મૂલ્ય પસંદ કરો."`,
    "311d38f551d45cb61e297748f08bb658": `તમારો ડેટા સફળતાપૂર્વક સાચવવામાં આવ્યો છે`,
    "4500d073ef5972cc935a59ff0b752d8b": `સુધી માન્ય`,
    "8de420ed8c345d37cf65a3494bff92ef": `મતદાર -કાર્ડ`,
    "f6fb95a7bfd158a53680691f250e2273": `સરકારી ફી:`,
    "c51bae43a860107d2ddaddcc44c72355": `સરનામાંનો પુરાવો (કોઈપણ)`,
    "a1cba2ed3414b72aa4d7651653d08900": `વયનો પુરાવો (કોઈપણ)`,
    "330dd937743341b8c97a75ddbcfacf1b": `ફોર્મ ડાઉનલોડ કરવાની લિંક:`,
    "de259fed6ef60355d0dd031c4a97e945": `ફોર્મ અપલોડ કરવાની લિંક:`,
    "6204374d06a6ca36a20e887451db78af": `વધુ સેવાઓનું અન્વેષણ કરો`,
    "98d047289f68350b76f20cd10f1db3d4": `આઈ.પી. નો પુરાવો`,
    "1a01812e1888f4eb6dca36e8b1d39c04": `ડી.ઓ.બી. સાબિતી`,
    "0dcd6f339c0f72875972d60324db52ff": `અરજી -ફી વિગતો`,
    "133e72d94045f209d146dd6fc01de185": `ફીની વિગતો`,
    "b6d11bb63dc2071d6cba2aaeba8c8a1d": `સગવડ ફી`,
    "719fec04166d6fa75f89cd29ad61fa8c": `કર`,
    "a876fbe73a6d039cb8c8566f6aa716d5": `કુલ રકમ`,
    "9d463ea3feb5afec51c20b4f62927ca3": `કુપનનૉ ગુપ્ત્તાન્ક`,
    "620a92a00838e523161dab899b76c764": `આગળ વધવું`,
    "6f861b42317b2333c01faa9b555b7e14": `આ કૂપન સાથે બચત.`,
    "c6088ccfc87a825ceb53a15ce4d99510": `તમે <Amount> ની કુલ છૂટ મેળવી છે`,
    "0e54fc6a9dba79c2bc03ac08f3bf4a27": `હા!`,
    "c1d9a46634bb89273f7d95a5577f15ad": `કૂપન છૂટ`,
    "1063e38cb53d94d386f21227fcd84717": `દૂર કરવું`,
    "ba8550da63869236a92fed41fba6e84f": `કૂપન કોડ સફળતાપૂર્વક લાગુ`,
    "f7a7e0e3897bac9ea4c83d53f6ad5a23": `અમાન્ય કૂપન કોડ.`,
    "90fe9a78f3ea30beeb6b7ef447d2b6dd": `કૂપન કોડ સફળતાપૂર્વક દૂર`,
    "af1b98adf7f686b84cd0b443e022b7a0": `શ્રેણી`,
    "11f18dd73f0c3d374d9d5c230cd43160": `યુવક કૌશલ્ય`,
    "5a6c0d2579b31142130f20104fa58ea6": `પ્રમાણપત્ર`,
    "c482980d384a9d0e7bc39e1140270870": `ફાઇનાન્સ`,
    "605669cab962bf944d99ce89cf9e58d9": `આરોગ્ય`,
    "5dac73aa49b9ccaca4b9b0d0ef615d10": `પોલીસ`,
    "b2ece3493eed05158a9cde4a0d2da2a5": `જાહેર ફરિયાદ`,
    "94df2a6972ca1fa79411645fe9b42339": `ઉપયોગિતા`,
    "f5f0c488ed56cf789d19a13ffba8abe3": `પરિવહન`,
    "0db377921f4ce762c62526131097968f": `સામાન્ય`,
    "bc34e4c4f36b0d66910a906449910f64": `શું તમે ખરેખર આ સેવાને દૂર કરવા માંગો છો?`,
    "1e22827dff05f5a1c044993aff539403": `તમારી પાસે કોઈ સાચવેલ સેવાઓ નથી.`,
    "b08f17b81d3b9b4f6287b87c53cfc97a": `તમારી પાસે ડ્રાફ્ટમાં કોઈ સેવાઓ નથી.`,
    "6f96ffbc03ad1b9ebb5c6e2ecfcbdc71": `તમે હજી સુધી કોઈ સેવાઓ લાગુ કરી નથી.`,
    "daf87fe41645c86672e96857222a0909": `સબમિટ કરી દીધેલી અરજી`,
    "54aeeea175c1509b7c56e0c984f9b4fa": `તમારી સેવા કરવાની તક આપવા બદલ આભાર. તમારી અરજી સફળતાપૂર્વક સબમિટ કરવામાં આવી છે.`,
    "f18c4dab2674eae1e8c287c683b66729": `અરજી ની તારીખ`,
    "fb0728ffa9b40d444db91c818eb21a2b": `અરજદારનુંં નામ`,
    "e500e94f457ddd88824c88fda548bed2": `અરજી`,
    "ad0206b8eec486df629ce37169376f65": `ભૂમિકા અને જવાબદારીઓ:`,
    "6f2ad1cfe3c5b08cc53c62470f4cb1f7": `દસ્તાવેજો જરૂરી:`,
    "1d83797411218ecca1e5ff4d4705dfb0": `પસંદગી પ્રક્રિયા:`,
    "ce9e1e989c012211d8bc83c5353b4495": `નોકરીની અરજીની વિગતો`,
    "18dcaa84c2ee447028f8450cd0380ba6": `શૈક્ષણિક વિગતો:`,
    "93e9a018926429f6d0b0c032910a8eb6": `જોબ એપ્લિકેશન વિગતોની સમીક્ષા કરો`,
    "6f906132d3c6b88b57225fdcf76f6e71": `વારંવાર પૂછાતા પ્રશ્નો`,
    "2d8d66b9c9b540fcb8384167f1565b64": `કૃપા કરીને જોડણી તપાસો અથવા કંઈક બીજું શોધવાનો પ્રયાસ કરો.`,
    "f73a506c52c2dba159fc0fbee823aace": `તમે આ યોજના માટે પહેલેથી જ અરજી કરી છે. કૃપા કરીને તમારી લાગુ યોજનાઓમાંથી તે માટે તમારી એપ્લિકેશનની સ્થિતિ તપાસો.`,
    "b73856e1c7ab3e647e28663d5e30fabe": `તમે આ યોજના માટે પહેલેથી જ અરજી કરી છે. કૃપા કરીને તમારી લાગુ સેવાઓમાંથી તે માટે તમારી એપ્લિકેશનની સ્થિતિ તપાસો.`,
    "cd850a99c0895272f2cdf23586867b16": `તમે આ યોજના માટે પહેલેથી જ અરજી કરી છે. કૃપા કરીને તમારી નોકરીમાંથી તે માટે તમારી એપ્લિકેશનની સ્થિતિ તપાસો.`,
    "4e0b8852fc3f3d1a2b4c24c4602766dd": `છબી JPG, PNG, PDF ફાઇલમાં અને 1MB કરતાં ઓછી હોવી આવશ્યક છે.`,
    "5a9e7167eb5b7cb7bb46cf5c05eed59a": `ફરવું`,
    "91412465ea9169dfd901dd5e7c96dd99": `અપલોડ કરવું`,
    "3835b71ace5673385a19d56bc938fda8": `ખેત -ફોટો`,
    "4386624773a0ef6de5a5de0dd6f8facd": `દસ્તાવેજ પ્રકાર દાખલ કરો`,
    "fdba3d2a9c707e50f4eec34cd6bdd0d6": `"તમારા ગ્રાહકને જાણો" એ સરકારી યોજના માટે અરજી કરનારા વપરાશકર્તાની ઓળખને ઓળખવા અને તેની ચકાસણી કરવાની ફરજિયાત પ્રક્રિયા છે.`,
    "244773cb8f460196045c5b90659d2c9d": `તમારી એપ્લિકેશન તમારી "ડ્રાફ્ટ સેવાઓ" માં સફળતાપૂર્વક સાચવવામાં આવી છે`,
    "bbcb2990551820fc4678835c14a84503": `ફોટો દૂર કરો`,
    "78e3e2ba60b81cf6cc24e6baf89eaa81": `અંગત પ્રમાણપત્રો`,
    "88bcab139c5d6142b04bf77d7dd1c023": `તમે આ સેવા માટે પહેલેથી જ અરજી કરી છે. કૃપા કરીને તમારી લાગુ સેવાઓમાંથી તે માટે તમારી એપ્લિકેશનની સ્થિતિ તપાસો.`,
    "bf8cd5900a65efaa89931067cb1d3775": `તમે ડ્રાફ્ટમાં કોઈ યોજનાઓ સાચવી નથી.`,
    "beab92ac3c8a798a35aea2ad0809313f": `મહેમાન તરીકે ચાલુ રાખો`,
    "95884374c7752d972101b70854caffe6": `સાઇન ઇન/સાઇન અપ કરો`,
    "e44cb1c4a784a247f7296cc02f7f301c": `જિલ્લાની પસંદગી`,
    "9aa060dc3e3bb3ffff9908c5e713a80c": `તમે હજી સુધી કોઈપણ સેવાઓ માટે અરજી કરી નથી.`,
    "383f419ea8c0b8142cbdc71e2e4b144a": `પાછલો પ્રશ્ન`,
    "fb188e808b65b9ee48a5c9d88fb92af2": `ફરીથી પ્રારંભ`,
    "db5eb84117d06047c97c9a0191b5fffe": `ટેકો`,
    "bc80ae98530c6ba4e76ed34c58d81875": `મોબાઇલ એપ્લિકેશન માટે Easygov ડાઉનલોડ કરો`,
    "85ce0cae173233410acbc380a7e531cd": `કૃપા કરીને નોંધો કે આ પૃષ્ઠ સરકારની વેબસાઇટ્સ / વેબ પૃષ્ઠોની લિંક્સ પણ પ્રદાન કરે છે. મંત્રાલયો/વિભાગો/સંગઠનો. આ વેબસાઇટ્સની સામગ્રી સંબંધિત સંસ્થાઓની માલિકીની છે અને વધુ માહિતી અથવા સૂચન માટે તેમનો સંપર્ક થઈ શકે છે`,
    "ad0a802ec8134e9210a5625933db50a7": `જાહેર કરવું`,
    "26dbd93cbe6f1e56b1577d8e3cb7fda3": `સેવાઓ પસંદ કરો`,
    "c6582b36b0f516684a873e8782517822": `યોજનાઓ અને સેવાઓ પસંદ કરો`,
    "b98287be44e1230cf144aa3af08ac82f": `તમે હજી સુધી કોઈ યોજનાઓ માટે નોંધાયેલા નથી`,
    "449d3cf7dd09172024ebe93872410f0f": `અરજી`,
    "025d9b3a3c2a6a27d04c57c1d7cabc9f": `કોઈ ક્રિયાની જરૂર નથી`,
    "06df33001c1d7187fdd81ea1f5b277aa": `ક્રિયા`,
    "adff901e227bf2b2200c4767fe773836": `ક્રિયાઓ જરૂરી છે`,
    "4ff84d2ee02fe6ef0ca2edc3cdecc0ed": `આવશ્યક ક્રિયાઓ`,
    "c08c272bc5648735d560f0ba5114a256": `સંપર્ક આધાર`,
    "fd2f820569d83784238e1027897b463e": `તમારું નામ દાખલ કરો`,
    "cda83d00666a7bfa8cde6808de4ef994": `સંપર્ક નંબર`,
    "a4d3b161ce1309df1c4e25df28694b7b": `રજૂ કરવું`,
    "4c2a8fe7eaf24721cc7a9f0175115bd4": `સંદેશ`,
    "68d390535e8a7120e3f6942d8a2214a0": `તમારો સંદેશ દાખલ કરો`,
    "2f644b4a27e68f505c4f4ac2ffe3a8ac": `કૃપા કરીને ફક્ત મૂળાક્ષરો, સંખ્યા, અલ્પવિરામ, -, _, / દાખલ કરો`,
    "58f7a43f2c3bd7854d99fbbbc3fa4ae9": `ફક્ત મૂળાક્ષરોની મંજૂરી છે.`,
    "4906d981bb592b32f6982fb4187c7b60": `માન્ય આઈએફએસસી કોડ દાખલ કરો.`,
    "562d3d08919ae105c3ebc42d9607d266": `કૃપા કરીને ફક્ત મૂળાક્ષરો અથવા સંખ્યાઓ દાખલ કરો`,
    "fab2bb673b21bef95ee70b99dbd7f9fc": `ફક્ત 11 અક્ષરોની મંજૂરી છે.`,
    "a7eb433676475fee54727c14ad880b8c": `ફક્ત 150 અક્ષરોની મંજૂરી છે.`,
    "7c58cde3b827bc7d57cbf77865046169": `કૃપા કરીને ફક્ત નંબરો દાખલ કરો`,
    "544b0264d55a1e4cf476b279240d9be4": `ફક્ત દસ અંકોની મંજૂરી છે. કૃપા કરીને 0 અથવા +91 ઉપસર્ગ ન કરો`,
    "73f0d980e48b8141e0776e02d96a872f": `ફક્ત બાર અંકોની મંજૂરી છે.`,
    "0ce98bd973419ff33921a2275e7903b9": `કૃપા કરીને માન્ય પાન નંબર દાખલ કરો.`,
    "c6f91b305e91a0053748310de170a73c": `ફક્ત છ અંકોની મંજૂરી છે`,
    "9f44bd93c8988c682d5ef5369fd11f47": `કૃપા કરીને માન્ય ઇમેઇલ દાખલ કરો`,
    "10803b83a68db8f7e7a33e3b41e184d0": `જન્મ તારીખ`,
    "ac598199ea0dd028ade4677a435caf4b": `માન્ય આધાર નંબર દાખલ કરો`,
    "d0de720ccdffea8490dfd2893c50ebc5": `તે {{displayName}} કરતા વધારે હોવું જોઈએ`,
    "ced6e171e13ebd5c37415c822706b9d4": `તે {{displayName}} કરતા ઓછું હોવું જોઈએ`,
    "d1a1cb2bd14fdd9eec972f162cfd1ee0": `તે {{displayName}} ની બરાબર હોવી જોઈએ`,
    "9483135517d156229d0439dff74b1ca0": `તે {{displayName}} કરતા વધારે અથવા બરાબર હોવું જોઈએ`,
    "01e9cd748e852b1ae389b980bff195b0": `તે {{displayName}} કરતા ઓછું અથવા બરાબર હોવું જોઈએ`,
    "dc236871b26ba387e1284445caa99df4": `તમારી સેવા કરવાની તક આપવા માટે.`,
    "2102fff7bb4de45c9e616e3f01b6810f": `તમારી અરજી સફળતાપૂર્વક સબમિટ કરવામાં આવી છે.`,
    "dc9466f3d424b4acba2fc5c9f58d4f8a": `માફ કરશો, તમે પાત્ર નથી`,
    "a9198ed7d89e246a80078db84d2722e8": `તમારો ફોન નંબર અથવા પાસવર્ડ મેળ ખાતો નથી`,
    "8ac0a98c8c2f2c86396e34b6b5d63e38": `સમાન નામ સાથેની પ્રોફાઇલ પહેલાથી અસ્તિત્વમાં છે`,
    "4ae112e33b3189c9fa04369b51259d76": `સમાન નામ અને સંબંધ સાથેની પ્રોફાઇલ પહેલેથી જ અસ્તિત્વમાં છે`,
    "b0fb09a28a4a52a1b3ca2656dec0435e": `શું તમે ખરેખર આ દસ્તાવેજને દૂર કરવા માંગો છો?`,
    "e384b6a4760d80be609d897414cfe680": `તમે રજિસ્ટર્ડ વપરાશકર્તા નથી, તમે આગળ વધવા માટે "સાઇન અપ" અથવા "OTP વડે લૉગિન" કરી શકો છો.`,
    "c2cd85ffa55b2ec9b049b7eb131d3a9f": `દાખલ કરેલા પાસવર્ડ્સ મેળ ખાતા નથી. ફરીથી પ્રયત્ન કરો`,
    "3a816ee692e09b3cac6ef18e14071bd4": `તમારો પાસવર્ડ સફળ રીતે સેટ કરવામાં આવ્યો છે`,
    "764d4847b734121ceb58e5c8195fa5b6": `તમારો પ્રતિસાદ અમને સુધારવા માટે નવી તકો શોધવાની મંજૂરી આપે છે અને ખાતરી કરે છે કે તમારી પાસે શ્રેષ્ઠ એપ્લિકેશનનો અનુભવ છે.`,
    "701d895fbc64569aa143ca6c44274139": `તમે OTP જનરેટ કરવાની મહત્તમ મર્યાદાને વટાવી દીધી છે, કૃપા કરીને થોડા સમય પછી ફરી પ્રયાસ કરો.`,
    "0bdfc4d562c9b0ccfaa4c0f6d4cac98f": `તમે હજી સુધી કોઈ યોજનાઓ માટે અરજી કરી નથી.`,
    "4391bf5189e3e13c4aa4cb314c605ac8": `તમે હજી સુધી કોઈ યોજનાઓ બચાવી નથી.`,
    "92202504591d549b89340d26ffc0753a": `આ નોકરી તમારી "મારી નોકરીઓ" માં સાચવવામાં આવી છે`,
    "af6ebe0c0e89201cb2294f32955f7b15": `એક સમયે ફક્ત 20 દસ્તાવેજોની મંજૂરી છે.`,
    "b1481f771aee527fd910fa8d8672cd19": `શું તમે ખરેખર આ સૂચનાને દૂર કરવા માંગો છો?`,
    "06a6b009c19d5816574cdc7800d10eb5": `તમારી વિનંતી સફળતાપૂર્વક ઉભી કરવામાં આવી છે.`,
    "b941bc7a9d4fe7a8c7c31ed440754285": `સમીક્ષા`,
    "e56b98da835e8f4a018502b121520ac7": `બાકી પ્રયાસો`,
    "416828262df288445e72ca1d3661f993": `ચેતવણી - સતત 4 અસફળ પ્રયત્નો પછી, તમારું એકાઉન્ટ લ locked ક થઈ જશે`,
    "9f978addf10226b03278e4932348ba98": `ફક્ત 40 અક્ષરોની મંજૂરી છે.`,
    "69f076c339373e00e9e841da965f4208": `આધાર અપડેટ કરો`,
    "48a82e0b2bd5818f110e55bb6f14aedc": `તમારા દ્વારા પૂરી પાડવામાં આવેલી નીચેની માહિતી સરકારી રેકોર્ડ સાથે મેળ ખાતી નથી`,
    "5c37ad5c0dd6e318af899b3c8fcbe886": `માફ કરશો, અમે સ્રોત સિસ્ટમમાંથી તમારો ડેટા લાવવામાં અસમર્થ છીએ. તમે ચકાસણી વિના આગળ વધી શકો છો અથવા પછીથી પ્રયાસ કરી શકો છો.`,
    "cdb2033fc9a30d6d6c1abe401cd1e37b": `પાછળથી પ્રયાસ કરવો`,
    "f8d855b26faffa0f8779fb44632d6bc1": `અમાન્ય કોડ`,
    "d53b4d564b9054db9b4b4a50e713197f": `કૃપા કરીને અહીં કેપ્ચા દાખલ કરો`,
    "ed001fb3ff7eaca9bbfbaacddc9d5a72": `કોઈ ઇતિહાસ મળ્યો નથી`,
    "4934d9bc9ebd024d4106d5e1f093ae79": `મુલાકાતીઓની ગણતરી`,
    "f7ce7cb4b450acfe0b4cef36c3bdec2d": `તમારું બેંક એકાઉન્ટ હાલમાં નિષ્ક્રિય છે. તમે આગળ વધી શકો છો પરંતુ જો તમારી અરજીને મંજૂરી મળે તો લાભ મેળવવા માટે તમારું બેંક એકાઉન્ટ વહેલી તકે સક્રિય કરો.`,
    "df9e5c88861b08c33b2d46de8d7037e9": `તમારું બેંક એકાઉન્ટ હાલમાં રદ થયેલ છે. તમે આગળ વધી શકો છો પરંતુ જો તમારી અરજીને મંજૂરી મળે તો લાભ મેળવવા માટે તમારું બેંક એકાઉન્ટ વહેલી તકે સક્રિય કરો.`,
    "3fc95e40b475f7269e64f25a1c4aef3b": `તમારું બેંક એકાઉન્ટ તમારા આધાર સાથે જોડાયેલું નથી. તમે આગળ વધી શકો છો પરંતુ જો તમારી અરજીને મંજૂરી મળે તો લાભ મેળવવા માટે વહેલી તકે તમારા બેંક એકાઉન્ટને તમારા આધાર સાથે જોડવામાં આવશે.`,
    "1c35d003a65da48bf9f6d6fc69271d5a": `માફ કરશો, સિસ્ટમ ભૂલને કારણે તમારી એપ્લિકેશન સબમિટ કરી શકાતી નથી. પછીથી ફરી પ્રયત્ન કરો.`,
    "8e437cbd840bce5c5edc0ef8164de6b7": `જો તમે તમારી વિગતોને અપડેટ કરો છો અથવા બદલો છો તો તમારો ડેટા અનરિફાઇડ થશે અને તમને અપડેટ કરેલા ડેટા માટે દસ્તાવેજ પ્રૂફ અપલોડ કરવાનું કહેવામાં આવશે. ચાલુ રાખવા માટે "આગળ વધો" પર ક્લિક કરો.`,
    "b010e3be9f81072a9808ed4a5dd0e45b": `સર્વેક્ષણમાં તમારા દ્વારા પ્રદાન કરવામાં આવેલી વિગતો સાથે નીચેની માહિતી મેળ ખાતી નથી:`,
    "01acb0344944f11d68aeecc9f5b574ea": `શું તમે પ્રતિસાદ (ઓ) સાથે આગળ વધવા માંગો છો?`,
    "4ff70f7b2e5a74415de360150a6e59d3": `તમારા`,
    "bd7aaae9a27a2a4d71214c002bcf50ce": `રેકોર્ડ સાથે મેળ ખાતો નથી.`,
    "ebfd339b0ac14aa79b325b53f06bd375": `તમે પ્રદાન કરેલી માહિતી તમારી આધાર વિગતો સાથે મેળ ખાતી નથી. તમે આગળ આગળ વધી શકતા નથી.`,
    "1e410e1b9903e7a99b93857f536b7e1d": `ચકાસી નથી`,
    "28b266f2bf0a4ecc2dec3b6ca24fcb9b": `અપલોડ કરવું`,
    "68982db8041ee52dd9e5a6e527c8a1fa": `ફરી ઉપડવું`,
    "e7bf7d83a37666fd8749e433189b1c27": `મહેરબાની કરી રાહ જુવો`,
    "c389f9e7d5ea93af9deb2e8f1f9c6d89": `વધુ જાણવા માટે {{"0x0001F6C8"}} પર ક્લિક કરો.`,
    "f17816bcbe79e18bee49388fe84d2876": `OTP સમાપ્ત.`,
    "1531d9dc798857eb85345d349bffc99b": `પાછળની બાજુ`,
    "7939de8a9958e01fb1f172e21c5a7a85": `કૃપા કરીને સાચી માહિતી પ્રદાન કરો, તે તમને લાયક યોજનાઓ બતાવવામાં મદદ કરશે.`,
    "bf58c4a9eac8e0ec82c158c3adfcd609": `યોજનાઓને વિવિધ કેટેગરીમાં વર્ગીકૃત કરવામાં આવી છે. તમે તમારી પસંદીદા કેટેગરી મુજબ યોજનાઓ ચકાસી શકો છો`,
    "09349e778786f7ed758021ddbed12ca6": `આ માહિતી અમને {Intent} કેટેગરી હેઠળ પાત્ર યોજનાઓ શોધવામાં મદદ કરશે.`,
    "0d45c019e7e9237a316f0f16824015d2": `તમારો પ્રોફાઇલ ડેટા વિવિધ ટ s બ્સ હેઠળ સંગ્રહિત છે. તમે તમારી કોઈપણ પ્રોફાઇલ માહિતીને સંપાદિત કરી શકો છો.`,
    "3f52884ec08606e6e73426eaec8de195": `આ માહિતી અમને આ યોજના હેઠળ તમને પ્રાપ્ત થઈ શકે તેવા ચોક્કસ લાભ અને લાભ મેળવવા માટે એપ્લિકેશન પ્રક્રિયા વિશેની વિગતો પ્રદાન કરવામાં મદદ કરશે.`,
    "c389f9e7d5ea93af9deb2e8f1f9c6d89": `વધુ જાણવા માટે {{"0x0001F6C8"}} પર ક્લિક કરો.`,
    "5cc2bdab3db5aa21633656a0d55f7477": `તમે એપ્લિકેશન સાથે આગળ વધી શકતા નથી`,
    "d5de543c0e83073f05a846d62cc83c81": `દસ્તાવેજ પહેલેથી જ તે જ ક્ષેત્ર માટે અપલોડ કરાયો છે.`,
    "fca8da28cbcbe094dc646872e7cdbc7b": `માન્ય દાખલ કરો`,
    "6fa2260c737f40fa1a3f63274c8fca48": `તમે આગળ આગળ વધી શકતા નથી. તમે ક્યાં તો પ્રોફાઇલમાં અથવા તમારા આધાર કાર્ડમાં તમારી વિગતોને સુધારી શકો છો`,
    "3272e327ba9c82197c5c982487224925": `માફ કરશો, અમે સ્રોત સિસ્ટમમાંથી તમારો ડેટા લાવવામાં અસમર્થ છીએ. પછીથી ફરી પ્રયત્ન કરો.`,
    "586637318049e6dbb03e991bc45cba06": `તમારું એકાઉન્ટ ડિલીટ કરવા માટે તમારે પહેલા તમારા એકાઉન્ટ સાથે સંકળાયેલ તમામ પ્રોફાઇલ્સ ડિલીટ કરવી પડશે.`,
    "ce6d6092569f3f247659ceb51d57be95": `તમે {{profileName}} પ્રોફાઇલ કાઢી શકતા નથી કારણ કે તમારી એપ્લાઇડ અથવા એનરોલ્ડ સ્કીમ્સમાં તમારી પાસે ચાલુ એપ્લિકેશન છે`,
    "8d258e398e2dbc8099c984258fadce2a": `તમારા રજીસ્ટર્ડ મોબાઈલ નંબર (xxx xxx {{phoneNumber}}) પર OTP મોકલવામાં આવ્યો છે.`,
    "abb2e3d13ded4cea9275beafacd9f4c8": `આ યોજનામાં તમારી રુચિ વધારવા માટે, "તમારી રુચિ ઉત્પન્ન કરો" પર ક્લિક કરો અને સરકાર આ યોજનાનો લાભ આપવા માટે તમારી પાસે પહોંચશે`,
    "493de8e0a6b5d4a10af30cf3105b9e1f": `તમારી રુચિ પેદા કરો`,
    "c9b0246a8f3989724ec4958f91e380a7": `યોજનાની વિગતો`,
    "804ca01b219522a88d26c3002632a00e": `વિગતો શેર કરવા બદલ આભાર.`,
    "04b6d9b7eb73749476d958b2133f4bbe": `યોજના -જાગૃત મોજણી`,
    "a5661f68fc78a5d4f15afa057fec7a9f": `આગળ વધવા માટે તમારે સર્વેક્ષણમાં તમારી વિગતો અપડેટ કરવાની જરૂર છે.`,
    "1e4531d1dc128abbc00b48e5c7b6ef48": `અપડેટ મોજણી`,
    "362e532b6ad1fda489c5909d437f02a3": `અમે તમારી વિગતો મેળવી રહ્યા છીએ, કૃપા કરીને રાહ જુઓ ..`,
    "8a1b3c7d62e2d60d5be34d8e36a9768f": `વ્યક્તિગત લૉગિન પર પાછા જાઓ`,
    "6737d50489b1cf1d4408e66b8591ca38": `તમારી યોજના એપ્લિકેશન પર તમારી પાસે ક્રિયાઓ બાકી છે. તમારી એપ્લિકેશન સાથે આગળ વધવા માટે બાકી ક્રિયાઓ પૂર્ણ કરો.`,
    "e37c99137b7396277137c1f73f8a86d5": `સીઇજીની પહેલ`,
    "706c7a936b977ec3c4e6603f888e3fce": `EasyGov દ્વારા સંચાલિત`,
    "598d42810b342196884f255912974d69": `એસ.એલ. નંબર`,
    "60e4e81c6f9c1938d77e8ded6d64643a": `સ્ક્રીન રીડર`,
    "15bbb9d0bbf25e8d2978de1168c749dc": `વેબસાઇટ`,
    "21d587949a2a2e89c958b622e244b405": `મફત/ વાણિજ્યિક`,
    "d5c03f7b855d442a2a7f24024cf89960": `નોન વિઝ્યુઅલ ડેસ્કટ .પ (ક્સેસ (એનવીડીએ)`,
    "f956e16f65a3a937e5ecfe4195d17d0d": `(બાહ્ય વેબસાઇટ જે નવી વિંડોમાં ખુલે છે)`,
    "b24ce0cd392a5b0b8dedc66c25213594": `મુક્ત`,
    "c8ed8ec3dc8b21290b5d28ca56344553": `જડબાં`,
    "b2788e1ab6be65f1c38bf7d23cd081f1": `વાણિજ્યક`,
    "ec18aa8148f59475130ff217d693b465": `બારી-આંખો`,
    "d96a273ceb2b5f83d710081d9c38440a": `જવા માટે સિસ્ટમ પ્રવેશ`,
    "c932fd98147348de9974252a0efcc7cb": `WebAnywhere`,
    "540431e2b3234a8698b4044f15ef1fff": `પીડીએફ સામગ્રી`,
    "801ab24683a4a8c433c6eb40c48bcd9d": `ડાઉનલોડ કરવું`,
    "83f8a38f024082405d2fff921c2bb1d2": `એડોબ એક્રોબેટ રીડર`,
    "9d8c84ff52cfc2c2e0075e669edacffd": `શબ્દ ફાઇલો`,
    "4db1a171aaf96723d4002cc41559ddf7": `બાહ્ય વેબસાઇટ જે નવી વિંડોમાં ખુલે છે`,
    "86c61b344697039b8a54bbef9e4959c3": `એક્સેલ વ્યૂઅર 2003 (2003 સુધીના કોઈપણ સંસ્કરણમાં)`,
    "04efb134468330da1430e32701160107": `એક્સેલ માટે માઇક્રોસોફ્ટ Office ફિસ સુસંગતતા પેક (2007 સંસ્કરણ માટે)`,
    "a385a9bb61bb694694eef5d2f54c563a": `પાવરપોઇન્ટ પ્રસ્તુતિઓ`,
    "da67d56a74c01537a71a3ab0eda00faa": `પાવરપોઇન્ટ માટે માઇક્રોસ .ફ્ટ Office ફિસ સુસંગતતા પેક (2007 સંસ્કરણ માટે)`,
    "bb625547a85b98c2c9f9f4121c72cd78": `ફ્લેશ સામગ્રી`,
    "ab69edd2798068928605717c6591462f": `એડોબ ફ્લેશ પ્લેયર`,
    "2c4fa0a307152ef814bbab8d4075d938": `ઓડિયો ફાઇલો`,
    "da7be99fd6fac5c8a4065eb2c07cad88": `વિંડોઝ મીડિયા પ્લેયર`,
    "5a5652ca26ee42fe73fdd79497cf9e21": `શબ્દ દર્શક (2003 સુધી કોઈપણ સંસ્કરણમાં)`,
    "2e856241d3d995fefdb1b4b32f84a79b": `વર્ડ માટે માઇક્રોસોફ્ટ Office ફિસ સુસંગતતા પેક (2007 સંસ્કરણ માટે)`,
    "64a443242750513eb56695e07aedcff2": `ફાઇલો એક્સેલ`,
    "382b0f5185773fa0f67a8ed8056c7759": `એન/એ`,
    "462c18d5b89050fb1b7f8fca1e535af8": `પ્રક્રિયાને કરેલું`,
    "ccb26ac32c3cc7cc2f0fd1bbac3b8e2d": `તમારી પાસે પ્રોસેસ્ડમાં કોઈ સેવાઓ નથી`,
    "fc44a0efc672761745c2e76aac6851d2": `તમારી એપ્લિકેશનને નકારી છે કારણ કે`,
    "7d0db380a5b95a8ba1da0bca241abda1": `તરફ`,
    "1252044838fd74c4e8b506ef1302a8b5": `ફરિયાદ`,
    "06627e6919d21853d887d00334ee5337": `ફરિયાદ સંચાલન`,
    "f940fbe4aec76e9784e7f15c4b92ac6d": `તમને પ્રોસેસ્ડમાં કોઈ ફરિયાદ નથી`,
    "2290dc1500a2b4548eb3b931f2e3609a": `તમારી પાસે કોઈ સાચવેલી ફરિયાદ નથી.`,
    "b25b60dc1a5f7c17e09bc73174668d9a": `તમને ડ્રાફ્ટમાં કોઈ ફરિયાદ નથી.`,
    "a02c58d421087ec9519620a29d68a6ec": `તમે હજી સુધી કોઈ ફરિયાદ માટે સબમિટ કરી નથી.`,
    "b65f636dcbc018b1901e23a7c2adde90": `વધુ અન્વેષણ કરવું`,
    "5c835c22429f25992b4371691ecde1a4": `રજૂ કરેલું`,
    "2535f5e4e0892c73a5fad023bf3e15e2": `મારી ફરિયાદો`,
    "4351cfebe4b61d8aa5efa1d020710005": `દૃશ્ય`,
    "e9516387730903ffd7cfe3317e05e385": `આ ફરિયાદ તમારી "મારી ફરિયાદ" માં બચાવી છે.`,
    "b70db24f2cb70fb266eb03da8f289ba5": `ફરિયાદ જુઓ`,
    "646558064e10a12b1e8914e86eca3ca6": `તમારી સેવા એપ્લિકેશન પર તમારી પાસે ક્રિયાઓ બાકી છે. તમારી એપ્લિકેશન સાથે આગળ વધવા માટે બાકી ક્રિયાઓ પૂર્ણ કરો.`,
    "d2841171ea05a48fa5463b3f7c7c92d7": `તમારી ફરિયાદ એપ્લિકેશન પર તમારી પાસે ક્રિયાઓ બાકી છે. તમારી એપ્લિકેશન સાથે આગળ વધવા માટે બાકી ક્રિયાઓ પૂર્ણ કરો.`,
    "9279f823b01f13b7a8c04688289543cc": `તમારી એપ્લિકેશન સફળતાપૂર્વક તમારી 'ડ્રાફ્ટ ફરિયાદ' માં સાચવવામાં આવી છે.`,
    "af481c93dcae73d73afe9927076c8a09": `તમે આ ફરિયાદો માટે પહેલેથી જ અરજી કરી છે. કૃપા કરીને તમારી લાગુ ફરિયાદોમાંથી તે માટે તમારી એપ્લિકેશનની સ્થિતિ તપાસો.`,
    "ce972231a0faf31791c7ac0bf0d1da84": `જો તમે કોઈ ચૂકવણી કરી હોય તો તમારી રકમ પરત કરવામાં આવશે નહીં.`,
    "2ffef02ca513f8bbadbaf130a23097de": `તમારી એપ્લિકેશન તમારી "ડ્રાફ્ટ ફરિયાદો" માં સફળતાપૂર્વક સાચવવામાં આવી છે`,
    "e55e425dbaf6b6449940eac8ea749092": `કૃપા કરીને ફક્ત મૂળાક્ષરો દાખલ કરો.`,
    "64ff9e3809686d4ea3253a631cae4761": `કૃપા કરીને 2 દશાંશ સ્થાનો પર યોગ્ય મૂલ્ય દાખલ કરો.`,
    "7ed0ef23d527c2876015161e422f49ce": `કૃપા કરીને કન્નડ ભાષામાં તમારું નામ દાખલ કરો.`,
    "60ae728544ab5cb25dba9ff4b8f6aa95": `સેવા સિંધુ માટે તમારો એપ્લિકેશન સંદર્ભ નંબર છે`,
    "2c0ed1f2f9239dbca46beca549190e00": `આધારને સરનામાંના પુરાવા તરીકે લેવામાં આવશે, કૃપા કરીને આધારની જેમ જિલ્લાની પસંદગી કરો.`,
    "8355a53d777c5ac84a37970b5158aba5": `ભૂલ 205: આધારમાં તમારું નામ આવક વિભાગ દ્વારા જારી કરાયેલ જાતિ અથવા આવકના પ્રમાણપત્રની વિગતો સાથે મેળ ખાતું નથી.`,
    "601f77894e71ae7d4922eda713dda66c": `ભૂલ 207: તમારી જાતિ અને આવકનું પ્રમાણપત્ર સમાપ્ત થઈ ગયું છે.`,
    "9182b3e8dc7965f2775a204095f4c992": `તમારા દ્વારા દાખલ કરેલ OTP ખોટો છે. કૃપા કરીને સાચા OTP સાથે ફરી પ્રયાસ કરો.`,
    "6e0fe1003e84e34da97a6c4c9040b31b": `પછાત વર્ગ કલ્યાણ વિભાગની અરવિ શૈક્ષણિક લોન યોજના (નવીકરણ) માટે અરજી સ્વીકારવાની છેલ્લી તારીખ 31 માર્ચ 2022 સુધી લંબાવી દેવામાં આવી છે.`,
    "69d3a9de2fc1407f59e8936ca0c525a0": `માફ કરશો, એક ભૂલ આવી છે. પછીથી ફરી પ્રયત્ન કરો.`,
    "7953c16d691da03c899391a39412fc9b": `તમારી યોજના એપ્લિકેશન પર તમારી પાસે ક્રિયાઓ બાકી છે. તમારી એપ્લિકેશન સાથે આગળ વધવા માટે <i> {{startDate}} </i> થી બાકી ક્રિયાઓ પૂર્ણ કરો.`,
    "f4f4366332ac6949882de59c81b08de9": `તમારી યોજના એપ્લિકેશન પર તમારી પાસે ક્રિયાઓ બાકી છે. <i> {{startDate}} </i> થી બાકીની ક્રિયાઓ પૂર્ણ કરો <i> {{endDate}} </i> તમારી એપ્લિકેશન સાથે આગળ વધવા માટે.`,
    "77b43c24220ee608380031b72f8017ce":`સેવા શ્રેણીઓ`,
    "e0e54c374f8b39a1520766e5924af100": `તમારા પરિવાર અને તમારા માટે યોજનાઓ, સેવાઓ અને આજીવિકા જાણવા માટેનું પ્લેટફોર્મ`,
    "d56f6b073f573237a6c4aed91589e982": `આજીવિકા`,
    "e5930fadbc620e368c92561f381ead58": `તમારા માટે આજીવિકાની તકો`,
    "192adf39ebd850e3e2e30971e885dc6d": `આ ક્ષણે તમારા રાજ્યમાં આજીવિકાની કોઈ તકો ગોઠવવામાં આવી નથી, અમે સતત અપડેટ કરીએ છીએ, કૃપા કરીને અમારી સાથે ફરી તપાસો`,
    "TM_Alt51": "જોડાણ(ઓ)",
    "TM_Hea1": "કુલ રકમ",
    "TM_Hea2": "સારાંશ",
    "TM_Sub56": "કુલ",
    "TM_But42": "ચૂકવણી કરો",
    "TM_Err34": "ચુકવણી નિષ્ફળતાને કારણે તમારી અરજી સબમિટ કરી શકાઈ નથી.",
    "TM_Rec55": "તમારી ચુકવણીની સ્થિતિની પુષ્ટિ કરવામાં સમય લાગી રહ્યો છે. અમે તમારી બેંક સાથે સતત તપાસ કરી રહ્યા છીએ. કૃપા કરીને આગામી 48 કલાક માટે કોઈપણ વધારાની ચૂકવણી કરવાનું ટાળો.",
    "TM_But13": "પાછા જાવ",
    "TM_Mod41":"ચુકવણી બાકી છે",
    "TM_Mod40":"ચુકવણી સફળ",
    "TM_Alt72":"ચુકવણી નિષ્ફળ",
    "TM_Pag48":"મદદ અને આધાર",
    "TM_Con33":"આભાર, તમારી અરજી સફળતાપૂર્વક સબમિટ કરવામાં આવી છે.",
    "TM_Alt124":"મહેરબાની કરી રાહ જુવો",
    "TM_Rec59": "આ સેવા માટેની તમારી છેલ્લી ચુકવણી બાકી સ્થિતિમાં છે. જ્યારે તે પૂર્ણ થશે ત્યારે અમે તમને સૂચિત કરીશું. આ દરમિયાન, અમારી અન્ય સેવાઓનું અન્વેષણ કરવા માટે નિઃસંકોચ.",
    "TM_Rec60":"ધૈર્ય રાખવા બદલ આભાર.",
    "TM_Rec61":"પાનું આપોઆપ સમય સમાપ્ત થશે",
    "TM_Alt165":"ચુકવણી કરવા માટે તમારા મોબાઇલ ઉપકરણની યુપીઆઈ એપ્લિકેશનમાં ક્યૂઆર કોડ સ્કેન કરો",
    "TM_Pag39":"લભારથી",
    "TM_Alt166":"ચુકવણી નકારી",
    "TM_Err39":"ચુકવણી નકારવાને કારણે તમારી અરજી સબમિટ કરી શકાઈ નથી.",
    "cc4cbfe72c596683881033f36eac3e2f":"અમે તમારી બેંક સાથે તમારી ચુકવણીની પુષ્ટિ કરીએ ત્યાં સુધી કૃપા કરીને રાહ જુઓ. આ પ્રક્રિયામાં થોડો સમય લાગી શકે છે।",
    "7d54c690a3a707e692cceb6978e24bc1": "ફી બ્રેકઅપ",
  }
export default language;