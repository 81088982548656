const initState = {
    data: undefined,
  };

  const printAndFinalSubmit = (state = initState, action) => {
    switch (action.type) {
      case "PRINT_AND_FINAL_SUBMIT":
        return {
          ...state,
          data: action.payload,
        };

      case "RESET_PRINT_AND_FINAL_SUBMIT":
        return {
          ...state,
          data: undefined,
        };

      default:
        return state;
    }
  };

  export default printAndFinalSubmit;
