const initState = {
    data: "",
  };
  
const getExternalProfileVerify = (state = initState, action) => {
    switch (action.type) {
      case "GET_EXTERNAL_PROFILE_VERIFY":
        return {
          ...state,
          data: action.payload,
        };
  
      case "RESET_FIELDS_PROFILE_VERIFY":
        return {
          ...state,
          data: "",
        };
  
      default:
        return state;
    }
  };
  
  export default getExternalProfileVerify;