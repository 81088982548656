const initState = {
    data: "",
  };

  const getServiceOnlineURLReducer = (state = initState, action) => {
    switch (action.type) {
      case "GET_SERVICE_ONLINE_URL":
        return {
          ...state,
          data: action.payload,
        };

      case "RESET_GET_SERVICE_ONLINE_URL":
        return {
          ...state,
          data: "",
        };

      default:
        return state;
    }
  };

  export default getServiceOnlineURLReducer;
