import React, { Suspense } from "react";
import { Router } from "react-router-dom";
import history from "./history";
import storage from "./utility/encryptData";
import keys from './common/keys'
import Routes from "./components/Routes";
import { connect } from "react-redux";
import { getSettings } from "./store/actions/getSettingsAction";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import NoInternet from './components/MOBILE/NoInternet';
import store from "./store/store";
import loader from "./images/loader.svg";
import { del } from "idb-keyval";
import { getProduct } from './store/actions/getProductAction'
import $ from 'jquery'
import RoutesWeb from "./components/RoutesWeb";
import getStaticText from './config'
import './App.scss';
import DisableMobileView from "./components/DisableMobileView";
import url_utility from "./utility/url";
import { deepLinkVisit } from "./store/actions/deepLinkVisitAction";
import handleBrowserName from "./utility/handleGetBrowserName";

const Loader = (
  <div id="overlay">
    <div id="preloader">
      <div id="loader">
      </div>
      <p id="loaderText" className="text-delete my-4" style={{lineHeight:"23px", position: "relative", top: "50%"}}></p>
    </div>
  </div>
);

class App extends React.Component {
  constructor(props) {

    
    super(props);
    this.state = {
      noInternetModal: false,
      openDialog:false,
    }
    this.hitAppOpenCount = false;
    this.events = ['click', 'keypress', 'load'];
    this.logout = this.logout.bind(this);
    this.getProductHit = true
    this.logoutTimeout = null;
    this.resetTimeout = this.resetTimeout.bind(this);
    this.isUrlVisit= true;
    this.apiCalled = false;
    this.closeModalUrls = ['/survey', '/specificSurvey', '/intentSurvey']
    for (var i in this.events) {
      window.addEventListener(this.events[i], this.resetTimeout);
    }

    this.setClockTimeout();
    this.staticText = storage.getItemValue(keys.LANGUAGE_OBJ)
    ? getStaticText(JSON.parse(storage.getItemValue(keys.LANGUAGE_OBJ)).code)
    : getStaticText("kn");
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  handleResize = () => {
    if (window.outerWidth < 600) {
      document.body.style.backgroundColor = 'white';
    } else {
      document.body.style.backgroundColor = ''; // Reset to default value
    }
  };
  clearTimeout() {
    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  }

  setClockTimeout() {
    this.logoutTimeout = setTimeout(
      this.logout,
      window.sessionTimeout * 60 * 1000
    );
  }

  resetTimeout() {
    window.clearTimeout(this.logoutTimeout);
    this.setClockTimeout();
  }

  logout() {
    this.toRemove()
    if (window.outerWidth < 600) {
      history.replace("/main");
    } else {
      history.replace("/");
    }
    this.destroy();
  }

  destroy() {
    this.clearTimeout();

    for (var i in this.events) {
      window.removeEventListener(this.events[i], this.resetTimeout);
    }
  }
  handleDeepLinkVisitApiCall(){
    const schemeObj = JSON.parse(storage.getItemValue(keys.DEEP_LINK_SOURCE));
    const deepLinkData = {
       productId: 12980,
       hostName: window.location.href,
       source: schemeObj.source,
       browser: handleBrowserName() 
    }
    this.props.deepLinkVisit(deepLinkData)
  }

  toRemove() {
    storage.removeItemValue(keys.SERVICE_CATEGORY_INDEX)
    storage.removeItemValue(keys.ACTIVE_INDEX)
    storage.removeItemValue(keys.SERVICE_ACTIVE_INDEX)
    storage.removeCookies(JSON.parse(storage.getItemValue(keys.USERNAME)));
    del(keys.KYC_FORM)
    del(keys.SAVE_KYC)
    del(keys.SAVED_PRODUCT)
    del(keys.APPLICATION_FORM)
    del(keys.DOCUMENT_LIST)
    del(keys.APPLICATION_FILES)
    del(keys.SELF_DECLERATION)
    storage.removeItemValue(keys.LOGIN_ATTEMPTS)
    storage.removeItemValue(keys.SHOW_FEEDBACK)
    storage.removeItemValue(keys.APPLICATION_FORM_ID)
    storage.removeItemValue(keys.TERM_AND_CONDITIONS)
    storage.removeItemValue(keys.SURVEY_TYPE)
    storage.removeItemValue(keys.SAVE_API_CALLED)
    storage.removeItemValue(keys.LOGIN_ATTEMPTS);
    storage.removeItemValue(keys.IS_GUEST_USER);
    storage.removeItemValue(keys.AUTHORIZATION_TOKEN);
    storage.removeItemValue(keys.USERNAME);
    storage.removeItemValue(keys.FAMILY_MEMBERS_LIST);
    storage.removeItemValue(keys.IS_SCHEME_FIRST_VISIT);
    storage.removeItemValue(keys.USER_PROFILE)
    storage.removeItemValue(keys.USE_USER_PROFILE)
    storage.removeItemValue(keys.ACTIVE_PROFILE);
    storage.removeItemValue(keys.IS_SURVEY_COMPLETED)
    storage.removeItemValue(keys.QUALIFIED_SCHEMES)
    storage.removeItemValue(keys.PROFILE_QUALIFIED_SCHEMES)
    storage.removeItemValue(keys.PROACTIVE_QUALIFIED_SCHEMES)
    storage.removeItemValue(keys.PASSED_SCHEME_ID_LIST)
    storage.removeItemValue(keys.NO_QUALIFIED_SCHEMES)
    storage.removeItemValue(keys.SURVEY_RESPONSE)
    storage.removeItemValue(keys.PROFILE_SURVEY_RESPONSE)
    storage.removeItemValue(keys.PRODUCT_DETAILS)
    storage.removeItemValue(keys.PRODUCT_DETAILS_SAVED)
    storage.removeItemValue(keys.LOGIN_WITH_OTP)
    storage.removeItemValue(keys.NEWSIGNIN)
    storage.removeItemValue(keys.IS_VIEW_ALL)
    storage.removeItemValue(keys.IS_VIEW_ALL_SAVE)
    storage.removeItemValue(keys.CURRENT_SOURCE)
    storage.removeItemValue(keys.EXTERNAL_USER_PROFILE)
    storage.removeItemValue(keys.IS_PROFILE_UPDATED)
    storage.removeItemValue(keys.SELECTED_PROFILE_DETAILS)
    storage.removeItemValue(keys.CURRENT_PRODUCT)
    storage.removeItemValue(keys.USER_PROFILE_LIST)
    storage.removeItemValue(keys.PRODUCT_DETAILS_SAVED)
  }

  componentWillMount() {

    window.removeEventListener('resize', this.handleResize);
    if (storage.getItemValue(keys.CURRENT_THEME)) {
      $("body").addClass(storage.getItemValue(keys.CURRENT_THEME))
    }

    // if(storage.getItemValue(keys.LANGUAGE_OBJ) && storage.getItemValue(keys.GEOGRAPHY_ID) && storage.getItemValue(keys.USERNAME)===null){
    //   history.replace("/homeUser")
    // }

    if (storage.getItemValue(keys.LANGUAGE_OBJ) && storage.getItemValue(keys.GEOGRAPHY_ID) && storage.getItemValue(keys.USERNAME) === null && window.outerWidth <= 600 && window.location.pathname!=="/privacyPolicy") {
      history.replace('/main')
    }
    // else if(storage.getItemValue(keys.LANGUAGE_OBJ) && storage.getItemValue(keys.GEOGRAPHY_ID) && storage.getItemValue(keys.USERNAME)===null && !storage.getItemValue(keys.IS_GUEST_USER)&& window.outerWidth >= 600){
    //   history.replace("/homeUser")
    // }
    let params_link = url_utility.params.get_param();
    if(params_link && Object.keys(params_link).length > 0 && params_link.hasOwnProperty("selectedId") && params_link.hasOwnProperty("source")){
      params_link["category"] = params_link?.hasOwnProperty("category") ? params_link.category : "scheme";
      params_link["selectedId"] = Number(params_link.selectedId);
      storage.setItemValue(keys.SURVEY_TYPE,JSON.stringify(params_link))
      let currentProduct = params_link?.productType?.toUpperCase();
      storage.setItemValue(keys.CURRENT_PRODUCT,currentProduct)
      let deepLinkUrl = "/specificSurvey"+window.location.search;
      storage.setItemValue(keys.DEEP_LINK_URL, deepLinkUrl);
    }
    if(params_link && Object.keys(params_link).length > 0 && params_link.hasOwnProperty("source")){
      storage.setItemValue(keys.DEEP_LINK_SOURCE,JSON.stringify(params_link));
      let deepLinkUrl = window.location.href;
      storage.setItemValue(keys.DEEP_LINK_URL, deepLinkUrl);
      this.handleDeepLinkVisitApiCall();
    }
    if(params_link && Object.keys(params_link).length > 0 && params_link.hasOwnProperty("ver")){
      storage.setItemValue(keys.IS_NEW_VERSION,true)
    } else {
      storage.removeItemValue(keys.IS_NEW_VERSION)
    }

    if(params_link && Object.keys(params_link).length > 0 && params_link.hasOwnProperty("category") && params_link.category === "advertisement"){
      storage.setItemValue(keys.SURVEY_TYPE_ADVERTISEMENT,JSON.stringify(params_link));
    }
    this.props.getSettings();
    window.addEventListener('online', this.handleInternetConnection.bind(this));
    window.addEventListener('offline', this.handleNoInternet.bind(this));
    window.addEventListener("keypress", this.resetTimeout.bind(this), false);
    window.addEventListener("click", this.resetTimeout.bind(this), false);
    window.addEventListener("load", this.resetTimeout.bind(this), false);
  }

  handleInternetConnection = () => {
    if (this.closeModalUrls.includes(window.location.pathname)) {
      this.setState({
        noInternetModal: false
      })
    } else {
      history.go(0)
    }
  }

  handleNoInternet = () => {
    store.dispatch({ type: "HANDLE_LOADER", payload: { showLoader: false } });
    this.setState({
      noInternetModal: true
    })
  }

  changethemeIndividual(theme) {
    $("body").addClass("theme-Business")
    storage.setItemValue(keys.CURRENT_THEME, "theme-Business")
    $("body").removeClass("theme-Individual");
    // if(this.props.onThemeChange){
    //   this.props.onThemeChange(theme)
    // }
  }

  changethemeBusiness(theme) {
    $("body").addClass("theme-Individual");
    storage.setItemValue(keys.CURRENT_THEME, "theme-Individual")
    $("body").removeClass("theme-Business");
    // if(this.props.onThemeChange){
    //   this.props.onThemeChange(theme)
    // }
  }

  componentWillReceiveProps({ settings }) {
    
    if (settings) {
      if (!storage.getItemValue(keys.GET_SETTINGS_VERSION)) {
        storage.setItemValue(keys.GET_SETTINGS_VERSION, settings.current_version);
        storage.setItemValue(keys.SETTINGS_DATA, JSON.stringify(settings));
        if (settings.isGtoBEnabled === "true" && settings.isGtoCEnabled === "false") {
            this.changethemeIndividual('In')
            storage.setItemValue(keys.USER_TYPE, "business");
        } else {
            this.changethemeBusiness('Bz')
            storage.setItemValue(keys.USER_TYPE, "individual");
        }
      } else {
        if (storage.getItemValue(keys.GET_SETTINGS_VERSION) !== settings.current_version) {
          this.getProductHit = false
          localStorage.clear();
          storage.setItemValue(keys.GET_SETTINGS_VERSION, settings.current_version)
          storage.setItemValue(keys.SETTINGS_DATA, JSON.stringify(settings));
          if (settings.isGtoBEnabled === "true" && settings.isGtoCEnabled === "false") {
            this.changethemeIndividual('In')
            storage.setItemValue(keys.USER_TYPE, "business");
          } else {
            this.changethemeBusiness('Bz')
            storage.setItemValue(keys.USER_TYPE, "individual");
          }
          if (!this.getProductHit) {
            this.getProductHit = true
            if (storage.getItemValue(keys.GEOGRAPHY_ID)) {
              this.props.getProduct(storage.getItemValue(keys.GEOGRAPHY_ID))
            }
            else {
                if(window.outerWidth <= 600){
                    history.push("/selectLanguage")
                } else {
                    if(window.location.pathname !== "/homeUser"){
                        history.push("/homeUser")
                    } else {
                        window.location.reload()
                    }
                }
            }
          }

        }
      }
    }
    if (!storage.getItemValue(keys.CURRENT_PRODUCT)) {
      storage.setItemValue(keys.CURRENT_PRODUCT, "SCHEME");
    }
    if (!storage.getItemValue(keys.THEME_TYPE)) {
      storage.setItemValue(keys.THEME_TYPE, "theme-light");
    }
    if (!storage.getItemValue(keys.USER_TYPE)) {
      this.changethemeBusiness('Bz')
      storage.setItemValue(keys.USER_TYPE, "individual");
    }

  }

  render() {
    this.staticText = storage.getItemValue(keys.LANGUAGE_OBJ)
    ? getStaticText(JSON.parse(storage.getItemValue(keys.LANGUAGE_OBJ)).code)
    : getStaticText("kn");
    const {openDialog} = this.state;
    return (
      <div>
        <Modal
          size="sm"
          centered
          show={this.props.showDataLoader}
          dialogClassName="modal-100w common_dialog_medium"
          animation={false}
        >
          <Modal.Body className="common_dialog_medium_body text-center pt-4 pb-5 mb-0">
            <img src="/img/icons/configuration.png" style={{height:"70px"}} />
            <p className="text-delete my-4" style={{lineHeight:"23px"}}>{this.staticText["362e532b6ad1fda489c5909d437f02a3"]} </p>
            <div class="slider-move">
              <div class="line"></div>
              <div class="subline inc"></div>
              <div class="subline dec"></div>
            </div>

          </Modal.Body>
        </Modal>


        <Modal
          id="modal"
          onHide={() => { }}
          aria-labelledby="contained-modal-title-vcenter"
          show={this.state.noInternetModal}
          dialogClassName="modal-100w introduction_Screen_dialog"
          animation={false}
        >
          <ModalBody>
            <NoInternet />
          </ModalBody>
        </Modal>
        {this.props.showLoader ? Loader : null}
        {this.props.showLoader ?
            setTimeout(() => {
                if(document.getElementById("loaderText")){
                    if(this.props.showLoader)
                        document.getElementById("loaderText").innerHTML = this.staticText["362e532b6ad1fda489c5909d437f02a3"]
                    else {
                        document.getElementById("loaderText").innerHTML = ""
                    }
                }
            }, 3000) : null
        }
        <Router history={history}>
          <Suspense fallback={Loader}>
            {window.outerWidth < 600 ?
              <DisableMobileView />  : <RoutesWeb />}
          </Suspense>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    settings: state.settings.data,
    showLoader: state.showLoader.data.showLoader,
    showDataLoader: state.showDataLoader.data.showDataLoader,
    deepLinkVisitData: state.deepLinkVisit
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => dispatch(getSettings()),
    getProduct: geographyId => dispatch(getProduct(geographyId)),
    deepLinkVisit: formData => dispatch(deepLinkVisit(formData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
