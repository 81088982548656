const initState = {
    data: undefined,
  };

  const eSignDataAndUrl = (state = initState, action) => {
    switch (action.type) {
      case "EKYC_AADHAAR_AUTHENTICATION":
        return {
          ...state,
          data: action.payload,
        };

      case "RESET_EKYC_AADHAAR_AUTHENTICATION":
        return {
          ...state,
          data: undefined,
        };

      default:
        return state;
    }
  };

  export default eSignDataAndUrl;
