const language = {
    "9e66e6bd4069124578736528a0432752": `ഞങ്ങളേക്കുറിച്ച്`,
    "0f5590b58efe9cfcce35e55a66280e59": `അംഗീകരിക്കുക & തുടരുക`,
    "77ab8893d0d282a9f2781d5711883903": `കൂട്ടിച്ചേര്ക്കുക`,
    "c40eba17959a63e52412cac8c38d467e": `പ്രൊഫൈൽ ചേർക്കുക`,
    "ac714b256ab565c91ade9e03e8c410f0": `നിങ്ങളുടെ എല്ലാ ഡാറ്റയും ഇല്ലാതാക്കി`,
    "b31b443baac1c6da4cb78dfaf2e5be44": `അപ്ലിക്കേഷൻ ലിങ്ക്`,
    "0ec157e0e15e12c56bfe1c72e0e7515a": `മാറ്റങ്ങൾ വരുത്തു`,
    "181c1a0ee6db7d7f835eed29791749ab": `ഈ സ്കീം ഇല്ലാതാക്കാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നുണ്ടോ?`,
    "d937652cde254e2a9b93b74a4ee2a02b": `{{name}} പ്രൊഫൈൽ ശാശ്വതമായി ഇല്ലാതാക്കാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നുണ്ടോ?`,
    "3ac1df709254c0e491c642aae5400814": `{{name}} അക്കൗണ്ട് ശാശ്വതമായി ഇല്ലാതാക്കാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നുണ്ടോ?`,
    "1cbb32715ee74ee3447f48febcc04864": `നിങ്ങളുടെ സംരക്ഷിച്ച സേവനങ്ങളിൽ നിന്ന് ഈ സേവനം ഇല്ലാതാക്കാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നുണ്ടോ?`,
    "ea4788705e6873b424c65e91c2846b19": `റദ്ദാക്കുക`,
    "f4ec5f57bd4d31b803312d873be40da9": `മാറ്റുക`,
    "6dd495f1c2ae29c8e9a6c4405f18e22e": `സ്ഥാനം മാറ്റുക`,
    "b1ad1d3aef325b23950a43cb82fd0670": `ഭാഷ തിരഞ്ഞെടുക്കുക`,
    "714341d9339756588d1dc724991c2b9a": `ഇവിടെ ക്ലിക്ക് ചെയ്യുക`,
    "f72b489f19f748cf78a86c78d547ed91": `നിങ്ങളുടെ സ്ഥാനം സ്ഥിരീകരിക്കുക`,
    "a0bfb8e59e6c13fc8d990781f77694fe": `തുടരുക`,
    "bee04ef1315b3f9562be34e2e28a7831": `അക്കൗണ്ട് ഇല്ലാതാക്കുക`,
    "3e9983cf1885a5ec9f5a5d8127137bd2": `പ്രൊഫൈൽ ഇല്ലാതാക്കുക`,
    "2ac7a924af94bf173f38eb31165efce3": `സ്കീം ഇല്ലാതാക്കുക`,
    "e0bb2f66e5b61be3235bf8969979de19": `ജില്ല`,
    "46a43a7757337712d4f6a3701a5c57dd": `ഫോം ഡൗൺലോഡുചെയ്യുക`,
    "4d7be8ac1b9cb2fd5843dbea7ce30344": `നിങ്ങൾ ഇതുവരെ ഒരു സേവനവും സംരക്ഷിച്ചിട്ടില്ല`,
    "edda2208040fe25b06d0d8f3af976651": `നിങ്ങൾ ഒരു ജോലിയും സംരക്ഷിച്ചിട്ടില്ല`,
    "f283a3e62b2809c07cf77075103c012f": `നിങ്ങൾക്കായി ഏറ്റവും അനുയോജ്യമായ സർക്കാർ ക്ഷേമപദ്ധതികളും സേവനങ്ങളും ജോലികളും കണ്ടെത്തുന്നതിനായി ഞങ്ങൾക്ക് ചില അടിസ്ഥാന പ്രൊഫൈൽ വിവരങ്ങൾ ആവശ്യമാണ്.`,
    // "f283a3e62b2809c07cf77075103c012f":`ഏറ്റവും അനുയോജ്യമായ സർക്കാർ ക്ഷേമപദ്ധതികളെ കണ്ടെത്തുന്നതിന് ലാബർത്തിക്ക് ചില അടിസ്ഥാന പ്രൊഫൈൽ വിവരങ്ങൾ ആവശ്യമാണ്`,
    "ce8ae9da5b7cd6c3df2929543a9af92d": `ഇമെയിൽ`,
    "01e8b0c4e059b594202e976860b2d481": `നിങ്ങളുടെ സ്വന്തം ഭാഷയിൽ പദ്ധതികളും സേവനങ്ങളും പര്യവേക്ഷണം ചെയ്യുക`,
    "483a5da192f46e3cfe8391d3ef234fc4": `പതിവുചോദ്യങ്ങൾ`,
    "37aaf54433bbfe989db8871121fe0ad8": `ഞങ്ങളെ പിന്തുടരുക:`,
    "e0c4332e8c13be976552a059f106354f": `തുടങ്ങി`,
    "48dee7c291ac7f19e499438b072da336": `സർക്കാർ ഫീസ്`,
    "2ceb32552d78024d91a123897636afcb": `EasyGov ന്റെ സിറ്റിസൺ എംപവർമെന്റ് ആപ്പ്`,
    //"357aa540fd5c8ae584ba18359105de3d":`ഏതെങ്കിലും സ്കീമുകളെക്കുറിച്ചോ മറ്റെന്തെങ്കിലും നിങ്ങൾക്ക് എന്തെങ്കിലും ചോദ്യമുണ്ടോ?`,
    "357aa540fd5c8ae584ba18359105de3d": `നിങ്ങൾക്ക് എന്തെങ്കിലും ചോദ്യമുണ്ടെങ്കിൽ ഞങ്ങളെ അറിയിക്കുക, ഞങ്ങൾ 24 പ്രവൃത്തി സമയത്തിനുള്ളിൽ നിങ്ങളെ ബന്ധപ്പെടും.`,
    "b3ba00d60ff5a9e11c15a6efbed13746": `നിങ്ങളുടെ നിലവിലെ പ്രൊഫൈൽ അപ്ഡേറ്റ് ചെയ്യുന്നതിന് "നിലവിലെ പ്രൊഫൈൽ അപ്ഡേറ്റുചെയ്യുക" ക്ലിക്കുചെയ്ത് ഒരു പുതിയ പ്രൊഫൈൽ ചേർക്കുക "" പ്രൊഫൈൽ ചേർക്കുക "തിരഞ്ഞെടുക്കുക`,
    "12ceff2290bb9039beaa8f36d5dec226": `ജോലികൾ`,
    "5eb57a6678ca337f92884f49b578a908": `...`,
    "4994a8ffeba4ac3140beb89e8d41f174": `ഭാഷ`,
    "846eb834b6bfe81dbd412bc010f8cac7": `ഞങ്ങളെ അറിയിക്കുക, ഞങ്ങൾ 24 പ്രവൃത്തി സമയത്തിനുള്ളിൽ നിങ്ങളെ ബന്ധപ്പെടും`,
    "a58252e21aaf876bf7b64c05cce1c477": `ലിങ്ക് പകർത്തി`,
    "ce5bf551379459c1c61d2a204061c455": `സ്ഥാപിക്കല്`,
    "ccffe10fe854018805baf0d3d9327285": `പ്രൊഫൈൽ നിയന്ത്രിക്കുക`,
    "49ee3087348e8d44e1feda1917443987": `പേര്`,
    "10ac3d04253ef7e1ddc73e6091c0cd55": `അടുത്തത്`,
    "bafd7322c6e97d25b6299b5d6fe8920b": `ഇല്ല`,
    "31916d996c9f53949315692bc897f0c7": `പ്രാധാന്യമില്ല? കാണുന്നത് തുടരുക`,
    "e81c4e4f2b7b93b481e13a8553c2ae1b": `അഥവാ`,
    "0d5e50bf1643118ee026e5d19226542a": `ദയവായി അക്ഷരവിന്യാസം പരിശോധിക്കുക അല്ലെങ്കിൽ മറ്റെന്തെങ്കിലും തിരയാൻ ശ്രമിക്കുക`,
    "64ed408b000d73143e6ab1b5acb7c26e": `പ്രൊഫൈൽ തിരഞ്ഞെടുക്കുക`,
    "fa2ead697d9998cbc65c81384e6533d5": `സ്വകാര്യതാനയം`,
    "7595f48afc2f088bc1ff3313ceeb3605": `അപേക്ഷിക്കാനുള്ള നടപടിക്രമം`,
    "cce99c598cfdb9773ab041d54c3d973a": `പ്രൊഫൈൽ`,
    "665d0095958d696322be90e4eb606977": `ഈ ബന്ധമുള്ള പ്രൊഫൈൽ ഇതിനകം നിലവിലുണ്ട്. നിങ്ങൾക്ക് നിലവിലെ പ്രൊഫൈൽ അപ്ഡേറ്റ് ചെയ്യാൻ കഴിയും`,
    "27bcc51421612805a9c006f24a4d6dc6": `സ്കീം നീക്കംചെയ്യുക`,
    "f8b66e3c488bb1df16cb3c7bb21447f0": `ആവശ്യമുള്ള രേഖകൾ`,
    "82609dd1953ccbfbb4e0d20623193b56": `തിരിച്ചു വീട്ടില് വരുക`,
    "c9cc8cce247e49bae79f15173ce97354": `രക്ഷിക്കും`,
    "c320cdeffafbb2d60d6d759619c6b5e2": `പ്രൊഫൈൽ സംരക്ഷിക്കുക`,
    "ab9753c948520fb02c20831980bad53a": `എന്റെ പദ്ധതികൾ`,
    "827452688eed02a12178e96f924ac529": `സ്കീം`,
    "cb80cf588ba5a0d3dc067b0b0ef219e9": `പദ്ധതികൾ`,
    "1925ba54f16355af0964df7dfa145761": `നിങ്ങൾക്കായി സ്കീമുകൾ`,
    "13348442cc6a27032d2b4aa28b75a5d3": `തിരയുക`,
    "32df01b9cf0491a879250b58ba2744ba": `തിരയൽ ഫലങ്ങൾ`,
    // "988cddb5e417156bd3603983dc0c32e5":`തിരയൽ സ്കീമുകൾ`,
    "0afcf4c1ba72c441b41fbafaab253fc6": `സ്ഥാനം തിരഞ്ഞെടുക്കുക`,
    "be2933038f93e25262400220080c111f": `സംസ്ഥാനം തിരഞ്ഞെടുക്കുക`,
    "dfe1d316ec9338e23c892b573667b9bb": `നിങ്ങളുടെ ഓപ്ഷൻ തിരഞ്ഞെടുക്കുക`,
    "c2ba7e785c49050f48da9aacc45c2b85": `സേവനം`,
    "992a0f0542384f1ee5ef51b7cf4ae6c4": `സേവനങ്ങള്`,
    "48ecd536efbb3622296aac8de326fa38": `ക്ഷമിക്കണം, നൽകിയിരിക്കുന്ന വിവരങ്ങളെ അടിസ്ഥാനമാക്കി, നിങ്ങൾക്ക് സ്കീമിന് യോഗ്യതയില്ല`,
    "4cd9a992dc66802a4b44c366881957d1": `ക്ഷമിക്കണം, അതിന്റെ ഫലമൊന്നുമില്ല`,
    "f86301756ec6964cb96e2b80a38f4162": `ക്ഷമിക്കണം! നിങ്ങൾക്കായി പൊരുത്തപ്പെടുന്ന ഒരു സ്കീമുകളും ഞങ്ങൾക്ക് കണ്ടെത്താൻ കഴിഞ്ഞില്ല`,
    "46a2a41cc6e552044816a2d04634545d": `സംസ്ഥാനം`,
    "efd9a0ba1e9d553a070028627fdcae18": `സർവേ വിശദാംശങ്ങൾ`,
    "4cd61af85d0ec40f50349cd7c3054ace": `പ്രൊഫൈൽ മാറ്റുക`,
    "1d8cd2bfc6943dcf70236f7d351572a0": `നിബന്ധനകളും വ്യവസ്ഥകളും`,
    "4f7e556abaf92ce8061ad57cc4e7edd1": `ഇത് ആവശ്യമാണ്`,
    "371999537b8bb6e7f0099b0c40e0e41d": `ഈ പ്രൊഫൈൽ ഇതിനകം സൃഷ്ടിച്ചു. നിങ്ങൾക്ക് പ്രൊഫൈൽ അപ്ഡേറ്റ് ചെയ്യാൻ കഴിയില്ല.`,
    "9eed21a5afd3ba6841c523b0d70599c7": `സ്കീമുകൾ കാണുന്നതിന് ഏതെങ്കിലും വിഭാഗത്തിൽ താഴെയുള്ള ഏതെങ്കിലും വിഭാഗത്തിൽ ക്ലിക്കുചെയ്യുക അല്ലെങ്കിൽ നിർദ്ദിഷ്ട വിഭാഗത്തിനായി ഒരു സർവേ നടത്തുക ക്ലിക്കുചെയ്യുക 2 ൽ കൂടുതൽ വിഭാഗത്തിൽ ക്ലിക്കുചെയ്യുക`,
    "37528894f18a085f9a078496a68eaaa8": `ട്രിപ്പ്`,
    "d876a9fe1aecb3ffc05a5639ebe22000": `വീണ്ടും ശ്രമിക്കുക`,
    "06933067aafd48425d67bcb01bba5cb6": `അപ്ഡേറ്റ് ചെയ്യുക`,
    "fba7f6c863513d97922faab074d3c716": `പ്രൊഫൈൽ അപ്ഡേറ്റ് ചെയ്യുക`,
    "90b1f6651953fcf8b43ddc0da6760aaf": `ഉപയോക്തൃ പ്രൊഫൈൽ`,
    "d8d7ad6abfd08c27baa8bedb78c72ddb": `എല്ലാം കാണുക`,
    "acac138f2e6f039df2e1fed2f2c0bc44": `EasyGov-ലേക്ക് സ്വാഗതം`,
    "6ebb2e31989c843ea65feefa43237dad": `നിങ്ങളുടെ അക്കൗണ്ടിൽ നിന്ന് ഒരു പ്രൊഫൈൽ ഇല്ലാതാക്കുമ്പോൾ, ആ പ്രത്യേക പ്രൊഫൈലിനായി സംഭരിച്ചിരിക്കുന്ന ഏതെങ്കിലും ഡാറ്റയോ വിവരങ്ങളോ വീണ്ടെടുക്കാൻ നിങ്ങൾക്ക് കഴിയില്ല. തിരഞ്ഞെടുത്ത പ്രൊഫൈലിനായുള്ള എല്ലാ സജീവവും സംരക്ഷിച്ചതുമായ സ്കീമുകളും ഇല്ലാതാക്കും`,
    "6a64fbe7b065fc8579f2521e0054de02": `നിങ്ങളുടെ ലാബർത്തി അക്കൗണ്ട് ഇല്ലാതാക്കുമ്പോൾ, നിങ്ങളുടെ പ്രൊഫൈലിലും നിങ്ങളുടെ അക്കൗണ്ടുമായി ബന്ധപ്പെട്ട മറ്റ് പ്രൊഫൈലുകളിലും സംഭരിച്ചിരിക്കുന്ന ഏതെങ്കിലും ഡാറ്റയും വിവരങ്ങളും വീണ്ടെടുക്കാൻ നിങ്ങൾക്ക് കഴിയില്ല. നിങ്ങളുടെ എല്ലാ സജീവവും സംരക്ഷിച്ചതുമായ സ്കീമുകളും ഇല്ലാതാക്കും`,
    "93cba07454f06a4a960172bbd6e2a435": `സമ്മതം`,
    "3aeee34bea95f2b0ced72be632778670": `നിങ്ങൾ ഏതെങ്കിലും സ്കീമുകൾ സംരക്ഷിച്ചിട്ടില്ല`,
    "cb32c835ee043e0e2faf4860e46669ae": `ഈ സ്കീം / സേവനം അവലോകനത്തിലാണ്. കുറച്ച് സമയത്തിന് ശേഷം ദയവായി വീണ്ടും ശ്രമിക്കുക.`,
    "c2e0ede88489afe5739c8973aa03a787": `നിങ്ങളുടെ പ്രൊഫൈലിനുമായി പൊരുത്തപ്പെടുന്ന സ്കീമുകളൊന്നുമില്ല`,
    "28d2dfa9b435bd81cb2f7f0499b846b3": `സ്കീമുകൾ കണ്ടെത്തുക`,
    "52561ab2d4640b4e299e817b6ba79173": `ലാബർത്തിയിലേക്ക് സ്വാഗതം`,
    "fb0fc4b5fdb2bef7b1c42f2cfd523c2d": `ശ്രമിച്ചു`,
    "e13e1cd5f02de37413f9497cc5b65de3": `നിങ്ങളുടെ അക്കൗണ്ട് 30 മിനിറ്റ് ലോക്കുചെയ്തു. ആ സമയത്തിന് ശേഷം ദയവായി ശ്രമിക്കുക`,
    "f2a6c498fb90eebdu72k7f888fce3b18": `സ്വാഗതം`,
    "1172b2ea87bed4861c8c43a201d53031": `എന്റെ സേവനങ്ങൾ`,
    "bcc254b55c4a1babdf1dcb82c207506b": `ഫോൺ`,
    "8458bad978f4fd6d6c7f8ec2ec0824b4": `പാസ്വേഡ് മറന്നോ?`,
    "d67850bd126f070221dcfd5fa6317043": `സൈൻ അപ്പ് ചെയ്യുക`,
    "619d59e2ea9f9bb9fe287404dd3ca931": `എല്ലാ സേവനങ്ങളും`,
    "6b46ae48421828d9973deec5fa9aa0c3": `ഇനം`,
    "104f0a6879399d6285ab770ed0dbc257": `സേവനം നീക്കംചെയ്യുക`,
    "0b3937a9dd481d527fcc72adaa735b57": `ലാബർതി അക്കൗണ്ട് ഇല്ലേ?`,
    "2f453cfe638e57e27bb0c9512436111e": `തുടരുക`,
    "f2fdea440d768b85591e936a803c3631": `സൈൻ ഇൻ`,
    "82b1fbd43ae27918a37b212a4f60a9ad": `കീഴടങ്ങുക`,
    "31693e2e9aa2e5be1bf3ae45ede9c98d": `നിങ്ങൾക്ക് യോഗ്യതയില്ലാത്ത പദ്ധതികൾ`,
    "d43cd6a49673f0f486ed7fbea1a5fd8a": `ആധാർ നമ്പർ`,
    "3a06727e678189b8502408cb9b50b437": `പ്രൊഫൈൽ മാറ്റുക`,
    "0323de4f66a1700e2173e9bcdce02715": `ലോഗൗട്ട്`,
    "644cd55dbe4d01e7e00fc7fc458f9b8f": `നിങ്ങൾ ലോഗൗട്ട് ചെയ്യണമെന്ന് ഉറപ്പാണോ?`,
    "c0fb5959a7a8d3ea991f9edb0b1a9e85": `ഐഡി തിരഞ്ഞെടുക്കുക, ഞങ്ങൾക്ക് നിങ്ങൾക്കായി സ്കീമുകൾ ലഭിക്കും`,
    "827a37fb93758383027c582a39ec6162": `ഐഡി തരം`,
    "cb3441fa3e1291a76dd5ea72e656bca3": `ഐഡി ഇല്ലാതെ തുടരുക`,
    "99dea78007133396a7b8ed70578ac6ae": `ലോഗിൻ`,
    "02aeaf2672eb06861c144004ef2248d2": `ലോഗിൻ അക്കൗണ്ട്`,
    "d4d208eb2d8ccd525a64be73f3521dc5": `അസാധുവായ ക്രെഡൻഷ്യലുകൾ`,
    "e8055d9577ec8edf34ee15a88a937daa": `ഒരു അക്കൗണ്ട് ഇല്ലേ?`,
    "942a3ef743c4e176f75d5223455f7e1a": `നിങ്ങളുടെ 10 അക്ക ഫോൺ നമ്പർ നൽകുക`,
    "21bdc5689c12595ae14298354d5550d5": `ഇതിനകം ഒരു അക്കൗണ്ട് ഉണ്ടോ?`,
    "dc647eb65e6711e155375218212b3964": `രഹസ്യവാക്ക്`,
    "0b3e8b8021c9b2fafee35ae01ac2cfb9": `${window.projectName} അപ്ലിക്കേഷൻ നിങ്ങൾക്കും നിങ്ങളുടെ കുടുംബത്തിനും ഏറ്റവും അനുയോജ്യമായ സർക്കാർ ക്ഷേമപദ്ധതികളെ കണ്ടെത്തുന്നതിന് സഹായിക്കും.`,
    "05aca668fa6754247f5094c042fb64ac": `ഫോൺ പരിശോധന`,
    "f9bc92a6602b9692a0ba578120c9c441": `നിങ്ങളുടെ OTP കോഡ് ഇവിടെ നൽകുക`,
    "d9d1e60e50119d9752001d4196ee6b3c": `OTP`,
    "6e95a24fa2a98c2f59c96a24b182ccfd": `നിങ്ങൾക്ക് ഏതെങ്കിലും കോഡ് ലഭിച്ചില്ലേ?`,
    "f922096c878b694ec7a3d9282a55904c": `പുതിയ കോഡ് വീണ്ടും അയയ്ക്കുക`,
    "32b830cef750168335d96aff4dd98782": `എന്റെ ജോലികൾ`,
    "bbd26bc8ce3623addc9c54c36f692f8d": `നിങ്ങൾക്കുള്ള ജോലികൾ`,
    "67cb88ed21d4232c4681a5e648554e09": `അപേക്ഷ ഫീസ്`,
    "b90455b71eb5e2399be9d20e23c1a1db": `അപേക്ഷിക്കാനുള്ള അവസാന തീയതി`,
    "bfe72f18052c940de13501ae9877c3b0": `അപേക്ഷിക്കേണ്ടവിധം`,
    "4f1f44ef7ce0c8a111f33fe400a6e4d7": `തിരഞ്ഞെടുക്കൽ നടപടിക്രമം`,
    "0f5679acafa674831691cfa01d17501e": `തൊഴിൽ അറിയിപ്പ്`,
    "a45ca50bcc7830af2f84fd046648ec92": `നിങ്ങളുടെ പ്രൊഫൈലിംഗുമായി പൊരുത്തപ്പെടുന്ന ജോലികളില്ല`,
    "c4bcece293eb1ad7f09d4cf9c2037ea6": `ജോലികൾ കണ്ടെത്തുക`,
    "5dc52ca9ffdc26147075682c92a0a853": `കുറ്റിക്കാടുകൾ`,
    "3f99ba6fbcb46f28fb24f271fc3c02c1": `ജോലി കാണുന്നതിന് ഏതെങ്കിലും വിഭാഗത്തിൽ ക്ലിക്കുചെയ്യുക. ഏതെങ്കിലും ജോലിക്കായി ഒരു സർവേ പ്രവർത്തിപ്പിക്കാൻ കാർഡിൽ ക്ലിക്കുചെയ്യുക`,
    "2078989a6c09fdd98efc35d3c1d1de3d": `ജോലി ഇല്ലാതാക്കുക`,
    "08c1517355f6e7313c4d629a193ac69a": `നിങ്ങളുടെ സംരക്ഷിച്ച ജോലികളിൽ നിന്ന് ഈ ജോലി ഇല്ലാതാക്കാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നുണ്ടോ?`,
    "e614dff8cba6f73ae7cee729fe0091dd": `OTP അസാധുവാണ്`,
    "c46c1e69c89811f03dc73feb09273409": `OTP ആവശ്യമാണ്`,
    "ae3bb2a1ac61750150b606298091d38a": `പുതിയ പാസ്വേഡ്`,
    "887f7db126221fe60d18c895d41dc8f6": `പാസ്വേഡ് സ്ഥിരീകരിക്കുക`,
    "70d9be9b139893aa6c69b5e77e614311": `ഉറപ്പിക്കുക`,
    "20aedd1e6de4dcf8d115b5a7424c58d7": `പാസ്വേഡ് ആവശ്യമാണ്.`,
    "841a389ec69697c28df7b9739419d21d": `പാസ്വേഡ് 6 പ്രതീകം ദൈർഘ്യമായിരിക്കണം.`,
    "bcb9407d90fd17ccff50587f5edfe987": `പാസ്വേഡ് പൊരുത്തപ്പെടുന്നില്ല.`,
    "318721657b639c8ca02293211db68837": `ഫോൺ നമ്പർ ആവശ്യമാണ്`,
    "4ea3456b0e1b463f50b81c79230cf070": `അസാധുവായ ഫോൺ നമ്പർ`,
    "bd454fc3d32f4425d146d3e861409871": `ഉപയോക്താവ് ഇതിനകം നിലവിലുണ്ട്`,
    "ab48b978d197e315e92e904e36d26175": `ഫോൺ നമ്പർ അസാധുവാണ്`,
    "70729403b9560d3adb142977608b81d2": `OTP പരിധി കവിഞ്ഞു. 4 മണിക്കൂറിന് ശേഷം ശ്രമിക്കുക.`,
    "1e4dbc7eaa78468a3bc1448a3d68d906": `ഫോൺ നമ്പർ`,
    "4f2df4c330b8df125a3d75f2d69a9b5e": `OTP അയയ്ക്കുക`,
    "9ec54ec336b65da6179cf750de33ecd7": `പാസ്വേഡ് മറന്നോ`,
    "98adc700c19f071f07a714a703a6fc1e": `ഐഡി വിശദാംശങ്ങൾ`,
    "f262fae0a2f17e8c1eb4e9eddb0160e4": `മുന്നോട്ടുനീങ്ങുക`,
    "49fb619c4872c491fae649c28b339ed8": `നിങ്ങളുടെ ക്രെഡൻഷ്യലുകൾ ഞങ്ങളുടെ റെക്കോർഡുകളുമായി പൊരുത്തപ്പെടുന്നില്ല`,
    "58557a71f9811899313234decda72d5a": `നിങ്ങളുടെ അക്കൗണ്ട് 30 മിനിറ്റ് ലോക്കുചെയ്തു. ആ സമയത്തിന് ശേഷം ദയവായി ശ്രമിക്കുക.`,
    "e7665f483813a56e9410787ce2d174ef": `ഫോൺ നമ്പർ ഇതിനകം ഉപയോഗത്തിലാണ്. സൈൻ അപ്പ് ചെയ്യാൻ ദയവായി സൈൻ ഇൻ ചെയ്യുക അല്ലെങ്കിൽ മറ്റൊരു നമ്പർ ഉപയോഗിക്കുക.`,
    "0a46fa5fb9e86e80fc94e229d1967699": `ഫോൺ നമ്പർ രജിസ്റ്റർ ചെയ്തിട്ടില്ല. ദയവായി വീണ്ടും ശ്രമിക്കുക`,
    "bdff8b1cbfb4d5623382d7b0678e707c": `പാസ്വേഡിൽ കുറഞ്ഞത് 8 പ്രതീകങ്ങൾ അടങ്ങിയിരിക്കണം, കുറഞ്ഞത് ഒരു വലിയ കേസ്, ഒരു ചെറിയ കേസ്, ഒരു നമ്പർ, ഒരു പ്രത്യേക പ്രതീകം`,
    "d55085482b3e8da780755832c17d7d60": `അവസാന തീയതി`,
    "988cddb5e417156bd3603983dc0c32e5": `തിരയൽ സ്കീമും സേവനങ്ങളും`,
    "261b39077b81875e115baa22bc6ce04c": `ജോലികൾ കാണുന്നതിന് ഒരു പ്രത്യേക വിഭാഗത്തിൽ പെടുന്നു, ദയവായി വിഭാഗത്തിൽ ക്ലിക്കുചെയ്യുക`,
    "bdb53070a3fc01196ae38b059a4b42ff": `ജോലി നീക്കംചെയ്യുക`,
    "ede1fcad3d6bf808fc948df7d22e1536": `ക്ഷമിക്കണം! നിങ്ങൾക്കായി പൊരുത്തപ്പെടുന്ന ജോലികൾ കണ്ടെത്താൻ ഞങ്ങൾക്ക് കഴിഞ്ഞില്ല`,
    "0c4fa7a0201f434b0ef84494d0aac07a": `ക്ഷമിക്കണം, നൽകിയ വിവരങ്ങളെ അടിസ്ഥാനമാക്കി, നിങ്ങൾക്ക് ജോലിക്ക് യോഗ്യതയില്ല`,
    "e4defbb7cdf529fa3606749a82c06932": `കുറ്റിക്കാടുകൾ`,
    "25d2401d71f4ba1ba7685559c1b77927": `നിങ്ങളുടെ പ്രൊഫൈലിനെ അടിസ്ഥാനമാക്കി, നിങ്ങൾ ഏത് ജോലിക്കും യോഗ്യത നേടിയില്ല. <br> ഞങ്ങൾ തുടർച്ചയായി കൂടുതൽ ജോലികൾ ചേർക്കുന്നു, ദയവായി പിന്നീട് പരിശോധിക്കുക.`,
    "ea7c2400c0ae5f58608cb5051ee852f3": `നിങ്ങളുടെ സംസ്ഥാനത്ത് നിങ്ങളുടെ അവസ്ഥയിൽ കോൺഫിഗർ ചെയ്തിട്ടില്ല, ഞങ്ങൾ തുടർച്ചയായി അപ്ഡേറ്റ് ചെയ്യുന്നു, ദയവായി ഞങ്ങളോട് വീണ്ടും പരിശോധിക്കുക`,
    "24fe48030f7d3097d5882535b04c3fa8": `കാലഹരണപ്പെട്ടു`,
    "9dc0186e2d5d3398ffee6ec964b15dc0": `കാലഹരണപ്പെട്ട ജോലികൾ നിങ്ങളുടെ "എന്റെ ജോലികളിൽ" നീക്കംചെയ്യും 30 ദിവസത്തെ കാലഹരണപ്പെട്ട തീയതി മുതൽ.`,
    "34a934c0c86d866df9d14f8a6e90c637": `യോഗ്യതയില്ലാത്ത കാരണം`,
    "a97ea56b0e00b2379736ae60869ff66a": `ചോദം`,
    "8e9e3212461f14c8dbea6456c920bcbc": `യോഗ്യമായ മൂല്യങ്ങൾ`,
    "c4dce677291fa5bc05276ffe93c5d8f2": `നിങ്ങൾ നൽകിയ മൂല്യം`,
    "9a917c4a4f546c6072bd61ae617763bf": `സ്കീം നിങ്ങൾക്കായിരിക്കരുത്`,
    "e57c13893a290ce882569b5638ba7b82": `നിങ്ങൾ നൽകിയ ഐഡി കണ്ടെത്തിയില്ല. ദയവായി വീണ്ടും ശ്രമിക്കുക.`,
    "e26859a59ede4ab8d8ea0213b68fa732": `ഉറവിട സംവിധാനം നിലവിൽ പ്രതികരിക്കുന്നില്ല, ദയവായി എപ്പോഴെങ്കിലും ശ്രമിക്കുക.`,
    // "e26859a59ede4ab8d8ea0213b68fa732":`ഉറവിട സംവിധാനം നിലവിൽ പ്രതികരിക്കുന്നില്ല, ദയവായി ഐഡി ഇല്ലാതെ തുടരുക അല്ലെങ്കിൽ എപ്പോഴെങ്കിലും ശ്രമിക്കുക.`,
    "d2235c7ecbbbae1bf62833dade7486f5": `പ്രൊഫൈൽ വിശദാംശങ്ങൾ സുവർണ്ണ റെക്കോർഡിൽ നിന്ന് എടുത്തിട്ടുണ്ട്.`,
    "346df6b73ae19a6619f26648cf415522": `ഇറ്റ്ലിഗരബിൾ സ്കീമുകളുടെ പട്ടിക പ്രൊഫൈൽ ഡാറ്റയെ അടിസ്ഥാനമാക്കിയുള്ളതാണ്.`,
    "6e0fe1553e84e34da97a6c4c9040b31b": `ലാബർത്തി`,
    "27559de2125101ccb786807bceb46aa1": `ഇന്ത്യ`,
    "936b6a0effe4990e8f5420a0099fa537": `EasyGov`,
    "8cf04a9734132302f96da8e113e80ce5": `വീട്`,
    "923b39c56654bb6264b6638ba8386b3c": `നിങ്ങളുടെ പ്രൊഫൈൽ സൃഷ്ടിക്കുന്നതിന് സൈൻ ഇൻ ചെയ്യുക / സൈൻ അപ്പ് ചെയ്യുക`,
    "52f2ba99491a1bae953303a4eaf50196": `OTP ഉപയോഗിച്ച് ലോഗിൻ ചെയ്യുക`,
    "18e0c8084d60f55016d67d4e2ca507ce": `SSO ഉപയോഗിച്ച് ലോഗിൻ ചെയ്യുക`,
    "d3d77957f18f1e208cb5b0caa07bec5c": `കൂടുതൽ സ്കീമുകൾ കണ്ടെത്തുക`,
    "6a26f548831e6a8c26bfbbd9f6ec61e0": `സഹായം`,
    "b2119dd0c37bb396e24199d5ffbd0c72": `സ്ക്രീൻ റീഡർ ആക്സസ്`,
    "34b6cd75171affba6957e308dcbd92be": `ഭാഷം`,
    "4221d3e17c6eca2ca6337251a3cf9c4e": `അവസാനമായി പുതുക്കിയത്`,
    "ece2c7eaaac9f27b055e6e482ab776b9": `വിവര തിരയൽ`,
    "ad8f678ccf4a4a34b46f01c74f25b91b": `വെബ്സൈറ്റിനുള്ളിൽ വിവരങ്ങൾ ആക്സസ് ചെയ്യാൻ ഉപയോക്താക്കളെ സഹായിക്കുന്നതിന്, ഒരു "ഇവിടെ തിരയുക" ഓപ്ഷൻ നൽകിയിട്ടുണ്ട്. ഇവിടെ, കന്നഡയിൽ ടൈപ്പുചെയ്യാനുള്ള ഓപ്ഷൻ ലഭ്യമാക്കി. തിരയുമ്പോൾ, യൂണിക്കോഡ് ഫോണ്ടിന്റെ ഉപയോഗം നിർബന്ധമാണ്. ഇംഗ്ലീഷിൽ തിരയാനുള്ള ഓപ്ഷൻ നൽകിയിട്ടുണ്ട്.`,
    "698aa21b21c2252839e5e54a0d2f12ef": `ഇന്റർനെറ്റ് പ്രവേശനക്ഷമത`,
    "4ac2856693f5c8b4fc6cdaf669ed2d74": `ഇന്റർനെറ്റ് മന്ദഗതിയിലാണെങ്കിൽ അല്ലെങ്കിൽ ലഭ്യമല്ലെങ്കിൽ, വെബ്സൈറ്റ് രൂപകൽപ്പനയിൽ ഒരു പരിഷ്ക്കരണം അല്ലെങ്കിൽ ചില ഉപപേജുകൾ തുറക്കില്ല.`,
    "0da22543a45316a826571412b3d5953c": `വെബ്സൈറ്റ് വേൾഡ് വൈഡ് വെബ് കൺസോർഷ്യയം (ഡബ്ല്യു 3 സി) വെബ് ഉള്ളടക്കത്തെ പിന്തുണയ്ക്കുന്നു വെബ് ഉള്ളടക്ക മാർഗ്ഗനിർദ്ദേശങ്ങൾ (ഡബ്ല്യുസിഎജി) 2.0 ലെവൽ AA. സ്ക്രീൻ വായനക്കാർ പോലുള്ള സഹായ സാങ്കേതികവിദ്യകൾ ഉപയോഗിച്ച് വിഷ്വൽ വൈകല്യമുള്ള ആളുകളെ ഇത് പ്രാപ്തമാക്കും. താടിയെല്ല്, എൻവിഡിഎ, സഫ, സൂപ്പർനോവ, വിൻഡോ-കണ്ണുകൾ എന്നിവ പോലുള്ള വ്യത്യസ്ത സ്ക്രീൻ വായനക്കാരുമായി വെബ്സൈറ്റിന്റെ വിവരങ്ങൾ ആക്സസ് ചെയ്യാൻ കഴിയും.`,
    "cb976b928eacd65e6342cfa78bf196da": `വ്യത്യസ്ത സ്ക്രീൻ വായനക്കാരെക്കുറിച്ചുള്ള വിവരങ്ങൾ ഇനിപ്പറയുന്ന പട്ടിക പട്ടികപ്പെടുത്തുന്നു`,
    "786f669131d80fbed4572a520c28c14d": `EasyGov സൃഷ്ടിച്ചതും ഉടമസ്ഥതയിലുള്ളതുമായ ഉള്ളടക്കം`,
    "fc290a7672f96fd8b94a9659c8c02322": `| എല്ലാ അവകാശങ്ങളും നിക്ഷിപ്തം`,
    "6016a2b341113bf496b719905398ecd2": `പകർപ്പവകാശം`,
    "0e8a85115f2c3ef0058c70f30a9b6deb": `നിങ്ങളുടെ രജിസ്റ്റർ ചെയ്ത മൊബൈൽ നമ്പറിലേക്ക് അയയ്ക്കുക OTP നൽകുക`,
    "34f8ccda5172e4eac9a8a014559b38c1": `നിങ്ങളുടെ പാസ്വേഡ് സജ്ജമാക്കുക`,
    "548899be111ad732b37786783dfbd6d8": `ഇന്റർനെറ്റ് കണക്ഷൻ ഇല്ല`,
    "5c3a1593ca1d34226359df44f3d45f2f": `കുറച്ചുനേരം പരിശോധിക്കുക.`,
    '35043eeac5651298d4bb6c100e071745': `നിങ്ങളെ മൂന്നാം കക്ഷി വെബ്സൈറ്റിലേക്ക് റീഡയറക്ടുചെയ്യും. നിങ്ങൾ തുടരാൻ ആഗ്രഹിക്കുന്നുവോ?`,
    "6182e08f3974a70ac4883b5fceaa14c0": `നിങ്ങളുടെ രജിസ്റ്റർ ചെയ്ത മൊബൈൽ നമ്പറിലേക്ക് അയയ്ക്കുക OTP നൽകുക`,
    "306fb6389c3b3fd2835af15cfc7c66e9": `ഫാമിലി ഐഡി മാറ്റുക`,
    "63fc7651ee1dbf6868a43a5b5fa8b581": `പ്രതീകങ്ങൾ മാത്രമേ അനുവദിക്കൂ`,
    "6903fbe8375d52217c15618170f4078a": `30 പ്രതീകങ്ങൾ മാത്രമേ അനുവദിക്കൂ`,
    "9ab37820a722ce13e80a3d0aa468d6dc": `* ആവശ്യമാണ്`,
    "0df1f18a2fa23cd60990e47692f99e07": `അപ്ലിക്കേഷൻ ലിങ്ക്`,
    "a60852f204ed8028c1c58808b746d115": `ശരി`,
    "edbd5921e9c7168e04cbe6f75ee76174": `നിങ്ങളുടെ പ്രൊഫൈലിനെ അടിസ്ഥാനമാക്കി, നിങ്ങൾ ഏതെങ്കിലും സ്കീമിന് യോഗ്യത നേടിയില്ല. ഞങ്ങൾ തുടർച്ചയായി കൂടുതൽ സ്കീമുകൾ ചേർക്കുന്നു, ദയവായി പിന്നീട് പരിശോധിക്കുക.`,
    "a203c39a340e1d45069b1ba64e1d003a": `ശ്രദ്ധ!`,
    "037673ab5a84897e0ee43ca37f7680f6": `നിങ്ങളുടെ ഡാറ്റ മാറ്റുകയാണെങ്കിൽ നിങ്ങളുടെ പ്രൊഫൈൽ ഡാറ്റയിലും അത് അപ്ഡേറ്റ് ചെയ്യും. നിങ്ങൾ തുടരാൻ ആഗ്രഹിക്കുന്നുവോ?`,
    "d1d16cede248b826a431ea11132567cb": `നിങ്ങൾ പരിശോധിച്ച ഡാറ്റ മാറ്റുകയാണെങ്കിൽ, അത് വീണ്ടും പരിശോധിക്കുകയും നിങ്ങളുടെ നില മാറുകയും ചെയ്യും. ഇത് നിങ്ങളുടെ അപ്ലിക്കേഷന്റെ നിരസിക്കുന്നതിനോ പ്രോസസ്സിംഗ് സമയം വർദ്ധിപ്പിക്കുന്നതിനോ കാരണമായേക്കാം.`,
    "23a0ca84a7fd64eee8248bd1dfc77185": `പേര് മാറ്റുക`,
    "3b383caaaa6113dd83b381b43a97d7ee": `ഒരു പ്രൊഫൈൽ ഇതിനകം തന്നെ ഈ ഉപയോക്താവിനായി നിലവിലുണ്ട്, ഒരു പുതിയ കുടുംബ ഐഡി ചേർക്കുന്നത് മുമ്പത്തെ വിശദാംശങ്ങൾ നീക്കംചെയ്യും. ദയവായി ഉറപ്പിക്കു`,
    "e0efbdcea0abe735dbf887292a5670fa": `സഹോദരൻ`,
    "ad6e7652b1bdfb38783486c2c3d5e806": `അതായ`,
    "19cda37369e2d2015e66b9d478ffbe15": `ചേച്ചി`,
    "81e053d78f01540e9cd6fb68a1c03976": `മറ്റാരോ`,
    "b8e18040dc07eead8e6741733653a740": `അമ്മ`,
    "a30ca91be97a9cedc0d2426e54b0e782": `പിതാവ്`,
    "784c01f6b735be57175e2f438297c741": `ഭാര`,
    "1968ab9fdfedf558714bf6caec9a1234": `പുതന്`,
    "7bc1cb93f51f61bde0e90b20c81ebf88": `മകള്`,
    "25f34d5943da3f09a2aa6358193a41bd": `അപേക്ഷിക്കാൻ തുടരുക`,
    "79dff508ab2e4d272f0630d001271eb1": `കൂടുതൽ ജോലികൾ പര്യവേക്ഷണം ചെയ്യുക`,
    "b6ebe1e750185946ed41be9191410612": `ലാബർത്തിയിലൂടെ അപേക്ഷിക്കാൻ നിങ്ങൾ വിശദാംശങ്ങൾ നൽകേണ്ടതുണ്ട്.`,
    "af1899084d8361b9afb9187da988ff9c": `"ക്ഷമിക്കണം, ഞങ്ങൾക്ക് നിങ്ങളുടെ ഡാറ്റ ലഭ്യമാക്കാനായില്ല".`,
    "1b56218ddf0a290241d661d26253d51f": `{{attempts}} {ശ്രമം (കൾ) ശേഷിക്കുന്നു`,
    "f299cff2484fdb361f4fc3189490d909": `നിങ്ങൾ നൽകിയ ഇനിപ്പറയുന്ന വിവരങ്ങൾ നിങ്ങളുടെ സർക്കാർ റീകോസറുമായി പൊരുത്തപ്പെടുന്നില്ല:`,
    "1edf5362e5f4af6f370a7f34c3136a7a": `സ്ഥിരീകരണം പരാജയപ്പെട്ടു`,
    "dcf9ac5128c95d88e068e223dfe01d27": `സ്ഥിരീകരണ നില`,
    "bc910f8bdf70f29374f496f05be0330c": `പേരിന്റെ ആദ്യഭാഗം`,
    "77587239bf4c54ea493c7033e1dbf636": `പേരിന്റെ അവസാന ഭാഗം`,
    "14fcbe1dd0f7a39f0195aba46cf89d17": `വിശദാംശങ്ങൾ പരിശോധിക്കുക`,
    "b6121db5ee47deeb0ca42d5dea0b2225": `ശ്രമങ്ങൾ പരാജയപ്പെട്ടു`,
    "839301cec5574d7c3256afce4fb85467": `നിങ്ങളുടെ കുടുംബ വിശദാംശങ്ങൾ സ്ഥിരീകരിക്കുന്നതിന് നിങ്ങൾ സാധുവായ എണ്ണം ശ്രമങ്ങൾ കവിഞ്ഞു. നിങ്ങളുടെ സ്ഥിരീകരണം തടഞ്ഞു`,
    "6733aa56fd001b9bf479650f3950688a": `നിങ്ങൾ സ്കീം ആപ്ലിക്കേഷൻ പേജിലേക്ക് നയിക്കപ്പെടുന്നു`,
    "9303c9bd4f8178680dc382adbfcd62af": `ഈ ഫീൽഡ് പൂരിപ്പിക്കേണ്ടതുണ്ട്.`,
    "53a04cfc3d09c9b2dfb8349791c62bc1": `നിങ്ങളുടെ പേര് ഈ കുടുംബത്തിലെ ഏതെങ്കിലും അംഗവുമായി പൊരുത്തപ്പെടുന്നില്ല`,
    "1ca35256fcb85c1ba9ea7ac1095462ad": `നിങ്ങളുടെ കുടുംബ വിശദാംശങ്ങൾ സ്ഥിരീകരിക്കുന്നതിന് നിങ്ങൾ സാധുവായ എണ്ണം ശ്രമങ്ങൾ കവിഞ്ഞു. നിങ്ങളുടെ സ്ഥിരീകരണം 3 മണിക്കൂർ തടഞ്ഞു`,
    "0e3a917198575c463bb4fdcb7332b03a": `അസാധുവായ ആധാർ കാർഡ് നമ്പർ`,
    "630058029627ce58e7571e7a5b6ec591": `പൂർണ്ണമായ പേര്`,
    "072355e896ac1a999d2fa0aa208f94ee": `ഈ സ്കീം നിങ്ങളുടെ "എന്റെ സ്കീമുകളിൽ" സംരക്ഷിച്ചു.`,
    "789189638f8a301304dfadeb61036dd9": `ഈ സേവനം നിങ്ങളുടെ "എന്റെ സേവനങ്ങളിൽ" സംരക്ഷിച്ചു.`,
    "ab8023b4fc2395738b8fd2df440641a9": `ഈ ജോലി നിങ്ങളുടെ "എന്റെ ജോലികളിൽ" സംരക്ഷിച്ചു.`,
    "671dec8fc4e3dc847c1b0d7b67b4e6e1": `ബന്ധു`,
    "f252b4e2f3b2963d330c8cde8e9cdbd9": `ലഭ്യമാണെങ്കിൽ`,
    "ef51899b57258db72f15b4703a8a86ba": `പാൻ ഇന്ത്യ സർക്കാർ വെൽഫെയർ സ്കീമുകൾ, സേവനങ്ങൾ, ജോലികൾ എന്നിവയ്ക്കായി പൗരന്മാരുടെ ആക്ഷൻബിൾ വിവരങ്ങൾ ആക്സസ് ചെയ്യുന്നതിന് പൗരന്മാർക്ക് ഒരൊറ്റ പ്ലാറ്റ്ഫോം.`,
    //New Translations for Phase 1:`#VALUE!`,
    "7510e00267e89c78470e79b42ecd6463": `നിങ്ങളുടെ സ്വന്തം ഭാഷയിൽ അപ്ലിക്കേഷൻ പര്യവേക്ഷണം ചെയ്യുക`,
    "83218ac34c1834c26781fe4bde918ee4": `സ്വാഗതം`,
    "1d00e7dce692e8dc3f6877f035e3a616": `അഥവാ`,
    "e0cf58fa3413df99ae83109ebb9a41a3": `OTP വീണ്ടും അയയ്ക്കുക`,
    "6ed83b24865902c6fd90bbd15247e042": `പാസ്വേഡ് സജ്ജമാക്കുക`,
    "9157806ea93e82c6843e29353af0339f": `OTP അഭ്യർത്ഥിക്കുക`,
    "69f18e98d2dd54118ade84732dc3a5e0": `പിൻ പരിശോധിക്കുക`,
    "2dd4472245a696bc0b4b944db2a8b519": `വ്യക്തി`,
    "a274f4d4670213a9045ce258c6c56b80": `അറിയിപ്പുകൾ`,
    "407456e575dee5a4b84a19a7111a4a4d": `2 അക്കങ്ങളുള്ള ഏതെങ്കിലും സ്കീം വിഭാഗത്തിൽ നിങ്ങൾ ക്ലിക്കുചെയ്യുമ്പോൾ, നിങ്ങൾക്ക് 2 അക്കങ്ങളുള്ള ഏതെങ്കിലും സ്കീം വിഭാഗത്തിൽ ക്ലിക്കുചെയ്യുമ്പോൾ നിങ്ങൾക്ക് യോഗ്യത ലഭിക്കാൻ നിങ്ങൾക്ക് കഴിയും, തുടർന്ന് നിങ്ങൾ കൂടുതൽ നൽകാൻ തിരഞ്ഞെടുത്ത വിഭാഗത്തിനായുള്ള സ്കീമുകളുടെ പരിഷ്കൃത പട്ടിക.`,
    "9139eb8e78808acbd15244d4eb9f1bc3": `കൂടുതൽ സ്കീമുകൾ പര്യവേക്ഷണം ചെയ്യുക`,
    "c61dd5f0e5b5389306ad5221ec2cd795": `ഈ സ്കീം നിങ്ങളുടെ "എന്റെ സ്കീമുകളിൽ" സംരക്ഷിച്ചു`,
    "f2a6c498fb90ee345d997f888fce3b18": `ഇല്ലാതാക്കുക`,
    "469bad4859f3c33fcb8ce09314c3e895": `സർവേ വിശദാംശങ്ങൾ അവലോകനം ചെയ്യുക`,
    "7dce122004969d56ae2e0245cb754d35": `തിരുത്തുക`,
    "c5984b3fe02f7b06d77fb538d842b0fc": `സർവേ വിശദാംശങ്ങൾ അപ്ഡേറ്റുചെയ്യുക`,
    "6cf33ce1a7a11a7178131567c777b637": `ഒരേ പേരിലുള്ള പ്രൊഫൈൽ ഇതിനകം നിലവിലുണ്ട്`,
    "fffcb4d1e12c96b2f571f77ad25878b8": `കാരണം`,
    "e8d89b6875d217a990d47701138e687a": `ഈ ഡാറ്റ ഇല്ലാതാക്കാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നുണ്ടോ?`,
    "aa98db08fed41a99a00aac36f521540f": `അവസാനം പരിശോധിച്ചു`,
    "5da618e8e4b89c66fe86e32cdafde142": `മുതല്`,
    "253ec560db0637f6a0e53aefcbd4be0c": `നിങ്ങൾ ലാബർതി അക്കൗണ്ട് ഇല്ലാതാക്കുമ്പോൾ, നിങ്ങളുടെ പ്രൊഫൈലിൽ സംരക്ഷിച്ച ഏതെങ്കിലും ഡാറ്റയോ വിവരങ്ങളോ വീണ്ടെടുക്കാൻ നിങ്ങൾക്ക് കഴിയില്ല. സംരക്ഷിച്ച എല്ലാ സ്കീമുകളും വിവരങ്ങളും {{name}} സംഭരിച്ച വിവരങ്ങളും ഇല്ലാതാക്കും.`,
    "c0e70d5bfc28ae3aadfb8f85697c54d5": `നിങ്ങളുടെ ഡാറ്റ വിജയകരമായി അപ്ഡേറ്റുചെയ്തു.`,
    "09b73aba9204a05ba5eaf0ea471fdc8c": `സുരാജ്യ സർവീസ് പ്രൈവറ്റ് ലിമിറ്റഡിന്റെ രജിസ്റ്റർ ചെയ്ത വ്യാപാരമുദ്രയാണ് EasyGov. EasyGov-ന്റെ പ്ലാറ്റ്‌ഫോം-എ-സേവന മാതൃക ഗവൺമെന്റ് ക്ഷേമത്തെ യാഥാസ്ഥിതികവും പ്രതിക്രിയാത്മകവും പ്രോഗ്രാം കേന്ദ്രീകൃതവും എന്നതിൽ നിന്ന് ഘട്ടം ഘട്ടമായി പുരോഗമനപരവും സജീവവും കുടുംബ കേന്ദ്രീകൃതവുമായി മാറ്റുന്നു. പൗരന്മാരുടെ പ്രയോജനങ്ങൾക്കായി വെൽഫെയർ സിസ്റ്റത്തിന്റെ ഈ സങ്കീർണ്ണമായ യാത്ര പരിഹരിക്കാൻ EasyGov വിവിധ സർക്കാരുകളെ സഹായിക്കുന്നു. കൂടുതൽ വിവരങ്ങൾക്ക് ദയവായി <a href='https://easygov.co.in/' target='_blank'> https://easygov.co.in/</a> സന്ദർശിക്കുക`,
    "8f241c62a9523b05bf0b6b16a09d856d": `ഭാഷ മാറ്റുക`,
    "1ccbe6084301f9b1e18e3e7caf723ae1": `നിങ്ങൾ ലോഗൗട്ട് ചെയ്യാൻ ആഗ്രഹിക്കുന്നുണ്ടോ?`,
    "32ca0a0f69103bad1e6f134d5a8e36d7": `ഒരു പ്രത്യേക വിഭാഗത്തിനായുള്ള ജോലികൾ കാണുന്നതിന്, ദയവായി ഏതെങ്കിലും തൊഴിൽ വിഭാഗത്തിൽ ക്ലിക്കുചെയ്യുക`,
    "d6e6cb19e3b9c02f89d6cd54cfa7c613": `വാപാരം`,
    "b1c94ca2fbc3e78fc30069c8d0f01680": `എല്ലാം`,
    "1dd1c5fb7f25cd41b291d43a89e3aefd": `ഇന്നേദിവസം`,
    "ebfe9ce86e6e9fb953aa7a25b59c1956": `തലേനാള്`,
    "4b2ef3655b5d6d48f1fa41892d0043b9": `അറിയിപ്പുകളൊന്നുമില്ല`,
    "094f537cfb139894f1412200c78e87b1": `തിരഞ്ഞെടുത്ത അറിയിപ്പുകൾ ഇല്ലാതാക്കാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നുണ്ടോ?`,
    "4201c11dc26b42deb90cb119c0b12fdb": `അറിയിപ്പ് ഡൗൺലോഡുചെയ്യുക`,
    "ae45c43efe0011f67ec52003c0d8f916": `<b> ഹിന്റ് </ b>: 'പാസ്വേഡിൽ' കുറഞ്ഞത് 8 <br> പ്രതീകങ്ങളെങ്കിലും അടങ്ങിയിരിക്കണം, കുറഞ്ഞത് ഒരു വലിയ കേസ്, ഒരു ചെറിയ കേസ്, ഒരു ചെറിയ കേസ്, ഒരു പ്രത്യേക പ്രതീകം.`,
    "248336101b461380a4b2391a7625493d": `സംരക്ഷിച്ചു`,
    "f03ab16cd58372c77ba45a3d9a5a1cb9": `കരട്`,
    "a4d158b65f20725f9fe9c1763e9805c8": `സ്വകാര്യ സ്പോൺസർ`,
    "7fe12d923db43febcc5d8ea18df632ca": `നിങ്ങളുടെ കെവൈസി പ്രമാണം തിരഞ്ഞെടുക്കുക`,
    "d8ce714c8a76133a8d5746e3bfdd7ef7": `കെവൈസി`,
    "4f67fe16b274bf31a67539fbedb8f8d3": `പ്രമാണ തരം`,
    "5127fa87ed375848481bfe9c786d8022": `നിങ്ങളുടെ കെവൈസി പൂരിപ്പിക്കുക`,
    "8f75e3041034138aba310ba3f097ed24": `പ്രമാണ നമ്പർ`,
    "9abfb506bebd1e72069f0be0014986dc": `പ്രമാണ നമ്പർ അസാധുവാണ്`,
    "ecfffd1bad9944f6a50e369f63268cff": `ക്യുആർ സ്കാൻ`,
    "c541f95d69a4a507b87b32d05dd6d73c": `ഫ്രെയിമിനുള്ളിൽ കോഡ് സ്ഥാപിക്കുക`,
    "3876661cb83fa41284f828bd8d7fbb7b": `സ്ഥിരീകരണത്തിനായി അപ്ലോഡ് ചെയ്യേണ്ടതും കെവൈസി പ്രക്രിയ പൂർത്തിയാക്കുന്നതുമായ കെവൈസി രേഖകൾ.`,
    "cc1d6f3625c4eb671d76b6ebf3f6f544": `നിങ്ങളുടെ ഉപഭോക്താവിനെ അറിയുക`,
    "08ac502bbb7652c683c1da97c7fdeb94": `{{xxxx}} നിങ്ങളുടെ മൊബൈൽ നമ്പറിലേക്ക് ഒരു OTP അയച്ചു`,
    "d19a88ee579190614084c2ea84379ac7": `OTP (1:00) വീണ്ടും അയയ്ക്കുക`,
    "7397e92003e187474e5eab39b27fcb6b": `ക്ഷമിക്കണം, ഉറവിട സിസ്റ്റങ്ങളിൽ നിന്ന് നിങ്ങളുടെ ഡാറ്റ ലഭ്യമാക്കാനായില്ല. പിന്നീട് വീണ്ടും ശ്രമിക്കുക.`,
    "a943d4ac1dd2e0048bcbf85225bf2811": `OTP സൃഷ്ടിക്കുന്നതിനുള്ള പരമാവധി പരിധി കവിഞ്ഞു, 3 മണിക്കൂറിന് ശേഷം വീണ്ടും ശ്രമിക്കുക`,
    "8dac090b39cf9b3bff9a9503531d04cf": `നിങ്ങളുടെ അക്കൗണ്ട് നിലവിൽ തടഞ്ഞു, ദയവായി കുറച്ച് സമയത്തിന് ശേഷം ശ്രമിക്കുക.`,
    "08798caaee5cc8ea1282883901c8810b": `ശേഷിക്കുന്ന ശ്രമങ്ങൾ - {{Number}} മുന്നറിയിപ്പ് - തുടർച്ചയായ 4 ശ്രമങ്ങൾക്ക് ശേഷം, നിങ്ങളുടെ അക്കൗണ്ട് ലോക്കുചെയ്യും.`,
    "33018956b3ffe20d413e3c6780f19aff": `കെവൈസി വിശദാംശങ്ങൾ സ്ഥിരീകരിക്കുക`,
    "90f35a35415ca3b4c623d7c515fce1c0": `ശ്രദ്ധ! നിങ്ങളുടെ വിശദാംശങ്ങൾ അപ്ഡേറ്റുചെയ്യുകയോ മാറ്റുകയോ ചെയ്താൽ നിങ്ങളുടെ ഡാറ്റ സ്ഥിരീകരിക്കുകയും അപ്ഡേറ്റുചെയ്ത ഡാറ്റയ്ക്കായി പ്രമാണ പ്രൂഫ് അപ്ലോഡ് ചെയ്യാൻ നിങ്ങളോട് ആവശ്യപ്പെടുകയും ചെയ്യും. തുടരാൻ "തുടരുക" ക്ലിക്കുചെയ്യുക`,
    "aee68a98fd803f091082faf867c0f62b": `കെവൈസി ഫോം പൂരിപ്പിക്കുക`,
    "2e853d869dd0c3cb0a64e4938ee90647": `പിൻ കോഡ് അസാധുവാണ്`,
    "8f3124e142a4000fe04a581f4f41c8da": `അസാധുവായ {{labour card}} നമ്പർ`,
    "dbb362212f1b8118f17633521d1e72f7": `നിങ്ങളുടെ അപ്ലിക്കേഷൻ നിങ്ങളുടെ 'ഡ്രാഫ്റ്റ് സ്കീമിൽ' വിജയകരമായി സംരക്ഷിച്ചു.`,
    "5c93310dd0291e121181e830cdda892e": `ചിതമണ്ഡപം`,
    "967d35e40f3f95b1f538bd248640bf3b": `കാമറ`,
    "37bed5f24fa3752c7830c021501539a0": `ഫോട്ടോ നീക്കംചെയ്യാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നുണ്ടോ?`,
    "1a263d755f169c2499c1a55f7a465b93": `നിങ്ങളുടെ പ്രമാണ ഐഡി നിലവിൽ തടഞ്ഞു, ദയവായി കുറച്ച് സമയത്തിന് ശേഷം ശ്രമിക്കുക.`,
    "689707127ddb398dc4b447e1dffa3a47": `നിങ്ങളുടെ വിശദാംശങ്ങൾ സ്ഥിരീകരിക്കുന്നതിന് നിങ്ങൾ സാധുവായ എണ്ണം ശ്രമങ്ങൾ കവിഞ്ഞു. നിങ്ങളുടെ സ്ഥിരീകരണം 3 മണിക്കൂർ തടഞ്ഞു.`,
    "04c850a81052df23680d00d2aa1d891e": `കെവൈസി നില`,
    "a441e519087df0d7c8572b9fafa14ae2": `നിങ്ങൾ നൽകിയ ഇനിപ്പറയുന്ന വിവരങ്ങൾ നിങ്ങളുടെ സർക്കാർ രേഖയുമായി പൊരുത്തപ്പെടുന്നില്ല .1. നിങ്ങളുടെ പേര് റെക്കോർഡുമായി പൊരുത്തപ്പെടുന്നില്ല .2. നിങ്ങളുടെ പ്രായം റെക്കോർഡുമായി പൊരുത്തപ്പെടുന്നില്ല.`,
    "75514ce9dac260a1f7abd0d85ce6af46": `നിങ്ങൾ നൽകിയ വിശദാംശങ്ങൾ റെക്കോർഡുകളുമായി പൊരുത്തപ്പെടുന്നില്ല`,
    "5955e277da6fb75982b0f651f4ae8b92": `ഉറവിട സിസ്റ്റങ്ങളിൽ നിന്ന് നിങ്ങളുടെ ഡാറ്റ ലഭ്യമാക്കാൻ ഞങ്ങൾക്ക് കഴിയില്ല. പിന്നീട് വീണ്ടും ശ്രമിക്കുക.`,
    "be60ac326400cc5ef0d173a833c4a40b": `നിങ്ങളുടെ വിശദാംശങ്ങൾ പരിശോധിച്ചുറപ്പിക്കുന്നു`,
    "d1083e84a9a7283ffb081263e8533509": `നിങ്ങളുടെ ആപ്ലിക്കേഷൻ നിങ്ങളുടെ 'ഡ്രാഫ്റ്റ് സ്കീമിൽ' വിജയകരമായി സംരക്ഷിച്ചു`,
    "0bad551abed71c0a41ca8df804afec86": `പ്രമാണം ഉപയോഗിച്ച് കെ.വൈക്`,
    "e6387f3e684958515af2947d8cdf01d7": `കീസി ഫോം അപ്ഡേറ്റുചെയ്യുക`,
    "9c7ed3d8ac60281e90519b4ebf07a9a2": `വിലാസം തിരഞ്ഞെടുക്കുക`,
    "31e41095bfaa14799239e8d9ba7ad438": `അപ്ലിക്കേഷൻ വിശദാംശങ്ങൾ`,
    "e1e4c8c9ccd9fc39c391da4bcd093fb2": `ഉപരോധിക്കുക`,
    "b9eca8f59016e8ff69a2c76fa869d23f": `ലേബർ കാർഡ് അപ്ലോഡുചെയ്യുക`,
    "6cf24d7a07132fe88b610e2b3d3def28": `സ്വയം പ്രഖ്യാപനം`,
    "827b05360cd22ccbaf3d085aded71570": `വിലാസത്തിന്റെ തെളിവ്`,
    "f734cd7792862bebbbb87ddc54285376": `തിരിച്ചറിയൽ രേഖ`,
    "5a8c686dfdba564a2801806f3827c124": `ചിത്രം JPG അല്ലെങ്കിൽ PNG ഫോർമാറ്റിലായിരിക്കണം. PDF ഫയൽ 1MB-യിൽ കുറവായിരിക്കണം.`,
    "d495cdb8315e12e3dc5fd6121ed69cbc": `IFSC കോഡ് അസാധുവാണ്`,
    "cd9f177eac6411501839f7868e38767b": `ഈ പ്രമാണം ഇല്ലാതാക്കാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നുണ്ടോ?`,
    "565d2daf0ccaf00d354669e7551b81dd": `പ്രൊഫൈൽ വിശദാംശങ്ങൾ അവലോകനം ചെയ്യുക`,
    "9895dd168483d1d34ab9ebfb2b6760be": `ബാങ്ക് തിരഞ്ഞെടുക്കുക`,
    "deb5c3f30f3a8381367392d6608fbe68": `അപ്ലിക്കേഷൻ നില`,
    "970324882e5bbfd2deef9e5b7e2d1160": `അപ്ലിക്കേഷൻ ഡൗൺലോഡുചെയ്യുക`,
    "03b55d8ed61b625c209cc42ea0a35b8c": `നന്ദി!`,
    "8276f0b86b5ee98d03f2eafba3590bb0": `നിങ്ങളെ സേവിക്കാൻ അവസരം നൽകുന്നതിന്. നിങ്ങളുടെ അപ്ലിക്കേഷൻ വിജയകരമായി സമർപ്പിച്ചു.`,
    "ac3122aeddff176ae67d3df2b2198d19": `നന്ദി! നിങ്ങളുടെ അപേക്ഷ സ്ഥിരീകരണത്തിനായി സമർപ്പിച്ചു, "അപ്ലൈഡ് ആപ്ലിക്കേഷനുകൾ" പ്രകാരം അപ്ലിക്കേഷനിൽ നിന്ന് നിങ്ങളുടെ അപ്ലിക്കേഷൻ നില പരിശോധിക്കുക`,
    "73da163444abaee6a5c88532b8eda629": `നിങ്ങളുടെ അനുഭവം എങ്ങനെയായിരുന്നു?`,
    "74eb855e4de6fe58228f03006c02fd8a": `ശരി`,
    "d36ed38206da38c85af59039d18f1108": `<b> നിങ്ങളുടെ ഫീഡ്ബാക്ക് വിവരിക്കുക </ b> (ഓപ്ഷണൽ)`,
    "446e92f15cd245125ccd983ef968e692": `വേണ്ട, നന്ദി!`,
    "634a1a1ed07518476cb7a4beab87185a": `ദാരുണമായ`,
    "f1b68d66337a81cfa0d2076171cba2a8": `ചീത്ത`,
    "45802158e78dd9584161629098018fe8": `നല്ല`,
    "5709082e0d4e7a2cb92c2348acf297be": `ഇത് ഇഷ്ടപ്പെട്ടു`,
    "c629e8ac2592361ec6474f07fb75f6fc": `നിങ്ങളുടെ ഫീഡ്ബാക്ക് മെച്ചപ്പെടുത്തുന്നതിനും നിങ്ങൾക്ക് മികച്ച അപ്ലിക്കേഷൻ അനുഭവം ഉണ്ടെന്ന് ഉറപ്പാക്കാനും പുതിയ അവസരങ്ങൾ കണ്ടെത്താൻ ഞങ്ങളെ അനുവദിക്കുന്നു.`,
    "871abe85eba547582d9e8f0f9c61efbd": `ഞങ്ങൾക്ക് ഫീഡ്ബാക്ക് അയയ്ച്ചതിന് നന്ദി.`,
    "733d43480c8589b1368e5def6b480415": `പ്രയോഗിച്ചു`,
    "a5b739036b4d0b2bc966291747198d9b": `ചേരുന്ന`,
    "0a4c051fb821b8e96d2bd564b893639b": `നിങ്ങൾക്കായി ഇഷ്ടാനുസൃതമാക്കിയ സ്കീമുകളൊന്നുമില്ല`,
    "9639e32cab248434a17ab32237cb3b71": `അപേക്ഷിക്കുക`,
    "3f68e67dc6c397aaa9d1c24c356f754f": `സ്ഥിരീകരിച്ചു`,
    "d63b911814d17bb4d541141b8531a458": `നിങ്ങളുടെ "എന്റെ സ്കീമുകളിൽ" ഈ പദ്ധതികൾ സംരക്ഷിച്ചു`,
    "454fd3b5167e532f28f4e3d47cd901fa": `നിങ്ങൾക്ക് ഇതുവരെ സംരക്ഷിച്ച സ്കീമില്ല`,
    "5e22b63835aef7976f3ca019841b1b28": `ഈ സ്കീം നീക്കംചെയ്യണമെന്ന് നിങ്ങൾക്ക് ഉറപ്പാണോ?`,
    "8454c7c12286671b47684cef824ffba6": `"ശ്രദ്ധിക്കുക! നിങ്ങൾ നിങ്ങളുടെ ഡാറ്റ മാറ്റുകയാണെങ്കിൽ നിങ്ങളുടെ പ്രൊഫൈൽ ഡാറ്റയിലും അത് അപ്ഡേറ്റ് ചെയ്യും. നിങ്ങൾ തുടരാൻ ആഗ്രഹിക്കുന്നുണ്ടോ?"`,
    "24a23d787190f2c4812ff9ab11847a72": `പദവി:`,
    "85896a744f6fd84cbcd7bddb31fae8d7": `പ്രവർത്തനം:`,
    "ccb168b5a6a86eb100dc2dee754a316b": `കാരണം:`,
    "85b7d770b94264f51f31d2d7a98f69f0": `അപ്ലിക്കേഷൻ പിൻവലിച്ചു`,
    "0ddc5e1c93fec761445a53ee9fd70285": `അധികാരത്തിലേക്ക് അയയ്ക്കുക`,
    "7a4813490daea6bbe84ed02f9534570b": `ആപ്ലിക്കേഷനും അറ്റാച്ചുമെന്റും പരിശോധിച്ചു`,
    "f9e39c8a1b3348b654b73fcdebeb3265": `അപേക്ഷ ഇതുവരെ അംഗീകരിച്ചിട്ടില്ല`,
    "6b76127f99b6f29c2a725da125d98760": `ആനുകൂല്യ വിതരണം`,
    "4296fd320beaf61dcf979ff634cf2f2d": `പ്രവർത്തനം ആവശ്യമാണ്:`,
    "fc341b619c2671a430365edefc100ff1": `പ്രവർത്തനങ്ങൾ പൂർത്തിയാക്കുക`,
    "4f4b8b7d27a33c83089f30dba4989be5": `അപ്ലിക്കേഷനുകൾ കാണുക`,
    "2f70605f8a2f6c01ac06fe0f6d32b44c": `നിങ്ങളുടെ അപേക്ഷ പിൻവലിക്കണമെന്ന് നിങ്ങൾക്ക് ഉറപ്പാണോ?`,
    "6eaaa83f6b9b14dbed5e0779d91fffb5": `അപേക്ഷ നിരസിച്ചു`,
    "6187927c14aae6cab0f87b55ef75edb1": `വീണ്ടും അപേക്ഷിക്കുക`,
    "864cacf59d640b51390faf29867c310e": `അടുത്ത ആനുകൂല്യത്തിനായി തുടരുക`,
    "16d2b386b2034b9488996466aaae0b57": `ചരിതം`,
    "c5b2992c0919083c2d8907f8fe1441f6": `സാമൂഹിക-സാമ്പത്തിക വിശദാംശങ്ങൾ`,
    "d79c1042de4ec2d7e6d84d3a8f436765": `തൊഴിൽ പ്രൊഫൈൽ`,
    "3c8d3371dfe92bb990493d3dda09717c": `വിദ്യാഭ്യാസ പ്രൊഫൈൽ`,
    "181bfe2f225b876c687104878a6b43cc": `ഭവന വിശദാംശങ്ങൾ`,
    "f081b3a821bf13173ac4ec3468122a8c": `ആരോഗ്യ വിശദാംശങ്ങൾ`,
    "fa284c99eeef0e1c874643e05a449214": `"ശ്രദ്ധ! നിങ്ങൾക്ക് എല്ലാ മൂല്യങ്ങളും തിരഞ്ഞെടുക്കാൻ കഴിയില്ല. ഈ മൂല്യം തിരഞ്ഞെടുത്തത് തിരഞ്ഞെടുക്കുന്നതിന് മുമ്പ് മറ്റൊരു മൂല്യം തിരഞ്ഞെടുക്കുക."`,
    "311d38f551d45cb61e297748f08bb658": `നിങ്ങളുടെ ഡാറ്റ വിജയകരമായി സംരക്ഷിച്ചു`,
    "4500d073ef5972cc935a59ff0b752d8b": `വരെ സാധുവാണ്`,
    "8de420ed8c345d37cf65a3494bff92ef": `വോട്ടർ കാർഡ്`,
    "f6fb95a7bfd158a53680691f250e2273": `സർക്കാർ ഫീസ്:`,
    "c51bae43a860107d2ddaddcc44c72355": `വിലാസത്തിന്റെ തെളിവ് (ഏതെങ്കിലും ഒന്ന്)`,
    "a1cba2ed3414b72aa4d7651653d08900": `പ്രായത്തിന്റെ തെളിവ് (ഏതെങ്കിലും ഒന്ന്)`,
    "330dd937743341b8c97a75ddbcfacf1b": `ഡൗൺലോഡ് ഫോമിലേക്കുള്ള ലിങ്ക്:`,
    "de259fed6ef60355d0dd031c4a97e945": `ഫോമിലേക്കുള്ള ലിങ്ക്:`,
    "6204374d06a6ca36a20e887451db78af": `കൂടുതൽ സേവനങ്ങൾ പര്യവേക്ഷണം ചെയ്യുക`,
    "98d047289f68350b76f20cd10f1db3d4": `ഐഡിയുടെ തെളിവ്`,
    "1a01812e1888f4eb6dca36e8b1d39c04": `ഡോബ് പ്രൂഫ്`,
    "0dcd6f339c0f72875972d60324db52ff": `അപേക്ഷ ഫീസ് വിശദാംശങ്ങൾ`,
    "133e72d94045f209d146dd6fc01de185": `ഫീസ് വിശദാംശങ്ങൾ`,
    "b6d11bb63dc2071d6cba2aaeba8c8a1d": `സൗകര്യങ്ങളുടെ ഫീസ്`,
    "719fec04166d6fa75f89cd29ad61fa8c": `നികുതികൾ`,
    "a876fbe73a6d039cb8c8566f6aa716d5": `മൊത്തം തുക`,
    "9d463ea3feb5afec51c20b4f62927ca3": `കൂപ്പൺ കോഡ്`,
    "620a92a00838e523161dab899b76c764": `പണമടയ്ക്കാൻ തുടരുക`,
    "6f861b42317b2333c01faa9b555b7e14": `ഈ കൂപ്പൺ ഉപയോഗിച്ച് സമ്പാദ്യം.`,
    "c6088ccfc87a825ceb53a15ce4d99510": `<Amount> ന്റെ ആകെ കിഴിവ് നിങ്ങൾ പ്രയോജനപ്പെടുത്തി`,
    "0e54fc6a9dba79c2bc03ac08f3bf4a27": `യായ്!`,
    "c1d9a46634bb89273f7d95a5577f15ad": `കൂപ്പൺ കിഴിവ്`,
    "1063e38cb53d94d386f21227fcd84717": `മാറ്റുക`,
    "ba8550da63869236a92fed41fba6e84f": `കൂപ്പൺ കോഡ് വിജയകരമായി പ്രയോഗിച്ചു`,
    "f7a7e0e3897bac9ea4c83d53f6ad5a23": `അസാധുവായ കൂപ്പൺ കോഡ്.`,
    "90fe9a78f3ea30beeb6b7ef447d2b6dd": `കൂപ്പൺ കോഡ് വിജയകരമായി നീക്കംചെയ്തു`,
    "af1b98adf7f686b84cd0b443e022b7a0": `വിഭാഗങ്ങൾ`,
    "11f18dd73f0c3d374d9d5c230cd43160": `യുവ നൈപുണ്യം`,
    "5a6c0d2579b31142130f20104fa58ea6": `സർട്ടിഫിക്കറ്റുകൾ`,
    "c482980d384a9d0e7bc39e1140270870": `ധനകാരം`,
    "605669cab962bf944d99ce89cf9e58d9": `ആരോഗം`,
    "5dac73aa49b9ccaca4b9b0d0ef615d10": `പോലീസ്`,
    "b2ece3493eed05158a9cde4a0d2da2a5": `പൊതു പരാതി`,
    "94df2a6972ca1fa79411645fe9b42339": `മേച്ചില`,
    "f5f0c488ed56cf789d19a13ffba8abe3": `വഹിച്ചുകൊണ്ടുപോവുക`,
    "0db377921f4ce762c62526131097968f": `പൊതുവായ`,
    "bc34e4c4f36b0d66910a906449910f64": `ഈ സേവനം നീക്കംചെയ്യണമെന്ന് നിങ്ങൾക്ക് ഉറപ്പാണോ?`,
    "1e22827dff05f5a1c044993aff539403": `നിങ്ങൾക്ക് സംരക്ഷിച്ച സേവനങ്ങളൊന്നുമില്ല.`,
    "b08f17b81d3b9b4f6287b87c53cfc97a": `നിങ്ങൾക്ക് ഡ്രാഫ്റ്റിൽ സേവനങ്ങളൊന്നും ഇല്ല.`,
    "6f96ffbc03ad1b9ebb5c6e2ecfcbdc71": `നിങ്ങൾ ഇതുവരെ ഒരു സേവനങ്ങളും പ്രയോഗിച്ചിട്ടില്ല.`,
    "daf87fe41645c86672e96857222a0909": `അപേക്ഷ സമർപ്പിച്ചു`,
    "54aeeea175c1509b7c56e0c984f9b4fa": `നിങ്ങളെ സേവിക്കാൻ അവസരം നൽകുന്നതിന് നന്ദി. നിങ്ങളുടെ അപ്ലിക്കേഷൻ വിജയകരമായി സമർപ്പിച്ചു.`,
    "f18c4dab2674eae1e8c287c683b66729": `ആപ്ലിക്കേഷൻ തീയതി`,
    "fb0728ffa9b40d444db91c818eb21a2b": `അപേക്ഷകന്റെ പേര്`,
    "e500e94f457ddd88824c88fda548bed2": `അപ്ലിക്കേഷൻ ഐഡി`,
    "ad0206b8eec486df629ce37169376f65": `റോൾ & ഉത്തരവാദിത്തങ്ങൾ:`,
    "6f2ad1cfe3c5b08cc53c62470f4cb1f7": `ആവശ്യമുള്ള രേഖകൾ:`,
    "1d83797411218ecca1e5ff4d4705dfb0": `തിരഞ്ഞെടുക്കൽ നടപടിക്രമം:`,
    "ce9e1e989c012211d8bc83c5353b4495": `തൊഴിൽ ആപ്ലിക്കേഷൻ വിശദാംശങ്ങൾ`,
    "18dcaa84c2ee447028f8450cd0380ba6": `അക്കാദമിക് വിശദാംശങ്ങൾ:`,
    "93e9a018926429f6d0b0c032910a8eb6": `തൊഴിൽ അപേക്ഷാ വിശദാംശങ്ങൾ അവലോകനം ചെയ്യുക`,
    "6f906132d3c6b88b57225fdcf76f6e71": `പതിവായി ചോദിക്കുന്ന ചോദ്യങ്ങൾ`,
    "2d8d66b9c9b540fcb8384167f1565b64": `അക്ഷരവിന്യാസം പരിശോധിക്കുക അല്ലെങ്കിൽ മറ്റെന്തെങ്കിലും തിരയാൻ ശ്രമിക്കുക.`,
    "f73a506c52c2dba159fc0fbee823aace": `നിങ്ങൾ ഇതിനകം ഈ സ്കീമിനായി അപേക്ഷിച്ചു. നിങ്ങളുടെ പ്രയോഗിച്ച സ്കീമുകളിൽ നിന്ന് സമാനമായ നിങ്ങളുടെ അപ്ലിക്കേഷൻ നില പരിശോധിക്കുക.`,
    "b73856e1c7ab3e647e28663d5e30fabe": `നിങ്ങൾ ഇതിനകം ഈ സ്കീമിനായി അപേക്ഷിച്ചു. നിങ്ങളുടെ പ്രായോഗിക സേവനങ്ങളിൽ നിന്ന് സമാനമായ നിങ്ങളുടെ അപ്ലിക്കേഷൻ നില പരിശോധിക്കുക.`,
    "cd850a99c0895272f2cdf23586867b16": `നിങ്ങൾ ഇതിനകം ഈ സ്കീമിനായി അപേക്ഷിച്ചു. നിങ്ങളുടെ ജോലികളിൽ നിന്ന് സമാനമായ നിങ്ങളുടെ അപ്ലിക്കേഷൻ നില പരിശോധിക്കുക.`,
    "4e0b8852fc3f3d1a2b4c24c4602766dd": `ചിത്രം JPG, PNG, PDF ഫയലിലും 1MB-യിൽ താഴെയും ആയിരിക്കണം.`,
    "5a9e7167eb5b7cb7bb46cf5c05eed59a": `മരിക്കുക`,
    "91412465ea9169dfd901dd5e7c96dd99": `അപ്ലോഡുചെയ്യുക`,
    "3835b71ace5673385a19d56bc938fda8": `ഫീൽഡ് ഫോട്ടോ)`,
    "4386624773a0ef6de5a5de0dd6f8facd": `പ്രമാണ തരം നൽകുക`,
    "fdba3d2a9c707e50f4eec34cd6bdd0d6": `സർക്കാർ പദ്ധതിക്ക് അപേക്ഷിക്കുന്ന ഉപയോക്താവിന്റെ ഐഡന്റിറ്റി തിരിച്ചറിയുകയും പരിശോധിക്കുകയും ചെയ്യുന്ന നിർബന്ധിത പ്രക്രിയയാണ് "നിങ്ങളുടെ ഉപഭോക്താവിനെ അറിയുക".`,
    "244773cb8f460196045c5b90659d2c9d": `നിങ്ങളുടെ ആപ്ലിക്കേഷൻ നിങ്ങളുടെ "ഡ്രാഫ്റ്റ് സേവനങ്ങളിൽ" വിജയകരമായി സംരക്ഷിച്ചു`,
    "bbcb2990551820fc4678835c14a84503": `ഫോട്ടോ നീക്കംചെയ്യുക`,
    "78e3e2ba60b81cf6cc24e6baf89eaa81": `വ്യക്തിഗത സർട്ടിഫിക്കറ്റുകൾ`,
    "88bcab139c5d6142b04bf77d7dd1c023": `നിങ്ങൾ ഇതിനകം ഈ സേവനത്തിനായി അപേക്ഷിച്ചു. നിങ്ങളുടെ പ്രായോഗിക സേവനങ്ങളിൽ നിന്ന് സമാനമായ നിങ്ങളുടെ അപ്ലിക്കേഷൻ നില പരിശോധിക്കുക.`,
    "bf8cd5900a65efaa89931067cb1d3775": `ഡ്രാഫ്റ്റിലെ ഏതെങ്കിലും സ്കീമുകൾ നിങ്ങൾ സംരക്ഷിച്ചിട്ടില്ല.`,
    "beab92ac3c8a798a35aea2ad0809313f": `ഒരു അതിഥിയായി തുടരുക`,
    "95884374c7752d972101b70854caffe6": `പ്രവേശിക്കുക / സൈൻ അപ്പ് ചെയ്യുക`,
    "e44cb1c4a784a247f7296cc02f7f301c": `ജില്ല തിരഞ്ഞെടുക്കുക`,
    "9aa060dc3e3bb3ffff9908c5e713a80c": `നിങ്ങൾ ഇതുവരെ ഒരു സേവനത്തിനും അപേക്ഷിച്ചിട്ടില്ല.`,
    "383f419ea8c0b8142cbdc71e2e4b144a": `മുമ്പത്തെ ചോദ്യം`,
    "fb188e808b65b9ee48a5c9d88fb92af2": `വീണ്ടും ആരംഭിക്കുക`,
    "db5eb84117d06047c97c9a0191b5fffe": `പിന്താങ്ങുക`,
    "bc80ae98530c6ba4e76ed34c58d81875": `മൊബൈൽ ആപ്പിനായി Easygov ഡൗൺലോഡ് ചെയ്യുക`,
    "85ce0cae173233410acbc380a7e531cd": `ഈ പേജ് ഗവൺമെന്റിന്റെ വെബ്സൈറ്റുകൾ / വെബ് പേജുകളിലേക്കുള്ള ലിങ്കുകളും നൽകുന്നു. മന്ത്രാത്രികൾ / വകുപ്പുകൾ / ഓർഗനൈസേഷനുകൾ. ഈ വെബ്സൈറ്റുകളുടെ ഉള്ളടക്കം ബന്ധപ്പെട്ട ഓർഗനൈസേഷനുകളുടെ ഉടമസ്ഥതയിലുള്ളതാണ്, അവ കൂടുതൽ വിവരങ്ങൾക്കോ ​​നിർദ്ദേശത്തിനോ വേണ്ടി ബന്ധപ്പെട്ടിരിക്കുന്നു`,
    "ad0a802ec8134e9210a5625933db50a7": `നിരാകരണം`,
    "26dbd93cbe6f1e56b1577d8e3cb7fda3": `സേവനങ്ങൾ തിരഞ്ഞെടുക്കുക`,
    "c6582b36b0f516684a873e8782517822": `സ്കീമുകളും സേവനങ്ങളും തിരഞ്ഞെടുക്കുക`,
    "b98287be44e1230cf144aa3af08ac82f": `നിങ്ങൾ ഇതുവരെ ഏതെങ്കിലും സ്കീമുകൾക്കായി ചേർത്തിട്ടില്ല`,
    "449d3cf7dd09172024ebe93872410f0f": `അപ്ലിക്കേഷൻ കാണുക`,
    "025d9b3a3c2a6a27d04c57c1d7cabc9f": `ഒരു നടപടിയും ആവശ്യമില്ല`,
    "06df33001c1d7187fdd81ea1f5b277aa": `പ്രവർത്തനങ്ങൾ`,
    "adff901e227bf2b2200c4767fe773836": `പ്രവർത്തനങ്ങൾ ആവശ്യമാണ്`,
    "4ff84d2ee02fe6ef0ca2edc3cdecc0ed": `പ്രവർത്തനങ്ങൾ ആവശ്യമാണ്`,
    "c08c272bc5648735d560f0ba5114a256": `ബന്ധപ്പെടാനുള്ള പിന്തുണ`,
    "fd2f820569d83784238e1027897b463e": `നിങ്ങളുടെ പേര് നൽകുക`,
    "cda83d00666a7bfa8cde6808de4ef994": `കോൺടാക്റ്റ് നമ്പർ`,
    "a4d3b161ce1309df1c4e25df28694b7b": `കീഴടങ്ങുക`,
    "4c2a8fe7eaf24721cc7a9f0175115bd4": `ദൂത്`,
    "68d390535e8a7120e3f6942d8a2214a0": `നിങ്ങളുടെ സന്ദേശം നൽകുക`,
    "2f644b4a27e68f505c4f4ac2ffe3a8ac": `അക്ഷരമാല, അക്കങ്ങൾ, കോമ, -, _, /`,
    "58f7a43f2c3bd7854d99fbbbc3fa4ae9": `അക്ഷരമാല മാത്രമേ അനുവദിക്കൂ.`,
    "4906d981bb592b32f6982fb4187c7b60": `സാധുവായ ഐഎഫ്എസ്സി കോഡ് നൽകുക.`,
    "562d3d08919ae105c3ebc42d9607d266": `അക്ഷരമാല അല്ലെങ്കിൽ അക്കങ്ങൾ മാത്രം നൽകുക`,
    "fab2bb673b21bef95ee70b99dbd7f9fc": `11 പ്രതീകങ്ങൾ മാത്രമേ അനുവദിക്കൂ.`,
    "a7eb433676475fee54727c14ad880b8c": `150 പ്രതീകങ്ങൾ മാത്രമേ അനുവദിക്കൂ.`,
    "7c58cde3b827bc7d57cbf77865046169": `നമ്പറുകൾ മാത്രം നൽകുക`,
    "544b0264d55a1e4cf476b279240d9be4": `പത്ത് അക്കങ്ങൾ മാത്രമേ അനുവദിക്കൂ. 0 അല്ലെങ്കിൽ +91 പ്രിഫിക്സ് ചെയ്യരുത്`,
    "73f0d980e48b8141e0776e02d96a872f": `പന്ത്രണ്ട് അക്കങ്ങൾ മാത്രമേ അനുവദിക്കൂ.`,
    "0ce98bd973419ff33921a2275e7903b9": `സാധുവായ ഒരു പാൻ നമ്പർ നൽകുക.`,
    "c6f91b305e91a0053748310de170a73c": `ആറ് അക്കങ്ങൾ മാത്രമേ അനുവദിക്കൂ`,
    "9f44bd93c8988c682d5ef5369fd11f47": `സാധുവായ ഇമെയിൽ നൽകുക`,
    "10803b83a68db8f7e7a33e3b41e184d0": `ജനിച്ച ദിവസം`,
    "ac598199ea0dd028ade4677a435caf4b": `സാധുവായ ആധാർ നമ്പർ നൽകുക`,
    "d0de720ccdffea8490dfd2893c50ebc5": `ഇത് {{displayName}} എന്നതിനേക്കാൾ വലുതായിരിക്കണം നിങ്ങളുടെ അപേക്ഷയുമായി മുന്നോട്ട് പോകുക.`,
    "ced6e171e13ebd5c37415c822706b9d4": `ഇത് {{displayName}} എന്നതിനേക്കാൾ കുറവായിരിക്കണം`,
    "d1a1cb2bd14fdd9eec972f162cfd1ee0": `ഇത് {{displayName}} എന്നതിന് തുല്യമായിരിക്കണം`,
    "9483135517d156229d0439dff74b1ca0": `ഇത് {{displayName}} എന്നതിനേക്കാൾ വലുതോ തുല്യമോ ആയിരിക്കണം`,
    "01e9cd748e852b1ae389b980bff195b0": `ഇത് {{displayName}} എന്നതിനേക്കാൾ കുറവോ തുല്യമോ ആയിരിക്കണം`,
    "dc236871b26ba387e1284445caa99df4": `നിങ്ങളെ സേവിക്കാൻ അവസരം നൽകുന്നതിന്.`,
    "2102fff7bb4de45c9e616e3f01b6810f": `നിങ്ങളുടെ അപ്ലിക്കേഷൻ വിജയകരമായി സമർപ്പിച്ചു.`,
    "dc9466f3d424b4acba2fc5c9f58d4f8a": `ക്ഷമിക്കണം, നിങ്ങൾക്ക് യോഗ്യതയില്ല`,
    "a9198ed7d89e246a80078db84d2722e8": `നിങ്ങളുടെ ഫോൺ നമ്പർ അല്ലെങ്കിൽ പാസ്വേഡ് പൊരുത്തപ്പെടുന്നില്ല`,
    "8ac0a98c8c2f2c86396e34b6b5d63e38": `ഒരേ പേരിലുള്ള പ്രൊഫൈൽ ഇതിനകം നിലവിലുണ്ട്`,
    "4ae112e33b3189c9fa04369b51259d76": `ഒരേ പേരും ബന്ധവും ഉള്ള പ്രൊഫൈൽ ഇതിനകം നിലവിലുണ്ട്`,
    "b0fb09a28a4a52a1b3ca2656dec0435e": `ഈ പ്രമാണം നീക്കംചെയ്യണമെന്ന് നിങ്ങൾക്ക് ഉറപ്പാണോ?`,
    "e384b6a4760d80be609d897414cfe680": `നിങ്ങൾ ഒരു രജിസ്റ്റർ ചെയ്ത ഉപയോക്താവല്ല, കൂടുതൽ മുന്നോട്ട് പോകുന്നതിന് നിങ്ങൾക്ക് "സൈൻ അപ്പ്" അല്ലെങ്കിൽ "OTP ഉപയോഗിച്ച് ലോഗിൻ ചെയ്യാൻ" കഴിയും`,
    "c2cd85ffa55b2ec9b049b7eb131d3a9f": `നൽകിയ പാസ്വേഡുകൾ പൊരുത്തപ്പെടുന്നില്ല. വീണ്ടും ശ്രമിക്കുക`,
    "3a816ee692e09b3cac6ef18e14071bd4": `നിങ്ങളുടെ പാസ്വേഡ് വളരെ വിജയകരമായി സജ്ജമാക്കി`,
    "764d4847b734121ceb58e5c8195fa5b6": `നിങ്ങളുടെ ഫീഡ്ബാക്ക് മെച്ചപ്പെടുത്തുന്നതിനുള്ള പുതിയ അവസരങ്ങൾ കണ്ടെത്താൻ ഞങ്ങളെ അനുവദിക്കുന്നു, നിങ്ങൾക്ക് മികച്ച അപ്ലിക്കേഷൻ അനുഭവം ഉണ്ടെന്ന് ഉറപ്പാക്കുക.`,
    "701d895fbc64569aa143ca6c44274139": `OTP സൃഷ്ടിക്കുന്നതിനുള്ള പരമാവധി പരിധി നിങ്ങൾ കവിഞ്ഞു, കുറച്ച് സമയത്തിന് ശേഷം വീണ്ടും ശ്രമിക്കുക.`,
    "0bdfc4d562c9b0ccfaa4c0f6d4cac98f": `നിങ്ങൾ ഇതുവരെ ഏതെങ്കിലും സ്കീമുകൾക്ക് അപേക്ഷിച്ചിട്ടില്ല.`,
    "4391bf5189e3e13c4aa4cb314c605ac8": `നിങ്ങൾ ഇതുവരെ ഒരു സ്കീമുകളും സംരക്ഷിച്ചിട്ടില്ല.`,
    "92202504591d549b89340d26ffc0753a": `ഈ ജോലി നിങ്ങളുടെ "എന്റെ ജോലികളിൽ" സംരക്ഷിച്ചു`,
    "af6ebe0c0e89201cb2294f32955f7b15": `ഒരു സമയം 20 പ്രമാണങ്ങൾ മാത്രമേ അനുവദിക്കൂ.`,
    "b1481f771aee527fd910fa8d8672cd19": `ഈ അറിയിപ്പ് നീക്കംചെയ്യണമെന്ന് നിങ്ങൾക്ക് ഉറപ്പാണോ?`,
    "06a6b009c19d5816574cdc7800d10eb5": `നിങ്ങളുടെ അഭ്യർത്ഥന വിജയകരമായി ഉയർത്തി.`,
    "b941bc7a9d4fe7a8c7c31ed440754285": `ആപ്ലിക്കേഷൻ അവലോകനം ചെയ്യുക`,
    "e56b98da835e8f4a018502b121520ac7": `അവശേഷിക്കുന്ന ശ്രമങ്ങൾ`,
    "416828262df288445e72ca1d3661f993": `മുന്നറിയിപ്പ് - തുടർച്ചയായ 4 ശ്രമങ്ങൾക്ക് ശേഷം, നിങ്ങളുടെ അക്കൗണ്ട് ലോക്കുചെയ്യും`,
    "9f978addf10226b03278e4932348ba98": `40 പ്രതീകങ്ങൾ മാത്രമേ അനുവദിക്കൂ.`,
    "69f076c339373e00e9e841da965f4208": `ആധാർ അപ്ഡേറ്റ് ചെയ്യുക`,
    "48a82e0b2bd5818f110e55bb6f14aedc": `നിങ്ങൾ നൽകുന്ന ഇനിപ്പറയുന്ന വിവരങ്ങൾ സർക്കാർ രേഖയുമായി പൊരുത്തപ്പെടുന്നില്ല`,
    "5c37ad5c0dd6e318af899b3c8fcbe886": `ക്ഷമിക്കണം, ഉറവിട സിസ്റ്റത്തിൽ നിന്ന് നിങ്ങളുടെ ഡാറ്റ ലഭ്യമാക്കാനായില്ല. നിങ്ങൾക്ക് സ്ഥിരീകരണമോ പിന്നീട് ശ്രമിക്കാം അല്ലെങ്കിൽ പിന്നീട് ശ്രമിക്കുക.`,
    "cdb2033fc9a30d6d6c1abe401cd1e37b": `പിന്നീട് ശ്രമിക്കുക`,
    "f8d855b26faffa0f8779fb44632d6bc1": `അസാധുവായ ക്യാപ്‌ച കോഡ്`,
    "d53b4d564b9054db9b4b4a50e713197f": `ദയവായി ഇവിടെ കാപ്ച നൽകുക`,
    "ed001fb3ff7eaca9bbfbaacddc9d5a72": `ഒരു ചരിത്രവും കണ്ടെത്തിയില്ല`,
    "4934d9bc9ebd024d4106d5e1f093ae79": `സന്ദർശകർ കണക്കാക്കുന്നു`,
    "f7ce7cb4b450acfe0b4cef36c3bdec2d": `നിങ്ങളുടെ ബാങ്ക് അക്കൗണ്ട് നിലവിൽ നിഷ്ക്രിയമാണ്. നിങ്ങളുടെ അപ്ലിക്കേഷൻ അംഗീകരിക്കപ്പെടുകയാണെങ്കിൽ ആനുകൂല്യം ലഭിക്കാൻ ആദ്യം നിങ്ങളുടെ ബാങ്ക് അക്കൗണ്ട് സജീവമാക്കി.`,
    "df9e5c88861b08c33b2d46de8d7037e9": `നിങ്ങളുടെ ബാങ്ക് അക്കൗണ്ട് നിലവിൽ റദ്ദാക്കി. നിങ്ങളുടെ അപ്ലിക്കേഷൻ അംഗീകരിക്കപ്പെടുകയാണെങ്കിൽ ആനുകൂല്യം ലഭിക്കാൻ ആദ്യം നിങ്ങളുടെ ബാങ്ക് അക്കൗണ്ട് സജീവമാക്കി.`,
    "3fc95e40b475f7269e64f25a1c4aef3b": `നിങ്ങളുടെ ബാങ്ക് അക്കൗണ്ട് നിങ്ങളുടെ ആധാറെയുമായി ബന്ധിപ്പിച്ചിട്ടില്ല. നിങ്ങൾക്ക് കൂടുതൽ മുന്നോട്ട് പോകാം, പക്ഷേ നിങ്ങളുടെ ബാങ്ക് അക്കൗണ്ട് ലിങ്ക്ഡ് നേടുക, നിങ്ങളുടെ അപേക്ഷ അംഗീകരിക്കുകയാണെങ്കിൽ ആനുകൂല്യം ലഭിക്കാൻ നിങ്ങളുടെ ആധാറിനൊപ്പം ലിങ്കുചെയ്യുക.`,
    "1c35d003a65da48bf9f6d6fc69271d5a": `ക്ഷമിക്കണം, ഒരു സിസ്റ്റം പിശക് കാരണം നിങ്ങളുടെ അപ്ലിക്കേഷൻ സമർപ്പിക്കാൻ കഴിഞ്ഞില്ല. പിന്നീട് വീണ്ടും ശ്രമിക്കുക.`,
    "8e437cbd840bce5c5edc0ef8164de6b7": `നിങ്ങളുടെ വിശദാംശങ്ങൾ അപ്ഡേറ്റുചെയ്യുകയോ മാറ്റുകയോ ചെയ്താൽ നിങ്ങളുടെ ഡാറ്റ സ്ഥിരീകരിക്കുകയും അപ്ഡേറ്റുചെയ്ത ഡാറ്റയ്ക്കായി പ്രമാണ പ്രൂഫ് അപ്ലോഡ് ചെയ്യാൻ നിങ്ങളോട് ആവശ്യപ്പെടുകയും ചെയ്യും. തുടരാൻ "തുടരുക" ക്ലിക്കുചെയ്യുക.`,
    "b010e3be9f81072a9808ed4a5dd0e45b": `ഇനിപ്പറയുന്ന വിവരങ്ങൾ സർവേയിൽ നിങ്ങൾ നൽകിയ വിശദാംശങ്ങളുമായി പൊരുത്തപ്പെടുന്നില്ല:`,
    "01acb0344944f11d68aeecc9f5b574ea": `നിങ്ങൾ പ്രതികരണവുമായി (കൾ) തുടരാൻ ആഗ്രഹിക്കുന്നുണ്ടോ?`,
    "4ff70f7b2e5a74415de360150a6e59d3": `നിങ്ങളുടെ`,
    "bd7aaae9a27a2a4d71214c002bcf50ce": `റെക്കോർഡുമായി പൊരുത്തപ്പെടുന്നില്ല.`,
    "ebfd339b0ac14aa79b325b53f06bd375": `നിങ്ങൾ നൽകുന്ന വിവരങ്ങൾ നിങ്ങളുടെ ആധാർ വിശദാംശങ്ങളുമായി പൊരുത്തപ്പെടുന്നില്ല. നിങ്ങൾക്ക് കൂടുതൽ മുന്നോട്ട് പോകാൻ കഴിയില്ല.`,
    "1e410e1b9903e7a99b93857f536b7e1d": `പരിശോധിച്ചിട്ടില്ല`,
    "28b266f2bf0a4ecc2dec3b6ca24fcb9b": `അപ്ലോഡുചെയ്യുക`,
    "68982db8041ee52dd9e5a6e527c8a1fa": `വീണ്ടും വിള`,
    "e7bf7d83a37666fd8749e433189b1c27": `കാത്തിരിക്കൂ`,
    "c389f9e7d5ea93af9deb2e8f1f9c6d89": `കൂടുതലറിയാൻ {{"0x0001F6C8"}} ക്ലിക്കുചെയ്യുക.`,
    "f17816bcbe79e18bee49388fe84d2876": `OTP കാലഹരണപ്പെട്ടു.`,
    "1531d9dc798857eb85345d349bffc99b": `പിന്നിലുള്ള`,
    "7939de8a9958e01fb1f172e21c5a7a85": `ശരിയായ വിവരങ്ങൾ നൽകുക, നിങ്ങൾ യോഗ്യതയുള്ള സ്കീമുകൾ കാണിക്കാൻ ഇത് ഞങ്ങളെ സഹായിക്കും.`,
    "bf58c4a9eac8e0ec82c158c3adfcd609": `വിവിധ വിഭാഗങ്ങളിൽ സ്കീമുകൾ തരം തിരിച്ചിരിക്കുന്നു. നിങ്ങൾ തിരഞ്ഞെടുത്ത വിഭാഗമനുസരിച്ച് സ്കീമുകൾ പരിശോധിക്കാൻ കഴിയും`,
    "09349e778786f7ed758021ddbed12ca6": `{Intent} വിഭാഗത്തിന് കീഴിലുള്ള യോഗ്യതയുള്ള സ്കീമുകൾ കണ്ടെത്താൻ ഈ വിവരങ്ങൾ സഹായിക്കും.`,
    "0d45c019e7e9237a316f0f16824015d2": `നിങ്ങളുടെ പ്രൊഫൈൽ ഡാറ്റ വ്യത്യസ്ത ടാബുകൾക്ക് കീഴിലാണ് സംഭരിച്ചിരിക്കുന്നത്. നിങ്ങളുടെ ഏതെങ്കിലും പ്രൊഫൈൽ വിവരങ്ങൾ എഡിറ്റുചെയ്യാനാകും.`,
    "3f52884ec08606e6e73426eaec8de195": `ഈ സ്കീമിന് കീഴിൽ നിങ്ങൾക്ക് ലഭിച്ചേക്കാവുന്ന കൃത്യമായ നേട്ടത്തെക്കുറിച്ചും ആനുകൂല്യങ്ങൾ ലഭിക്കുന്നതിനുള്ള അപ്ലിക്കേഷൻ പ്രക്രിയയെക്കുറിച്ചും നിങ്ങൾക്ക് വിശദാംശങ്ങൾ നൽകാൻ ഈ വിവരങ്ങൾ ഞങ്ങളെ സഹായിക്കും.`,
    "c389f9e7d5ea93af9deb2e8f1f9c6d89": `കൂടുതലറിയാൻ {{"0x0001F6C8"}} ക്ലിക്കുചെയ്യുക.`,
    "5cc2bdab3db5aa21633656a0d55f7477": `ആപ്ലിക്കേഷൻ ഉപയോഗിച്ച് നിങ്ങൾക്ക് കൂടുതൽ മുന്നോട്ട് പോകാൻ കഴിയില്ല`,
    "d5de543c0e83073f05a846d62cc83c81": `ഒരേ ഫീൽഡിനായി ഇതിനകം പ്രമാണം ഇതിനകം അപ്ലോഡുചെയ്തു.`,
    "fca8da28cbcbe094dc646872e7cdbc7b": `സാധുവായത് നൽകുക`,
    "6fa2260c737f40fa1a3f63274c8fca48": `നിങ്ങൾക്ക് കൂടുതൽ മുന്നോട്ട് പോകാൻ കഴിയില്ല. നിങ്ങൾക്ക് പ്രൊഫൈലിൽ അല്ലെങ്കിൽ നിങ്ങളുടെ ആധാർ കാർഡിൽ നിങ്ങളുടെ വിശദാംശങ്ങൾ ശരിയാക്കാം`,
    "3272e327ba9c82197c5c982487224925": `ക്ഷമിക്കണം, ഉറവിട സിസ്റ്റത്തിൽ നിന്ന് നിങ്ങളുടെ ഡാറ്റ ലഭ്യമാക്കാനായില്ല. പിന്നീട് വീണ്ടും ശ്രമിക്കുക.`,
    "586637318049e6dbb03e991bc45cba06": `നിങ്ങളുടെ അക്കൗണ്ട് ഇല്ലാതാക്കാൻ നിങ്ങൾ ആദ്യം നിങ്ങളുടെ അക്കൗണ്ടുമായി ബന്ധപ്പെട്ട എല്ലാ പ്രൊഫൈലുകളും ഇല്ലാതാക്കണം.`,
    "ce6d6092569f3f247659ceb51d57be95": `നിങ്ങളുടെ പ്രയോഗിച്ച അല്ലെങ്കിൽ എൻറോൾ ചെയ്ത സ്കീമുകളിൽ നിങ്ങൾക്ക് നിലവിലുള്ള ആപ്ലിക്കേഷൻ ഉള്ളതിനാൽ നിങ്ങൾക്ക് {{profileName}} പ്രൊഫൈൽ ഇല്ലാതാക്കാൻ കഴിയില്ല`,
    "8d258e398e2dbc8099c984258fadce2a": `നിങ്ങളുടെ രജിസ്റ്റർ ചെയ്ത മൊബൈൽ നമ്പറിലേക്ക് ഒരു OTP അയച്ചു (xxx xxx {{phoneNumber}})`,
    "abb2e3d13ded4cea9275beafacd9f4c8": `ഈ സ്കീമിൽ നിങ്ങളുടെ താൽപ്പര്യം ഉയർത്താൻ, "നിങ്ങളുടെ താൽപ്പര്യം സൃഷ്ടിക്കുക" ക്ലിക്കുചെയ്യുക, ഈ സ്കീമിന്റെ നേട്ടം നൽകാൻ സർക്കാർ നിങ്ങളെ സമീപിക്കും`,
    "493de8e0a6b5d4a10af30cf3105b9e1f": `നിങ്ങളുടെ താൽപ്പര്യം സൃഷ്ടിക്കുക`,
    "c9b0246a8f3989724ec4958f91e380a7": `സ്കീം വിശദാംശങ്ങൾ`,
    "804ca01b219522a88d26c3002632a00e": `വിശദാംശങ്ങൾ പങ്കിട്ടതിന് നന്ദി.`,
    "04b6d9b7eb73749476d958b2133f4bbe": `സ്കീം ആര്നെസ് സർവേ`,
    "a5661f68fc78a5d4f15afa057fec7a9f": `കൂടുതൽ മുന്നോട്ട് പോകുന്നതിന് നിങ്ങൾ സർവേയിൽ നിങ്ങളുടെ വിശദാംശങ്ങൾ അപ്ഡേറ്റ് ചെയ്യേണ്ടതുണ്ട്.`,
    "1e4531d1dc128abbc00b48e5c7b6ef48": `അപ്ഡേറ്റുചെയ്യുക സർവേ`,
    "362e532b6ad1fda489c5909d437f02a3": `ഞങ്ങൾ നിങ്ങളുടെ വിശദാംശങ്ങൾ ലഭ്യമാക്കുന്നു, ദയവായി കാത്തിരിക്കുക ..`,
    "8a1b3c7d62e2d60d5be34d8e36a9768f": `വ്യക്തിഗത ലോഗിനിലേക്ക് മടങ്ങുക`,
    "6737d50489b1cf1d4408e66b8591ca38": `നിങ്ങളുടെ സ്കീം ആപ്ലിക്കേഷനിൽ പ്രവർത്തനക്ഷമതയിലുണ്ട്. നിങ്ങളുടെ അപ്ലിക്കേഷൻ ഉപയോഗിച്ച് കൂടുതൽ മുന്നോട്ട് പോകാൻ ശേഷിക്കുന്ന പ്രവർത്തനങ്ങൾ പൂർത്തിയാക്കുക.`,
    "e37c99137b7396277137c1f73f8a86d5": `സിജിന്റെ ഇന്റീയേറ്റ്`,
    "706c7a936b977ec3c4e6603f888e3fce": `EasyGov നൽകുന്നത്`,
    "598d42810b342196884f255912974d69": `സ്ല. ഇല്ല.`,
    "60e4e81c6f9c1938d77e8ded6d64643a": `സ്ക്രീൻ റീഡർ`,
    "15bbb9d0bbf25e8d2978de1168c749dc": `വെബ്സൈറ്റ്`,
    "21d587949a2a2e89c958b622e244b405": `സ / ജന്യ / വാണിജ്യപരമായ`,
    "d5c03f7b855d442a2a7f24024cf89960": `നോൺ ദൃശ്യ അല്ലാത്ത ഡെസ്ക്ടോപ്പ് ആക്സസ്സ് (എൻവിഡിഎ)`,
    "f956e16f65a3a937e5ecfe4195d17d0d": `(ഒരു പുതിയ വിൻഡോയിൽ തുറക്കുന്ന ബാഹ്യ വെബ്സൈറ്റ്)`,
    "b24ce0cd392a5b0b8dedc66c25213594": `സൗ ജന്യം`,
    "c8ed8ec3dc8b21290b5d28ca56344553": `താടിയെല്ലുകൾ`,
    "b2788e1ab6be65f1c38bf7d23cd081f1": `വാണിജ്യപരമായ`,
    "ec18aa8148f59475130ff217d693b465": `വിൻഡോ കണ്ണുകൾ`,
    "d96a273ceb2b5f83d710081d9c38440a": `പോകാൻ സിസ്റ്റം ആക്സസ്`,
    "c932fd98147348de9974252a0efcc7cb": `WebAnywhere`,
    "540431e2b3234a8698b4044f15ef1fff": `പിഡിഎഫ് ഉള്ളടക്കം`,
    "801ab24683a4a8c433c6eb40c48bcd9d": `ഡൗൺലോഡ്`,
    "83f8a38f024082405d2fff921c2bb1d2": `അഡോബ് അക്രോബാറ്റ് റീഡർ`,
    "9d8c84ff52cfc2c2e0075e669edacffd": `വേഡ് ഫയലുകൾ`,
    "4db1a171aaf96723d4002cc41559ddf7": `ഒരു പുതിയ വിൻഡോയിൽ തുറക്കുന്ന ബാഹ്യ വെബ്സൈറ്റ്`,
    "86c61b344697039b8a54bbef9e4959c3": `എക്സൽ വ്യൂവർ 2003 (2003 വരെയുള്ള ഏത് പതിപ്പിലും)`,
    "04efb134468330da1430e32701160107": `എക്സൽ- നായുള്ള മൈക്രോസോഫ്റ്റ് ഓഫീസ് അനുയോജ്യത പായ്ക്ക് (2007 പതിപ്പിനായി)`,
    "a385a9bb61bb694694eef5d2f54c563a": `പവർപോയിന്റ് അവതരണങ്ങൾ`,
    "da67d56a74c01537a71a3ab0eda00faa": `പവർപോയിന്റിനായുള്ള മൈക്രോസോഫ്റ്റ് ഓഫീസ് അനുയോജ്യത പായ്ക്ക് (2007 പതിപ്പിനായി)`,
    "bb625547a85b98c2c9f9f4121c72cd78": `ഫ്ലാഷ് ഉള്ളടക്കം`,
    "ab69edd2798068928605717c6591462f": `അഡോബ് ഫ്ലാഷ് പ്ലെയർ`,
    "2c4fa0a307152ef814bbab8d4075d938": `ഓഡിയോ ഫയലുകൾ`,
    "da7be99fd6fac5c8a4065eb2c07cad88": `വിൻഡോസ് മീഡിയ പ്ലെയർ`,
    "5a5652ca26ee42fe73fdd79497cf9e21": `വേഡ് വ്യൂവർ (2003 വരെ ഏത് പതിപ്പിലും)`,
    "2e856241d3d995fefdb1b4b32f84a79b": `വേഡിനായുള്ള മൈക്രോസോഫ്റ്റ് ഓഫീസ് അനുയോജ്യത പായ്ക്ക് (2007 പതിപ്പിന്)`,
    "64a443242750513eb56695e07aedcff2": `എക്സൽ ഫയലുകൾ`,
    "382b0f5185773fa0f67a8ed8056c7759": `എൻ/എ`,
    "462c18d5b89050fb1b7f8fca1e535af8": `പ്രോസസ്സ് ചെയ്തു`,
    "ccb26ac32c3cc7cc2f0fd1bbac3b8e2d": `പ്രോസസ് ചെയ്ത സേവനങ്ങളൊന്നും നിങ്ങൾക്ക് ഇല്ല`,
    "fc44a0efc672761745c2e76aac6851d2": `നിങ്ങളുടെ അപ്ലിക്കേഷൻ നിരസിക്കപ്പെട്ടു`,
    "7d0db380a5b95a8ba1da0bca241abda1": `സ്ഥാനം`,
    "1252044838fd74c4e8b506ef1302a8b5": `സങ്കടം`,
    "06627e6919d21853d887d00334ee5337": `പരാതി മാനേജുമെന്റ്`,
    "f940fbe4aec76e9784e7f15c4b92ac6d": `പ്രോസസ് ചെയ്തതിൽ നിങ്ങൾക്ക് ഒരു പരാതിയും ഇല്ല`,
    "2290dc1500a2b4548eb3b931f2e3609a": `നിങ്ങൾക്ക് സംരക്ഷിച്ച പരാതികൾ ഇല്ല.`,
    "b25b60dc1a5f7c17e09bc73174668d9a": `നിങ്ങൾക്ക് ഡ്രാഫ്റ്റിൽ ഒരു പരാതിയും ഇല്ല.`,
    "a02c58d421087ec9519620a29d68a6ec": `നിങ്ങൾ ഇതുവരെ ഒരു പരാതിക്കും സമർപ്പിച്ചിട്ടില്ല.`,
    "b65f636dcbc018b1901e23a7c2adde90": `കൂടുതൽ പര്യവേക്ഷണം ചെയ്യുക`,
    "5c835c22429f25992b4371691ecde1a4": `സമർപ്പിച്ചു`,
    "2535f5e4e0892c73a5fad023bf3e15e2": `എന്റെ പരാതികൾ`,
    "4351cfebe4b61d8aa5efa1d020710005": `ദര്ശനം`,
    "e9516387730903ffd7cfe3317e05e385": `ഈ പരാതി നിങ്ങളുടെ "എന്റെ പരാതി" യിൽ രക്ഷിക്കപ്പെട്ടു.`,
    "b70db24f2cb70fb266eb03da8f289ba5": `പരാതി കാണുക`,
    "646558064e10a12b1e8914e86eca3ca6": `നിങ്ങളുടെ സേവന ആപ്ലിക്കേഷനിൽ പ്രവർത്തനക്ഷമതയിലുണ്ട്. നിങ്ങളുടെ അപ്ലിക്കേഷൻ ഉപയോഗിച്ച് കൂടുതൽ മുന്നോട്ട് പോകാൻ ശേഷിക്കുന്ന പ്രവർത്തനങ്ങൾ പൂർത്തിയാക്കുക.`,
    "d2841171ea05a48fa5463b3f7c7c92d7": `നിങ്ങളുടെ പരാതി പ്രയോഗത്തിൽ നിങ്ങൾ പ്രവർത്തനക്ഷമതയില്ല. നിങ്ങളുടെ അപ്ലിക്കേഷൻ ഉപയോഗിച്ച് കൂടുതൽ മുന്നോട്ട് പോകാൻ ശേഷിക്കുന്ന പ്രവർത്തനങ്ങൾ പൂർത്തിയാക്കുക.`,
    "9279f823b01f13b7a8c04688289543cc": `നിങ്ങളുടെ അപ്ലിക്കേഷൻ നിങ്ങളുടെ 'ഡ്രാഫ്റ്റ് പരാതിയിൽ വിജയകരമായി സംരക്ഷിച്ചു.`,
    "af481c93dcae73d73afe9927076c8a09": `ഈ പരാതികൾക്കായി നിങ്ങൾ ഇതിനകം പ്രയോഗിച്ചു. നിങ്ങളുടെ പ്രയോഗിച്ച പരാതികളിൽ നിന്ന് സമാനമായ നിങ്ങളുടെ അപ്ലിക്കേഷൻ നില പരിശോധിക്കുക.`,
    "ce972231a0faf31791c7ac0bf0d1da84": `നിങ്ങൾ എന്തെങ്കിലും പണം നൽകിയിട്ടുണ്ടെങ്കിൽ നിങ്ങളുടെ തുക തിരികെ നൽകില്ല.`,
    "2ffef02ca513f8bbadbaf130a23097de": `നിങ്ങളുടെ ആപ്ലിക്കേഷൻ നിങ്ങളുടെ "ഡ്രാഫ്റ്റ് പരാതികളിൽ" വിജയകരമായി സംരക്ഷിച്ചു`,
    "e55e425dbaf6b6449940eac8ea749092": `അക്ഷരമാല മാത്രം നൽകുക.`,
    "64ff9e3809686d4ea3253a631cae4761": `2 ദശാംശസ്ഥാനങ്ങളിലേക്ക് ഒരു മൂല്യം ശരിയായി നൽകുക.`,
    "7ed0ef23d527c2876015161e422f49ce": `കന്നഡ ഭാഷയിൽ ദയവായി നിങ്ങളുടെ പേര് നൽകുക.`,
    "60ae728544ab5cb25dba9ff4b8f6aa95": `സേവ സിന്ധുവിനുള്ള നിങ്ങളുടെ അപ്ലിക്കേഷൻ റഫറൻസ് നമ്പർ`,
    "2c0ed1f2f9239dbca46beca549190e00": `വിലാസത്തിന്റെ തെളിവായി ആധാറിനെ എടുക്കും, ആധാറിലെന്നപോലെ ജില്ല തിരഞ്ഞെടുക്കുക.`,
    "8355a53d777c5ac84a37970b5158aba5": `പിശക് 205: ആധാറിലെ നിങ്ങളുടെ പേര് ജാതി അല്ലെങ്കിൽ വരുമാന സർട്ടിഫിക്കറ്റിലെ വിശദാംശങ്ങളുമായി പൊരുത്തപ്പെടുന്നില്ല.`,
    "601f77894e71ae7d4922eda713dda66c": `പിശക് 207: നിങ്ങളുടെ ജാതി, വരുമാന സർട്ടിഫിക്കറ്റ് കാലഹരണപ്പെട്ടു.`,
    "9182b3e8dc7965f2775a204095f4c992": `നിങ്ങൾ നൽകിയ OTP തെറ്റാണ്. ശരിയായ OTP ഉപയോഗിച്ച് വീണ്ടും ശ്രമിക്കുക.`,
    "6e0fe1003e84e34da97a6c4c9040b31b": `അരിവ് വിദ്യാഭ്യാസ വായ്പ പദ്ധതിയുടെ (പുതുക്കൽ) അപേക്ഷ സ്വീകരിക്കുന്നതിനുള്ള അവസാന തീയതി 2022 മാർച്ച് 31 ആയി ഉയർന്നു.`,
    "69d3a9de2fc1407f59e8936ca0c525a0": `ക്ഷമിക്കണം, ഒരു പിശക് സംഭവിച്ചു. പിന്നീട് വീണ്ടും ശ്രമിക്കുക.`,
    "7953c16d691da03c899391a39412fc9b": `നിങ്ങളുടെ സ്കീം അപേക്ഷയിൽ നിങ്ങൾക്ക് തീർപ്പുകൽപ്പിക്കാത്ത പ്രവർത്തനങ്ങളുണ്ട്. നിങ്ങളുടെ അപേക്ഷയുമായി മുന്നോട്ട് പോകുന്നതിന് <i>{{startDate}}</i> മുതൽ തീർപ്പുകൽപ്പിക്കാത്ത പ്രവർത്തനങ്ങൾ പൂർ`,
    "f4f4366332ac6949882de59c81b08de9": `നിങ്ങളുടെ സ്കീം അപേക്ഷയിൽ നിങ്ങൾക്ക് തീർപ്പുകൽപ്പിക്കാത്ത പ്രവർത്തനങ്ങളുണ്ട്. നിങ്ങളുടെ അപേക്ഷയുമായി മുന്നോട്ട് പോകുന്നതിന് <i>{{startDate}}</i> മുതൽ <i>{{endDate}}</i> വരെ തീർച്ചപ്പെടുത്താത്ത പ്രവർത്തനങ്ങൾ പൂർത്തിയാക്കുക.`,
    "77b43c24220ee608380031b72f8017ce":`സേവന വിഭാഗങ്ങൾ`,
    "e0e54c374f8b39a1520766e5924af100": `നിങ്ങളുടെ കുടുംബത്തിനും നിങ്ങൾക്കുമുള്ള സ്കീമുകളും സേവനങ്ങളും ഉപജീവന മാർഗ്ഗങ്ങളും അറിയാനുള്ള ഒരു പ്ലാറ്റ്ഫോം`,
    "d56f6b073f573237a6c4aed91589e982": `ഉപജീവനമാർഗം`,
    "e5930fadbc620e368c92561f381ead58": `നിങ്ങൾക്കുള്ള ഉപജീവന അവസരങ്ങൾ`,
    "192adf39ebd850e3e2e30971e885dc6d": `നിലവിൽ നിങ്ങളുടെ സംസ്ഥാനത്ത് ഉപജീവന അവസരങ്ങളൊന്നും കോൺഫിഗർ ചെയ്തിട്ടില്ല, ഞങ്ങൾ തുടർച്ചയായി അപ്ഡേറ്റ് ചെയ്യുകയാണ്, ദയവായി ഞങ്ങളുമായി വീണ്ടും പരിശോധിക്കുക.`,
    "TM_Alt51": "അറ്റാച്ച്മെന്റ്(കൾ)",
    "TM_Hea1": "മൊത്തം തുക",
    "TM_Hea2": "സംഗ്രഹം",
    "TM_Sub56": "മൊത്തം",
    "TM_But42": "പണം നൽകുക",
    "TM_Mod41":"പണമടയ്ക്കൽ ശേഷിക്കുന്നു",
    "TM_Mod40":"പേയ്‌മെന്റ് വിജയകരമായി",
    "TM_Alt72":"പേയ്‌മെന്റ് പരാജയപ്പെട്ടു",
    "TM_Pag48":"സഹായസഹകരണങ്ങൾ",
    "TM_Con33":"നന്ദി, നിങ്ങളുടെ അപേക്ഷ വിജയകരമായി സമർപ്പിച്ചു.",
    "TM_Err34": "പേയ്‌മെന്റ് പരാജയപ്പെട്ടതിനാൽ നിങ്ങളുടെ അപേക്ഷ സമർപ്പിക്കാനായില്ല.",
    "TM_Rec55": "നിങ്ങളുടെ പേയ്‌മെന്റിന്റെ നില സ്ഥിരീകരിക്കാൻ സമയമെടുക്കുന്നു. ഞങ്ങൾ നിങ്ങളുടെ ബാങ്കുമായി തുടർച്ചയായി പരിശോധിക്കുന്നു. അടുത്ത 48 മണിക്കൂർ നേരത്തേക്ക് അധിക പേയ്‌മെന്റുകൾ നടത്തുന്നത് ഒഴിവാക്കുക.",
    "TM_But13": "തിരിച്ച് പോകൂ",
    "TM_Alt124":"കാത്തിരിക്കൂ",
    "TM_Rec58": "കാത്തിരിക്കൂ. ഞങ്ങൾ നിങ്ങളുടെ പേയ്‌മെന്റ് നില പരിശോധിച്ചുകൊണ്ടിരിക്കുകയാണ്",
    "TM_Rec59": "ഈ സേവനത്തിനുള്ള നിങ്ങളുടെ അവസാന പേയ്‌മെന്റ് തീർപ്പാക്കാത്ത നിലയിലാണ്. പൂർത്തിയാകുമ്പോൾ ഞങ്ങൾ നിങ്ങളെ അറിയിക്കും. അതിനിടയിൽ, ഞങ്ങളുടെ മറ്റ് സേവനങ്ങൾ പര്യവേക്ഷണം ചെയ്യാൻ മടിക്കേണ്ടതില്ല.",
    "TM_Rec60":"നിങ്ങളുടെ ക്ഷമക്ക് നന്ദി.",
    "TM_Rec61":"പേജ് സ്വയമേവ കാലഹരണപ്പെടും",
    "TM_Alt165":"പണമടയ്ക്കാൻ നിങ്ങളുടെ മൊബൈൽ ഉപകരണത്തിന്റെ യുപിഐ ആപ്പിലെ ക്യുആർ കോഡ് സ്കാൻ ചെയ്യുക",
    "TM_Pag39": "ലഭാരതി",
    "TM_Alt166":"പേയ്‌മെന്റ് നിരസിച്ചു",
    "TM_Err39":"പേയ്‌മെന്റ് നിരസിച്ചതിനാൽ നിങ്ങളുടെ അപേക്ഷ സമർപ്പിക്കാൻ കഴിഞ്ഞില്ല.",
    "cc4cbfe72c596683881033f36eac3e2f":"നിങ്ങളുടെ പേയ്‌മെന്റ് ബാങ്കിൽ ഞങ്ങൾ സ്ഥിരീകരിക്കുന്നത് വരെ കാത്തിരിക്കുക. ഈ പ്രക്രിയയ്ക്ക് ഒരു നിമിഷം എടുത്തേക്കാം.",
    "7d54c690a3a707e692cceb6978e24bc1": "ഫീസ് ബ്രേക്കപ്പ്",
}
export default language;