const language = {
    "9e66e6bd4069124578736528a0432752": `ਸਾਡੇ ਬਾਰੇ`,
    "0f5590b58efe9cfcce35e55a66280e59": `ਸਵੀਕਾਰ ਕਰੋ ਅਤੇ ਜਾਰੀ ਰੱਖੋ`,
    "77ab8893d0d282a9f2781d5711883903": `ਸ਼ਾਮਲ ਕਰੋ`,
    "c40eba17959a63e52412cac8c38d467e": `ਪ੍ਰੋਫਾਈਲ ਸ਼ਾਮਲ ਕਰੋ`,
    "ac714b256ab565c91ade9e03e8c410f0": `ਤੁਹਾਡੇ ਸਾਰੇ ਡੇਟਾ ਨੂੰ ਮਿਟਾ ਦਿੱਤਾ ਗਿਆ ਹੈ`,
    "b31b443baac1c6da4cb78dfaf2e5be44": `ਐਪਲੀਕੇਸ਼ਨ ਲਿੰਕ`,
    "0ec157e0e15e12c56bfe1c72e0e7515a": `ਤਬਦੀਲੀਆਂ ਲਾਗੂ ਕਰੋ`,
    "181c1a0ee6db7d7f835eed29791749ab": `ਕੀ ਤੁਸੀਂ ਯਕੀਨਨ ਇਸ ਯੋਜਨਾ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?`,
    "d937652cde254e2a9b93b74a4ee2a02b": `ਕੀ ਤੁਸੀਂ ਨਿਸ਼ਚਤ ਰੂਪ ਵਿੱਚ ਹਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ {{name}} ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?`,
    "3ac1df709254c0e491c642aae5400814": `ਕੀ ਤੁਸੀਂ ਨਿਸ਼ਚਤ ਰੂਪ ਵਿੱਚ ਹਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ {{name}} ਨਾਮ "ਖਾਤੇ?`,
    "1cbb32715ee74ee3447f48febcc04864": `ਕੀ ਤੁਸੀਂ ਯਕੀਨਨ ਇਸ ਸੇਵਾ ਨੂੰ ਆਪਣੀਆਂ ਸੁਰੱਖਿਅਤ ਕੀਤੀਆਂ ਸੇਵਾਵਾਂ ਤੋਂ ਹਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?`,
    "ea4788705e6873b424c65e91c2846b19": `ਰੱਦ ਕਰੋ`,
    "f4ec5f57bd4d31b803312d873be40da9": `ਬਦਲੋ`,
    "6dd495f1c2ae29c8e9a6c4405f18e22e": `ਸਥਾਨ ਬਦਲੋ`,
    "b1ad1d3aef325b23950a43cb82fd0670": `ਭਾਸ਼ਾ ਚੁਣੋ`,
    "714341d9339756588d1dc724991c2b9a": `ਇੱਥੇ ਕਲਿੱਕ ਕਰੋ`,
    "f72b489f19f748cf78a86c78d547ed91": `ਆਪਣੇ ਟਿਕਾਣੇ ਦੀ ਪੁਸ਼ਟੀ ਕਰੋ`,
    "a0bfb8e59e6c13fc8d990781f77694fe": `ਜਾਰੀ ਰੱਖੋ`,
    "bee04ef1315b3f9562be34e2e28a7831": `ਖਾਤਾ ਮਿਟਾਓ`,
    "3e9983cf1885a5ec9f5a5d8127137bd2": `ਪ੍ਰੋਫਾਈਲ ਮਿਟਾਓ`,
    "2ac7a924af94bf173f38eb31165efce3": `ਡਿਲੀਟ ਮਿਟਾਓ`,
    "e0bb2f66e5b61be3235bf8969979de19": `ਜ਼ਿਲ੍ਹਾ`,
    "46a43a7757337712d4f6a3701a5c57dd": `ਡਾਉਨਲੋਡ ਫਾਰਮ`,
    "4d7be8ac1b9cb2fd5843dbea7ce30344": `ਤੁਸੀਂ ਅਜੇ ਕਿਸੇ ਸੇਵਾ ਨੂੰ ਸੁਰੱਖਿਅਤ ਨਹੀਂ ਕੀਤਾ ਹੈ`,
    "edda2208040fe25b06d0d8f3af976651": `ਤੁਸੀਂ ਕਿਸੇ ਵੀ ਨੌਕਰੀ ਨੂੰ ਨਹੀਂ ਬਚਾਇਆ`,
    "f283a3e62b2809c07cf77075103c012f": `ਤੁਹਾਡੇ ਲਈ ਸਭ ਤੋਂ ਵਧੀਆ ਸਰਕਾਰੀ ਭਲਾਈ ਸਕੀਮਾਂ, ਸੇਵਾਵਾਂ ਅਤੇ ਨੌਕਰੀਆਂ ਲੱਭਣ ਲਈ ਸਾਨੂੰ ਕੁਝ ਮੁੱਢਲੀ ਪ੍ਰੋਫਾਈਲ ਜਾਣਕਾਰੀ ਦੀ ਲੋੜ ਹੈ।`,
    // "f283a3e62b2809c07cf77075103c012f":`ਲਾਭਾਰਥੀ ਨੂੰ ਸਭ ਤੋਂ ਵਧੀਆ ਸਰਕਾਰੀ ਭਲਾਈ ਸਕੀਮਾਂ ਲੱਭਣ ਲਈ ਕੁਝ ਬੁਨਿਆਦੀ ਪ੍ਰੋਫਾਈਲ ਜਾਣਕਾਰੀ ਦੀ ਲੋੜ ਹੈ`,
    "ce8ae9da5b7cd6c3df2929543a9af92d": `ਈ - ਮੇਲ`,
    "01e8b0c4e059b594202e976860b2d481": `ਆਪਣੀ ਭਾਸ਼ਾ ਵਿੱਚ ਸਕੀਮਾਂ ਅਤੇ ਸੇਵਾਵਾਂ ਦੀ ਪੜਚੋਲ ਕਰੋ`,
    "483a5da192f46e3cfe8391d3ef234fc4": `ਅਕਸਰ ਪੁੱਛੇ ਜਾਂਦੇ ਸਵਾਲ`,
    "37aaf54433bbfe989db8871121fe0ad8": `ਸਾਡੇ ਪਿਛੇ ਆਓ:`,
    "e0c4332e8c13be976552a059f106354f": `ਸ਼ੁਰੂ ਕਰੋ`,
    "48dee7c291ac7f19e499438b072da336": `ਸਰਕਾਰੀ ਫੀਸ`,
    "2ceb32552d78024d91a123897636afcb": `EasyGov ਦੇ ਨਾਗਰਿਕ ਸ਼ਕਤੀਕਰਨ ਐਪ`,
    //"357aa540fd5c8ae584ba18359105de3d":`ਕੀ ਤੁਹਾਨੂੰ ਕਿਸੇ ਵੀ ਯੋਜਨਾਵਾਂ ਜਾਂ ਕਿਸੇ ਹੋਰ ਚੀਜ਼ ਬਾਰੇ ਕੋਈ ਪ੍ਰਸ਼ਨ ਹੈ?`,
    "357aa540fd5c8ae584ba18359105de3d": `ਜੇ ਤੁਹਾਨੂੰ ਕੋਈ ਪੁੱਛਗਿੱਛ ਹੈ ਤਾਂ ਸਾਨੂੰ ਦੱਸੋ ਅਤੇ ਅਸੀਂ 24 ਕਾਰੋਬਾਰੀ ਘੰਟਿਆਂ ਦੇ ਅੰਦਰ ਤੁਹਾਡੇ ਕੋਲ ਵਾਪਸ ਆਵਾਂਗੇ.`,
    "b3ba00d60ff5a9e11c15a6efbed13746": `ਆਪਣੇ ਮੌਜੂਦਾ ਪ੍ਰੋਫਾਈਲ ਨੂੰ ਅਪਡੇਟ ਕਰਨ ਲਈ "ਮੌਜੂਦਾ ਪ੍ਰੋਫਾਈਲ ਅਪਡੇਟ ਕਰੋ" ਤੇ ਕਲਿਕ ਕਰੋ ਅਤੇ ਨਵਾਂ ਪ੍ਰੋਫਾਈਲ ਸ਼ਾਮਲ ਕਰੋ "ਪ੍ਰੋਫਾਈਲ ਸ਼ਾਮਲ ਕਰੋ" ਦੀ ਚੋਣ ਕਰੋ`,
    "12ceff2290bb9039beaa8f36d5dec226": `ਨੌਕਰੀਆਂ`,
    "5eb57a6678ca337f92884f49b578a908": `...`,
    "4994a8ffeba4ac3140beb89e8d41f174": `ਭਾਸ਼ਾ`,
    "846eb834b6bfe81dbd412bc010f8cac7": `ਸਾਨੂੰ ਦੱਸੋ ਅਤੇ ਅਸੀਂ 24 ਕਾਰੋਬਾਰੀ ਘੰਟਿਆਂ ਦੇ ਅੰਦਰ ਤੁਹਾਡੇ ਕੋਲ ਵਾਪਸ ਆਵਾਂਗੇ`,
    "a58252e21aaf876bf7b64c05cce1c477": `ਲਿੰਕ ਨਕਲ`,
    "ce5bf551379459c1c61d2a204061c455": `ਟਿਕਾਣਾ`,
    "ccffe10fe854018805baf0d3d9327285": `ਪ੍ਰੋਫਾਈਲ ਦਾ ਪ੍ਰਬੰਧਨ ਕਰੋ`,
    "49ee3087348e8d44e1feda1917443987": `ਨਾਮ`,
    "10ac3d04253ef7e1ddc73e6091c0cd55": `ਅਗਲਾ`,
    "bafd7322c6e97d25b6299b5d6fe8920b": `ਨਹੀਂ`,
    "31916d996c9f53949315692bc897f0c7": `ਢੁਕਵਾਂ ਨਹੀਂ? ਦੇਖਣਾ ਜਾਰੀ ਰੱਖੋ`,
    "e81c4e4f2b7b93b481e13a8553c2ae1b": `ਜਾਂ`,
    "0d5e50bf1643118ee026e5d19226542a": `ਕਿਰਪਾ ਕਰਕੇ ਸਪੈਲਿੰਗ ਦੀ ਜਾਂਚ ਕਰੋ ਜਾਂ ਕਿਸੇ ਹੋਰ ਚੀਜ਼ ਦੀ ਭਾਲ ਕਰਨ ਦੀ ਕੋਸ਼ਿਸ਼ ਕਰੋ`,
    "64ed408b000d73143e6ab1b5acb7c26e": `ਕਿਰਪਾ ਕਰਕੇ ਪ੍ਰੋਫਾਈਲ ਦੀ ਚੋਣ ਕਰੋ`,
    "fa2ead697d9998cbc65c81384e6533d5": `ਪਰਾਈਵੇਟ ਨੀਤੀ`,
    "7595f48afc2f088bc1ff3313ceeb3605": `ਲਾਗੂ ਕਰਨ ਦੀ ਵਿਧੀ`,
    "cce99c598cfdb9773ab041d54c3d973a": `ਪਰੋਫਾਈਲ`,
    "665d0095958d696322be90e4eb606977": `ਇਸ ਰਿਸ਼ਤੇ ਦੇ ਨਾਲ ਪ੍ਰੋਫਾਈਲ ਪਹਿਲਾਂ ਹੀ ਮੌਜੂਦ ਹੈ. ਤੁਸੀਂ ਮੌਜੂਦਾ ਪ੍ਰੋਫਾਈਲ ਨੂੰ ਅਪਡੇਟ ਕਰ ਸਕਦੇ ਹੋ`,
    "27bcc51421612805a9c006f24a4d6dc6": `ਸਕੀਮ ਨੂੰ ਹਟਾਓ`,
    "f8b66e3c488bb1df16cb3c7bb21447f0": `ਲੋੜੀਂਦੇ ਦਸਤਾਵੇਜ਼`,
    "82609dd1953ccbfbb4e0d20623193b56": `ਘਰ ਵਾਪਸ ਆਓ`,
    "c9cc8cce247e49bae79f15173ce97354": `ਸੇਵ`,
    "c320cdeffafbb2d60d6d759619c6b5e2": `ਪ੍ਰੋਫਾਈਲ ਸੇਵ ਕਰੋ`,
    "ab9753c948520fb02c20831980bad53a": `ਮੇਰੀਆਂ ਯੋਜਨਾਵਾਂ`,
    "827452688eed02a12178e96f924ac529": `ਸਕੀਮ`,
    "cb80cf588ba5a0d3dc067b0b0ef219e9": `ਯੋਜਨਾਵਾਂ`,
    "1925ba54f16355af0964df7dfa145761": `ਤੁਹਾਡੇ ਲਈ ਯੋਜਨਾਵਾਂ`,
    "13348442cc6a27032d2b4aa28b75a5d3": `ਖੋਜ`,
    "32df01b9cf0491a879250b58ba2744ba": `ਖੋਜ ਨਤੀਜੇ`,
    // "988cddb5e417156bd3603983dc0c32e5":`ਖੋਜ ਸਕੀਮਾਂ`,
    "0afcf4c1ba72c441b41fbafaab253fc6": `ਟਿਕਾਣਾ ਚੁਣੋ`,
    "be2933038f93e25262400220080c111f": `ਰਾਜ ਦੀ ਚੋਣ ਕਰੋ`,
    "dfe1d316ec9338e23c892b573667b9bb": `ਆਪਣਾ ਵਿਕਲਪ ਚੁਣੋ`,
    "c2ba7e785c49050f48da9aacc45c2b85": `ਸੇਵਾ`,
    "992a0f0542384f1ee5ef51b7cf4ae6c4": `ਸੇਵਾਵਾਂ`,
    "48ecd536efbb3622296aac8de326fa38": `ਮੁਆਫ ਕਰਨਾ, ਪ੍ਰਦਾਨ ਕੀਤੀ ਜਾਣਕਾਰੀ ਦੇ ਅਧਾਰ ਤੇ, ਤੁਸੀਂ ਸਕੀਮ ਲਈ ਯੋਗ ਨਹੀਂ ਹੋ`,
    "4cd9a992dc66802a4b44c366881957d1": `ਮਾਫ ਕਰਨਾ, ਕੋਈ ਨਤੀਜਾ ਨਹੀਂ ਮਿਲਿਆ`,
    "f86301756ec6964cb96e2b80a38f4162": `ਮਾਫ ਕਰਨਾ! ਅਸੀਂ ਤੁਹਾਡੇ ਲਈ ਕੋਈ ਮੇਲ ਖਾਂਦੀਆਂ ਯੋਜਨਾਵਾਂ ਨਹੀਂ ਲੱਭ ਸਕੇ`,
    "46a2a41cc6e552044816a2d04634545d": `ਰਾਜ`,
    "efd9a0ba1e9d553a070028627fdcae18": `ਸਰਵੇਖਣ ਵੇਰਵੇ`,
    "4cd61af85d0ec40f50349cd7c3054ace": `ਸਵਿੱਚ ਪ੍ਰੋਫਾਈਲ`,
    "1d8cd2bfc6943dcf70236f7d351572a0": `ਨਿਯਮ ਅਤੇ ਸ਼ਰਤਾਂ`,
    "4f7e556abaf92ce8061ad57cc4e7edd1": `ਇਹ ਜ਼ਰੂਰੀ ਹੈ`,
    "371999537b8bb6e7f0099b0c40e0e41d": `ਇਹ ਪ੍ਰੋਫਾਈਲ ਪਹਿਲਾਂ ਹੀ ਬਣਾਇਆ ਗਿਆ ਹੈ. ਤੁਸੀਂ ਪ੍ਰੋਫਾਈਲ ਨੂੰ ਅਪਡੇਟ ਨਹੀਂ ਕਰ ਸਕਦੇ.`,
    "9eed21a5afd3ba6841c523b0d70599c7": `ਯੋਜਨਾਵਾਂ ਨੂੰ 2 ਤੋਂ ਘੱਟ ਦੇ ਨਾਲ ਜਾਂ 3 ਤੋਂ ਵੱਧ ਦੇ ਨਾਲ ਇੱਕ ਸਰਵੇਖਣ ਚਲਾਉਣ ਲਈ ਕਿਸੇ ਵੀ ਸ਼੍ਰੇਣੀ ਤੇ ਕਲਿਕ ਕਰਨ ਲਈ`,
    "37528894f18a085f9a078496a68eaaa8": `ਰੁਝਾਨ`,
    "d876a9fe1aecb3ffc05a5639ebe22000": `ਫਿਰ ਕੋਸ਼ਿਸ਼ ਕਰੋ`,
    "06933067aafd48425d67bcb01bba5cb6": `ਅਪਡੇਟ`,
    "fba7f6c863513d97922faab074d3c716": `ਪ੍ਰੋਫਾਈਲ ਅਪਡੇਟ ਕਰੋ`,
    "90b1f6651953fcf8b43ddc0da6760aaf": `ਉਪਭੋਗਤਾ ਪ੍ਰੋਫਾਈਲ`,
    "d8d7ad6abfd08c27baa8bedb78c72ddb": `ਸਭ ਵੇਖੋ`,
    "acac138f2e6f039df2e1fed2f2c0bc44": `EasyGov ਵਿੱਚ ਤੁਹਾਡਾ ਸੁਆਗਤ ਹੈ`,
    "6ebb2e31989c843ea65feefa43237dad": `ਜਦੋਂ ਤੁਸੀਂ ਆਪਣੇ ਖਾਤੇ ਤੋਂ ਪ੍ਰੋਫਾਈਲ ਮਿਟਾਉਂਦੇ ਹੋ, ਤਾਂ ਤੁਸੀਂ ਉਸ ਖਾਸ ਪ੍ਰੋਫਾਈਲ ਲਈ ਸਟੋਰ ਕੀਤੇ ਕਿਸੇ ਵੀ ਡੇਟਾ ਜਾਂ ਜਾਣਕਾਰੀ ਨੂੰ ਪ੍ਰਾਪਤ ਨਹੀਂ ਕਰ ਸਕੋਗੇ. ਚੁਣੇ ਹੋਏ ਪ੍ਰੋਫਾਈਲ ਲਈ ਸਾਰੇ ਕਿਰਿਆਸ਼ੀਲ ਅਤੇ ਸੇਵ ਕੀਤੀਆਂ ਯੋਜਨਾਵਾਂ ਨੂੰ ਮਿਟਾ ਦਿੱਤਾ ਜਾਏਗਾ`,
    "6a64fbe7b065fc8579f2521e0054de02": `ਜਦੋਂ ਤੁਸੀਂ ਆਪਣੇ ਲਭਾਰਥੀ ਖਾਤੇ ਨੂੰ ਮਿਟਾਉਂਦੇ ਹੋ, ਤਾਂ ਤੁਸੀਂ ਆਪਣੇ ਪ੍ਰੋਫਾਈਲ ਅਤੇ ਤੁਹਾਡੇ ਖਾਤੇ ਨਾਲ ਜੁੜੇ ਹੋਰ ਪ੍ਰੋਫਾਈਲਾਂ ਅਤੇ ਹੋਰ ਪ੍ਰੋਫਾਈਲਾਂ ਵਿੱਚ ਸਟੋਰ ਕੀਤੇ ਕੋਈ ਵੀ ਡੇਟਾ ਜਾਂ ਜਾਣਕਾਰੀ ਪ੍ਰਾਪਤ ਨਹੀਂ ਕਰ ਸਕੋਗੇ. ਤੁਹਾਡੀਆਂ ਸਾਰੀਆਂ ਕਿਰਿਆਵਾਂ ਅਤੇ ਸੇਵ ਕੀਤੀਆਂ ਸਕੀਮਾਂ ਵੀ ਮਿਟਾਈਆਂ ਜਾਣਗੀਆਂ`,
    "93cba07454f06a4a960172bbd6e2a435": `ਹਾਂ`,
    "3aeee34bea95f2b0ced72be632778670": `ਤੁਸੀਂ ਕਿਸੇ ਵੀ ਯੋਜਨਾਵਾਂ ਨੂੰ ਸੁਰੱਖਿਅਤ ਨਹੀਂ ਕੀਤਾ ਹੈ`,
    "cb32c835ee043e0e2faf4860e46669ae": `ਇਹ ਯੋਜਨਾ / ਸੇਵਾ ਸਮੀਖਿਆ ਅਧੀਨ ਹੈ. ਕਿਰਪਾ ਕਰਕੇ ਕਿਸੇ ਸਮੇਂ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ.`,
    "c2e0ede88489afe5739c8973aa03a787": `ਤੁਹਾਡੀ ਪ੍ਰੋਫਾਈਲ ਨਾਲ ਮੇਲ ਕਰਨ ਵਾਲੀਆਂ ਯੋਜਨਾਵਾਂ ਨਹੀਂ ਹਨ`,
    "28d2dfa9b435bd81cb2f7f0499b846b3": `ਸਕੀਮਾਂ ਲੱਭੋ`,
    "52561ab2d4640b4e299e817b6ba79173": `ਲਭਾਰਥੀ ਵਿੱਚ ਤੁਹਾਡਾ ਸਵਾਗਤ ਹੈ`,
    "fb0fc4b5fdb2bef7b1c42f2cfd523c2d": `ਕੋਸ਼ਿਸ਼ਾਂ ਬਚੀਆਂ`,
    "e13e1cd5f02de37413f9497cc5b65de3": `ਤੁਹਾਡਾ ਖਾਤਾ 30 ਮਿੰਟ ਲਈ ਲੌਕ ਕਰ ਦਿੱਤਾ ਗਿਆ ਹੈ. ਕਿਰਪਾ ਕਰਕੇ ਉਸ ਸਮੇਂ ਤੋਂ ਬਾਅਦ ਕੋਸ਼ਿਸ਼ ਕਰੋ`,
    "f2a6c498fb90eebdu72k7f888fce3b18": `ਜੀ ਆਇਆਂ ਨੂੰ`,
    "1172b2ea87bed4861c8c43a201d53031": `ਮੇਰੀਆਂ ਸੇਵਾਵਾਂ`,
    "bcc254b55c4a1babdf1dcb82c207506b": `ਫੋਨ`,
    "8458bad978f4fd6d6c7f8ec2ec0824b4": `ਪਾਸਵਰਡ ਭੁੱਲ ਗਏ?`,
    "d67850bd126f070221dcfd5fa6317043": `ਸਾਇਨ ਅਪ`,
    "619d59e2ea9f9bb9fe287404dd3ca931": `ਸਾਰੀਆਂ ਸੇਵਾਵਾਂ`,
    "6b46ae48421828d9973deec5fa9aa0c3": `ਕ੍ਰਮਬੱਧ`,
    "104f0a6879399d6285ab770ed0dbc257": `ਸੇਵਾ ਹਟਾਓ`,
    "0b3937a9dd481d527fcc72adaa735b57": `ਲਭਾਰਥੀ ਖਾਤਾ ਨਹੀਂ ਹੈ?`,
    "2f453cfe638e57e27bb0c9512436111e": `ਜਾਰੀ ਰੱਖੋ`,
    "f2fdea440d768b85591e936a803c3631": `ਸਾਈਨ - ਇਨ`,
    "82b1fbd43ae27918a37b212a4f60a9ad": `ਜਮ੍ਹਾਂ ਕਰੋ`,
    "31693e2e9aa2e5be1bf3ae45ede9c98d": `ਯੋਜਨਾਵਾਂ ਤੁਸੀਂ ਯੋਗ ਨਹੀਂ ਹੋ`,
    "d43cd6a49673f0f486ed7fbea1a5fd8a": `ਆਧਾਰ ਨੰਬਰ`,
    "3a06727e678189b8502408cb9b50b437": `ਪ੍ਰੋਫਾਈਲ ਬਦਲੋ`,
    "0323de4f66a1700e2173e9bcdce02715": `ਲਾੱਗ ਆਊਟ, ਬਾਹਰ ਆਉਣਾ`,
    "644cd55dbe4d01e7e00fc7fc458f9b8f": `ਕੀ ਤੁਸੀਂ ਯਕੀਨਨ ਲਾਗਆਉਟ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?`,
    "c0fb5959a7a8d3ea991f9edb0b1a9e85": `ਕਿਰਪਾ ਕਰਕੇ ਆਈਡੀ ਚੁਣੋ, ਅਸੀਂ ਤੁਹਾਡੇ ਲਈ ਸਕੀਮਾਂ ਪ੍ਰਾਪਤ ਕਰਾਂਗੇ`,
    "827a37fb93758383027c582a39ec6162": `ਦੀ ਕਿਸਮ ਆਈਡੀ`,
    "cb3441fa3e1291a76dd5ea72e656bca3": `ਬਿਨਾਂ ਕਿਸੇ ਆਈਡੀ ਦੇ ਜਾਰੀ ਰੱਖੋ`,
    "99dea78007133396a7b8ed70578ac6ae": `ਲਾਗਿਨ`,
    "02aeaf2672eb06861c144004ef2248d2": `ਲਾਗਇਨ ਖਾਤਾ`,
    "d4d208eb2d8ccd525a64be73f3521dc5": `ਗਲਤ ਪ੍ਰਮਾਣ ਪੱਤਰ`,
    "e8055d9577ec8edf34ee15a88a937daa": `ਕੋਈ ਖਾਤਾ ਨਹੀਂ ਹੈ?`,
    "942a3ef743c4e176f75d5223455f7e1a": `ਆਪਣਾ 10 ਅੰਕ ਦਾ ਫੋਨ ਨੰਬਰ ਦਰਜ ਕਰੋ`,
    "21bdc5689c12595ae14298354d5550d5": `ਪਹਿਲਾਂ ਹੀ ਕੋਈ ਖਾਤਾ ਹੈ?`,
    "dc647eb65e6711e155375218212b3964": `ਪਾਸਵਰਡ`,
    "0b3e8b8021c9b2fafee35ae01ac2cfb9": `${window.projectName} ਐਪ ਤੁਹਾਡੇ ਅਤੇ ਤੁਹਾਡੇ ਪਰਿਵਾਰ ਲਈ ਸਭ ਤੋਂ ਵਧੀਆ ਸਰਕਾਰੀ ਭਲਾਈ ਸਕੀਮਾਂ ਲੱਭਣ ਵਿੱਚ ਤੁਹਾਡੀ ਮਦਦ ਕਰੇਗੀ।`,
    "05aca668fa6754247f5094c042fb64ac": `ਫੋਨ ਦੀ ਤਸਦੀਕ`,
    "f9bc92a6602b9692a0ba578120c9c441": `ਆਪਣਾ OTP ਕੋਡ ਇਥੇ ਦਰਜ ਕਰੋ`,
    "d9d1e60e50119d9752001d4196ee6b3c": `OTP`,
    "6e95a24fa2a98c2f59c96a24b182ccfd": `ਕੀ ਤੁਹਾਨੂੰ ਕੋਈ ਕੋਡ ਪ੍ਰਾਪਤ ਨਹੀਂ ਹੋਇਆ?`,
    "f922096c878b694ec7a3d9282a55904c": `ਨਵੇਂ ਕੋਡ ਨੂੰ ਦੁਬਾਰਾ ਭੇਜੋ`,
    "32b830cef750168335d96aff4dd98782": `ਮੇਰੀਆਂ ਨੌਕਰੀਆਂ`,
    "bbd26bc8ce3623addc9c54c36f692f8d": `ਤੁਹਾਡੇ ਲਈ ਨੌਕਰੀਆਂ`,
    "67cb88ed21d4232c4681a5e648554e09": `ਐਪਲੀਕੇਸ਼ਨ ਫੀਸ`,
    "b90455b71eb5e2399be9d20e23c1a1db": `ਅਰਜ਼ੀ ਦੇਣ ਲਈ ਆਖਰੀ ਤਾਰੀਖ`,
    "bfe72f18052c940de13501ae9877c3b0": `ਅਰਜ਼ੀ ਕਿਵੇਂ ਦੇਣੀ ਹੈ`,
    "4f1f44ef7ce0c8a111f33fe400a6e4d7": `ਚੋਣ ਵਿਧੀ`,
    "0f5679acafa674831691cfa01d17501e": `ਨੌਕਰੀ ਨੋਟੀਫਿਕੇਸ਼ਨ`,
    "a45ca50bcc7830af2f84fd046648ec92": `ਤੁਹਾਡੇ ਪ੍ਰੋਫਾਈਲ ਨਾਲ ਮੇਲ ਨਹੀਂ ਖਾਂਦਾ`,
    "c4bcece293eb1ad7f09d4cf9c2037ea6": `ਨੌਕਰੀਆਂ ਲੱਭੋ`,
    "5dc52ca9ffdc26147075682c92a0a853": `ਪੋਸਟ`,
    "3f99ba6fbcb46f28fb24f271fc3c02c1": `ਨੌਕਰੀਆਂ ਦੇਖਣ ਲਈ ਕਿਸੇ ਵੀ ਸ਼੍ਰੇਣੀ ਤੇ ਕਲਿਕ ਕਰੋ. ਕਿਸੇ ਵੀ ਨੌਕਰੀ ਲਈ ਕਿਸੇ ਵੀ ਨੌਕਰੀ ਨੂੰ ਚਲਾਉਣ ਲਈ ਕਾਰਡ ਤੇ ਕਲਿਕ ਕਰੋ`,
    "2078989a6c09fdd98efc35d3c1d1de3d": `ਨੌਕਰੀ ਨੂੰ ਮਿਟਾਓ`,
    "08c1517355f6e7313c4d629a193ac69a": `ਕੀ ਤੁਸੀਂ ਯਕੀਨਨ ਇਸ ਨੌਕਰੀ ਨੂੰ ਆਪਣੀਆਂ ਸੁਰੱਖਿਅਤ ਕੀਤੀਆਂ ਨੌਕਰੀਆਂ ਤੋਂ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?`,
    "e614dff8cba6f73ae7cee729fe0091dd": `ਅਵੈਧ OTP`,
    "c46c1e69c89811f03dc73feb09273409": `OTP ਲੋੜੀਂਦਾ ਹੈ`,
    "ae3bb2a1ac61750150b606298091d38a": `ਨਵਾਂ ਪਾਸਵਰਡ`,
    "887f7db126221fe60d18c895d41dc8f6": `ਪਾਸਵਰਡ ਪੱਕਾ ਕਰੋ`,
    "70d9be9b139893aa6c69b5e77e614311": `ਪੁਸ਼ਟੀ ਕਰੋ`,
    "20aedd1e6de4dcf8d115b5a7424c58d7": `ਪਾਸਵਰਡ ਲੋੜੀਂਦਾ ਹੈ.`,
    "841a389ec69697c28df7b9739419d21d": `ਪਾਸਵਰਡ 6 ਅੱਖਰ ਲੰਬਾ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ.`,
    "bcb9407d90fd17ccff50587f5edfe987": `ਪਾਸਵਰਡ ਮੇਲ ਨਹੀਂ ਖਾਂਦਾ.`,
    "318721657b639c8ca02293211db68837": `ਫੋਨ ਨੰਬਰ ਲੋੜੀਂਦਾ ਹੈ`,
    "4ea3456b0e1b463f50b81c79230cf070": `ਗਲਤ ਫੋਨ ਨੰਬਰ`,
    "bd454fc3d32f4425d146d3e861409871": `ਵਰਤੋਂ ਕਰਨ ਵਾਲਾ ਪਹਿਲਾਂ ਤੋਂ ਮੌਜੂਦ`,
    "ab48b978d197e315e92e904e36d26175": `ਫੋਨ ਨੰਬਰ ਅਵੈਧ ਹੈ`,
    "70729403b9560d3adb142977608b81d2": `OTP ਸੀਮਾ ਵਧ ਗਈ. 4 ਘੰਟੇ ਬਾਅਦ ਕੋਸ਼ਿਸ਼ ਕਰੋ.`,
    "1e4dbc7eaa78468a3bc1448a3d68d906": `ਫੋਨ ਨੰਬਰ`,
    "4f2df4c330b8df125a3d75f2d69a9b5e": `OTP ਭੇਜੋ`,
    "9ec54ec336b65da6179cf750de33ecd7": `ਪਾਸਵਰਡ ਭੁੱਲ ਗਏ`,
    "98adc700c19f071f07a714a703a6fc1e": `ਆਈਡੀ ਵੇਰਵੇ`,
    "f262fae0a2f17e8c1eb4e9eddb0160e4": `ਅੱਗੇ ਵਧੋ`,
    "49fb619c4872c491fae649c28b339ed8": `ਤੁਹਾਡੇ ਪ੍ਰਮਾਣ ਪੱਤਰ ਸਾਡੇ ਰਿਕਾਰਡਾਂ ਨਾਲ ਮੇਲ ਨਹੀਂ ਖਾਂਦੇ`,
    "58557a71f9811899313234decda72d5a": `ਤੁਹਾਡਾ ਖਾਤਾ 30 ਮਿੰਟ ਲਈ ਲੌਕ ਕਰ ਦਿੱਤਾ ਗਿਆ ਹੈ. ਕਿਰਪਾ ਕਰਕੇ ਉਸ ਸਮੇਂ ਦੇ ਬਾਅਦ ਕੋਸ਼ਿਸ਼ ਕਰੋ.`,
    "e7665f483813a56e9410787ce2d174ef": `ਫੋਨ ਨੰਬਰ ਪਹਿਲਾਂ ਹੀ ਵਰਤੋਂ ਅਧੀਨ ਹੈ. ਸਾਈਨ ਅਪ ਕਰਨ ਲਈ ਕਿਰਪਾ ਕਰਕੇ ਸਾਈਨ ਇਨ ਕਰੋ ਜਾਂ ਹੋਰ ਨੰਬਰ ਦੀ ਵਰਤੋਂ ਕਰੋ.`,
    "0a46fa5fb9e86e80fc94e229d1967699": `ਫੋਨ ਨੰਬਰ ਰਜਿਸਟਰਡ ਨਹੀਂ ਹੈ. ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ`,
    "bdff8b1cbfb4d5623382d7b0678e707c": `ਪਾਸਵਰਡ ਵਿੱਚ ਘੱਟੋ ਘੱਟ 8 ਅੱਖਰ ਹੋਣੇ ਚਾਹੀਦੇ ਹਨ, ਜਿਸ ਵਿੱਚ ਘੱਟੋ ਘੱਟ ਇੱਕ ਵੱਡੇ ਕੇਸਾਂ ਵਿੱਚ, ਇੱਕ ਛੋਟੇ ਕੇਸ, ਇੱਕ ਨੰਬਰ ਅਤੇ ਇੱਕ ਵਿਸ਼ੇਸ਼ ਅੱਖਰ`,
    "d55085482b3e8da780755832c17d7d60": `ਆਖਰੀ ਤਾਰੀਖ`,
    "988cddb5e417156bd3603983dc0c32e5": `ਖੋਜ ਸਕੀਮ ਅਤੇ ਸੇਵਾਵਾਂ`,
    "261b39077b81875e115baa22bc6ce04c": `ਨੌਕਰੀਆਂ ਵੇਖਣ ਲਈ ਕਿਸੇ ਵਿਸ਼ੇਸ਼ ਸ਼੍ਰੇਣੀ ਨਾਲ ਸਬੰਧਤ ਹੈ, ਕਿਰਪਾ ਕਰਕੇ ਸ਼੍ਰੇਣੀ ਤੇ ਕਲਿਕ ਕਰੋ`,
    "bdb53070a3fc01196ae38b059a4b42ff": `ਨੌਕਰੀ ਹਟਾਓ`,
    "ede1fcad3d6bf808fc948df7d22e1536": `ਮਾਫ ਕਰਨਾ! ਅਸੀਂ ਤੁਹਾਡੇ ਲਈ ਕੋਈ ਮੇਲ ਖਾਂਦੀਆਂ ਨੌਕਰੀਆਂ ਨਹੀਂ ਲੱਭ ਸਕੇ`,
    "0c4fa7a0201f434b0ef84494d0aac07a": `ਮੁਆਫ ਕਰਨਾ, ਪ੍ਰਦਾਨ ਕੀਤੀ ਜਾਣਕਾਰੀ ਦੇ ਅਧਾਰ ਤੇ, ਤੁਸੀਂ ਨੌਕਰੀ ਲਈ ਯੋਗ ਨਹੀਂ ਹੋ`,
    "e4defbb7cdf529fa3606749a82c06932": `ਪੋਸਟ`,
    "25d2401d71f4ba1ba7685559c1b77927": `ਤੁਹਾਡੀ ਪ੍ਰੋਫਾਈਲ ਦੇ ਅਧਾਰ ਤੇ, ਅਸੀਂ ਤੁਹਾਨੂੰ ਕਿਸੇ ਨੌਕਰੀ ਲਈ ਯੋਗ ਨਹੀਂ ਲੱਭਿਆ. <br> ਅਸੀਂ ਲਗਾਤਾਰ ਵਧੇਰੇ ਨੌਕਰੀਆਂ ਜੋੜ ਰਹੇ ਹਾਂ, ਕਿਰਪਾ ਕਰਕੇ ਬਾਅਦ ਵਿੱਚ ਚੈੱਕ ਕਰੋ.`,
    "ea7c2400c0ae5f58608cb5051ee852f3": `ਇਸ ਸਮੇਂ ਤੁਹਾਡੇ ਰਾਜ ਵਿੱਚ ਤੁਹਾਡੀ ਰਾਜ ਵਿੱਚ ਕੌਂਫਿਗਰ ਕੀਤੇ ਕੋਈ ਕੰਮ ਨਹੀਂ ਹਨ, ਅਸੀਂ ਨਿਰੰਤਰ ਅਪਡੇਟ ਕਰ ਰਹੇ ਹਾਂ, ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਦੁਬਾਰਾ ਜਾਂਚ ਕਰੋ`,
    "24fe48030f7d3097d5882535b04c3fa8": `ਮਿਆਦ ਪੁੱਗ ਗਈ`,
    "9dc0186e2d5d3398ffee6ec964b15dc0": `ਮਿਆਦ ਪੁੱਗਣ ਦੀ ਮਿਤੀ ਤੋਂ 30 ਦਿਨਾਂ ਦੀ ਮਿਆਦ ਤੋਂ ਬਾਅਦ ਸਾਰੀਆਂ ਮਿਆਦ ਪੁੱਗੀਆਂ ਨੌਕਰੀਆਂ ਨੂੰ ਤੁਹਾਡੀਆਂ "ਮੇਰੇ ਨੌਕਰੀਆਂ" ਤੋਂ ਹਟਾ ਦਿੱਤਾ ਜਾਵੇਗਾ.`,
    "34a934c0c86d866df9d14f8a6e90c637": `ਗੈਰ ਯੋਗ ਦਾ ਕਾਰਨ`,
    "a97ea56b0e00b2379736ae60869ff66a": `ਸਵਾਲ`,
    "8e9e3212461f14c8dbea6456c920bcbc": `ਯੋਗ ਮੁੱਲ`,
    "c4dce677291fa5bc05276ffe93c5d8f2": `ਤੁਹਾਡੇ ਦੁਆਰਾ ਦਿੱਤਾ ਮੁੱਲ`,
    "9a917c4a4f546c6072bd61ae617763bf": `ਸਕੀਮ ਤੁਹਾਡੇ ਲਈ ਨਹੀਂ`,
    "e57c13893a290ce882569b5638ba7b82": `ਤੁਹਾਡੇ ਦੁਆਰਾ ਦਾਖਲ ਕੀਤੀ ਗਈ ਆਈਡੀ ਨਹੀਂ ਲੱਭੀ. ਮੁੜ ਕੋਸ਼ਿਸ ਕਰੋ ਜੀ.`,
    "e26859a59ede4ab8d8ea0213b68fa732": `ਸਰੋਤ ਪ੍ਰਣਾਲੀ ਇਸ ਸਮੇਂ ਜਵਾਬ ਨਹੀਂ ਦੇ ਰਹੀ ਹੈ, ਕਿਰਪਾ ਕਰਕੇ ਕਿਸੇ ਸਮੇਂ ਕੋਸ਼ਿਸ਼ ਕਰੋ.`,
    // "e26859a59ede4ab8d8ea0213b68fa732":`ਸਰੋਤ ਪ੍ਰਣਾਲੀ ਇਸ ਸਮੇਂ ਕੋਈ ਜਵਾਬ ਨਹੀਂ ਦੇ ਰਹੀ ਹੈ, ਕਿਰਪਾ ਕਰਕੇ ਬਿਨਾ ਆਈਡੀ ਬਿਨਾਂ ਜਾਰੀ ਰੱਖੋ ਜਾਂ ਕਿਸੇ ਸਮੇਂ ਕੋਸ਼ਿਸ਼ ਕਰੋ.`,
    "d2235c7ecbbbae1bf62833dade7486f5": `ਪ੍ਰੋਫਾਈਲ ਦੇ ਵੇਰਵੇ ਸੁਨਹਿਰੀ ਰਿਕਾਰਡ ਤੋਂ ਲਿਆ ਗਿਆ ਹੈ.`,
    "346df6b73ae19a6619f26648cf415522": `ਅਯੋਗ ਸਕੀਮਾਂ ਦੀ ਸੂਚੀ ਪ੍ਰੋਫਾਈਲ ਡੇਟਾ 'ਤੇ ਅਧਾਰਤ ਹੈ.`,
    "6e0fe1553e84e34da97a6c4c9040b31b": `ਲਾਭਾਰਤ`,
    "27559de2125101ccb786807bceb46aa1": `ਭਾਰਤ`,
    "936b6a0effe4990e8f5420a0099fa537": `EasyGov`,
    "8cf04a9734132302f96da8e113e80ce5": `ਘਰ`,
    "923b39c56654bb6264b6638ba8386b3c": `ਆਪਣਾ ਪ੍ਰੋਫਾਈਲ ਬਣਾਉਣ ਲਈ ਸਾਈਨ ਇਨ / ਸਾਈਨ ਅਪ ਕਰੋ`,
    "52f2ba99491a1bae953303a4eaf50196": `OTP ਨਾਲ ਲੌਗਇਨ ਕਰੋ`,
    "18e0c8084d60f55016d67d4e2ca507ce": `SSO ਨਾਲ ਲੌਗਇਨ ਕਰੋ`,
    "d3d77957f18f1e208cb5b0caa07bec5c": `ਹੋਰ ਸਕੀਮਾਂ ਲੱਭੋ`,
    "6a26f548831e6a8c26bfbbd9f6ec61e0": `ਮਦਦ ਕਰੋ`,
    "b2119dd0c37bb396e24199d5ffbd0c72": `ਸਕਰੀਨ ਰੀਡਰ ਐਕਸੈਸ`,
    "34b6cd75171affba6957e308dcbd92be": `ਵਰਜਨ`,
    "4221d3e17c6eca2ca6337251a3cf9c4e": `ਆਖਰੀ ਵਾਰ ਅਪਡੇਟ ਕੀਤਾ ਗਿਆ`,
    "ece2c7eaaac9f27b055e6e482ab776b9": `ਜਾਣਕਾਰੀ ਭਾਲ`,
    "ad8f678ccf4a4a34b46f01c74f25b91b": `ਉਪਯੋਗਕਰਤਾ ਦੀ ਵੈਬਸਾਈਟ ਦੇ ਅੰਦਰ ਜਾਣਕਾਰੀ ਤੱਕ ਪਹੁੰਚਣ ਵਿੱਚ ਸਹਾਇਤਾ ਕਰਨ ਲਈ, ਇੱਕ "ਖੋਜ ਇੱਥੇ" ਵਿਕਲਪ ਪ੍ਰਦਾਨ ਕੀਤਾ ਗਿਆ ਹੈ. ਇੱਥੇ, ਕੰਨੜ ਵਿੱਚ ਟਾਈਪ ਕਰਨ ਦਾ ਵਿਕਲਪ ਉਪਲਬਧ ਹੈ. ਜਦੋਂ ਭਾਲ ਕਰ ਰਹੇ ਹੋ, ਯੂਨੀਕੋਡ ਫੋਂਟ ਦੀ ਵਰਤੋਂ ਲਾਜ਼ਮੀ ਹੈ. ਅੰਗਰੇਜ਼ੀ ਵਿਚ ਖੋਜ ਕਰਨ ਦਾ ਵਿਕਲਪ ਵੀ ਪ੍ਰਦਾਨ ਕੀਤਾ ਜਾਂਦਾ ਹੈ.`,
    "698aa21b21c2252839e5e54a0d2f12ef": `ਇੰਟਰਨੈੱਟ ਪਹੁੰਚ`,
    "4ac2856693f5c8b4fc6cdaf669ed2d74": `ਜੇ ਇੰਟਰਨੈਟ ਹੌਲੀ ਜਾਂ ਅਣਉਪਲਬਧ ਹੈ, ਤਾਂ ਵੈਬਸਾਈਟ ਡਿਜ਼ਾਈਨ ਜਾਂ ਕੁਝ ਉਪ-ਪੇਜਾਂ ਵਿੱਚ ਇੱਕ ਸੋਧ ਹੋ ਸਕਦੀ ਹੈ.`,
    "0da22543a45316a826571412b3d5953c": `ਵੈਬਸਾਈਟ ਵਰਲਡ ਵਾਈਡ ਵੈੱਬ ਕਨਸੋਰਟੀਅਮ (ਡਬਲਯੂ 3 ਸੀ) ਵੈਬ ਸਮਗਰੀ ਐਕਸੈਸਿਬਿਲਟੀ ਦਿਸ਼ਾ ਨਿਰਦੇਸ਼ਾਂ (ਡਬਲਯੂਸੀਏਜੀ) ਦੀ ਪਾਲਣਾ ਕਰਦੀ ਹੈ. ਇਹ ਵਿਜ਼ੂਅਲ ਕਮਜ਼ੋਰੀ ਵਾਲੇ ਲੋਕਾਂ ਨੂੰ ਯੋਗ ਬਣਾਏਗਾ, ਜਿਵੇਂ ਕਿ ਸਹਾਇਤਾ ਤਕਨਾਲੋਜੀ, ਜਿਵੇਂ ਕਿ ਸਕ੍ਰੀਨ ਰੀਡਰ. ਵੈਬਸਾਈਟ ਦੀ ਜਾਣਕਾਰੀ ਵੱਖ-ਵੱਖ ਸਕ੍ਰੀਨ ਪਾਠਕਾਂ ਦੇ ਨਾਲ ਪਹੁੰਚਯੋਗ ਹੈ, ਜਿਵੇਂ ਕਿ ਜਬਾੜੇ, ਐਨਵੀਡੀਏ, ਸਫਾ, ਸੁਪਰਨੋਵਾ ਅਤੇ ਵਿੰਡੋ-ਅੱਖਾਂ.`,
    "cb976b928eacd65e6342cfa78bf196da": `ਹੇਠ ਦਿੱਤੇ ਟੇਬਲ ਵੱਖ-ਵੱਖ ਸਕ੍ਰੀਨ ਪਾਠਕਾਂ ਬਾਰੇ ਜਾਣਕਾਰੀ ਦੀ ਸੂਚੀ ਹੈ`,
    "786f669131d80fbed4572a520c28c14d": `EasyGov ਦੁਆਰਾ ਬਣਾਈ ਅਤੇ ਮਲਕੀਅਤ ਵਾਲੀ ਸਮੱਗਰੀ`,
    "fc290a7672f96fd8b94a9659c8c02322": `| ਸਾਰੇ ਹੱਕ ਰਾਖਵੇਂ ਹਨ`,
    "6016a2b341113bf496b719905398ecd2": `ਕਾਪੀਰਾਈਟ`,
    "0e8a85115f2c3ef0058c70f30a9b6deb": `ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੇ ਰਜਿਸਟਰਡ ਮੋਬਾਈਲ ਨੰਬਰ ਤੇ ਭੇਜਿਆ ਗਿਆ OTP ਦਰਜ ਕਰੋ`,
    "34f8ccda5172e4eac9a8a014559b38c1": `ਕਿਰਪਾ ਕਰਕੇ ਆਪਣਾ ਪਾਸਵਰਡ ਸੈੱਟ ਕਰੋ`,
    "548899be111ad732b37786783dfbd6d8": `ਕੋਈ ਇੰਟਰਨੈਟ ਕਨੈਕਸ਼ਨ ਨਹੀਂ`,
    "5c3a1593ca1d34226359df44f3d45f2f": `ਕਿਰਪਾ ਕਰਕੇ ਕਿਸੇ ਸਮੇਂ ਦੁਬਾਰਾ ਜਾਂਚ ਕਰੋ.`,
    '35043eeac5651298d4bb6c100e071745': `ਤੁਹਾਨੂੰ ਤੀਜੀ ਧਿਰ ਦੀ ਵੈਬਸਾਈਟ ਤੇ ਭੇਜਿਆ ਜਾਵੇਗਾ. ਕੀ ਤੁਸੀਂ ਜਾਰੀ ਰੱਖਣਾ ਚਾਹੁੰਦੇ ਹੋ?`,
    "6182e08f3974a70ac4883b5fceaa14c0": `ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੇ ਰਜਿਸਟਰਡ ਮੋਬਾਈਲ ਨੰਬਰ ਤੇ ਭੇਜਿਆ ਗਿਆ OTP ਦਰਜ ਕਰੋ`,
    "306fb6389c3b3fd2835af15cfc7c66e9": `ਫੈਮਲੀ ਆਈਡੀ ਬਦਲੋ`,
    "63fc7651ee1dbf6868a43a5b5fa8b581": `ਸਿਰਫ ਅੱਖਰਾਂ ਦੀ ਆਗਿਆ ਹੈ`,
    "6903fbe8375d52217c15618170f4078a": `ਸਿਰਫ 30 ਅੱਖਰਾਂ ਦੀ ਆਗਿਆ ਹੈ`,
    "9ab37820a722ce13e80a3d0aa468d6dc": `* ਲੋੜੀਂਦਾ`,
    "0df1f18a2fa23cd60990e47692f99e07": `ਐਪਲੀਕੇਸ਼ਨ ਲਿੰਕ`,
    "a60852f204ed8028c1c58808b746d115": `ਠੀਕ ਹੈ`,
    "edbd5921e9c7168e04cbe6f75ee76174": `ਤੁਹਾਡੀ ਪ੍ਰੋਫਾਈਲ ਦੇ ਅਧਾਰ ਤੇ, ਅਸੀਂ ਤੁਹਾਨੂੰ ਕਿਸੇ ਵੀ ਸਕੀਮ ਲਈ ਯੋਗ ਨਹੀਂ ਲੱਭਿਆ. ਅਸੀਂ ਲਗਾਤਾਰ ਹੋਰ ਯੋਜਨਾਵਾਂ ਜੋੜ ਰਹੇ ਹਾਂ, ਕਿਰਪਾ ਕਰਕੇ ਬਾਅਦ ਵਿੱਚ ਚੈੱਕ ਕਰੋ.`,
    "a203c39a340e1d45069b1ba64e1d003a": `ਧਿਆਨ!`,
    "037673ab5a84897e0ee43ca37f7680f6": `ਜੇ ਤੁਸੀਂ ਆਪਣਾ ਡੇਟਾ ਬਦਲਦੇ ਹੋ ਤਾਂ ਇਹ ਵੀ ਉਹੀ ਤੁਹਾਡੇ ਪ੍ਰੋਫਾਈਲ ਡੇਟਾ ਵਿੱਚ ਅਪਡੇਟ ਕੀਤਾ ਜਾਏਗਾ. ਕੀ ਤੁਸੀਂ ਜਾਰੀ ਰੱਖਣਾ ਚਾਹੁੰਦੇ ਹੋ?`,
    "d1d16cede248b826a431ea11132567cb": `ਜੇ ਤੁਸੀਂ ਆਪਣਾ ਪ੍ਰਮਾਣਿਤ ਡੇਟਾ ਬਦਲਦੇ ਹੋ, ਤਾਂ ਇਹ ਦੁਬਾਰਾ ਪ੍ਰਮਾਣਿਤ ਕੀਤਾ ਜਾਵੇਗਾ ਅਤੇ ਤੁਹਾਡੀ ਸਥਿਤੀ ਬਦਲੀ ਜਾਏਗੀ. ਇਹ ਤੁਹਾਡੀ ਅਰਜ਼ੀ ਦੇ ਅਸਵੀਕਾਰ ਜਾਂ ਵੱਧ ਰਹੀ ਪ੍ਰੋਸੈਸਿੰਗ ਸਮੇਂ ਦੇ ਵਧੇਰੇ ਅਧਿਕਾਰਾਂ ਦਾ ਕਾਰਨ ਬਣ ਸਕਦਾ ਹੈ.`,
    "23a0ca84a7fd64eee8248bd1dfc77185": `ਨਾਮ ਬਦਲੋ`,
    "3b383caaaa6113dd83b381b43a97d7ee": `ਇਸ ਉਪਭੋਗਤਾ ਲਈ ਪਹਿਲਾਂ ਤੋਂ ਮੌਜੂਦ ਹੈ, ਜੋ ਕਿ ਇੱਕ ਨਵਾਂ ਫੈਮਲੀ ਆਈਡੀ ਸ਼ਾਮਲ ਕਰਨਾ ਪਿਛਲੇ ਵੇਰਵੇ ਹਟਾ ਦੇਵੇਗਾ. ਕ੍ਰਿਪਾ ਕਰਕੇ ਪੁਸ਼ਟੀ ਕਰੋ`,
    "e0efbdcea0abe735dbf887292a5670fa": `ਭਰਾ`,
    "ad6e7652b1bdfb38783486c2c3d5e806": `ਸਵੈ`,
    "19cda37369e2d2015e66b9d478ffbe15": `ਭੈਣ`,
    "81e053d78f01540e9cd6fb68a1c03976": `ਕੋਈ ਹੋਰ`,
    "b8e18040dc07eead8e6741733653a740": `ਮਾਂ`,
    "a30ca91be97a9cedc0d2426e54b0e782": `ਪਿਤਾ ਜੀ`,
    "784c01f6b735be57175e2f438297c741": `ਜੀਵਨ ਸਾਥੀ`,
    "1968ab9fdfedf558714bf6caec9a1234": `ਪੁੱਤਰ`,
    "7bc1cb93f51f61bde0e90b20c81ebf88": `ਧੀ`,
    "25f34d5943da3f09a2aa6358193a41bd": `ਲਾਗੂ ਕਰਨ ਲਈ ਅੱਗੇ ਵਧੋ`,
    "79dff508ab2e4d272f0630d001271eb1": `ਹੋਰ ਨੌਕਰੀਆਂ ਦੀ ਪੜਚੋਲ ਕਰੋ`,
    "b6ebe1e750185946ed41be9191410612": `ਤੁਹਾਨੂੰ ਲਭਾਰਤਸ਼ੀ ਦੁਆਰਾ ਲਾਗੂ ਕਰਨ ਦੇ ਯੋਗ ਹੋਣ ਦੇ ਵੇਰਵਿਆਂ ਨੂੰ ਪ੍ਰਦਾਨ ਕਰਨ ਦੀ ਜ਼ਰੂਰਤ ਹੈ.`,
    "af1899084d8361b9afb9187da988ff9c": `"ਮੁਆਫ ਕਰਨਾ, ਅਸੀਂ ਤੁਹਾਡੇ ਡਾਟਾ ਲਿਆਉਣ ਵਿੱਚ ਅਸਮਰੱਥ ਹਾਂ".`,
    "1b56218ddf0a290241d661d26253d51f": `ਸਿਰਫ {{attempts}} ਦੀ ਕੋਸ਼ਿਸ਼ ਕੀਤੀ ਗਈ ਹੈ`,
    "f299cff2484fdb361f4fc3189490d909": `ਤੁਹਾਡੀ ਸਰਕਾਰ ਦੀ ਰੀਕ ਨਾਲ ਮੇਲ ਨਹੀਂ ਖਾਂਦੀ ਤੁਹਾਡੀ ਦੁਆਰਾ ਦਿੱਤੀ ਗਈ ਹੇਠਲੀ ਜਾਣਕਾਰੀ:`,
    "1edf5362e5f4af6f370a7f34c3136a7a": `ਤਸਦੀਕ ਅਸਫਲ`,
    "dcf9ac5128c95d88e068e223dfe01d27": `ਤਸਦੀਕ ਸਥਿਤੀ`,
    "bc910f8bdf70f29374f496f05be0330c": `ਪਹਿਲਾ ਨਾਂ`,
    "77587239bf4c54ea493c7033e1dbf636": `ਆਖਰੀ ਨਾਂਮ`,
    "14fcbe1dd0f7a39f0195aba46cf89d17": `ਵੇਰਵਿਆਂ ਦੀ ਤਸਦੀਕ ਕਰੋ`,
    "b6121db5ee47deeb0ca42d5dea0b2225": `ਕੋਸ਼ਿਸ਼ ਅਸਫਲ`,
    "839301cec5574d7c3256afce4fb85467": `ਤੁਸੀਂ ਆਪਣੇ ਪਰਿਵਾਰਕ ਵੇਰਵਿਆਂ ਦੀ ਤਸਦੀਕ ਕਰਨ ਦੀਆਂ ਕੋਸ਼ਿਸ਼ਾਂ ਦੀ ਜਾਇਜ਼ ਗਿਣਤੀ ਨੂੰ ਪਾਰ ਕਰ ਚੁੱਕੇ ਹੋ. ਤੁਹਾਡੀ ਤਸਦੀਕ ਨੂੰ ਰੋਕ ਦਿੱਤਾ ਗਿਆ ਹੈ`,
    "6733aa56fd001b9bf479650f3950688a": `ਤੁਹਾਨੂੰ ਐਪਲੀਕੇਸ਼ਨ ਪੇਜ ਨੂੰ ਸਕੀਮ ਕਰਨ ਦੇ ਨਿਰਦੇਸ਼ ਦਿੱਤੇ ਜਾ ਰਹੇ ਹਨ`,
    "9303c9bd4f8178680dc382adbfcd62af": `ਇਸ ਫੀਲਡ ਦੀ ਲੋੜ ਹੈ.`,
    "53a04cfc3d09c9b2dfb8349791c62bc1": `ਤੁਹਾਡਾ ਨਾਮ ਇਸ ਪਰਿਵਾਰ ਦੇ ਕਿਸੇ ਵੀ ਮੈਂਬਰ ਨਾਲ ਮੇਲ ਨਹੀਂ ਖਾਂਦਾ ਸੀ`,
    "1ca35256fcb85c1ba9ea7ac1095462ad": `ਤੁਸੀਂ ਆਪਣੇ ਪਰਿਵਾਰਕ ਵੇਰਵਿਆਂ ਦੀ ਤਸਦੀਕ ਕਰਨ ਦੀਆਂ ਕੋਸ਼ਿਸ਼ਾਂ ਦੀ ਜਾਇਜ਼ ਗਿਣਤੀ ਨੂੰ ਪਾਰ ਕਰ ਚੁੱਕੇ ਹੋ. ਤੁਹਾਡੀ ਤਸਦੀਕ 3 ਘੰਟਿਆਂ ਲਈ ਬਲੌਕ ਕੀਤੀ ਗਈ ਹੈ`,
    "0e3a917198575c463bb4fdcb7332b03a": `ਅਵੈਧ ਆਧਾਰ ਕਾਰਡ ਨੰਬਰ`,
    "630058029627ce58e7571e7a5b6ec591": `ਪੂਰਾ ਨਾਂਮ`,
    "072355e896ac1a999d2fa0aa208f94ee": `ਇਹ ਯੋਜਨਾ ਤੁਹਾਡੀਆਂ "ਮੇਰੀਆਂ ਯੋਜਨਾਵਾਂ" ਵਿੱਚ ਸੁਰੱਖਿਅਤ ਕੀਤੀ ਗਈ ਹੈ.`,
    "789189638f8a301304dfadeb61036dd9": `ਇਹ ਸੇਵਾ ਤੁਹਾਡੀਆਂ "ਮੇਰੀਆਂ ਸੇਵਾਵਾਂ" ਵਿੱਚ ਸੁਰੱਖਿਅਤ ਕੀਤੀ ਗਈ ਹੈ.`,
    "ab8023b4fc2395738b8fd2df440641a9": `ਇਹ ਨੌਕਰੀ ਤੁਹਾਡੇ "ਮੇਰੇ ਨੌਕਰੀਆਂ" ਵਿੱਚ ਬਚ ਗਈ ਹੈ.`,
    "671dec8fc4e3dc847c1b0d7b67b4e6e1": `ਸਬੰਧ`,
    "f252b4e2f3b2963d330c8cde8e9cdbd9": `ਜੇ ਉਪਲਬਧ ਹੋਵੇ`,
    "ef51899b57258db72f15b4703a8a86ba": `ਇੰਡੀਆ ਸਰਕਾਰ ਭਲਾਈ ਸਕੀਮਾਂ, ਸੇਵਾਵਾਂ ਅਤੇ ਨੌਕਰੀਆਂ ਲਈ ਨਾਗਰਿਕਾਂ ਦਾ ਇੱਕ ਸਿੰਗਲ ਪਲੇਟਫਾਰਮਸ ਲਈ ਕਾਰਜਸ਼ੀਲ ਜਾਣਕਾਰੀ ਪ੍ਰਾਪਤ ਕਰਨ ਲਈ.`,
    //New Translations for Phase 1:`#VALUE!`,
    "7510e00267e89c78470e79b42ecd6463": `ਆਪਣੀ ਭਾਸ਼ਾ ਵਿਚ ਐਪ ਦੀ ਪੜਚੋਲ ਕਰੋ`,
    "83218ac34c1834c26781fe4bde918ee4": `ਜੀ ਆਇਆਂ ਨੂੰ`,
    "1d00e7dce692e8dc3f6877f035e3a616": `ਜਾਂ`,
    "e0cf58fa3413df99ae83109ebb9a41a3": `OTP ਦੁਬਾਰਾ ਭੇਜੋ`,
    "6ed83b24865902c6fd90bbd15247e042": `ਪਾਸਵਰਡ ਸੈੱਟਅੱਪ`,
    "9157806ea93e82c6843e29353af0339f": `ਬੇਨਤੀ OTP`,
    "69f18e98d2dd54118ade84732dc3a5e0": `ਪਿੰਨ ਪਿੰਨ`,
    "2dd4472245a696bc0b4b944db2a8b519": `ਵਿਅਕਤੀਗਤ`,
    "a274f4d4670213a9045ce258c6c56b80": `ਸੂਚਨਾਵਾਂ`,
    "407456e575dee5a4b84a19a7111a4a4d": `ਜਦੋਂ ਤੁਸੀਂ 2 ਤੋਂ ਘੱਟ ਨੰਬਰਾਂ ਵਾਲੀ ਕਿਸੇ ਸਕੀਮ ਸ਼੍ਰੇਣੀ 'ਤੇ ਕਲਿੱਕ ਕਰਦੇ ਹੋ, ਤਾਂ ਤੁਸੀਂ ਉਨ੍ਹਾਂ ਸਕੀਮਾਂ ਨੂੰ ਦੇਖਣ ਦੇ ਯੋਗ ਹੋ ਜਾਂਦੇ ਹੋ ਜਿਨ੍ਹਾਂ ਲਈ ਤੁਸੀਂ ਯੋਗ ਹੋ ਸਕਦੇ ਹੋ, ਜਦੋਂ ਤੁਸੀਂ 2 ਤੋਂ ਵੱਧ ਨੰਬਰਾਂ ਵਾਲੀ ਕਿਸੇ ਵੀ ਸਕੀਮ ਸ਼੍ਰੇਣੀ 'ਤੇ ਕਲਿੱਕ ਕਰਦੇ ਹੋ ਤਾਂ ਸ਼੍ਰੇਣੀ ਵਿਸ਼ੇਸ਼ ਸਰਵੇਖਣ ਤੁਹਾਨੂੰ ਹੋਰ ਦੇਣ ਲਈ ਚੱਲੇਗਾ। ਚੁਣੀ ਗਈ ਸ਼੍ਰੇਣੀ ਲਈ ਯੋਜਨਾਵਾਂ ਦੀ ਸ਼ੁੱਧ ਸੂਚੀ।`,
    "9139eb8e78808acbd15244d4eb9f1bc3": `ਹੋਰ ਸਕੀਮਾਂ ਦੀ ਪੜਚੋਲ ਕਰੋ`,
    "c61dd5f0e5b5389306ad5221ec2cd795": `ਇਹ ਯੋਜਨਾ ਤੁਹਾਡੇ "ਮੇਰੀਆਂ ਯੋਜਨਾਵਾਂ" ਵਿੱਚ ਸੁਰੱਖਿਅਤ ਕੀਤੀ ਗਈ ਹੈ`,
    "f2a6c498fb90ee345d997f888fce3b18": `ਮਿਟਾਓ`,
    "469bad4859f3c33fcb8ce09314c3e895": `ਸਰਵੇਖਣ ਵੇਰਵਿਆਂ ਦੀ ਸਮੀਖਿਆ ਕਰੋ`,
    "7dce122004969d56ae2e0245cb754d35": `ਸੋਧ`,
    "c5984b3fe02f7b06d77fb538d842b0fc": `ਸਰਵੇਖਣ ਵੇਰਵਿਆਂ ਨੂੰ ਅਪਡੇਟ ਕਰੋ`,
    "6cf33ce1a7a11a7178131567c777b637": `ਉਸੇ ਨਾਮ ਅਤੇ ਰਿਸ਼ਤੇ ਦੇ ਨਾਲ ਪ੍ਰੋਫਾਈਲ ਪਹਿਲਾਂ ਹੀ ਮੌਜੂਦ ਹੈ`,
    "fffcb4d1e12c96b2f571f77ad25878b8": `ਕਾਰਨ`,
    "e8d89b6875d217a990d47701138e687a": `ਕੀ ਤੁਸੀਂ ਯਕੀਨਨ ਇਸ ਡੇਟਾ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?`,
    "aa98db08fed41a99a00aac36f521540f": `ਆਖਰੀ ਪ੍ਰਮਾਣਿਤ`,
    "5da618e8e4b89c66fe86e32cdafde142": `ਤੋਂ`,
    "253ec560db0637f6a0e53aefcbd4be0c": `ਜਦੋਂ ਤੁਸੀਂ ਲਾਭਾਰਤ ਖਾਤੇ ਨੂੰ ਮਿਟਾਉਂਦੇ ਹੋ, ਤਾਂ ਤੁਸੀਂ ਆਪਣੇ ਪ੍ਰੋਫਾਈਲ ਵਿੱਚ ਕੋਈ ਵੀ ਡਾਟਾ ਜਾਂ ਜਾਣਕਾਰੀ ਪ੍ਰਾਪਤ ਕਰਨ ਦੇ ਯੋਗ ਨਹੀਂ ਹੋਵੋਗੇ. ਸਾਰੀਆਂ ਸੁਰੱਖਿਅਤ ਕੀਤੀਆਂ ਸਕੀਮਾਂ ਅਤੇ {{name}} ਲਈ ਸਟੋਰ ਕੀਤੀ ਜਾਣਕਾਰੀ ਵੀ ਮਿਟਾ ਦਿੱਤੀ ਜਾਵੇਗੀ।`,
    "c0e70d5bfc28ae3aadfb8f85697c54d5": `ਤੁਹਾਡਾ ਡਾਟਾ ਸਫਲਤਾਪੂਰਵਕ ਅਪਡੇਟ ਕੀਤਾ ਗਿਆ ਹੈ.`,
    "09b73aba9204a05ba5eaf0ea471fdc8c": `EasyGov ਸੂਰਜੀ ਸੇਵਾ ਪ੍ਰਾਈਵੇਟ ਲਿਮਟਿਡ ਦਾ ਇੱਕ ਰਜਿਸਟਰਡ ਟ੍ਰੇਡਮਾਰਕ ਹੈ। EasyGov ਦਾ ਪਲੇਟਫਾਰਮ-ਏ-ਏ-ਸਰਵਿਸ ਮਾਡਲ ਪੜਾਅਵਾਰ ਢੰਗ ਨਾਲ ਸਰਕਾਰੀ ਭਲਾਈ ਨੂੰ ਰੂੜੀਵਾਦੀ, ਪ੍ਰਤੀਕਿਰਿਆਸ਼ੀਲ ਅਤੇ ਪ੍ਰੋਗਰਾਮ ਕੇਂਦਰਿਤ ਤੋਂ ਪ੍ਰਗਤੀਸ਼ੀਲ, ਕਿਰਿਆਸ਼ੀਲ ਅਤੇ ਪਰਿਵਾਰ ਕੇਂਦਰਿਤ ਵਿੱਚ ਬਦਲਦਾ ਹੈ। EasyGov ਨਾਗਰਿਕਾਂ ਦੇ ਲਾਭਾਂ ਲਈ ਭਲਾਈ ਪ੍ਰਣਾਲੀ ਦੀ ਇਸ ਗੁੰਝਲਦਾਰ ਯਾਤਰਾ ਨੂੰ ਹੱਲ ਕਰਨ ਲਈ ਵੱਖ-ਵੱਖ ਸਰਕਾਰਾਂ ਦੀ ਮਦਦ ਕਰ ਰਿਹਾ ਹੈ। ਵਧੇਰੇ ਜਾਣਕਾਰੀ ਲਈ ਕਿਰਪਾ ਕਰਕੇ <a href='https://easygov.co.in/' target='_blank'> https://easygov.co.in/</a> 'ਤੇ ਜਾਓ`,
    "8f241c62a9523b05bf0b6b16a09d856d": `ਭਾਸ਼ਾ ਬਦਲੋ`,
    "1ccbe6084301f9b1e18e3e7caf723ae1": `ਕੀ ਤੁਹਾਨੂੰ ਯਕੀਨ ਹੈ ਕਿ ਤੁਸੀਂ ਲੌਗਆਉਟ ਕਰਨਾ ਚਾਹੁੰਦੇ ਹੋ?`,
    "32ca0a0f69103bad1e6f134d5a8e36d7": `ਕਿਸੇ ਵਿਸ਼ੇਸ਼ ਸ਼੍ਰੇਣੀ ਲਈ ਨੌਕਰੀਆਂ ਦੇਖਣ ਲਈ, ਕਿਰਪਾ ਕਰਕੇ ਕਿਸੇ ਵੀ ਨੌਕਰੀ ਦੀ ਸ਼੍ਰੇਣੀ 'ਤੇ ਕਲਿੱਕ ਕਰੋ`,
    "d6e6cb19e3b9c02f89d6cd54cfa7c613": `ਕਾਰੋਬਾਰ`,
    "b1c94ca2fbc3e78fc30069c8d0f01680": `ਸਾਰੇ`,
    "1dd1c5fb7f25cd41b291d43a89e3aefd": `ਅੱਜ`,
    "ebfe9ce86e6e9fb953aa7a25b59c1956": `ਕੱਲ`,
    "4b2ef3655b5d6d48f1fa41892d0043b9": `ਕੋਈ ਨੋਟੀਫਿਕੇਸ਼ਨ ਨਹੀਂ`,
    "094f537cfb139894f1412200c78e87b1": `ਕੀ ਤੁਸੀਂ ਚੁਣੀਆਂ ਹੋਈਆਂ ਸੂਚਨਾਵਾਂ ਨੂੰ ਮਿਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?`,
    "4201c11dc26b42deb90cb119c0b12fdb": `ਡਾਉਨਲੋਡ ਨੋਟੀਫਿਕੇਸ਼ਨ`,
    "ae45c43efe0011f67ec52003c0d8f916": `<ਬੀ> ਇਸ਼ਾਰਾ </ ਬੀ>: 'ਪਾਸਵਰਡ' ਵਿੱਚ ਘੱਟੋ ਘੱਟ 8 - <br> ਅੱਖਰ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ, ਜਿਸ ਵਿੱਚ ਘੱਟੋ ਘੱਟ ਇੱਕ ਵੱਡੇ ਅੱਖਰ, ਇੱਕ ਛੋਟੇ ਕੇਸ, ਇੱਕ ਨੰਬਰ ਅਤੇ ਇੱਕ ਵਿਸ਼ੇਸ਼ ਪਾਤਰ ਸ਼ਾਮਲ ਹਨ.`,
    "248336101b461380a4b2391a7625493d": `ਸੰਭਾਲੀ ਗਈ`,
    "f03ab16cd58372c77ba45a3d9a5a1cb9": `ਡਰਾਫਟ`,
    "a4d158b65f20725f9fe9c1763e9805c8": `ਪ੍ਰਾਈਵੇਟ ਸਪਾਂਸਰਡ`,
    "7fe12d923db43febcc5d8ea18df632ca": `ਆਪਣੇ ਕੇਵਾਈਸੀ ਦਸਤਾਵੇਜ਼ ਚੁਣੋ`,
    "d8ce714c8a76133a8d5746e3bfdd7ef7": `ਕੇਵਾਈਸੀ`,
    "4f67fe16b274bf31a67539fbedb8f8d3": `ਦਸਤਾਵੇਜ਼ ਕਿਸਮ`,
    "5127fa87ed375848481bfe9c786d8022": `ਆਪਣੇ ਕੇਵਾਈਸੀ ਭਰੋ`,
    "8f75e3041034138aba310ba3f097ed24": `ਦਸਤਾਵੇਜ਼ ਨੰਬਰ`,
    "9abfb506bebd1e72069f0be0014986dc": `ਗਲਤ ਦਸਤਾਵੇਜ਼ ਨੰਬਰ`,
    "ecfffd1bad9944f6a50e369f63268cff": `ਕਿਊਆਰ ਸਕੈਨ`,
    "c541f95d69a4a507b87b32d05dd6d73c": `ਕੋਡ ਨੂੰ ਫਰੇਮ ਦੇ ਅੰਦਰ ਰੱਖੋ`,
    "3876661cb83fa41284f828bd8d7fbb7b": `ਕੇਸੀ ਦਸਤਾਵੇਜ਼ ਤਸਦੀਕ ਕਰਨ ਅਤੇ ਕੇਵਾਈਸੀ ਪ੍ਰਕਿਰਿਆ ਦੇ ਸੰਪੂਰਨ ਹੋਣ ਲਈ ਅਪਲੋਡ ਕੀਤੇ ਜਾਣ ਵਾਲੇ.`,
    "cc1d6f3625c4eb671d76b6ebf3f6f544": `ਆਪਣੇ ਗਾਹਕ ਨੂੰ ਜਾਣੋ`,
    "08ac502bbb7652c683c1da97c7fdeb94": `{{xxxx}} ਤੁਹਾਡੇ ਮੋਬਾਈਲ ਨੰਬਰ ਤੇ ਇੱਕ OTP ਭੇਜਿਆ ਗਿਆ ਹੈ`,
    "d19a88ee579190614084c2ea84379ac7": `OTP (1:00) ਦੁਬਾਰਾ ਭੇਜੋ`,
    "7397e92003e187474e5eab39b27fcb6b": `ਮੁਆਫ ਕਰਨਾ, ਅਸੀਂ ਸਰੋਤ ਪ੍ਰਣਾਲੀਆਂ ਤੋਂ ਤੁਹਾਡੇ ਡੇਟਾ ਨੂੰ ਪ੍ਰਾਪਤ ਕਰਨ ਵਿੱਚ ਅਸਮਰੱਥ ਹਾਂ. ਬਾਅਦ ਵਿੱਚ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ ਜੀ.`,
    "a943d4ac1dd2e0048bcbf85225bf2811": `OTP ਪੈਦਾ ਕਰਨ ਦੀ ਤੁਸੀਂ ਵੱਧ ਤੋਂ ਵੱਧ ਸੀਮਾ ਨੂੰ ਪਾਰ ਕਰ ਚੁੱਕੇ ਹੋ, ਕਿਰਪਾ ਕਰਕੇ 3 ਘੰਟਿਆਂ ਬਾਅਦ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ`,
    "8dac090b39cf9b3bff9a9503531d04cf": `ਤੁਹਾਡਾ ਖਾਤਾ ਇਸ ਸਮੇਂ ਬਲੌਕ ਕੀਤਾ ਗਿਆ ਹੈ, ਕਿਰਪਾ ਕਰਕੇ ਕੁਝ ਸਮੇਂ ਬਾਅਦ ਕੋਸ਼ਿਸ਼ ਕਰੋ.`,
    "08798caaee5cc8ea1282883901c8810b": `ਬਾਕੀ ਕੋਸ਼ਿਸ਼ਾਂ - {{Number}} ਚੇਤਾਵਨੀ - ਲਗਾਤਾਰ 4 ਲਗਾਤਾਰ ਅਸਫਲ ਕੋਸ਼ਿਸ਼ਾਂ ਤੋਂ ਬਾਅਦ, ਤੁਹਾਡਾ ਖਾਤਾ ਤਾਲਾਬੰਦ ਹੋ ਜਾਵੇਗਾ.`,
    "33018956b3ffe20d413e3c6780f19aff": `ਕੇਵਾਈਸੀ ਵੇਰਵਿਆਂ ਦੀ ਪੁਸ਼ਟੀ ਕਰੋ`,
    "90f35a35415ca3b4c623d7c515fce1c0": `ਧਿਆਨ! ਜੇ ਤੁਸੀਂ ਆਪਣੇ ਵੇਰਵਿਆਂ ਨੂੰ ਅਪਡੇਟ ਜਾਂ ਬਦਲਦੇ ਹੋ ਤਾਂ ਤੁਹਾਡੇ ਡੇਟਾ ਦੀ ਅਣਚਾਹੇ ਹੋ ਜਾਵੇਗਾ ਅਤੇ ਤੁਹਾਨੂੰ ਅਪਡੇਟ ਕੀਤੇ ਡਾਟੇ ਲਈ ਦਸਤਾਵੇਜ਼ ਦਾ ਸਬੂਤ ਅਪਲੋਡ ਕਰਨ ਲਈ ਕਿਹਾ ਜਾਵੇਗਾ. ਜਾਰੀ ਰੱਖਣ ਲਈ "ਜਾਰੀ ਰੱਖੋ" ਤੇ ਕਲਿਕ ਕਰੋ`,
    "aee68a98fd803f091082faf867c0f62b": `ਕੇਵਾਈਸੀ ਫਾਰਮ ਭਰੋ`,
    "2e853d869dd0c3cb0a64e4938ee90647": `ਗਲਤ ਪਿੰਨ ਕੋਡ`,
    "8f3124e142a4000fe04a581f4f41c8da": `ਅਵੈਧ {{labour card}} ਨੰਬਰ`,
    "dbb362212f1b8118f17633521d1e72f7": `ਤੁਹਾਡੀ ਐਪਲੀਕੇਸ਼ਨ ਨੇ ਤੁਹਾਡੀ 'ਡਰਾਫਟ ਸਕੀਮ' ਸਫਲਤਾਪੂਰਵਕ ਸੇਵ ਕੀਤਾ.`,
    "5c93310dd0291e121181e830cdda892e": `ਗੈਲਰੀ`,
    "967d35e40f3f95b1f538bd248640bf3b": `ਕੈਮਰਾ`,
    "37bed5f24fa3752c7830c021501539a0": `ਕੀ ਤੁਸੀਂ ਸੱਚਮੁੱਚ ਫੋਟੋ ਹਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?`,
    "1a263d755f169c2499c1a55f7a465b93": `ਤੁਹਾਡੀ ਡੌਕੂਮੈਂਟ ਆਈਡੀ ਇਸ ਵੇਲੇ ਬਲੌਕ ਕੀਤੀ ਗਈ ਹੈ, ਕਿਰਪਾ ਕਰਕੇ ਕੁਝ ਸਮੇਂ ਬਾਅਦ ਕੋਸ਼ਿਸ਼ ਕਰੋ.`,
    "689707127ddb398dc4b447e1dffa3a47": `ਤੁਸੀਂ ਆਪਣੇ ਵੇਰਵਿਆਂ ਦੀ ਤਸਦੀਕ ਕਰਨ ਦੀਆਂ ਕੋਸ਼ਿਸ਼ਾਂ ਦੀ ਜਾਇਜ਼ ਗਿਣਤੀ ਨੂੰ ਪਾਰ ਕਰ ਚੁੱਕੇ ਹੋ. ਤੁਹਾਡੀ ਤਸਦੀਕ ਨੇ 3 ਘੰਟਿਆਂ ਲਈ ਬਲੌਕ ਕੀਤਾ ਹੈ.`,
    "04c850a81052df23680d00d2aa1d891e": `ਕੇਵਾਈਸੀ ਸਥਿਤੀ`,
    "a441e519087df0d7c8572b9fafa14ae2": `ਤੁਹਾਡੀ ਸਰਕਾਰ ਦੇ ਰਿਕਾਰਡ 1. ਨਾਲ ਸਬੰਧਤ ਹੇਠ ਲਿਖੀ ਜਾਣਕਾਰੀ ਨਹੀਂ. ਤੁਹਾਡਾ ਨਾਮ ਰਿਕਾਰਡ .2 ਨਾਲ ਮੇਲ ਨਹੀਂ ਖਾਂਦਾ. ਤੁਹਾਡੀ ਉਮਰ ਦਾ ਰਿਕਾਰਡ ਨਾਲ ਮੇਲ ਨਹੀਂ ਖਾਂਦਾ.`,
    "75514ce9dac260a1f7abd0d85ce6af46": `ਤੁਹਾਡੇ ਦੁਆਰਾ ਪ੍ਰਦਾਨ ਕੀਤੇ ਗਏ ਵੇਰਵੇ ਰਿਕਾਰਡਾਂ ਨਾਲ ਮੇਲ ਨਹੀਂ ਖਾਂਦਾ`,
    "5955e277da6fb75982b0f651f4ae8b92": `ਅਸੀਂ ਸਰੋਤ ਪ੍ਰਣਾਲੀਆਂ ਤੋਂ ਤੁਹਾਡੇ ਡੇਟਾ ਨੂੰ ਪ੍ਰਾਪਤ ਕਰਨ ਵਿੱਚ ਅਸਮਰੱਥ ਹਾਂ. ਬਾਅਦ ਵਿੱਚ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ ਜੀ.`,
    "be60ac326400cc5ef0d173a833c4a40b": `ਤੁਹਾਡੇ ਵੇਰਵਿਆਂ ਦੀ ਪੁਸ਼ਟੀ ਕੀਤੀ ਜਾ ਰਹੀ ਹੈ`,
    "d1083e84a9a7283ffb081263e8533509": `ਤੁਹਾਡੀ ਅਰਜ਼ੀ ਨੂੰ ਤੁਹਾਡੀ 'ਡਰਾਫਟ ਸਕੀਮ' ਵਿੱਚ ਸਫਲਤਾਪੂਰਵਕ ਸੇਵ ਕੀਤਾ ਗਿਆ ਹੈ`,
    "0bad551abed71c0a41ca8df804afec86": `ਦਸਤਾਵੇਜ਼ ਦੀ ਵਰਤੋਂ ਕਰ ਰਿਹਾ ਹੈ`,
    "e6387f3e684958515af2947d8cdf01d7": `ਕੇਵਾਈਸੀ ਫਾਰਮ ਨੂੰ ਅਪਡੇਟ ਕਰੋ`,
    "9c7ed3d8ac60281e90519b4ebf07a9a2": `ਪਤਾ ਚੁਣੋ`,
    "31e41095bfaa14799239e8d9ba7ad438": `ਐਪਲੀਕੇਸ਼ਨ ਵੇਰਵੇ`,
    "e1e4c8c9ccd9fc39c391da4bcd093fb2": `ਬਲਾਕ`,
    "b9eca8f59016e8ff69a2c76fa869d23f": `ਸੀਡ ਕਾਰਡ ਅਪਲੋਡ ਕਰੋ`,
    "6cf24d7a07132fe88b610e2b3d3def28": `ਸਵੈ ਘੋਸ਼ਣਾ`,
    "827b05360cd22ccbaf3d085aded71570": `ਪਤੇ ਦਾ ਸਬੂਤ`,
    "f734cd7792862bebbbb87ddc54285376": `ਪਛਾਣ ਦਾ ਸਬੂਤ`,
    "5a8c686dfdba564a2801806f3827c124": `ਚਿੱਤਰ JPG ਜਾਂ PNG ਫਾਰਮੈਟ ਵਿੱਚ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ। PDF ਫਾਈਲ 1 MB ਤੋਂ ਘੱਟ ਹੋਣੀ ਚਾਹੀਦੀ ਹੈ.`,
    "d495cdb8315e12e3dc5fd6121ed69cbc": `ਗਲਤ IFSC ਕੋਡ`,
    "cd9f177eac6411501839f7868e38767b": `ਕੀ ਤੁਸੀਂ ਯਕੀਨਨ ਇਸ ਦਸਤਾਵੇਜ਼ ਨੂੰ ਹਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?`,
    "565d2daf0ccaf00d354669e7551b81dd": `ਪ੍ਰੋਫਾਈਲ ਵੇਰਵਿਆਂ ਦੀ ਸਮੀਖਿਆ ਕਰੋ`,
    "9895dd168483d1d34ab9ebfb2b6760be": `ਬੈਂਕ ਚੁਣੋ`,
    "deb5c3f30f3a8381367392d6608fbe68": `ਅਰਜ਼ੀ ਦੀ ਸਥਿਤੀ`,
    "970324882e5bbfd2deef9e5b7e2d1160": `ਐਪਲੀਕੇਸ਼ਨ ਡਾਉਨਲੋਡ ਕਰੋ`,
    "03b55d8ed61b625c209cc42ea0a35b8c": `ਤੁਹਾਡਾ ਧੰਨਵਾਦ!`,
    "8276f0b86b5ee98d03f2eafba3590bb0": `ਤੁਹਾਡੀ ਸੇਵਾ ਕਰਨ ਦਾ ਮੌਕਾ ਦੇਣ ਲਈ. ਤੁਹਾਡੀ ਅਰਜ਼ੀ ਨੂੰ ਸਫਲਤਾਪੂਰਵਕ ਜਮ੍ਹਾ ਕਰ ਦਿੱਤਾ ਗਿਆ ਹੈ.`,
    "ac3122aeddff176ae67d3df2b2198d19": `ਤੁਹਾਡਾ ਧੰਨਵਾਦ! ਤੁਹਾਡੀ ਅਰਜ਼ੀ ਤਸਦੀਕ ਕਰਨ ਲਈ ਜਮ੍ਹਾ ਕਰ ਦਿੱਤੀ ਗਈ ਹੈ, "ਲਾਗੂ ਕਾਰਜਾਂ" ਦੇ ਤਹਿਤ ਆਪਣੀ ਅਰਜ਼ੀ ਸਥਿਤੀ ਨੂੰ ਵੇਖੋ`,
    "73da163444abaee6a5c88532b8eda629": `ਤੁਹਾਡਾ ਤਜਰਬਾ ਕਿਵੇਂ ਰਿਹਾ?`,
    "74eb855e4de6fe58228f03006c02fd8a": `ਠੀਕ ਹੈ`,
    "d36ed38206da38c85af59039d18f1108": `<b> ਆਪਣੇ ਫੀਡਬੈਕ ਦਾ ਵਰਣਨ ਕਰੋ </ ਬੀ> (ਵਿਕਲਪਿਕ)`,
    "446e92f15cd245125ccd983ef968e692": `ਨਹੀਂ ਧੰਨਵਾਦ!`,
    "634a1a1ed07518476cb7a4beab87185a": `ਭਿਆਨਕ`,
    "f1b68d66337a81cfa0d2076171cba2a8": `ਬੁਰਾ`,
    "45802158e78dd9584161629098018fe8": `ਚੰਗਾ`,
    "5709082e0d4e7a2cb92c2348acf297be": `ਇਸ ਨੂੰ ਪਿਆਰ ਕੀਤਾ`,
    "c629e8ac2592361ec6474f07fb75f6fc": `ਤੁਹਾਡਾ ਫੀਡਬੈਕ ਸਾਨੂੰ ਸੁਧਾਰ ਦੇ ਨਵੇਂ ਮੌਕਿਆਂ ਦੀ ਖੋਜ ਕਰਨ ਦੀ ਆਗਿਆ ਦਿੰਦਾ ਹੈ ਅਤੇ ਇਹ ਸੁਨਿਸ਼ਚਿਤ ਕਰਨ ਵਿੱਚ ਤੁਹਾਡੇ ਕੋਲ ਵਧੀਆ ਤਜਰਬਾ ਹੈ.`,
    "871abe85eba547582d9e8f0f9c61efbd": `ਸਾਨੂੰ ਫੀਡਬੈਕ ਭੇਜਣ ਲਈ ਧੰਨਵਾਦ.`,
    "733d43480c8589b1368e5def6b480415": `ਲਾਗੂ ਕੀਤਾ`,
    "a5b739036b4d0b2bc966291747198d9b": `ਦਾਖਲਾ`,
    "0a4c051fb821b8e96d2bd564b893639b": `ਤੁਹਾਡੇ ਲਈ ਕੋਈ ਅਨੁਕੂਲਿਤ ਸਕੀਮਾਂ ਨਹੀਂ`,
    "9639e32cab248434a17ab32237cb3b71": `ਲਾਗੂ ਕਰੋ`,
    "3f68e67dc6c397aaa9d1c24c356f754f": `ਪ੍ਰਮਾਣਿਤ`,
    "d63b911814d17bb4d541141b8531a458": `ਇਹ ਯੋਜਨਾਵਾਂ ਤੁਹਾਡੀਆਂ "ਮੇਰੀਆਂ ਯੋਜਨਾਵਾਂ" ਵਿੱਚ ਸੁਰੱਖਿਅਤ ਕੀਤੀਆਂ ਗਈਆਂ ਹਨ`,
    "454fd3b5167e532f28f4e3d47cd901fa": `ਤੁਹਾਡੀ ਅਜੇ ਕੋਈ ਸੁਰੱਖਿਅਤ ਯੋਜਨਾ ਨਹੀਂ ਹੈ`,
    "5e22b63835aef7976f3ca019841b1b28": `ਕੀ ਤੁਸੀਂ ਇਸ ਯੋਜਨਾ ਨੂੰ ਹਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?`,
    "8454c7c12286671b47684cef824ffba6": `"ਧਿਆਨ! ਜੇ ਤੁਸੀਂ ਆਪਣਾ ਡੇਟਾ ਬਦਲਦੇ ਹੋ ਇਹ ਵੀ ਉਹੀ ਤੁਹਾਡੇ ਪ੍ਰੋਫਾਈਲ ਡੇਟਾ ਵਿੱਚ ਅਪਡੇਟ ਕੀਤਾ ਜਾਏਗਾ. ਕੀ ਤੁਸੀਂ ਜਾਰੀ ਰੱਖਣਾ ਚਾਹੁੰਦੇ ਹੋ?"`,
    "24a23d787190f2c4812ff9ab11847a72": `ਸਥਿਤੀ:`,
    "85896a744f6fd84cbcd7bddb31fae8d7": `ਕਾਰਵਾਈ:`,
    "ccb168b5a6a86eb100dc2dee754a316b": `ਕਾਰਨ:`,
    "85b7d770b94264f51f31d2d7a98f69f0": `ਅਰਜ਼ੀ ਵਾਪਸ ਲੈ`,
    "0ddc5e1c93fec761445a53ee9fd70285": `ਅਧਿਕਾਰ ਨੂੰ ਭੇਜੋ`,
    "7a4813490daea6bbe84ed02f9534570b": `ਐਪਲੀਕੇਸ਼ਨ ਅਤੇ ਲਗਾਵ ਪ੍ਰਮਾਣਿਤ`,
    "f9e39c8a1b3348b654b73fcdebeb3265": `ਅਰਜ਼ੀ ਅਜੇ ਪ੍ਰਵਾਨਗੀ ਨਹੀਂ ਦਿੱਤੀ ਗਈ`,
    "6b76127f99b6f29c2a725da125d98760": `ਲਾਭ ਵੰਡ`,
    "4296fd320beaf61dcf979ff634cf2f2d": `ਕਾਰਵਾਈ ਦੀ ਲੋੜ:`,
    "fc341b619c2671a430365edefc100ff1": `ਪੂਰੀ ਕਾਰਵਾਈਆਂ`,
    "4f4b8b7d27a33c83089f30dba4989be5": `ਐਪਲੀਕੇਸ਼ਨਾਂ ਵੇਖੋ`,
    "2f70605f8a2f6c01ac06fe0f6d32b44c": `ਕੀ ਤੁਸੀਂ ਯਕੀਨਨ ਆਪਣੀ ਅਰਜ਼ੀ ਵਾਪਸ ਲੈਣਾ ਚਾਹੁੰਦੇ ਹੋ?`,
    "6eaaa83f6b9b14dbed5e0779d91fffb5": `ਅਰਜ਼ੀ ਰੱਦ ਕਰ ਦਿੱਤੀ ਗਈ`,
    "6187927c14aae6cab0f87b55ef75edb1": `ਦੁਬਾਰਾ ਲਾਗੂ ਕਰੋ`,
    "864cacf59d640b51390faf29867c310e": `ਅਗਲੇ ਲਾਭ ਲਈ ਅੱਗੇ ਵਧੋ`,
    "16d2b386b2034b9488996466aaae0b57": `ਇਤਿਹਾਸ`,
    "c5b2992c0919083c2d8907f8fe1441f6": `ਸਮਾਜਿਕ-ਆਰਥਿਕ ਵੇਰਵੇ`,
    "d79c1042de4ec2d7e6d84d3a8f436765": `ਕਿੱਤਾ ਪ੍ਰੋਫਾਈਲ`,
    "3c8d3371dfe92bb990493d3dda09717c": `ਸਿੱਖਿਆ ਪ੍ਰੋਫਾਈਲ`,
    "181bfe2f225b876c687104878a6b43cc": `ਹਾਉਸਿੰਗ ਵੇਰਵਿਆਂ`,
    "f081b3a821bf13173ac4ec3468122a8c": `ਸਿਹਤ ਵੇਰਵੇ`,
    "fa284c99eeef0e1c874643e05a449214": `"ਧਿਆਨ! ਤੁਸੀਂ ਸਾਰੇ ਮੁੱਲਾਂ ਦੀ ਚੋਣ ਨਹੀਂ ਕਰ ਸਕਦੇ. ਇਸ ਮੁੱਲ ਨੂੰ ਨਾ ਖੋਲ੍ਹਣ ਤੋਂ ਪਹਿਲਾਂ ਇਕ ਹੋਰ ਮੁੱਲ ਚੁਣੋ."`,
    "311d38f551d45cb61e297748f08bb658": `ਤੁਹਾਡਾ ਡਾਟਾ ਸਫਲਤਾਪੂਰਵਕ ਸੁਰੱਖਿਅਤ ਕੀਤਾ ਗਿਆ ਹੈ`,
    "4500d073ef5972cc935a59ff0b752d8b": `ਤੱਕ ਵੈਧ`,
    "8de420ed8c345d37cf65a3494bff92ef": `ਵੋਟਰ ਕਾਰਡ`,
    "f6fb95a7bfd158a53680691f250e2273": `ਸਰਕਾਰੀ ਫੀਸ:`,
    "c51bae43a860107d2ddaddcc44c72355": `ਪਤਾ ਦਾ ਸਬੂਤ (ਕੋਈ ਵੀ)`,
    "a1cba2ed3414b72aa4d7651653d08900": `ਉਮਰ ਦਾ ਸਬੂਤ (ਕੋਈ ਵੀ)`,
    "330dd937743341b8c97a75ddbcfacf1b": `ਫਾਰਮ ਡਾਉਨਲੋਡ ਕਰਨ ਲਈ ਲਿੰਕ:`,
    "de259fed6ef60355d0dd031c4a97e945": `ਅਪਲੋਡ ਫਾਰਮ ਨਾਲ ਲਿੰਕ:`,
    "6204374d06a6ca36a20e887451db78af": `ਹੋਰ ਸੇਵਾਵਾਂ ਦੀ ਪੜਚੋਲ ਕਰੋ`,
    "98d047289f68350b76f20cd10f1db3d4": `ID ਦਾ ਸਬੂਤ`,
    "1a01812e1888f4eb6dca36e8b1d39c04": `ਡੀਓਬੀ ਦਾ ਸਬੂਤ`,
    "0dcd6f339c0f72875972d60324db52ff": `ਐਪਲੀਕੇਸ਼ਨ ਫੀਸ ਦੇ ਵੇਰਵੇ`,
    "133e72d94045f209d146dd6fc01de185": `ਫੀਸ ਦੇ ਵੇਰਵੇ`,
    "b6d11bb63dc2071d6cba2aaeba8c8a1d": `ਸਹੂਲਤ ਫੀਸ`,
    "719fec04166d6fa75f89cd29ad61fa8c": `ਟੈਕਸ`,
    "a876fbe73a6d039cb8c8566f6aa716d5": `ਕੁੱਲ ਮਾਤਰਾ`,
    "9d463ea3feb5afec51c20b4f62927ca3": `ਕੂਪਨ ਕੋਡ`,
    "620a92a00838e523161dab899b76c764": `ਭੁਗਤਾਨ ਕਰਨ ਲਈ ਅੱਗੇ ਵਧੋ`,
    "6f861b42317b2333c01faa9b555b7e14": `ਇਸ ਕੂਪਨ ਨਾਲ ਬਚਤ.`,
    "c6088ccfc87a825ceb53a15ce4d99510": `ਤੁਸੀਂ <Amount> ਦੀ ਕੁੱਲ ਛੂਟ ਪ੍ਰਾਪਤ ਕੀਤੀ ਹੈ`,
    "0e54fc6a9dba79c2bc03ac08f3bf4a27": `ਯੇ!`,
    "c1d9a46634bb89273f7d95a5577f15ad": `ਕੂਪਨ ਛੂਟ`,
    "1063e38cb53d94d386f21227fcd84717": `ਹਟਾਓ`,
    "ba8550da63869236a92fed41fba6e84f": `ਕੂਪਨ ਕੋਡ ਸਫਲਤਾਪੂਰਵਕ ਲਾਗੂ ਕੀਤਾ ਗਿਆ`,
    "f7a7e0e3897bac9ea4c83d53f6ad5a23": `ਗਲਤ ਕੂਪਨ ਕੋਡ.`,
    "90fe9a78f3ea30beeb6b7ef447d2b6dd": `ਕੂਪਨ ਕੋਡ ਸਫਲਤਾਪੂਰਵਕ ਹਟਾ ਦਿੱਤਾ ਗਿਆ`,
    "af1b98adf7f686b84cd0b443e022b7a0": `ਵਰਗ`,
    "11f18dd73f0c3d374d9d5c230cd43160": `ਨੌਜਵਾਨ ਹੁਨਰ`,
    "5a6c0d2579b31142130f20104fa58ea6": `ਸਰਟੀਫਿਕੇਟ`,
    "c482980d384a9d0e7bc39e1140270870": `ਵਿੱਤ`,
    "605669cab962bf944d99ce89cf9e58d9": `ਸਿਹਤ`,
    "5dac73aa49b9ccaca4b9b0d0ef615d10": `ਪੁਲਿਸ`,
    "b2ece3493eed05158a9cde4a0d2da2a5": `ਜਨਤਕ ਸ਼ਿਕਾਇਤ`,
    "94df2a6972ca1fa79411645fe9b42339": `ਸਹੂਲਤ`,
    "f5f0c488ed56cf789d19a13ffba8abe3": `ਆਵਾਜਾਈ`,
    "0db377921f4ce762c62526131097968f": `ਜਨਰਲ`,
    "bc34e4c4f36b0d66910a906449910f64": `ਕੀ ਤੁਸੀਂ ਯਕੀਨਨ ਇਸ ਸੇਵਾ ਨੂੰ ਹਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?`,
    "1e22827dff05f5a1c044993aff539403": `ਤੁਹਾਡੇ ਕੋਲ ਕੋਈ ਸੁਰੱਖਿਅਤ ਸੇਵਾਵਾਂ ਨਹੀਂ ਹੈ.`,
    "b08f17b81d3b9b4f6287b87c53cfc97a": `ਤੁਹਾਡੇ ਕੋਲ ਖਰੜੇ ਵਿੱਚ ਕੋਈ ਸੇਵਾਵਾਂ ਨਹੀਂ ਹਨ.`,
    "6f96ffbc03ad1b9ebb5c6e2ecfcbdc71": `ਤੁਸੀਂ ਅਜੇ ਤੱਕ ਕਿਸੇ ਵੀ ਸੇਵਾਵਾਂ ਨੂੰ ਨਹੀਂ ਲਾਗੂ ਕੀਤਾ ਹੈ.`,
    "daf87fe41645c86672e96857222a0909": `ਅਰਜ਼ੀ ਜਮ੍ਹਾ`,
    "54aeeea175c1509b7c56e0c984f9b4fa": `ਤੁਹਾਡੀ ਸੇਵਾ ਕਰਨ ਦਾ ਮੌਕਾ ਦੇਣ ਲਈ ਤੁਹਾਡਾ ਧੰਨਵਾਦ. ਤੁਹਾਡੀ ਅਰਜ਼ੀ ਨੂੰ ਸਫਲਤਾਪੂਰਵਕ ਜਮ੍ਹਾ ਕਰ ਦਿੱਤਾ ਗਿਆ ਹੈ.`,
    "f18c4dab2674eae1e8c287c683b66729": `ਅਰਜ਼ੀ ਦੀ ਮਿਤੀ`,
    "fb0728ffa9b40d444db91c818eb21a2b": `ਬਿਨੈਕਾਰ ਦਾ ਨਾਮ`,
    "e500e94f457ddd88824c88fda548bed2": `ਐਪਲੀਕੇਸ਼ਨ ਆਈਡੀ`,
    "ad0206b8eec486df629ce37169376f65": `ਭੂਮਿਕਾ ਅਤੇ ਜ਼ਿੰਮੇਵਾਰੀਆਂ:`,
    "6f2ad1cfe3c5b08cc53c62470f4cb1f7": `ਦਸਤਾਵੇਜ਼ ਲੋੜੀਂਦੇ ਹਨ:`,
    "1d83797411218ecca1e5ff4d4705dfb0": `ਚੋਣ ਪ੍ਰਕਿਰਿਆ:`,
    "ce9e1e989c012211d8bc83c5353b4495": `ਨੌਕਰੀ ਦੇ ਅਰਜ਼ੀ ਦੇ ਵੇਰਵੇ`,
    "18dcaa84c2ee447028f8450cd0380ba6": `ਅਕਾਦਮਿਕ ਵੇਰਵਾ:`,
    "93e9a018926429f6d0b0c032910a8eb6": `ਜੌਬ ਐਪਲੀਕੇਸ਼ਨ ਦੇ ਵੇਰਵੇ ਦੀ ਸਮੀਖਿਆ ਕਰੋ`,
    "6f906132d3c6b88b57225fdcf76f6e71": `ਅਕਸਰ ਪੁੱਛੇ ਜਾਂਦੇ ਸਵਾਲ`,
    "2d8d66b9c9b540fcb8384167f1565b64": `ਕਿਰਪਾ ਕਰਕੇ ਸਪੈਲਿੰਗ ਦੀ ਜਾਂਚ ਕਰੋ ਜਾਂ ਕਿਸੇ ਹੋਰ ਚੀਜ਼ ਦੀ ਭਾਲ ਕਰਨ ਦੀ ਕੋਸ਼ਿਸ਼ ਕਰੋ.`,
    "f73a506c52c2dba159fc0fbee823aace": `ਤੁਸੀਂ ਪਹਿਲਾਂ ਹੀ ਇਸ ਸਕੀਮ ਲਈ ਅਰਜ਼ੀ ਦਿੱਤੀ ਹੈ. ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੀਆਂ ਲਾਗੂ ਸਕੀਮਾਂ ਤੋਂ ਆਪਣੀ ਅਰਜ਼ੀ ਸਥਿਤੀ ਦੀ ਜਾਂਚ ਕਰੋ.`,
    "b73856e1c7ab3e647e28663d5e30fabe": `ਤੁਸੀਂ ਪਹਿਲਾਂ ਹੀ ਇਸ ਸਕੀਮ ਲਈ ਅਰਜ਼ੀ ਦਿੱਤੀ ਹੈ. ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੀਆਂ ਲਾਗੂ ਸੇਵਾਵਾਂ ਲਈ ਆਪਣੀ ਅਰਜ਼ੀ ਸਥਿਤੀ ਦੀ ਜਾਂਚ ਕਰੋ.`,
    "cd850a99c0895272f2cdf23586867b16": `ਤੁਸੀਂ ਪਹਿਲਾਂ ਹੀ ਇਸ ਸਕੀਮ ਲਈ ਅਰਜ਼ੀ ਦਿੱਤੀ ਹੈ. ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੀ ਨੌਕਰੀ ਦੀ ਸਥਿਤੀ ਨੂੰ ਆਪਣੀਆਂ ਨੌਕਰੀਆਂ ਤੋਂ ਵੇਖੋ.`,
    "4e0b8852fc3f3d1a2b4c24c4602766dd": `ਚਿੱਤਰ JPG, PNG, PDF ਫਾਈਲ ਵਿੱਚ ਅਤੇ 1MB ਤੋਂ ਘੱਟ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ।`,
    "5a9e7167eb5b7cb7bb46cf5c05eed59a": `ਪੜਤਾਲ`,
    "91412465ea9169dfd901dd5e7c96dd99": `ਅਪਲੋਡ ਕਰੋ`,
    "3835b71ace5673385a19d56bc938fda8": `ਫੀਲਡ ਫੋਟੋ`,
    "4386624773a0ef6de5a5de0dd6f8facd": `ਦਸਤਾਵੇਜ਼ ਕਿਸਮ ਦਾਖਲ ਕਰੋ`,
    "fdba3d2a9c707e50f4eec34cd6bdd0d6": `"ਆਪਣੇ ਗਾਹਕ ਜਾਣੋ" ਉਪਭੋਗਤਾ ਦੀ ਪਛਾਣ ਦੀ ਪਛਾਣ ਕਰਨ ਅਤੇ ਇਸਦੀ ਪਛਾਣ ਕਰਨ ਦੀ ਲਾਜ਼ਮੀ ਪ੍ਰਕਿਰਿਆ ਹੈ ਜੋ ਸਰਕਾਰੀ ਸਕੀਮ ਲਈ ਅਰਜ਼ੀ ਦੇ ਰਹੇ ਹਨ.`,
    "244773cb8f460196045c5b90659d2c9d": `ਤੁਹਾਡੀ ਅਰਜ਼ੀ ਤੁਹਾਡੇ "ਡਰਾਫਟ ਸਰਵਿਸਿਜ਼" ਵਿੱਚ ਸਫਲਤਾਪੂਰਵਕ ਸੇਵ ਕੀਤੀ ਗਈ ਹੈ`,
    "bbcb2990551820fc4678835c14a84503": `ਫੋਟੋ ਹਟਾਓ`,
    "78e3e2ba60b81cf6cc24e6baf89eaa81": `ਨਿੱਜੀ ਸਰਟੀਫਿਕੇਟ`,
    "88bcab139c5d6142b04bf77d7dd1c023": `ਤੁਸੀਂ ਪਹਿਲਾਂ ਹੀ ਇਸ ਸੇਵਾ ਲਈ ਅਰਜ਼ੀ ਦਿੱਤੀ ਹੈ. ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੀਆਂ ਲਾਗੂ ਸੇਵਾਵਾਂ ਲਈ ਆਪਣੀ ਅਰਜ਼ੀ ਸਥਿਤੀ ਦੀ ਜਾਂਚ ਕਰੋ.`,
    "bf8cd5900a65efaa89931067cb1d3775": `ਤੁਸੀਂ ਡਰਾਫਟ ਵਿੱਚ ਕਿਸੇ ਵੀ ਯੋਜਨਾਵਾਂ ਨੂੰ ਨਹੀਂ ਬਚਾਇਆ.`,
    "beab92ac3c8a798a35aea2ad0809313f": `ਮਹਿਮਾਨ ਵਜੋਂ ਜਾਰੀ ਰੱਖੋ`,
    "95884374c7752d972101b70854caffe6": `ਸਾਈਨ ਇਨ / ਸਾਈਨ ਅਪ ਕਰੋ`,
    "e44cb1c4a784a247f7296cc02f7f301c": `ਜ਼ਿਲ੍ਹਾ ਚੁਣੋ`,
    "9aa060dc3e3bb3ffff9908c5e713a80c": `ਤੁਸੀਂ ਅਜੇ ਕਿਸੇ ਵੀ ਸੇਵਾਵਾਂ ਲਈ ਅਰਜ਼ੀ ਨਹੀਂ ਦਿੱਤੀ ਹੈ.`,
    "383f419ea8c0b8142cbdc71e2e4b144a": `ਪਿਛਲਾ ਪ੍ਰਸ਼ਨ`,
    "fb188e808b65b9ee48a5c9d88fb92af2": `ਦੁਬਾਰਾ ਸ਼ੁਰੂ ਕਰੋ`,
    "db5eb84117d06047c97c9a0191b5fffe": `ਸਹਾਇਤਾ`,
    "bc80ae98530c6ba4e76ed34c58d81875": `ਮੋਬਾਈਲ ਐਪ ਲਈ Easygov ਡਾਊਨਲੋਡ ਕਰੋ`,
    "85ce0cae173233410acbc380a7e531cd": `ਕਿਰਪਾ ਕਰਕੇ ਯਾਦ ਰੱਖੋ ਕਿ ਇਹ ਪੰਨਾ ਵੈਬਸਾਈਟਾਂ ਦੇ ਵੈੱਬਸਾਈਟਾਂ ਲਈ ਲਿੰਕ ਵੀ ਪ੍ਰਦਾਨ ਕਰਦਾ ਹੈ. ਮੰਤਰਾਲੇ / ਵਿਭਾਗ / ਸੰਗਠਨ. ਇਨ੍ਹਾਂ ਵੈਬਸਾਈਟਾਂ ਦੀ ਸਮੱਗਰੀ ਸਬੰਧਤ ਸੰਗਠਨਾਂ ਦੀ ਮਲਕੀਅਤ ਰੱਖੀ ਜਾ ਸਕਦੀ ਹੈ ਅਤੇ ਉਨ੍ਹਾਂ ਨਾਲ ਕਿਸੇ ਹੋਰ ਜਾਣਕਾਰੀ ਜਾਂ ਸੁਝਾਅ ਲਈ ਸੰਪਰਕ ਕੀਤਾ ਜਾ ਸਕਦਾ ਹੈ`,
    "ad0a802ec8134e9210a5625933db50a7": `ਤਿਆਗ`,
    "26dbd93cbe6f1e56b1577d8e3cb7fda3": `ਸੇਵਾਵਾਂ ਚੁਣੋ`,
    "c6582b36b0f516684a873e8782517822": `ਸਕੀਮਾਂ ਅਤੇ ਸੇਵਾਵਾਂ ਦੀ ਚੋਣ ਕਰੋ`,
    "b98287be44e1230cf144aa3af08ac82f": `ਤੁਸੀਂ ਅਜੇ ਤੱਕ ਕਿਸੇ ਵੀ ਯੋਜਨਾਵਾਂ ਲਈ ਨਾਮਜ਼ਦ ਨਹੀਂ ਹੋ`,
    "449d3cf7dd09172024ebe93872410f0f": `ਐਪਲੀਕੇਸ਼ਨ ਵੇਖੋ`,
    "025d9b3a3c2a6a27d04c57c1d7cabc9f": `ਕੋਈ ਕਾਰਵਾਈ ਦੀ ਲੋੜ ਨਹੀਂ`,
    "06df33001c1d7187fdd81ea1f5b277aa": `ਕਾਰਵਾਈਆਂ`,
    "adff901e227bf2b2200c4767fe773836": `ਕਾਰਵਾਈਆਂ ਦੀ ਲੋੜ ਹੈ`,
    "4ff84d2ee02fe6ef0ca2edc3cdecc0ed": `ਕਾਰਵਾਈਆਂ ਲੋੜੀਂਦੀਆਂ ਹਨ`,
    "c08c272bc5648735d560f0ba5114a256": `ਸੰਪਰਕ ਸਹਾਇਤਾ`,
    "fd2f820569d83784238e1027897b463e": `ਆਪਣਾ ਨਾਮ ਦਰਜ ਕਰੋ`,
    "cda83d00666a7bfa8cde6808de4ef994": `ਸੰਪਰਕ ਨੰਬਰ`,
    "a4d3b161ce1309df1c4e25df28694b7b": `ਜਮ੍ਹਾਂ ਕਰੋ`,
    "4c2a8fe7eaf24721cc7a9f0175115bd4": `ਸੁਨੇਹਾ`,
    "68d390535e8a7120e3f6942d8a2214a0": `ਆਪਣਾ ਸੁਨੇਹਾ ਦਰਜ ਕਰੋ`,
    "2f644b4a27e68f505c4f4ac2ffe3a8ac": `ਕਿਰਪਾ ਕਰਕੇ ਸਿਰਫ ਅੱਖਰ, ਨੰਬਰ, ਕਾਮੇ, -, _, /`,
    "58f7a43f2c3bd7854d99fbbbc3fa4ae9": `ਸਿਰਫ ਅੱਖਰ ਦੀ ਇਜਾਜ਼ਤ ਹੈ.`,
    "4906d981bb592b32f6982fb4187c7b60": `ਇੱਕ ਵੈਧ ਆਈਐੱਫਐੱਸਸੀ ਕੋਡ ਦਰਜ ਕਰੋ.`,
    "562d3d08919ae105c3ebc42d9607d266": `ਕਿਰਪਾ ਕਰਕੇ ਸਿਰਫ ਅੱਖਰ ਜਾਂ ਨੰਬਰ ਦਾਖਲ ਕਰੋ`,
    "fab2bb673b21bef95ee70b99dbd7f9fc": `ਸਿਰਫ 11 ਅੱਖਰਾਂ ਦੀ ਆਗਿਆ ਹੈ.`,
    "a7eb433676475fee54727c14ad880b8c": `ਸਿਰਫ 150 ਅੱਖਰਾਂ ਦੀ ਆਗਿਆ ਹੈ.`,
    "7c58cde3b827bc7d57cbf77865046169": `ਕਿਰਪਾ ਕਰਕੇ ਸਿਰਫ ਨੰਬਰ ਦਾਖਲ ਕਰੋ`,
    "544b0264d55a1e4cf476b279240d9be4": `ਸਿਰਫ ਦਸ ਅੰਕ ਦੀ ਆਗਿਆ ਹੈ. ਕਿਰਪਾ ਕਰਕੇ ਅਗੇਤਰ 0 ਜਾਂ +91 ਨਾ ਕਰੋ`,
    "73f0d980e48b8141e0776e02d96a872f": `ਸਿਰਫ ਬਾਰਾਂ ਅੰਕ ਦੀ ਆਗਿਆ ਹੈ.`,
    "0ce98bd973419ff33921a2275e7903b9": `ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਵੈਧ ਪੈਨ ਨੰਬਰ ਦਰਜ ਕਰੋ.`,
    "c6f91b305e91a0053748310de170a73c": `ਸਿਰਫ ਛੇ ਅੰਕ ਦੀ ਆਗਿਆ ਹੈ`,
    "9f44bd93c8988c682d5ef5369fd11f47": `ਕਿਰਪਾ ਕਰਕੇ ਵੈਧ ਈਮੇਲ ਦਰਜ ਕਰੋ`,
    "10803b83a68db8f7e7a33e3b41e184d0": `ਜਨਮ ਤਾਰੀਖ`,
    "ac598199ea0dd028ade4677a435caf4b": `ਇੱਕ ਵੈਧ ਆਧਾਰ ਨੰਬਰ ਦਰਜ ਕਰੋ`,
    "d0de720ccdffea8490dfd2893c50ebc5": `ਇਹ {{displayName}} ਤੋਂ ਵੱਡਾ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ`,
    "ced6e171e13ebd5c37415c822706b9d4": `ਇਹ ਘੱਟ {{displayName}} ਤੋਂ ਘੱਟ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ`,
    "d1a1cb2bd14fdd9eec972f162cfd1ee0": `ਇਹ {{displayName}} ਦੇ ਬਰਾਬਰ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ`,
    "9483135517d156229d0439dff74b1ca0": `ਇਹ {{displayName}} ਤੋਂ ਵੱਧ ਜਾਂ ਬਰਾਬਰ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ`,
    "01e9cd748e852b1ae389b980bff195b0": `ਇਹ {{displayName}} ਤੋਂ ਘੱਟ ਜਾਂ ਬਰਾਬਰ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ`,
    "dc236871b26ba387e1284445caa99df4": `ਤੁਹਾਡੀ ਸੇਵਾ ਕਰਨ ਦਾ ਮੌਕਾ ਦੇਣ ਲਈ.`,
    "2102fff7bb4de45c9e616e3f01b6810f": `ਤੁਹਾਡੀ ਅਰਜ਼ੀ ਨੂੰ ਸਫਲਤਾਪੂਰਵਕ ਜਮ੍ਹਾ ਕਰ ਦਿੱਤਾ ਗਿਆ ਹੈ.`,
    "dc9466f3d424b4acba2fc5c9f58d4f8a": `ਮਾਫ ਕਰਨਾ, ਤੁਸੀਂ ਯੋਗ ਨਹੀਂ ਹੋ`,
    "a9198ed7d89e246a80078db84d2722e8": `ਤੁਹਾਡਾ ਫੋਨ ਨੰਬਰ ਜਾਂ ਪਾਸਵਰਡ ਮੇਲ ਨਹੀਂ ਖਾਂਦਾ`,
    "8ac0a98c8c2f2c86396e34b6b5d63e38": `ਉਸੇ ਨਾਮ ਦੇ ਨਾਲ ਪ੍ਰੋਫਾਈਲ ਪਹਿਲਾਂ ਹੀ ਮੌਜੂਦ ਹੈ`,
    "4ae112e33b3189c9fa04369b51259d76": `ਉਸੇ ਨਾਮ ਅਤੇ ਰਿਸ਼ਤੇ ਦੇ ਨਾਲ ਪ੍ਰੋਫਾਈਲ ਪਹਿਲਾਂ ਹੀ ਮੌਜੂਦ ਹੈ`,
    "b0fb09a28a4a52a1b3ca2656dec0435e": `ਕੀ ਤੁਸੀਂ ਇਸ ਦਸਤਾਵੇਜ਼ ਨੂੰ ਹਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?`,
    "e384b6a4760d80be609d897414cfe680": `ਤੁਸੀਂ ਰਜਿਸਟਰਡ ਉਪਭੋਗਤਾ ਨਹੀਂ ਹੋ, ਤੁਸੀਂ ਜਾਂ ਤਾਂ ਅੱਗੇ ਵਧਣ ਲਈ "ਸਾਈਨ ਅਪ" ਜਾਂ "OTP ਨਾਲ ਲੌਗਇਨ ਕਰੋ" ਦੇ ਸਕਦੇ ਹੋ`,
    "c2cd85ffa55b2ec9b049b7eb131d3a9f": `ਪਾਸਵਰਡ ਦਰਜ ਕੀਤੇ ਪਾਸਵਰਡ ਨਹੀਂ ਕੀਤੇ ਗਏ. ਫਿਰ ਕੋਸ਼ਿਸ਼ ਕਰੋ`,
    "3a816ee692e09b3cac6ef18e14071bd4": `ਤੁਹਾਡਾ ਪਾਸਵਰਡ ਸਫਲਤਾਪੂਰਵਕ ਸੈੱਟ ਕੀਤਾ ਗਿਆ ਹੈ`,
    "764d4847b734121ceb58e5c8195fa5b6": `ਤੁਹਾਡੀ ਫੀਡਬੈਕ ਸਾਨੂੰ ਸੁਧਾਰ ਕਰਨ ਦੇ ਨਵੇਂ ਮੌਕਿਆਂ ਦੀ ਖੋਜ ਕਰਨ ਦੀ ਆਗਿਆ ਦਿੰਦੀ ਹੈ ਅਤੇ ਇਹ ਸੁਨਿਸ਼ਚਿਤ ਕਰਨ ਅਤੇ ਇਹ ਸੁਨਿਸ਼ਚਿਤ ਕਰਨ ਵਿੱਚ ਤੁਹਾਡੇ ਕੋਲ ਵਧੀਆ ਤਜਰਬਾ ਹੈ.`,
    "701d895fbc64569aa143ca6c44274139": `ਤੁਸੀਂ OTP ਪੈਦਾ ਕਰਨ ਦੀ ਵੱਧ ਤੋਂ ਵੱਧ ਸੀਮਾ ਨੂੰ ਪਾਰ ਕਰ ਲਿਆ ਹੈ, ਕਿਰਪਾ ਕਰਕੇ ਕਿਸੇ ਸਮੇਂ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ.`,
    "0bdfc4d562c9b0ccfaa4c0f6d4cac98f": `ਤੁਸੀਂ ਅਜੇ ਕਿਸੇ ਵੀ ਯੋਜਨਾਵਾਂ ਲਈ ਅਰਜ਼ੀ ਨਹੀਂ ਦਿੱਤੀ ਹੈ.`,
    "4391bf5189e3e13c4aa4cb314c605ac8": `ਤੁਸੀਂ ਅਜੇ ਤੱਕ ਕਿਸੇ ਵੀ ਯੋਜਨਾਵਾਂ ਨੂੰ ਸੁਰੱਖਿਅਤ ਨਹੀਂ ਕੀਤਾ ਹੈ.`,
    "92202504591d549b89340d26ffc0753a": `ਇਹ ਨੌਕਰੀ ਤੁਹਾਡੇ "ਮੇਰੇ ਨੌਕਰੀਆਂ" ਵਿੱਚ ਬਚ ਗਈ ਹੈ`,
    "af6ebe0c0e89201cb2294f32955f7b15": `ਇਕ ਸਮੇਂ ਸਿਰਫ 20 ਦਸਤਾਵੇਜ਼ਾਂ ਦੀ ਆਗਿਆ ਹੈ.`,
    "b1481f771aee527fd910fa8d8672cd19": `ਕੀ ਤੁਸੀਂ ਯਕੀਨਨ ਇਸ ਨੋਟੀਫਿਕੇਸ਼ਨ ਨੂੰ ਹਟਾਉਣਾ ਚਾਹੁੰਦੇ ਹੋ?`,
    "06a6b009c19d5816574cdc7800d10eb5": `ਤੁਹਾਡੀ ਬੇਨਤੀ ਸਫਲਤਾਪੂਰਵਕ ਉਭਾਈ ਗਈ ਹੈ.`,
    "b941bc7a9d4fe7a8c7c31ed440754285": `ਸਮੀਖਿਆ ਅਰਜ਼ੀ`,
    "e56b98da835e8f4a018502b121520ac7": `ਬਾਕੀ ਕੋਸ਼ਿਸ਼ਾਂ`,
    "416828262df288445e72ca1d3661f993": `ਚੇਤਾਵਨੀ - ਲਗਾਤਾਰ 4 ਲਗਾਤਾਰ ਅਸਫਲ ਕੋਸ਼ਿਸ਼ਾਂ ਤੋਂ ਬਾਅਦ, ਤੁਹਾਡਾ ਖਾਤਾ ਬੰਦ ਹੋ ਜਾਵੇਗਾ`,
    "9f978addf10226b03278e4932348ba98": `ਸਿਰਫ 40 ਅੱਖਰਾਂ ਦੀ ਆਗਿਆ ਹੈ.`,
    "69f076c339373e00e9e841da965f4208": `ਆਧਾਰ ਅਪਡੇਟ ਕਰੋ`,
    "48a82e0b2bd5818f110e55bb6f14aedc": `ਹੇਠ ਲਿਖੀ ਜਾਣਕਾਰੀ ਸਰਕਾਰੀ ਰਿਕਾਰਡ ਨਾਲ ਮੇਲ ਨਹੀਂ ਖਾਂਦੀ`,
    "5c37ad5c0dd6e318af899b3c8fcbe886": `ਮੁਆਫ ਕਰਨਾ, ਅਸੀਂ ਸਰੋਤ ਪ੍ਰਣਾਲੀ ਤੋਂ ਤੁਹਾਡੇ ਡੇਟਾ ਨੂੰ ਪ੍ਰਾਪਤ ਕਰਨ ਵਿੱਚ ਅਸਮਰੱਥ ਹਾਂ. ਤੁਸੀਂ ਬਿਨਾਂ ਤਸਦੀਕ ਕੀਤੇ ਜਾਂ ਬਾਅਦ ਵਿੱਚ ਕੋਸ਼ਿਸ਼ ਕਰੋ.`,
    "cdb2033fc9a30d6d6c1abe401cd1e37b": `ਬਾਅਦ ਵਿੱਚ ਕੋਸ਼ਿਸ਼ ਕਰੋ`,
    "f8d855b26faffa0f8779fb44632d6bc1": `ਅਵੈਧ ਕੈਪਟਚਾ ਕੋਡ`,
    "d53b4d564b9054db9b4b4a50e713197f": `ਕਿਰਪਾ ਕਰਕੇ ਇੱਥੇ ਕੈਪਚਰ ਦਾਖਲ ਕਰੋ`,
    "ed001fb3ff7eaca9bbfbaacddc9d5a72": `ਕੋਈ ਇਤਿਹਾਸ ਨਹੀਂ ਮਿਲਿਆ`,
    "4934d9bc9ebd024d4106d5e1f093ae79": `ਯਾਤਰੀ ਗਿਣਦੇ ਹਨ`,
    "f7ce7cb4b450acfe0b4cef36c3bdec2d": `ਤੁਹਾਡਾ ਬੈਂਕ ਖਾਤਾ ਇਸ ਸਮੇਂ ਕਿਰਿਆਸ਼ੀਲ ਹੈ. ਤੁਸੀਂ ਅੱਗੇ ਵਧ ਸਕਦੇ ਹੋ ਪਰ ਲਾਭ ਪ੍ਰਾਪਤ ਕਰਨ ਲਈ ਆਪਣੇ ਬੈਂਕ ਖਾਤੇ ਨੂੰ ਸਰਗਰਮ ਕਰ ਸਕਦੇ ਹੋ, ਜੇ ਤੁਹਾਡੀ ਅਰਜ਼ੀ ਨੂੰ ਪ੍ਰਵਾਨਗੀ ਦੇਵੇ.`,
    "df9e5c88861b08c33b2d46de8d7037e9": `ਤੁਹਾਡਾ ਬੈਂਕ ਖਾਤਾ ਇਸ ਸਮੇਂ ਰੱਦ ਕਰ ਦਿੱਤਾ ਗਿਆ ਹੈ. ਤੁਸੀਂ ਅੱਗੇ ਵਧ ਸਕਦੇ ਹੋ ਪਰ ਲਾਭ ਪ੍ਰਾਪਤ ਕਰਨ ਲਈ ਆਪਣੇ ਬੈਂਕ ਖਾਤੇ ਨੂੰ ਸਰਗਰਮ ਕਰ ਸਕਦੇ ਹੋ, ਜੇ ਤੁਹਾਡੀ ਅਰਜ਼ੀ ਨੂੰ ਪ੍ਰਵਾਨਗੀ ਦੇਵੇ.`,
    "3fc95e40b475f7269e64f25a1c4aef3b": `ਤੁਹਾਡਾ ਬੈਂਕ ਖਾਤਾ ਤੁਹਾਡੇ ਆਧਾਰ ਨਾਲ ਜੁੜਿਆ ਨਹੀਂ ਹੈ. ਤੁਸੀਂ ਅੱਗੇ ਵਧ ਸਕਦੇ ਹੋ ਪਰ ਆਪਣਾ ਬੈਂਕ ਖਾਤਾ ਆਪਣੇ ਆਧਾਰ ਨਾਲ ਜੁੜੇ ਹੋਏ ਲਾਭ ਪ੍ਰਾਪਤ ਕਰਨ ਲਈ ਇਸ ਤੋਂ ਜਲਦੀ ਨਾਲ ਜੁੜੇ ਹੋਏ, ਜੇ ਤੁਹਾਡੀ ਅਰਜ਼ੀ ਨੂੰ ਮਨਜ਼ੂਰ ਹੋ ਜਾਂਦਾ ਹੈ.`,
    "1c35d003a65da48bf9f6d6fc69271d5a": `ਮੁਆਫ ਕਰਨਾ, ਤੁਹਾਡੀ ਅਰਜ਼ੀ ਇੱਕ ਸਿਸਟਮ ਅਸ਼ੁੱਧੀ ਦੇ ਕਾਰਨ ਜਮ੍ਹਾਂ ਨਹੀਂ ਕੀਤੀ ਜਾ ਸਕੀ. ਬਾਅਦ ਵਿੱਚ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ ਜੀ.`,
    "8e437cbd840bce5c5edc0ef8164de6b7": `ਜੇ ਤੁਸੀਂ ਆਪਣੇ ਵੇਰਵਿਆਂ ਨੂੰ ਅਪਡੇਟ ਜਾਂ ਬਦਲਦੇ ਹੋ ਤਾਂ ਤੁਹਾਡੇ ਡੇਟਾ ਦੀ ਅਣ-ਸੰਚਤ ਹੋ ਜਾਵੇਗਾ ਅਤੇ ਤੁਹਾਨੂੰ ਅਪਡੇਟ ਕੀਤੇ ਡਾਟੇ ਲਈ ਦਸਤਾਵੇਜ਼ ਦਾ ਸਬੂਤ ਅਪਲੋਡ ਕਰਨ ਲਈ ਕਿਹਾ ਜਾਵੇਗਾ. ਜਾਰੀ ਰੱਖਣ ਲਈ "ਅੱਗੇ" ਤੇ ਕਲਿਕ ਕਰੋ.`,
    "b010e3be9f81072a9808ed4a5dd0e45b": `ਹੇਠ ਦਿੱਤੀ ਜਾਣਕਾਰੀ ਤੁਹਾਡੇ ਦੁਆਰਾ ਦਿੱਤੇ ਵੇਰਵਿਆਂ ਨਾਲ ਸਬੰਧਤ ਵੇਰਵਿਆਂ ਨਾਲ ਮੇਲ ਨਹੀਂ ਖਾਂਦੀ:`,
    "01acb0344944f11d68aeecc9f5b574ea": `ਕੀ ਤੁਸੀਂ ਜਵਾਬ ਦੇ ਕੇ ਅੱਗੇ ਵਧਣਾ ਚਾਹੁੰਦੇ ਹੋ?`,
    "4ff70f7b2e5a74415de360150a6e59d3": `ਤੁਹਾਡਾ`,
    "bd7aaae9a27a2a4d71214c002bcf50ce": `ਰਿਕਾਰਡ ਨਾਲ ਮੇਲ ਨਹੀਂ ਖਾਂਦਾ.`,
    "ebfd339b0ac14aa79b325b53f06bd375": `ਤੁਹਾਡੇ ਦੁਆਰਾ ਦਿੱਤੀ ਗਈ ਜਾਣਕਾਰੀ ਤੁਹਾਡੇ ਆਧਾਰ ਵੇਰਵਿਆਂ ਨਾਲ ਮੇਲ ਨਹੀਂ ਖਾਂਦੀ. ਤੁਸੀਂ ਅੱਗੇ ਨਹੀਂ ਵਧ ਸਕਦੇ.`,
    "1e410e1b9903e7a99b93857f536b7e1d": `ਪ੍ਰਮਾਣਿਤ ਨਹੀ`,
    "28b266f2bf0a4ecc2dec3b6ca24fcb9b": `ਅਪਲੋਡ ਕਰੋ`,
    "68982db8041ee52dd9e5a6e527c8a1fa": `ਦੁਬਾਰਾ ਫਸਲ`,
    "e7bf7d83a37666fd8749e433189b1c27": `ਕ੍ਰਿਪਾ ਕਰਕੇ ਉਡੀਕ ਕਰੋ`,
    "c389f9e7d5ea93af9deb2e8f1f9c6d89": `ਹੋਰ ਜਾਣਨ ਲਈ {{"0x0001F6C8"}} 'ਤੇ ਕਲਿੱਕ ਕਰੋ.`,
    "f17816bcbe79e18bee49388fe84d2876": `OTP ਦੀ ਮਿਆਦ ਖਤਮ ਹੋ ਗਈ.`,
    "1531d9dc798857eb85345d349bffc99b": `ਵਾਪਸ`,
    "7939de8a9958e01fb1f172e21c5a7a85": `ਕਿਰਪਾ ਕਰਕੇ ਸਹੀ ਜਾਣਕਾਰੀ ਪ੍ਰਦਾਨ ਕਰੋ, ਇਹ ਸਾਨੂੰ ਤੁਹਾਨੂੰ ਦਿਖਾਉਣ ਵਿੱਚ ਸਹਾਇਤਾ ਕਰੇਗੀ ਸਕੀਮ ਤੁਸੀਂ ਯੋਗ ਹੋ.`,
    "bf58c4a9eac8e0ec82c158c3adfcd609": `ਯੋਜਨਾਵਾਂ ਵੱਖ ਵੱਖ ਸ਼੍ਰੇਣੀਆਂ ਵਿੱਚ ਸ਼੍ਰੇਣੀਬੱਧ ਕੀਤੀਆਂ ਗਈਆਂ ਹਨ. ਤੁਸੀਂ ਆਪਣੀ ਪਸੰਦ ਅਨੁਸਾਰ ਯੋਜਨਾਵਾਂ ਦੀ ਜਾਂਚ ਕਰ ਸਕਦੇ ਹੋ`,
    "09349e778786f7ed758021ddbed12ca6": `ਇਹ ਜਾਣਕਾਰੀ {Intent} ਸ਼੍ਰੇਣੀ ਦੇ ਅਧੀਨ ਯੋਗ ਸਕੀਮਾਂ ਨੂੰ ਲੱਭਣ ਵਿੱਚ ਸਾਡੀ ਮਦਦ ਕਰੇਗੀ।`,
    "0d45c019e7e9237a316f0f16824015d2": `ਤੁਹਾਡਾ ਪ੍ਰੋਫਾਈਲ ਡੇਟਾ ਵੱਖ ਵੱਖ ਟੈਬਾਂ ਦੇ ਤਹਿਤ ਸਟੋਰ ਕੀਤਾ ਜਾਂਦਾ ਹੈ. ਤੁਸੀਂ ਆਪਣੀ ਵੀ ਪ੍ਰੋਫਾਈਲ ਜਾਣਕਾਰੀ ਨੂੰ ਸੰਪਾਦਿਤ ਕਰ ਸਕਦੇ ਹੋ.`,
    "3f52884ec08606e6e73426eaec8de195": `ਇਹ ਜਾਣਕਾਰੀ ਤੁਹਾਨੂੰ ਇਸ ਸਕੀਮ ਦੇ ਤਹਿਤ ਇਸ ਸਕੀਮ ਅਤੇ ਲਾਭ ਪ੍ਰਕਿਰਿਆ ਦਾ ਲਾਭ ਲੈਣ ਲਈ ਸਹੀ ਲਾਭ ਬਾਰੇ ਵੇਰਵੇ ਪ੍ਰਦਾਨ ਕਰਨ ਵਿੱਚ ਸਹਾਇਤਾ ਕਰੇਗੀ.`,
    "c389f9e7d5ea93af9deb2e8f1f9c6d89": `ਹੋਰ ਜਾਣਨ ਲਈ {{"0x0001F6C8"}} ਤੇ ਕਲਿੱਕ ਕਰੋ.`,
    "5cc2bdab3db5aa21633656a0d55f7477": `ਤੁਸੀਂ ਅਰਜ਼ੀ ਦੇ ਨਾਲ ਅੱਗੇ ਨਹੀਂ ਵਧ ਸਕਦੇ`,
    "d5de543c0e83073f05a846d62cc83c81": `ਉਸੇ ਹੀ ਖੇਤਰ ਲਈ ਪਹਿਲਾਂ ਤੋਂ ਅਪਲੋਡ ਕੀਤਾ ਦਸਤਾਵੇਜ਼.`,
    "fca8da28cbcbe094dc646872e7cdbc7b": `ਯੋਗ ਦਰਜ ਕਰੋ`,
    "6fa2260c737f40fa1a3f63274c8fca48": `ਤੁਸੀਂ ਅੱਗੇ ਨਹੀਂ ਵਧ ਸਕਦੇ. ਜਾਂ ਤਾਂ ਤੁਸੀਂ ਆਪਣੇ ਵੇਰਵੇ ਨੂੰ ਪ੍ਰੋਫਾਈਲ ਵਿਚ ਜਾਂ ਆਪਣੇ ਆਧਾਰ ਕਾਰਡ ਵਿਚ ਸਹੀ ਕਰ ਸਕਦੇ ਹੋ`,
    "3272e327ba9c82197c5c982487224925": `ਮੁਆਫ ਕਰਨਾ, ਅਸੀਂ ਸਰੋਤ ਪ੍ਰਣਾਲੀ ਤੋਂ ਤੁਹਾਡੇ ਡੇਟਾ ਨੂੰ ਪ੍ਰਾਪਤ ਕਰਨ ਵਿੱਚ ਅਸਮਰੱਥ ਹਾਂ. ਬਾਅਦ ਵਿੱਚ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ ਜੀ.`,
    "586637318049e6dbb03e991bc45cba06": `ਆਪਣੇ ਖਾਤੇ ਨੂੰ ਮਿਟਾਉਣ ਲਈ ਤੁਹਾਨੂੰ ਪਹਿਲਾਂ ਆਪਣੇ ਖਾਤੇ ਨਾਲ ਜੁੜੇ ਸਾਰੇ ਪ੍ਰੋਫਾਈਲ ਮਿਟਾਉਣੇ ਪੈਣਗੇ.`,
    "ce6d6092569f3f247659ceb51d57be95": `ਤੁਸੀਂ {{profileName}} ਪ੍ਰੋਫਾਈਲ ਨੂੰ ਮਿਟਾ ਨਹੀਂ ਸਕਦੇ ਹੋ ਕਿਉਂਕਿ ਤੁਹਾਡੀ ਅਰਜ਼ੀ ਜਾਂ ਨਾਮਜ਼ਦ ਯੋਜਨਾਵਾਂ ਵਿੱਚ ਤੁਹਾਡੇ ਕੋਲ ਚੱਲ ਰਹੀ ਐਪਲੀਕੇਸ਼ਨ ਹੈ`,
    "8d258e398e2dbc8099c984258fadce2a": `ਇੱਕ OTP ਤੁਹਾਡੇ ਰਜਿਸਟਰਡ ਮੋਬਾਈਲ ਨੰਬਰ ਤੇ ਭੇਜਿਆ ਗਿਆ ਹੈ (xxx xxx {{phoneNumber}})`,
    "abb2e3d13ded4cea9275beafacd9f4c8": `ਇਸ ਸਕੀਮ ਵਿਚ ਆਪਣੀ ਦਿਲਚਸਪੀ ਵਧਾਉਣ ਲਈ, "ਆਪਣੀ ਦਿਲਚਸਪੀ ਪੈਦਾ ਕਰਨ 'ਤੇ ਕਲਿਕ ਕਰੋ ਅਤੇ ਸਰਕਾਰ ਇਸ ਯੋਜਨਾ ਦਾ ਲਾਭ ਦੇਣ ਲਈ ਤੁਹਾਡੀ ਪੂਰੀ ਤਰ੍ਹਾਂ ਪਹੁੰਚੇਗੀ`,
    "493de8e0a6b5d4a10af30cf3105b9e1f": `ਆਪਣੀ ਦਿਲਚਸਪੀ ਪੈਦਾ ਕਰੋ`,
    "c9b0246a8f3989724ec4958f91e380a7": `ਸਕੀਮ ਦੇ ਵੇਰਵੇ`,
    "804ca01b219522a88d26c3002632a00e": `ਵੇਰਵਿਆਂ ਨੂੰ ਸਾਂਝਾ ਕਰਨ ਲਈ ਧੰਨਵਾਦ.`,
    "04b6d9b7eb73749476d958b2133f4bbe": `ਅਸੰਭਵਤਾ ਦਾ ਸਰਵੇਖਣ`,
    "a5661f68fc78a5d4f15afa057fec7a9f": `ਅੱਗੇ ਜਾਣ ਲਈ ਤੁਹਾਨੂੰ ਸਰਵੇਖਣ ਵਿੱਚ ਆਪਣੇ ਵੇਰਵੇ ਅਪਡੇਟ ਕਰਨ ਦੀ ਜ਼ਰੂਰਤ ਹੈ.`,
    "1e4531d1dc128abbc00b48e5c7b6ef48": `ਅੱਪਡੇਟ ਸਰਵੇਖਣ`,
    "362e532b6ad1fda489c5909d437f02a3": `ਅਸੀਂ ਤੁਹਾਡੇ ਵੇਰਵੇ ਲਿਆ ਰਹੇ ਹਾਂ, ਕਿਰਪਾ ਕਰਕੇ ਉਡੀਕ ਕਰੋ ..`,
    "8a1b3c7d62e2d60d5be34d8e36a9768f": `ਵਾਪਸ ਵਿਅਕਤੀਗਤ ਲੌਗਇਨ ਤੇ`,
    "6737d50489b1cf1d4408e66b8591ca38": `ਤੁਹਾਡੇ ਕੋਲ ਤੁਹਾਡੀ ਸਕੀਮ ਦੀ ਅਰਜ਼ੀ 'ਤੇ ਕਾਰਵਾਈਆਂ ਦੀ ਬਕਾਇਆ ਹੈ. ਆਪਣੀ ਅਰਜ਼ੀ ਦੇ ਨਾਲ ਅੱਗੇ ਵਧਣ ਲਈ ਬਕਾਇਆ ਕਿਰਿਆਵਾਂ ਨੂੰ ਪੂਰਾ ਕਰੋ.`,
    "e37c99137b7396277137c1f73f8a86d5": `ਸੀਈਜੀ ਦਾ ਘੀਆ ਸੰਬੰਧਤ`,
    "706c7a936b977ec3c4e6603f888e3fce": `EasyGov ਦੁਆਰਾ ਸੰਚਾਲਿਤ`,
    "598d42810b342196884f255912974d69": `ਐਸਆਈ. ਨੰਬਰ`,
    "60e4e81c6f9c1938d77e8ded6d64643a": `ਸਕਰੀਨ ਰੀਡਰ`,
    "15bbb9d0bbf25e8d2978de1168c749dc": `ਵੈੱਬਸਾਈਟ`,
    "21d587949a2a2e89c958b622e244b405": `ਮੁਫਤ / ਵਪਾਰਕ`,
    "d5c03f7b855d442a2a7f24024cf89960": `ਗੈਰ ਵਿਜ਼ੂਅਲ ਡੈਸਕਟਾਪ ਐਕਸੈਸ (ਐਨਵੀਡੀਏ)`,
    "f956e16f65a3a937e5ecfe4195d17d0d": `(ਬਾਹਰੀ ਵੈਬਸਾਈਟ ਜੋ ਇਕ ਨਵੀਂ ਵਿੰਡੋ ਵਿਚ ਖੁੱਲ੍ਹਦੀ ਹੈ)`,
    "b24ce0cd392a5b0b8dedc66c25213594": `ਮੁਫਤ`,
    "c8ed8ec3dc8b21290b5d28ca56344553": `ਜਬਾੜੇ`,
    "b2788e1ab6be65f1c38bf7d23cd081f1": `ਵਪਾਰਕ`,
    "ec18aa8148f59475130ff217d693b465": `ਵਿੰਡੋ-ਅੱਖਾਂ`,
    "d96a273ceb2b5f83d710081d9c38440a": `ਸਿਸਟਮ ਪਹੁੰਚ ਜਾਣ ਲਈ`,
    "c932fd98147348de9974252a0efcc7cb": `WebAnywhere`,
    "540431e2b3234a8698b4044f15ef1fff": `ਪੀਡੀਐਫ ਸਮੱਗਰੀ`,
    "801ab24683a4a8c433c6eb40c48bcd9d": `ਡਾਉਨਲੋਡ ਕਰੋ`,
    "83f8a38f024082405d2fff921c2bb1d2": `ਅਡੋਬ ਐਕਰੋਬੈਟ ਰੀਡਰ`,
    "9d8c84ff52cfc2c2e0075e669edacffd": `ਸ਼ਬਦ ਫਾਈਲਾਂ`,
    "4db1a171aaf96723d4002cc41559ddf7": `ਬਾਹਰੀ ਵੈਬਸਾਈਟ ਜੋ ਇਕ ਨਵੀਂ ਵਿੰਡੋ ਵਿਚ ਖੁੱਲ੍ਹਦੀ ਹੈ`,
    "86c61b344697039b8a54bbef9e4959c3": `ਐਕਸਲ ਦਰਸ਼ਕ 2003 (2003 ਤੱਕ ਕਿਸੇ ਵੀ ਵਰਜਨ ਵਿੱਚ)`,
    "04efb134468330da1430e32701160107": `ਐਕਸਲ ਲਈ ਮਾਈਕਰੋਸੌਫਟ ਦਫਤਰ ਦੀ ਅਨੁਕੂਲਤਾ ਪੈਕ (2007 ਦੇ ਵਰਜ਼ਨ ਲਈ)`,
    "a385a9bb61bb694694eef5d2f54c563a": `ਪਾਵਰਪੁਆਇੰਟ ਪੇਸ਼ਕਾਰੀ`,
    "da67d56a74c01537a71a3ab0eda00faa": `ਮਾਈਕਰੋਸੌਫਟ ਆਫਿਸ ਅਨੁਕੂਲਤਾ ਪਾਵਰਪੁਆਇੰਟ ਲਈ ਪੈਕ (2007 ਦੇ ਵਰਜ਼ਨ ਲਈ)`,
    "bb625547a85b98c2c9f9f4121c72cd78": `ਫਲੈਸ਼ ਸਮੱਗਰੀ`,
    "ab69edd2798068928605717c6591462f": `ਅਡੋਬ ਫਲੈਸ਼ ਪਲੇਅਰ`,
    "2c4fa0a307152ef814bbab8d4075d938": `ਆਡੀਓ ਫਾਈਲਾਂ`,
    "da7be99fd6fac5c8a4065eb2c07cad88": `ਵਿੰਡੋਜ਼ ਮੀਡੀਆ ਪਲੇਅਰ`,
    "5a5652ca26ee42fe73fdd79497cf9e21": `ਸ਼ਬਦ ਦਰਸ਼ਕ (2003 ਤੱਕ ਕਿਸੇ ਵੀ ਵਰਜਨ ਵਿੱਚ)`,
    "2e856241d3d995fefdb1b4b32f84a79b": `ਵਰਡ ਲਈ ਮਾਈਕਰੋਸੌਫਟ ਆਫਿਸ ਅਨੁਕੂਲਤਾ ਪੈਕ (2007 ਦੇ ਵਰਜ਼ਨ ਲਈ)`,
    "64a443242750513eb56695e07aedcff2": `ਐਕਸਲ ਫਾਈਲਾਂ`,
    "382b0f5185773fa0f67a8ed8056c7759": `ਐਨ/ਏ`,
    "462c18d5b89050fb1b7f8fca1e535af8": `ਕਾਰਵਾਈ ਕੀਤੀ ਗਈ`,
    "ccb26ac32c3cc7cc2f0fd1bbac3b8e2d": `ਤੁਹਾਡੇ ਕੋਲ ਪ੍ਰੋਸੈਸਡ ਵਿੱਚ ਕੋਈ ਸੇਵਾਵਾਂ ਨਹੀਂ ਹਨ`,
    "fc44a0efc672761745c2e76aac6851d2": `ਤੁਹਾਡੀ ਅਰਜ਼ੀ ਰੱਦ ਕਰ ਦਿੱਤੀ ਗਈ ਹੈ ਕਿਉਂਕਿ`,
    "7d0db380a5b95a8ba1da0bca241abda1": `ਤੇ`,
    "1252044838fd74c4e8b506ef1302a8b5": `ਸ਼ਿਕਾਇਤ`,
    "06627e6919d21853d887d00334ee5337": `ਸ਼ਿਕਾਇਤ ਪ੍ਰਬੰਧਨ`,
    "f940fbe4aec76e9784e7f15c4b92ac6d": `ਤੁਹਾਡੇ ਕੋਲ ਪ੍ਰੋਸੈਸਡ ਵਿੱਚ ਕੋਈ ਸ਼ਿਕਾਇਤ ਨਹੀਂ ਹੈ`,
    "2290dc1500a2b4548eb3b931f2e3609a": `ਤੁਹਾਡੇ ਕੋਲ ਕੋਈ ਸੁਰੱਖਿਅਤ ਹੋ ਗਈ ਸ਼ਿਕਾਇਤ ਨਹੀਂ ਹੈ.`,
    "b25b60dc1a5f7c17e09bc73174668d9a": `ਤੁਹਾਡੇ ਕੋਲ ਡਰਾਫਟ ਵਿੱਚ ਕੋਈ ਸ਼ਿਕਾਇਤ ਨਹੀਂ ਹੈ.`,
    "a02c58d421087ec9519620a29d68a6ec": `ਤੁਸੀਂ ਅਜੇ ਕਿਸੇ ਸ਼ਿਕਾਇਤ ਲਈ ਜਮ੍ਹਾ ਨਹੀਂ ਕੀਤਾ ਹੈ.`,
    "b65f636dcbc018b1901e23a7c2adde90": `ਹੋਰ ਪੜਚੋਲ ਕਰੋ`,
    "5c835c22429f25992b4371691ecde1a4": `ਪੇਸ਼`,
    "2535f5e4e0892c73a5fad023bf3e15e2": `ਮੇਰੀਆਂ ਸ਼ਿਕਾਇਤਾਂ`,
    "4351cfebe4b61d8aa5efa1d020710005": `ਵੇਖੋ`,
    "e9516387730903ffd7cfe3317e05e385": `ਇਹ ਸ਼ਿਕਾਇਤ ਤੁਹਾਡੀ "ਮੇਰੀ ਸ਼ਿਕਾਇਤ" ਵਿੱਚ ਬਚ ਗਈ ਹੈ.`,
    "b70db24f2cb70fb266eb03da8f289ba5": `ਸ਼ਿਕਾਇਤ ਵੇਖੋ`,
    "646558064e10a12b1e8914e86eca3ca6": `ਤੁਹਾਡੇ ਕੋਲ ਤੁਹਾਡੀ ਸੇਵਾ ਅਰਜ਼ੀ 'ਤੇ ਕਾਰਵਾਈਆਂ ਦੀ ਬਕਾਇਆ ਹੈ. ਆਪਣੀ ਅਰਜ਼ੀ ਦੇ ਨਾਲ ਅੱਗੇ ਵਧਣ ਲਈ ਬਕਾਇਆ ਕਿਰਿਆਵਾਂ ਨੂੰ ਪੂਰਾ ਕਰੋ.`,
    "d2841171ea05a48fa5463b3f7c7c92d7": `ਤੁਹਾਡੇ ਕੋਲ ਤੁਹਾਡੀ ਸ਼ਿਕਾਇਤ ਐਪਲੀਕੇਸ਼ਨ 'ਤੇ ਕਾਰਵਾਈਆਂ ਦੀ ਬਕਾਇਆ ਹੈ. ਆਪਣੀ ਅਰਜ਼ੀ ਦੇ ਨਾਲ ਅੱਗੇ ਵਧਣ ਲਈ ਬਕਾਇਆ ਕਿਰਿਆਵਾਂ ਨੂੰ ਪੂਰਾ ਕਰੋ.`,
    "9279f823b01f13b7a8c04688289543cc": `ਤੁਹਾਡੀ ਅਰਜ਼ੀ ਨੇ ਤੁਹਾਡੀ 'ਡਰਾਫਟ ਸ਼ਿਕਾਇਤ' ਵਿਚ ਸਫਲਤਾਪੂਰਵਕ ਸੁਰੱਖਿਅਤ ਕੀਤੀ.`,
    "af481c93dcae73d73afe9927076c8a09": `ਤੁਸੀਂ ਪਹਿਲਾਂ ਹੀ ਇਸ ਸ਼ਿਕਾਇਤਾਂ ਲਈ ਅਰਜ਼ੀ ਦਿੱਤੀ ਹੈ. ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੀ ਅਰਜ਼ੀ ਦੀ ਸਥਿਤੀ ਨੂੰ ਆਪਣੀਆਂ ਲਾਗੂ ਕਰਾਰਾਂ ਲਈ ਉਸੇ ਲਈ ਵੇਖੋ.`,
    "ce972231a0faf31791c7ac0bf0d1da84": `ਜੇ ਤੁਸੀਂ ਕੋਈ ਭੁਗਤਾਨ ਕੀਤਾ ਹੈ ਤਾਂ ਤੁਹਾਡੀ ਰਕਮ ਵਾਪਸ ਨਹੀਂ ਕੀਤੀ ਜਾਏਗੀ.`,
    "2ffef02ca513f8bbadbaf130a23097de": `ਤੁਹਾਡੀ ਅਰਜ਼ੀ ਤੁਹਾਡੇ "ਡਰਾਫਟ ਸ਼ਿਕਾਇਤਾਂ" ਵਿੱਚ ਸਫਲਤਾਪੂਰਵਕ ਸੁਰੱਖਿਅਤ ਕੀਤੀ ਗਈ ਹੈ`,
    "e55e425dbaf6b6449940eac8ea749092": `ਕਿਰਪਾ ਕਰਕੇ ਸਿਰਫ ਅੱਖਰ ਦਾਖਲ ਕਰੋ.`,
    "64ff9e3809686d4ea3253a631cae4761": `ਕਿਰਪਾ ਕਰਕੇ 2 ਦਸ਼ਮਲਵ ਸਥਾਨਾਂ ਤੇ ਇੱਕ ਮੁੱਲ ਚੁਣੋ.`,
    "7ed0ef23d527c2876015161e422f49ce": `ਕਿਰਪਾ ਕਰਕੇ ਕੰਨੜ ਭਾਸ਼ਾ ਵਿੱਚ ਆਪਣਾ ਨਾਮ ਦਰਜ ਕਰੋ.`,
    "60ae728544ab5cb25dba9ff4b8f6aa95": `ਸੇਵਾ ਸਿੰਧੂ ਲਈ ਤੁਹਾਡਾ ਅਰਜ਼ੀ ਹਵਾਲਾ ਨੰਬਰ ਹੈ`,
    "2c0ed1f2f9239dbca46beca549190e00": `ਆਧਾਰ ਨੂੰ ਪਤੇ ਦੇ ਸਬੂਤ ਵਜੋਂ ਲਿਆ ਜਾਵੇਗਾ, ਕਿਰਪਾ ਕਰਕੇ ਜ਼ਿਲ੍ਹੇ ਦੇ ਤੌਰ ਤੇ ਆਧਾਰ ਵਿੱਚ.`,
    "8355a53d777c5ac84a37970b5158aba5": `ਗਲਤੀ 205: ਆਧਾਰ ਵਿੱਚ ਤੁਹਾਡਾ ਨਾਮ ਮਾਲ ਵਿਭਾਗ ਦੁਆਰਾ ਜਾਰੀ ਕੀਤੀ ਗਈ ਆਮਦਨੀ ਸਰਟੀਫਿਕੇਟ ਦੇ ਵੇਰਵਿਆਂ ਨਾਲ ਮੇਲ ਨਹੀਂ ਖਾਂਦਾ.`,
    "601f77894e71ae7d4922eda713dda66c": `ਗਲਤੀ 207: ਤੁਹਾਡੀ ਜਾਤੀ ਅਤੇ ਆਮਦਨੀ ਸਰਟੀਫਿਕੇਟ ਦੀ ਮਿਆਦ ਪੁੱਗ ਗਈ ਹੈ.`,
    "9182b3e8dc7965f2775a204095f4c992": `ਤੁਹਾਡੇ ਦੁਆਰਾ ਦਾਖਲ ਹੋਇਆ OTP ਗਲਤ ਹੈ. ਕਿਰਪਾ ਕਰਕੇ ਸਹੀ OTP ਨਾਲ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ.`,
    "6e0fe1003e84e34da97a6c4c9040b31b": `ਅਰਮਿਆਈ ਵਿਦਿਅਕ ਲੋਨ ਸਕੀਮ (ਨਵੀਨੀਕਰਨ) ਨੂੰ ਪਛੜਿਆ ਹੋਇਆ ਸਥਾਨ ਭਲਾਈ ਵਿਭਾਗ ਨੂੰ 31 ਮਾਰਚ 2022 ਤੱਕ ਦਾ ਰੂਪ ਵਧਿਆ ਹੈ.`,
    "69d3a9de2fc1407f59e8936ca0c525a0": `ਮੁਆਫ ਕਰਨਾ, ਇੱਕ ਗਲਤੀ ਆਈ ਹੈ. ਬਾਅਦ ਵਿੱਚ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ ਜੀ.`,
    "7953c16d691da03c899391a39412fc9b": `ਤੁਹਾਡੇ ਕੋਲ ਤੁਹਾਡੀ ਸਕੀਮ ਦੀ ਅਰਜ਼ੀ 'ਤੇ ਕਾਰਵਾਈਆਂ ਦੀ ਬਕਾਇਆ ਹੈ. ਆਪਣੀ ਅਰਜ਼ੀ ਦੇ ਨਾਲ ਅੱਗੇ ਵਧਣ ਲਈ <i> {{startDate}} </ i> ਤੋਂ ਬਾਅਦ ਵੱਲ ਧਿਆਨ ਦੇਣ ਵਾਲੀਆਂ ਕਿਰਿਆਵਾਂ ਨੂੰ ਪੂਰਾ ਕਰੋ.`,
    "f4f4366332ac6949882de59c81b08de9": `ਤੁਹਾਡੇ ਕੋਲ ਤੁਹਾਡੀ ਸਕੀਮ ਦੀ ਅਰਜ਼ੀ 'ਤੇ ਕਾਰਵਾਈਆਂ ਦੀ ਬਕਾਇਆ ਹੈ. <I> {{startDate}} </ I> ਤੁਹਾਡੇ ਬਿਨੈ-ਪੱਤਰ ਨਾਲ ਅੱਗੇ ਵਧਣ ਲਈ </ i> {{endDate}} </ I> ਤੋਂ ਬਾਅਦ ਵੱਲ ਧਿਆਨ ਦੇਣਾ.`,
    "77b43c24220ee608380031b72f8017ce":`ਸੇਵਾ ਸ਼੍ਰੇਣੀਆਂ`,
    "33965792dee65aa8a8a825ce28e60750": `ਪਲੇਟਫਾਰਮ ਤੁਹਾਡੇ ਪਰਿਵਾਰ ਅਤੇ ਤੁਹਾਡੇ ਲਈ ਯੋਜਨਾਵਾਂ ਜਾਣਨ ਲਈ`,
    "d56f6b073f573237a6c4aed91589e982": `ਰੋਜ਼ੀ-ਰੋਟੀ`,
    "e5930fadbc620e368c92561f381ead58": `ਤੁਹਾਡੇ ਲਈ ਰੋਜ਼ੀ-ਰੋਟੀ ਦੇ ਮੌਕੇ`,
    "192adf39ebd850e3e2e30971e885dc6d": `ਇਸ ਸਮੇਂ ਤੁਹਾਡੇ ਰਾਜ ਵਿੱਚ ਰੋਜ਼ੀ-ਰੋਟੀ ਦੇ ਕੋਈ ਮੌਕੇ ਕੌਂਫਿਗਰ ਨਹੀਂ ਕੀਤੇ ਗਏ ਹਨ, ਅਸੀਂ ਲਗਾਤਾਰ ਅੱਪਡੇਟ ਕਰ ਰਹੇ ਹਾਂ, ਕਿਰਪਾ ਕਰਕੇ ਸਾਡੇ ਨਾਲ ਦੁਬਾਰਾ ਸੰਪਰਕ ਕਰੋ`,
    "TM_Alt51": "ਨੱਥੀ(ਆਂ)",
    "TM_Hea1": "ਕੁੱਲ ਮਾਤਰਾ",
    "TM_Hea2": "ਸੰਖੇਪ",
    "TM_Sub56": "ਕੁੱਲ",
    "TM_But42": "ਭੁਗਤਾਨ ਕਰੋ",
    "TM_Mod41":"ਭੁਗਤਾਨ ਬਕਾਇਆ",
    "TM_Mod40":"ਭੁਗਤਾਨ ਸਫਲ ਰਿਹਾ",
    "TM_Alt72":"ਭੁਗਤਾਨ ਅਸਫਲ ਰਿਹਾ",
    "TM_Pag48":"ਮਦਦ ਅਤੇ ਸਹਾਇਤਾ",
    "TM_Con33":"ਤੁਹਾਡਾ ਧੰਨਵਾਦ, ਤੁਹਾਡੀ ਅਰਜ਼ੀ ਸਫਲਤਾਪੂਰਵਕ ਜਮ੍ਹਾਂ ਹੋ ਗਈ ਹੈ।",
    "TM_Err34": "ਭੁਗਤਾਨ ਅਸਫਲ ਹੋਣ ਕਾਰਨ ਤੁਹਾਡੀ ਅਰਜ਼ੀ ਸਪੁਰਦ ਨਹੀਂ ਕੀਤੀ ਜਾ ਸਕੀ।",
    "TM_Rec55": "ਤੁਹਾਡੇ ਭੁਗਤਾਨ ਦੀ ਸਥਿਤੀ ਦੀ ਪੁਸ਼ਟੀ ਕਰਨ ਵਿੱਚ ਸਮਾਂ ਲੱਗ ਰਿਹਾ ਹੈ। ਅਸੀਂ ਤੁਹਾਡੇ ਬੈਂਕ ਨਾਲ ਲਗਾਤਾਰ ਜਾਂਚ ਕਰ ਰਹੇ ਹਾਂ। ਕਿਰਪਾ ਕਰਕੇ ਅਗਲੇ 48 ਘੰਟਿਆਂ ਲਈ ਕੋਈ ਵੀ ਵਾਧੂ ਭੁਗਤਾਨ ਕਰਨ ਤੋਂ ਬਚੋ।",
    "TM_But13": "ਵਾਪਸ ਜਾਓ",
    "TM_Alt124":"ਕ੍ਰਿਪਾ ਕਰਕੇ ਉਡੀਕ ਕਰੋ",
    "TM_Rec58": "ਰੁਕੋ। ਅਸੀਂ ਤੁਹਾਡੀ ਭੁਗਤਾਨ ਸਥਿਤੀ ਦੀ ਪੁਸ਼ਟੀ ਕਰ ਰਹੇ ਹਾਂ",
    "TM_Rec59": "ਇਸ ਸੇਵਾ ਲਈ ਤੁਹਾਡਾ ਆਖਰੀ ਭੁਗਤਾਨ ਬਕਾਇਆ ਸਥਿਤੀ ਵਿੱਚ ਹੈ। ਇਹ ਪੂਰਾ ਹੋਣ 'ਤੇ ਅਸੀਂ ਤੁਹਾਨੂੰ ਸੂਚਿਤ ਕਰਾਂਗੇ। ਇਸ ਦੌਰਾਨ, ਸਾਡੀਆਂ ਹੋਰ ਸੇਵਾਵਾਂ ਦੀ ਪੜਚੋਲ ਕਰਨ ਲਈ ਬੇਝਿਜਕ ਮਹਿਸੂਸ ਕਰੋ।",
    "TM_Rec60":"ਆਪਣੇ ਧੀਰਜ ਲਈ ਧੰਨਵਾਦ.",
    "TM_Rec61":"ਪੰਨਾ ਆਪਣੇ ਆਪ ਹੀ ਸਮਾਂ ਸਮਾਪਤ ਹੋ ਜਾਵੇਗਾ",
    "TM_Alt165":"ਭੁਗਤਾਨ ਕਰਨ ਲਈ ਆਪਣੇ ਮੋਬਾਈਲ ਡਿਵਾਈਸ ਦੇ ਇਉਪੀਆਈ ਐਪ ਵਿੱਚ ਕਿਉਆਰ  ਕੋਡ ਨੂੰ ਸਕੈਨ ਕਰੋ",
    "TM_Pag39": "ਲਭਾਰਤੀ",
    "TM_Alt166":"ਭੁਗਤਾਨ ਅਸਵੀਕਾਰ ਕੀਤਾ ਗਿਆ",
    "TM_Err39":"ਭੁਗਤਾਨ ਅਸਵੀਕਾਰ ਕੀਤੇ ਜਾਣ ਕਾਰਨ ਤੁਹਾਡੀ ਅਰਜ਼ੀ ਸਪੁਰਦ ਨਹੀਂ ਕੀਤੀ ਜਾ ਸਕੀ।",
    "cc4cbfe72c596683881033f36eac3e2f":"ਕਿਰਪਾ ਕਰਕੇ ਉਡੀਕ ਕਰੋ ਜਦੋਂ ਤੱਕ ਅਸੀਂ ਤੁਹਾਡੇ ਬੈਂਕ ਨਾਲ ਤੁਹਾਡੇ ਭੁਗਤਾਨ ਦੀ ਪੁਸ਼ਟੀ ਕਰਦੇ ਹਾਂ। ਇਸ ਪ੍ਰਕਿਰਿਆ ਵਿੱਚ ਕੁਝ ਸਮਾਂ ਲੱਗ ਸਕਦਾ ਹੈ।",
    "7d54c690a3a707e692cceb6978e24bc1": "ਫੀਸ ਬਰੇਕਅੱਪ",
}
export default language;