import axios from "axios";
import history from "../../history"

export const getSettings = () => {
  const instance = axios.create({
    baseURL: window.HOST,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "",
    },
  });

  return (dispatch) => {
    // dispatch({ type: "HANDLE_LOADER", payload: {showLoader: true} });
    instance
      .post("get_settings")
      .then((response) => {
        if (response.status === 200) {
          if(response.data.code === 401){
            if(window.outerWidth <=600){
              history.push("/main")
            } else {
              history.push("/homeUser")
            }
            // dispatch({ type: "HANDLE_LOADER", payload: {showLoader: false}});
          } else {
          // dispatch({ type: "HANDLE_LOADER", payload: {showLoader: false} });
          dispatch({ type: "GET_SETTINGS", payload: response.data.data });
          }
        }
      })
      .catch((err) => {
        dispatch({ type: "HANDLE_LOADER", payload: {showLoader: false}});
        console.log(err);
      });
  };
};
