const initState = {
    data: "",
  };
  
  const saveKycFormReducer = (state = initState, action) => {
    switch (action.type) {
      case "SAVE_KYC_FORM":
        return {
          ...state,
          data: action.payload,
        };
  
      case "RESET_SAVE_KYC_FORM":
        return {
          ...state,
          data: "",
        };
  
      default:
        return state;
    }
  };
  
  export default saveKycFormReducer;
  