const initState = {
    data: undefined,
  };

  const getUserCertificateByApplicationIdReducer = (state = initState, action) => {
    switch (action.type) {
      case "GET_USER_CERTIFICATE":
        return {
          ...state,
          data: action.payload,
        };

        case "RESET_GET_USER_CERTIFICATE":
        return {
          ...state,
          data: '',
        };

      default:
        return state;
    }
  };

  export default getUserCertificateByApplicationIdReducer;
