const language = {
  "9e66e6bd4069124578736528a0432752": `ನಮ್ಮ ಬಗ್ಗೆ`,
  "0f5590b58efe9cfcce35e55a66280e59": `ಸ್ವೀಕರಿಸಿ ಮತ್ತು ಮುಂದುವರಿಸಿ`,
  "77ab8893d0d282a9f2781d5711883903": `ಸೇರಿಸು`,
  "c40eba17959a63e52412cac8c38d467e": `ಪ್ರೊಫೈಲ್ ಸೇರಿಸಿ`,
  "ac714b256ab565c91ade9e03e8c410f0": `ನಿಮ್ಮ ಎಲ್ಲಾ ಡೇಟಾವನ್ನು ಅಳಿಸಲಾಗಿದೆ`,
  "b31b443baac1c6da4cb78dfaf2e5be44": `ಅಪ್ಲಿಕೇಶನ್ ಲಿಂಕ್`,
  "0ec157e0e15e12c56bfe1c72e0e7515a": `ಬದಲಾವಣೆಗಳನ್ನು ಅನ್ವಯಿಸಲಿಚ್ಛಿಸುತ್ತೀರಾ`,
  "181c1a0ee6db7d7f835eed29791749ab": `ಈ ಯೋಜನೆಯನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?`,
  "d937652cde254e2a9b93b74a4ee2a02b": `{{name}} ಪ್ರೊಫೈಲ್ ಅನ್ನು ಶಾಶ್ವತವಾಗಿ ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?`,
  "3ac1df709254c0e491c642aae5400814": `{{name}} ಖಾತೆಯನ್ನು ಶಾಶ್ವತವಾಗಿ ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?`,
  "1cbb32715ee74ee3447f48febcc04864": `ನಿಮ್ಮ ಉಳಿಸಿದ ಸೇವೆಗಳಿಂದ ಈ ಸೇವೆಯನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?`,
  "ea4788705e6873b424c65e91c2846b19": `ರದ್ದುಮಾಡು`,
  "f4ec5f57bd4d31b803312d873be40da9": `ಬದಲಾವಣೆ`,
  "6dd495f1c2ae29c8e9a6c4405f18e22e": `ಸ್ಥಳವನ್ನು ಬದಲಾಯಿಸಿ`,
  "b1ad1d3aef325b23950a43cb82fd0670": `ಭಾಷೆ ಆರಿಸಿ`,
  "714341d9339756588d1dc724991c2b9a": `ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ`,
  "f72b489f19f748cf78a86c78d547ed91": `ನಿಮ್ಮ ಸ್ಥಳವನ್ನು ದೃಢೀಕರಿಸಿ`,
  "a0bfb8e59e6c13fc8d990781f77694fe": `ಮುಂದುವರಿಸು`,
  "bee04ef1315b3f9562be34e2e28a7831": `ಖಾತೆಯನ್ನು ಅಳಿಸಿ`,
  "3e9983cf1885a5ec9f5a5d8127137bd2": `ಪ್ರೊಫೈಲ್ ಅಳಿಸಿ`,
  "2ac7a924af94bf173f38eb31165efce3": `ಯೋಜನೆ`,
  "e0bb2f66e5b61be3235bf8969979de19": `ಜಿಲ್ಲೆ`,
  "46a43a7757337712d4f6a3701a5c57dd": `ಡೌನ್‌ಲೋಡ್ ಫಾರ್ಮ್`,
  "4d7be8ac1b9cb2fd5843dbea7ce30344": `ನೀವು ಇನ್ನೂ ಯಾವುದೇ ಸೇವೆಯನ್ನು ಉಳಿಸಿಲ್ಲ`,
  "edda2208040fe25b06d0d8f3af976651": `ನೀವು ಯಾವುದೇ ಉದ್ಯೋಗಗಳನ್ನು ಉಳಿಸಿಲ್ಲ`,
  "f283a3e62b2809c07cf77075103c012f": `ನಿಮಗಾಗಿ ಸೂಕ್ತವಾದ ಸೂಕ್ತವಾದ ಸರ್ಕಾರಿ ಕಲ್ಯಾಣ ಯೋಜನೆಗಳು, ಸೇವೆಗಳು ಮತ್ತು ಉದ್ಯೋಗಗಳನ್ನು ಕಂಡುಹಿಡಿಯಲು ನಮಗೆ ಕೆಲವು ಮೂಲಭೂತ ಪ್ರೊಫೈಲ್ ಮಾಹಿತಿಯ ಅಗತ್ಯವಿದೆ.`,
  // "f283a3e62b2809c07cf77075103c012f":`ಅತ್ಯುತ್ತಮ ಸೂಕ್ತವಾದ ಸರ್ಕಾರಿ ಕಲ್ಯಾಣ ಯೋಜನೆಗಳನ್ನು ಕಂಡುಹಿಡಿಯಲು ಲಾಬ್ಹಾರ್ಥಿಗೆ ಕೆಲವು ಮೂಲಭೂತ ಪ್ರೊಫೈಲ್ ಮಾಹಿತಿಯ ಅಗತ್ಯವಿದೆ`,
  "ce8ae9da5b7cd6c3df2929543a9af92d": `ಇಮೇಲ್ ಮಾಡುವುದು`,
  "01e8b0c4e059b594202e976860b2d481": `ನಿಮ್ಮ ಸ್ವಂತ ಭಾಷೆಯಲ್ಲಿ ಯೋಜನೆಗಳು ಮತ್ತು ಸೇವೆಗಳನ್ನು ಅನ್ವೇಷಿಸಿ`,
  "483a5da192f46e3cfe8391d3ef234fc4": `ಪದೇ ಪದೇ ಕೇಳಲಾಗುವ ಪ್ರಶ್ನೆಗಳು`,
  "37aaf54433bbfe989db8871121fe0ad8": `ನಮ್ಮನ್ನು ಅನುಸರಿಸಿ:`,
  "e0c4332e8c13be976552a059f106354f": `ಪ್ರಾರಂಭಿಸಿ`,
  "48dee7c291ac7f19e499438b072da336": `ಸರ್ಕಾರಿ ಶುಲ್ಕ`,
  "2ceb32552d78024d91a123897636afcb": `EasyGov ನ ನಾಗರಿಕ ಸಬಲೀಕರಣ ಅಪ್ಲಿಕೇಶನ್`,
  //"357aa540fd5c8ae584ba18359105de3d":`ಯಾವುದೇ ಯೋಜನೆಗಳು ಅಥವಾ ಇನ್ನಾವುದರ ಬಗ್ಗೆ ನಿಮಗೆ ಯಾವುದೇ ಪ್ರಶ್ನೆ ಇದೆಯೇ?`,
  "357aa540fd5c8ae584ba18359105de3d": `ಒಂದು ವೇಳೆ ನೀವು ಯಾವುದೇ ಪ್ರಶ್ನೆಯನ್ನು ಹೊಂದಿದ್ದರೆ ನಮಗೆ ತಿಳಿಸಿ ಮತ್ತು ನಾವು 24 ವ್ಯವಹಾರ ಗಂಟೆಗಳಲ್ಲಿ ನಿಮ್ಮನ್ನು ಮರಳಿ ಪಡೆಯುತ್ತೇವೆ.`,
  "b3ba00d60ff5a9e11c15a6efbed13746": `ನಿಮ್ಮ ಪ್ರಸ್ತುತ ಪ್ರೊಫೈಲ್ ಅನ್ನು ನವೀಕರಿಸಲು “ಪ್ರಸ್ತುತ ಪ್ರೊಫೈಲ್ ನವೀಕರಿಸಿ” ಕ್ಲಿಕ್ ಮಾಡಿ ಮತ್ತು ಹೊಸ ಪ್ರೊಫೈಲ್ ಸೇರಿಸಲು “ಪ್ರೊಫೈಲ್ ಸೇರಿಸಿ” ಆಯ್ಕೆಮಾಡಿ`,
  "12ceff2290bb9039beaa8f36d5dec226": `ಉದ್ಯೋಗ`,
  "5eb57a6678ca337f92884f49b578a908": `...`,
  "4994a8ffeba4ac3140beb89e8d41f174": `ಭಾಷೆ`,
  "846eb834b6bfe81dbd412bc010f8cac7": `ನಮಗೆ ತಿಳಿಸಿ ಮತ್ತು ನಾವು 24 ವ್ಯವಹಾರ ಗಂಟೆಗಳಲ್ಲಿ ನಿಮ್ಮನ್ನು ಮರಳಿ ಪಡೆಯುತ್ತೇವೆ`,
  "a58252e21aaf876bf7b64c05cce1c477": `ಲಿಂಕ್ ಅನ್ನು ನಕಲಿಸಲಾಗಿದೆ`,
  "ce5bf551379459c1c61d2a204061c455": `ಸ್ಥಳ`,
  "ccffe10fe854018805baf0d3d9327285": `ಪ್ರೊಫೈಲ್ ಅನ್ನು ನಿರ್ವಹಿಸಿ`,
  "49ee3087348e8d44e1feda1917443987": `ಹೆಸರು`,
  "10ac3d04253ef7e1ddc73e6091c0cd55": `ನೆನ್ನಿಯ`,
  "bafd7322c6e97d25b6299b5d6fe8920b": `ಇಲ್ಲ`,
  "31916d996c9f53949315692bc897f0c7": `ಪ್ರಸ್ತುತವಲ್ಲವೇ? ನೋಡುವುದನ್ನು ಮುಂದುವರಿಸಿ`,
  "e81c4e4f2b7b93b481e13a8553c2ae1b": `ಅಥವಾ`,
  "0d5e50bf1643118ee026e5d19226542a": `ದಯವಿಟ್ಟು ಕಾಗುಣಿತವನ್ನು ಪರಿಶೀಲಿಸಿ ಅಥವಾ ಬೇರೆ ಯಾವುದನ್ನಾದರೂ ಹುಡುಕಲು ಪ್ರಯತ್ನಿಸಿ`,
  "64ed408b000d73143e6ab1b5acb7c26e": `ದಯವಿಟ್ಟು ಪ್ರೊಫೈಲ್ ಆಯ್ಕೆಮಾಡಿ`,
  "fa2ead697d9998cbc65c81384e6533d5": `ಗೌಪ್ಯತಾ ನೀತಿ`,
  "7595f48afc2f088bc1ff3313ceeb3605": `ಅನ್ವಯಿಸುವ ವಿಧಾನ`,
  "cce99c598cfdb9773ab041d54c3d973a": `ಪ್ರಚಾರ`,
  "665d0095958d696322be90e4eb606977": `ಈ ಸಂಬಂಧದೊಂದಿಗೆ ಪ್ರೊಫೈಲ್ ಈಗಾಗಲೇ ಅಸ್ತಿತ್ವದಲ್ಲಿದೆ. ನೀವು ಪ್ರಸ್ತುತ ಪ್ರೊಫೈಲ್ ಅನ್ನು ನವೀಕರಿಸಬಹುದು`,
  "27bcc51421612805a9c006f24a4d6dc6": `ಸ್ಕೀಮ್ ತೆಗೆದುಹಾಕಿ`,
  "f8b66e3c488bb1df16cb3c7bb21447f0": `ಅಗತ್ಯವಾದ ದಾಖಲೆಗಳು`,
  "82609dd1953ccbfbb4e0d20623193b56": `ಮನೆಗೆ ಮರಳು`,
  "c9cc8cce247e49bae79f15173ce97354": `ಉಳಿಸು`,
  "c320cdeffafbb2d60d6d759619c6b5e2": `ಪ್ರೊಫೈಲ್ ಉಳಿಸಿ`,
  "ab9753c948520fb02c20831980bad53a": `ನನ್ನ ಯೋಜನೆಗಳು`,
  "827452688eed02a12178e96f924ac529": `ಯೋಜನೆ`,
  "cb80cf588ba5a0d3dc067b0b0ef219e9": `ಯೋಜನೆಗಳು`,
  "1925ba54f16355af0964df7dfa145761": `ನಿಮಗಾಗಿ ಯೋಜನೆಗಳು`,
  "13348442cc6a27032d2b4aa28b75a5d3": `ಹುಡುಕಿ`,
  "32df01b9cf0491a879250b58ba2744ba": `ಹುಡುಕಾಟ ಫಲಿತಾಂಶಗಳು`,
  // "988cddb5e417156bd3603983dc0c32e5":`ಯೋಜನೆಗಳನ್ನು ಹುಡುಕಿ`,
  "0afcf4c1ba72c441b41fbafaab253fc6": `ಸ್ಥಳವನ್ನು ಆಯ್ಕೆಮಾಡಿ`,
  "be2933038f93e25262400220080c111f": `ರಾಜ್ಯವನ್ನು ಆರಿಸಿ`,
  "dfe1d316ec9338e23c892b573667b9bb": `ನಿಮ್ಮ ಆಯ್ಕೆಯನ್ನು ಆರಿಸಿ`,
  "c2ba7e785c49050f48da9aacc45c2b85": `ಸೇವಕ`,
  "992a0f0542384f1ee5ef51b7cf4ae6c4": `ಸೇವೆಗಳು`,
  "48ecd536efbb3622296aac8de326fa38": `ಕ್ಷಮಿಸಿ, ಒದಗಿಸಿದ ಮಾಹಿತಿಯ ಆಧಾರದ ಮೇಲೆ, ನೀವು ಯೋಜನೆಗೆ ಅರ್ಹರಲ್ಲ`,
  "4cd9a992dc66802a4b44c366881957d1": `ಕ್ಷಮಿಸಿ, ಯಾವುದೇ ಫಲಿತಾಂಶ ಕಂಡುಬಂದಿಲ್ಲ`,
  "f86301756ec6964cb96e2b80a38f4162": `ಕ್ಷಮಿಸಿ! ನಿಮಗಾಗಿ ಯಾವುದೇ ಹೊಂದಾಣಿಕೆಯ ಯೋಜನೆಗಳನ್ನು ನಾವು ಕಂಡುಹಿಡಿಯಲಾಗಲಿಲ್ಲ`,
  "46a2a41cc6e552044816a2d04634545d": `ರಾಜ್ಯ`,
  "efd9a0ba1e9d553a070028627fdcae18": `ಸಮೀಕ್ಷೆಯ ವಿವರಗಳು`,
  "4cd61af85d0ec40f50349cd7c3054ace": `ಪ್ರೊಫೈಲ್`,
  "1d8cd2bfc6943dcf70236f7d351572a0": `ನಿಯಮ ಮತ್ತು ಶರತ್ತುಗಳು`,
  "4f7e556abaf92ce8061ad57cc4e7edd1": `ಇದು ಅಗತ್ಯವಿದೆ`,
  "371999537b8bb6e7f0099b0c40e0e41d": `ಈ ಪ್ರೊಫೈಲ್ ಅನ್ನು ಈಗಾಗಲೇ ರಚಿಸಲಾಗಿದೆ. ನೀವು ಪ್ರೊಫೈಲ್ ಅನ್ನು ನವೀಕರಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ.`,
  "9eed21a5afd3ba6841c523b0d70599c7": `ಯೋಜನೆಗಳನ್ನು ವೀಕ್ಷಿಸಲು 2 ಕ್ಕಿಂತ ಕಡಿಮೆ ಇರುವ ಯಾವುದೇ ವರ್ಗದ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ ಅಥವಾ ನಿರ್ದಿಷ್ಟ ವರ್ಗಕ್ಕಾಗಿ ಸಮೀಕ್ಷೆಯನ್ನು ನಡೆಸಲು 2 ಕ್ಕಿಂತ ಹೆಚ್ಚು ವರ್ಗದ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ`,
  "37528894f18a085f9a078496a68eaaa8": `ಪ್ರವೃತ್ತಿಯ`,
  "d876a9fe1aecb3ffc05a5639ebe22000": `ಮತ್ತೆ ಪ್ರಯತ್ನಿಸು`,
  "06933067aafd48425d67bcb01bba5cb6": `ನವೀಕರಿಸು`,
  "fba7f6c863513d97922faab074d3c716": `ಪ್ರೊಫೈಲ್ ನವೀಕರಿಸಿ`,
  "90b1f6651953fcf8b43ddc0da6760aaf": `ಬಳಕೆದಾರರ ಪ್ರೊಫೈಲ್`,
  "d8d7ad6abfd08c27baa8bedb78c72ddb": `ಎಲ್ಲಾ ವೀಕ್ಷಿಸಿ`,
  "acac138f2e6f039df2e1fed2f2c0bc44": `EasyGov ಗೆ ಸುಸ್ವಾಗತ`,
  "6ebb2e31989c843ea65feefa43237dad": `ನಿಮ್ಮ ಖಾತೆಯಿಂದ ನೀವು ಪ್ರೊಫೈಲ್ ಅನ್ನು ಅಳಿಸಿದಾಗ, ನಿರ್ದಿಷ್ಟ ಪ್ರೊಫೈಲ್‌ಗಾಗಿ ಸಂಗ್ರಹವಾಗಿರುವ ಯಾವುದೇ ಡೇಟಾ ಅಥವಾ ಮಾಹಿತಿಯನ್ನು ಹಿಂಪಡೆಯಲು ನಿಮಗೆ ಸಾಧ್ಯವಾಗುವುದಿಲ್ಲ. ಆಯ್ಕೆಮಾಡಿದ ಪ್ರೊಫೈಲ್‌ಗಾಗಿ ಎಲ್ಲಾ ಪೂರ್ವಭಾವಿ ಮತ್ತು ಉಳಿಸಿದ ಯೋಜನೆಗಳನ್ನು ಸಹ ಅಳಿಸಲಾಗುತ್ತದೆ`,
  "6a64fbe7b065fc8579f2521e0054de02": `ನಿಮ್ಮ ಲ್ಯಾಬ್‌ಹಾರ್ಥಿ ಖಾತೆಯನ್ನು ನೀವು ಅಳಿಸಿದಾಗ, ನಿಮ್ಮ ಪ್ರೊಫೈಲ್‌ನಲ್ಲಿ ಸಂಗ್ರಹವಾಗಿರುವ ಯಾವುದೇ ಡೇಟಾ ಅಥವಾ ಮಾಹಿತಿಯನ್ನು ನಿಮ್ಮ ಖಾತೆಗೆ ಸಂಬಂಧಿಸಿದ ಇತರ ಪ್ರೊಫೈಲ್‌ಗಳನ್ನು ಹಿಂಪಡೆಯಲು ನಿಮಗೆ ಸಾಧ್ಯವಾಗುವುದಿಲ್ಲ. ನಿಮ್ಮ ಎಲ್ಲಾ ಪೂರ್ವಭಾವಿ ಮತ್ತು ಉಳಿಸಿದ ಯೋಜನೆಗಳನ್ನು ಸಹ ಅಳಿಸಲಾಗುತ್ತದೆ`,
  "93cba07454f06a4a960172bbd6e2a435": `ಹೌದು`,
  "3aeee34bea95f2b0ced72be632778670": `ನೀವು ಯಾವುದೇ ಯೋಜನೆಗಳನ್ನು ಉಳಿಸಿಲ್ಲ`,
  "cb32c835ee043e0e2faf4860e46669ae": `ಈ ಯೋಜನೆ/ಸೇವೆ ಪರಿಶೀಲನೆಯಲ್ಲಿದೆ. ಸ್ವಲ್ಪ ಸಮಯದ ನಂತರ ದಯವಿಟ್ಟು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ.`,
  "c2e0ede88489afe5739c8973aa03a787": `ನಿಮ್ಮ ಪ್ರೊಫೈಲ್‌ಗೆ ಹೊಂದಿಕೆಯಾಗುವ ಯಾವುದೇ ಯೋಜನೆಗಳಿಲ್ಲ`,
  "28d2dfa9b435bd81cb2f7f0499b846b3": `ಯೋಜನೆಗಳನ್ನು ಹುಡುಕಿ`,
  "52561ab2d4640b4e299e817b6ba79173": `ಲಾಬ್ಹಾರ್ಥಿಗೆ ಸುಸ್ವಾಗತ`,
  "fb0fc4b5fdb2bef7b1c42f2cfd523c2d": `ಪ್ರಯತ್ನಗಳು ಉಳಿದಿವೆ`,
  "e13e1cd5f02de37413f9497cc5b65de3": `ನಿಮ್ಮ ಖಾತೆಯನ್ನು 30 ನಿಮಿಷಗಳ ಕಾಲ ಲಾಕ್ ಮಾಡಲಾಗಿದೆ. ದಯವಿಟ್ಟು ಆ ಸಮಯದ ನಂತರ ಪ್ರಯತ್ನಿಸಿ`,
  "f2a6c498fb90eebdu72k7f888fce3b18": `ಸುಧನ`,
  "1172b2ea87bed4861c8c43a201d53031": `ನನ್ನ ಸೇವೆಗಳು`,
  "bcc254b55c4a1babdf1dcb82c207506b": `ದೂರವಾಣಿ`,
  "8458bad978f4fd6d6c7f8ec2ec0824b4": `ಪಾಸ್ವರ್ಡ್ ಮರೆತಿರಾ?`,
  "d67850bd126f070221dcfd5fa6317043": `ಸೈನ್ ಅಪ್`,
  "619d59e2ea9f9bb9fe287404dd3ca931": `ಎಲ್ಲಾ ಸೇವೆಗಳು`,
  "6b46ae48421828d9973deec5fa9aa0c3": `ವಿಂಗಡಿಸು`,
  "104f0a6879399d6285ab770ed0dbc257": `ಸೇವೆಯನ್ನು ತೆಗೆದುಹಾಕಿ`,
  "0b3937a9dd481d527fcc72adaa735b57": `ಲಾಬ್ಹಾರ್ಥಿ ಖಾತೆ ಇಲ್ಲವೇ?`,
  "2f453cfe638e57e27bb0c9512436111e": `ಮುಂದುವರಿಸು`,
  "f2fdea440d768b85591e936a803c3631": `ಸೈನ್ ಇನ್ ಮಾಡಿ`,
  "82b1fbd43ae27918a37b212a4f60a9ad": `ಸಲ್ಲಿಸು`,
  "31693e2e9aa2e5be1bf3ae45ede9c98d": `ನೀವು ಅರ್ಹರಲ್ಲದ ಯೋಜನೆಗಳು`,
  "d43cd6a49673f0f486ed7fbea1a5fd8a": `ಆಧಾರ್ ಸಂಖ್ಯೆ`,
  "3a06727e678189b8502408cb9b50b437": `ಪ್ರೊಫೈಲ್ ಬದಲಾಯಿಸಿ`,
  "0323de4f66a1700e2173e9bcdce02715": `ಲಾಗ್ ಔಟ್`,
  "644cd55dbe4d01e7e00fc7fc458f9b8f": `ನೀವು ಲಾಗ್ಔಟ್ ಮಾಡಲು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?`,
  "c0fb5959a7a8d3ea991f9edb0b1a9e85": `ದಯವಿಟ್ಟು ಐಡಿ ಆಯ್ಕೆಮಾಡಿ, ನಾವು ನಿಮಗಾಗಿ ಯೋಜನೆಗಳನ್ನು ಪಡೆಯುತ್ತೇವೆ`,
  "827a37fb93758383027c582a39ec6162": `ಐಡಿ ಯ ಪ್ರಕಾರ`,
  "cb3441fa3e1291a76dd5ea72e656bca3": `ಯಾವುದೇ ಐಡಿ ಇಲ್ಲದೆ ಮುಂದುವರಿಸಿ`,
  "99dea78007133396a7b8ed70578ac6ae": `ಲಗಾಟಿಸು`,
  "02aeaf2672eb06861c144004ef2248d2": `ಲಾಗಿನ್ ಖಾತೆ`,
  "d4d208eb2d8ccd525a64be73f3521dc5": `ಅಮಾನ್ಯ ರುಜುವಾತುಗಳು`,
  "e8055d9577ec8edf34ee15a88a937daa": `ಖಾತೆ ಇಲ್ಲವೇ?`,
  "942a3ef743c4e176f75d5223455f7e1a": `ನಿಮ್ಮ 10 ಅಂಕಿಯ ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ`,
  "21bdc5689c12595ae14298354d5550d5": `ಈಗಾಗಲೇ ಖಾತೆ ಹೊಂದಿದ್ದೀರ?`,
  "dc647eb65e6711e155375218212b3964": `ಗುಪ್ತಪದ`,
  "0b3e8b8021c9b2fafee35ae01ac2cfb9": `${window.projectName} ನಿಮಗಾಗಿ ಮತ್ತು ನಿಮ್ಮ ಕುಟುಂಬಕ್ಕೆ ಸೂಕ್ತವಾದ ಸರ್ಕಾರಿ ಕಲ್ಯಾಣ ಯೋಜನೆಗಳನ್ನು ಕಂಡುಹಿಡಿಯಲು ಅಪ್ಲಿಕೇಶನ್ ನಿಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ.`,
  "05aca668fa6754247f5094c042fb64ac": `ಫೋನ್ ಪರಿಶೀಲನೆ`,
  "f9bc92a6602b9692a0ba578120c9c441": `ನಿಮ್ಮ OTP ಕೋಡ್ ಅನ್ನು ಇಲ್ಲಿ ನಮೂದಿಸಿ`,
  "d9d1e60e50119d9752001d4196ee6b3c": `OTP`,
  "6e95a24fa2a98c2f59c96a24b182ccfd": `ನೀವು ಯಾವುದೇ ಕೋಡ್ ಸ್ವೀಕರಿಸಲಿಲ್ಲವೇ?`,
  "f922096c878b694ec7a3d9282a55904c": `ಹೊಸ ಕೋಡ್ ಅನ್ನು ಮರುಹೊಂದಿಸಿ`,
  "32b830cef750168335d96aff4dd98782": `ನನ್ನ ಉದ್ಯೋಗಗಳು`,
  "bbd26bc8ce3623addc9c54c36f692f8d": `ನಿಮಗಾಗಿ ಉದ್ಯೋಗಗಳು`,
  "67cb88ed21d4232c4681a5e648554e09": `ಅರ್ಜಿ ಶುಲ್ಕ`,
  "b90455b71eb5e2399be9d20e23c1a1db": `ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ಕೊನೆಯ ದಿನಾಂಕ`,
  "bfe72f18052c940de13501ae9877c3b0": `ಅರ್ಜಿ ಸಲ್ಲಿಸುವುದು ಹೇಗೆ`,
  "4f1f44ef7ce0c8a111f33fe400a6e4d7": `ಆಯ್ಕೆ ವಿಧಾನ`,
  "0f5679acafa674831691cfa01d17501e": `ಉದ್ಯೋಗ ಅಧಿಸೂಚನೆ`,
  "a45ca50bcc7830af2f84fd046648ec92": `ನಿಮ್ಮ ಪ್ರೊಫೈಲ್‌ಗೆ ಹೊಂದಿಕೆಯಾಗುವ ಯಾವುದೇ ಉದ್ಯೋಗಗಳಿಲ್ಲ`,
  "c4bcece293eb1ad7f09d4cf9c2037ea6": `ಉದ್ಯೋಗಗಳನ್ನು ಹುಡುಕಿ`,
  "5dc52ca9ffdc26147075682c92a0a853": `ಹಚ್ಚೆ`,
  "3f99ba6fbcb46f28fb24f271fc3c02c1": `ಉದ್ಯೋಗಗಳನ್ನು ವೀಕ್ಷಿಸಲು ಯಾವುದೇ ವರ್ಗದ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ. ಯಾವುದೇ ಉದ್ಯೋಗಕ್ಕಾಗಿ ಸಮೀಕ್ಷೆಯನ್ನು ಚಲಾಯಿಸಲು ಕಾರ್ಡ್ ಕ್ಲಿಕ್ ಮಾಡಿ`,
  "2078989a6c09fdd98efc35d3c1d1de3d": `ಕೆಲಸ ಅಳಿಸಿ`,
  "08c1517355f6e7313c4d629a193ac69a": `ನಿಮ್ಮ ಉಳಿಸಿದ ಉದ್ಯೋಗಗಳಿಂದ ಈ ಕೆಲಸವನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?`,
  "e614dff8cba6f73ae7cee729fe0091dd": `ಅಮಾನ್ಯ OTP`,
  "c46c1e69c89811f03dc73feb09273409": `OTP ಅಗತ್ಯವಿದೆ`,
  "ae3bb2a1ac61750150b606298091d38a": `ಹೊಸ ಪಾಸ್‌ವರ್ಡ್`,
  "887f7db126221fe60d18c895d41dc8f6": `ಪಾಸ್ವರ್ಡ್ ದೃಢೀಕರಿಸಿ`,
  "70d9be9b139893aa6c69b5e77e614311": `ದೃಢೀಕರಿಸಿ`,
  "20aedd1e6de4dcf8d115b5a7424c58d7": `ಗುಪ್ತಪದದ ಅಗತ್ಯವಿದೆ.`,
  "841a389ec69697c28df7b9739419d21d": `ಪಾಸ್ವರ್ಡ್ 6 ಅಕ್ಷರಗಳಷ್ಟು ಉದ್ದವಾಗಿರಬೇಕು.`,
  "bcb9407d90fd17ccff50587f5edfe987": `ಪಾಸ್ವರ್ಡ್ ಹೊಂದಿಕೆಯಾಗುವುದಿಲ್ಲ.`,
  "318721657b639c8ca02293211db68837": `ಫೋನ್ ಸಂಖ್ಯೆ ಅಗತ್ಯವಿದೆ`,
  "4ea3456b0e1b463f50b81c79230cf070": `ಅಮಾನ್ಯ ಫೋನ್ ಸಂಖ್ಯೆ`,
  "bd454fc3d32f4425d146d3e861409871": `ಬಳಕೆದಾರರು ಈಗಾಗಲೇ ಅಸ್ತಿತ್ವದಲ್ಲಿದ್ದಾರೆ`,
  "ab48b978d197e315e92e904e36d26175": `ಫೋನ್ ಸಂಖ್ಯೆ ಅಮಾನ್ಯವಾಗಿದೆ`,
  "70729403b9560d3adb142977608b81d2": `OTP ಮಿತಿ ಮೀರಿದೆ. 4 ಗಂಟೆಗಳ ನಂತರ ಪ್ರಯತ್ನಿಸಿ.`,
  "1e4dbc7eaa78468a3bc1448a3d68d906": `ದೂರವಾಣಿ ಸಂಖ್ಯೆ`,
  "4f2df4c330b8df125a3d75f2d69a9b5e": `OTP ಕಳುಹಿಸಿ`,
  "9ec54ec336b65da6179cf750de33ecd7": `ಪಾಸ್ವರ್ಡ್ ಮರೆತಿರಾ`,
  "98adc700c19f071f07a714a703a6fc1e": `ಐಡಿ ವಿವರಗಳು`,
  "f262fae0a2f17e8c1eb4e9eddb0160e4": `ಮುಂದುವರೆಯಲು`,
  "49fb619c4872c491fae649c28b339ed8": `ನಿಮ್ಮ ರುಜುವಾತುಗಳು ನಮ್ಮ ದಾಖಲೆಗಳಿಗೆ ಹೊಂದಿಕೆಯಾಗುವುದಿಲ್ಲ`,
  "58557a71f9811899313234decda72d5a": `ನಿಮ್ಮ ಖಾತೆಯನ್ನು 30 ನಿಮಿಷಗಳ ಕಾಲ ಲಾಕ್ ಮಾಡಲಾಗಿದೆ. ದಯವಿಟ್ಟು ಆ ಸಮಯದ ನಂತರ ಪ್ರಯತ್ನಿಸಿ.`,
  "e7665f483813a56e9410787ce2d174ef": `ಫೋನ್ ಸಂಖ್ಯೆ ಈಗಾಗಲೇ ಬಳಕೆಯಲ್ಲಿದೆ. ಸೈನ್ ಅಪ್ ಮಾಡಲು ದಯವಿಟ್ಟು ಸೈನ್ ಇನ್ ಮಾಡಿ ಅಥವಾ ಇನ್ನೊಂದು ಸಂಖ್ಯೆಯನ್ನು ಬಳಸಿ.`,
  "0a46fa5fb9e86e80fc94e229d1967699": `ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ನೋಂದಾಯಿಸಲಾಗಿಲ್ಲ. ದಯವಿಟ್ಟು ಮರುಪ್ರಯತ್ನಿಸಿ`,
  "bdff8b1cbfb4d5623382d7b0678e707c": `ಪಾಸ್ವರ್ಡ್ ಕನಿಷ್ಠ ಒಂದು ದೊಡ್ಡ ಕೇಸ್, ಒಂದು ಲೋವರ್ ಕೇಸ್, ಒಂದು ಸಂಖ್ಯೆ ಮತ್ತು ಒಂದು ವಿಶೇಷ ಅಕ್ಷರ ಸೇರಿದಂತೆ ಕನಿಷ್ಠ 8 ಅಕ್ಷರಗಳನ್ನು ಹೊಂದಿರಬೇಕು`,
  "d55085482b3e8da780755832c17d7d60": `ಕೊನೆಯ ದಿನಾಂಕ`,
  "988cddb5e417156bd3603983dc0c32e5": `ಹುಡುಕಾಟ ಯೋಜನೆ ಮತ್ತು ಸೇವೆಗಳು`,
  "261b39077b81875e115baa22bc6ce04c": `ಉದ್ಯೋಗಗಳನ್ನು ವೀಕ್ಷಿಸಲು ನಿರ್ದಿಷ್ಟ ವರ್ಗಕ್ಕೆ ಸೇರಿದೆ, ದಯವಿಟ್ಟು ವರ್ಗದ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ`,
  "bdb53070a3fc01196ae38b059a4b42ff": `ಕೆಲಸವನ್ನು ತೆಗೆದುಹಾಕಿ`,
  "ede1fcad3d6bf808fc948df7d22e1536": `ಕ್ಷಮಿಸಿ! ನಿಮಗಾಗಿ ಯಾವುದೇ ಹೊಂದಾಣಿಕೆಯ ಉದ್ಯೋಗಗಳು ನಮಗೆ ಸಿಗಲಿಲ್ಲ`,
  "0c4fa7a0201f434b0ef84494d0aac07a": `ಕ್ಷಮಿಸಿ, ಒದಗಿಸಿದ ಮಾಹಿತಿಯ ಆಧಾರದ ಮೇಲೆ, ನೀವು ಕೆಲಸಕ್ಕೆ ಅರ್ಹರಲ್ಲ`,
  "e4defbb7cdf529fa3606749a82c06932": `ಹಚ್ಚೆ`,
  "25d2401d71f4ba1ba7685559c1b77927": `ನಿಮ್ಮ ಪ್ರೊಫೈಲ್ ಅನ್ನು ಆಧರಿಸಿ, ನೀವು ಯಾವುದೇ ಕೆಲಸಕ್ಕೆ ಅರ್ಹರಾಗಿರುವುದನ್ನು ನಾವು ಕಾಣಲಿಲ್ಲ. <br> ನಾವು ನಿರಂತರವಾಗಿ ಹೆಚ್ಚಿನ ಉದ್ಯೋಗಗಳನ್ನು ಸೇರಿಸುತ್ತಿದ್ದೇವೆ, ದಯವಿಟ್ಟು ನಂತರ ಪರಿಶೀಲಿಸಿ.`,
  "ea7c2400c0ae5f58608cb5051ee852f3": `ಈ ಸಮಯದಲ್ಲಿ ನಿಮ್ಮ ರಾಜ್ಯದಲ್ಲಿ ಯಾವುದೇ ಉದ್ಯೋಗಗಳನ್ನು ಕಾನ್ಫಿಗರ್ ಮಾಡಲಾಗಿಲ್ಲ, ನಾವು ನಿರಂತರವಾಗಿ ನವೀಕರಿಸುತ್ತಿದ್ದೇವೆ, ದಯವಿಟ್ಟು ನಮ್ಮೊಂದಿಗೆ ಮತ್ತೆ ಪರಿಶೀಲಿಸಿ`,
  "24fe48030f7d3097d5882535b04c3fa8": `ಅವಧಿ ಮುಗಿದ`,
  "9dc0186e2d5d3398ffee6ec964b15dc0": `ಅವಧಿ ಮುಗಿದ ಎಲ್ಲಾ ಉದ್ಯೋಗಗಳನ್ನು ನಿಮ್ಮ “ನನ್ನ ಉದ್ಯೋಗಗಳು” ನಿಂದ ಅವಧಿ ಮುಗಿದ ದಿನಾಂಕದಿಂದ 30 ದಿನಗಳ ನಂತರ ತೆಗೆದುಹಾಕಲಾಗುತ್ತದೆ.`,
  "34a934c0c86d866df9d14f8a6e90c637": `ಅರ್ಹವಲ್ಲದ ಕಾರಣ`,
  "a97ea56b0e00b2379736ae60869ff66a": `ಪ್ರಶ್ನೆ`,
  "8e9e3212461f14c8dbea6456c920bcbc": `ಅರ್ಹ ಮೌಲ್ಯಗಳು`,
  "c4dce677291fa5bc05276ffe93c5d8f2": `ನೀವು ಒದಗಿಸಿದ ಮೌಲ್ಯ`,
  "9a917c4a4f546c6072bd61ae617763bf": `ಸ್ಕೀಮ್ ನಿಮಗಾಗಿ ಅಲ್ಲ`,
  "e57c13893a290ce882569b5638ba7b82": `ನೀವು ನಮೂದಿಸಿದ ಐಡಿ ಕಂಡುಬಂದಿಲ್ಲ. ದಯವಿಟ್ಟು ಪುನಃ ಪ್ರಯತ್ನಿಸಿ.`,
  "e26859a59ede4ab8d8ea0213b68fa732": `ಮೂಲ ವ್ಯವಸ್ಥೆಯು ಪ್ರಸ್ತುತ ಪ್ರತಿಕ್ರಿಯಿಸುತ್ತಿಲ್ಲ, ದಯವಿಟ್ಟು ಸ್ವಲ್ಪ ಸಮಯದ ನಂತರ ಪ್ರಯತ್ನಿಸಿ.`,
  // "e26859a59ede4ab8d8ea0213b68fa732":`ಮೂಲ ವ್ಯವಸ್ಥೆಯು ಪ್ರಸ್ತುತ ಪ್ರತಿಕ್ರಿಯಿಸುತ್ತಿಲ್ಲ, ದಯವಿಟ್ಟು ಐಡಿ ಇಲ್ಲದೆ ಮುಂದುವರಿಯಿರಿ ಅಥವಾ ಸ್ವಲ್ಪ ಸಮಯದ ನಂತರ ಪ್ರಯತ್ನಿಸಿ.`,
  "d2235c7ecbbbae1bf62833dade7486f5": `ಪ್ರೊಫೈಲ್ ವಿವರಗಳನ್ನು ಗೋಲ್ಡನ್ ರೆಕಾರ್ಡ್‌ನಿಂದ ತೆಗೆದುಕೊಳ್ಳಲಾಗಿದೆ.`,
  "346df6b73ae19a6619f26648cf415522": `ಅನರ್ಹ ಯೋಜನೆಗಳ ಪಟ್ಟಿ ಪ್ರೊಫೈಲ್ ಡೇಟಾವನ್ನು ಆಧರಿಸಿದೆ.`,
  "6e0fe1553e84e34da97a6c4c9040b31b": `ಲಕ್ಷರ್ತಿ`,
  "27559de2125101ccb786807bceb46aa1": `ಭಾರತ`,
  "936b6a0effe4990e8f5420a0099fa537": `EasyGov`,
  "8cf04a9734132302f96da8e113e80ce5": `ಮನೆ`,
  "923b39c56654bb6264b6638ba8386b3c": `ನಿಮ್ಮ ಪ್ರೊಫೈಲ್ ರಚಿಸಲು ಸೈನ್ ಇನ್/ಸೈನ್ ಅಪ್ ಮಾಡಿ`,
  "52f2ba99491a1bae953303a4eaf50196": `OTP ಯೊಂದಿಗೆ ಲಾಗಿನ್ ಮಾಡಿ`,
  "18e0c8084d60f55016d67d4e2ca507ce": `SSO ನೊಂದಿಗೆ ಲಾಗಿನ್ ಮಾಡಿ`,
  "d3d77957f18f1e208cb5b0caa07bec5c": `ಹೆಚ್ಚಿನ ಯೋಜನೆಗಳನ್ನು ಹುಡುಕಿ`,
  "6a26f548831e6a8c26bfbbd9f6ec61e0": `ಸಹಾಯ ಮಾಡು`,
  "b2119dd0c37bb396e24199d5ffbd0c72": `ಸ್ಕ್ರೀನ್ ರೀಡರ್ ಪ್ರವೇಶ`,
  "34b6cd75171affba6957e308dcbd92be": `ಆವೃತ್ತಿ`,
  "4221d3e17c6eca2ca6337251a3cf9c4e": `ಕೊನೆಯದಾಗಿ ನವೀಕರಿಸಲಾಗಿದೆ`,
  "ece2c7eaaac9f27b055e6e482ab776b9": `ಮಾಹಿತಿ ಹುಡುಕಾಟ`,
  "ad8f678ccf4a4a34b46f01c74f25b91b": `ವೆಬ್‌ಸೈಟ್‌ನಲ್ಲಿ ಮಾಹಿತಿಯನ್ನು ಪ್ರವೇಶಿಸಲು ಬಳಕೆದಾರರಿಗೆ ಸಹಾಯ ಮಾಡಲು, “ಇಲ್ಲಿ ಹುಡುಕಾಟ” ಆಯ್ಕೆಯನ್ನು ಒದಗಿಸಲಾಗಿದೆ. ಇಲ್ಲಿ, ಕನ್ನಡವನ್ನು ಟೈಪ್ ಮಾಡುವ ಆಯ್ಕೆಯು ಲಭ್ಯವಾಗಿದೆ. ಹುಡುಕುವಾಗ, ಯುನಿಕೋಡ್ ಫಾಂಟ್ ಬಳಕೆ ಕಡ್ಡಾಯವಾಗಿದೆ. ಇಂಗ್ಲಿಷ್ನಲ್ಲಿ ಹುಡುಕುವ ಆಯ್ಕೆಯನ್ನು ಸಹ ಒದಗಿಸಲಾಗಿದೆ.`,
  "698aa21b21c2252839e5e54a0d2f12ef": `ಇಂಟರ್ನೆಟ್ ಪ್ರವೇಶಿಸುವಿಕೆ`,
  "4ac2856693f5c8b4fc6cdaf669ed2d74": `ಇಂಟರ್ನೆಟ್ ನಿಧಾನವಾಗಿದ್ದರೆ ಅಥವಾ ಲಭ್ಯವಿಲ್ಲದಿದ್ದರೆ, ವೆಬ್‌ಸೈಟ್ ವಿನ್ಯಾಸದಲ್ಲಿ ಮಾರ್ಪಾಡು ಇರಬಹುದು ಅಥವಾ ಕೆಲವು ಉಪಪುಟಗಳು ತೆರೆಯದಿರಬಹುದು.`,
  "0da22543a45316a826571412b3d5953c": `ವೆಬ್‌ಸೈಟ್ ವರ್ಲ್ಡ್ ವೈಡ್ ವೆಬ್ ಕನ್ಸೋರ್ಟಿಯಂ (ಡಬ್ಲ್ಯು 3 ಸಿ) ವೆಬ್ ವಿಷಯ ಪ್ರವೇಶಿಸುವಿಕೆ ಮಾರ್ಗಸೂಚಿಗಳನ್ನು (ಡಬ್ಲ್ಯುಸಿಎಜಿ) 2.0 ಮಟ್ಟದ ಎಎಗೆ ಅನುಸರಿಸುತ್ತದೆ. ದೃಷ್ಟಿಹೀನತೆ ಹೊಂದಿರುವ ಜನರಿಗೆ ಸ್ಕ್ರೀನ್ ಓದುಗರಂತಹ ಸಹಾಯಕ ತಂತ್ರಜ್ಞಾನಗಳನ್ನು ಬಳಸಿಕೊಂಡು ವೆಬ್‌ಸೈಟ್ ಪ್ರವೇಶಿಸಲು ಇದು ಅನುವು ಮಾಡಿಕೊಡುತ್ತದೆ. ವೆಬ್‌ಸೈಟ್‌ನ ಮಾಹಿತಿಯನ್ನು ವಿಭಿನ್ನ ಪರದೆಯ ಓದುಗರಾದ ಜಾಸ್, ಎನ್‌ವಿಡಿಎ, ಸಫಾ, ಸೂಪರ್ನೋವಾ ಮತ್ತು ವಿಂಡೋ-ಐಸ್‌ಗಳೊಂದಿಗೆ ಪ್ರವೇಶಿಸಬಹುದು.`,
  "cb976b928eacd65e6342cfa78bf196da": `ಕೆಳಗಿನ ಟೇಬಲ್ ವಿಭಿನ್ನ ಪರದೆಯ ಓದುಗರ ಬಗ್ಗೆ ಮಾಹಿತಿಯನ್ನು ಪಟ್ಟಿ ಮಾಡುತ್ತದೆ`,
  "786f669131d80fbed4572a520c28c14d": `ವಿಷಯವನ್ನು ರಚಿಸಲಾಗಿದೆ ಮತ್ತು EasyGov ಒಡೆತನದಲ್ಲಿದೆ`,
  "fc290a7672f96fd8b94a9659c8c02322": `| ಎಲ್ಲ ಹಕ್ಕುಗಳನ್ನು ಕಾಯ್ದಿರಿಸಲಾಗಿದೆ`,
  "6016a2b341113bf496b719905398ecd2": `ಕೃತಿಸ್ವಾಮ್ಯ`,
  "0e8a85115f2c3ef0058c70f30a9b6deb": `ದಯವಿಟ್ಟು ನಿಮ್ಮ ನೋಂದಾಯಿತ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಗೆ ಕಳುಹಿಸಿದ OTP ಅನ್ನು ನಮೂದಿಸಿ`,
  "34f8ccda5172e4eac9a8a014559b38c1": `ದಯವಿಟ್ಟು ನಿಮ್ಮ ಪಾಸ್‌ವರ್ಡ್ ಅನ್ನು ಹೊಂದಿಸಿ`,
  "548899be111ad732b37786783dfbd6d8": `ಇಂಟರ್ನೆಟ್ ಸಂಪರ್ಕವಿಲ್ಲ`,
  "5c3a1593ca1d34226359df44f3d45f2f": `ಸ್ವಲ್ಪ ಸಮಯದ ನಂತರ ದಯವಿಟ್ಟು ಮತ್ತೆ ಪರಿಶೀಲಿಸಿ.`,
  '35043eeac5651298d4bb6c100e071745': `ನಿಮ್ಮನ್ನು ಮೂರನೇ ವ್ಯಕ್ತಿಯ ವೆಬ್‌ಸೈಟ್‌ಗೆ ಮರುನಿರ್ದೇಶಿಸಲಾಗುತ್ತದೆ. ನೀವು ಮುಂದುವರಿಸಲು ಬಯಸುತ್ತೀರಾ?`,
  "6182e08f3974a70ac4883b5fceaa14c0": `ದಯವಿಟ್ಟು ನಿಮ್ಮ ನೋಂದಾಯಿತ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಗೆ ಕಳುಹಿಸಿದ OTP ಅನ್ನು ನಮೂದಿಸಿ`,
  "306fb6389c3b3fd2835af15cfc7c66e9": `ಕುಟುಂಬದ ಐಡಿಯನ್ನು ಬದಲಾಯಿಸಿ`,
  "63fc7651ee1dbf6868a43a5b5fa8b581": `ಅಕ್ಷರಗಳನ್ನು ಮಾತ್ರ ಅನುಮತಿಸಲಾಗಿದೆ`,
  "6903fbe8375d52217c15618170f4078a": `ಕೇವಲ 30 ಅಕ್ಷರಗಳನ್ನು ಮಾತ್ರ ಅನುಮತಿಸಲಾಗಿದೆ`,
  "9ab37820a722ce13e80a3d0aa468d6dc": `*ಅಗತ್ಯವಿದೆ`,
  "0df1f18a2fa23cd60990e47692f99e07": `ಅಪ್ಲಿಕೇಶನ್ ಲಿಂಕ್`,
  "a60852f204ed8028c1c58808b746d115": `ಸರಿ`,
  "edbd5921e9c7168e04cbe6f75ee76174": `ನಿಮ್ಮ ಪ್ರೊಫೈಲ್ ಅನ್ನು ಆಧರಿಸಿ, ನೀವು ಯಾವುದೇ ಯೋಜನೆಗೆ ಅರ್ಹರಾಗಲಿಲ್ಲ. ನಾವು ನಿರಂತರವಾಗಿ ಹೆಚ್ಚಿನ ಯೋಜನೆಗಳನ್ನು ಸೇರಿಸುತ್ತಿದ್ದೇವೆ, ದಯವಿಟ್ಟು ನಂತರ ಪರಿಶೀಲಿಸಿ.`,
  "a203c39a340e1d45069b1ba64e1d003a": `ಗಮನ!`,
  "037673ab5a84897e0ee43ca37f7680f6": `ನಿಮ್ಮ ಡೇಟಾವನ್ನು ನೀವು ಬದಲಾಯಿಸಿದರೆ ಅದನ್ನು ನಿಮ್ಮ ಪ್ರೊಫೈಲ್ ಡೇಟಾದಲ್ಲಿಯೂ ನವೀಕರಿಸಲಾಗುತ್ತದೆ. ನೀವು ಮುಂದುವರಿಸಲು ಬಯಸುತ್ತೀರಾ?`,
  "d1d16cede248b826a431ea11132567cb": `ನಿಮ್ಮ ಪರಿಶೀಲಿಸಿದ ಡೇಟಾವನ್ನು ನೀವು ಬದಲಾಯಿಸಿದರೆ, ಅದನ್ನು ಮರು-ಪರಿಶೀಲಿಸಲಾಗುತ್ತದೆ ಮತ್ತು ನಿಮ್ಮ ಸ್ಥಿತಿಯನ್ನು ಬದಲಾಯಿಸಲಾಗುತ್ತದೆ. ಇದು ನಿಮ್ಮ ಅಪ್ಲಿಕೇಶನ್‌ನ ಹೆಚ್ಚಿನ ನಿರಾಕರಣೆ ಅಥವಾ ಹೆಚ್ಚಿದ ಸಂಸ್ಕರಣಾ ಸಮಯಕ್ಕೆ ಕಾರಣವಾಗಬಹುದು.`,
  "23a0ca84a7fd64eee8248bd1dfc77185": `ಹೆಸರು ಬದಲಾಯಿಸು`,
  "3b383caaaa6113dd83b381b43a97d7ee": `ಈ ಬಳಕೆದಾರರಿಗಾಗಿ ಈಗಾಗಲೇ ಪ್ರೊಫೈಲ್ ಅಸ್ತಿತ್ವದಲ್ಲಿದೆ, ಹೊಸ ಕುಟುಂಬ ಐಡಿಯನ್ನು ಸೇರಿಸುವುದರಿಂದ ಹಿಂದಿನ ವಿವರಗಳನ್ನು ತೆಗೆದುಹಾಕಲಾಗುತ್ತದೆ. ದಯವಿಟ್ಟು ದ್ರುಡೀಕರಿಸಿ`,
  "e0efbdcea0abe735dbf887292a5670fa": `ತಿಕ್ಕಲು`,
  "ad6e7652b1bdfb38783486c2c3d5e806": `ಆತ್ಮ`,
  "19cda37369e2d2015e66b9d478ffbe15": `ಸೋದರಿ`,
  "81e053d78f01540e9cd6fb68a1c03976": `ಬೇರೆ ಯಾರೋ`,
  "b8e18040dc07eead8e6741733653a740": `ತಾಯಿ`,
  "a30ca91be97a9cedc0d2426e54b0e782": `ತಂದೆ`,
  "784c01f6b735be57175e2f438297c741": `ಸಂಗಾತಿಯ`,
  "1968ab9fdfedf558714bf6caec9a1234": `ಪುತ್ರ`,
  "7bc1cb93f51f61bde0e90b20c81ebf88": `ಮಗಳು`,
  "25f34d5943da3f09a2aa6358193a41bd": `ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ಮುಂದುವರಿಯಿರಿ`,
  "79dff508ab2e4d272f0630d001271eb1": `ಹೆಚ್ಚಿನ ಉದ್ಯೋಗಗಳನ್ನು ಅನ್ವೇಷಿಸಿ`,
  "b6ebe1e750185946ed41be9191410612": `ಲಾಬ್ಹಾರ್ಥಿಯ ಮೂಲಕ ಅರ್ಜಿ ಸಲ್ಲಿಸಲು ನೀವು ವಿವರಗಳನ್ನು ಒದಗಿಸಬೇಕಾಗಿದೆ.`,
  "af1899084d8361b9afb9187da988ff9c": `“ಕ್ಷಮಿಸಿ, ನಿಮ್ಮ ಡೇಟಾವನ್ನು ಪಡೆಯಲು ನಮಗೆ ಸಾಧ್ಯವಾಗುತ್ತಿಲ್ಲ”.`,
  "1b56218ddf0a290241d661d26253d51f": `{{attempts}} ಪ್ರಯತ್ನ (ಗಳು) ಮಾತ್ರ ಉಳಿದಿವೆ`,
  "f299cff2484fdb361f4fc3189490d909": `ನೀವು ಒದಗಿಸಿದ ಈ ಕೆಳಗಿನ ಮಾಹಿತಿಯು ನಿಮ್ಮ ಸರ್ಕಾರದ ಮರುಕಳಿಸುವಿಕೆಯೊಂದಿಗೆ ಹೊಂದಿಕೆಯಾಗಲಿಲ್ಲ:`,
  "1edf5362e5f4af6f370a7f34c3136a7a": `ಪರಿಶೀಲನೆ ವಿಫಲವಾಗಿದೆ`,
  "dcf9ac5128c95d88e068e223dfe01d27": `ಪರಿಶೀಲನೆ ಸ್ಥಿತಿ`,
  "bc910f8bdf70f29374f496f05be0330c": `ಮೊದಲ ಹೆಸರು`,
  "77587239bf4c54ea493c7033e1dbf636": `ಕೊನೆಯ ಹೆಸರು`,
  "14fcbe1dd0f7a39f0195aba46cf89d17": `ವಿವರಗಳನ್ನು ಪರಿಶೀಲಿಸಿ`,
  "b6121db5ee47deeb0ca42d5dea0b2225": `ಪ್ರಯತ್ನಗಳು ವಿಫಲವಾಗಿವೆ`,
  "839301cec5574d7c3256afce4fb85467": `ನಿಮ್ಮ ಕುಟುಂಬದ ವಿವರಗಳನ್ನು ಪರಿಶೀಲಿಸುವ ಮಾನ್ಯ ಸಂಖ್ಯೆಯ ಪ್ರಯತ್ನಗಳನ್ನು ನೀವು ಮೀರಿದ್ದೀರಿ. ನಿಮ್ಮ ಪರಿಶೀಲನೆಯನ್ನು ನಿರ್ಬಂಧಿಸಲಾಗಿದೆ`,
  "6733aa56fd001b9bf479650f3950688a": `ನಿಮ್ಮನ್ನು ಸ್ಕೀಮ್ ಅಪ್ಲಿಕೇಶನ್ ಪುಟಕ್ಕೆ ನಿರ್ದೇಶಿಸಲಾಗುತ್ತಿದೆ`,
  "9303c9bd4f8178680dc382adbfcd62af": `ಈ ಜಾಗ ಬೇಕಾಗಿದೆ.`,
  "53a04cfc3d09c9b2dfb8349791c62bc1": `ನಿಮ್ಮ ಹೆಸರು ಈ ಕುಟುಂಬದ ಯಾವುದೇ ಸದಸ್ಯರೊಂದಿಗೆ ಹೊಂದಿಕೆಯಾಗಲಿಲ್ಲ`,
  "1ca35256fcb85c1ba9ea7ac1095462ad": `ನಿಮ್ಮ ಕುಟುಂಬದ ವಿವರಗಳನ್ನು ಪರಿಶೀಲಿಸುವ ಮಾನ್ಯ ಸಂಖ್ಯೆಯ ಪ್ರಯತ್ನಗಳನ್ನು ನೀವು ಮೀರಿದ್ದೀರಿ. ನಿಮ್ಮ ಪರಿಶೀಲನೆಯನ್ನು 3 ಗಂಟೆಗಳ ಕಾಲ ನಿರ್ಬಂಧಿಸಲಾಗಿದೆ`,
  "0e3a917198575c463bb4fdcb7332b03a": `ಅಮಾನ್ಯ ಆಧಾರ್ ಕಾರ್ಡ್ ಸಂಖ್ಯೆ`,
  "630058029627ce58e7571e7a5b6ec591": `ಪೂರ್ಣ ಹೆಸರು`,
  "072355e896ac1a999d2fa0aa208f94ee": `ಈ ಯೋಜನೆಯನ್ನು ನಿಮ್ಮ “ನನ್ನ ಯೋಜನೆಗಳಲ್ಲಿ” ಉಳಿಸಲಾಗಿದೆ.`,
  "789189638f8a301304dfadeb61036dd9": `ಈ ಸೇವೆಯನ್ನು ನಿಮ್ಮ “ನನ್ನ ಸೇವೆಗಳಲ್ಲಿ” ಉಳಿಸಲಾಗಿದೆ.`,
  "ab8023b4fc2395738b8fd2df440641a9": `ಈ ಕೆಲಸವನ್ನು ನಿಮ್ಮ “ನನ್ನ ಉದ್ಯೋಗ” ದಲ್ಲಿ ಉಳಿಸಲಾಗಿದೆ.`,
  "671dec8fc4e3dc847c1b0d7b67b4e6e1": `ಸಂಬಂಧ`,
  "f252b4e2f3b2963d330c8cde8e9cdbd9": `ಲಭ್ಯವಿದ್ದಲ್ಲಿ`,
  "ef51899b57258db72f15b4703a8a86ba": `ಪ್ಯಾನ್ ಇಂಡಿಯಾ ಸರ್ಕಾರಿ ಕಲ್ಯಾಣ ಯೋಜನೆಗಳು, ಸೇವೆಗಳು ಮತ್ತು ಉದ್ಯೋಗಗಳಿಗೆ ಕ್ರಿಯಾತ್ಮಕ ಮಾಹಿತಿಯನ್ನು ಪ್ರವೇಶಿಸಲು ನಾಗರಿಕರಿಗೆ ಒಂದೇ ವೇದಿಕೆ.`,
  //New Translations for Phase 1:`#VALUE!`,
  "7510e00267e89c78470e79b42ecd6463": `ಅಪ್ಲಿಕೇಶನ್ ಅನ್ನು ನಿಮ್ಮ ಸ್ವಂತ ಭಾಷೆಯಲ್ಲಿ ಅನ್ವೇಷಿಸಿ`,
  "83218ac34c1834c26781fe4bde918ee4": `ಸುಧನ`,
  "1d00e7dce692e8dc3f6877f035e3a616": `ಅಥವಾ`,
  "e0cf58fa3413df99ae83109ebb9a41a3": `OTP ಅನ್ನು ಮರುಕಳುಹಿಸಿ`,
  "6ed83b24865902c6fd90bbd15247e042": `ಪಾಸ್ವರ್ಡ್ ಅನ್ನು ಹೊಂದಿಸಿ`,
  "9157806ea93e82c6843e29353af0339f": `OTP ವಿನಂತಿಸಿ`,
  "69f18e98d2dd54118ade84732dc3a5e0": `ಪಿನ್ ಪರಿಶೀಲಿಸಿ`,
  "2dd4472245a696bc0b4b944db2a8b519": `ವ್ಯಕ್ತಿ`,
  "a274f4d4670213a9045ce258c6c56b80": `ಅಧಿಸೂಚನೆಗಳು`,
  "407456e575dee5a4b84a19a7111a4a4d": `ಕಡಿಮೆ ಆ 2 ಸಂಖ್ಯೆಗಳೊಂದಿಗೆ ನೀವು ಯಾವುದೇ ಸ್ಕೀಮ್ ವರ್ಗವನ್ನು ಕ್ಲಿಕ್ ಮಾಡಿದಾಗ, ನೀವು ಅರ್ಹತೆ ಪಡೆಯಬಹುದಾದ ಯೋಜನೆಗಳನ್ನು ವೀಕ್ಷಿಸಲು ನಿಮಗೆ ಸಾಧ್ಯವಾಗುತ್ತದೆ, ನೀವು 2 ಕ್ಕಿಂತ ಹೆಚ್ಚು ಸಂಖ್ಯೆಗಳೊಂದಿಗೆ ಯಾವುದೇ ಸ್ಕೀಮ್ ವರ್ಗವನ್ನು ಕ್ಲಿಕ್ ಮಾಡಿದಾಗ ನಿಮಗೆ ಹೆಚ್ಚಿನದನ್ನು ನೀಡಲು ವರ್ಗ ನಿರ್ದಿಷ್ಟ ಸಮೀಕ್ಷೆಯು ನಡೆಯುತ್ತದೆ ಆಯ್ದ ವರ್ಗದ ಯೋಜನೆಗಳ ಪರಿಷ್ಕೃತ ಪಟ್ಟಿ.`,
  "9139eb8e78808acbd15244d4eb9f1bc3": `ಹೆಚ್ಚಿನ ಯೋಜನೆಗಳನ್ನು ಅನ್ವೇಷಿಸಿ`,
  "c61dd5f0e5b5389306ad5221ec2cd795": `ಈ ಯೋಜನೆಯನ್ನು ನಿಮ್ಮ “ನನ್ನ ಯೋಜನೆಗಳಲ್ಲಿ” ಉಳಿಸಲಾಗಿದೆ`,
  "f2a6c498fb90ee345d997f888fce3b18": `ಅಳಿಸು`,
  "469bad4859f3c33fcb8ce09314c3e895": `ಸಮೀಕ್ಷೆಯ ವಿವರಗಳನ್ನು ಪರಿಶೀಲಿಸಿ`,
  "7dce122004969d56ae2e0245cb754d35": `ತಿದ್ದು`,
  "c5984b3fe02f7b06d77fb538d842b0fc": `ಸಮೀಕ್ಷೆಯ ವಿವರಗಳನ್ನು ನವೀಕರಿಸಿ`,
  "6cf33ce1a7a11a7178131567c777b637": `ಒಂದೇ ಹೆಸರು ಮತ್ತು ಸಂಬಂಧ ಹೊಂದಿರುವ ಪ್ರೊಫೈಲ್ ಈಗಾಗಲೇ ಅಸ್ತಿತ್ವದಲ್ಲಿದೆ`,
  "fffcb4d1e12c96b2f571f77ad25878b8": `ಕಾರಣ`,
  "e8d89b6875d217a990d47701138e687a": `ಈ ಡೇಟಾವನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?`,
  "aa98db08fed41a99a00aac36f521540f": `ಕೊನೆಯದಾಗಿ ಪರಿಶೀಲಿಸಿದ`,
  "5da618e8e4b89c66fe86e32cdafde142": `ನಿಂದ`,
  "253ec560db0637f6a0e53aefcbd4be0c": `ನೀವು ಲಾಭರ್ತಿ ಖಾತೆಯನ್ನು ಅಳಿಸಿದಾಗ, ನಿಮ್ಮ ಪ್ರೊಫೈಲ್‌ನಲ್ಲಿ ಉಳಿಸಲಾದ ಯಾವುದೇ ಡೇಟಾ ಅಥವಾ ಮಾಹಿತಿಯನ್ನು ಹಿಂಪಡೆಯಲು ನಿಮಗೆ ಸಾಧ್ಯವಾಗುವುದಿಲ್ಲ. {{name}} ಗಾಗಿ ಸಂಗ್ರಹಿಸಲಾದ ಎಲ್ಲಾ ಉಳಿಸಿದ ಯೋಜನೆಗಳು ಮತ್ತು ಮಾಹಿತಿಯನ್ನು ಸಹ ಅಳಿಸಲಾಗುತ್ತದೆ.`,
  "c0e70d5bfc28ae3aadfb8f85697c54d5": `ನಿಮ್ಮ ಡೇಟಾವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ನವೀಕರಿಸಲಾಗಿದೆ.`,
  "09b73aba9204a05ba5eaf0ea471fdc8c": `EasyGov ಸುರಾಜ್ಯ ಸರ್ವಿಸ್ ಪ್ರೈವೇಟ್ ಲಿಮಿಟೆಡ್‌ನ ನೋಂದಾಯಿತ ಟ್ರೇಡ್‌ಮಾರ್ಕ್ ಆಗಿದೆ. EasyGov ನ ವೇದಿಕೆ-ಸೇವೆಯ ಮಾದರಿಯು ಸರ್ಕಾರದ ಕಲ್ಯಾಣವನ್ನು ಸಂಪ್ರದಾಯವಾದಿ, ಪ್ರತಿಕ್ರಿಯಾತ್ಮಕ ಮತ್ತು ಕಾರ್ಯಕ್ರಮ ಕೇಂದ್ರಿತದಿಂದ ಹಂತ ಹಂತವಾಗಿ ಪ್ರಗತಿಶೀಲ, ಪೂರ್ವಭಾವಿ ಮತ್ತು ಕುಟುಂಬ ಕೇಂದ್ರಿತವಾಗಿ ಪರಿವರ್ತಿಸುತ್ತದೆ. ನಾಗರಿಕರ ಪ್ರಯೋಜನಗಳಿಗಾಗಿ ಕಲ್ಯಾಣ ವ್ಯವಸ್ಥೆಯ ಈ ಸಂಕೀರ್ಣ ಪ್ರಯಾಣವನ್ನು ಪರಿಹರಿಸಲು EasyGov ವಿವಿಧ ಸರ್ಕಾರಗಳಿಗೆ ಸಹಾಯ ಮಾಡುತ್ತಿದೆ. ಹೆಚ್ಚಿನ ಮಾಹಿತಿಗಾಗಿ ದಯವಿಟ್ಟು <a href='https://easygov.co.in/' target='_blank'> https://easygov.co.in/</a> ಗೆ ಭೇಟಿ ನೀಡಿ`,
  "8f241c62a9523b05bf0b6b16a09d856d": `ಭಾಷೆ ಬದಲಾಯಿಸಿ`,
  "1ccbe6084301f9b1e18e3e7caf723ae1": `ನೀವು ಲಾಗ್‌ಔಟ್ ಮಾಡಲು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?`,
  "32ca0a0f69103bad1e6f134d5a8e36d7": `ನಿರ್ದಿಷ್ಟ ವರ್ಗಕ್ಕೆ ಉದ್ಯೋಗಗಳನ್ನು ವೀಕ್ಷಿಸಲು, ದಯವಿಟ್ಟು ಯಾವುದೇ ಉದ್ಯೋಗ ವರ್ಗದ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ`,
  "d6e6cb19e3b9c02f89d6cd54cfa7c613": `ವ್ಯವಹಾರ`,
  "b1c94ca2fbc3e78fc30069c8d0f01680": `ಎಲ್ಲರೂ`,
  "1dd1c5fb7f25cd41b291d43a89e3aefd": `ಇಂದು`,
  "ebfe9ce86e6e9fb953aa7a25b59c1956": `ನಿನ್ನೆ`,
  "4b2ef3655b5d6d48f1fa41892d0043b9": `ಯಾವುದೇ ಅಧಿಸೂಚನೆಗಳು ಇಲ್ಲ`,
  "094f537cfb139894f1412200c78e87b1": `ಆಯ್ದ ಅಧಿಸೂಚನೆಗಳನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?`,
  "4201c11dc26b42deb90cb119c0b12fdb": `ಅಧಿಸೂಚನೆ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ`,
  "ae45c43efe0011f67ec52003c0d8f916": `<b> ಸುಳಿವು </b>: 'ಪಾಸ್‌ವರ್ಡ್' ಕನಿಷ್ಠ 8 <br> ಅಕ್ಷರಗಳನ್ನು ಹೊಂದಿರಬೇಕು, ಇದರಲ್ಲಿ ಕನಿಷ್ಠ ಒಂದು ದೊಡ್ಡ ಕೇಸ್, ಒಂದು ಲೋವರ್ ಕೇಸ್, ಒಂದು ಸಂಖ್ಯೆ ಮತ್ತು ಒಂದು ವಿಶೇಷ ಪಾತ್ರ.`,
  "248336101b461380a4b2391a7625493d": `ಉಳಿತ`,
  "f03ab16cd58372c77ba45a3d9a5a1cb9": `ಕರಡು`,
  "a4d158b65f20725f9fe9c1763e9805c8": `ಖಾಸಗಿ ಪ್ರಾಯೋಜಿತ`,
  "7fe12d923db43febcc5d8ea18df632ca": `ನಿಮ್ಮ ಕೆವೈಸಿ ಡಾಕ್ಯುಮೆಂಟ್ ಆಯ್ಕೆಮಾಡಿ`,
  "d8ce714c8a76133a8d5746e3bfdd7ef7": `ಕಸ`,
  "4f67fe16b274bf31a67539fbedb8f8d3": `ಡಾಕ್ಯುಮೆಂಟ್ ಪ್ರಕಾರ`,
  "5127fa87ed375848481bfe9c786d8022": `ನಿಮ್ಮ ಕೆವೈಸಿ ಭರ್ತಿ ಮಾಡಿ`,
  "8f75e3041034138aba310ba3f097ed24": `ಡಾಕ್ಯುಮೆಂಟ್ ಸಂಖ್ಯೆ`,
  "9abfb506bebd1e72069f0be0014986dc": `ಅಮಾನ್ಯ ಡಾಕ್ಯುಮೆಂಟ್ ಸಂಖ್ಯೆ`,
  "ecfffd1bad9944f6a50e369f63268cff": `ಕ್ಯೂಆರ್ ಸ್ಕ್ಯಾನ್`,
  "c541f95d69a4a507b87b32d05dd6d73c": `ಕೋಡ್ ಅನ್ನು ಫ್ರೇಮ್ ಒಳಗೆ ಇರಿಸಿ`,
  "3876661cb83fa41284f828bd8d7fbb7b": `ಕೆವೈಸಿ ಪ್ರಕ್ರಿಯೆಯ ಪರಿಶೀಲನೆ ಮತ್ತು ಪೂರ್ಣಗೊಳಿಸುವಿಕೆಗಾಗಿ ಕೆವೈಸಿ ದಾಖಲೆಗಳನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಬೇಕು.`,
  "cc1d6f3625c4eb671d76b6ebf3f6f544": `ನಿಮ್ಮ ಗ್ರಾಹಕರನ್ನು ತಿಳಿದುಕೊಳ್ಳಿ`,
  "08ac502bbb7652c683c1da97c7fdeb94": `{{xxxx}} ಅವರು ನಿಮ್ಮ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಗೆ OTP ಕಳುಹಿಸಿದ್ದಾರೆ`,
  "d19a88ee579190614084c2ea84379ac7": `OTP (1:00) ಮರುಕಳುಹಿಸಿ`,
  "7397e92003e187474e5eab39b27fcb6b": `ಕ್ಷಮಿಸಿ, ನಿಮ್ಮ ಡೇಟಾವನ್ನು ಮೂಲ ವ್ಯವಸ್ಥೆಗಳಿಂದ ಪಡೆಯಲು ನಮಗೆ ಸಾಧ್ಯವಾಗುತ್ತಿಲ್ಲ. ದಯವಿಟ್ಟು ನಂತರ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ.`,
  "a943d4ac1dd2e0048bcbf85225bf2811": `OTP ರಚಿಸಲು ನೀವು ಗರಿಷ್ಠ ಮಿತಿಯನ್ನು ಮೀರಿದ್ದೀರಿ, ದಯವಿಟ್ಟು 3 ಗಂಟೆಗಳ ನಂತರ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ`,
  "8dac090b39cf9b3bff9a9503531d04cf": `ನಿಮ್ಮ ಖಾತೆಯನ್ನು ಪ್ರಸ್ತುತ ನಿರ್ಬಂಧಿಸಲಾಗಿದೆ, ದಯವಿಟ್ಟು ಸ್ವಲ್ಪ ಸಮಯದ ನಂತರ ಪ್ರಯತ್ನಿಸಿ.`,
  "08798caaee5cc8ea1282883901c8810b": `ಉಳಿದಿರುವ ಪ್ರಯತ್ನಗಳು - {{Number}} ಎಚ್ಚರಿಕೆ - ಸತತ 4 ವಿಫಲ ಪ್ರಯತ್ನಗಳ ನಂತರ, ನಿಮ್ಮ ಖಾತೆಯನ್ನು ಲಾಕ್ ಮಾಡಲಾಗುತ್ತದೆ.`,
  "33018956b3ffe20d413e3c6780f19aff": `ಕೆವೈಸಿ ವಿವರಗಳನ್ನು ದೃಢೀಕರಿಸಿ`,
  "90f35a35415ca3b4c623d7c515fce1c0": `ಗಮನ! ನಿಮ್ಮ ವಿವರಗಳನ್ನು ನೀವು ನವೀಕರಿಸಿದರೆ ಅಥವಾ ಬದಲಾಯಿಸಿದರೆ ನಿಮ್ಮ ಡೇಟಾವನ್ನು ಪರಿಶೀಲಿಸಲಾಗುವುದಿಲ್ಲ ಮತ್ತು ನವೀಕರಿಸಿದ ಡೇಟಾಕ್ಕಾಗಿ ಡಾಕ್ಯುಮೆಂಟ್ ಪ್ರೂಫ್ ಅನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಲು ನೀವು ಕೇಳುತ್ತೀರಿ. ಮುಂದುವರಿಯಲು "ಮುಂದುವರಿಸಿ" ಕ್ಲಿಕ್ ಮಾಡಿ`,
  "aee68a98fd803f091082faf867c0f62b": `ಕೆವೈಸಿ ಫಾರ್ಮ್ ಅನ್ನು ಭರ್ತಿ ಮಾಡಿ`,
  "2e853d869dd0c3cb0a64e4938ee90647": `ಅಮಾನ್ಯ ಪಿನ್ ಕೋಡ್`,
  "8f3124e142a4000fe04a581f4f41c8da": `ಅಮಾನ್ಯ {{labour card}} ಸಂಖ್ಯೆ`,
  "dbb362212f1b8118f17633521d1e72f7": `ನಿಮ್ಮ 'ಕರಡು ಯೋಜನೆ'ಯಲ್ಲಿ ನಿಮ್ಮ ಅಪ್ಲಿಕೇಶನ್ ಯಶಸ್ವಿಯಾಗಿ ಉಳಿಸಲಾಗಿದೆ.`,
  "5c93310dd0291e121181e830cdda892e": `ಗ್ಯಾಲರಿ`,
  "967d35e40f3f95b1f538bd248640bf3b": `ಕ್ಯಾಮೆರಾ`,
  "37bed5f24fa3752c7830c021501539a0": `ನೀವು ಫೋಟೋವನ್ನು ತೆಗೆದುಹಾಕಲು ಬಯಸುತ್ತೀರಾ?`,
  "1a263d755f169c2499c1a55f7a465b93": `ನಿಮ್ಮ ಡಾಕ್ಯುಮೆಂಟ್ ಐಡಿಯನ್ನು ಪ್ರಸ್ತುತ ನಿರ್ಬಂಧಿಸಲಾಗಿದೆ, ದಯವಿಟ್ಟು ಸ್ವಲ್ಪ ಸಮಯದ ನಂತರ ಪ್ರಯತ್ನಿಸಿ.`,
  "689707127ddb398dc4b447e1dffa3a47": `ನಿಮ್ಮ ವಿವರಗಳನ್ನು ಪರಿಶೀಲಿಸುವ ಮಾನ್ಯ ಸಂಖ್ಯೆಯ ಪ್ರಯತ್ನಗಳನ್ನು ನೀವು ಮೀರಿದ್ದೀರಿ. ನಿಮ್ಮ ಪರಿಶೀಲನೆ 3 ಗಂಟೆಗಳ ಕಾಲ ನಿರ್ಬಂಧಿಸಲಾಗಿದೆ.`,
  "04c850a81052df23680d00d2aa1d891e": `ಕೆವೈಸಿ ಸ್ಥಿತಿ`,
  "a441e519087df0d7c8572b9fafa14ae2": `ನೀವು ಒದಗಿಸಿದ ಕೆಳಗಿನ ಮಾಹಿತಿಯು ನಿಮ್ಮ ಸರ್ಕಾರಿ ದಾಖಲೆಯೊಂದಿಗೆ ಹೊಂದಿಕೆಯಾಗುತ್ತಿಲ್ಲ.1. ನಿಮ್ಮ ಹೆಸರು ದಾಖಲೆಯೊಂದಿಗೆ ಹೊಂದಿಕೆಯಾಗುತ್ತಿಲ್ಲ.2. ನಿಮ್ಮ ವಯಸ್ಸು ದಾಖಲೆಯೊಂದಿಗೆ ಹೊಂದಿಕೆಯಾಗುತ್ತಿಲ್ಲ.`,
  "75514ce9dac260a1f7abd0d85ce6af46": `ನೀವು ಒದಗಿಸಿದ ವಿವರಗಳು ದಾಖಲೆಗಳೊಂದಿಗೆ ಹೊಂದಿಕೆಯಾಗುವುದಿಲ್ಲ`,
  "5955e277da6fb75982b0f651f4ae8b92": `ನಿಮ್ಮ ಡೇಟಾವನ್ನು ಮೂಲ ವ್ಯವಸ್ಥೆಗಳಿಂದ ಪಡೆಯಲು ನಮಗೆ ಸಾಧ್ಯವಾಗುತ್ತಿಲ್ಲ. ದಯವಿಟ್ಟು ನಂತರ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ.`,
  "be60ac326400cc5ef0d173a833c4a40b": `ನಿಮ್ಮ ವಿವರಗಳನ್ನು ಪರಿಶೀಲಿಸಲಾಗುತ್ತಿದೆ`,
  "d1083e84a9a7283ffb081263e8533509": `ನಿಮ್ಮ ‘ಡ್ರಾಫ್ಟ್ ಸ್ಕೀಮ್’ ನಲ್ಲಿ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಉಳಿಸಲಾಗಿದೆ`,
  "0bad551abed71c0a41ca8df804afec86": `ಡಾಕ್ಯುಮೆಂಟ್ ಬಳಸಿ ಕೆವೈಸಿ`,
  "e6387f3e684958515af2947d8cdf01d7": `ಕೆವೈಸಿ ಫಾರ್ಮ್ ಅನ್ನು ನವೀಕರಿಸಿ`,
  "9c7ed3d8ac60281e90519b4ebf07a9a2": `ವಿಳಾಸವನ್ನು ಆರಿಸಿ`,
  "31e41095bfaa14799239e8d9ba7ad438": `ಅಪ್ಲಿಕೇಶನ್ ವಿವರಗಳು`,
  "e1e4c8c9ccd9fc39c391da4bcd093fb2": `ನಿರ್ಬಂಧ`,
  "b9eca8f59016e8ff69a2c76fa869d23f": `ಕಾರ್ಮಿಕ ಕಾರ್ಡ್ ಅಪ್‌ಲೋಡ್ ಮಾಡಿ`,
  "6cf24d7a07132fe88b610e2b3d3def28": `ಸ್ವಯಂ ಘೋಷಣೆ`,
  "827b05360cd22ccbaf3d085aded71570": `ವಿಳಾಸದ ಪುರಾವೆ`,
  "f734cd7792862bebbbb87ddc54285376": `ಗುರುತಿನ ಆಧಾರ`,
  "5a8c686dfdba564a2801806f3827c124": `ಚಿತ್ರವು JPG ಅಥವಾ PNG ಸ್ವರೂಪದಲ್ಲಿರಬೇಕು. PDF ಫೈಲ್ 1MB ಗಿಂತ ಕಡಿಮೆ ಇರಬೇಕು.`,
  "d495cdb8315e12e3dc5fd6121ed69cbc": `ಅಮಾನ್ಯ IFSC ಕೋಡ್`,
  "cd9f177eac6411501839f7868e38767b": `ಈ ಡಾಕ್ಯುಮೆಂಟ್ ಅನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?`,
  "565d2daf0ccaf00d354669e7551b81dd": `ಪ್ರೊಫೈಲ್ ವಿವರಗಳನ್ನು ಪರಿಶೀಲಿಸಿ`,
  "9895dd168483d1d34ab9ebfb2b6760be": `ಬ್ಯಾಂಕ್ ಆಯ್ಕೆಮಾಡಿ`,
  "deb5c3f30f3a8381367392d6608fbe68": `ಅಪ್ಲಿಕೇಶನ್ ಸ್ಥಿತಿ`,
  "970324882e5bbfd2deef9e5b7e2d1160": `ಅಪ್ಲಿಕೇಶನ್ ಡೌನ್ಲೋಡ್ ಮಾಡಿ`,
  "03b55d8ed61b625c209cc42ea0a35b8c": `ಧನ್ಯವಾದಗಳು!`,
  "8276f0b86b5ee98d03f2eafba3590bb0": `ನಿಮಗೆ ಸೇವೆ ಸಲ್ಲಿಸಲು ಅವಕಾಶ ನೀಡಿದ್ದಕ್ಕಾಗಿ. ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಸಲ್ಲಿಸಲಾಗಿದೆ.`,
  "ac3122aeddff176ae67d3df2b2198d19": `ಧನ್ಯವಾದಗಳು! ಪರಿಶೀಲನೆಗಾಗಿ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಸಲ್ಲಿಸಲಾಗಿದೆ, "ಅನ್ವಯಿಕ ಅಪ್ಲಿಕೇಶನ್‌ಗಳು" ಅಡಿಯಲ್ಲಿ ಅಪ್ಲಿಕೇಶನ್‌ನಿಂದ ನಿಮ್ಮ ಅಪ್ಲಿಕೇಶನ್ ಸ್ಥಿತಿಯನ್ನು ಪರಿಶೀಲಿಸಿ`,
  "73da163444abaee6a5c88532b8eda629": `ನಿಮ್ಮ ಅನುಭವ ಹೇಗಿತ್ತು?`,
  "74eb855e4de6fe58228f03006c02fd8a": `ಸರಿ`,
  "d36ed38206da38c85af59039d18f1108": `<b>ನಿಮ್ಮ ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ವಿವರಿಸಿ</b> (ಐಚ್ಛಿಕ)`,
  "446e92f15cd245125ccd983ef968e692": `ಬೇಡ ಧನ್ಯವಾದಗಳು!`,
  "634a1a1ed07518476cb7a4beab87185a": `ಭಯಾನಕ`,
  "f1b68d66337a81cfa0d2076171cba2a8": `ಕೆಟ್ಟ`,
  "45802158e78dd9584161629098018fe8": `ಒಳ್ಳೆಯ`,
  "5709082e0d4e7a2cb92c2348acf297be": `ಇಷ್ಟವಾಯಿತು`,
  "c629e8ac2592361ec6474f07fb75f6fc": `ನಿಮ್ಮ ಪ್ರತಿಕ್ರಿಯೆ ಸುಧಾರಿಸಲು ಹೊಸ ಅವಕಾಶಗಳನ್ನು ಕಂಡುಹಿಡಿಯಲು ಮತ್ತು ನಿಮಗೆ ಉತ್ತಮ ಅಪ್ಲಿಕೇಶನ್ ಅನುಭವವಿದೆ ಎಂದು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು ನಮಗೆ ಅನುಮತಿಸುತ್ತದೆ.`,
  "871abe85eba547582d9e8f0f9c61efbd": `ನಮಗೆ ಪ್ರತಿಕ್ರಿಯೆ ಕಳುಹಿಸಿದ್ದಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು.`,
  "733d43480c8589b1368e5def6b480415": `ಅನ್ವಯಿಕ`,
  "a5b739036b4d0b2bc966291747198d9b": `ಸೇರಿಕೊಂಡಳು`,
  "0a4c051fb821b8e96d2bd564b893639b": `ನಿಮಗಾಗಿ ಕಸ್ಟಮೈಸ್ ಮಾಡಿದ ಯೋಜನೆಗಳಿಲ್ಲ`,
  "9639e32cab248434a17ab32237cb3b71": `ಅನ್ವಯಿಸು`,
  "3f68e67dc6c397aaa9d1c24c356f754f": `ಪರಿಶೀಲಿಸಿದ`,
  "d63b911814d17bb4d541141b8531a458": `ಈ ಯೋಜನೆಗಳನ್ನು ನಿಮ್ಮ "ನನ್ನ ಯೋಜನೆಗಳಲ್ಲಿ" ಉಳಿಸಲಾಗಿದೆ`,
  "454fd3b5167e532f28f4e3d47cd901fa": `ನೀವು ಇನ್ನೂ ಯಾವುದೇ ಉಳಿಸಿದ ಯೋಜನೆ ಇಲ್ಲ`,
  "5e22b63835aef7976f3ca019841b1b28": `ಈ ಯೋಜನೆಯನ್ನು ತೆಗೆದುಹಾಕಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?`,
  "8454c7c12286671b47684cef824ffba6": `"ಗಮನ! ನಿಮ್ಮ ಡೇಟಾವನ್ನು ನೀವು ಬದಲಾಯಿಸಿದರೆ ಅದನ್ನು ನಿಮ್ಮ ಪ್ರೊಫೈಲ್ ಡೇಟಾದಲ್ಲಿಯೂ ನವೀಕರಿಸಲಾಗುತ್ತದೆ. ನೀವು ಮುಂದುವರಿಸಲು ಬಯಸುವಿರಾ?"`,
  "24a23d787190f2c4812ff9ab11847a72": `ಸ್ಥಿತಿ:`,
  "85896a744f6fd84cbcd7bddb31fae8d7": `ಕ್ರಿಯೆ:`,
  "ccb168b5a6a86eb100dc2dee754a316b": `ಕಾರಣ:`,
  "85b7d770b94264f51f31d2d7a98f69f0": `ಅರ್ಜಿಯನ್ನು ಹಿಂತೆಗೆದುಕೊಳ್ಳಿ`,
  "0ddc5e1c93fec761445a53ee9fd70285": `ಅಧಿಕಾರಕ್ಕೆ ಕಳುಹಿಸಿ`,
  "7a4813490daea6bbe84ed02f9534570b": `ಅಪ್ಲಿಕೇಶನ್ ಮತ್ತು ಲಗತ್ತು ಪರಿಶೀಲಿಸಲಾಗಿದೆ`,
  "f9e39c8a1b3348b654b73fcdebeb3265": `ಅರ್ಜಿಯನ್ನು ಇನ್ನೂ ಅನುಮೋದಿಸಲಾಗಿಲ್ಲ`,
  "6b76127f99b6f29c2a725da125d98760": `ಲಾಭ ವಿತರಣೆಗೆ`,
  "4296fd320beaf61dcf979ff634cf2f2d": `ಕ್ರಮ ಅಗತ್ಯವಿದೆ:`,
  "fc341b619c2671a430365edefc100ff1": `ಸಂಪೂರ್ಣ ಕ್ರಿಯೆಗಳು`,
  "4f4b8b7d27a33c83089f30dba4989be5": `ಅಪ್ಲಿಕೇಶನ್‌ಗಳನ್ನು ವೀಕ್ಷಿಸಿ`,
  "2f70605f8a2f6c01ac06fe0f6d32b44c": `ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಹಿಂತೆಗೆದುಕೊಳ್ಳಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?`,
  "6eaaa83f6b9b14dbed5e0779d91fffb5": `ಅರ್ಜಿಯನ್ನು ತಿರಸ್ಕರಿಸಲಾಗಿದೆ`,
  "6187927c14aae6cab0f87b55ef75edb1": `ಮತ್ತೆ ಅರ್ಜಿ ಸಲ್ಲಿಸು`,
  "864cacf59d640b51390faf29867c310e": `ಮುಂದಿನ ಪ್ರಯೋಜನಕ್ಕಾಗಿ ಮುಂದುವರಿಯಿರಿ`,
  "16d2b386b2034b9488996466aaae0b57": `ಇತಿಹಾಸ`,
  "c5b2992c0919083c2d8907f8fe1441f6": `ಸಾಮಾಜಿಕ-ಆರ್ಥಿಕ ವಿವರಗಳು`,
  "d79c1042de4ec2d7e6d84d3a8f436765": `ಉದ್ಯೋಗ ಪ್ರೊಫೈಲ್`,
  "3c8d3371dfe92bb990493d3dda09717c": `ಶಿಕ್ಷಣ ವಿವರ`,
  "181bfe2f225b876c687104878a6b43cc": `ವಸತಿ ವಿವರಗಳು`,
  "f081b3a821bf13173ac4ec3468122a8c": `ಆರೋಗ್ಯದ ವಿವರಗಳು`,
  "fa284c99eeef0e1c874643e05a449214": `"ಗಮನ! ನೀವು ಎಲ್ಲಾ ಮೌಲ್ಯಗಳನ್ನು ಆಯ್ಕೆ ಮಾಡಲು ಸಾಧ್ಯವಿಲ್ಲ. ಈ ಮೌಲ್ಯವನ್ನು ಆಯ್ಕೆ ಮಾಡುವ ಮೊದಲು ಮತ್ತೊಂದು ಮೌಲ್ಯವನ್ನು ಆರಿಸಿ."`,
  "311d38f551d45cb61e297748f08bb658": `ನಿಮ್ಮ ಡೇಟಾವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಉಳಿಸಲಾಗಿದೆ`,
  "4500d073ef5972cc935a59ff0b752d8b": `ಮಾನ್ಯ`,
  "8de420ed8c345d37cf65a3494bff92ef": `ಮತಕಡೆ`,
  "f6fb95a7bfd158a53680691f250e2273": `ಸರ್ಕಾರಿ ಶುಲ್ಕ:`,
  "c51bae43a860107d2ddaddcc44c72355": `ವಿಳಾಸದ ಪುರಾವೆ (ಯಾರಾದರೂ)`,
  "a1cba2ed3414b72aa4d7651653d08900": `ವಯಸ್ಸಿನ ಪುರಾವೆ (ಯಾರಾದರೂ)`,
  "330dd937743341b8c97a75ddbcfacf1b": `ಫಾರ್ಮ್ ಅನ್ನು ಡೌನ್‌ಲೋಡ್ ಮಾಡಲು ಲಿಂಕ್:`,
  "de259fed6ef60355d0dd031c4a97e945": `ಫಾರ್ಮ್ ಅನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಲು ಲಿಂಕ್:`,
  "6204374d06a6ca36a20e887451db78af": `ಹೆಚ್ಚಿನ ಸೇವೆಗಳನ್ನು ಅನ್ವೇಷಿಸಿ`,
  "98d047289f68350b76f20cd10f1db3d4": `ನಾನು ಡಿ ಯ ಪುರಾವೆ`,
  "1a01812e1888f4eb6dca36e8b1d39c04": `ಡಾಬ್ ಪ್ರೂಫ್`,
  "0dcd6f339c0f72875972d60324db52ff": `ಅರ್ಜಿ ಶುಲ್ಕ ವಿವರಗಳು`,
  "133e72d94045f209d146dd6fc01de185": `ಶುಲ್ಕ ವಿವರಗಳು`,
  "b6d11bb63dc2071d6cba2aaeba8c8a1d": `ಅನುಕೂಲಕರ ಶುಲ್ಕ`,
  "719fec04166d6fa75f89cd29ad61fa8c": `ತೆರಿಗೆ`,
  "a876fbe73a6d039cb8c8566f6aa716d5": `ಒಟ್ಟು ಮೊತ್ತ`,
  "9d463ea3feb5afec51c20b4f62927ca3": `ಕೂಪನ್ ಕೋಡ್`,
  "620a92a00838e523161dab899b76c764": `ಪಾವತಿಸಲು ಮುಂದುವರಿಯಿರಿ`,
  "6f861b42317b2333c01faa9b555b7e14": `ಈ ಕೂಪನ್‌ನೊಂದಿಗೆ ಉಳಿತಾಯ.`,
  "c6088ccfc87a825ceb53a15ce4d99510": `ನೀವು <Amount> ಒಟ್ಟು ರಿಯಾಯಿತಿಯನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದೀರಿ`,
  "0e54fc6a9dba79c2bc03ac08f3bf4a27": `ವಾಹ್!`,
  "c1d9a46634bb89273f7d95a5577f15ad": `ಕೂಪನ್ ರಿಯಾಯಿತಿ`,
  "1063e38cb53d94d386f21227fcd84717": `ತೆಗೆದುಹಾಕು`,
  "ba8550da63869236a92fed41fba6e84f": `ಕೂಪನ್ ಕೋಡ್ ಯಶಸ್ವಿಯಾಗಿ ಅನ್ವಯಿಸಲಾಗಿದೆ`,
  "f7a7e0e3897bac9ea4c83d53f6ad5a23": `ಅಮಾನ್ಯ ಕೂಪನ್ ಕೋಡ್.`,
  "90fe9a78f3ea30beeb6b7ef447d2b6dd": `ಕೂಪನ್ ಕೋಡ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ತೆಗೆದುಹಾಕಲಾಗಿದೆ`,
  "af1b98adf7f686b84cd0b443e022b7a0": `ವರ್ಗಗಳು`,
  "11f18dd73f0c3d374d9d5c230cd43160": `ಯುವ ಕೌಶಲ್ಯ`,
  "5a6c0d2579b31142130f20104fa58ea6": `ಪ್ರಮಾಣಪತ್ರ`,
  "c482980d384a9d0e7bc39e1140270870": `ಹಣಕಾಸು`,
  "605669cab962bf944d99ce89cf9e58d9": `ಆರೋಗ್ಯ`,
  "5dac73aa49b9ccaca4b9b0d0ef615d10": `ಪೊಲೀಸರು`,
  "b2ece3493eed05158a9cde4a0d2da2a5": `ಸಾರ್ವಜನಿಕ ಕುಂದುಕೊರತೆ`,
  "94df2a6972ca1fa79411645fe9b42339": `ಉಪಯುಕ್ತತೆ`,
  "f5f0c488ed56cf789d19a13ffba8abe3": `ಸಾಗಿಸು`,
  "0db377921f4ce762c62526131097968f": `ಸಾಮಾನ್ಯ`,
  "bc34e4c4f36b0d66910a906449910f64": `ಈ ಸೇವೆಯನ್ನು ತೆಗೆದುಹಾಕಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?`,
  "1e22827dff05f5a1c044993aff539403": `ನೀವು ಯಾವುದೇ ಉಳಿತಾಯ ಸೇವೆಗಳನ್ನು ಹೊಂದಿಲ್ಲ.`,
  "b08f17b81d3b9b4f6287b87c53cfc97a": `ಡ್ರಾಫ್ಟ್‌ನಲ್ಲಿ ನೀವು ಯಾವುದೇ ಸೇವೆಗಳನ್ನು ಹೊಂದಿಲ್ಲ.`,
  "6f96ffbc03ad1b9ebb5c6e2ecfcbdc71": `ನೀವು ಇನ್ನೂ ಯಾವುದೇ ಸೇವೆಗಳನ್ನು ಅನ್ವಯಿಸಿಲ್ಲ.`,
  "daf87fe41645c86672e96857222a0909": `ಅರ್ಜಿಯನ್ನು ಸಲ್ಲಿಸಲಾಗಿದೆ`,
  "54aeeea175c1509b7c56e0c984f9b4fa": `ನಿಮಗೆ ಸೇವೆ ಸಲ್ಲಿಸಲು ಅವಕಾಶ ನೀಡಿದ್ದಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು. ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಸಲ್ಲಿಸಲಾಗಿದೆ.`,
  "f18c4dab2674eae1e8c287c683b66729": `ಅರ್ಜಿ ಸಲ್ಲಿಸಿದ ದಿನಾಂಕ`,
  "fb0728ffa9b40d444db91c818eb21a2b": `ಅರ್ಜಿದಾರರ ಹೆಸರು`,
  "e500e94f457ddd88824c88fda548bed2": `ಅಪ್ಲಿಕೇಶನ್ ಐಡಿ`,
  "ad0206b8eec486df629ce37169376f65": `ಪಾತ್ರ ಮತ್ತು ಜವಾಬ್ದಾರಿಗಳು:`,
  "6f2ad1cfe3c5b08cc53c62470f4cb1f7": `ಅವಶ್ಯಕ ದಾಖಲೆಗಳು:`,
  "1d83797411218ecca1e5ff4d4705dfb0": `ಆಯ್ಕೆ ವಿಧಾನ:`,
  "ce9e1e989c012211d8bc83c5353b4495": `ಉದ್ಯೋಗ ಅಪ್ಲಿಕೇಶನ್ ವಿವರಗಳು`,
  "18dcaa84c2ee447028f8450cd0380ba6": `ಶೈಕ್ಷಣಿಕ ವಿವರಗಳು:`,
  "93e9a018926429f6d0b0c032910a8eb6": `ಉದ್ಯೋಗ ಅಪ್ಲಿಕೇಶನ್ ವಿವರಗಳನ್ನು ಪರಿಶೀಲಿಸಿ`,
  "6f906132d3c6b88b57225fdcf76f6e71": `ಪದೇ ಪದೇ ಕೇಳಲಾಗುವ ಪ್ರಶ್ನೆಗಳು`,
  "2d8d66b9c9b540fcb8384167f1565b64": `ದಯವಿಟ್ಟು ಕಾಗುಣಿತವನ್ನು ಪರಿಶೀಲಿಸಿ ಅಥವಾ ಬೇರೆ ಯಾವುದನ್ನಾದರೂ ಹುಡುಕಲು ಪ್ರಯತ್ನಿಸಿ.`,
  "f73a506c52c2dba159fc0fbee823aace": `ಈ ಯೋಜನೆಗಾಗಿ ನೀವು ಈಗಾಗಲೇ ಅರ್ಜಿ ಸಲ್ಲಿಸಿದ್ದೀರಿ. ನಿಮ್ಮ ಅನ್ವಯಿಕ ಯೋಜನೆಗಳಿಂದ ದಯವಿಟ್ಟು ನಿಮ್ಮ ಅಪ್ಲಿಕೇಶನ್ ಸ್ಥಿತಿಯನ್ನು ಪರಿಶೀಲಿಸಿ.`,
  "b73856e1c7ab3e647e28663d5e30fabe": `ಈ ಯೋಜನೆಗಾಗಿ ನೀವು ಈಗಾಗಲೇ ಅರ್ಜಿ ಸಲ್ಲಿಸಿದ್ದೀರಿ. ನಿಮ್ಮ ಅನ್ವಯಿಕ ಸೇವೆಗಳಿಂದ ದಯವಿಟ್ಟು ನಿಮ್ಮ ಅಪ್ಲಿಕೇಶನ್ ಸ್ಥಿತಿಯನ್ನು ಪರಿಶೀಲಿಸಿ.`,
  "cd850a99c0895272f2cdf23586867b16": `ಈ ಯೋಜನೆಗಾಗಿ ನೀವು ಈಗಾಗಲೇ ಅರ್ಜಿ ಸಲ್ಲಿಸಿದ್ದೀರಿ. ನಿಮ್ಮ ಉದ್ಯೋಗಗಳಿಂದ ದಯವಿಟ್ಟು ನಿಮ್ಮ ಅಪ್ಲಿಕೇಶನ್ ಸ್ಥಿತಿಯನ್ನು ಪರಿಶೀಲಿಸಿ.`,
  "4e0b8852fc3f3d1a2b4c24c4602766dd": `ಚಿತ್ರವು JPG, PNG, PDF ಫೈಲ್ ಮತ್ತು 1MB ಗಿಂತ ಕಡಿಮೆ ಇರಬೇಕು.`,
  "5a9e7167eb5b7cb7bb46cf5c05eed59a": `ಗಡಿನ`,
  "91412465ea9169dfd901dd5e7c96dd99": `ಉರುಳಿಸು`,
  "3835b71ace5673385a19d56bc938fda8": `ಫೀಲ್ಡ್ ಫೋಟೋಗ್ರಾಫ್`,
  "4386624773a0ef6de5a5de0dd6f8facd": `ಡಾಕ್ಯುಮೆಂಟ್ ಪ್ರಕಾರವನ್ನು ನಮೂದಿಸಿ`,
  "fdba3d2a9c707e50f4eec34cd6bdd0d6": `"ನಿಮ್ಮ ಗ್ರಾಹಕರನ್ನು ತಿಳಿದುಕೊಳ್ಳಿ" ಎಂಬುದು ಸರ್ಕಾರದ ಯೋಜನೆಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸುತ್ತಿರುವ ಬಳಕೆದಾರರ ಗುರುತನ್ನು ಗುರುತಿಸುವ ಮತ್ತು ಪರಿಶೀಲಿಸುವ ಕಡ್ಡಾಯ ಪ್ರಕ್ರಿಯೆಯಾಗಿದೆ.`,
  "244773cb8f460196045c5b90659d2c9d": `ನಿಮ್ಮ ಅಪ್ಲಿಕೇಶನ್ ಅನ್ನು ನಿಮ್ಮ "ಡ್ರಾಫ್ಟ್ ಸೇವೆಗಳಲ್ಲಿ" ಯಶಸ್ವಿಯಾಗಿ ಉಳಿಸಲಾಗಿದೆ`,
  "bbcb2990551820fc4678835c14a84503": `ಫೋಟೋ ತೆಗೆದುಹಾಕಿ`,
  "78e3e2ba60b81cf6cc24e6baf89eaa81": `ವೈಯಕ್ತಿಕ ಪ್ರಮಾಣಪತ್ರಗಳು`,
  "88bcab139c5d6142b04bf77d7dd1c023": `ನೀವು ಈಗಾಗಲೇ ಈ ಸೇವೆಗಾಗಿ ಅರ್ಜಿ ಸಲ್ಲಿಸಿದ್ದೀರಿ. ನಿಮ್ಮ ಅನ್ವಯಿಕ ಸೇವೆಗಳಿಂದ ದಯವಿಟ್ಟು ನಿಮ್ಮ ಅಪ್ಲಿಕೇಶನ್ ಸ್ಥಿತಿಯನ್ನು ಪರಿಶೀಲಿಸಿ.`,
  "bf8cd5900a65efaa89931067cb1d3775": `ಡ್ರಾಫ್ಟ್‌ನಲ್ಲಿ ನೀವು ಯಾವುದೇ ಯೋಜನೆಗಳನ್ನು ಉಳಿಸಿಲ್ಲ.`,
  "beab92ac3c8a798a35aea2ad0809313f": `ಅತಿಥಿಯಾಗಿ ಮುಂದುವರಿಸಿ`,
  "95884374c7752d972101b70854caffe6": `ಸೈನ್ ಇನ್/ಸೈನ್ ಅಪ್ ಮಾಡಿ`,
  "e44cb1c4a784a247f7296cc02f7f301c": `ಜಿಲ್ಲೆ ಆಯ್ಕೆಮಾಡಿ`,
  "9aa060dc3e3bb3ffff9908c5e713a80c": `ನೀವು ಇನ್ನೂ ಯಾವುದೇ ಸೇವೆಗಳಿಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಿಲ್ಲ.`,
  "383f419ea8c0b8142cbdc71e2e4b144a": `ಹಿಂದಿನ ಪ್ರಶ್ನೆ`,
  "fb188e808b65b9ee48a5c9d88fb92af2": `ಮತ್ತೆ ಪ್ರಾರಂಭಿಸಿ`,
  "db5eb84117d06047c97c9a0191b5fffe": `ಬೆಂಬಲ`,
  "bc80ae98530c6ba4e76ed34c58d81875": `ಮೊಬೈಲ್ ಅಪ್ಲಿಕೇಶನ್‌ಗಾಗಿ Easygov ಅನ್ನು ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ`,
  "85ce0cae173233410acbc380a7e531cd": `ಈ ಪುಟವು ಸರ್ಕಾರದ ವೆಬ್‌ಸೈಟ್‌ಗಳು / ವೆಬ್ ಪುಟಗಳಿಗೆ ಲಿಂಕ್‌ಗಳನ್ನು ಸಹ ಒದಗಿಸುತ್ತದೆ ಎಂಬುದನ್ನು ದಯವಿಟ್ಟು ಗಮನಿಸಿ. ಸಚಿವಾಲಯಗಳು/ಇಲಾಖೆಗಳು/ಸಂಘಟನೆಗಳು. ಈ ವೆಬ್‌ಸೈಟ್‌ಗಳ ವಿಷಯವನ್ನು ಆಯಾ ಸಂಸ್ಥೆಗಳ ಒಡೆತನದಲ್ಲಿದೆ ಮತ್ತು ಹೆಚ್ಚಿನ ಮಾಹಿತಿ ಅಥವಾ ಸಲಹೆಗಾಗಿ ಅವುಗಳನ್ನು ಸಂಪರ್ಕಿಸಬಹುದು`,
  "ad0a802ec8134e9210a5625933db50a7": `ಹಕ್ಕು ನಿರಾಕರಣೆ`,
  "26dbd93cbe6f1e56b1577d8e3cb7fda3": `ಸೇವೆಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ`,
  "c6582b36b0f516684a873e8782517822": `ಯೋಜನೆಗಳು ಮತ್ತು ಸೇವೆಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ`,
  "b98287be44e1230cf144aa3af08ac82f": `ನೀವು ಇನ್ನೂ ಯಾವುದೇ ಯೋಜನೆಗಳಿಗೆ ದಾಖಲಾಗುವುದಿಲ್ಲ`,
  "449d3cf7dd09172024ebe93872410f0f": `ಅಪ್ಲಿಕೇಶನ್ ವೀಕ್ಷಿಸಿ`,
  "025d9b3a3c2a6a27d04c57c1d7cabc9f": `ಯಾವುದೇ ಕ್ರಮ ಅಗತ್ಯವಿಲ್ಲ`,
  "06df33001c1d7187fdd81ea1f5b277aa": `ಕಾರ್ಯಗಳು`,
  "adff901e227bf2b2200c4767fe773836": `ಕ್ರಿಯೆಗಳು ಅಗತ್ಯವಿದೆ`,
  "4ff84d2ee02fe6ef0ca2edc3cdecc0ed": `ಕ್ರಮಗಳು ಅಗತ್ಯವಿದೆ`,
  "c08c272bc5648735d560f0ba5114a256": `ಬೆಂಬಲ ಬೆಂಬಲ`,
  "fd2f820569d83784238e1027897b463e": `ನಿಮ್ಮ ಹೆಸರನ್ನು ನಮೂದಿಸಿ`,
  "cda83d00666a7bfa8cde6808de4ef994": `ಸಂಪರ್ಕ ಸಂಖ್ಯೆ`,
  "a4d3b161ce1309df1c4e25df28694b7b": `ಸಲ್ಲಿಸು`,
  "4c2a8fe7eaf24721cc7a9f0175115bd4": `ಸಂದೇಶ`,
  "68d390535e8a7120e3f6942d8a2214a0": `ನಿಮ್ಮ ಸಂದೇಶವನ್ನು ನಮೂದಿಸಿ`,
  "2f644b4a27e68f505c4f4ac2ffe3a8ac": `ದಯವಿಟ್ಟು ವರ್ಣಮಾಲೆಗಳು, ಸಂಖ್ಯೆಗಳು, ಅಲ್ಪವಿರಾಮ, -, _, /`,
  "58f7a43f2c3bd7854d99fbbbc3fa4ae9": `ವರ್ಣಮಾಲೆಗಳನ್ನು ಮಾತ್ರ ಅನುಮತಿಸಲಾಗಿದೆ.`,
  "4906d981bb592b32f6982fb4187c7b60": `ಮಾನ್ಯ ಐಎಫ್‌ಎಸ್‌ಸಿ ಕೋಡ್ ಅನ್ನು ನಮೂದಿಸಿ.`,
  "562d3d08919ae105c3ebc42d9607d266": `ದಯವಿಟ್ಟು ವರ್ಣಮಾಲೆಗಳು ಅಥವಾ ಸಂಖ್ಯೆಗಳನ್ನು ಮಾತ್ರ ನಮೂದಿಸಿ`,
  "fab2bb673b21bef95ee70b99dbd7f9fc": `ಕೇವಲ 11 ಅಕ್ಷರಗಳನ್ನು ಮಾತ್ರ ಅನುಮತಿಸಲಾಗಿದೆ.`,
  "a7eb433676475fee54727c14ad880b8c": `ಕೇವಲ 150 ಅಕ್ಷರಗಳನ್ನು ಮಾತ್ರ ಅನುಮತಿಸಲಾಗಿದೆ.`,
  "7c58cde3b827bc7d57cbf77865046169": `ದಯವಿಟ್ಟು ಸಂಖ್ಯೆಗಳನ್ನು ಮಾತ್ರ ನಮೂದಿಸಿ`,
  "544b0264d55a1e4cf476b279240d9be4": `ಕೇವಲ ಹತ್ತು ಅಂಕೆಗಳನ್ನು ಮಾತ್ರ ಅನುಮತಿಸಲಾಗಿದೆ. ದಯವಿಟ್ಟು 0 ಅಥವಾ +91 ಪೂರ್ವಪ್ರತ್ಯಯ ಮಾಡಬೇಡಿ`,
  "73f0d980e48b8141e0776e02d96a872f": `ಕೇವಲ ಹನ್ನೆರಡು ಅಂಕೆಗಳನ್ನು ಮಾತ್ರ ಅನುಮತಿಸಲಾಗಿದೆ.`,
  "0ce98bd973419ff33921a2275e7903b9": `ದಯವಿಟ್ಟು ಮಾನ್ಯ ಪ್ಯಾನ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ.`,
  "c6f91b305e91a0053748310de170a73c": `ಕೇವಲ ಆರು ಅಂಕೆಗಳನ್ನು ಮಾತ್ರ ಅನುಮತಿಸಲಾಗಿದೆ`,
  "9f44bd93c8988c682d5ef5369fd11f47": `ದಯವಿಟ್ಟು ಮಾನ್ಯ ಇಮೇಲ್ ನಮೂದಿಸಿ`,
  "10803b83a68db8f7e7a33e3b41e184d0": `ಹುಟ್ತಿದ ದಿನ`,
  "ac598199ea0dd028ade4677a435caf4b": `ಮಾನ್ಯ ಆಧಾರ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ`,
  "d0de720ccdffea8490dfd2893c50ebc5": `ಇದು {{displayName}} ಗಿಂತ ಹೆಚ್ಚಾಗಿರಬೇಕು`,
  "ced6e171e13ebd5c37415c822706b9d4": `ಇದು {{displayName}} ಗಿಂತ ಕಡಿಮೆಯಿರಬೇಕು`,
  "d1a1cb2bd14fdd9eec972f162cfd1ee0": `ಇದು {{displayName}} ಗೆ ಸಮನಾಗಿರಬೇಕು`,
  "9483135517d156229d0439dff74b1ca0": `ಇದು {{displayName}} ಗಿಂತ ಹೆಚ್ಚಾಗಿರಬೇಕು ಅಥವಾ ಸಮನಾಗಿರಬೇಕು`,
  "01e9cd748e852b1ae389b980bff195b0": `ಇದು {{displayName}} ಗಿಂತ ಕಡಿಮೆ ಅಥವಾ ಸಮನಾಗಿರಬೇಕು`,
  "dc236871b26ba387e1284445caa99df4": `ನಿಮಗೆ ಸೇವೆ ಸಲ್ಲಿಸಲು ಅವಕಾಶ ನೀಡಿದ್ದಕ್ಕಾಗಿ.`,
  "2102fff7bb4de45c9e616e3f01b6810f": `ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಸಲ್ಲಿಸಲಾಗಿದೆ.`,
  "dc9466f3d424b4acba2fc5c9f58d4f8a": `ಕ್ಷಮಿಸಿ, ನೀವು ಅರ್ಹರಲ್ಲ`,
  "a9198ed7d89e246a80078db84d2722e8": `ನಿಮ್ಮ ಫೋನ್ ಸಂಖ್ಯೆ ಅಥವಾ ಪಾಸ್‌ವರ್ಡ್ ಹೊಂದಿಕೆಯಾಗುವುದಿಲ್ಲ`,
  "8ac0a98c8c2f2c86396e34b6b5d63e38": `ಅದೇ ಹೆಸರಿನ ಪ್ರೊಫೈಲ್ ಈಗಾಗಲೇ ಅಸ್ತಿತ್ವದಲ್ಲಿದೆ`,
  "4ae112e33b3189c9fa04369b51259d76": `ಒಂದೇ ಹೆಸರು ಮತ್ತು ಸಂಬಂಧ ಹೊಂದಿರುವ ಪ್ರೊಫೈಲ್ ಈಗಾಗಲೇ ಅಸ್ತಿತ್ವದಲ್ಲಿದೆ`,
  "b0fb09a28a4a52a1b3ca2656dec0435e": `ಈ ಡಾಕ್ಯುಮೆಂಟ್ ಅನ್ನು ತೆಗೆದುಹಾಕಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?`,
  "e384b6a4760d80be609d897414cfe680": `ನೀವು ನೋಂದಾಯಿತ ಬಳಕೆದಾರರಲ್ಲ, ಮುಂದೆ ಮುಂದುವರಿಯಲು ನೀವು "ಸೈನ್ ಅಪ್" ಅಥವಾ "OTPಯೊಂದಿಗೆ ಲಾಗಿನ್" ಮಾಡಬಹುದು`,
  "c2cd85ffa55b2ec9b049b7eb131d3a9f": `ನಮೂದಿಸಿದ ಪಾಸ್‌ವರ್ಡ್‌ಗಳು ಹೊಂದಿಕೆಯಾಗಲಿಲ್ಲ. ಮತ್ತೆ ಪ್ರಯತ್ನಿಸು`,
  "3a816ee692e09b3cac6ef18e14071bd4": `ನಿಮ್ಮ ಪಾಸ್‌ವರ್ಡ್ ಯಶಸ್ವಿಯಾಗಿ ಹೊಂದಿಸಲ್ಪಟ್ಟಿದೆ`,
  "764d4847b734121ceb58e5c8195fa5b6": `ಸುಧಾರಿಸಲು ಹೊಸ ಅವಕಾಶಗಳನ್ನು ಕಂಡುಹಿಡಿಯಲು ಮತ್ತು ನಿಮ್ಮ ಪ್ರತಿಕ್ರಿಯೆ ನಿಮಗೆ ಉತ್ತಮ ಅಪ್ಲಿಕೇಶನ್ ಅನುಭವವನ್ನು ಹೊಂದಿದೆ ಎಂದು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು ನಿಮ್ಮ ಪ್ರತಿಕ್ರಿಯೆ ನಮಗೆ ಅನುಮತಿಸುತ್ತದೆ.`,
  "701d895fbc64569aa143ca6c44274139": `OTP ರಚಿಸಲು ನೀವು ಗರಿಷ್ಠ ಮಿತಿಯನ್ನು ಮೀರಿದ್ದೀರಿ, ದಯವಿಟ್ಟು ಸ್ವಲ್ಪ ಸಮಯದ ನಂತರ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ.`,
  "0bdfc4d562c9b0ccfaa4c0f6d4cac98f": `ನೀವು ಇನ್ನೂ ಯಾವುದೇ ಯೋಜನೆಗಳಿಗೆ ಅರ್ಜಿ ಸಲ್ಲಿಸಿಲ್ಲ.`,
  "4391bf5189e3e13c4aa4cb314c605ac8": `ನೀವು ಇನ್ನೂ ಯಾವುದೇ ಯೋಜನೆಗಳನ್ನು ಉಳಿಸಿಲ್ಲ.`,
  "92202504591d549b89340d26ffc0753a": `ಈ ಕೆಲಸವನ್ನು ನಿಮ್ಮ "ನನ್ನ ಉದ್ಯೋಗಗಳಲ್ಲಿ" ಉಳಿಸಲಾಗಿದೆ`,
  "af6ebe0c0e89201cb2294f32955f7b15": `ಒಂದು ಸಮಯದಲ್ಲಿ ಕೇವಲ 20 ದಾಖಲೆಗಳನ್ನು ಮಾತ್ರ ಅನುಮತಿಸಲಾಗಿದೆ.`,
  "b1481f771aee527fd910fa8d8672cd19": `ಈ ಅಧಿಸೂಚನೆಯನ್ನು ತೆಗೆದುಹಾಕಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?`,
  "06a6b009c19d5816574cdc7800d10eb5": `ನಿಮ್ಮ ವಿನಂತಿಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಹೆಚ್ಚಿಸಲಾಗಿದೆ.`,
  "b941bc7a9d4fe7a8c7c31ed440754285": `ಅಪ್ಲಿಕೇಶನ್ ಪರಿಶೀಲಿಸಿ`,
  "e56b98da835e8f4a018502b121520ac7": `ಉಳಿದಿರುವ ಪ್ರಯತ್ನಗಳು`,
  "416828262df288445e72ca1d3661f993": `ಎಚ್ಚರಿಕೆ - ಸತತ 4 ವಿಫಲ ಪ್ರಯತ್ನಗಳ ನಂತರ, ನಿಮ್ಮ ಖಾತೆಯನ್ನು ಲಾಕ್ ಮಾಡಲಾಗುತ್ತದೆ`,
  "9f978addf10226b03278e4932348ba98": `ಕೇವಲ 40 ಅಕ್ಷರಗಳನ್ನು ಮಾತ್ರ ಅನುಮತಿಸಲಾಗಿದೆ.`,
  "69f076c339373e00e9e841da965f4208": `ಆಧಾರ್ ಅನ್ನು ನವೀಕರಿಸಿ`,
  "48a82e0b2bd5818f110e55bb6f14aedc": `ನೀವು ಒದಗಿಸಿದ ಕೆಳಗಿನ ಮಾಹಿತಿಯು ಸರ್ಕಾರದ ದಾಖಲೆಗೆ ಹೊಂದಿಕೆಯಾಗಲಿಲ್ಲ`,
  "5c37ad5c0dd6e318af899b3c8fcbe886": `ಕ್ಷಮಿಸಿ, ನಿಮ್ಮ ಡೇಟಾವನ್ನು ಮೂಲ ವ್ಯವಸ್ಥೆಯಿಂದ ಪಡೆಯಲು ನಮಗೆ ಸಾಧ್ಯವಾಗುತ್ತಿಲ್ಲ. ನೀವು ಪರಿಶೀಲನೆಯಿಲ್ಲದೆ ಮುಂದುವರಿಯಬಹುದು ಅಥವಾ ನಂತರ ಪ್ರಯತ್ನಿಸಬಹುದು.`,
  "cdb2033fc9a30d6d6c1abe401cd1e37b": `ನಂತರ ಪ್ರಯತ್ನಿಸಿ`,
  "f8d855b26faffa0f8779fb44632d6bc1": `ಅಮಾನ್ಯ ಕ್ಯಾಪ್ಚಾ ಕೋಡ್`,
  "d53b4d564b9054db9b4b4a50e713197f": `ದಯವಿಟ್ಟು ಇಲ್ಲಿ ಕ್ಯಾಪ್ಚಾವನ್ನು ನಮೂದಿಸಿ`,
  "ed001fb3ff7eaca9bbfbaacddc9d5a72": `ಯಾವುದೇ ಇತಿಹಾಸ ಕಂಡುಬಂದಿಲ್ಲ`,
  "4934d9bc9ebd024d4106d5e1f093ae79": `ಸಂದರ್ಶಕರು ಎಣಿಸುತ್ತಾರೆ`,
  "f7ce7cb4b450acfe0b4cef36c3bdec2d": `ನಿಮ್ಮ ಬ್ಯಾಂಕ್ ಖಾತೆ ಪ್ರಸ್ತುತ ನಿಷ್ಕ್ರಿಯವಾಗಿದೆ. ನಿಮ್ಮ ಅಪ್ಲಿಕೇಶನ್ ಅನುಮೋದನೆ ಪಡೆದರೆ ನೀವು ಮತ್ತಷ್ಟು ಮುಂದುವರಿಯಬಹುದು ಆದರೆ ನಿಮ್ಮ ಬ್ಯಾಂಕ್ ಖಾತೆಯನ್ನು ಪ್ರಯೋಜನವನ್ನು ಪಡೆಯಲು ಬೇಗನೆ ಸಕ್ರಿಯಗೊಳಿಸಬಹುದು.`,
  "df9e5c88861b08c33b2d46de8d7037e9": `ನಿಮ್ಮ ಬ್ಯಾಂಕ್ ಖಾತೆಯನ್ನು ಪ್ರಸ್ತುತ ರದ್ದುಗೊಳಿಸಲಾಗಿದೆ. ನಿಮ್ಮ ಅಪ್ಲಿಕೇಶನ್ ಅನುಮೋದನೆ ಪಡೆದರೆ ನೀವು ಮತ್ತಷ್ಟು ಮುಂದುವರಿಯಬಹುದು ಆದರೆ ನಿಮ್ಮ ಬ್ಯಾಂಕ್ ಖಾತೆಯನ್ನು ಪ್ರಯೋಜನವನ್ನು ಪಡೆಯಲು ಬೇಗನೆ ಸಕ್ರಿಯಗೊಳಿಸಬಹುದು.`,
  "3fc95e40b475f7269e64f25a1c4aef3b": `ನಿಮ್ಮ ಬ್ಯಾಂಕ್ ಖಾತೆಯು ನಿಮ್ಮ ಆಧಾರ್‌ನೊಂದಿಗೆ ಸಂಬಂಧ ಹೊಂದಿಲ್ಲ. ನೀವು ಮತ್ತಷ್ಟು ಮುಂದುವರಿಯಬಹುದು ಆದರೆ ನಿಮ್ಮ ಅಪ್ಲಿಕೇಶನ್ ಅನುಮೋದನೆ ಪಡೆದರೆ ನಿಮ್ಮ ಬ್ಯಾಂಕ್ ಖಾತೆಯನ್ನು ನಿಮ್ಮ ಆಧಾರ್ನೊಂದಿಗೆ ಮೊದಲೇ ಲಿಂಕ್ ಮಾಡಿ.`,
  "1c35d003a65da48bf9f6d6fc69271d5a": `ಕ್ಷಮಿಸಿ, ಸಿಸ್ಟಮ್ ದೋಷದಿಂದಾಗಿ ನಿಮ್ಮ ಅಪ್ಲಿಕೇಶನ್ ಅನ್ನು ಸಲ್ಲಿಸಲಾಗುವುದಿಲ್ಲ. ದಯವಿಟ್ಟು ನಂತರ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ.`,
  "8e437cbd840bce5c5edc0ef8164de6b7": `ನಿಮ್ಮ ವಿವರಗಳನ್ನು ನೀವು ನವೀಕರಿಸಿದರೆ ಅಥವಾ ಬದಲಾಯಿಸಿದರೆ ನಿಮ್ಮ ಡೇಟಾವನ್ನು ಪರಿಶೀಲಿಸಲಾಗುವುದಿಲ್ಲ ಮತ್ತು ನವೀಕರಿಸಿದ ಡೇಟಾಕ್ಕಾಗಿ ಡಾಕ್ಯುಮೆಂಟ್ ಪ್ರೂಫ್ ಅನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಲು ನಿಮ್ಮನ್ನು ಕೇಳಲಾಗುತ್ತದೆ. ಮುಂದುವರಿಸಲು “ಮುಂದುವರಿಯಿರಿ” ಕ್ಲಿಕ್ ಮಾಡಿ.`,
  "b010e3be9f81072a9808ed4a5dd0e45b": `ಈ ಕೆಳಗಿನ ಮಾಹಿತಿಯು ಸಮೀಕ್ಷೆಯಲ್ಲಿ ನೀವು ಒದಗಿಸಿದ ವಿವರಗಳೊಂದಿಗೆ ಹೊಂದಿಕೆಯಾಗುವುದಿಲ್ಲ:`,
  "01acb0344944f11d68aeecc9f5b574ea": `ಪ್ರತಿಕ್ರಿಯೆ (ಗಳ) ನೊಂದಿಗೆ ಮುಂದುವರಿಯಲು ನೀವು ಬಯಸುವಿರಾ?`,
  "4ff70f7b2e5a74415de360150a6e59d3": `ನಿನ್ನ`,
  "bd7aaae9a27a2a4d71214c002bcf50ce": `ದಾಖಲೆಯೊಂದಿಗೆ ಹೊಂದಿಕೆಯಾಗಲಿಲ್ಲ.`,
  "ebfd339b0ac14aa79b325b53f06bd375": `ನೀವು ಒದಗಿಸಿದ ಮಾಹಿತಿಯು ನಿಮ್ಮ ಆಧಾರ್ ವಿವರಗಳೊಂದಿಗೆ ಹೊಂದಿಕೆಯಾಗಲಿಲ್ಲ. ನೀವು ಮುಂದೆ ಮುಂದುವರಿಯಲು ಸಾಧ್ಯವಿಲ್ಲ.`,
  "1e410e1b9903e7a99b93857f536b7e1d": `ಪರಿಶೀಲಿಸಿಲ್ಲ`,
  "28b266f2bf0a4ecc2dec3b6ca24fcb9b": `ಉರುಳಿಸು`,
  "68982db8041ee52dd9e5a6e527c8a1fa": `ಪುನಃ ಹೆಣೆದ`,
  "e7bf7d83a37666fd8749e433189b1c27": `ದಯಮಾಡಿ ನಿರೀಕ್ಷಿಸಿ`,
  "c389f9e7d5ea93af9deb2e8f1f9c6d89": `ಇನ್ನಷ್ಟು ತಿಳಿಯಲು {{"0x0001F6C8"}} about ಕ್ಲಿಕ್ ಮಾಡಿ.`,
  "f17816bcbe79e18bee49388fe84d2876": `OTP ಅವಧಿ ಮುಗಿದಿದೆ.`,
  "1531d9dc798857eb85345d349bffc99b": `ಹಿಂದೆ`,
  "7939de8a9958e01fb1f172e21c5a7a85": `ದಯವಿಟ್ಟು ಸರಿಯಾದ ಮಾಹಿತಿಯನ್ನು ಒದಗಿಸಿ, ನೀವು ಅರ್ಹವಾದ ಯೋಜನೆಗಳನ್ನು ನಿಮಗೆ ತೋರಿಸಲು ಇದು ನಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ.`,
  "bf58c4a9eac8e0ec82c158c3adfcd609": `ಯೋಜನೆಗಳನ್ನು ವಿವಿಧ ವರ್ಗಗಳ ಅಡಿಯಲ್ಲಿ ವರ್ಗೀಕರಿಸಲಾಗಿದೆ. ನಿಮ್ಮ ಆದ್ಯತೆಯ ವರ್ಗದ ಪ್ರಕಾರ ನೀವು ಯೋಜನೆಗಳನ್ನು ಪರಿಶೀಲಿಸಬಹುದು`,
  "09349e778786f7ed758021ddbed12ca6": `{Intent} ವರ್ಗದ ಅಡಿಯಲ್ಲಿ ಅರ್ಹ ಯೋಜನೆಗಳನ್ನು ಕಂಡುಹಿಡಿಯಲು ಈ ಮಾಹಿತಿಯು ನಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ.`,
  "0d45c019e7e9237a316f0f16824015d2": `ನಿಮ್ಮ ಪ್ರೊಫೈಲ್ ಡೇಟಾವನ್ನು ವಿಭಿನ್ನ ಟ್ಯಾಬ್‌ಗಳ ಅಡಿಯಲ್ಲಿ ಸಂಗ್ರಹಿಸಲಾಗಿದೆ. ನಿಮ್ಮ ಯಾವುದೇ ಪ್ರೊಫೈಲ್ ಮಾಹಿತಿಯನ್ನು ನೀವು ಸಂಪಾದಿಸಬಹುದು.`,
  "3f52884ec08606e6e73426eaec8de195": `ಈ ಯೋಜನೆಯಡಿ ನೀವು ಪಡೆಯಬಹುದಾದ ನಿಖರವಾದ ಲಾಭದ ಬಗ್ಗೆ ಮತ್ತು ಪ್ರಯೋಜನವನ್ನು ಪಡೆಯಲು ಅಪ್ಲಿಕೇಶನ್ ಪ್ರಕ್ರಿಯೆಯ ಬಗ್ಗೆ ವಿವರಗಳನ್ನು ಒದಗಿಸಲು ಈ ಮಾಹಿತಿಯು ನಮಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ.`,
  "c389f9e7d5ea93af9deb2e8f1f9c6d89": `ಇನ್ನಷ್ಟು ತಿಳಿಯಲು {{"0x0001F6C8"}} ಕ್ಲಿಕ್ ಮಾಡಿ.`,
  "5cc2bdab3db5aa21633656a0d55f7477": `ನೀವು ಅಪ್ಲಿಕೇಶನ್‌ನೊಂದಿಗೆ ಮುಂದುವರಿಯಲು ಸಾಧ್ಯವಿಲ್ಲ`,
  "d5de543c0e83073f05a846d62cc83c81": `ಅದೇ ಕ್ಷೇತ್ರಕ್ಕಾಗಿ ಈಗಾಗಲೇ ಅಪ್‌ಲೋಡ್ ಮಾಡಲಾಗಿದೆ.`,
  "fca8da28cbcbe094dc646872e7cdbc7b": `ಮಾನ್ಯವಾಗಿ ನಮೂದಿಸಿ`,
  "6fa2260c737f40fa1a3f63274c8fca48": `ನೀವು ಮುಂದೆ ಮುಂದುವರಿಯಲು ಸಾಧ್ಯವಿಲ್ಲ. ನಿಮ್ಮ ವಿವರಗಳನ್ನು ನೀವು ಪ್ರೊಫೈಲ್‌ನಲ್ಲಿ ಅಥವಾ ನಿಮ್ಮ ಆಧಾರ್ ಕಾರ್ಡ್‌ನಲ್ಲಿ ಸರಿಪಡಿಸಬಹುದು`,
  "3272e327ba9c82197c5c982487224925": `ಕ್ಷಮಿಸಿ, ನಿಮ್ಮ ಡೇಟಾವನ್ನು ಮೂಲ ವ್ಯವಸ್ಥೆಯಿಂದ ಪಡೆಯಲು ನಮಗೆ ಸಾಧ್ಯವಾಗುತ್ತಿಲ್ಲ. ದಯವಿಟ್ಟು ನಂತರ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ.`,
  "586637318049e6dbb03e991bc45cba06": `ನಿಮ್ಮ ಖಾತೆಯನ್ನು ಅಳಿಸಲು ನೀವು ಮೊದಲು ನಿಮ್ಮ ಖಾತೆಗೆ ಸಂಬಂಧಿಸಿದ ಎಲ್ಲಾ ಪ್ರೊಫೈಲ್‌ಗಳನ್ನು ಅಳಿಸಬೇಕು.`,
  "ce6d6092569f3f247659ceb51d57be95": `ನಿಮ್ಮ ಅನ್ವಯಿಕ ಅಥವಾ ದಾಖಲಾದ ಯೋಜನೆಗಳಲ್ಲಿ ನೀವು ನಡೆಯುತ್ತಿರುವ ಅಪ್ಲಿಕೇಶನ್ ಹೊಂದಿರುವುದರಿಂದ ನೀವು {{profileName}} ಪ್ರೊಫೈಲ್ ಅನ್ನು ಅಳಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ`,
  "8d258e398e2dbc8099c984258fadce2a": `ನಿಮ್ಮ ನೋಂದಾಯಿತ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಗೆ OTP ಕಳುಹಿಸಲಾಗಿದೆ (xxx xxx {{phoneNumber}})`,
  "abb2e3d13ded4cea9275beafacd9f4c8": `ಈ ಯೋಜನೆಯಲ್ಲಿ ನಿಮ್ಮ ಆಸಕ್ತಿಯನ್ನು ಹೆಚ್ಚಿಸಲು, "ನಿಮ್ಮ ಆಸಕ್ತಿಯನ್ನು ರಚಿಸಿ" ಕ್ಲಿಕ್ ಮಾಡಿ ಮತ್ತು ಈ ಯೋಜನೆಯ ಪ್ರಯೋಜನವನ್ನು ನೀಡಲು ಸರ್ಕಾರವು ನಿಮ್ಮನ್ನು ತಲುಪುತ್ತದೆ`,
  "493de8e0a6b5d4a10af30cf3105b9e1f": `ನಿಮ್ಮ ಆಸಕ್ತಿಯನ್ನು ಸೃಷ್ಟಿಸಿ`,
  "c9b0246a8f3989724ec4958f91e380a7": `ಯೋಜನೆ ವಿವರಗಳು`,
  "804ca01b219522a88d26c3002632a00e": `ವಿವರಗಳನ್ನು ಹಂಚಿಕೊಂಡಿದ್ದಕ್ಕಾಗಿ ಧನ್ಯವಾದಗಳು.`,
  "04b6d9b7eb73749476d958b2133f4bbe": `ಸ್ಕೀಮ್ ಅವಾರ್ನೆಸ್ ಸಮೀಕ್ಷೆ`,
  "a5661f68fc78a5d4f15afa057fec7a9f": `ಮತ್ತಷ್ಟು ಮುಂದುವರಿಯಲು ನೀವು ಸಮೀಕ್ಷೆಯಲ್ಲಿ ನಿಮ್ಮ ವಿವರಗಳನ್ನು ನವೀಕರಿಸಬೇಕಾಗಿದೆ.`,
  "1e4531d1dc128abbc00b48e5c7b6ef48": `ಸಮೀಕ್ಷೆಯನ್ನು ನವೀಕರಿಸಿ`,
  "362e532b6ad1fda489c5909d437f02a3": `ನಾವು ನಿಮ್ಮ ವಿವರಗಳನ್ನು ಪಡೆಯುತ್ತಿದ್ದೇವೆ, ದಯವಿಟ್ಟು ನಿರೀಕ್ಷಿಸಿ ..`,
  "8a1b3c7d62e2d60d5be34d8e36a9768f": `ವೈಯಕ್ತಿಕ ಲಾಗಿನ್‌ಗೆ ಹಿಂತಿರುಗಿ`,
  "6737d50489b1cf1d4408e66b8591ca38": `ನಿಮ್ಮ ಸ್ಕೀಮ್ ಅಪ್ಲಿಕೇಶನ್‌ನಲ್ಲಿ ನೀವು ಬಾಕಿ ಉಳಿದಿರುವ ಕ್ರಮಗಳನ್ನು ಹೊಂದಿದ್ದೀರಿ. ನಿಮ್ಮ ಅಪ್ಲಿಕೇಶನ್‌ನೊಂದಿಗೆ ಮುಂದುವರಿಯಲು ಬಾಕಿ ಇರುವ ಕ್ರಮಗಳನ್ನು ಪೂರ್ಣಗೊಳಿಸಿ.`,
  "e37c99137b7396277137c1f73f8a86d5": `ಸಿಇಜಿಯ ಉದ್ದೇಶ`,
  "706c7a936b977ec3c4e6603f888e3fce": `EasyGov ನಿಂದ ನಡೆಸಲ್ಪಡುತ್ತಿದೆ`,
  "598d42810b342196884f255912974d69": `ಕ್ರಮ ಸಂಖ್ಯೆ`,
  "60e4e81c6f9c1938d77e8ded6d64643a": `ಪರದೆ ಓದುಗ`,
  "15bbb9d0bbf25e8d2978de1168c749dc": `ಜಾಲತಾಣ`,
  "21d587949a2a2e89c958b622e244b405": `ಉಚಿತ/ ವಾಣಿಜ್ಯ`,
  "d5c03f7b855d442a2a7f24024cf89960": `ವಿಷುಯಲ್ ಡೆಸ್ಕ್‌ಟಾಪ್ ಪ್ರವೇಶ (ಎನ್‌ವಿಡಿಎ)`,
  "f956e16f65a3a937e5ecfe4195d17d0d": `(ಹೊಸ ವಿಂಡೋದಲ್ಲಿ ತೆರೆಯುವ ಬಾಹ್ಯ ವೆಬ್‌ಸೈಟ್)`,
  "b24ce0cd392a5b0b8dedc66c25213594": `ಸ್ವಾರಸ್ಯವಾದ`,
  "c8ed8ec3dc8b21290b5d28ca56344553": `ದವಡೆಗಳು`,
  "b2788e1ab6be65f1c38bf7d23cd081f1": `ವಾಣಿಜ್ಯ`,
  "ec18aa8148f59475130ff217d693b465": `ಕಿಟಕಿ-ಕಣ್ಣುಗಳು`,
  "d96a273ceb2b5f83d710081d9c38440a": `ಹೋಗಲು ಸಿಸ್ಟಮ್ ಪ್ರವೇಶ`,
  "c932fd98147348de9974252a0efcc7cb": `WebAnywhere`,
  "540431e2b3234a8698b4044f15ef1fff": `ಪಿಡಿಎಫ್ ವಿಷಯ`,
  "801ab24683a4a8c433c6eb40c48bcd9d": `ಡೌನ್‌ಲೋಡ್`,
  "83f8a38f024082405d2fff921c2bb1d2": `ಅಡೋಬ್ ಅಕ್ರೋಬ್ಯಾಟ್ ರೀಡರ್`,
  "9d8c84ff52cfc2c2e0075e669edacffd": `ಪದ ಫೈಲ್‌ಗಳು`,
  "4db1a171aaf96723d4002cc41559ddf7": `ಹೊಸ ವಿಂಡೋದಲ್ಲಿ ತೆರೆಯುವ ಬಾಹ್ಯ ವೆಬ್‌ಸೈಟ್`,
  "86c61b344697039b8a54bbef9e4959c3": `ಎಕ್ಸೆಲ್ ವೀಕ್ಷಕ 2003 (2003 ರವರೆಗೆ ಯಾವುದೇ ಆವೃತ್ತಿಯಲ್ಲಿ)`,
  "04efb134468330da1430e32701160107": `ಎಕ್ಸೆಲ್ ಗಾಗಿ ಮೈಕ್ರೋಸಾಫ್ಟ್ ಆಫೀಸ್ ಹೊಂದಾಣಿಕೆ ಪ್ಯಾಕ್ (2007 ಆವೃತ್ತಿಗೆ)`,
  "a385a9bb61bb694694eef5d2f54c563a": `ಪವರ್ಪಾಯಿಂಟ್ ಪ್ರಸ್ತುತಿಗಳು`,
  "da67d56a74c01537a71a3ab0eda00faa": `ಪವರ್ಪಾಯಿಂಟ್ಗಾಗಿ ಮೈಕ್ರೋಸಾಫ್ಟ್ ಆಫೀಸ್ ಹೊಂದಾಣಿಕೆ ಪ್ಯಾಕ್ (2007 ಆವೃತ್ತಿಗೆ)`,
  "bb625547a85b98c2c9f9f4121c72cd78": `ಫ್ಲ್ಯಾಶ್ ಅಂಶ`,
  "ab69edd2798068928605717c6591462f": `ಅಡೋಬ್ ಫ್ಲ್ಯಾಶ್ ಪ್ಲೇಯರ್`,
  "2c4fa0a307152ef814bbab8d4075d938": `ಆಡಿಯೊ ಫೈಲ್‌ಗಳು`,
  "da7be99fd6fac5c8a4065eb2c07cad88": `ವಿಂಡೋಸ್ ಮೀಡಿಯಾ ಪ್ಲೇಯರ್`,
  "5a5652ca26ee42fe73fdd79497cf9e21": `ಪದ ವೀಕ್ಷಕ (2003 ರವರೆಗೆ ಯಾವುದೇ ಆವೃತ್ತಿಯಲ್ಲಿ)`,
  "2e856241d3d995fefdb1b4b32f84a79b": `ವರ್ಡ್‌ಗಾಗಿ ಮೈಕ್ರೋಸಾಫ್ಟ್ ಆಫೀಸ್ ಹೊಂದಾಣಿಕೆ ಪ್ಯಾಕ್ (2007 ಆವೃತ್ತಿಗೆ)`,
  "64a443242750513eb56695e07aedcff2": `ಎಕ್ಸೆಲ್ ಫೈಲ್‌ಗಳು`,
  "382b0f5185773fa0f67a8ed8056c7759": `ಎನ್ / ಎ`,
  "462c18d5b89050fb1b7f8fca1e535af8": `ಸಂಸ್ಕರಿಸಿದ`,
  "ccb26ac32c3cc7cc2f0fd1bbac3b8e2d": `ಸಂಸ್ಕರಿಸಿದ ಯಾವುದೇ ಸೇವೆಗಳನ್ನು ನೀವು ಹೊಂದಿಲ್ಲ`,
  "fc44a0efc672761745c2e76aac6851d2": `ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ತಿರಸ್ಕರಿಸಲಾಗಿದೆ ಏಕೆಂದರೆ`,
  "7d0db380a5b95a8ba1da0bca241abda1": `ಬಳಿಗೆ`,
  "1252044838fd74c4e8b506ef1302a8b5": `ಗದ್ದಲ`,
  "06627e6919d21853d887d00334ee5337": `ಕುಂದುಕೊರತೆ ನಿರ್ವಹಣೆ`,
  "f940fbe4aec76e9784e7f15c4b92ac6d": `ಸಂಸ್ಕರಿಸಿದಲ್ಲಿ ನಿಮಗೆ ಯಾವುದೇ ಕುಂದುಕೊರತೆ ಇಲ್ಲ`,
  "2290dc1500a2b4548eb3b931f2e3609a": `ನಿಮಗೆ ಯಾವುದೇ ಉಳಿಸಿದ ಕುಂದುಕೊರತೆ ಇಲ್ಲ.`,
  "b25b60dc1a5f7c17e09bc73174668d9a": `ಡ್ರಾಫ್ಟ್‌ನಲ್ಲಿ ನಿಮಗೆ ಯಾವುದೇ ಕುಂದುಕೊರತೆ ಇಲ್ಲ.`,
  "a02c58d421087ec9519620a29d68a6ec": `ನೀವು ಇನ್ನೂ ಯಾವುದೇ ಕುಂದುಕೊರತೆಗಾಗಿ ಸಲ್ಲಿಸಿಲ್ಲ.`,
  "b65f636dcbc018b1901e23a7c2adde90": `ಇನ್ನಷ್ಟು ಅನ್ವೇಷಿಸಿ`,
  "5c835c22429f25992b4371691ecde1a4": `ಸಲ್ಲಿಸಿದ`,
  "2535f5e4e0892c73a5fad023bf3e15e2": `ನನ್ನ ಕುಂದುಕೊರತೆಗಳು`,
  "4351cfebe4b61d8aa5efa1d020710005": `ನೋಟ`,
  "e9516387730903ffd7cfe3317e05e385": `ಈ ಕುಂದುಕೊರತೆಯನ್ನು ನಿಮ್ಮ "ನನ್ನ ಕುಂದುಕೊರತೆ" ಯಲ್ಲಿ ಉಳಿಸಲಾಗಿದೆ.`,
  "b70db24f2cb70fb266eb03da8f289ba5": `ಕುಂದುಕೊರತೆಗಳನ್ನು ವೀಕ್ಷಿಸಿ`,
  "646558064e10a12b1e8914e86eca3ca6": `ನಿಮ್ಮ ಸೇವಾ ಅಪ್ಲಿಕೇಶನ್‌ನಲ್ಲಿ ನೀವು ಬಾಕಿ ಉಳಿದಿರುವ ಕ್ರಮಗಳನ್ನು ಹೊಂದಿದ್ದೀರಿ. ನಿಮ್ಮ ಅಪ್ಲಿಕೇಶನ್‌ನೊಂದಿಗೆ ಮುಂದುವರಿಯಲು ಬಾಕಿ ಇರುವ ಕ್ರಮಗಳನ್ನು ಪೂರ್ಣಗೊಳಿಸಿ.`,
  "d2841171ea05a48fa5463b3f7c7c92d7": `ನಿಮ್ಮ ಕುಂದುಕೊರತೆ ಅಪ್ಲಿಕೇಶನ್‌ನಲ್ಲಿ ನೀವು ಬಾಕಿ ಉಳಿದಿರುವ ಕ್ರಮಗಳನ್ನು ಹೊಂದಿದ್ದೀರಿ. ನಿಮ್ಮ ಅಪ್ಲಿಕೇಶನ್‌ನೊಂದಿಗೆ ಮುಂದುವರಿಯಲು ಬಾಕಿ ಇರುವ ಕ್ರಮಗಳನ್ನು ಪೂರ್ಣಗೊಳಿಸಿ.`,
  "9279f823b01f13b7a8c04688289543cc": `ನಿಮ್ಮ ಅಪ್ಲಿಕೇಶನ್ ನಿಮ್ಮ 'ಕರಡು ಕುಂದುಕೊರತೆ'ಯಲ್ಲಿ ಯಶಸ್ವಿಯಾಗಿ ಉಳಿಸಲಾಗಿದೆ.`,
  "af481c93dcae73d73afe9927076c8a09": `ಈ ಕುಂದುಕೊರತೆಗಳಿಗಾಗಿ ನೀವು ಈಗಾಗಲೇ ಅರ್ಜಿ ಸಲ್ಲಿಸಿದ್ದೀರಿ. ನಿಮ್ಮ ಅನ್ವಯಿಕ ಕುಂದುಕೊರತೆಗಳಿಂದ ದಯವಿಟ್ಟು ನಿಮ್ಮ ಅಪ್ಲಿಕೇಶನ್ ಸ್ಥಿತಿಯನ್ನು ಪರಿಶೀಲಿಸಿ.`,
  "ce972231a0faf31791c7ac0bf0d1da84": `ನೀವು ಯಾವುದನ್ನಾದರೂ ಪಾವತಿಸಿದ್ದರೆ ನಿಮ್ಮ ಮೊತ್ತವನ್ನು ಮರುಪಾವತಿಸಲಾಗುವುದಿಲ್ಲ.`,
  "2ffef02ca513f8bbadbaf130a23097de": `ನಿಮ್ಮ "ಕರಡು ಕುಂದುಕೊರತೆಗಳಲ್ಲಿ" ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಉಳಿಸಲಾಗಿದೆ`,
  "e55e425dbaf6b6449940eac8ea749092": `ದಯವಿಟ್ಟು ವರ್ಣಮಾಲೆಗಳನ್ನು ಮಾತ್ರ ನಮೂದಿಸಿ.`,
  "64ff9e3809686d4ea3253a631cae4761": `ದಯವಿಟ್ಟು 2 ದಶಮಾಂಶ ಸ್ಥಳಗಳಿಗೆ ಸರಿಯಾದ ಮೌಲ್ಯವನ್ನು ನಮೂದಿಸಿ.`,
  "7ed0ef23d527c2876015161e422f49ce": `ದಯವಿಟ್ಟು ನಿಮ್ಮ ಹೆಸರನ್ನು ಕನ್ನಡ ಭಾಷೆಯಲ್ಲಿ ನಮೂದಿಸಿ.`,
  "60ae728544ab5cb25dba9ff4b8f6aa95": `ಸೆವಾ ಸಿಂಧುಗಾಗಿ ನಿಮ್ಮ ಅಪ್ಲಿಕೇಶನ್ ಉಲ್ಲೇಖ ಸಂಖ್ಯೆ`,
  "2c0ed1f2f9239dbca46beca549190e00": `ಆಧಾರ್ ಅನ್ನು ವಿಳಾಸದ ಪುರಾವೆಯಾಗಿ ತೆಗೆದುಕೊಳ್ಳಲಾಗುವುದು, ದಯವಿಟ್ಟು ಆಧಾರ್ ಇರುವಂತೆ ಜಿಲ್ಲೆಯನ್ನು ಆಯ್ಕೆ ಮಾಡಿ.`,
  "8355a53d777c5ac84a37970b5158aba5": `ದೋಷ 205: ಆಧಾರ್‌ನಲ್ಲಿ ನಿಮ್ಮ ಹೆಸರು ಕಂದಾಯ ಇಲಾಖೆ ನೀಡಿದ ಜಾತಿ ಅಥವಾ ಆದಾಯ ಪ್ರಮಾಣಪತ್ರದಲ್ಲಿನ ವಿವರಗಳೊಂದಿಗೆ ಹೊಂದಿಕೆಯಾಗುವುದಿಲ್ಲ.`,
  "601f77894e71ae7d4922eda713dda66c": `ದೋಷ 207: ನಿಮ್ಮ ಜಾತಿ ಮತ್ತು ಆದಾಯ ಪ್ರಮಾಣಪತ್ರದ ಅವಧಿ ಮುಗಿದಿದೆ.`,
  "9182b3e8dc7965f2775a204095f4c992": `ನೀವು ನಮೂದಿಸಿದ OTP ತಪ್ಪಾಗಿದೆ. ದಯವಿಟ್ಟು ಸರಿಯಾದ OTP ಯೊಂದಿಗೆ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ.`,
  "6e0fe1003e84e34da97a6c4c9040b31b": `ಹಿಂದುಳಿದ ವರ್ಗಗಳ ಕಲ್ಯಾಣ ಇಲಾಖೆಯ ಅರಿವು ಶೈಕ್ಷಣಿಕ ಸಾಲ ಯೋಜನೆ (ನವೀಕರಣ) ಗಾಗಿ ಅರ್ಜಿಯನ್ನು ಸ್ವೀಕರಿಸುವ ಕೊನೆಯ ದಿನಾಂಕವನ್ನು ಮಾರ್ಚ್ 31, 2022 ಕ್ಕೆ ವಿಸ್ತರಿಸಲಾಗಿದೆ.`,
  "69d3a9de2fc1407f59e8936ca0c525a0": `ಕ್ಷಮಿಸಿ, ದೋಷ ಸಂಭವಿಸಿದೆ. ದಯವಿಟ್ಟು ನಂತರ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ.`,
  "7953c16d691da03c899391a39412fc9b": `ನಿಮ್ಮ ಸ್ಕೀಮ್ ಅಪ್ಲಿಕೇಶನ್‌ನಲ್ಲಿ ನೀವು ಬಾಕಿ ಉಳಿದಿರುವ ಕ್ರಮಗಳನ್ನು ಹೊಂದಿದ್ದೀರಿ. ನಿಮ್ಮ ಅಪ್ಲಿಕೇಶನ್‌ನೊಂದಿಗೆ ಮತ್ತಷ್ಟು ಮುಂದುವರಿಯಲು <i> {{startDate}} </i> ನಿಂದ ಬಾಕಿ ಇರುವ ಕ್ರಿಯೆಗಳನ್ನು ಪೂರ್ಣಗೊಳಿಸಿ.`,
  "f4f4366332ac6949882de59c81b08de9": `ನಿಮ್ಮ ಸ್ಕೀಮ್ ಅಪ್ಲಿಕೇಶನ್‌ನಲ್ಲಿ ನೀವು ಬಾಕಿ ಉಳಿದಿರುವ ಕ್ರಮಗಳನ್ನು ಹೊಂದಿದ್ದೀರಿ. <i> {{startDate}} </i> ನಿಂದ ಬಾಕಿ ಇರುವ ಕ್ರಿಯೆಗಳನ್ನು ಪೂರ್ಣಗೊಳಿಸಿ <i> {{enddate}} </i> ನಿಮ್ಮ ಅಪ್ಲಿಕೇಶನ್‌ನೊಂದಿಗೆ ಮತ್ತಷ್ಟು ಮುಂದುವರಿಯಲು.`,
  "77b43c24220ee608380031b72f8017ce":`ಸೇವಾ ವರ್ಗಗಳು`,
  "e0e54c374f8b39a1520766e5924af100": `ನಿಮ್ಮ ಕುಟುಂಬ ಮತ್ತು ನಿಮಗಾಗಿ ಯೋಜನೆಗಳು, ಸೇವೆಗಳು ಮತ್ತು ಜೀವನೋಪಾಯವನ್ನು ತಿಳಿದುಕೊಳ್ಳಲು ಒಂದು ವೇದಿಕೆ`,
  "d56f6b073f573237a6c4aed91589e982": `ಜೀವನೋಪಾಯ`,
  "e5930fadbc620e368c92561f381ead58": `ನಿಮಗಾಗಿ ಜೀವನೋಪಾಯದ ಅವಕಾಶಗಳು`,
  "192adf39ebd850e3e2e30971e885dc6d": `ಈ ಸಮಯದಲ್ಲಿ ನಿಮ್ಮ ರಾಜ್ಯದಲ್ಲಿ ಯಾವುದೇ ಜೀವನೋಪಾಯದ ಅವಕಾಶಗಳನ್ನು ಕಾನ್ಫಿಗರ್ ಮಾಡಲಾಗಿಲ್ಲ, ನಾವು ನಿರಂತರವಾಗಿ ನವೀಕರಿಸುತ್ತಿದ್ದೇವೆ, ದಯವಿಟ್ಟು ನಮ್ಮೊಂದಿಗೆ ಮತ್ತೊಮ್ಮೆ ಪರಿಶೀಲಿಸಿ`,
  "TM_Alt51": "ಲಗತ್ತು(ಗಳು)",
  "TM_Hea1": "ಒಟ್ಟು ಮೊತ್ತ",
  "TM_Hea2": "ಸಾರಾಂಶ",
  "TM_Sub56": "ಒಟ್ಟು",
  "TM_But42": "ಪಾವತಿ",
  "TM_Mod41":"ಪಾವತಿ ಬಾಕಿ ಇದೆ ",
  "TM_Mod40":"ಪಾವತಿ ಯಶಸ್ವಿಯಾಗಿದೆ",
  "TM_Alt72":"ಪಾವತಿ ವಿಫಲವಾಗಿದೆ",
  "TM_Pag48":"ಸಹಾಯ ಮತ್ತು ಬೆಂಬಲ",
  "TM_Con33":"ಧನ್ಯವಾದಗಳು, ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಸಲ್ಲಿಸಲಾಗಿದೆ.",
  "TM_Err34": "ಪಾವತಿ ವಿಫಲವಾದ ಕಾರಣ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಸಲ್ಲಿಸಲಾಗಲಿಲ್ಲ.",
  "TM_Rec55": "ನಿಮ್ಮ ಪಾವತಿಯ ಸ್ಥಿತಿಯನ್ನು ಖಚಿತಪಡಿಸಲು ಇದು ಸಮಯ ತೆಗೆದುಕೊಳ್ಳುತ್ತಿದೆ. ನಾವು ನಿಮ್ಮ ಬ್ಯಾಂಕ್‌ನೊಂದಿಗೆ ನಿರಂತರವಾಗಿ ಪರಿಶೀಲಿಸುತ್ತಿದ್ದೇವೆ. ದಯವಿಟ್ಟು ಮುಂದಿನ 48 ಗಂಟೆಗಳವರೆಗೆ ಯಾವುದೇ ಹೆಚ್ಚುವರಿ ಪಾವತಿಗಳನ್ನು ಮಾಡುವುದನ್ನು ತಪ್ಪಿಸಿ.",
  "TM_But13": "ಹಿಂದೆ ಹೋಗು",
  "TM_Alt124": "ದಯಮಾಡಿ ನಿರೀಕ್ಷಿಸಿ",
  "TM_Rec58": "ಹೋಲ್ಡ್ ಮಾಡಿ.ನಾವು ನಿಮ್ಮ ಪಾವತಿ ಸ್ಥಿತಿಯನ್ನು ಪರಿಶೀಲಿಸುತ್ತಿದ್ದೇವೆ",
  "TM_Rec59":"ಈ ಸೇವೆಗಾಗಿ ನಿಮ್ಮ ಕೊನೆಯ ಪಾವತಿಯು ಬಾಕಿ ಉಳಿದಿದೆ. ಅದು ಪೂರ್ಣಗೊಂಡಾಗ ನಾವು ನಿಮಗೆ ಸೂಚಿಸುತ್ತೇವೆ. ಈ ಮಧ್ಯೆ, ನಮ್ಮ ಇತರ ಸೇವೆಗಳನ್ನು ಅನ್ವೇಷಿಸಲು ಮುಕ್ತವಾಗಿರಿ.",
  "TM_Rec60":"ನಿಮ್ಮ ಸಹನೆಗೆ ಧನ್ಯವಾದಗಳು.",
  "TM_Rec61":"ಪುಟವು ಸ್ವಯಂಚಾಲಿತವಾಗಿ ಸಮಯ ಮೀರುತ್ತದೆ",
  "TM_Alt165":"ಪಾವತಿ ಮಾಡಲು ನಿಮ್ಮ ಮೊಬೈಲ್ ಸಾಧನದ ಯುಪಿಐ ಅಪ್ಲಿಕೇಶನ್‌ನಲ್ಲಿ ಕ್ಯೂಆರ್ ಕೋಡ್ ಅನ್ನು ಸ್ಕ್ಯಾನ್ ಮಾಡಿ",
  "TM_Pag39": "ಲಾಭರ್ತಿ",
  "TM_Alt166":"ಪಾವತಿಯನ್ನು ತಿರಸ್ಕರಿಸಲಾಗಿದೆ",
  "TM_Err39":"ಪಾವತಿಯನ್ನು ತಿರಸ್ಕರಿಸಿದ ಕಾರಣ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಸಲ್ಲಿಸಲಾಗಲಿಲ್ಲ.",
  "cc4cbfe72c596683881033f36eac3e2f":"ನಿಮ್ಮ ಬ್ಯಾಂಕ್‌ನೊಂದಿಗೆ ನಿಮ್ಮ ಪಾವತಿಯನ್ನು ನಾವು ಖಚಿತಪಡಿಸುವವರೆಗೆ ದಯವಿಟ್ಟು ನಿರೀಕ್ಷಿಸಿ. ಈ ಪ್ರಕ್ರಿಯೆಯು ಸ್ವಲ್ಪ ಸಮಯ ತೆಗೆದುಕೊಳ್ಳಬಹುದು.",
  "7d54c690a3a707e692cceb6978e24bc1": "ಶುಲ್ಕ ವಿರಾಮ",
}
export default language;