import React from 'react';
import {Container, Row, Col} from "react-bootstrap";



function disableMobileView() {
  return (
    <Container className="mobile-display padding-0 background">
      <Row className="px-0 mx-0">

        <Col xs={12} className="text-center">
          <img
            src={process.env.PUBLIC_URL +"/img/disableMobileView/failureMobile.png"}
            className="mt-10"
          />
        </Col>
        <Col xs={12} className=" ">
          <p class="text-center"><b>Oops</b></p>
        </Col>
        <Col xs={12} className="text-center">
          <p class="text-center">This portal is available only on web!</p>
        </Col>

      </Row>


    </Container>
  );
}

export default disableMobileView;


