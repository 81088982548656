const initState = {
    data: undefined,
  };
  
  const saveDocsForUserReducer = (state = initState, action) => {
    switch (action.type) {
      case "SAVE_DOCS_FOR_USER":
        return {
          ...state,
          data: action.payload,
        };
  
      case "RESET_SAVE_DOCS_FOR_USER":
        return {
          ...state,
          data: undefined,
        };
  
      default:
        return state;
    }
  };
  
  export default saveDocsForUserReducer;
  