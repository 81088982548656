const language = {
    "9e66e6bd4069124578736528a0432752": `எங்களை பற்றி`,
    "0f5590b58efe9cfcce35e55a66280e59": `ஏற்றுக்கொண்டு தொடரவும்`,
    "77ab8893d0d282a9f2781d5711883903": `கூட்டு`,
    "c40eba17959a63e52412cac8c38d467e": `சுயவிவரத்தைச் சேர்க்கவும்`,
    "ac714b256ab565c91ade9e03e8c410f0": `உங்கள் தரவு அனைத்தும் நீக்கப்பட்டுள்ளன`,
    "b31b443baac1c6da4cb78dfaf2e5be44": `பயன்பாட்டு இணைப்பு`,
    "0ec157e0e15e12c56bfe1c72e0e7515a": `மாற்றங்களைப் பயன்படுத்துங்கள்`,
    "181c1a0ee6db7d7f835eed29791749ab": `இந்த திட்டத்தை நீக்க விரும்புகிறீர்களா?`,
    "d937652cde254e2a9b93b74a4ee2a02b": `{{name}} சுயவிவரத்தை நிரந்தரமாக நீக்க விரும்புகிறீர்களா?`,
    "3ac1df709254c0e491c642aae5400814": `{{name}} கணக்கை நிரந்தரமாக நீக்க விரும்புகிறீர்களா?`,
    "1cbb32715ee74ee3447f48febcc04864": `நீங்கள் சேமித்த சேவைகளிலிருந்து இந்த சேவையை நீக்க விரும்புகிறீர்களா?`,
    "ea4788705e6873b424c65e91c2846b19": `ரத்துசெய்`,
    "f4ec5f57bd4d31b803312d873be40da9": `மாற்றம்`,
    "6dd495f1c2ae29c8e9a6c4405f18e22e": `இருப்பிடத்தை மாற்றவும்`,
    "b1ad1d3aef325b23950a43cb82fd0670": `மொழியைத் தேர்வுசெய்க`,
    "714341d9339756588d1dc724991c2b9a": `இங்கே கிளிக் செய்க`,
    "f72b489f19f748cf78a86c78d547ed91": `உங்கள் இருப்பிடத்தை உறுதிப்படுத்தவும்`,
    "a0bfb8e59e6c13fc8d990781f77694fe": `தொடரவும்`,
    "bee04ef1315b3f9562be34e2e28a7831": `கணக்கை நீக்குக`,
    "3e9983cf1885a5ec9f5a5d8127137bd2": `சுயவிவரத்தை நீக்கு`,
    "2ac7a924af94bf173f38eb31165efce3": `திட்டத்தை நீக்கு`,
    "e0bb2f66e5b61be3235bf8969979de19": `மாவட்டம்`,
    "46a43a7757337712d4f6a3701a5c57dd": `படிவத்தைப் பதிவிறக்கவும்`,
    "4d7be8ac1b9cb2fd5843dbea7ce30344": `நீங்கள் இதுவரை எந்த சேவையையும் சேமிக்கவில்லை`,
    "edda2208040fe25b06d0d8f3af976651": `நீங்கள் எந்த வேலைகளையும் சேமிக்கவில்லை`,
    "f283a3e62b2809c07cf77075103c012f": `உங்களுக்காக மிகவும் பொருத்தமான அரசாங்க நலத் திட்டங்கள், சேவைகள் மற்றும் வேலைகளைக் கண்டுபிடிப்பதற்கு எங்களுக்கு சில அடிப்படை சுயவிவர தகவல்கள் தேவை.`,
    // "f283a3e62b2809c07cf77075103c012f":`மிகவும் பொருத்தமான அரசாங்க நலத் திட்டங்களைக் கண்டுபிடிப்பதற்கு லாபார்த்திக்கு சில அடிப்படை சுயவிவர தகவல்கள் தேவை`,
    "ce8ae9da5b7cd6c3df2929543a9af92d": `மின்னஞ்சல்`,
    "01e8b0c4e059b594202e976860b2d481": `உங்கள் சொந்த மொழியில் திட்டங்கள் மற்றும் சேவைகளை ஆராயுங்கள்`,
    "483a5da192f46e3cfe8391d3ef234fc4": `கேள்விகள்`,
    "37aaf54433bbfe989db8871121fe0ad8": `எங்களை பின்தொடரவும்:`,
    "e0c4332e8c13be976552a059f106354f": `தொடங்கவும்`,
    "48dee7c291ac7f19e499438b072da336": `அரசாங்க கட்டணம்`,
    "2ceb32552d78024d91a123897636afcb": `Easygov குடிமக்கள் அதிகாரமளிக்கும் பயன்பாடு`,
    //"357aa540fd5c8ae584ba18359105de3d":`ஏதேனும் திட்டங்கள் அல்லது வேறு ஏதாவது பற்றி உங்களுக்கு ஏதேனும் கேள்வி இருக்கிறதா?`,
    "357aa540fd5c8ae584ba18359105de3d": `உங்களிடம் ஏதேனும் வினவல் இருந்தால் எங்களுக்குத் தெரியப்படுத்துங்கள், மேலும் 24 வணிக நேரங்களுக்குள் நாங்கள் உங்களைத் திரும்பப் பெறுவோம்.`,
    "b3ba00d60ff5a9e11c15a6efbed13746": `உங்கள் தற்போதைய சுயவிவரத்தைப் புதுப்பிக்க “தற்போதைய சுயவிவரத்தைப் புதுப்பிக்கவும்” என்பதைக் கிளிக் செய்து புதிய சுயவிவரத்தைச் சேர்க்க “சுயவிவரத்தைச் சேர்” என்பதைத் தேர்ந்தெடுக்கவும்`,
    "12ceff2290bb9039beaa8f36d5dec226": `வேலைகள்`,
    "5eb57a6678ca337f92884f49b578a908": `...`,
    "4994a8ffeba4ac3140beb89e8d41f174": `மொழி`,
    "846eb834b6bfe81dbd412bc010f8cac7": `எங்களுக்குத் தெரியப்படுத்துங்கள், நாங்கள் 24 வணிக நேரங்களுக்குள் உங்களைத் திரும்பப் பெறுவோம்`,
    "a58252e21aaf876bf7b64c05cce1c477": `இணைப்பு நகலெடுக்கப்பட்டது`,
    "ce5bf551379459c1c61d2a204061c455": `இடம்`,
    "ccffe10fe854018805baf0d3d9327285": `சுயவிவரத்தை நிர்வகிக்கவும்`,
    "49ee3087348e8d44e1feda1917443987": `பெயர்`,
    "10ac3d04253ef7e1ddc73e6091c0cd55": `அடுத்தது`,
    "bafd7322c6e97d25b6299b5d6fe8920b": `இல்லை`,
    "31916d996c9f53949315692bc897f0c7": `பொருத்தமற்றது? தொடர்ந்து பார்க்கவும்`,
    "e81c4e4f2b7b93b481e13a8553c2ae1b": `அல்லது`,
    "0d5e50bf1643118ee026e5d19226542a": `தயவுசெய்து எழுத்துப்பிழை சரிபார்க்கவும் அல்லது வேறு ஏதாவது தேட முயற்சிக்கவும்`,
    "64ed408b000d73143e6ab1b5acb7c26e": `சுயவிவரத்தைத் தேர்ந்தெடுக்கவும்`,
    "fa2ead697d9998cbc65c81384e6533d5": `தனியுரிமைக் கொள்கை`,
    "7595f48afc2f088bc1ff3313ceeb3605": `விண்ணப்பிக்க செயல்முறை`,
    "cce99c598cfdb9773ab041d54c3d973a": `சுயவிவரம்`,
    "665d0095958d696322be90e4eb606977": `இந்த உறவுடன் சுயவிவரம் ஏற்கனவே உள்ளது. தற்போதைய சுயவிவரத்தை நீங்கள் புதுப்பிக்கலாம்`,
    "27bcc51421612805a9c006f24a4d6dc6": `திட்டத்தை அகற்று`,
    "f8b66e3c488bb1df16cb3c7bb21447f0": `தேவையான ஆவணங்கள்`,
    "82609dd1953ccbfbb4e0d20623193b56": `வீடு திரும்பவும்`,
    "c9cc8cce247e49bae79f15173ce97354": `சேமிக்கவும்`,
    "c320cdeffafbb2d60d6d759619c6b5e2": `சுயவிவரத்தை சேமிக்கவும்`,
    "ab9753c948520fb02c20831980bad53a": `என் திட்டங்கள்`,
    "827452688eed02a12178e96f924ac529": `திட்டம்`,
    "cb80cf588ba5a0d3dc067b0b0ef219e9": `திட்டங்கள்`,
    "1925ba54f16355af0964df7dfa145761": `உங்களுக்கான திட்டங்கள்`,
    "13348442cc6a27032d2b4aa28b75a5d3": `தேடு`,
    "32df01b9cf0491a879250b58ba2744ba": `தேடல் முடிவுகள்`,
    // "988cddb5e417156bd3603983dc0c32e5":`தேடல் திட்டங்கள்`,
    "0afcf4c1ba72c441b41fbafaab253fc6": `இருப்பிடத்தைத் தேர்ந்தெடுக்கவும்`,
    "be2933038f93e25262400220080c111f": `மாநிலத்தைத் தேர்ந்தெடுக்கவும்`,
    "dfe1d316ec9338e23c892b573667b9bb": `உங்கள் விருப்பத்தைத் தேர்ந்தெடுக்கவும்`,
    "c2ba7e785c49050f48da9aacc45c2b85": `சேவை`,
    "992a0f0542384f1ee5ef51b7cf4ae6c4": `சேவைகள்`,
    "48ecd536efbb3622296aac8de326fa38": `மன்னிக்கவும், வழங்கப்பட்ட தகவல்களின் அடிப்படையில், நீங்கள் திட்டத்திற்கு தகுதியற்றவர்`,
    "4cd9a992dc66802a4b44c366881957d1": `மன்னிக்கவும், எந்த முடிவும் கிடைக்கவில்லை`,
    "f86301756ec6964cb96e2b80a38f4162": `மன்னிக்கவும்! உங்களுக்காக பொருந்தக்கூடிய எந்த திட்டங்களையும் எங்களால் கண்டுபிடிக்க முடியவில்லை`,
    "46a2a41cc6e552044816a2d04634545d": `நிலை`,
    "efd9a0ba1e9d553a070028627fdcae18": `கணக்கெடுப்பு விவரங்கள்`,
    "4cd61af85d0ec40f50349cd7c3054ace": `சுயவிவரத்தை மாற்றவும்`,
    "1d8cd2bfc6943dcf70236f7d351572a0": `விதிமுறைகளும் நிபந்தனைகளும்`,
    "4f7e556abaf92ce8061ad57cc4e7edd1": `இது தேவை`,
    "371999537b8bb6e7f0099b0c40e0e41d": `இந்த சுயவிவரம் ஏற்கனவே உருவாக்கப்பட்டுள்ளது. நீங்கள் சுயவிவரத்தைப் புதுப்பிக்க முடியாது.`,
    "9eed21a5afd3ba6841c523b0d70599c7": `திட்டங்களைக் காண 2 க்கும் குறைவான எந்தவொரு வகையிலும் கிளிக் செய்யவும் அல்லது குறிப்பிட்ட வகைக்கு ஒரு கணக்கெடுப்பை இயக்கவும் 2 க்கும் மேற்பட்ட வகைகளைக் கிளிக் செய்க`,
    "37528894f18a085f9a078496a68eaaa8": `டிரெண்டிங்`,
    "d876a9fe1aecb3ffc05a5639ebe22000": `மீண்டும் முயற்சி செய்`,
    "06933067aafd48425d67bcb01bba5cb6": `புதுப்பிப்பு`,
    "fba7f6c863513d97922faab074d3c716": `சுயவிவரத்தைப் புதுப்பிக்கவும்`,
    "90b1f6651953fcf8b43ddc0da6760aaf": `பயனர் சுயவிவரம்`,
    "d8d7ad6abfd08c27baa8bedb78c72ddb": `அனைத்தையும் காட்டு`,
    "acac138f2e6f039df2e1fed2f2c0bc44": `EasyGov க்கு வரவேற்கிறோம்`,
    "6ebb2e31989c843ea65feefa43237dad": `உங்கள் கணக்கிலிருந்து ஒரு சுயவிவரத்தை நீக்கும்போது, ​​அந்த குறிப்பிட்ட சுயவிவரத்திற்காக சேமிக்கப்பட்ட எந்த தரவையும் அல்லது தகவல்களையும் நீங்கள் மீட்டெடுக்க முடியாது. தேர்ந்தெடுக்கப்பட்ட சுயவிவரத்திற்கான அனைத்து செயலில் மற்றும் சேமிக்கப்பட்ட திட்டங்களும் நீக்கப்படும்`,
    "6a64fbe7b065fc8579f2521e0054de02": `உங்கள் லேப்ஹார்த்தி கணக்கை நீக்கியால், உங்கள் சுயவிவரத்தில் சேமிக்கப்பட்ட எந்த தரவையும் அல்லது தகவல்களையும் உங்கள் கணக்குடன் தொடர்புடைய பிற சுயவிவரங்களையும் மீட்டெடுக்க முடியாது. உங்கள் செயல்திறன் மிக்க மற்றும் சேமிக்கப்பட்ட திட்டங்களும் நீக்கப்படும்`,
    "93cba07454f06a4a960172bbd6e2a435": `ஆம்`,
    "3aeee34bea95f2b0ced72be632778670": `நீங்கள் எந்த திட்டங்களையும் சேமிக்கவில்லை`,
    "cb32c835ee043e0e2faf4860e46669ae": `இந்த திட்டம்/சேவை மதிப்பாய்வு செய்யப்பட்டுள்ளது. சிறிது நேரம் கழித்து மீண்டும் முயற்சிக்கவும்.`,
    "c2e0ede88489afe5739c8973aa03a787": `உங்கள் சுயவிவரத்துடன் பொருந்தக்கூடிய திட்டங்கள் எதுவும் இல்லை`,
    "28d2dfa9b435bd81cb2f7f0499b846b3": `திட்டங்களைக் கண்டறியவும்`,
    "52561ab2d4640b4e299e817b6ba79173": `லாபர்த்திக்கு வருக`,
    "fb0fc4b5fdb2bef7b1c42f2cfd523c2d": `முயற்சிகள் எஞ்சியுள்ளன`,
    "e13e1cd5f02de37413f9497cc5b65de3": `உங்கள் கணக்கு 30 நிமிடங்கள் பூட்டப்பட்டுள்ளது. தயவுசெய்து அந்த நேரத்திற்குப் பிறகு முயற்சிக்கவும்`,
    "f2a6c498fb90eebdu72k7f888fce3b18": `வரவேற்பு`,
    "1172b2ea87bed4861c8c43a201d53031": `எனது சேவைகள்`,
    "bcc254b55c4a1babdf1dcb82c207506b": `தொலைபேசி`,
    "8458bad978f4fd6d6c7f8ec2ec0824b4": `கடவுச்சொல்லை மறந்துவிட்டீர்களா?`,
    "d67850bd126f070221dcfd5fa6317043": `பதிவு செய்க`,
    "619d59e2ea9f9bb9fe287404dd3ca931": `அனைத்து சேவைகளும்`,
    "6b46ae48421828d9973deec5fa9aa0c3": `வகைபடுத்து`,
    "104f0a6879399d6285ab770ed0dbc257": `சேவையை அகற்று`,
    "0b3937a9dd481d527fcc72adaa735b57": `லாபர்த்தி கணக்கு இல்லையா?`,
    "2f453cfe638e57e27bb0c9512436111e": `தொடரவும்`,
    "f2fdea440d768b85591e936a803c3631": `உள்நுழைக`,
    "82b1fbd43ae27918a37b212a4f60a9ad": `சமர்ப்பிக்கவும்`,
    "31693e2e9aa2e5be1bf3ae45ede9c98d": `நீங்கள் தகுதியற்ற திட்டங்கள்`,
    "d43cd6a49673f0f486ed7fbea1a5fd8a": `ஆதார் எண்`,
    "3a06727e678189b8502408cb9b50b437": `சுயவிவரத்தை மாற்றவும்`,
    "0323de4f66a1700e2173e9bcdce02715": `வெளியேறு`,
    "644cd55dbe4d01e7e00fc7fc458f9b8f": `நீங்கள் நிச்சயமாக வெளியேற விரும்புகிறீர்களா?`,
    "c0fb5959a7a8d3ea991f9edb0b1a9e85": `தயவுசெய்து ஐடியைத் தேர்ந்தெடுக்கவும், உங்களுக்காக திட்டங்களைப் பெறுவோம்`,
    "827a37fb93758383027c582a39ec6162": `ஐடி வகை`,
    "cb3441fa3e1291a76dd5ea72e656bca3": `எந்த ஐடியும் இல்லாமல் தொடரவும்`,
    "99dea78007133396a7b8ed70578ac6ae": `உள்நுழைய`,
    "02aeaf2672eb06861c144004ef2248d2": `உள்நுழைவு கணக்கு`,
    "d4d208eb2d8ccd525a64be73f3521dc5": `தவறான ஆவண சான்றுகள்`,
    "e8055d9577ec8edf34ee15a88a937daa": `கணக்கு இல்லையா?`,
    "942a3ef743c4e176f75d5223455f7e1a": `உங்கள் 10 இலக்க தொலைபேசி எண்ணை உள்ளிடவும்`,
    "21bdc5689c12595ae14298354d5550d5": `ஏற்கனவே ஒரு கணக்கு உள்ளதா?`,
    "dc647eb65e6711e155375218212b3964": `கடவுச்சொல்`,
    "0b3e8b8021c9b2fafee35ae01ac2cfb9": `${window.projectName} உங்களுக்கும் உங்கள் குடும்பத்தினருக்கும் மிகவும் பொருத்தமான அரசாங்க நலத் திட்டங்களைக் கண்டறிய உங்களுக்கு உதவும்.`,
    "05aca668fa6754247f5094c042fb64ac": `தொலைபேசி சரிபார்ப்பு`,
    "f9bc92a6602b9692a0ba578120c9c441": `உங்கள் OTP குறியீட்டை இங்கே உள்ளிடவும்`,
    "d9d1e60e50119d9752001d4196ee6b3c": `OTP`,
    "6e95a24fa2a98c2f59c96a24b182ccfd": `நீங்கள் எந்த குறியீட்டையும் பெறவில்லையா?`,
    "f922096c878b694ec7a3d9282a55904c": `புதிய குறியீட்டை மீண்டும் அனுப்புங்கள்`,
    "32b830cef750168335d96aff4dd98782": `என் வேலைகள்`,
    "bbd26bc8ce3623addc9c54c36f692f8d": `உங்களுக்காக வேலைகள்`,
    "67cb88ed21d4232c4681a5e648554e09": `விண்ணப்ப கட்டணம்`,
    "b90455b71eb5e2399be9d20e23c1a1db": `விண்ணப்பிக்க கடைசி தேதி`,
    "bfe72f18052c940de13501ae9877c3b0": `எப்படி விண்ணப்பிப்பது`,
    "4f1f44ef7ce0c8a111f33fe400a6e4d7": `தேர்வு செயல்முறை`,
    "0f5679acafa674831691cfa01d17501e": `வேலை அறிவிப்பு`,
    "a45ca50bcc7830af2f84fd046648ec92": `உங்கள் சுயவிவரத்துடன் பொருந்தக்கூடிய வேலைகள் எதுவும் இல்லை`,
    "c4bcece293eb1ad7f09d4cf9c2037ea6": `வேலைகளைக் கண்டறியவும்`,
    "5dc52ca9ffdc26147075682c92a0a853": `இடுகைகள்`,
    "3f99ba6fbcb46f28fb24f271fc3c02c1": `வேலைகளைக் காண எந்த வகையிலும் கிளிக் செய்க. எந்தவொரு வேலைக்கும் ஒரு கணக்கெடுப்பை இயக்க கார்டில் கிளிக் செய்க`,
    "2078989a6c09fdd98efc35d3c1d1de3d": `வேலையை நீக்கு`,
    "08c1517355f6e7313c4d629a193ac69a": `நீங்கள் சேமித்த வேலைகளிலிருந்து இந்த வேலையை நீக்க விரும்புகிறீர்களா?`,
    "e614dff8cba6f73ae7cee729fe0091dd": `தவறான OTP`,
    "c46c1e69c89811f03dc73feb09273409": `OTP தேவை`,
    "ae3bb2a1ac61750150b606298091d38a": `புதிய கடவுச்சொல்`,
    "887f7db126221fe60d18c895d41dc8f6": `கடவுச்சொல்லை உறுதிப்படுத்தவும்`,
    "70d9be9b139893aa6c69b5e77e614311": `உறுதிப்படுத்தவும்`,
    "20aedd1e6de4dcf8d115b5a7424c58d7": `கடவுச்சொல் தேவை.`,
    "841a389ec69697c28df7b9739419d21d": `கடவுச்சொல் 6 எழுத்து நீளமாக இருக்க வேண்டும்.`,
    "bcb9407d90fd17ccff50587f5edfe987": `கடவுச்சொல் பொருந்தவில்லை.`,
    "318721657b639c8ca02293211db68837": `தொலைபேசி எண் தேவை`,
    "4ea3456b0e1b463f50b81c79230cf070": `தவறான தொலைபேசி எண்`,
    "bd454fc3d32f4425d146d3e861409871": `பயனர் ஏற்கனவே இருக்கிறார்`,
    "ab48b978d197e315e92e904e36d26175": `தொலைபேசி எண் தவறானது`,
    "70729403b9560d3adb142977608b81d2": `OTP வரம்பு மீறியது. 4 மணி நேரம் கழித்து முயற்சிக்கவும்.`,
    "1e4dbc7eaa78468a3bc1448a3d68d906": `தொலைபேசி எண்`,
    "4f2df4c330b8df125a3d75f2d69a9b5e": `OTP ஐ அனுப்பு`,
    "9ec54ec336b65da6179cf750de33ecd7": `கடவுச்சொல்லை மறந்துவிட்டீர்களா`,
    "98adc700c19f071f07a714a703a6fc1e": `அடையாள விவரங்கள்`,
    "f262fae0a2f17e8c1eb4e9eddb0160e4": `தொடரவும்`,
    "49fb619c4872c491fae649c28b339ed8": `உங்கள் நற்சான்றிதழ்கள் எங்கள் பதிவுகளுடன் பொருந்தவில்லை`,
    "58557a71f9811899313234decda72d5a": `உங்கள் கணக்கு 30 நிமிடங்கள் பூட்டப்பட்டுள்ளது. தயவுசெய்து அந்த நேரத்திற்குப் பிறகு முயற்சிக்கவும்.`,
    "e7665f483813a56e9410787ce2d174ef": `தொலைபேசி எண் ஏற்கனவே பயன்பாட்டில் உள்ளது. பதிவுபெற தயவுசெய்து உள்நுழைக அல்லது மற்றொரு எண்ணைப் பயன்படுத்தவும்.`,
    "0a46fa5fb9e86e80fc94e229d1967699": `தொலைபேசி எண் பதிவு செய்யப்படவில்லை. மீண்டும் முயற்சிக்கவும்`,
    "bdff8b1cbfb4d5623382d7b0678e707c": `கடவுச்சொல்லில் குறைந்தபட்சம் 8 எழுத்துக்கள் இருக்க வேண்டும், இதில் குறைந்தபட்சம் ஒரு மேல் வழக்கு, ஒரு சிறிய வழக்கு, ஒரு எண் மற்றும் ஒரு சிறப்பு எழுத்து`,
    "d55085482b3e8da780755832c17d7d60": `கடைசி தேதி`,
    "988cddb5e417156bd3603983dc0c32e5": `தேடல் திட்டம் மற்றும் சேவைகள்`,
    "261b39077b81875e115baa22bc6ce04c": `வேலைகளைக் காண ஒரு குறிப்பிட்ட வகைக்கு சொந்தமானது, தயவுசெய்து வகையைக் கிளிக் செய்க`,
    "bdb53070a3fc01196ae38b059a4b42ff": `வேலையை அகற்று`,
    "ede1fcad3d6bf808fc948df7d22e1536": `மன்னிக்கவும்! உங்களுக்காக பொருந்தக்கூடிய எந்த வேலைகளையும் எங்களால் கண்டுபிடிக்க முடியவில்லை`,
    "0c4fa7a0201f434b0ef84494d0aac07a": `மன்னிக்கவும், வழங்கப்பட்ட தகவல்களின் அடிப்படையில், நீங்கள் வேலைக்கு தகுதியற்றவர்`,
    "e4defbb7cdf529fa3606749a82c06932": `இடுகைகள்`,
    "25d2401d71f4ba1ba7685559c1b77927": `உங்கள் சுயவிவரத்தின் அடிப்படையில், எந்தவொரு வேலைக்கும் நீங்கள் தகுதியுடையவர் என்று நாங்கள் காணவில்லை. <br> நாங்கள் தொடர்ந்து அதிக வேலைகளைச் சேர்க்கிறோம், தயவுசெய்து பின்னர் சரிபார்க்கவும்.`,
    "ea7c2400c0ae5f58608cb5051ee852f3": `இந்த நேரத்தில் உங்கள் மாநிலத்தில் எந்த வேலைகளும் கட்டமைக்கப்படவில்லை, நாங்கள் தொடர்ந்து புதுப்பித்து வருகிறோம், தயவுசெய்து எங்களுடன் மீண்டும் சரிபார்க்கவும்`,
    "24fe48030f7d3097d5882535b04c3fa8": `காலாவதியான`,
    "9dc0186e2d5d3398ffee6ec964b15dc0": `காலாவதியான தேதியிலிருந்து 30 நாட்களுக்குப் பிறகு காலாவதியான அனைத்து வேலைகளும் உங்கள் “எனது வேலைகளிலிருந்து” அகற்றப்படும்.`,
    "34a934c0c86d866df9d14f8a6e90c637": `தகுதி பெறாததற்கான காரணம்`,
    "a97ea56b0e00b2379736ae60869ff66a": `கேள்வி`,
    "8e9e3212461f14c8dbea6456c920bcbc": `தகுதியான மதிப்புகள்`,
    "c4dce677291fa5bc05276ffe93c5d8f2": `நீங்கள் வழங்கிய மதிப்பு`,
    "9a917c4a4f546c6072bd61ae617763bf": `திட்டம் உங்களுக்காக அல்ல`,
    "e57c13893a290ce882569b5638ba7b82": `நீங்கள் உள்ளிட்ட ஐடி கண்டுபிடிக்கப்படவில்லை. தயவுசெய்து மீண்டும் முயற்சி செய்க.`,
    "e26859a59ede4ab8d8ea0213b68fa732": `மூல அமைப்பு தற்போது பதிலளிக்கவில்லை, தயவுசெய்து சிறிது நேரம் முயற்சிக்கவும்.`,
    // "e26859a59ede4ab8d8ea0213b68fa732":`மூல அமைப்பு தற்போது பதிலளிக்கவில்லை, தயவுசெய்து ஐடி இல்லாமல் தொடரவும் அல்லது சிறிது நேரத்திற்குப் பிறகு முயற்சிக்கவும்.`,
    "d2235c7ecbbbae1bf62833dade7486f5": `சுயவிவர விவரங்கள் கோல்டன் பதிவிலிருந்து எடுக்கப்பட்டுள்ளன.`,
    "346df6b73ae19a6619f26648cf415522": `தகுதியற்ற திட்டங்களின் பட்டியல் சுயவிவரத் தரவை அடிப்படையாகக் கொண்டது.`,
    "6e0fe1553e84e34da97a6c4c9040b31b": `லாபார்த்தி`,
    "27559de2125101ccb786807bceb46aa1": `இந்தியா`,
    "936b6a0effe4990e8f5420a0099fa537": `EasyGov`,
    "8cf04a9734132302f96da8e113e80ce5": `வீடு`,
    "923b39c56654bb6264b6638ba8386b3c": `உங்கள் சுயவிவரத்தை உருவாக்க உள்நுழைக/பதிவுபெறுக`,
    "52f2ba99491a1bae953303a4eaf50196": `OTP உடன் உள்நுழைக`,
    "18e0c8084d60f55016d67d4e2ca507ce": `SSO உடன் உள்நுழைக`,
    "d3d77957f18f1e208cb5b0caa07bec5c": `மேலும் திட்டங்களைக் கண்டறியவும்`,
    "6a26f548831e6a8c26bfbbd9f6ec61e0": `உதவி`,
    "b2119dd0c37bb396e24199d5ffbd0c72": `திரை வாசகர் அணுகல்`,
    "34b6cd75171affba6957e308dcbd92be": `பதிப்பு`,
    "4221d3e17c6eca2ca6337251a3cf9c4e": `கடைசியாக புதுப்பிக்கப்பட்டது`,
    "ece2c7eaaac9f27b055e6e482ab776b9": `தகவல் தேடல்`,
    "ad8f678ccf4a4a34b46f01c74f25b91b": `வலைத்தளத்திற்குள் தகவல்களை அணுக பயனர்களுக்கு உதவ, “இங்கே தேடல்” விருப்பம் வழங்கப்பட்டது. இங்கே, கன்னடத்தை தட்டச்சு செய்வதற்கான விருப்பம் கிடைக்கிறது. தேடும்போது, ​​யூனிகோட் எழுத்துருவின் பயன்பாடு கட்டாயமாகும். ஆங்கிலத்தில் தேடுவதற்கான விருப்பமும் வழங்கப்படுகிறது.`,
    "698aa21b21c2252839e5e54a0d2f12ef": `இணைய அணுகல்`,
    "4ac2856693f5c8b4fc6cdaf669ed2d74": `இணையம் மெதுவாக அல்லது கிடைக்கவில்லை என்றால், வலைத்தள வடிவமைப்பில் ஒரு மாற்றம் இருக்கலாம் அல்லது சில துணைப்பிரிவுகள் திறக்கப்படாமல் போகலாம்.`,
    "0da22543a45316a826571412b3d5953c": `வலைத்தளம் உலகளாவிய வலை கூட்டமைப்பு (டபிள்யூ3சி ) வலை உள்ளடக்க அணுகல் வழிகாட்டுதல்கள் (WCAG) 2.0 நிலை AA உடன் இணங்குகிறது. இது பார்வைக் குறைபாடுள்ளவர்களுக்கு திரை வாசகர்கள் போன்ற உதவி தொழில்நுட்பங்களைப் பயன்படுத்தி வலைத்தளத்தை அணுக உதவும். ஜாஸ், என்விடிஏ, சஃபா, சூப்பர்நோவா மற்றும் சாளரக் கண்கள் போன்ற வெவ்வேறு திரை வாசகர்களுடன் வலைத்தளத்தின் தகவல்கள் அணுகலாம்.`,
    "cb976b928eacd65e6342cfa78bf196da": `பின்வரும் அட்டவணை வெவ்வேறு திரை வாசகர்களைப் பற்றிய தகவல்களை பட்டியலிடுகிறது`,
    "786f669131d80fbed4572a520c28c14d": `EasyGov ஆல் உருவாக்கப்பட்ட மற்றும் சொந்தமான உள்ளடக்கம்`,
    "fc290a7672f96fd8b94a9659c8c02322": `| அனைத்து உரிமைகளும் பாதுகாக்கப்பட்டவை`,
    "6016a2b341113bf496b719905398ecd2": `பதிப்புரிமை`,
    "0e8a85115f2c3ef0058c70f30a9b6deb": `உங்கள் பதிவுசெய்யப்பட்ட மொபைல் எண்ணுக்கு அனுப்பப்பட்ட OTP ஐ உள்ளிடவும்`,
    "34f8ccda5172e4eac9a8a014559b38c1": `உங்கள் கடவுச்சொல்லை அமைக்கவும்`,
    "548899be111ad732b37786783dfbd6d8": `இணைய இணைப்பு இல்லை`,
    "5c3a1593ca1d34226359df44f3d45f2f": `சிறிது நேரத்திற்குப் பிறகு மீண்டும் சரிபார்க்கவும்.`,
    '35043eeac5651298d4bb6c100e071745': `நீங்கள் மூன்றாம் தரப்பு வலைத்தளத்திற்கு திருப்பி விடப்படுவீர்கள். நீங்கள் தொடர விரும்புகிறீர்களா?`,
    "6182e08f3974a70ac4883b5fceaa14c0": `உங்கள் பதிவுசெய்யப்பட்ட மொபைல் எண்ணுக்கு அனுப்பப்பட்ட OTP ஐ உள்ளிடவும்`,
    "306fb6389c3b3fd2835af15cfc7c66e9": `குடும்ப ஐடியை மாற்றவும்`,
    "63fc7651ee1dbf6868a43a5b5fa8b581": `எழுத்துக்கள் மட்டுமே அனுமதிக்கப்படுகின்றன`,
    "6903fbe8375d52217c15618170f4078a": `30 எழுத்துக்கள் மட்டுமே அனுமதிக்கப்படுகின்றன`,
    "9ab37820a722ce13e80a3d0aa468d6dc": `*தேவை`,
    "0df1f18a2fa23cd60990e47692f99e07": `பயன்பாட்டு இணைப்பு`,
    "a60852f204ed8028c1c58808b746d115": `சரி`,
    "edbd5921e9c7168e04cbe6f75ee76174": `உங்கள் சுயவிவரத்தின் அடிப்படையில், எந்தவொரு திட்டத்திற்கும் நீங்கள் தகுதியுடையவர் என்று நாங்கள் காணவில்லை. நாங்கள் தொடர்ந்து கூடுதல் திட்டங்களைச் சேர்க்கிறோம், பின்னர் சரிபார்க்கவும்.`,
    "a203c39a340e1d45069b1ba64e1d003a": `கவனம்!`,
    "037673ab5a84897e0ee43ca37f7680f6": `உங்கள் தரவை மாற்றினால், உங்கள் சுயவிவரத் தரவிலும் புதுப்பிக்கப்படும். நீங்கள் தொடர விரும்புகிறீர்களா?`,
    "d1d16cede248b826a431ea11132567cb": `உங்கள் சரிபார்க்கப்பட்ட தரவை நீங்கள் மாற்றினால், அது மீண்டும் சரிபார்க்கப்படும், மேலும் உங்கள் நிலை மாற்றப்படும். இது உங்கள் பயன்பாட்டின் நிராகரிப்பு அல்லது அதிகரித்த செயலாக்க நேரத்திற்கு அதிக வாய்ப்புகளுக்கு வழிவகுக்கும்.`,
    "23a0ca84a7fd64eee8248bd1dfc77185": `பெயர் மாற்றம்`,
    "3b383caaaa6113dd83b381b43a97d7ee": `இந்த பயனருக்கு ஏற்கனவே ஒரு சுயவிவரம் உள்ளது, புதிய குடும்ப ஐடியைச் சேர்ப்பது முந்தைய விவரங்களை அகற்றும். தயவுசெய்து உறுதிப்படுத்துங்கள்`,
    "e0efbdcea0abe735dbf887292a5670fa": `சகோதரன்`,
    "ad6e7652b1bdfb38783486c2c3d5e806": `சுய`,
    "19cda37369e2d2015e66b9d478ffbe15": `சகோதரி`,
    "81e053d78f01540e9cd6fb68a1c03976": `வேறு யாரோ`,
    "b8e18040dc07eead8e6741733653a740": `அம்மா`,
    "a30ca91be97a9cedc0d2426e54b0e782": `அப்பா`,
    "784c01f6b735be57175e2f438297c741": `மனைவி`,
    "1968ab9fdfedf558714bf6caec9a1234": `மகன்`,
    "7bc1cb93f51f61bde0e90b20c81ebf88": `மகள்`,
    "25f34d5943da3f09a2aa6358193a41bd": `விண்ணப்பிக்க தொடரவும்`,
    "79dff508ab2e4d272f0630d001271eb1": `மேலும் வேலைகளை ஆராயுங்கள்`,
    "b6ebe1e750185946ed41be9191410612": `லாபார்த்தி மூலம் விண்ணப்பிக்க நீங்கள் விவரங்களை வழங்க வேண்டும்.`,
    "af1899084d8361b9afb9187da988ff9c": `“மன்னிக்கவும், உங்கள் தரவை எங்களால் பெற முடியவில்லை”.`,
    "1b56218ddf0a290241d661d26253d51f": `{{attempts}} முயற்சி (கள்) மட்டுமே`,
    "f299cff2484fdb361f4fc3189490d909": `நீங்கள் வழங்கிய பின்வரும் தகவல்கள் உங்கள் அரசாங்க மறுசீரமைப்புடன் பொருந்தவில்லை:`,
    "1edf5362e5f4af6f370a7f34c3136a7a": `சரிபார்ப்பு தோல்வியடைந்தது`,
    "dcf9ac5128c95d88e068e223dfe01d27": `சரிபார்ப்பு நிலை`,
    "bc910f8bdf70f29374f496f05be0330c": `முதல் பெயர்`,
    "77587239bf4c54ea493c7033e1dbf636": `கடைசி பெயர்`,
    "14fcbe1dd0f7a39f0195aba46cf89d17": `விவரங்களை சரிபார்க்கவும்`,
    "b6121db5ee47deeb0ca42d5dea0b2225": `முயற்சிகள் தோல்வியடைந்தன`,
    "839301cec5574d7c3256afce4fb85467": `உங்கள் குடும்ப விவரங்களை சரிபார்க்க சரியான எண்ணிக்கையிலான முயற்சிகளை நீங்கள் மீறிவிட்டீர்கள். உங்கள் சரிபார்ப்பு தடுக்கப்பட்டுள்ளது`,
    "6733aa56fd001b9bf479650f3950688a": `நீங்கள் திட்ட பயன்பாட்டு பக்கத்திற்கு அனுப்பப்படுகிறீர்கள்`,
    "9303c9bd4f8178680dc382adbfcd62af": `இந்த புலம் தேவை.`,
    "53a04cfc3d09c9b2dfb8349791c62bc1": `இந்த குடும்பத்தின் எந்தவொரு உறுப்பினருடனும் உங்கள் பெயர் பொருந்தவில்லை`,
    "1ca35256fcb85c1ba9ea7ac1095462ad": `உங்கள் குடும்ப விவரங்களை சரிபார்க்க சரியான எண்ணிக்கையிலான முயற்சிகளை நீங்கள் மீறிவிட்டீர்கள். உங்கள் சரிபார்ப்பு 3 மணி நேரம் தடுக்கப்பட்டுள்ளது`,
    "0e3a917198575c463bb4fdcb7332b03a": `தவறான ஆதார் அட்டை எண்`,
    "630058029627ce58e7571e7a5b6ec591": `முழு பெயர்`,
    "072355e896ac1a999d2fa0aa208f94ee": `இந்த திட்டம் உங்கள் “எனது திட்டங்களில்” சேமிக்கப்பட்டுள்ளது.`,
    "789189638f8a301304dfadeb61036dd9": `இந்த சேவை உங்கள் “எனது சேவைகளில்” சேமிக்கப்பட்டுள்ளது.`,
    "ab8023b4fc2395738b8fd2df440641a9": `இந்த வேலை உங்கள் “எனது வேலைகளில்” சேமிக்கப்பட்டுள்ளது.`,
    "671dec8fc4e3dc847c1b0d7b67b4e6e1": `உறவு`,
    "f252b4e2f3b2963d330c8cde8e9cdbd9": `கிடைத்தால்`,
    "ef51899b57258db72f15b4703a8a86ba": `பான் இந்தியா அரசாங்க நலத் திட்டங்கள், சேவைகள் மற்றும் வேலைகளுக்கு நடவடிக்கை எடுக்கக்கூடிய தகவல்களை அணுக குடிமக்களுக்கு ஒரு தளம்.`,
    //New Translations for Phase 1:`#VALUE!`,
    "7510e00267e89c78470e79b42ecd6463": `பயன்பாட்டை உங்கள் சொந்த மொழியில் ஆராயுங்கள்`,
    "83218ac34c1834c26781fe4bde918ee4": `வரவேற்பு`,
    "1d00e7dce692e8dc3f6877f035e3a616": `அல்லது`,
    "e0cf58fa3413df99ae83109ebb9a41a3": `OTP ஐ மீண்டும் அனுப்பவும்`,
    "6ed83b24865902c6fd90bbd15247e042": `கடவுச்சொல்லை அமைக்கவும்`,
    "9157806ea93e82c6843e29353af0339f": `OTP ஐக் கோருங்கள்`,
    "69f18e98d2dd54118ade84732dc3a5e0": `முள் சரிபார்க்கவும்`,
    "2dd4472245a696bc0b4b944db2a8b519": `தனிப்பட்ட`,
    "a274f4d4670213a9045ce258c6c56b80": `அறிவிப்புகள்`,
    "407456e575dee5a4b84a19a7111a4a4d": `அந்த 2 எண்களுடன் குறைவான எந்தவொரு திட்ட வகையையும் நீங்கள் கிளிக் செய்யும் போது, ​​நீங்கள் தகுதியுள்ள திட்டங்களை நீங்கள் காண முடியும், எந்தவொரு திட்ட வகையையும் 2 எண்களுடன் கிளிக் செய்யும் போது, ​​வகை குறிப்பிட்ட கணக்கெடுப்பு உங்களுக்கு மேலும் பலவற்றை வழங்கும் தேர்ந்தெடுக்கப்பட்ட வகைக்கான திட்டங்களின் சுத்திகரிக்கப்பட்ட பட்டியல்.`,
    "9139eb8e78808acbd15244d4eb9f1bc3": `மேலும் திட்டங்களை ஆராயுங்கள்`,
    "c61dd5f0e5b5389306ad5221ec2cd795": `இந்த திட்டம் உங்கள் “எனது திட்டங்களில்” சேமிக்கப்பட்டுள்ளது`,
    "f2a6c498fb90ee345d997f888fce3b18": `அழி`,
    "469bad4859f3c33fcb8ce09314c3e895": `கணக்கெடுப்பு விவரங்களை மதிப்பாய்வு செய்யவும்`,
    "7dce122004969d56ae2e0245cb754d35": `தொகு`,
    "c5984b3fe02f7b06d77fb538d842b0fc": `கணக்கெடுப்பு விவரங்களைப் புதுப்பிக்கவும்`,
    "6cf33ce1a7a11a7178131567c777b637": `அதே பெயர் மற்றும் உறவுடன் சுயவிவரம் ஏற்கனவே உள்ளது`,
    "fffcb4d1e12c96b2f571f77ad25878b8": `காரணம்`,
    "e8d89b6875d217a990d47701138e687a": `இந்தத் தரவை நீக்க விரும்புகிறீர்களா?`,
    "aa98db08fed41a99a00aac36f521540f": `கடைசியாக சரிபார்க்கப்பட்டது`,
    "5da618e8e4b89c66fe86e32cdafde142": `இருந்து`,
    "253ec560db0637f6a0e53aefcbd4be0c": `நீங்கள் லாபார்த்தி கணக்கை நீக்கும்போது, ​​உங்கள் சுயவிவரத்தில் சேமிக்கப்பட்ட எந்த தரவையும் அல்லது தகவல்களையும் மீட்டெடுக்க முடியாது. {{name}} for க்காக சேமிக்கப்பட்ட அனைத்து சேமிக்கப்பட்ட திட்டங்களும் தகவல்களும் நீக்கப்படும்.`,
    "c0e70d5bfc28ae3aadfb8f85697c54d5": `உங்கள் தரவு வெற்றிகரமாக புதுப்பிக்கப்பட்டுள்ளது.`,
    "09b73aba9204a05ba5eaf0ea471fdc8c": `EasyGov என்பது சுராஜ்யா சர்வீஸ் பிரைவேட் லிமிடெட்டின் பதிவு செய்யப்பட்ட வர்த்தக முத்திரையாகும். EasyGov இன் இயங்குதளம்-ஒரு-சேவை மாதிரியானது அரசாங்க நலன்களை பழமைவாத, எதிர்வினை மற்றும் திட்ட மையமாக இருந்து படிப்படியாக முற்போக்கான, செயல்திறன் மற்றும் குடும்ப மையமாக மாற்றுகிறது. குடிமக்களின் நலன்களுக்காக நலன்புரி அமைப்பின் இந்த சிக்கலான பயணத்தைத் தீர்க்க EasyGov பல்வேறு அரசாங்கங்களுக்கு உதவுகிறது. மேலும் தகவலுக்கு <a href='https://easygov.co.in/' target='_blank'> https://easygov.co.in/</a> ஐப் பார்வையிடவும்`,
    "8f241c62a9523b05bf0b6b16a09d856d": `மொழியை மாற்றவும்`,
    "1ccbe6084301f9b1e18e3e7caf723ae1": `நீங்கள் நிச்சயமாக வெளியேற விரும்புகிறீர்களா?`,
    "32ca0a0f69103bad1e6f134d5a8e36d7": `ஒரு குறிப்பிட்ட வகைக்கான வேலைகளைக் காண, தயவுசெய்து எந்த வேலை வகையிலும் கிளிக் செய்க`,
    "d6e6cb19e3b9c02f89d6cd54cfa7c613": `வணிக`,
    "b1c94ca2fbc3e78fc30069c8d0f01680": `எல்லாம்`,
    "1dd1c5fb7f25cd41b291d43a89e3aefd": `இன்று`,
    "ebfe9ce86e6e9fb953aa7a25b59c1956": `நேற்று`,
    "4b2ef3655b5d6d48f1fa41892d0043b9": `அறிவிப்புகள் இல்லை`,
    "094f537cfb139894f1412200c78e87b1": `தேர்ந்தெடுக்கப்பட்ட அறிவிப்புகளை நீக்க விரும்புகிறீர்களா?`,
    "4201c11dc26b42deb90cb119c0b12fdb": `அறிவிப்பைப் பதிவிறக்கவும்`,
    "ae45c43efe0011f67ec52003c0d8f916": `<b> குறிப்பு </b>: 'கடவுச்சொல்' குறைந்தது 8 <br> எழுத்துக்களைக் கொண்டிருக்க வேண்டும், இதில் குறைந்தது ஒரு மேல் வழக்கு, ஒரு சிறிய வழக்கு, ஒரு எண் மற்றும் ஒரு சிறப்பு எழுத்து ஆகியவை அடங்கும்.`,
    "248336101b461380a4b2391a7625493d": `சேமிக்கப்பட்டது`,
    "f03ab16cd58372c77ba45a3d9a5a1cb9": `வரைவு`,
    "a4d158b65f20725f9fe9c1763e9805c8": `தனியார் நிதியுதவி`,
    "7fe12d923db43febcc5d8ea18df632ca": `உங்கள் கேஒய்சி ஆவணத்தைத் தேர்ந்தெடுக்கவும்`,
    "d8ce714c8a76133a8d5746e3bfdd7ef7": `கேஒய்சி`,
    "4f67fe16b274bf31a67539fbedb8f8d3": `ஆவண வகை`,
    "5127fa87ed375848481bfe9c786d8022": `உங்கள் கேஒய்சி ஐ நிரப்பவும்`,
    "8f75e3041034138aba310ba3f097ed24": `ஆவண எண்`,
    "9abfb506bebd1e72069f0be0014986dc": `தவறான ஆவண எண்`,
    "ecfffd1bad9944f6a50e369f63268cff": `QR ஸ்கேன்`,
    "c541f95d69a4a507b87b32d05dd6d73c": `சட்டத்திற்குள் குறியீட்டை வைக்கவும்`,
    "3876661cb83fa41284f828bd8d7fbb7b": `கேஒய்சி ஆவணங்கள் கேஒய்சி செயல்முறையின் சரிபார்ப்பு மற்றும் முடிக்க பதிவேற்றப்பட வேண்டும்.`,
    "cc1d6f3625c4eb671d76b6ebf3f6f544": `உங்கள் வாடிக்கையாளரை அறிந்து கொள்ளுங்கள்`,
    "08ac502bbb7652c683c1da97c7fdeb94": `{{xxxx}} உங்கள் மொபைல் எண்ணுக்கு OTP அனுப்பியுள்ளார்`,
    "d19a88ee579190614084c2ea84379ac7": `OTP (1:00)`,
    "7397e92003e187474e5eab39b27fcb6b": `மன்னிக்கவும், மூல அமைப்புகளிலிருந்து உங்கள் தரவை எங்களால் பெற முடியவில்லை. பின்னர் மீண்டும் முயற்சிக்கவும்.`,
    "a943d4ac1dd2e0048bcbf85225bf2811": `OTP ஐ உருவாக்குவதற்கான அதிகபட்ச வரம்பை நீங்கள் தாண்டிவிட்டீர்கள், தயவுசெய்து 3 மணி நேரத்திற்குப் பிறகு மீண்டும் முயற்சிக்கவும்`,
    "8dac090b39cf9b3bff9a9503531d04cf": `உங்கள் கணக்கு தற்போது தடுக்கப்பட்டுள்ளது, தயவுசெய்து சிறிது நேரம் கழித்து முயற்சிக்கவும்.`,
    "08798caaee5cc8ea1282883901c8810b": `மீதமுள்ள முயற்சிகள் - {{Number}} எச்சரிக்கை - தொடர்ச்சியாக 4 தோல்வியுற்ற முயற்சிகளுக்குப் பிறகு, உங்கள் கணக்கு பூட்டப்படும்.`,
    "33018956b3ffe20d413e3c6780f19aff": `கேஒய்சி விவரங்களை உறுதிப்படுத்தவும்`,
    "90f35a35415ca3b4c623d7c515fce1c0": `கவனம்! உங்கள் விவரங்களை நீங்கள் புதுப்பித்தால் அல்லது மாற்றினால், உங்கள் தரவு சரிபார்க்கப்படாது, மேலும் புதுப்பிக்கப்பட்ட தரவுகளுக்கான ஆவண ஆதாரத்தை பதிவேற்றும்படி கேட்கப்படுவீர்கள். தொடர "தொடரவும்" என்பதைக் கிளிக் செய்க`,
    "aee68a98fd803f091082faf867c0f62b": `கேஒய்சி படிவத்தை நிரப்பவும்`,
    "2e853d869dd0c3cb0a64e4938ee90647": `தவறான முள் குறியீடு`,
    "8f3124e142a4000fe04a581f4f41c8da": `தவறான {{labour card}} எண்`,
    "dbb362212f1b8118f17633521d1e72f7": `உங்கள் பயன்பாடு உங்கள் 'வரைவு திட்டத்தில்' வெற்றிகரமாக சேமிக்கப்பட்டது.`,
    "5c93310dd0291e121181e830cdda892e": `கேலரி`,
    "967d35e40f3f95b1f538bd248640bf3b": `புகைப்பட கருவி`,
    "37bed5f24fa3752c7830c021501539a0": `புகைப்படத்தை அகற்ற விரும்புகிறீர்களா?`,
    "1a263d755f169c2499c1a55f7a465b93": `உங்கள் ஆவண ஐடி தற்போது தடுக்கப்பட்டுள்ளது, தயவுசெய்து சிறிது நேரம் கழித்து முயற்சிக்கவும்.`,
    "689707127ddb398dc4b447e1dffa3a47": `உங்கள் விவரங்களை சரிபார்க்க சரியான முயற்சிகளின் எண்ணிக்கையை நீங்கள் மீறிவிட்டீர்கள். உங்கள் சரிபார்ப்பு 3 மணி நேரம் தடுக்கப்பட்டுள்ளது.`,
    "04c850a81052df23680d00d2aa1d891e": `கேஒய்சி நிலை`,
    "a441e519087df0d7c8572b9fafa14ae2": `-நீங்கள் வழங்கிய பின்வரும் தகவல்கள் உங்கள் அரசாங்கப் பதிவோடு பொருந்தவில்லை.1. உங்கள் பெயர் பதிவோடு பொருந்தவில்லை.2. உங்கள் வயது பதிவுடன் பொருந்தவில்லை.`,
    "75514ce9dac260a1f7abd0d85ce6af46": `நீங்கள் வழங்கிய விவரங்கள் பதிவுகளுடன் பொருந்தவில்லை`,
    "5955e277da6fb75982b0f651f4ae8b92": `மூல அமைப்புகளிலிருந்து உங்கள் தரவை எங்களால் பெற முடியவில்லை. பின்னர் மீண்டும் முயற்சிக்கவும்.`,
    "be60ac326400cc5ef0d173a833c4a40b": `உங்கள் விவரங்கள் சரிபார்க்கப்படுகின்றன`,
    "d1083e84a9a7283ffb081263e8533509": `உங்கள் விண்ணப்பம் உங்கள் ‘வரைவு திட்டத்தில்’ வெற்றிகரமாக சேமிக்கப்பட்டுள்ளது`,
    "0bad551abed71c0a41ca8df804afec86": `ஆவணத்தைப் பயன்படுத்தி கேஒய்சி`,
    "e6387f3e684958515af2947d8cdf01d7": `கேஒய்சி படிவத்தைப் புதுப்பிக்கவும்`,
    "9c7ed3d8ac60281e90519b4ebf07a9a2": `முகவரியைத் தேர்ந்தெடுக்கவும்`,
    "31e41095bfaa14799239e8d9ba7ad438": `விண்ணப்ப விவரங்கள்`,
    "e1e4c8c9ccd9fc39c391da4bcd093fb2": `தொகுதி`,
    "b9eca8f59016e8ff69a2c76fa869d23f": `தொழிலாளர் அட்டையைப் பதிவேற்றவும்`,
    "6cf24d7a07132fe88b610e2b3d3def28": `சுய அறிவிப்பு`,
    "827b05360cd22ccbaf3d085aded71570": `முகவரி சான்று`,
    "f734cd7792862bebbbb87ddc54285376": `அடையாள சான்று`,
    "5a8c686dfdba564a2801806f3827c124": `படம் JPG அல்லது PNG வடிவத்தில் இருக்க வேண்டும். PDF கோப்பு 1MB க்கும் குறைவாக இருக்க வேண்டும்.`,
    "d495cdb8315e12e3dc5fd6121ed69cbc": `தவறான IFSC குறியீடு`,
    "cd9f177eac6411501839f7868e38767b": `இந்த ஆவணத்தை நீக்க விரும்புகிறீர்களா?`,
    "565d2daf0ccaf00d354669e7551b81dd": `சுயவிவர விவரங்களை மதிப்பாய்வு செய்யவும்`,
    "9895dd168483d1d34ab9ebfb2b6760be": `வங்கியைத் தேர்ந்தெடுக்கவும்`,
    "deb5c3f30f3a8381367392d6608fbe68": `பயன்பாட்டு நிலை`,
    "970324882e5bbfd2deef9e5b7e2d1160": `பயன்பாட்டைப் பதிவிறக்கவும்`,
    "03b55d8ed61b625c209cc42ea0a35b8c": `நன்றி!`,
    "8276f0b86b5ee98d03f2eafba3590bb0": `உங்களுக்கு சேவை செய்வதற்கான வாய்ப்பை வழங்கியதற்காக. உங்கள் விண்ணப்பம் வெற்றிகரமாக சமர்ப்பிக்கப்பட்டுள்ளது.`,
    "ac3122aeddff176ae67d3df2b2198d19": `நன்றி! உங்கள் விண்ணப்பம் சரிபார்ப்புக்காக சமர்ப்பிக்கப்பட்டுள்ளது, "பயன்பாட்டு பயன்பாடுகள்" இன் கீழ் பயன்பாட்டிலிருந்து உங்கள் பயன்பாட்டு நிலையை சரிபார்க்கவும்`,
    "73da163444abaee6a5c88532b8eda629": `உங்கள் அனுபவம் எப்படி இருந்தது?`,
    "74eb855e4de6fe58228f03006c02fd8a": `சரி`,
    "d36ed38206da38c85af59039d18f1108": `<b> உங்கள் கருத்தை விவரிக்கவும் </b> (விரும்பினால்)`,
    "446e92f15cd245125ccd983ef968e692": `நன்றி இல்லை!`,
    "634a1a1ed07518476cb7a4beab87185a": `பயங்கரமான`,
    "f1b68d66337a81cfa0d2076171cba2a8": `மோசமான`,
    "45802158e78dd9584161629098018fe8": `நல்ல`,
    "5709082e0d4e7a2cb92c2348acf297be": `அதை நேசித்தேன்`,
    "c629e8ac2592361ec6474f07fb75f6fc": `உங்கள் கருத்து மேம்படுத்துவதற்கான புதிய வாய்ப்புகளைக் கண்டறியவும், உங்களுக்கு சிறந்த பயன்பாட்டு அனுபவம் இருப்பதை உறுதிசெய்யவும் அனுமதிக்கிறது.`,
    "871abe85eba547582d9e8f0f9c61efbd": `எங்களுக்கு கருத்து அனுப்பியதற்கு நன்றி.`,
    "733d43480c8589b1368e5def6b480415": `பயன்படுத்தப்பட்டது`,
    "a5b739036b4d0b2bc966291747198d9b": `பதிவுசெய்தது`,
    "0a4c051fb821b8e96d2bd564b893639b": `உங்களுக்காக தனிப்பயனாக்கப்பட்ட திட்டங்கள் எதுவும் இல்லை`,
    "9639e32cab248434a17ab32237cb3b71": `விண்ணப்பிக்கவும்`,
    "3f68e67dc6c397aaa9d1c24c356f754f": `சரிபார்க்கப்பட்டது`,
    "d63b911814d17bb4d541141b8531a458": `இந்த திட்டங்கள் உங்கள் "எனது திட்டங்களில்" சேமிக்கப்பட்டுள்ளன`,
    "454fd3b5167e532f28f4e3d47cd901fa": `உங்களிடம் இதுவரை சேமிக்கப்பட்ட திட்டம் இல்லை`,
    "5e22b63835aef7976f3ca019841b1b28": `இந்த திட்டத்தை அகற்ற விரும்புகிறீர்களா?`,
    "8454c7c12286671b47684cef824ffba6": `"கவனம்! உங்கள் தரவை மாற்றினால், உங்கள் சுயவிவரத் தரவிலும் புதுப்பிக்கப்படும். தொடர விரும்புகிறீர்களா?"`,
    "24a23d787190f2c4812ff9ab11847a72": `நிலை:`,
    "85896a744f6fd84cbcd7bddb31fae8d7": `செயல்:`,
    "ccb168b5a6a86eb100dc2dee754a316b": `காரணம்:`,
    "85b7d770b94264f51f31d2d7a98f69f0": `விண்ணப்பத்தைத் திரும்பப் பெறுங்கள்`,
    "0ddc5e1c93fec761445a53ee9fd70285": `அதிகாரத்திற்கு அனுப்புங்கள்`,
    "7a4813490daea6bbe84ed02f9534570b": `பயன்பாடு மற்றும் இணைப்பு சரிபார்க்கப்பட்டது`,
    "f9e39c8a1b3348b654b73fcdebeb3265": `விண்ணப்பம் இன்னும் அங்கீகரிக்கப்படவில்லை`,
    "6b76127f99b6f29c2a725da125d98760": `நன்மை வழங்கல்`,
    "4296fd320beaf61dcf979ff634cf2f2d": `நடவடிக்கை தேவை:`,
    "fc341b619c2671a430365edefc100ff1": `முழுமையான செயல்கள்`,
    "4f4b8b7d27a33c83089f30dba4989be5": `பயன்பாடுகளைக் காண்க`,
    "2f70605f8a2f6c01ac06fe0f6d32b44c": `உங்கள் விண்ணப்பத்தை திரும்பப் பெற விரும்புகிறீர்களா?`,
    "6eaaa83f6b9b14dbed5e0779d91fffb5": `விண்ணப்பம் நிராகரிக்கப்பட்டது`,
    "6187927c14aae6cab0f87b55ef75edb1": `மீண்டும் விண்ணப்பிக்கவும்`,
    "864cacf59d640b51390faf29867c310e": `அடுத்த நன்மைக்காக தொடரவும்`,
    "16d2b386b2034b9488996466aaae0b57": `வரலாறு`,
    "c5b2992c0919083c2d8907f8fe1441f6": `சமூக-பொருளாதார விவரங்கள்`,
    "d79c1042de4ec2d7e6d84d3a8f436765": `தொழில் சுயவிவரம்`,
    "3c8d3371dfe92bb990493d3dda09717c": `கல்வி சுயவிவரம்`,
    "181bfe2f225b876c687104878a6b43cc": `வீட்டு விவரங்கள்`,
    "f081b3a821bf13173ac4ec3468122a8c": `சுகாதார விவரங்கள்`,
    "fa284c99eeef0e1c874643e05a449214": `"கவனம்! எல்லா மதிப்புகளையும் நீங்கள் தேர்ந்தெடுக்க முடியாது. இந்த மதிப்பைத் தேர்ந்தெடுப்பதற்கு முன் மற்றொரு மதிப்பைத் தேர்வுசெய்க."`,
    "311d38f551d45cb61e297748f08bb658": `உங்கள் தரவு வெற்றிகரமாக சேமிக்கப்பட்டுள்ளது`,
    "4500d073ef5972cc935a59ff0b752d8b": `செல்லுபடியாகும் வரை`,
    "8de420ed8c345d37cf65a3494bff92ef": `வாக்காளர் அட்டை`,
    "f6fb95a7bfd158a53680691f250e2273": `அரசாங்க கட்டணம்:`,
    "c51bae43a860107d2ddaddcc44c72355": `முகவரியின் ஆதாரம் (ஏதேனும் ஒன்று)`,
    "a1cba2ed3414b72aa4d7651653d08900": `வயதுக்கு ஆதாரம் (ஏதேனும் ஒன்று)`,
    "330dd937743341b8c97a75ddbcfacf1b": `படிவத்தைப் பதிவிறக்குவதற்கான இணைப்பு:`,
    "de259fed6ef60355d0dd031c4a97e945": `பதிவேற்ற படிவத்திற்கான இணைப்பு:`,
    "6204374d06a6ca36a20e887451db78af": `மேலும் சேவைகளை ஆராயுங்கள்`,
    "98d047289f68350b76f20cd10f1db3d4": `ஐடியின் ஆதாரம்`,
    "1a01812e1888f4eb6dca36e8b1d39c04": `பிறந்த தேதி சான்று`,
    "0dcd6f339c0f72875972d60324db52ff": `விண்ணப்ப கட்டண விவரங்கள்`,
    "133e72d94045f209d146dd6fc01de185": `கட்டண விவரங்கள்`,
    "b6d11bb63dc2071d6cba2aaeba8c8a1d": `வசதி கட்டணம்`,
    "719fec04166d6fa75f89cd29ad61fa8c": `வரி`,
    "a876fbe73a6d039cb8c8566f6aa716d5": `மொத்த தொகை`,
    "9d463ea3feb5afec51c20b4f62927ca3": `கூப்பன் குறியீடு`,
    "620a92a00838e523161dab899b76c764": `செலுத்த தொடரவும்`,
    "6f861b42317b2333c01faa9b555b7e14": `இந்த கூப்பனுடன் சேமிப்பு.`,
    "c6088ccfc87a825ceb53a15ce4d99510": `<Amount> இன் மொத்த தள்ளுபடியைப் பெற்றுள்ளீர்கள்`,
    "0e54fc6a9dba79c2bc03ac08f3bf4a27": `ஆம்!`,
    "c1d9a46634bb89273f7d95a5577f15ad": `கூப்பன் தள்ளுபடி`,
    "1063e38cb53d94d386f21227fcd84717": `அகற்று`,
    "ba8550da63869236a92fed41fba6e84f": `கூப்பன் குறியீடு வெற்றிகரமாக பயன்படுத்தப்படுகிறது`,
    "f7a7e0e3897bac9ea4c83d53f6ad5a23": `தவறான கூப்பன் குறியீடு.`,
    "90fe9a78f3ea30beeb6b7ef447d2b6dd": `கூப்பன் குறியீடு வெற்றிகரமாக அகற்றப்பட்டது`,
    "af1b98adf7f686b84cd0b443e022b7a0": `வகைகள்`,
    "11f18dd73f0c3d374d9d5c230cd43160": `இளைஞர்களின் திறன்`,
    "5a6c0d2579b31142130f20104fa58ea6": `சான்றிதழ்கள்`,
    "c482980d384a9d0e7bc39e1140270870": `நிதி`,
    "605669cab962bf944d99ce89cf9e58d9": `ஆரோக்கியம்`,
    "5dac73aa49b9ccaca4b9b0d0ef615d10": `காவல்`,
    "b2ece3493eed05158a9cde4a0d2da2a5": `பொது குறை`,
    "94df2a6972ca1fa79411645fe9b42339": `பயன்பாடு`,
    "f5f0c488ed56cf789d19a13ffba8abe3": `போக்குவரத்து`,
    "0db377921f4ce762c62526131097968f": `பொது`,
    "bc34e4c4f36b0d66910a906449910f64": `இந்த சேவையை அகற்ற விரும்புகிறீர்களா?`,
    "1e22827dff05f5a1c044993aff539403": `உங்களிடம் சேமிக்கப்பட்ட சேவைகள் எதுவும் இல்லை.`,
    "b08f17b81d3b9b4f6287b87c53cfc97a": `வரைவில் உங்களிடம் எந்த சேவைகளும் இல்லை.`,
    "6f96ffbc03ad1b9ebb5c6e2ecfcbdc71": `நீங்கள் இதுவரை எந்த சேவைகளையும் பயன்படுத்தவில்லை.`,
    "daf87fe41645c86672e96857222a0909": `விண்ணப்பம் சமர்ப்பிக்கப்பட்டது`,
    "54aeeea175c1509b7c56e0c984f9b4fa": `உங்களுக்கு சேவை செய்வதற்கான வாய்ப்பை வழங்கியதற்கு நன்றி. உங்கள் விண்ணப்பம் வெற்றிகரமாக சமர்ப்பிக்கப்பட்டுள்ளது.`,
    "f18c4dab2674eae1e8c287c683b66729": `விண்ணப்பத்தின் தேதி`,
    "fb0728ffa9b40d444db91c818eb21a2b": `விண்ணப்பிப்பவரின் பெயர்`,
    "e500e94f457ddd88824c88fda548bed2": `பயன்பாட்டு ஐடி`,
    "ad0206b8eec486df629ce37169376f65": `பங்கு மற்றும் பொறுப்புகள்:`,
    "6f2ad1cfe3c5b08cc53c62470f4cb1f7": `தேவையான ஆவணங்கள்:`,
    "1d83797411218ecca1e5ff4d4705dfb0": `தேர்வு நடைமுறை:`,
    "ce9e1e989c012211d8bc83c5353b4495": `வேலை விண்ணப்ப விவரங்கள்`,
    "18dcaa84c2ee447028f8450cd0380ba6": `கல்வி விவரங்கள்:`,
    "93e9a018926429f6d0b0c032910a8eb6": `வேலை விண்ணப்ப விவரங்களை மதிப்பாய்வு செய்யவும்`,
    "6f906132d3c6b88b57225fdcf76f6e71": `அடிக்கடி கேட்கப்படும் கேள்விகள்`,
    "2d8d66b9c9b540fcb8384167f1565b64": `தயவுசெய்து எழுத்துப்பிழை சரிபார்க்கவும் அல்லது வேறு ஏதாவது தேட முயற்சிக்கவும்.`,
    "f73a506c52c2dba159fc0fbee823aace": `இந்த திட்டத்திற்கு நீங்கள் ஏற்கனவே விண்ணப்பித்துள்ளீர்கள். உங்கள் பயன்பாட்டு திட்டங்களிலிருந்து உங்கள் விண்ணப்ப நிலையை சரிபார்க்கவும்.`,
    "b73856e1c7ab3e647e28663d5e30fabe": `இந்த திட்டத்திற்கு நீங்கள் ஏற்கனவே விண்ணப்பித்துள்ளீர்கள். உங்கள் பயன்பாட்டு சேவைகளிலிருந்து உங்கள் விண்ணப்ப நிலையை சரிபார்க்கவும்.`,
    "cd850a99c0895272f2cdf23586867b16": `இந்த திட்டத்திற்கு நீங்கள் ஏற்கனவே விண்ணப்பித்துள்ளீர்கள். உங்கள் வேலைகளிலிருந்து உங்கள் விண்ணப்ப நிலையை சரிபார்க்கவும்.`,
    "4e0b8852fc3f3d1a2b4c24c4602766dd": `படம் JPG, PNG, PDF கோப்பு மற்றும் 1MB க்கும் குறைவாக இருக்க வேண்டும்.`,
    "5a9e7167eb5b7cb7bb46cf5c05eed59a": `மறுதொடக்கம்`,
    "91412465ea9169dfd901dd5e7c96dd99": `பதிவேற்றவும்`,
    "3835b71ace5673385a19d56bc938fda8": `கள புகைப்படம்`,
    "4386624773a0ef6de5a5de0dd6f8facd": `ஆவண வகையை உள்ளிடவும்`,
    "fdba3d2a9c707e50f4eec34cd6bdd0d6": `"உங்கள் வாடிக்கையாளரை அறிந்து கொள்ளுங்கள்" என்பது அரசாங்கத் திட்டத்திற்கு விண்ணப்பிக்கும் பயனரின் அடையாளத்தை அடையாளம் கண்டு சரிபார்க்கும் கட்டாய செயல்முறையாகும்.`,
    "244773cb8f460196045c5b90659d2c9d": `உங்கள் பயன்பாடு உங்கள் "வரைவு சேவைகளில்" வெற்றிகரமாக சேமிக்கப்பட்டுள்ளது`,
    "bbcb2990551820fc4678835c14a84503": `புகைப்படத்தை அகற்று`,
    "78e3e2ba60b81cf6cc24e6baf89eaa81": `தனிப்பட்ட சான்றிதழ்கள்`,
    "88bcab139c5d6142b04bf77d7dd1c023": `இந்த சேவைக்கு நீங்கள் ஏற்கனவே விண்ணப்பித்துள்ளீர்கள். உங்கள் பயன்பாட்டு சேவைகளிலிருந்து உங்கள் விண்ணப்ப நிலையை சரிபார்க்கவும்.`,
    "bf8cd5900a65efaa89931067cb1d3775": `வரைவில் எந்த திட்டங்களையும் நீங்கள் சேமிக்கவில்லை.`,
    "beab92ac3c8a798a35aea2ad0809313f": `விருந்தினராக தொடரவும்`,
    "95884374c7752d972101b70854caffe6": `உள்நுழைக/பதிவுபெறுக`,
    "e44cb1c4a784a247f7296cc02f7f301c": `மாவட்டத்தைத் தேர்ந்தெடுக்கவும்`,
    "9aa060dc3e3bb3ffff9908c5e713a80c": `நீங்கள் இதுவரை எந்த சேவைகளுக்கும் விண்ணப்பிக்கவில்லை.`,
    "383f419ea8c0b8142cbdc71e2e4b144a": `முந்தைய கேள்வி`,
    "fb188e808b65b9ee48a5c9d88fb92af2": `மீண்டும் தொடங்க`,
    "db5eb84117d06047c97c9a0191b5fffe": `ஆதரவு`,
    "bc80ae98530c6ba4e76ed34c58d81875": `மொபைல் பயன்பாட்டிற்கான Easygov ஐப் பதிவிறக்கவும்`,
    "85ce0cae173233410acbc380a7e531cd": `இந்த பக்கம் அரசாங்கத்தின் வலைத்தளங்கள் / வலைப்பக்கங்களுக்கான இணைப்புகளையும் வழங்குகிறது என்பதை நினைவில் கொள்க. அமைச்சுகள்/துறைகள்/அமைப்புகள். இந்த வலைத்தளங்களின் உள்ளடக்கம் அந்தந்த நிறுவனங்களுக்கு சொந்தமானது, மேலும் அவை மேலதிக தகவல் அல்லது ஆலோசனைக்கு தொடர்பு கொள்ளப்படலாம்`,
    "ad0a802ec8134e9210a5625933db50a7": `மறுப்பு`,
    "26dbd93cbe6f1e56b1577d8e3cb7fda3": `சேவைகளைத் தேர்ந்தெடுக்கவும்`,
    "c6582b36b0f516684a873e8782517822": `திட்டங்கள் மற்றும் சேவைகளைத் தேர்ந்தெடுக்கவும்`,
    "b98287be44e1230cf144aa3af08ac82f": `நீங்கள் இதுவரை எந்த திட்டங்களுக்கும் சேர்க்கப்படவில்லை`,
    "449d3cf7dd09172024ebe93872410f0f": `பயன்பாட்டைக் காண்க`,
    "025d9b3a3c2a6a27d04c57c1d7cabc9f": `எந்த நடவடிக்கையும் தேவையில்லை`,
    "06df33001c1d7187fdd81ea1f5b277aa": `செயல்கள்`,
    "adff901e227bf2b2200c4767fe773836": `தேவையான செயல்கள்`,
    "4ff84d2ee02fe6ef0ca2edc3cdecc0ed": `தேவையான செயல்கள்`,
    "c08c272bc5648735d560f0ba5114a256": `தொடர்பு ஆதரவு`,
    "fd2f820569d83784238e1027897b463e": `உங்கள் பெயரை உள்ளிடவும்`,
    "cda83d00666a7bfa8cde6808de4ef994": `தொடர்பு எண்`,
    "a4d3b161ce1309df1c4e25df28694b7b": `சமர்ப்பிக்கவும்`,
    "4c2a8fe7eaf24721cc7a9f0175115bd4": `செய்தி`,
    "68d390535e8a7120e3f6942d8a2214a0": `உங்கள் செய்தியை உள்ளிடவும்`,
    "2f644b4a27e68f505c4f4ac2ffe3a8ac": `தயவுசெய்து எழுத்துக்கள், எண்கள், கமா, -, _, / மட்டுமே உள்ளிடவும்`,
    "58f7a43f2c3bd7854d99fbbbc3fa4ae9": `எழுத்துக்கள் மட்டுமே அனுமதிக்கப்படுகின்றன.`,
    "4906d981bb592b32f6982fb4187c7b60": `செல்லுபடியாகும் ஐஎஃப்எஸ்சி குறியீட்டை உள்ளிடவும்.`,
    "562d3d08919ae105c3ebc42d9607d266": `தயவுசெய்து எழுத்துக்கள் அல்லது எண்களை மட்டுமே உள்ளிடவும்`,
    "fab2bb673b21bef95ee70b99dbd7f9fc": `11 எழுத்துக்கள் மட்டுமே அனுமதிக்கப்படுகின்றன.`,
    "a7eb433676475fee54727c14ad880b8c": `150 எழுத்துக்கள் மட்டுமே அனுமதிக்கப்படுகின்றன.`,
    "7c58cde3b827bc7d57cbf77865046169": `தயவுசெய்து எண்களை மட்டுமே உள்ளிடவும்`,
    "544b0264d55a1e4cf476b279240d9be4": `பத்து இலக்கங்கள் மட்டுமே அனுமதிக்கப்படுகின்றன. தயவுசெய்து 0 அல்லது +91 முன்னொட்டு வேண்டாம்`,
    "73f0d980e48b8141e0776e02d96a872f": `பன்னிரண்டு இலக்கங்கள் மட்டுமே அனுமதிக்கப்படுகின்றன.`,
    "0ce98bd973419ff33921a2275e7903b9": `செல்லுபடியாகும் பான் எண்ணை உள்ளிடவும்.`,
    "c6f91b305e91a0053748310de170a73c": `ஆறு இலக்கங்கள் மட்டுமே அனுமதிக்கப்படுகின்றன`,
    "9f44bd93c8988c682d5ef5369fd11f47": `சரியான மின்னஞ்சலை உள்ளிடவும்`,
    "10803b83a68db8f7e7a33e3b41e184d0": `பிறந்த தேதி`,
    "ac598199ea0dd028ade4677a435caf4b": `செல்லுபடியாகும் ஆதார் எண்ணை உள்ளிடவும்`,
    "d0de720ccdffea8490dfd2893c50ebc5": `இது {{displayName}} ஐ விட அதிகமாக இருக்க வேண்டும்`,
    "ced6e171e13ebd5c37415c822706b9d4": `இது {{displayName}} ஐ விட குறைவாக இருக்க வேண்டும்`,
    "d1a1cb2bd14fdd9eec972f162cfd1ee0": `இது {{displayName}} for க்கு சமமாக இருக்க வேண்டும்`,
    "9483135517d156229d0439dff74b1ca0": `இது {{displayName}} ஐ விட அதிகமாகவோ அல்லது சமமாகவோ இருக்க வேண்டும்}}`,
    "01e9cd748e852b1ae389b980bff195b0": `இது {{displayName}} for ஐ விட குறைவாகவோ அல்லது சமமாகவோ இருக்க வேண்டும்`,
    "dc236871b26ba387e1284445caa99df4": `உங்களுக்கு சேவை செய்வதற்கான வாய்ப்பை வழங்கியதற்காக.`,
    "2102fff7bb4de45c9e616e3f01b6810f": `உங்கள் விண்ணப்பம் வெற்றிகரமாக சமர்ப்பிக்கப்பட்டுள்ளது.`,
    "dc9466f3d424b4acba2fc5c9f58d4f8a": `மன்னிக்கவும், நீங்கள் தகுதி பெறவில்லை`,
    "a9198ed7d89e246a80078db84d2722e8": `உங்கள் தொலைபேசி எண் அல்லது கடவுச்சொல் பொருந்தவில்லை`,
    "8ac0a98c8c2f2c86396e34b6b5d63e38": `அதே பெயருடன் சுயவிவரம் ஏற்கனவே உள்ளது`,
    "4ae112e33b3189c9fa04369b51259d76": `அதே பெயர் மற்றும் உறவைக் கொண்ட சுயவிவரம் ஏற்கனவே உள்ளது`,
    "b0fb09a28a4a52a1b3ca2656dec0435e": `இந்த ஆவணத்தை அகற்ற விரும்புகிறீர்களா?`,
    "e384b6a4760d80be609d897414cfe680": `நீங்கள் பதிவுசெய்யப்பட்ட பயனர் அல்ல, மேலும் தொடர நீங்கள் "பதிவுபெறலாம்" அல்லது "OTP உடன் உள்நுழையலாம்"`,
    "c2cd85ffa55b2ec9b049b7eb131d3a9f": `உள்ளிடப்பட்ட கடவுச்சொற்கள் பொருந்தவில்லை. மீண்டும் முயற்சி செய்`,
    "3a816ee692e09b3cac6ef18e14071bd4": `உங்கள் கடவுச்சொல் வெற்றிகரமாக அமைக்கப்பட்டுள்ளது`,
    "764d4847b734121ceb58e5c8195fa5b6": `மேம்படுத்துவதற்கான புதிய வாய்ப்புகளைக் கண்டறியவும், உங்களுக்கு சிறந்த பயன்பாட்டு அனுபவம் இருப்பதை உறுதிசெய்யவும் உங்கள் கருத்து எங்களுக்கு அனுமதிக்கிறது.`,
    "701d895fbc64569aa143ca6c44274139": `OTP ஐ உருவாக்குவதற்கான அதிகபட்ச வரம்பை நீங்கள் மீறிவிட்டீர்கள், தயவுசெய்து சிறிது நேரம் கழித்து மீண்டும் முயற்சிக்கவும்.`,
    "0bdfc4d562c9b0ccfaa4c0f6d4cac98f": `நீங்கள் இதுவரை எந்த திட்டங்களுக்கும் விண்ணப்பிக்கவில்லை.`,
    "4391bf5189e3e13c4aa4cb314c605ac8": `நீங்கள் இதுவரை எந்த திட்டங்களையும் சேமிக்கவில்லை.`,
    "92202504591d549b89340d26ffc0753a": `இந்த வேலை உங்கள் "எனது வேலைகளில்" சேமிக்கப்பட்டுள்ளது`,
    "af6ebe0c0e89201cb2294f32955f7b15": `ஒரு நேரத்தில் 20 ஆவணங்கள் மட்டுமே அனுமதிக்கப்படுகின்றன.`,
    "b1481f771aee527fd910fa8d8672cd19": `இந்த அறிவிப்பை அகற்ற விரும்புகிறீர்களா?`,
    "06a6b009c19d5816574cdc7800d10eb5": `உங்கள் கோரிக்கை வெற்றிகரமாக எழுப்பப்பட்டுள்ளது.`,
    "b941bc7a9d4fe7a8c7c31ed440754285": `பயன்பாட்டை மதிப்பாய்வு செய்யவும்`,
    "e56b98da835e8f4a018502b121520ac7": `மீதமுள்ள முயற்சிகள்`,
    "416828262df288445e72ca1d3661f993": `எச்சரிக்கை - தொடர்ச்சியாக 4 தோல்வியுற்ற முயற்சிகளுக்குப் பிறகு, உங்கள் கணக்கு பூட்டப்படும்`,
    "9f978addf10226b03278e4932348ba98": `40 எழுத்துக்கள் மட்டுமே அனுமதிக்கப்படுகின்றன.`,
    "69f076c339373e00e9e841da965f4208": `ஆதார் புதுப்பிக்கவும்`,
    "48a82e0b2bd5818f110e55bb6f14aedc": `நீங்கள் வழங்கிய பின்வரும் தகவல்கள் அரசாங்க பதிவுடன் பொருந்தவில்லை`,
    "5c37ad5c0dd6e318af899b3c8fcbe886": `மன்னிக்கவும், மூல அமைப்பிலிருந்து உங்கள் தரவை எங்களால் பெற முடியவில்லை. நீங்கள் சரிபார்ப்பு இல்லாமல் தொடரலாம் அல்லது பின்னர் முயற்சி செய்யலாம்.`,
    "cdb2033fc9a30d6d6c1abe401cd1e37b": `சிறிது நேரம் கழித்து முயலுங்கள்`,
    "f8d855b26faffa0f8779fb44632d6bc1": `தவறான கேப்ட்சா குறியீடு`,
    "d53b4d564b9054db9b4b4a50e713197f": `தயவுசெய்து இங்கே கேப்ட்சாவை உள்ளிடவும்`,
    "ed001fb3ff7eaca9bbfbaacddc9d5a72": `வரலாறு எதுவும் கிடைக்கவில்லை`,
    "4934d9bc9ebd024d4106d5e1f093ae79": `பார்வையாளர்கள் எண்ணுகிறார்கள்`,
    "f7ce7cb4b450acfe0b4cef36c3bdec2d": `உங்கள் வங்கி கணக்கு தற்போது செயலற்ற நிலையில் உள்ளது. உங்கள் விண்ணப்பம் அங்கீகரிக்கப்பட்டால், நீங்கள் மேலும் தொடரலாம், ஆனால் உங்கள் வங்கிக் கணக்கை ஆரம்பத்தில் செயல்படுத்தலாம்.`,
    "df9e5c88861b08c33b2d46de8d7037e9": `உங்கள் வங்கி கணக்கு தற்போது ரத்து செய்யப்பட்டுள்ளது. உங்கள் விண்ணப்பம் அங்கீகரிக்கப்பட்டால், நீங்கள் மேலும் தொடரலாம், ஆனால் உங்கள் வங்கிக் கணக்கை ஆரம்பத்தில் செயல்படுத்தலாம்.`,
    "3fc95e40b475f7269e64f25a1c4aef3b": `உங்கள் வங்கிக் கணக்கு உங்கள் ஆதார் உடன் இணைக்கப்படவில்லை. உங்கள் விண்ணப்பம் ஒப்புதல் அளிக்கப்பட்டால், நீங்கள் மேலும் தொடரலாம், ஆனால் உங்கள் வங்கிக் கணக்கை உங்கள் ஆதாரத்துடன் இணைத்துக்கொள்ளலாம்.`,
    "1c35d003a65da48bf9f6d6fc69271d5a": `மன்னிக்கவும், கணினி பிழை காரணமாக உங்கள் விண்ணப்பத்தை சமர்ப்பிக்க முடியவில்லை. பின்னர் மீண்டும் முயற்சிக்கவும்.`,
    "8e437cbd840bce5c5edc0ef8164de6b7": `உங்கள் விவரங்களை நீங்கள் புதுப்பித்தால் அல்லது மாற்றினால், உங்கள் தரவு சரிபார்க்கப்படாது, மேலும் புதுப்பிக்கப்பட்ட தரவுகளுக்கான ஆவண ஆதாரத்தை பதிவேற்றும்படி கேட்கப்படுவீர்கள். தொடர “தொடரவும்” என்பதைக் கிளிக் செய்க.`,
    "b010e3be9f81072a9808ed4a5dd0e45b": `கணக்கெடுப்பில் நீங்கள் வழங்கிய விவரங்களுடன் பின்வரும் தகவல்கள் பொருந்தவில்லை:`,
    "01acb0344944f11d68aeecc9f5b574ea": `நீங்கள் பதில் (கள்) உடன் தொடர விரும்புகிறீர்களா?`,
    "4ff70f7b2e5a74415de360150a6e59d3": `உங்கள்`,
    "bd7aaae9a27a2a4d71214c002bcf50ce": `பதிவோடு பொருந்தவில்லை.`,
    "ebfd339b0ac14aa79b325b53f06bd375": `நீங்கள் வழங்கிய தகவல்கள் உங்கள் ஆதார் விவரங்களுடன் பொருந்தவில்லை. நீங்கள் மேலும் தொடர முடியாது.`,
    "1e410e1b9903e7a99b93857f536b7e1d": `சரிபார்க்கப்படவில்லை`,
    "28b266f2bf0a4ecc2dec3b6ca24fcb9b": `பதிவேற்றவும்`,
    "68982db8041ee52dd9e5a6e527c8a1fa": `மீண்டும் பயிர்`,
    "e7bf7d83a37666fd8749e433189b1c27": `தயவுசெய்து காத்திருங்கள்`,
    "c389f9e7d5ea93af9deb2e8f1f9c6d89": `மேலும் அறிய {{"0x0001F6C8"}} the இல் கிளிக் செய்க.`,
    "f17816bcbe79e18bee49388fe84d2876": `OTP காலாவதியானது.`,
    "1531d9dc798857eb85345d349bffc99b": `மீண்டும்`,
    "7939de8a9958e01fb1f172e21c5a7a85": `தயவுசெய்து சரியான தகவலை வழங்கவும், நீங்கள் தகுதியான திட்டங்களைக் காட்ட இது எங்களுக்கு உதவும்.`,
    "bf58c4a9eac8e0ec82c158c3adfcd609": `திட்டங்கள் வெவ்வேறு வகைகளின் கீழ் வகைப்படுத்தப்பட்டுள்ளன. நீங்கள் விரும்பும் வகையின்படி திட்டங்களை சரிபார்க்கலாம்`,
    "09349e778786f7ed758021ddbed12ca6": `{Intent} பிரிவின் கீழ் தகுதியான திட்டங்களைக் கண்டுபிடிக்க இந்த தகவல் எங்களுக்கு உதவும்.`,
    "0d45c019e7e9237a316f0f16824015d2": `உங்கள் சுயவிவர தரவு வெவ்வேறு தாவல்களின் கீழ் சேமிக்கப்படுகிறது. உங்கள் சுயவிவரத் தகவல்களைத் திருத்தலாம்.`,
    "3f52884ec08606e6e73426eaec8de195": `இந்த திட்டத்தின் கீழ் நீங்கள் பெறக்கூடிய சரியான நன்மை மற்றும் நன்மைகளைப் பெறுவதற்கான விண்ணப்ப செயல்முறை பற்றிய விவரங்களை உங்களுக்கு வழங்க இந்த தகவல் எங்களுக்கு உதவும்.`,
    "c389f9e7d5ea93af9deb2e8f1f9c6d89": `மேலும் அறிய {{"0x0001F6C8"}} இல் கிளிக் செய்க.`,
    "5cc2bdab3db5aa21633656a0d55f7477": `விண்ணப்பத்துடன் நீங்கள் மேலும் தொடர முடியாது`,
    "d5de543c0e83073f05a846d62cc83c81": `அதே புலத்திற்கு ஏற்கனவே பதிவேற்றப்பட்ட ஆவணம்.`,
    "fca8da28cbcbe094dc646872e7cdbc7b": `செல்லுபடியாகும்`,
    "6fa2260c737f40fa1a3f63274c8fca48": `நீங்கள் மேலும் தொடர முடியாது. உங்கள் விவரங்களை சுயவிவரத்தில் அல்லது உங்கள் ஆதார் அட்டையில் சரிசெய்யலாம்`,
    "3272e327ba9c82197c5c982487224925": `மன்னிக்கவும், மூல அமைப்பிலிருந்து உங்கள் தரவை எங்களால் பெற முடியவில்லை. பின்னர் மீண்டும் முயற்சிக்கவும்.`,
    "586637318049e6dbb03e991bc45cba06": `உங்கள் கணக்கை நீக்க முதலில் உங்கள் கணக்குடன் தொடர்புடைய அனைத்து சுயவிவரங்களையும் நீக்க வேண்டும்.`,
    "ce6d6092569f3f247659ceb51d57be95": `நீங்கள் பயன்படுத்தப்பட்ட அல்லது பதிவுசெய்யப்பட்ட திட்டங்களில் தொடர்ந்து பயன்பாடு இருப்பதால் {{profileName}} சுயவிவரத்தை நீக்க முடியாது`,
    "8d258e398e2dbc8099c984258fadce2a": `உங்கள் பதிவுசெய்யப்பட்ட மொபைல் எண்ணுக்கு ஒரு OTP அனுப்பப்பட்டுள்ளது (xxx xxx {{phoneNumber}})`,
    "abb2e3d13ded4cea9275beafacd9f4c8": `இந்தத் திட்டத்தில் உங்கள் ஆர்வத்தை உயர்த்த, "உங்கள் ஆர்வத்தை உருவாக்கு" என்பதைக் கிளிக் செய்து, இந்த திட்டத்தின் நன்மையை வழங்க அரசாங்கம் உங்களை அணுகும்`,
    "493de8e0a6b5d4a10af30cf3105b9e1f": `உங்கள் ஆர்வத்தை உருவாக்குங்கள்`,
    "c9b0246a8f3989724ec4958f91e380a7": `திட்ட விவரங்கள்`,
    "804ca01b219522a88d26c3002632a00e": `விவரங்களைப் பகிர்ந்தமைக்கு நன்றி.`,
    "04b6d9b7eb73749476d958b2133f4bbe": `திட்ட விழிப்புணர்வு ஆய்வு`,
    "a5661f68fc78a5d4f15afa057fec7a9f": `மேலும் தொடர உங்கள் விவரங்களை கணக்கெடுப்பில் புதுப்பிக்க வேண்டும்.`,
    "1e4531d1dc128abbc00b48e5c7b6ef48": `கணக்கெடுப்பு புதுப்பிப்பு`,
    "362e532b6ad1fda489c5909d437f02a3": `உங்கள் விவரங்களை நாங்கள் பெறுகிறோம், தயவுசெய்து காத்திருங்கள் ..`,
    "8a1b3c7d62e2d60d5be34d8e36a9768f": `தனிப்பட்ட உள்நுழைவுக்குத் திரும்பு`,
    "6737d50489b1cf1d4408e66b8591ca38": `உங்கள் திட்ட பயன்பாட்டில் நிலுவையில் உள்ள நடவடிக்கைகள் உள்ளன. உங்கள் விண்ணப்பத்துடன் மேலும் தொடர நிலுவையில் உள்ள செயல்களை முடிக்கவும்.`,
    "e37c99137b7396277137c1f73f8a86d5": `சிஈஜி இன் உள்ளுணர்வு`,
    "706c7a936b977ec3c4e6603f888e3fce": `EasyGov ஆல் இயக்கப்படுகிறது`,
    "598d42810b342196884f255912974d69": `எஸ்.எல். இல்லை.`,
    "60e4e81c6f9c1938d77e8ded6d64643a": `ஸ்கிரீன் ரீடர்`,
    "15bbb9d0bbf25e8d2978de1168c749dc": `இணையதளம்`,
    "21d587949a2a2e89c958b622e244b405": `இலவச/ வணிக`,
    "d5c03f7b855d442a2a7f24024cf89960": `அல்லாத விஷுவல் டெஸ்க்டாப் அணுகல் (என்விடிஏ)`,
    "f956e16f65a3a937e5ecfe4195d17d0d": `(புதிய சாளரத்தில் திறக்கும் வெளிப்புற வலைத்தளம்)`,
    "b24ce0cd392a5b0b8dedc66c25213594": `இலவசம்`,
    "c8ed8ec3dc8b21290b5d28ca56344553": `தாடைகள்`,
    "b2788e1ab6be65f1c38bf7d23cd081f1": `வணிக`,
    "ec18aa8148f59475130ff217d693b465": `சாளரக் கண்கள்`,
    "d96a273ceb2b5f83d710081d9c38440a": `செல்ல கணினி அணுகல்`,
    "c932fd98147348de9974252a0efcc7cb": `WebAnywhere`,
    "540431e2b3234a8698b4044f15ef1fff": `PDF உள்ளடக்கம்`,
    "801ab24683a4a8c433c6eb40c48bcd9d": `பதிவிறக்க`,
    "83f8a38f024082405d2fff921c2bb1d2": `அடோப் அக்ரோபேட் ரீடர்`,
    "9d8c84ff52cfc2c2e0075e669edacffd": `சொல் கோப்புகள்`,
    "4db1a171aaf96723d4002cc41559ddf7": `புதிய சாளரத்தில் திறக்கும் வெளிப்புற வலைத்தளம்`,
    "86c61b344697039b8a54bbef9e4959c3": `எக்செல் பார்வையாளர் 2003 (எந்தவொரு பதிப்பிலும் 2003 வரை)`,
    "04efb134468330da1430e32701160107": `எக்செல் (2007 பதிப்பிற்கு) மைக்ரோசாஃப்ட் ஆபிஸ் பொருந்தக்கூடிய பேக்`,
    "a385a9bb61bb694694eef5d2f54c563a": `பவர்பாயிண்ட் விளக்கக்காட்சிகள்`,
    "da67d56a74c01537a71a3ab0eda00faa": `பவர்பாயிண்ட் (2007 பதிப்பிற்கு) மைக்ரோசாஃப்ட் ஆபிஸ் பொருந்தக்கூடிய பேக்`,
    "bb625547a85b98c2c9f9f4121c72cd78": `ஃபிளாஷ் உள்ளடக்கம்`,
    "ab69edd2798068928605717c6591462f": `அடோப் மின்னொளி விளையாட்டு கருவி`,
    "2c4fa0a307152ef814bbab8d4075d938": `ஆடியோ கோப்புகள்`,
    "da7be99fd6fac5c8a4065eb2c07cad88": `விண்டோஸ் மீடியா பிளேயர்`,
    "5a5652ca26ee42fe73fdd79497cf9e21": `சொல் பார்வையாளர் (எந்த பதிப்பிலும் 2003 வரை)`,
    "2e856241d3d995fefdb1b4b32f84a79b": `வேர்டுக்கான மைக்ரோசாஃப்ட் ஆபிஸ் பொருந்தக்கூடிய பேக் (2007 பதிப்பிற்கு)`,
    "64a443242750513eb56695e07aedcff2": `எக்செல் கோப்புகள்`,
    "382b0f5185773fa0f67a8ed8056c7759": `என்/ஏ`,
    "462c18d5b89050fb1b7f8fca1e535af8": `செயலாக்கப்பட்டது`,
    "ccb26ac32c3cc7cc2f0fd1bbac3b8e2d": `பதப்படுத்தப்பட்ட சேவைகளில் உங்களிடம் எந்த சேவைகளும் இல்லை`,
    "fc44a0efc672761745c2e76aac6851d2": `உங்கள் பயன்பாடு நிராகரிக்கப்படுகிறது`,
    "7d0db380a5b95a8ba1da0bca241abda1": `at`,
    "1252044838fd74c4e8b506ef1302a8b5": `குறை`,
    "06627e6919d21853d887d00334ee5337": `குறை தீர்க்கும் மேலாண்மை`,
    "f940fbe4aec76e9784e7f15c4b92ac6d": `பதப்படுத்தப்பட்டதில் உங்களுக்கு எந்தவிதமான குறைகளும் இல்லை`,
    "2290dc1500a2b4548eb3b931f2e3609a": `உங்களிடம் சேமிக்கப்பட்ட குறைகள் எதுவும் இல்லை.`,
    "b25b60dc1a5f7c17e09bc73174668d9a": `வரைவில் உங்களுக்கு எந்த குறைகளும் இல்லை.`,
    "a02c58d421087ec9519620a29d68a6ec": `நீங்கள் இதுவரை எந்தக் குறைகளுக்கும் சமர்ப்பிக்கவில்லை.`,
    "b65f636dcbc018b1901e23a7c2adde90": `மேலும் ஆராயுங்கள்`,
    "5c835c22429f25992b4371691ecde1a4": `சமர்ப்பிக்கப்பட்டது`,
    "2535f5e4e0892c73a5fad023bf3e15e2": `என் குறைகள்`,
    "4351cfebe4b61d8aa5efa1d020710005": `பார்வை`,
    "e9516387730903ffd7cfe3317e05e385": `உங்கள் "என் குறைகளை" இந்த குறைகளை காப்பாற்றியுள்ளது.`,
    "b70db24f2cb70fb266eb03da8f289ba5": `குறைகளை காண்க`,
    "646558064e10a12b1e8914e86eca3ca6": `உங்கள் சேவை பயன்பாட்டில் நிலுவையில் உள்ள நடவடிக்கைகள் உள்ளன. உங்கள் விண்ணப்பத்துடன் மேலும் தொடர நிலுவையில் உள்ள செயல்களை முடிக்கவும்.`,
    "d2841171ea05a48fa5463b3f7c7c92d7": `உங்கள் குறை தீர்க்கும் பயன்பாட்டில் நிலுவையில் உள்ள நடவடிக்கைகள் உள்ளன. உங்கள் விண்ணப்பத்துடன் மேலும் தொடர நிலுவையில் உள்ள செயல்களை முடிக்கவும்.`,
    "9279f823b01f13b7a8c04688289543cc": `உங்கள் பயன்பாடு உங்கள் 'வரைவு குறைகளை' வெற்றிகரமாக சேமித்தது.`,
    "af481c93dcae73d73afe9927076c8a09": `இந்த குறைகளுக்கு நீங்கள் ஏற்கனவே விண்ணப்பித்துள்ளீர்கள். உங்கள் பயன்பாட்டு குறைகளிலிருந்து உங்கள் விண்ணப்ப நிலையை சரிபார்க்கவும்.`,
    "ce972231a0faf31791c7ac0bf0d1da84": `நீங்கள் ஏதேனும் பணம் செலுத்தியிருந்தால் உங்கள் தொகை திருப்பித் தரப்படாது.`,
    "2ffef02ca513f8bbadbaf130a23097de": `உங்கள் பயன்பாடு உங்கள் "வரைவு குறைகளில்" வெற்றிகரமாக சேமிக்கப்பட்டுள்ளது`,
    "e55e425dbaf6b6449940eac8ea749092": `தயவுசெய்து எழுத்துக்களை மட்டுமே உள்ளிடவும்.`,
    "64ff9e3809686d4ea3253a631cae4761": `2 தசம இடங்களுக்கு சரியான மதிப்பை உள்ளிடவும்.`,
    "7ed0ef23d527c2876015161e422f49ce": `உங்கள் பெயரை கன்னட மொழியில் உள்ளிடவும்.`,
    "60ae728544ab5cb25dba9ff4b8f6aa95": `சேவா சிந்துவுக்கான உங்கள் பயன்பாட்டு குறிப்பு எண்`,
    "2c0ed1f2f9239dbca46beca549190e00": `ஆதார் முகவரியின் சான்றாக எடுத்துக் கொள்ளப்படும், தயவுசெய்து ஆதாரத்தைப் போல மாவட்டத்தைத் தேர்ந்தெடுக்கவும்.`,
    "8355a53d777c5ac84a37970b5158aba5": `பிழை 205: ஆதாரத்தில் உங்கள் பெயர் சாதி அல்லது வருவாய் துறை வழங்கிய வருமான சான்றிதழில் உள்ள விவரங்களுடன் பொருந்தவில்லை.`,
    "601f77894e71ae7d4922eda713dda66c": `பிழை 207: உங்கள் சாதி மற்றும் வருமான சான்றிதழ் காலாவதியானது.`,
    "9182b3e8dc7965f2775a204095f4c992": `நீங்கள் உள்ளிட்ட OTP தவறானது. சரியான OTP உடன் மீண்டும் முயற்சிக்கவும்.`,
    "6e0fe1003e84e34da97a6c4c9040b31b": `பின்தங்கிய வகுப்புகள் நலத்துறையின் ARIVU கல்வி கடன் திட்டத்திற்கான (புதுப்பித்தல்) விண்ணப்பத்தை ஏற்றுக்கொண்ட கடைசி தேதி மார்ச் 31, 2022 வரை நீட்டிக்கப்பட்டுள்ளது.`,
    "69d3a9de2fc1407f59e8936ca0c525a0": `மன்னிக்கவும், பிழை ஏற்பட்டது. பின்னர் மீண்டும் முயற்சிக்கவும்.`,
    "7953c16d691da03c899391a39412fc9b": `உங்கள் திட்ட பயன்பாட்டில் நிலுவையில் உள்ள நடவடிக்கைகள் உள்ளன. உங்கள் விண்ணப்பத்துடன் மேலும் தொடர <i> {{startDate}} </i> இலிருந்து நிலுவையில் உள்ள செயல்களை முடிக்கவும்.`,
    "f4f4366332ac6949882de59c81b08de9": `உங்கள் திட்ட பயன்பாட்டில் நிலுவையில் உள்ள நடவடிக்கைகள் உள்ளன. உங்கள் பயன்பாட்டுடன் மேலும் தொடர <i> {{startDate}} </i> முதல் <i> {{endDate}} </i> வரை நிலுவையில் உள்ள செயல்களை முடிக்கவும்.`,
    "77b43c24220ee608380031b72f8017ce":`சேவை வகைகள்`,
    "e0e54c374f8b39a1520766e5924af100": `உங்கள் குடும்பத்திற்கும் உங்களுக்குமான திட்டங்கள், சேவைகள் மற்றும் வாழ்வாதாரத்தை அறிய ஒரு தளம்`,
    "d56f6b073f573237a6c4aed91589e982": `வாழ்வாதாரம்`,
    "e5930fadbc620e368c92561f381ead58": `உங்களுக்கான வாழ்வாதார வாய்ப்புகள்`,
    "192adf39ebd850e3e2e30971e885dc6d": `தற்போது உங்கள் மாநிலத்தில் வாழ்வாதார வாய்ப்புகள் எதுவும் கட்டமைக்கப்படவில்லை, நாங்கள் தொடர்ந்து புதுப்பித்து வருகிறோம், தயவுசெய்து எங்களுடன் மீண்டும் சரிபார்க்கவும்`,
    "TM_Alt51": "இணைப்பு(கள்)",
    "TM_Hea1": "மொத்த தொகை",
    "TM_Hea2": "சுருக்கம்",
    "TM_Sub56": "மொத்தம்",
    "TM_But42": "பணம் செலுத்து",
    "TM_Mod41":"கட்டணம் நிலுவையில் உள்ளது",
    "TM_Mod40":"பணம் வெற்றிகரமாக செலுத்தப்பட்டது ",
    "TM_Alt72":"பணம் செலுத்த முடியவில்லை",
    "TM_Pag48":"உதவி மற்றும் ஆதரவு",
    "TM_Con33":"நன்றி, உங்கள் விண்ணப்பம் வெற்றிகரமாக சமர்ப்பிக்கப்பட்டது.",
    "TM_Err34": "பணம் செலுத்துவதில் தோல்வி ஏற்பட்டதால் உங்கள் விண்ணப்பத்தை சமர்ப்பிக்க முடியவில்லை.",
    "TM_Rec55": "உங்கள் கட்டணத்தின் நிலையை உறுதிசெய்ய நேரம் எடுக்கிறது. நாங்கள் தொடர்ந்து உங்கள் வங்கியில் சோதனை செய்து வருகிறோம். அடுத்த 48 மணிநேரத்திற்கு கூடுதல் பணம் செலுத்துவதைத் தவிர்க்கவும்.",
    "TM_But13": "திரும்பி செல்",
    "TM_Alt124":"தயவுசெய்து காத்திருக்கவும்",
    "TM_Rec58": "காத்திருங்கள்.உங்கள் கட்டண நிலையை நாங்கள் சரிபார்க்கிறோம்",
    "TM_Rec59": "இந்தச் சேவைக்கான உங்கள் கடைசிப் பணம் நிலுவையில் உள்ளது. அது முடிந்ததும் உங்களுக்கு அறிவிப்போம். இதற்கிடையில், எங்கள் பிற சேவைகளை ஆராய தயங்க வேண்டாம்.",
    "TM_Rec60":"பொறுமை காத்தமைக்கு நன்றி.",
    "TM_Rec61":"பக்கம் தானாகவே காலாவதியாகிவிடும்",
    "TM_Alt165":"பணம் செலுத்த உங்கள் மொபைல் சாதனத்தின் யுபிஐ பயன்பாட்டில் உள்ள கியூஆர் குறியீட்டை ஸ்கேன் செய்யவும்",
    "TM_Pag39": "லபார்த்தி",
    "TM_Alt166":"கட்டணம் நிராகரிக்கப்பட்டது",
    "TM_Err39":"கட்டணம் நிராகரிக்கப்பட்டதால் உங்கள் விண்ணப்பத்தை சமர்ப்பிக்க முடியவில்லை.",
    "cc4cbfe72c596683881033f36eac3e2f":"உங்கள் வங்கியில் உங்கள் கட்டணத்தை உறுதிசெய்யும் வரை காத்திருக்கவும். இந்த செயல்முறை சிறிது நேரம் ஆகலாம்.",
    "7d54c690a3a707e692cceb6978e24bc1": "கட்டண முறிவு",
}
export default language;