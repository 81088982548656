const initState = {
    data: "",
  };

  const signUpReducer = (state = initState, action) => {
    switch (action.type) {
      case "SIGN_UP":
        return {
          ...state,
          data: action.payload,
        };

      case "RESET_SIGNUP_DATA":
        return {
          ...state,
          data: undefined,
        };

      default:
        return state;
    }
  };

  export default signUpReducer;
