const initState = {
    data: "",
  };

  const otpVerifyReducer = (state = initState, action) => {
    switch (action.type) {
      case "OTP_VERIFY":
        return {
          ...state,
          data: action.payload,
        };

        case "OTP_VERIFY_RESET":
          return {
            ...state,
            data: "",
          };

      default:
        return state;
    }
  };

  export default otpVerifyReducer;
