import React from "react";
import ReactDOM from "react-dom";
import "bootstrap-css-only/css/bootstrap.min.css";
import "./css/web/index_web.css";
import "./css/mobile/index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store/store";

ReactDOM.render(
  
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
