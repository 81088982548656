const initState = {
    data: "",
  };
  
  const deleteApplicationFromDraftReducer = (state = initState, action) => {
    switch (action.type) {
      case "WITHDRAW_APPLICATION":
        return {
          ...state,
          data: action.payload,
        };
  
      case "RESET_WITHDRAW_APPLICATION":
        return {
          ...state,
          data: "",
        };
  
      default:
        return state;
    }
  };
  
  export default deleteApplicationFromDraftReducer;
  