import axios from "axios";
import keys from '../../common/keys'
import storage from '../../utility/encryptData'
import CryptoJS from 'crypto-js'
import history from "../../history"

const getTranslatedValue = (translatedText, hash) => {
  let translatedObj = translatedText[hash]
  if(translatedObj){
    return translatedText[hash]
  } else {
    return null
  }
}

export const getProduct = (geographyId, closeLoader = false) => {
  const instance = axios.create({
    baseURL: window.HOST,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "",
    },
  });

  return (dispatch) => {
    dispatch({ type: "HANDLE_LOADER", payload: { showLoader: true } });
    let requestObj;
    if(storage.getItemValue(keys.USER_TYPE) === "business"){
      requestObj = { geographyId: geographyId, beneficiaryTypeId: 4, channel: window.outerWidth <=600 ? `${window.displayChannelMobile}` : `${window.displayChannelWeb}` };
    } else{
      requestObj = { geographyId: geographyId, beneficiaryTypeId: 1, channel: window.outerWidth <=600 ? `${window.displayChannelMobile}` : `${window.displayChannelWeb}` };
    }
    if (storage.getItemValue(keys.LANGUAGE_OBJ)) {
      requestObj["locale"] = JSON.parse(
        storage.getItemValue(keys.LANGUAGE_OBJ)
      ).code;
    }
    instance
      .get("getproducts/", { params: requestObj })
      .then((response) => {
        if (response.status === 200) {
          if(response.data.code === 401){
            if(window.outerWidth <=600){
              history.push("/main")
            } else {
              history.push("/homeUser")
            }
            dispatch({ type: "HANDLE_LOADER", payload: {showLoader: false}});
          } else {
            let schemeList = response.data.data.objects
            let tagsList = response.data.data.taggingData
            let translatedText = response.data.translation
            if(JSON.parse(storage.getItemValue(keys.LANGUAGE_OBJ)).code !== "en"){
              if(schemeList && schemeList.length > 0){
                schemeList.map(scheme => {
                  if(getTranslatedValue(translatedText, CryptoJS.MD5(scheme.service.name.replace(/\s/g,"")).toString(CryptoJS.enc.Hex))){
                    scheme.service['untranslated_name'] = scheme.service.name
                    scheme.service.name = getTranslatedValue(translatedText, CryptoJS.MD5(scheme.service.name.replace(/\s/g,"")).toString(CryptoJS.enc.Hex))
                  }
                  if(scheme.service.benefits && getTranslatedValue(translatedText, CryptoJS.MD5(scheme.service.benefits.replace(/\s/g,"")).toString(CryptoJS.enc.Hex))){
                    scheme.service['untranslated_benefits'] = scheme.service.benefits
                    scheme.service.benefits = getTranslatedValue(translatedText, CryptoJS.MD5(scheme.service.benefits.replace(/\s/g,"")).toString(CryptoJS.enc.Hex))
                  }
                  if(scheme.downloadButton && getTranslatedValue(translatedText, CryptoJS.MD5(scheme.downloadButton.replace(/\s/g,"")).toString(CryptoJS.enc.Hex))){
                    scheme['untranslated_benefits'] = scheme.downloadButton
                    scheme.downloadButton = getTranslatedValue(translatedText, CryptoJS.MD5(scheme.downloadButton.replace(/\s/g,"")).toString(CryptoJS.enc.Hex))
                  }
                  scheme.productTags.map(productTag => {
                    if(productTag.visible){
                      if(getTranslatedValue(translatedText, CryptoJS.MD5(productTag.name.replace(/\s/g,"")).toString(CryptoJS.enc.Hex))){
                        productTag['untranslated_name'] = productTag.name
                        productTag.name = getTranslatedValue(translatedText, CryptoJS.MD5(productTag.name.replace(/\s/g,"")).toString(CryptoJS.enc.Hex))
                      }
                    }
                  })
                })
              }
              if(tagsList && tagsList.length > 0){
                tagsList.map(tag => {
                  if(getTranslatedValue(translatedText, CryptoJS.MD5(tag.category.replace(/\s/g,"")).toString(CryptoJS.enc.Hex))){
                    tag['untranslated_category'] = tag.category
                    tag.category = getTranslatedValue(translatedText, CryptoJS.MD5(tag.category.replace(/\s/g,"")).toString(CryptoJS.enc.Hex))
                  }
                  if(getTranslatedValue(translatedText, CryptoJS.MD5(tag.subCategory.replace(/\s/g,"")).toString(CryptoJS.enc.Hex))){
                    tag['untranslated_subCategory'] = tag.subCategory
                    tag.subCategory = getTranslatedValue(translatedText, CryptoJS.MD5(tag.subCategory.replace(/\s/g,"")).toString(CryptoJS.enc.Hex))
                  }
                })
              }
            }
            response.data.data.objects = schemeList
            response.data.data.taggingData = tagsList
            dispatch({ type: "HANDLE_LOADER", payload: { showLoader: false } });
            if(!closeLoader || storage.getItemValue(keys.USER_TYPE))
              dispatch({ type: "HANDLE_LOADER", payload: { showLoader: false } });
            dispatch({ type: "GET_PRODUCT", payload: response.data.data });
          }
        }
      })
      .catch((err) => {
        dispatch({ type: "HANDLE_LOADER", payload: {showLoader: false}});
        console.log(err);
      });
  };
};
