const initState = {
  data: "",
};

const getAppOpenCountReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_APP_OPEN_COUNT":
      return {
        ...state,
        data: action.payload,
      };

      case "REST_GET_APP_OPEN_COUNT":
        return {
          ...state,
          data: "",
        };

    default:
      return state;
  }
};

export default getAppOpenCountReducer;
